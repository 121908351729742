import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { SpinnerService } from 'src/app/core/spinner.service';
import { DateDispalyFormatPipeService } from 'src/app/shared/pipes/date-dispaly-format-pipe.service';
import { dateOnlyWithTimezone } from 'src/app/shared/pipes/dateOnlyWithTimezone.service';
import { PhoneFilterPipe } from 'src/app/shared/pipes/phone.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';

@Component({
  selector: 'app-edit-contract-dialog',
  templateUrl: './edit-contract-dialog.component.html',
  styleUrls: ['./edit-contract-dialog.component.scss']
})
export class EditContractDialogComponent implements OnInit {
  pickupContract: any;
  allClients: any;
  selectedClient: any;
  pickupList: any;
  pickupType: any;
  contractPeriods: any;
  didCreateNewContract: boolean;
  clientRequired: boolean;
  pickupTypeRequired: boolean;
  contractPerPriceRequired: boolean;
  contractExtraPriceRequired: boolean;
  noOfContractRequired: boolean;
  startDateRequired: boolean;
  contractPeriodRequired: boolean;
  fnameRequired: boolean;
  lnameRequired: boolean;
  phoneRequired: boolean;
  emailRequired: boolean;
  invalidEmail: boolean;
  showStartDateRequired: boolean;
  phoneValid: boolean;
  contractPerPrice: string;
  contractExtraPrice: number;
  noOfContract: string;
  showStartDate: any;
  showEndDate: any;
  contractPeriod: any;
  fname: string;
  lname: string;
  phone: string;
  email: string;
  previousValues: any;
  didPauseContact: any;
  selectedClientObj: any;
  contractNetDays: number;
  contractDiscount: number;
  contractNetDaysRequired: boolean;
  contractDiscountRequired: boolean;
  contractDiscountRequiredError: boolean;
  allNetTermsList: any = [0, 15, 30, 60];
  endDateError: boolean;
  newDate: any;
  constructor(public dialogRef: MatDialogRef<EditContractDialogComponent>,
    private pickupService: PickUpService,
    private ClientFilterdata: ClientFilterService,
    @Inject(MAT_DIALOG_DATA) public data1: MatDialog,
    private spinnerService: SpinnerService,
    public phoneFilterPipe: PhoneFilterPipe,
    public dateFormat: dateOnlyWithTimezone,
    public dateTimeFormat: DateDispalyFormatPipeService,
  ) { }

  ngOnInit() {
    var spinnerRef = this.spinnerService.start();
    let localUser = JSON.parse(localStorage.getItem('user'));
    const listOfClients = localUser.user.User.Clients;
    if (listOfClients.length > 0) {
      this.allClients = listOfClients.sort(function (a, b) {
        if (a.Name < b.Name) {
          return -1;
        }
        if (a.Name > b.Name) {
          return 1;
        }
        return 0;
      });
    }

    this.pickupService.getPickupContractById(this.data1['ID']).subscribe((data) => {
      // data.Result.map((val: any) => {
      //   val.Start_Date = this.dateTimeFormat.transform(val.Start_Date, this.data1['TimeZone']);
      //   val.End_Date = this.dateTimeFormat.transform(val.End_Date, this.data1['TimeZone']);
      // });


      this.pickupContract = data.Result
      this.spinnerService.stop(spinnerRef);
      this.selectedClient = this.pickupContract[0].ClientId;
      this.pickupType = this.pickupContract[0].PickupType.ID;
      this.contractPerPrice = this.pickupContract[0].Contract_Price_Per_Pickup;
      this.contractExtraPrice = this.pickupContract[0].Extra_Price_Per_Pickup;
      this.showStartDate = this.pickupContract[0].Start_Date;
      this.showEndDate = this.pickupContract[0].End_Date;
      this.noOfContract = this.pickupContract[0].No_Of_Contracted_Pickups;
      this.email = this.pickupContract[0].Email;
      this.fname = this.pickupContract[0].AspNetUser.FirstName;
      this.lname = this.pickupContract[0].AspNetUser.LastName;
      this.phone = this.pickupContract[0].AspNetUser.PhoneNumber;
      this.contractPeriod = this.pickupContract[0].ContractPeriod.ID;
      this.previousValues = this.pickupContract[0]
      this.didPauseContact = this.pickupContract[0].PauseContract == true ? "yes" : "no"
      this.didCreateNewContract = false
      this.contractNetDays = this.pickupContract[0].Netdays
      this.contractDiscount = this.pickupContract[0].DiscountInPercent
      this.phone = this.phoneFilterPipe.transform(this.phone)
      this.selectedClientObj = this.allClients.filter((ele) => (this.pickupContract[0].ClientId == ele.ClientId))
      let date = new Date(this.pickupContract[0].Start_Date);
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      let hr = date.getHours();
      let min = date.getMinutes();
      let newDate = moment.tz(new Date(year, month, day, hr, min), this.data1['TimeZone']);
      newDate.format();
      this.newDate = moment(newDate).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
      let i = new Date().getTimezoneOffset() * -60000
      this.showStartDate = new Date(i + new Date(this.showStartDate).valueOf())
      if (this.showEndDate) this.showEndDate = new Date(i + new Date(this.showEndDate).valueOf());

    });
    this.pickupService.getPickupFilterList().subscribe((d) => {
      const list = d.Result;
      const index = list.findIndex((p) => p['Title'] === 'One Time Pickup');
      if (index > 1) {
        list.splice(index, 1);
      }
      this.pickupList = list;
    });

    this.pickupService.getContractPeriod().subscribe((d) => {
      const list1 = d.Result;
      this.contractPeriods = list1;
    });
  }
  createNewContract() {
    this.contractPerPrice = this.previousValues.Contract_Price_Per_Pickup;
    this.contractExtraPrice = this.previousValues.Extra_Price_Per_Pickup;
    this.noOfContract = this.previousValues.No_Of_Contracted_Pickups;
    this.contractPeriod = this.previousValues.ContractPeriod.ID;
    this.contractNetDays = this.previousValues.Netdays;
    this.contractDiscount = this.previousValues.DiscountInPercent;
  }
  isvalid() {
    if (this.selectedClient == null) {
      this.clientRequired = true;
      setTimeout(() => {
        this.clientRequired = false;
      }, 3000);
    } else if (this.pickupType == null) {
      this.pickupTypeRequired = true;
      setTimeout(() => {
        this.pickupTypeRequired = false;
      }, 3000);
    } else if (this.noOfContract == undefined || this.noOfContract == '') {
      this.noOfContractRequired = true;
      setTimeout(() => {
        this.noOfContractRequired = false;
      }, 3000);
    } else if (this.contractPeriod == undefined) {
      this.contractPeriodRequired = true;
      setTimeout(() => {
        this.contractPeriodRequired = false;
      }, 3000);
    }
    else if (this.contractPerPrice == '' || this.contractPerPrice == undefined) {
      this.contractPerPriceRequired = true;
      setTimeout(() => {
        this.contractPerPriceRequired = false;
      }, 3000);
    } else if (this.contractExtraPrice == undefined || this.contractExtraPrice == null) {
      this.contractExtraPriceRequired = true;
      setTimeout(() => {
        this.contractExtraPriceRequired = false;
      }, 3000);
    } else if (this.showStartDate == undefined || this.showStartDate == '') {
      this.startDateRequired = true;
      setTimeout(() => {
        this.startDateRequired = false;
      }, 3000);
    }
    else if (this.contractNetDays == undefined || this.contractNetDays == null) {
      this.contractNetDaysRequired = true;
      setTimeout(() => {
        this.contractNetDaysRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount == undefined || this.contractDiscount == null) {
      this.contractDiscountRequired = true;
      setTimeout(() => {
        this.contractDiscountRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount < 0 || this.contractDiscount >= 101) {
      this.contractDiscountRequiredError = true;
      setTimeout(() => {
        this.contractDiscountRequiredError = false;
      }, 3000);
    } else if (this.fname == undefined || this.fname == '') {
      this.fnameRequired = true;
      setTimeout(() => {
        this.fnameRequired = false;
      }, 3000);
    }
    else if (this.lname == undefined || this.lname == '') {
      this.lnameRequired = true;
      setTimeout(() => {
        this.lnameRequired = false;
      }, 3000);
    } else if (this.email == undefined || this.email == '') {
      this.emailRequired = true;
      setTimeout(() => {
        this.emailRequired = false;
      }, 3000);
    } else if (this.validateEmail() == false) {
      this.invalidEmail = true;
      setTimeout(() => {
        this.invalidEmail = false;
      }, 3000);
    } else if (this.phone == undefined || this.phone == '') {
      this.phoneRequired = true;
      setTimeout(() => {
        this.phoneRequired = false;
      }, 3000);
    } else if (this.phone.length !== 12) {
      this.phoneValid = true;
      setTimeout(() => {
        this.phoneValid = false;
      }, 3000);
    }
    else {
      this.updateContract()
    }

  }
  updateContract() {
    var spinnerRef = this.spinnerService.start();
    let startDate = moment
      .tz(this.showStartDate, 'YYYY-MM-DDTHH:mm:ss', this.data1['TimeZone'])
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');
    startDate = startDate.split("Z")[0];
    let endDate;
    if (this.showEndDate !== '' && this.showEndDate !== undefined && this.showEndDate !== null) {
      endDate = moment
        .tz(this.showEndDate, 'YYYY-MM-DDTHH:mm:ss', this.selectedClientObj.TimeZone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
      endDate = endDate.split("Z")[0];
    } else endDate = ""

    let payload = {
      "UserId": this.pickupContract[0].AspNetUser.Id,
      "ID": this.pickupContract[0].ID,
      "ClientId": this.selectedClient,
      "PickupTypeId": this.pickupType,
      "Contract_Price_Per_Pickup": this.contractPerPrice,
      "Extra_Price_Per_Pickup": this.contractExtraPrice || '0',
      "No_Of_Contracted_Pickups": this.noOfContract,
      "ContractPeriodId": this.contractPeriod,
      "Start_Date": startDate,
      "End_Date": endDate,
      "Email": this.email,
      "ApprovalStatusId": this.pickupContract[0].ApprovalStatusId,
      "PauseContract": this.didPauseContact == 'no' ? false : true,
      "Q_CustomerID": this.pickupContract[0].Q_CustomerID,
      "Netdays": this.contractNetDays,
      "DiscountInPercent": this.contractDiscount
    }
    console.log(payload)
    this.pickupService.updateContract(payload).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);

      this.dialogRef.close("Done")
    },
      (err) => {
        console.log(err);
      });
  }
  onChangeEndDate(date) {
    this.showEndDate = new Date(date);
  }
  onChangeStartDate(date) {
    this.showStartDate = new Date(date);
  }
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.email) === false) return false;
    else return true;
  }
}
