import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../loginmodule/login/login.component';
import { BackgroundPageComponent } from '../loginmodule/password-page/background-page.component';

import { UserComponent } from '../dashboard/user/user/user.component';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';
import { RequestPickupComponent } from '../dashboard/Request_pickup/request-pickup/request-pickup.component';
import { HomeComponent } from '../dashboard/home/home.component';
import { PickupsComponent } from '../dashboard/pickups/pickups.component';
import { CustomersComponent } from '../dashboard/customers/customers.component';
import { ProfileSetingsComponent } from '../dashboard/profileinfo/profile-setings/profile-setings.component';
import { TrackpickupComponent } from '../dashboard/Track_Pickup/trackpickup/trackpickup.component';
import { RequestPickupMenuComponent } from '../dashboard/Request_pickup/request-pickup-menu/request-pickup-menu.component';
import { NoPickActiveDialogComponent } from '../dashboard/Track_Pickup/no-pick-active-dialog/no-pick-active-dialog.component';
import { EdituserComponent } from '../dashboard/user/edituser/edituser.component';
import { EditUserDetailsComponent } from '../dashboard/user/editUserDetails/editUserDetails.component';
import { EditDashboardComponent } from '../dashboard/edit-dashboard/edit-dashboard.component';
import { CustomerFacilityDetailsComponent } from '../dashboard/customer-facility-details/customer-facility-details.component';
import { CustomerFacilitatorViewComponent } from '../dashboard/customer-facilitator-view/customer-facilitator-view.component';
import { MenuViewComponent } from '../dashboard/menu-view/menu-view.component';
import { CreateMenuDialogComponent } from '../dashboard/create-menu-dialog/create-menu-dialog.component';
import { CustomerViewDetailsComponent } from '../dashboard/customer-main-view/customer-main-view.component';
import { CreatePickuplistComponent } from '../dashboard/Request_pickup/create-pickuplist/create-pickuplist.component';
import { CustomerProfileSettingsComponent } from '../dashboard/profile-setting-view/profile-setting-view.component';
import { ConfirmPickupListComponent } from '../dashboard/Request_pickup/confirm-pickup-list/confirm-pickup-list.component';
import { usersAdminViewComponent } from '../dashboard/usersAdminView/usersAdminView/usersAdminView.component';
import { PickupRequestSubmissionComponent } from '../dashboard/Request_pickup/pickup-request-submission/pickup-request-submission.component';
import { PickupDetailsComponent } from '../dashboard/pickup-details/pickup-details.component';
import { SavedPickupTemplateComponent } from '../dashboard/Request_pickup/saved-pickup-template/saved-pickup-template.component';
import { EditPickupTemplateComponent } from '../dashboard/Request_pickup/edit-pickup-template/edit-pickup-template.component';
import { SavePickupListmodalComponent } from '../dashboard/Request_pickup/save-pickup-listmodal/save-pickup-listmodal.component';
import { TrackPickupListComponent } from '../dashboard/Track_Pickup/track-pickup-list/track-pickup-list.component';
import { PickupInRouteComponent } from '../dashboard/Track_Pickup/pickup-in-route/pickup-in-route.component';
import { TrackRoutePickedUpComponent } from '../dashboard/Track_Pickup/track-route-picked-up/track-route-picked-up.component';
import { ImpactComponent } from '../dashboard/impact/impact/impact.component';
import { PickupOrderCompleteComponent } from '../dashboard/Track_Pickup/pickup-order-complete/pickup-order-complete.component';
import { AuthGuard } from '../shared/services/authguard.guard';
import { AddeditDateComponent } from '../dashboard/customers/addedit-date/addedit-date.component';
import { PasswordComponent } from '../loginmodule/password-page/password-dialog/password-dialog.component';
import { GrabreportComponent } from '../dashboard/customers/grabreport/grabreport.component';
import { GenericProductsComponent } from '../dashboard/menu-view/generic-products/generic-products.component';
import { DonationRecepitsComponent } from '../dashboard/pickup-details/donation-recepits/donation-recepits.component';
import { SAASsignUpComponent } from '../SAAS/regModule/saassign-up/saassign-up.component';
import { SubscriptionComponent } from '../SAAS/regModule/subscription/subscription.component';
import { BillingComponent } from '../SAAS/regModule/billing/billing.component';
import { CheckoutComponent } from '../SAAS/regModule/checkout/checkout.component';
import { ReceiptSuccessComponent } from '../SAAS/regModule/receipt-success/receipt-success.component';
import { EditSubscriptionComponent } from '../SAAS/regModule/edit-subscription/edit-subscription.component';
import { ChangeeditSusbscriptionComponent } from '../SAAS/regModule/changeedit-susbscription/changeedit-susbscription.component';
import { UpdateCheckoutComponent } from '../SAAS/regModule/update-checkout/update-checkout.component';
import { RegistrationComponent } from '../SAAS/regModule/onetimebilling/registration/registration.component';
import { OnetimebillingcardpaymentComponent } from '../SAAS/regModule/onetimebilling/onetimebillingcardpayment/onetimebillingcardpayment.component';
import { OnetimebillingsuccessComponent } from '../SAAS/regModule/onetimebilling/onetimebillingsuccess/onetimebillingsuccess.component';
import { EditfacilityComponent } from '../SAAS/regModule/saassign-up/editfacility/editfacility.component';
import { OnetimepickuprequestComponent } from '../dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickuprequest.component';
import { OnetimepickupbillrequestComponent } from '../dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupbillrequest/onetimepickupbillrequest.component';
import { OnetimepickupbillcardpayComponent } from '../dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupbillcardpay/onetimepickupbillcardpay.component';
import { OnetimepickupbillsuccessComponent } from '../dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupbillsuccess/onetimepickupbillsuccess.component';
import { OnetimepickupregistrationComponent } from '../dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupregistration/onetimepickupregistration.component';
import { HelpScreenComponent } from '../dashboard/templates/help-screen/help-screen.component';
import { BackgroundotpComponent } from '../SAAS/regModule/onetimebilling/backgroundotp/backgroundotp.component';
import { BackgroundOTPPasswordComponent } from '../SAAS/regModule/onetimebilling/background-otppassword/background-otppassword.component';
import { ErrorpageComponent } from '../dashboard/templates/help-screen/errorpage/errorpage.component';

import { PricingModuleComponent } from '../../app/pricingModule/pricing-module/pricing-module.component';
import { SaasPricingModuleComponent } from '../../app/pricingModule/Saas/saas-pricing-module/saas-pricing-module.component';
import { OtpPricingModuleComponent } from '../../app/pricingModule/Otp/otp-pricing-module/otp-pricing-module.component';
import { PromotionCodePricingComponent } from '../pricingModule/promotionCode/promotion-code-pricing/promotion-code-pricing.component';
import { FeatureTogglesGuard } from '../shared/services/feature-toggles.guard';
import { SAASFeatureGuard } from '../shared/services/saasfeature.guard';
import { EditToggleFeatureGuard } from '../shared/services/edit-toggle-feature.guard';
import { PrcingControlFeatureGuard } from '../shared/services/prcing-control-feature.guard';
import { FeaturetoggleComponent } from '../../app/dashboard/templates/featuretoggle/featuretoggle.component';
import { BillingSectionComponent } from '../dashboard/billing-section/billing-section.component';
import { RoleGuardFeatureGuard } from '../shared/services/role-guard.service';
import { DistanceMatrixPageComponent } from '../dashboard/distance-matrix-page/distance-matrix-page.component';
import { PickupContractComponent } from '../dashboard/pickup-contract/pickup-contract.component';
import { BillingInvoicesComponent } from '../dashboard/billing-invoices/billing-invoices.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'account/activate/:id', component: BackgroundPageComponent },
  {
    path: 'RegisterEmail',
    component: BackgroundotpComponent,
    canActivate: [FeatureTogglesGuard],
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'help',
    component: HelpScreenComponent,
    canActivate: [AuthGuard],
  },
  // { path: '**', redirectTo: '/', canActivate: [AuthGuard] },
  {
    path: 'users',
    component: usersAdminViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'paymentSuccess',
    component: ReceiptSuccessComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'checkoutupdate/:id',
    component: UpdateCheckoutComponent,
    canActivate: [SAASFeatureGuard],
  },
  {
    path: 'error',
    component: ErrorpageComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [SAASFeatureGuard],
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [SAASFeatureGuard],
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    canActivate: [SAASFeatureGuard],
  },
  {
    path: 'registration',
    component: SAASsignUpComponent,
    canActivate: [SAASFeatureGuard],
  },
  {
    path: 'EditFacility',
    component: EditfacilityComponent,
  },
  {
    path: 'EditSubscription/:id',
    component: EditSubscriptionComponent,
    canActivate: [SAASFeatureGuard],
  },
  {
    path: 'ChangeeditSusbscription',
    component: ChangeeditSusbscriptionComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'PickupRegistration',
    component: RegistrationComponent,
    canActivate: [FeatureTogglesGuard],
  },
  {
    path: 'PickupBilling',
    component: OnetimebillingcardpaymentComponent,
    canActivate: [FeatureTogglesGuard],
  },
  {
    path: 'Pickupsuccess',
    component: OnetimebillingsuccessComponent,
    canActivate: [FeatureTogglesGuard],
  },
  {
    path: 'UsersCustomerView',
    component: UserComponent,
    canActivate: [AuthGuard],
  },
  { path: 'edituser', component: EdituserComponent, canActivate: [AuthGuard] },
  {
    path: 'editUserDetails',
    component: EditUserDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Customers',
    component: CustomersComponent,
    canActivate: [AuthGuard],
  },
  { path: 'Pickups', component: PickupsComponent, canActivate: [AuthGuard] },
  { path: 'Impact', component: ImpactComponent, canActivate: [AuthGuard] },
  {
    path: 'RequestPickup-menu',
    component: RequestPickupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'RequestPickup-nomenu',
    component: RequestPickupMenuComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'OneTimePickup',
    component: OnetimepickuprequestComponent,
    canActivate: [AuthGuard, FeatureTogglesGuard],
  },
  {
    path: 'OTPBRequest/:id',
    component: OnetimepickupbillrequestComponent,
    canActivate: [AuthGuard, FeatureTogglesGuard],
  },
  {
    path: 'OTBCardPay',
    component: OnetimepickupbillcardpayComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'OTPRegistration',
    component: OnetimepickupregistrationComponent,
    canActivate: [AuthGuard, FeatureTogglesGuard],
  },
  {
    path: 'OTPReqSuccess',
    component: OnetimepickupbillsuccessComponent,
    canActivate: [AuthGuard, FeatureTogglesGuard],
  },
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'profile',
    component: ProfileSetingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'trackpickup',
    component: TrackpickupComponent,
    canActivate: [AuthGuard],
  },
  { path: '', component: LoginComponent, canActivate: [AuthGuard] },
  {
    path: 'editDashboard',
    component: EditDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'FacilityDetails/:id',
    component: CustomerFacilityDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CustomerFacilitatorview/:id',
    component: CustomerFacilitatorViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'FacilityMenuView/:id',
    component: MenuViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'GenericProducts',
    component: GenericProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CustomerViewDetails/:id',
    component: CustomerViewDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CreateMenu',
    component: CreateMenuDialogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Products/:id',
    component: CreatePickuplistComponent,
    // canActivate: [AuthGuard, EditToggleFeatureGuard]
    canActivate: [AuthGuard],
  },
  {
    path: 'ConfirmPickupList',
    component: ConfirmPickupListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CustomerProfileSettings',
    component: CustomerProfileSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'PickupRequestSubmission',
    component: PickupRequestSubmissionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'SavedPickupTemplates',
    component: SavedPickupTemplateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editpickupTemplate',
    component: EditPickupTemplateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'SavePickupList',
    component: SavePickupListmodalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'PickupInRoute',
    component: PickupInRouteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'TrackPickupList/:id',
    component: TrackPickupListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'TrackRoutePickedUp/:id',
    component: TrackRoutePickedUpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pickupDetails/:id',
    component: PickupDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pickup-order-complete',
    component: PickupOrderCompleteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addeditdate',
    component: AddeditDateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'grabreport',
    component: GrabreportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Donation_Receipts_List',
    component: DonationRecepitsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pricingModule',
    component: PricingModuleComponent,
    canActivate: [AuthGuard, PrcingControlFeatureGuard],
  },
  {
    path: 'SAASPricing',
    component: SaasPricingModuleComponent,
    canActivate: [AuthGuard, PrcingControlFeatureGuard],
  },
  {
    path: 'otp_pricing_module',
    component: OtpPricingModuleComponent,
    canActivate: [AuthGuard, PrcingControlFeatureGuard],
  },
  {
    path: 'promotion_code_pricing',
    component: PromotionCodePricingComponent,
    canActivate: [AuthGuard, PrcingControlFeatureGuard],
  },
  {
    path: 'featuretoggle',
    component: FeaturetoggleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'billing-section',
    component: BillingSectionComponent,
    canActivate: [AuthGuard, RoleGuardFeatureGuard],
  }, {
    path: 'distanceMatrix',
    component: DistanceMatrixPageComponent,
    canActivate: [AuthGuard, RoleGuardFeatureGuard],
  },
  {
    path: 'pickup-contract',
    component: PickupContractComponent,
    canActivate: [AuthGuard, RoleGuardFeatureGuard],
  },
  {
    path: 'billing-invoice',
    component: BillingInvoicesComponent,
    canActivate: [AuthGuard, RoleGuardFeatureGuard],
  },


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }
