import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { UpoloadMenuDialogComponent } from '../upoload-menu-dialog/upoload-menu-dialog.component';
import { AccountcompletionMenuDialogComponent } from '../accountcompletion-menu-dialog/accountcompletion-menu-dialog.component';
import { ImportMenuDialogComponent } from '../import-menu-dialog/import-menu-dialog.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AppState } from 'src/app/shared/app-state';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SaasconfirmComponent } from 'src/app/saasconfirm/saasconfirm.component';
import { SpinnerService } from 'src/app/core/spinner.service';
import { EditMYearlyComponent } from '../customers/edit-myearly/edit-myearly.component';
import { environment } from 'src/environments/environment';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { EdituserComponent } from '../../dashboard/user/edituser/edituser.component';
@Component({
  selector: 'app-create-menu-dialog',
  templateUrl: './create-menu-dialog.component.html',
  styleUrls: ['./create-menu-dialog.component.scss'],
})
export class CreateMenuDialogComponent implements OnInit {
  modalclose: string;
  info: any;
  menuType: any;
  customId: any;
  grabId: any;
  genericId: any;
  noMenuSelectedId = null;
  clientId: any;
  sendpickupTypes = [];
  NotificationpickupTypes = [];
  menusId: any;
  generic_custom: boolean;
  buttonDisabled: boolean;
  error: never;
  infoClients: any;
  WaypointIDText: boolean;
  WayPointID: any;
  waypointForm: any;
  requireField: boolean;
  driverService: any;
  userRole: any;
  editClientID: any;
  clientData: any;
  yearlyPlan: boolean;
  fglogin = true;
  noPlaning: boolean;
  faclityCount: any;
  spinnerRef: any;
  saascreationfinal: boolean;
  approveStatus: any;
  userIddata: any;
  saascreatemenu: boolean;
  relaodinfo: any;
  customSelected: boolean = false;
  genericSelected: boolean = false;

  constructor(
    public _rdata: modalDataService,
    private AdminService: AdmintoolsService,
    private CustomerService: CustomerService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    private ClientFilterdata: ClientFilterService,
    private pricingModule: PricingModuleServiceService,
    private state: AppState,
    private formBuilder: FormBuilder,
    private sassService: SAASService,
    public dialogRef: MatDialogRef<CreateMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
    });
    if (this.state.user.User.Roles[0].Name === 'Facility Group') {
      this.fglogin = false;
    }
    this.waypointForm = this.formBuilder.group({
      waypointID: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
    });
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.infoClients = JSON.parse(res);
    });
    this.buttonDisabled = false;
    this._rdata.basicInfo2Observe.subscribe((res) => {
      this.info = JSON.parse(res);
      var sendpickupTypes = [];
      for (var i = 0; i < this.info.pickupTypes.length; i++) {
        sendpickupTypes.push({ ID: this.info.pickupTypes[i] });
      }
      this.sendpickupTypes = sendpickupTypes;

      var NotificationpickupTypes = [];
      for (var i = 0; i < this.info.NotificationTypes.length; i++) {
        NotificationpickupTypes.push({ ID: this.info.NotificationTypes[i] });
      }
      this.NotificationpickupTypes = NotificationpickupTypes;
      if (this.info.facility_type === 'Facility' || this.info.facility_type === 'SAAS Facility') {
        this.generic_custom = true;
      }
    });
    if (this.info.facility_type === 'SAAS Facility') {
      this.saascreationfinal = true;
    }
    // this.CustomerService.getPickupTypeList().subscribe(data => {
    //   this.driverService = data.Result;
    //   if (this.state.user.User.Roles[0].Name === 'Facility Group') {
    //     this.approveStatus = 2;
    //   } else {
    // if (this.driverService) {
    //   for (const status of this.driverService) {
    //     if (this.info.driverService === status.ID) {
    //       if (status.Title === 'SAAS') {
    //         this.saascreationfinal = true;
    //         if (status.Title === 'SAAS' && !this.info.FacilityGroupId) {
    //           this.approveStatus = 1;
    //         } else {
    //           this.approveStatus = 2;
    //         }
    //       } else {
    //         this.approveStatus = 2;
    //       }
    //     }
    //   }
    // } else {
    //   this.approveStatus = 2;
    // }
    // }
    // });
    // this.createAccount();
  }
  // updateGrab(){
  //
  //
  //   this.CustomerService.updateGrab(this.info.accountName).subscribe(data => {
  //     // this.buttonDisabled = true;
  //     // {
  //     //   this.dialogRef.close();
  //     //   this.state.isSpinnerVisible = false;
  //     // }
  //   },
  //   error => {
  //     if (error.statusText === 'Internal Server Error') {
  //   }
  // }
  //   );
  // }
  createAccountGrab(type) {
    if (type === 'Grab') {
      this.WaypointIDText = true;
    }
  }
  createAccountFinal(type) {
    this.CustomerService.getMenuTypes().subscribe((data) => {
      const menus = data.Result;
      for (const menu of menus) {
        if (menu.Title === 'Generic') {
          this.genericId = menu.ID;
        } else if (menu.Title === 'Grab') {
          this.grabId = menu.ID;
        } else {
          this.customId = menu.ID;
        }
        this.menuType = menu;
      }
      this.createAccount(type);
    });
  }
  createAccount(type) {
    if (type === 'Custom') {
      this.menusId = this.customId;
      this.createClient(type);
    } else if (type === 'Generic') {
      this.menusId = this.genericId;
      this.createClient(type);
    } else if (type === 'Grab') {
      this.menusId = this.grabId;
      if (this.WayPointID) {
        this.createClient(type);
        this.grabData();
      } else {
        this.requireField = true;
        setTimeout(() => {
          this.requireField = false;
        }, 3000);
      }
    } else {
      this.menusId = this.noMenuSelectedId;
      this.createClient(type);
    }
  }
  createClient(type) {
    if (this.state.user.User.Roles[0].Name === 'Facility Group') {
      if (this.state.user.User.Clients[0].DriverService === 'SAAS') {
        this.editClientID = this.state.user['FacilityGroupId'];
      }
    }
    if (this.state.user.User.Roles[0].Name === 'Facility') {
      if (this.state.user.User.Clients[0].DriverService === 'SAAS') {
        this.editClientID = this.state.user.User.Clients[0].ClientId;
      }
    }
    this.userRole = this.state.user.User.Roles[0].Name;
    if (this.userRole === 'Facility Group') {
      this.sassService.getSubDetails(this.editClientID).subscribe((data) => {
        this.clientData = data.Result[0];
        let saasprice;
        if (this.clientData.StripePlanId) {
          this.noPlaning = false;
          this.pricingModule.getsubscriptionSAAS(this.clientData.StripePlanId).subscribe((data) => {
            let price;
            price = data['Result'];
            for (const status of price) {
              if (this.clientData.StripePlanId === status.StripePlanId) {
                if (status.SubscriptionType === 'month') {
                  saasprice = status.Price;
                  this.yearlyPlan = false;
                } else {
                  saasprice = status.Price;
                  this.yearlyPlan = true;
                }
              }
            }
          });
          // if (this.clientData.StripePlanId === 'price_1H2W2rICi4RDu2OllIjS8nqt') {
          // if (this.clientData.StripePlanId === environment.yearlyKey) {
          //   this.yearlyPlan = true;
          // } else {
          //   this.yearlyPlan = false;
          // }
          // } else {
          //   this.noPlaning = true;
          // }
          this.CustomerService.getAllFilteredCustomers(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.editClientID,
            null,
            null,
            null,
            null
          ).subscribe((data) => {
            if (data.Result.length > 0) {
              data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
              this.faclityCount = data.Result.length;
              // this.multipleFGdata = data.Result;
              this.spinnerService.stop(this.spinnerRef);
              this.dialogRef.close();
              const dialogRef = this.dialog.open(SaasconfirmComponent, {
                width: '500px',
                // height: '440px',
                data: [{ faclityCount: this.faclityCount }, { yearlyPlan: this.yearlyPlan }, { active: '' }, { type: '' }, { saasPrice: saasprice }],
                panelClass: 'custom-editSub-container',
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.spinnerRef = this.spinnerService.start();
                  this.finalcreation(type);
                }
              });
            }
          });
        } else {
          this.noPlaning = true;
          this.finalcreation(type);
        }
      });
    } else {
      if (this.saascreationfinal === true && this.info.FacilityGroupId) {
        this.spinnerRef = this.spinnerService.start();
        let saasprice;
        this.sassService.getSubDetails(this.info.FacilityGroupId).subscribe((data) => {
          this.clientData = data.Result[0];
          if (this.clientData.StripePlanId) {
            this.noPlaning = false;
            // if (this.clientData.StripePlanId === 'price_1H2W2rICi4RDu2OllIjS8nqt') {
            this.pricingModule.getsubscriptionSAAS(this.clientData.StripePlanId).subscribe((data) => {
              let price;
              price = data['Result'];
              for (const status of price) {
                if (this.clientData.StripePlanId === status.StripePlanId) {
                  if (status.SubscriptionType === 'month') {
                    saasprice = status.Price;
                    this.yearlyPlan = false;
                  } else {
                    saasprice = status.Price;
                    this.yearlyPlan = true;
                  }
                }
              }
            });
            // if (this.clientData.StripePlanId === environment.yearlyKey) {
            //   this.yearlyPlan = true;
            // } else {
            //   this.yearlyPlan = false;
            // }
          }
          this.CustomerService.getAllFilteredCustomers(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.info.FacilityGroupId,
            null,
            null,
            null,
            null
          ).subscribe((data) => {
            if (data.Result.length > 0) {
              data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
              this.faclityCount = data.Result.length;
              // this.multipleFGdata = data.Result;
              this.spinnerService.stop(this.spinnerRef);
              this.dialogRef.close();
              const dialogRef = this.dialog.open(SaasconfirmComponent, {
                width: '500px',
                // height: '440px',
                data: [{ faclityCount: this.faclityCount }, { yearlyPlan: this.yearlyPlan }, { active: '' }, { type: '' }, { saasPrice: saasprice }],
                panelClass: 'custom-editSub-container',
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.spinnerRef = this.spinnerService.start();
                  this.finalcreation(type);
                }
              });
            }
          });
        });
      } else {
        this.finalcreation(type);
      }
    }
  }
  finalcreation(type) {
    if (type === 'Custom' && this.saascreationfinal === true && !this.info.FacilityGroupId) {
      this.dialogRef.close();
      this.saascreatemenu = true;
      this.sasscreation(type);
    } else if (type === 'Generic' && this.saascreationfinal === true && !this.info.FacilityGroupId) {
      this.dialogRef.close();
      this.sasscreation(type);
    } else if (this.saascreationfinal === true && !this.info.FacilityGroupId) {
      this.dialogRef.close();
      this.sasscreation(type);
    } else {
      let menusId;
      if (type === 'Custom') {
        menusId = null;
      } else {
        menusId = this.menusId;
      }

      if (type === 'Custom') {
        this.gotoImportMenuDialog('new');
      } else {
        this.spinnerRef = this.spinnerService.start();
        let payload = {
          Name: this.info.accountName,
          EINNumber: this.info.taxId,
          Web: '',
          Address: this.info.businessAddress,
          PickupType: this.sendpickupTypes,
          Address2: this.info.address,
          // City: this.info.city,
          // State: this.info.state,
          CityId: this.info.city,
          StateId: this.info.state,
          ZIP: this.info.zip,
          Country: this.info.country,
          Phone: this.info.PhoneNumber.replace(/[_-]/g, ''),
          Email: this.info.email,
          HasFridgeStorage: false,
          NumberOfPeopleServed: 1,
          WasteExpenditure: false,
          ClientTypeId: 1,
          ApprovalStatusId: 2,
          WayPointID: this.WayPointID,
          WP_Client_Id: 0,
          MenuEdit: this.info.isMenuEdit,
          TimeZone: this.info.timezone,
          InviteUser: this.info.facilityAccess,
          MenuTypeId: menusId,
          ClientCategories: [
            {
              ClientCategoryId: this.info.account_type,
            },
          ],
          DriverServices: [
            {
              ID: this.info.driverService,
            },
          ],
          DriverNotes: this.info.driverNotes,
          User_Client: [],
          NotificationTypes: this.NotificationpickupTypes,
          SAASDriverId: this.info.selectedDriver,
          FacilitatorId: this.info.FacilitatorId,
          FacilityGroupId: this.info.FacilityGroupId,
          AllowSchedulePickup: this.info.scheduler,
        };
        this.buttonDisabled = true;
        this.CustomerService.createAccount(payload).subscribe((data) => {
          
          this.spinnerService.stop(this.spinnerRef);
          let localUser = JSON.parse(localStorage.getItem('user'));
          localUser.user.User.Clients.push(data['Result'][0]);
          localStorage.setItem('user', JSON.stringify(localUser));
          this.state.user = JSON.parse(localStorage.getItem('user')).user;
          this.infoClients.facilitator = '';
          this.infoClients.facilitatorGroup = '';
          this.infoClients.facility = '';
          this.infoClients.nonprofits = '';
          this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.infoClients));

          if (this.clientData) {
            if (this.state.user.User.Roles[0].Name === 'Facility Group') {
              if (this.clientData.StripePlanId) {
                if (this.info.facility_type === 'SAAS Facility') {
                  this.saascreationfinal = true;
                  const payloadStripe = {
                    QuantityChange: true,
                    Update: true,
                    ClientId: this.info.FacilityGroupId,
                    UserId: this.state.user.User.UserId,
                  };
                  this.state.user = JSON.parse(localStorage.getItem('user')).user;
                  this.sassService.striping(payloadStripe).subscribe((data) => {});
                  // }
                }
                // if (this.driverService) {
                //   for (const status of this.driverService) {
                //     if (this.info.driverService === status.ID) {
                //       if (status.Title === 'SAAS') {
                //         this.saascreationfinal = true;
                //         const payloadStripe = {
                //           QuantityChange: true,
                //           Update: true,
                //           ClientId: this.info.FacilityGroupId,
                //           UserId: this.state.user.User.UserId
                //         };
                //         this.state.user = JSON.parse(
                //           localStorage.getItem('user')
                //         ).user;
                //         this.sassService
                //           .striping(payloadStripe)
                //           .subscribe(data => {});
                //       }
                //     }
                //   }
                // }
              }
            }
          }
          this.relaodinfo.reloadclientdata = true;
          this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
          this.clientId = data['Result'][0].ClientId;
          const payload1 = {
            FirstName: this.info.adminFirstName,
            LastName: this.info.adminLastName,
            Email: this.info.adminEmail,
            OnClientCreation: true,
            PhoneNumber: this.info.Phone.replace(/[_-]/g, ''),
            Clients: [
              {
                ClientId: this.clientId,
              },
            ],
            RoleId: this.info.roleId,
          };
          
          if (this.info.existingUser) {
            const payload1 = {
              ClientId: this.clientId,
              Id: this.info.existingUser
            };

            this.AdminService.updateUser(payload1).subscribe((response) => {
              if ('error' in response) {
              this.spinnerService.stop(this.spinnerRef);
              this.buttonDisabled = false;
              // this.error = response['message'];
            } else {
              this.dialogRef.close();
              this.userIddata = this.info.existingUser;
              if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                const payloadStripe = {
                  QuantityChange: true,
                  Update: true,
                  ClientId: this.info.FacilityGroupId,
                  // UserId: this.userIddata
                };
                this.sassService.striping(payloadStripe).subscribe((data) => {});
              }

              if (type === 'Custom') {
                if (this.saascreationfinal !== true) {
                  this.gotoImportMenuDialog(this.clientId);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.gotoImportMenuDialog(this.clientId);
                }
              } else if (type === 'Generic') {
                this.buttonDisabled = true;
                if (this.userRole === 'Facility Group') {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal !== true) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              } else {
                this.buttonDisabled = true;

                if (this.userRole === 'Facility Group') {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              }
              // this.state.isSpinnerVisible = false;
            }
            });

            return;
          }

          if (this.info.existingUser) {
            const payload1 = {
              ClientId: this.clientId,
              Id: this.info.existingUser
            };

            this.AdminService.updateUser(payload1).subscribe((response) => {
              if ('error' in response) {
              this.spinnerService.stop(this.spinnerRef);
              this.buttonDisabled = false;
              // this.error = response['message'];
            } else {
              this.dialogRef.close();
              this.userIddata = this.info.existingUser;
              if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                const payloadStripe = {
                  QuantityChange: true,
                  Update: true,
                  ClientId: this.info.FacilityGroupId,
                  // UserId: this.userIddata
                };
                this.sassService.striping(payloadStripe).subscribe((data) => {});
              }

              if (type === 'Custom') {
                if (this.saascreationfinal !== true) {
                  this.gotoImportMenuDialog(this.clientId);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.gotoImportMenuDialog(this.clientId);
                }
              } else if (type === 'Generic') {
                this.buttonDisabled = true;
                if (this.userRole === 'Facility Group') {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal !== true) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              } else {
                this.buttonDisabled = true;

                if (this.userRole === 'Facility Group') {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              }
              // this.state.isSpinnerVisible = false;
            }
            });

            return;
          }

          this.AdminService.createUser(payload1).subscribe((response) => {
            if ('error' in response) {
              this.spinnerService.stop(this.spinnerRef);
              this.buttonDisabled = false;
              // this.error = response['message'];
            } else {
              this.dialogRef.close();
              this.userIddata = response['UserId'];
              if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                const payloadStripe = {
                  QuantityChange: true,
                  Update: true,
                  ClientId: this.info.FacilityGroupId,
                  // UserId: this.userIddata
                };
                this.sassService.striping(payloadStripe).subscribe((data) => {});
              }

              if (type === 'Custom') {
                if (this.saascreationfinal !== true) {
                  this.gotoImportMenuDialog(this.clientId);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.gotoImportMenuDialog(this.clientId);
                }
              } else if (type === 'Generic') {
                this.buttonDisabled = true;
                if (this.userRole === 'Facility Group') {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal !== true) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              } else {
                this.buttonDisabled = true;

                if (this.userRole === 'Facility Group') {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                } else {
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              }
              // this.state.isSpinnerVisible = false;
            }
          });
          // this._rdata.resetBasicInfo2(JSON.stringify(this.info));
        });
        this._rdata.updateBasicInfo2(JSON.stringify(this.info));
      }
    }
  }
  sasscreation(type) {
    // this.info.accountTypetrue = true;
    // this.info.clientId = this.clientId;
    // this.info.userId = this.userIddata;
    this.info.saascreatemenu = this.saascreatemenu;
    this.info.menutype = type;
    this.info.WayPointID = this.WayPointID;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(EditMYearlyComponent, {
      width: '700px',
      height: '690px',
      panelClass: 'custom-editdialog-container',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((selection) => {});
  }
  grabData() {
    let grabdata = {
      Name: this.info.accountName,
      waypointID: this.WayPointID,
    };
    this.AdminService.grabmenucreation(grabdata).subscribe((data) => {});
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  gotoImportMenuDialog(id) {
    // this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    // this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    const dialogRef = this.dialog.open(ImportMenuDialogComponent, {
      width: '700px',
      // height: '450px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: {
        clientId: id,
        importstatus: this.data['importstatus'],
        menuId: this.menusId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    this.dialogRef.close(this.modalclose);
  }

  accountcompletion(id, facility) {
    this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(AccountcompletionMenuDialogComponent, {
      width: '550px',
      height: '550px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: [
        {
          clientId: id,
        },
        {
          type: facility,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  selectDiv(type) {
    if (type === 'Generic') {
      this.genericSelected = true;
      this.customSelected = false;
    } else {
      this.customSelected = true;
      this.genericSelected = false;
    }
  }

  selectMenu() {
    if (this.genericSelected === true) {
      this.createAccountFinal('Generic');
    } else if (this.customSelected) {
      this.createAccountFinal('Custom');
    } else {
    }
  }

  backToEditUser() {
    this.dialogRef.close(this.modalclose);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(EdituserComponent, {
      width: '1220px',
      height: '785px',
      // disableClose: true,
      // height: "530px",this.facility_type
      panelClass: 'user-details-dialog',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
