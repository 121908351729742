import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { Router } from '@angular/router';
import { SAASService } from 'src/app/shared/services/saas.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { SpinnerService } from 'src/app/core/spinner.service';
import { MatDialog } from '@angular/material';
import { SuccesPopupComponent } from '../succes-popup/succes-popup.component';
import { EditfacilityComponent } from './editfacility/editfacility.component';
@Component({
  selector: 'app-saassign-up',
  templateUrl: './saassign-up.component.html',
  styleUrls: ['./saassign-up.component.scss'],
})
export class SAASsignUpComponent implements OnInit {
  pickupTypeID = [];
  pickupTypeIDFG = [];
  PickupTypeS = [
    {
      ID: 1,
      Title: 'Food',
    },
    {
      ID: 2,
      Title: 'Organic Recycling',
    },
  ];
  allDeliveryStatus = [
    {
      ID: 1,
      Title: 'SAAS Facility',
    },
    {
      ID: 2,
      Title: 'SAAS Facility Group',
    },
  ];
  public numberForm: FormGroup;
  CompanyTitle: any;
  EmailFG: any;
  taxForm: any;
  FirstName: any;
  LastName: any;
  selectedType: any;
  Email: any;
  i = 0;
  PhoneNumber: any;
  PhoneNumberFG: any;
  TaxID: any;
  TaxIDFG: any;
  Address1: any;
  index: any;
  Address2FG: any;
  Address1FG: any;
  Address2: any;
  State: any;
  StateFG: any;
  City: any;
  ZipCode: any;
  menuEdit = true;
  menuEditFG = true;
  sendpickupTypes: any[];
  requireFirstName: boolean;
  requireLastName: boolean;
  requireCompanyTitle: boolean;
  requireselectedType: boolean;
  requireEmail: boolean;
  checkselectedType = false;
  requirePhoneNumber: boolean;
  multipleFGadd = [];
  facilityFGadd = [];
  requireTaxID: boolean;
  requireAddress1: boolean;
  requireState: boolean;
  requireCity: boolean;
  requireZipCode: boolean;
  invalidEmail: boolean;
  Country = 'USA';
  requireCountry: boolean;
  dataerror: string;
  NotVisibileFG: boolean = true;
  VisibleForFG: boolean;
  facility: boolean = true;
  facilitygroup: boolean;
  CompanyTitleFG: any;
  CityFG: any;
  ZipCodeFG: any;
  CountryFG = 'USA';
  fcailityValidation: boolean;
  multipleFGaddFinal: any[];
  requireField: boolean;
  menueditble = true;
  menueditbleFG = true;
  dataerrortrue = false;
  public numberFormZip: FormGroup;
  citydataID: any;
  pickupstatesdata: any;
  citydataFGID: any;
  storedata: any;

  constructor(
    private customerServices: CustomerService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private sassService: SAASService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('saasFG'))) {
      this.multipleFGadd = JSON.parse(
        localStorage.getItem('multiplefacilities')
      );
      this.NotVisibileFG = false;
      this.VisibleForFG = true;
      this.storedata = JSON.parse(localStorage.getItem('saasFG'));
      this.CompanyTitle = this.storedata.CompanyTitle;
      this.FirstName = this.storedata.FirstName;
      this.LastName = this.storedata.LastName;
      this.Email = this.storedata.Email;
      this.TaxID = this.storedata.TaxID;
      this.PhoneNumber = this.storedata.PhoneNumber;
      this.Address1 = this.storedata.Address1;
      this.Address2 = this.storedata.Address2;
      this.State = this.storedata.StateId;
      this.ZipCode = this.storedata.ZipCode;
      this.menuEdit = this.storedata.MenuEdit;

      if (this.storedata.Account === 'SAAS Facility') {
        this.checkselectedType = false;
      } else {
        this.checkselectedType = true;
      }
      this.pickupfgstates();
      this.selectedfgstorestate();
      if (this.multipleFGadd[0].PickupType.length > 0) {
        for (const status of this.PickupTypeS) {
          for (const status1 of this.multipleFGadd[0].PickupType) {
            if (status.Title === status1.Title) {
              status['checkedId'] = true;
            }
          }
        }
        var sendpickupTypes = [];
        for (var i = 0; i < this.multipleFGadd[0].PickupType.length; i++) {
          sendpickupTypes.push(this.multipleFGadd[0].PickupType[i].Title);
        }
        this.pickupTypeIDFG = sendpickupTypes;
      } else {
        for (const status of this.PickupTypeS) {
          if (status.Title === 'Food') {
            this.pickupTypeIDFG.push(status.Title);
          }
        }
        for (var j = 0; j < this.PickupTypeS.length; j++) {
          if (this.pickupTypeIDFG[0] === this.PickupTypeS[j].Title) {
            this.PickupTypeS[j]['checkedId'] = true;
          }
        }
      }
      this.keyPressFirstName(this.Email);
    } else if (
      JSON.parse(localStorage.getItem('multiplefacilities')) &&
      JSON.parse(localStorage.getItem('multiplefacilities')).length > 0
    ) {
      this.storedata = JSON.parse(localStorage.getItem('multiplefacilities'));
      this.NotVisibileFG = true;
      this.VisibleForFG = false;
      this.CompanyTitle = this.storedata[0].CompanyTitle;
      this.FirstName = this.storedata[0].FirstName;
      this.LastName = this.storedata[0].LastName;
      this.Email = this.storedata[0].Email;
      this.TaxID = this.storedata[0].TaxID;
      this.PhoneNumber = this.storedata[0].PhoneNumber;
      this.Address1 = this.storedata[0].Address1;
      this.Address2 = this.storedata[0].Address2;
      this.State = this.storedata[0].StateId;
      this.ZipCode = this.storedata[0].ZipCode;
      this.menuEdit = this.storedata[0].MenuEdit;
      this.keyPressFirstName(this.Email);
      if (this.storedata[0].Account === 'SAAS Facility') {
        this.checkselectedType = false;
      } else {
        this.checkselectedType = true;
      }
      this.pickupstates();
      this.selectedstorestate();
      if (this.storedata[0].PickupType.length > 0) {
        for (const status of this.PickupTypeS) {
          for (const status1 of this.storedata[0].PickupType) {
            if (status.Title === status1.Title) {
              status['checkedId'] = true;
            }
          }
        }
        var sendpickupTypes = [];
        for (var i = 0; i < this.storedata[0].PickupType.length; i++) {
          sendpickupTypes.push(this.storedata[0].PickupType[i].Title);
        }
        this.pickupTypeID = sendpickupTypes;
        this.pickupTypeIDFG = sendpickupTypes;
      } else {
        for (const status of this.PickupTypeS) {
          if (status.Title === 'Food') {
            this.pickupTypeID.push(status.Title);
          }
        }
        for (var j = 0; j < this.PickupTypeS.length; j++) {
          if (this.pickupTypeID[0] === this.PickupTypeS[j].Title) {
            this.PickupTypeS[j]['checkedId'] = true;
          }
        }
        for (const status of this.PickupTypeS) {
          if (status.Title === 'Food') {
            this.pickupTypeIDFG.push(status.Title);
          }
        }
        for (var j = 0; j < this.PickupTypeS.length; j++) {
          if (this.pickupTypeIDFG[0] === this.PickupTypeS[j].Title) {
            this.PickupTypeS[j]['checkedId'] = true;
          }
        }
      }
    } else {
      this.pickupstates();
      for (const status of this.PickupTypeS) {
        if (status.Title === 'Food') {
          this.pickupTypeID.push(status.Title);
        }
      }
      for (var j = 0; j < this.PickupTypeS.length; j++) {
        if (this.pickupTypeID[0] === this.PickupTypeS[j].Title) {
          this.PickupTypeS[j]['checkedId'] = true;
        }
      }
      for (const status of this.PickupTypeS) {
        if (status.Title === 'Food') {
          this.pickupTypeIDFG.push(status.Title);
        }
      }
      for (var j = 0; j < this.PickupTypeS.length; j++) {
        if (this.pickupTypeIDFG[0] === this.PickupTypeS[j].Title) {
          this.PickupTypeS[j]['checkedId'] = true;
        }
      }
    }
    this.numberForm = this.formBuilder.group({
      number: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
    this.numberFormZip = this.formBuilder.group({
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
        ]),
      ],
    });
    this.taxForm = this.formBuilder.group({
      tax: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
    if (this.checkselectedType === false) {
      this.selectedType = 'SAAS Facility';
    } else {
      this.selectedType = 'SAAS Facility Group';
    }
    // this.customerServices.CompostPickupTypeList().subscribe(
    //   data => {
    // this.PickupTypeS = data.Result;
    // for (const status of this.PickupTypeS) {
    //   if (status.Title === 'Food') {
    //     this.pickupTypeIDFG.push(status.Title);
    //   }
    // }
    // for (var j = 0; j < this.PickupTypeS.length; j++) {
    //   if (this.pickupTypeIDFG[0] === this.PickupTypeS[j].Title) {
    //     this.PickupTypeS[j]['checkedId'] = true;
    //   }
    // }
    //   },
    //   error => {
    //     // this.state.isSpinnerVisible = false;
    //     // alert(JSON.stringify(error));
    //   }
    // );
  }
  // goToSucessReg() {
  //   this.router.navigate(["Subscription"]);
  // }
  keyPressZipCode(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  onCheckboxChagen(event, value) {
    if (event.checked) {
      this.pickupTypeID.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.pickupTypeID.indexOf(value);
      if (index > -1) {
        this.pickupTypeID.splice(index, 1);
      }
    }
    let count = 0;
    for (var j = 0; j < this.PickupTypeS.length; j++) {
      for (var i = 0; i < this.pickupTypeID.length; i++) {
        if (this.pickupTypeID[j] === 'Food') {
          count++;
        } else {
        }
      }
    }
    if (count !== 0) {
      this.menueditble = true;
    } else {
      this.menueditble = false;
      this.menuEdit = true;
    }
  }
  onCheckboxChagenFG(event, value) {
    if (event.checked) {
      this.pickupTypeIDFG.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.pickupTypeIDFG.indexOf(value);
      if (index > -1) {
        this.pickupTypeIDFG.splice(index, 1);
      }
    }
    let count = 0;
    for (var j = 0; j < this.PickupTypeS.length; j++) {
      for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
        if (this.pickupTypeIDFG[j] === 'Food') {
          count++;
        } else {
        }
      }
    }
    for (var j = 0; j < this.multipleFGadd.length; j++) {
      // let pickuptype = [];
      const pickuptype = [];
      for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
        pickuptype.push({ Title: this.pickupTypeIDFG[i] });
      }
      this.multipleFGadd[j].PickupType = pickuptype;
    }
    if (count !== 0) {
      this.menueditbleFG = true;
    } else {
      this.menueditbleFG = false;
      this.menuEditFG = true;
    }
  }
  checkValue() {}
  keyPressFirstName(event: any) {
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateEmail: true,
        Email: event,
        SAASClient: '',
      };
      this.sassService.reigster(body1).subscribe((data) => {
        if (
          data.message ===
          'Field requires a unique email id and this email id has already been used'
        ) {
          this.dataerrortrue = true;
          this.dataerror = data.message;
        } else {
          this.dataerrortrue = false;
        }
      });
    } else {
      this.dataerrortrue = false;
    }
  }
  // email-validation
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.Email) === false) {
      return false;
    }
    return true;
  }
  validateEmailFG() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.EmailFG) === false) {
      return false;
    }
    return true;
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  selectedTypeValue() {
    if (this.checkselectedType === false) {
      this.pickupTypeID = [];
      this.selectedType = 'SAAS Facility';
      this.NotVisibileFG = true;
      this.VisibleForFG = false;
      for (const status of this.PickupTypeS) {
        if (status.Title === 'Food') {
          this.pickupTypeID.push(status.Title);
        }
      }
      for (var j = 0; j < this.PickupTypeS.length; j++) {
        if (this.pickupTypeID[0] === this.PickupTypeS[j].Title) {
          this.PickupTypeS[j]['checkedId'] = true;
        } else {
          this.PickupTypeS[j]['checkedId'] = false;
        }
      }
      this.menuEdit = true;
    } else {
      this.pickupTypeIDFG = [];
      this.selectedType = 'SAAS Facility Group';
      this.NotVisibileFG = false;
      this.VisibleForFG = true;
      for (const status of this.PickupTypeS) {
        if (status.Title === 'Food') {
          this.pickupTypeIDFG.push(status.Title);
        }
      }
      for (var j = 0; j < this.PickupTypeS.length; j++) {
        if (this.pickupTypeIDFG[0] === this.PickupTypeS[j].Title) {
          this.PickupTypeS[j]['checkedId'] = true;
        } else {
          this.PickupTypeS[j]['checkedId'] = false;
        }
      }
      for (var j = 0; j < this.multipleFGadd.length; j++) {
        // let pickuptype = [];
        const pickuptype = [];
        for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
          pickuptype.push({ Title: this.pickupTypeIDFG[i] });
        }
        this.multipleFGadd[j].PickupType = pickuptype;
      }
      this.menuEdit = true;
    }
  }
  AddFacility() {
    // this.pickupTypeIDFG = [];
    this.facilitygroup = true;
    this.facility = false;
    let count = 0;
    for (var j = 0; j < this.PickupTypeS.length; j++) {
      for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
        if (this.PickupTypeS[j].Title === this.pickupTypeIDFG[i].Title) {
          this.pickupTypeIDFG.push(this.pickupTypeIDFG[i].Title);
          this.PickupTypeS[j]['checkedId'] = true;
        }
        if (
          this.PickupTypeS[j].Title === 'Food' &&
          this.PickupTypeS[j]['checkedId'] == true
        ) {
          count++;
        } else {
        }
      }
      if (count !== 0) {
        this.menueditbleFG = true;
      } else {
        this.menueditbleFG = false;
        this.menuEditFG = true;
      }
    }
    // for (const status of this.PickupTypeS) {
    //   if (status.Title === 'Food') {
    //     this.pickupTypeIDFG.push(status.Title);
    //   }
    // }
    // for (var j = 0; j < this.PickupTypeS.length; j++) {
    //   if (this.pickupTypeIDFG[0] === this.PickupTypeS[j].Title) {
    //     this.PickupTypeS[j]['checkedId'] = true;
    //   }
    //   else {
    //     this.PickupTypeS[j]['checkedId'] = false;
    //   }
    // }
  }
  closefacility() {
    this.pickupTypeID = [];
    this.facility = true;
    this.facilitygroup = false;
    // for (const status of this.PickupTypeS) {
    //   if (status.Title === 'Food') {
    //     this.pickupTypeID.push(status.Title);
    //   }
    // }
    // for (var j = 0; j < this.PickupTypeS.length; j++) {
    //   if (this.pickupTypeID[0] === this.PickupTypeS[j].Title) {
    //     this.PickupTypeS[j]['checkedId'] = true;
    //   } else {
    //     this.PickupTypeS[j]['checkedId'] = false;
    //   }
    // }
  }
  goTofacilitygroup() {
    if (this.PhoneNumber) {
      this.numberForm.controls.number.setValue(numberSetMask(this.PhoneNumber));
    }
    if (
      this.CompanyTitle &&
      this.CompanyTitle !== undefined &&
      this.CompanyTitle !== null
    ) {
      if (
        this.FirstName &&
        this.FirstName !== undefined &&
        this.FirstName !== null
      ) {
        if (
          this.LastName &&
          this.LastName !== undefined &&
          this.LastName !== null
        ) {
          if (
            this.selectedType &&
            this.selectedType !== undefined &&
            this.selectedType !== null
          ) {
            if (this.Email && this.Email !== undefined && this.Email !== null) {
              if (this.validateEmail() === true) {
                // if (this.PhoneNumber && this.PhoneNumber !== undefined && this.PhoneNumber !== null) {
                if (this.numberForm.valid) {
                  if (
                    this.TaxID &&
                    this.TaxID !== undefined &&
                    this.TaxID !== null
                  ) {
                    if (
                      this.Address1 &&
                      this.Address1 !== undefined &&
                      this.Address1 !== null
                    ) {
                      if (
                        this.State &&
                        this.State !== undefined &&
                        this.State !== null
                      ) {
                        if (
                          this.City &&
                          this.City !== undefined &&
                          this.City !== null &&
                          this.City !== 'Select City'
                        ) {
                          // if (this.ZipCode && this.ZipCode !== undefined && this.ZipCode !== null) {
                          if (this.numberFormZip.valid) {
                            if (
                              this.Country &&
                              this.Country !== undefined &&
                              this.Country !== null
                            ) {
                              if (this.pickupTypeIDFG[0]) {
                                if (this.dataerrortrue === false) {
                                  const body = {
                                    CompanyTitle: this.CompanyTitle,
                                    FirstName: this.FirstName,
                                    LastName: this.LastName,
                                    Account: this.selectedType,
                                    Email: this.Email,
                                    PhoneNumber: this.PhoneNumber,
                                    TaxID: this.TaxID,
                                    Country: this.Country,
                                    Address1: this.Address1,
                                    Address2: this.Address2,
                                    StateId: this.State,
                                    CityId: this.City,
                                    ZipCode: this.ZipCode,
                                  };
                                  if (this.multipleFGadd.length > 0) {
                                    localStorage.setItem(
                                      'multiplefacilities',
                                      JSON.stringify(this.multipleFGadd)
                                    );
                                    localStorage.setItem(
                                      'saasFG',
                                      JSON.stringify(body)
                                    );
                                    this.router.navigate(['subscription']);
                                    // var spinnerRef = this.spinnerService.start();
                                    // this.sassService.reigster(body1).subscribe(
                                    //   data => {
                                    //     // alert(data.message)
                                    //     if (data.message === 'User already exist') {
                                    //       this.dataerrortrue = true;
                                    //       this.dataerror = 'Field requires a unique email id and this email id has already been used';
                                    //       // alert('This email already exists');
                                    //     } else if (data.message) {
                                    //       alert('Some error occurred.');
                                    //     } else {
                                    //       // localStorage.setItem('UserId', JSON.stringify(data.UserId));
                                    //       // localStorage.setItem('ClientId', JSON.stringify(data.ClientId));
                                    //       // localStorage.setItem('NOofFacility', JSON.stringify(data.FacilityCount));
                                    //       this.router.navigate(['subscription']);
                                    //     }
                                    //     this.spinnerService.stop(spinnerRef);
                                    //     // this.performLoginTask(data)
                                    //   },
                                    //   error => {
                                    //     this.spinnerService.stop(spinnerRef);
                                    //     // this.buttonDisabled = false;
                                    //   }
                                    // );
                                  } else {
                                    this.fcailityValidation = true;
                                    setTimeout(() => {
                                      this.fcailityValidation = false;
                                    }, 3000);
                                  }
                                } else {
                                  this.dataerror =
                                    'Field requires a unique email id and this email id has already been used';
                                }
                              } else {
                                this.requireField = true;
                                setTimeout(() => {
                                  this.requireField = false;
                                }, 3000);
                              }
                            } else {
                              this.requireCountry = true;
                              setTimeout(() => {
                                this.requireCountry = false;
                              }, 3000);
                            }
                          } else {
                            this.requireZipCode = true;
                            setTimeout(() => {
                              this.requireZipCode = false;
                            }, 3000);
                          }
                        } else {
                          this.requireCity = true;
                          setTimeout(() => {
                            this.requireCity = false;
                          }, 3000);
                        }
                      } else {
                        this.requireState = true;
                        setTimeout(() => {
                          this.requireState = false;
                        }, 3000);
                      }
                    } else {
                      this.requireAddress1 = true;
                      setTimeout(() => {
                        this.requireAddress1 = false;
                      }, 3000);
                    }
                  } else {
                    this.requireTaxID = true;
                    setTimeout(() => {
                      this.requireTaxID = false;
                    }, 3000);
                  }
                } else {
                  this.requirePhoneNumber = true;
                  setTimeout(() => {
                    this.requirePhoneNumber = false;
                  }, 3000);
                }
              } else {
                this.invalidEmail = true;
                setTimeout(() => {
                  this.invalidEmail = false;
                }, 3000);
              }
            } else {
              this.requireEmail = true;
              setTimeout(() => {
                this.requireEmail = false;
              }, 3000);
            }
          } else {
            this.requireselectedType = true;
            setTimeout(() => {
              this.requireselectedType = false;
            }, 3000);
          }
        } else {
          this.requireLastName = true;
          setTimeout(() => {
            this.requireLastName = false;
          }, 3000);
        }
      } else {
        this.requireFirstName = true;
        setTimeout(() => {
          this.requireFirstName = false;
        }, 3000);
      }
    } else {
      this.requireCompanyTitle = true;
      setTimeout(() => {
        this.requireCompanyTitle = false;
      }, 3000);
    }
  }
  goToSucessReg() {
    const sendpickupTypes = [];
    for (var i = 0; i < this.pickupTypeID.length; i++) {
      sendpickupTypes.push({ Title: this.pickupTypeID[i] });
    }
    if (this.PhoneNumber) {
      this.numberForm.controls.number.setValue(numberSetMask(this.PhoneNumber));
    }
    this.sendpickupTypes = sendpickupTypes;
    if (
      this.CompanyTitle &&
      this.CompanyTitle !== undefined &&
      this.CompanyTitle !== null
    ) {
      if (
        this.FirstName &&
        this.FirstName !== undefined &&
        this.FirstName !== null
      ) {
        if (
          this.LastName &&
          this.LastName !== undefined &&
          this.LastName !== null
        ) {
          if (
            this.selectedType &&
            this.selectedType !== undefined &&
            this.selectedType !== null
          ) {
            if (this.Email && this.Email !== undefined && this.Email !== null) {
              if (this.validateEmail() === true) {
                // if (this.PhoneNumber && this.PhoneNumber !== undefined && this.PhoneNumber !== null) {
                if (this.numberForm.valid) {
                  if (
                    this.TaxID &&
                    this.TaxID !== undefined &&
                    this.TaxID !== null
                  ) {
                    if (
                      this.Address1 &&
                      this.Address1 !== undefined &&
                      this.Address1 !== null
                    ) {
                      if (
                        this.State &&
                        this.State !== undefined &&
                        this.State !== null
                      ) {
                        if (
                          this.City &&
                          this.City !== undefined &&
                          this.City !== null &&
                          this.City !== 'Select City'
                        ) {
                          // if (this.ZipCode && this.ZipCode !== undefined && this.ZipCode !== null) {
                          if (this.numberFormZip.valid) {
                            if (
                              this.Country &&
                              this.Country !== undefined &&
                              this.Country !== null
                            ) {
                              if (this.dataerrortrue === false) {
                                if (this.pickupTypeID[0]) {
                                  this.multipleFGadd = [];
                                  const body1 = {
                                    CompanyTitle: this.CompanyTitle,
                                    FirstName: this.FirstName,
                                    LastName: this.LastName,
                                    Account: this.selectedType,
                                    Email: this.Email,
                                    PhoneNumber: this.PhoneNumber,
                                    TaxID: this.TaxID,
                                    Country: this.Country,
                                    Address1: this.Address1,
                                    Address2: this.Address2,
                                    // State: this.State,
                                    // City: this.City,
                                    StateId: this.State,
                                    CityId: this.City,
                                    ZipCode: this.ZipCode,
                                    PickupType: this.sendpickupTypes,
                                    MenuEdit: this.menuEdit,
                                  };
                                  this.facilityFGadd.push(body1);
                                  const body = {
                                    SAASClient: [
                                      {
                                        CompanyTitle: this.CompanyTitle,
                                        FirstName: this.FirstName,
                                        LastName: this.LastName,
                                        Account: this.selectedType,
                                        Email: this.Email,
                                        PhoneNumber: this.PhoneNumber,
                                        TaxID: this.TaxID,
                                        Country: this.Country,
                                        Address1: this.Address1,
                                        Address2: this.Address2,
                                        // State: this.State,
                                        // City: this.City,
                                        StateId: this.State,
                                        CityId: this.City,
                                        ZipCode: this.ZipCode,
                                        PickupType: this.sendpickupTypes,
                                        MenuEdit: this.menuEdit,
                                      },
                                    ],
                                  };
                                  // var spinnerRef = this.spinnerService.start();
                                  // this.sassService.reigster(body).subscribe(
                                  //   data => {
                                  //     // alert(data.message)
                                  //     if (data.message === 'User already exist') {
                                  //       this.facilityFGadd = [];
                                  //       this.dataerror = 'This email already exists';
                                  //       // alert('This email already exists');
                                  //     } else if (data.message) {
                                  //       alert('Some error occurred.');
                                  //     } else {
                                  localStorage.removeItem('saasFG');
                                  localStorage.setItem(
                                    'multiplefacilities',
                                    JSON.stringify(this.facilityFGadd)
                                  );
                                  // localStorage.setItem('UserId', JSON.stringify(data.UserId));
                                  // localStorage.setItem('ClientId', JSON.stringify(data.ClientId));
                                  this.router.navigate(['subscription']);
                                  // let finalData;
                                  // if (data.message === 'User Signup Successfully') {
                                  //   finalData = 'Email has been sent successfully.';
                                  // }
                                  // const dialogRef = this.dialog.open(SuccesPopupComponent,
                                  //   {
                                  //     width: '480px',
                                  //     // height: '480px',
                                  //     data: { ClientData: finalData },
                                  //     panelClass: 'custom-editSub-container',
                                  //   });
                                  // dialogRef.afterClosed().subscribe(result => {
                                  //   this.CompanyTitle = '',
                                  //     this.FirstName = '',
                                  //     this.LastName = '',
                                  //     this.Email = '',
                                  //     this.PhoneNumber = '',
                                  //     this.TaxID = '',
                                  //     this.Country = '',
                                  //     this.Address1 = '',
                                  //     this.Address2 = '',
                                  //     this.State = '',
                                  //     this.City = '',
                                  //     this.ZipCode = '';
                                  // }
                                  // );
                                  //   }
                                  //   this.spinnerService.stop(spinnerRef);
                                  //   // this.performLoginTask(data)
                                  // },
                                  // error => {
                                  // this.state.isSpinnerVisible = false;
                                  // alert(JSON.stringify(error));
                                  // this.spinnerService.stop(spinnerRef);
                                  // this.buttonDisabled = false;
                                  //   }
                                  // );
                                } else {
                                  this.requireField = true;
                                  setTimeout(() => {
                                    this.requireField = false;
                                  }, 3000);
                                }
                              } else {
                                this.dataerror =
                                  'Field requires a unique email id and this email id has already been used';
                              }
                            } else {
                              this.requireCountry = true;
                              setTimeout(() => {
                                this.requireCountry = false;
                              }, 3000);
                            }
                          } else {
                            this.requireZipCode = true;
                            setTimeout(() => {
                              this.requireZipCode = false;
                            }, 3000);
                          }
                        } else {
                          this.requireCity = true;
                          setTimeout(() => {
                            this.requireCity = false;
                          }, 3000);
                        }
                      } else {
                        this.requireState = true;
                        setTimeout(() => {
                          this.requireState = false;
                        }, 3000);
                      }
                    } else {
                      this.requireAddress1 = true;
                      setTimeout(() => {
                        this.requireAddress1 = false;
                      }, 3000);
                    }
                  } else {
                    this.requireTaxID = true;
                    setTimeout(() => {
                      this.requireTaxID = false;
                    }, 3000);
                  }
                } else {
                  this.requirePhoneNumber = true;
                  setTimeout(() => {
                    this.requirePhoneNumber = false;
                  }, 3000);
                }
              } else {
                this.invalidEmail = true;
                setTimeout(() => {
                  this.invalidEmail = false;
                }, 3000);
              }
            } else {
              this.requireEmail = true;
              setTimeout(() => {
                this.requireEmail = false;
              }, 3000);
            }
          } else {
            this.requireselectedType = true;
            setTimeout(() => {
              this.requireselectedType = false;
            }, 3000);
          }
        } else {
          this.requireLastName = true;
          setTimeout(() => {
            this.requireLastName = false;
          }, 3000);
        }
      } else {
        this.requireFirstName = true;
        setTimeout(() => {
          this.requireFirstName = false;
        }, 3000);
      }
    } else {
      this.requireCompanyTitle = true;
      setTimeout(() => {
        this.requireCompanyTitle = false;
      }, 3000);
    }
  }
  closeaddfacility(id) {
    for (let i = 0; i < this.multipleFGadd.length; ++i) {
      if (this.multipleFGadd[i].Id === id) {
        this.multipleFGadd.splice(i, 1);
      }
    }
  }
  goTofacilityReg() {
    const sendpickupTypes = [];
    for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
      sendpickupTypes.push({ Title: this.pickupTypeIDFG[i] });
    }
    if (this.PhoneNumberFG) {
      this.numberForm.controls.number.setValue(
        numberSetMask(this.PhoneNumberFG)
      );
    }
    this.sendpickupTypes = sendpickupTypes;
    if (
      this.CompanyTitleFG &&
      this.CompanyTitleFG !== undefined &&
      this.CompanyTitleFG !== null
    ) {
      // if (this.FirstName && this.FirstName !== undefined && this.FirstName !== null) {
      //   if (this.LastName && this.LastName !== undefined && this.LastName !== null) {
      // if (this.selectedType && this.selectedType !== undefined && this.selectedType !== null) {
      if (this.EmailFG && this.EmailFG !== undefined && this.EmailFG !== null) {
        if (this.validateEmailFG() === true) {
          // if (this.PhoneNumberFG && this.PhoneNumberFG !== undefined && this.PhoneNumberFG !== null) {
          if (this.numberForm.valid) {
            if (
              this.TaxIDFG &&
              this.TaxIDFG !== undefined &&
              this.TaxIDFG !== null
            ) {
              if (
                this.Address1FG &&
                this.Address1FG !== undefined &&
                this.Address1FG !== null
              ) {
                if (
                  this.StateFG &&
                  this.StateFG !== undefined &&
                  this.StateFG !== null
                ) {
                  if (
                    this.CityFG &&
                    this.CityFG !== undefined &&
                    this.CityFG !== null &&
                    this.CityFG !== 'Select City'
                  ) {
                    // if (this.ZipCodeFG && this.ZipCodeFG !== undefined && this.ZipCodeFG !== null) {
                    if (this.numberFormZip.valid) {
                      if (
                        this.CountryFG &&
                        this.CountryFG !== undefined &&
                        this.CountryFG !== null
                      ) {
                        if (this.dataerrortrue === false) {
                          if (this.pickupTypeIDFG[0]) {
                            // let id ;
                            // console.log(this.i)
                            // id = this.i++;
                            // console.log(id)
                            i = 0;
                            const body = {
                              Id: this.multipleFGadd.length + 1,
                              CompanyTitle: this.CompanyTitleFG,
                              // FirstName: this.FirstName,
                              // LastName: this.LastName,
                              Account: 'SAAS Facility',
                              Email: this.EmailFG,
                              PhoneNumber: this.PhoneNumberFG,
                              TaxID: this.TaxIDFG,
                              Country: this.CountryFG,
                              Address1: this.Address1FG,
                              Address2: this.Address2FG,
                              // State: this.StateFG,
                              // City: this.CityFG,
                              StateId: this.StateFG,
                              CityId: this.CityFG,
                              ZipCode: this.ZipCodeFG,
                              PickupType: this.sendpickupTypes,
                              MenuEdit: this.menuEditFG,
                            };
                            this.multipleFGadd.push(body);
                            this.facility = true;
                            this.facilitygroup = false;
                            (this.CompanyTitleFG = ''),
                              (this.EmailFG = ''),
                              (this.PhoneNumberFG = ''),
                              (this.TaxIDFG = ''),
                              // this.CountryFG = '',
                              (this.Address1FG = ''),
                              (this.Address2FG = ''),
                              (this.StateFG = ''),
                              (this.CityFG = ''),
                              (this.ZipCodeFG = '');
                            // this.pickupTypeIDFG = [];
                            this.menuEditFG = true;
                            // for (const status of this.PickupTypeS) {
                            //   if (status.Title === 'Food') {
                            //     this.pickupTypeIDFG.push(status.Title);
                            //   }
                            // }
                            // for (var j = 0; j < this.PickupTypeS.length; j++) {
                            //   if (this.pickupTypeIDFG[0] === this.PickupTypeS[j].Title) {
                            //     this.PickupTypeS[j]['checkedId'] = true;
                            //   } else {
                            //     this.PickupTypeS[j]['checkedId'] = false;
                            //   }
                            // }
                            // let finalData;
                            // if (data.message === 'User Signup Successfully') {
                            //   finalData = 'Email has been sent successfully.';
                            // }
                            // const dialogRef = this.dialog.open(SuccesPopupComponent,
                            //   {
                            //     width: '480px',
                            //     // height: '480px',
                            //     data: { ClientData: finalData },
                            //     panelClass: 'custom-editSub-container',
                            //   });
                            // dialogRef.afterClosed().subscribe(result => {
                            //   this.CompanyTitle = '',
                            //     this.FirstName = '',
                            //     this.LastName = '',
                            //     this.Email = '',
                            //     this.PhoneNumber = '',
                            //     this.TaxID = '',
                            //     this.Country = '',
                            //     this.Address1 = '',
                            //     this.Address2 = '',
                            //     this.State = '',
                            //     this.City = '',
                            //     this.ZipCode = '';
                            // }
                            // );
                          } else {
                            this.requireField = true;
                            setTimeout(() => {
                              this.requireField = false;
                            }, 3000);
                          }
                        } else {
                          this.dataerror =
                            'Field requires a unique email id and this email id has already been used';
                        }
                      } else {
                        this.requireCountry = true;
                        setTimeout(() => {
                          this.requireCountry = false;
                        }, 3000);
                      }
                    } else {
                      this.requireZipCode = true;
                      setTimeout(() => {
                        this.requireZipCode = false;
                      }, 3000);
                    }
                  } else {
                    this.requireCity = true;
                    setTimeout(() => {
                      this.requireCity = false;
                    }, 3000);
                  }
                } else {
                  this.requireState = true;
                  setTimeout(() => {
                    this.requireState = false;
                  }, 3000);
                }
              } else {
                this.requireAddress1 = true;
                setTimeout(() => {
                  this.requireAddress1 = false;
                }, 3000);
              }
            } else {
              this.requireTaxID = true;
              setTimeout(() => {
                this.requireTaxID = false;
              }, 3000);
            }
          } else {
            this.requirePhoneNumber = true;
            setTimeout(() => {
              this.requirePhoneNumber = false;
            }, 3000);
          }
        } else {
          this.invalidEmail = true;
          setTimeout(() => {
            this.invalidEmail = false;
          }, 3000);
        }
      } else {
        this.requireEmail = true;
        setTimeout(() => {
          this.requireEmail = false;
        }, 3000);
      }
    } else {
      //     this.requireselectedType = true;
      //     setTimeout(() => {
      //       this.requireselectedType = false;
      //     }, 3000);
      //   }
      // } else {
      //       this.requireFirstName = true;
      //       setTimeout(() => {
      //         this.requireFirstName = false;
      //       }, 3000);
      //     }
      //   } else {
      //     this.requireLastName = true;
      //     setTimeout(() => {
      //       this.requireLastName = false;
      //     }, 3000);
      //   }
      // } else {
      this.requireCompanyTitle = true;
      setTimeout(() => {
        this.requireCompanyTitle = false;
      }, 3000);
    }
    // console.log(Body)
  }
  selectedstate() {
    this.sassService.pickupcitydetails(this.State).subscribe((data) => {
      this.citydataID = data['Result'];
      this.City = '';
    });
  }
  selectedstatefg() {
    this.sassService.pickupcitydetails(this.StateFG).subscribe((data) => {
      this.citydataFGID = data['Result'];
      this.CityFG = '';
    });
  }
  selectedstorestate() {
    this.sassService.pickupcitydetails(this.State).subscribe((data) => {
      this.citydataID = data['Result'];
      for (var i = 0; i < this.citydataID.length; i++) {
        if (this.citydataID[i].ID == this.storedata[0].CityId) {
          this.City = this.citydataID[i].ID;
        }
      }
    });
  }
  selectedfgstorestate() {
    this.sassService.pickupcitydetails(this.State).subscribe((data) => {
      this.citydataID = data['Result'];
      for (var i = 0; i < this.citydataID.length; i++) {
        if (this.citydataID[i].ID == this.storedata.CityId) {
          this.City = this.citydataID[i].ID;
        }
      }
    });
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.pickupstatesdata = data['Result'];
      if (this.storedata) {
        for (var i = 0; i < this.pickupstatesdata.length; i++) {
          if (this.pickupstatesdata[i].ID === this.storedata[0].StateId) {
            this.State = this.pickupstatesdata[i].ID;
          }
        }
      }
    });
  }
  pickupfgstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.pickupstatesdata = data['Result'];
      for (var i = 0; i < this.pickupstatesdata.length; i++) {
        if (this.pickupstatesdata[i].ID === this.storedata.StateId) {
          this.State = this.pickupstatesdata[i].ID;
        }
      }
    });
  }
  updatefacility(value) {
    const dialogRef = this.dialog.open(EditfacilityComponent, {
      width: '800px',
      height: '700px',
      data: [{ value }],
      panelClass: 'custom-datedialog-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.multipleFGadd.map((val) => {
          if (val.Id === result.Id) {
            val.CompanyTitle = result.CompanyTitle;
            val.Account = result.Account;
            val.Email = result.Email;
            val.PhoneNumber = result.PhoneNumber;
            val.TaxID = result.TaxID;
            val.Address1 = result.Address1;
            val.Address2 = result.Address2;
            val.Country = result.Country;
            val.StateId = result.StateId;
            val.CityId = result.CityId;
            val.ZipCode = result.ZipCode;
            val.PickupType = result.PickupType;
            val.MenuEdit = result.MenuEdit;
          }
        });
      }
    });
  }
}
