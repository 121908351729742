import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AppState } from 'src/app/shared/app-state';
import { Router, ActivatedRoute } from '@angular/router';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { DeleteProductDialogComponent } from '../delete-product-dialog/delete-product-dialog.component';
import { DeleteCategoryDialogComponent } from '../delete-category-dialog/delete-category-dialog.component';
import { AddCategoryDialogComponent } from '../add-category-dialog/add-category-dialog.component';
import { AddMainCategoryDialogComponent } from '../add-main-category-dialog/add-main-category-dialog.component';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { UpdateCategoryDialogComponent } from '../update-category-dialog/update-category-dialog.component';
import { UpdateCategoryDataDialogComponent } from '../update-category-data-dialog/update-category-data-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { SpinnerService } from 'src/app/core/spinner.service';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
export interface PeriodicElement {
  ItemName: string;
  ProductId: string;
  Category: string;
  UnitValue: string;
  Weight: string;
  AddedBy: string;
  DateAdded: string;
  Edit: any;
  Delete: any;
}
const ELEMENT_DATA: PeriodicElement[] = [];
export interface PeriodicElements {
  CatergoryName: string;
  ProductID: number;
  Items: string;
  AddedBy: string;
  DateAdded: string;
  Activity: string;
}
const ELEMENT_DATAS: PeriodicElements[] = [];
@Component({
  selector: 'app-generic-products',
  templateUrl: './generic-products.component.html',
  styleUrls: ['./generic-products.component.scss'],
})
export class GenericProductsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  pageNumber: any = 1;
  pageSizeCategory: any = 10;
  pageNumberCategory: any = 1;
  sortColumnCategory: string;
  checkBoxTrue: boolean;
  Menuscategory: any;
  [x: string]: any;
  Menus: any;
  RecordCount: any;
  RecordCountCategory: any;
  pageSize: number = 10;
  createdbyuser: string;
  createdby: string;
  MenuCount: any;
  displayedColumns: string[] = ['ProductId', 'ItemName', 'Category', 'UnitValue', 'Weight', 'AddedBy', 'DateAdded', 'Edit'];
  displayedColumnss: string[] = ['ProductID', 'CatergoryName', 'AddedByd', 'DateAddedd', 'Editd'];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  data = Object.assign(ELEMENT_DATA);
  dataSource = new MatTableDataSource<Element>(this.data);
  selection = new SelectionModel<Element>(true, []);
  dataSources = new MatTableDataSource(ELEMENT_DATAS);
  category: any;
  sortColumn: any = null;
  selectedPageSize = [10, 25, 50, 100];
  EditItemPermission:boolean;
  DeleteItemPermission: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator2: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private state: AppState,
    private router: Router,
    private route: ActivatedRoute,
    private AdminService: AdmintoolsService,
    private customerService: CustomerService,
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSources.paginator = this.paginator2;
    this.dataSources.sort = this.sort;
  }

  ngOnInit() {
    this.EditItemPermission = getFeature('editMenuItem');
    this.DeleteItemPermission = getFeature('deleteMenuItem');
    this.selection.isSelected = this.isChecked.bind(this);
    this.menuGetData();
    this.categoryGetData();
    this.state.isSpinnerVisible = true;

    this.pageSize = 10;
    this.dataSource.paginator = this.paginator;
  }
  goToCustomerPage() {
    this.router.navigate(['Customers']);
  }
  onChangePagedata(e) {
    e ? this.pageNumberCategory = e : null;
    var spinnerRef = this.spinnerService.start();
    this.customerService.getAllCategoriesForGeneric(this.pageNumberCategory, this.pageSizeCategory, this.sortColumnCategory).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dataSources = data['Result'];
      this.Menuscategory = data.Result;
      this.RecordCount = data.RecordCount;
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const displayedRows = this.dataSource.connect().value.length;
    let isAllSelected = numSelected === displayedRows;

    if (isAllSelected) {
      isAllSelected = this.isAllDisplayedSelected();
    }
    return isAllSelected;
  }

  isAllDisplayedSelected() {
    let isAllDisplayedSelected = true;

    if (this.selection.selected.length === 0) {
      return this.isAllSelected();
    }

    this.dataSource.connect().value.some((element) => {
      if (!this.selection.isSelected(element)) {
        isAllDisplayedSelected = false;
        return isAllDisplayedSelected;
      }
    });
    return isAllDisplayedSelected;
  }
  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el) => el['ProductId'] === row.ProductId);
    if (found) {
      return true;
    }
    return false;
  }
  checked(row: any) {
    this.selection.select(row);
    var found = this.selection.selected.find((el) => el['ProductId'] === row.ProductId);
    if (found) {
      return true;
    }
    return false;
  }
  unchecked(row: any) {
    var found = this.selection.selected.find((el) => el['ProductId'] === row.ProductId);
    this.selection.deselect(found);
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
    if (found) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterViewChecked() {
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
      this.cdRef.detectChanges();
    }
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // this.selection.clear()  :
    this.isViewableSelected() ? this.deselectRows() : this.selectRows();
  }
  isViewableSelected() {
    return this.isAllSelected() || this.isAllDisplayedSelected();
  }

  deselectRows() {
    const itemsToBeUnselected = this.dataSource.connect().value;
    itemsToBeUnselected.forEach((element) => {
      var found = this.selection.selected.find((el) => el['ProductId'] === element['ProductId']);
      this.selection.deselect(found);
    });
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
  }

  selectRows() {
    const currentlyDisplayedRows = this.dataSource.connect().value;

    for (let index = 0; index < currentlyDisplayedRows.length; index++) {
      this.selection.select(currentlyDisplayedRows[index]);
    }
  }

  removeSelectedRows() {
    const dialogRef = this.dialog.open(DeleteProductDialogComponent, {
      width: '700px',
      // height: "520px",
      panelClass: 'custom-Filterdialog-container',
      data: { productId: this.selection.selected, clientId: this.Menus.ClientId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pageNumber = 1;
        this.menuGetData();
        this.state.isSpinnerVisible = false;
        this.checkBoxTrue = false;
        this.selection = new SelectionModel<Element>(true, []);
      }
    });
  }
  // ngAfterViewChecked() {
  //   if (this.selection.selected.length > 0) {
  //     this.checkBoxTrue = true;
  //     this.cdRef.detectChanges();
  //   }
  // }
  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //     this.selection.clear() :
  //     this.dataSource.data.forEach(row => {
  //       this.selection.select(row)
  //     });
  //   if (this.selection.selected.length > 0) {
  //     this.checkBoxTrue = true;
  //     this.cdRef.detectChanges();
  //   } else {
  //     this.checkBoxTrue = false;
  //     this.cdRef.detectChanges();
  //   }
  // }
  //pagination category page
  onChangePage(e) {
    // this.pageSize = e.pageSize;
    e ? this.pageNumber = e : null;
    var spinnerRef = this.spinnerService.start();
    // this.pageNumber = e.pageIndex + 1;
    this.customerService.getAllgenericproducts(this.pageNumber, this.pageSize, this.sortColumn).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result;
      this.dataSource.data = data['Result'];
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      this.MenuCount = data.RecordCount;
      // this.dataSource = this.Menus;
    });
  }

  categoryGetData() {
    var spinnerRef = this.spinnerService.start();
    this.customerService.getAllCategoriesForGeneric(this.pageNumberCategory, this.pageSizeCategory, this.sortColumnCategory).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dataSources = data.Result;
      this.Menuscategory = data.Result;
      this.RecordCountCategory = data.RecordCount;
    });
  }

  menuGetData() {
    var spinnerRef = this.spinnerService.start();
    this.customerService.getAllgenericproducts(this.pageNumber, this.pageSize, this.sortColumn).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result;
      this.dataSource.data = this.Menus;
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      this.MenuCount = data.RecordCount;
      this.RecordCount = data.RecordCount;
      // this.dataSource = this.Menus;
    });
  }
  deleteCategorieItem(CategorieItem) {
    console.log(CategorieItem);
    const dialogRef = this.dialog.open(DeleteCategoryDialogComponent, {
      width: '700px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ SectionId: CategorieItem }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categoryGetData();
        this.menuGetData();
        this.state.isSpinnerVisible = false;
      }
    });
  }

  deleteItem(item) {
    // console.log(item);
    const dialogRef = this.dialog.open(DeleteProductDialogComponent, {
      width: '700px',
      // height: "520px",
      panelClass: 'custom-Filterdialog-container',
      data: { productId: item.ProductId, name: item.Name, clientId: this.Menus.ClientId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pageNumber = 1;
        this.menuGetData();
        this.state.isSpinnerVisible = false;
        this.checkBoxTrue = false;
        this.selection = new SelectionModel<Element>(true, []);
      }
    });
  }

  additems() {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      width: '1000px',
      // height: "450px",
      panelClass: 'custom-Filterdialog-container',
      data: '',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.menuGetData();
      }
    });
  }
  updateCategory(CategoryData) {
    const dialogRef = this.dialog.open(UpdateCategoryDataDialogComponent, {
      width: '670px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ CategoryData }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categoryGetData();
      }
    });
  }
  updateitems(productdata) {
    const dialogRef = this.dialog.open(UpdateCategoryDialogComponent, {
      width: '1000px',
      // height: "450px",
      panelClass: 'custom-Filterdialog-container',
      data: [{ productdata }, { clientId: this.route.snapshot.params['id'] }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.menuGetData();
      }
    });
  }
  addcategory() {
    const dialogRef = this.dialog.open(AddMainCategoryDialogComponent, {
      width: '670px',
      // height: "450px",
      // data: this.Menus.ClientId
      panelClass: 'custom-Filterdialog-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categoryGetData();
      }
    });
  }
  mapColumnName(columnName) {
    if (columnName == 'CatergoryName') {
      return 'Name';
    }
    if (columnName == 'ProductID') {
      return 'SectionId';
    }
    if (columnName == 'AddedBy') {
      return 'CreatedBy';
    }
    if (columnName == 'DateAdded') {
      return 'CreatedOn';
    }
  }
  sortTable(event) {
    this.pageNumberCategory = 1;
    var spinnerRef = this.spinnerService.start();
    this.sortColumnCategory = this.mapColumnName(event.active);
    if (this.sortColumn === 'ProductId') this.sortColumn = 'SequenceId';
    if (event.direction === 'desc') {
      this.sortColumnCategory = '-' + this.sortColumnCategory;
    }
    this.customerService.getAllCategoriesdata(this.pageNumberCategory, this.pageSizeCategory, this.sortColumnCategory).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dataSources = data['Result'];
      this.Menuscategory = data.Result;
      this.RecordCount = data.RecordCount;
    });
  }
  mapColumnNames(columnName) {
    if (columnName == 'ItemName') {
      return 'Name';
    }
    if (columnName == 'ProductId') {
      return 'ProductId';
    }
    if (columnName == 'Category') {
      return 'Category';
    }
    if (columnName == 'UnitValue') {
      return 'Price';
    }
    if (columnName == 'Weight') {
      return 'Weight';
    }
    if (columnName == 'AddedBy') {
      return 'CreatedBy';
    }
    if (columnName == 'DateAdded') {
      return 'CreatedOn';
    }
  }
  sortTableForProduct(event) {
    this.pageNumber = 1;
    var spinnerRef = this.spinnerService.start();
    this.sortColumn = this.mapColumnNames(event.active);
    if (this.sortColumn === 'ProductId') this.sortColumn = 'SequenceId';

    if (event.direction == 'desc') {
      this.sortColumn = '-' + this.sortColumn;
    }
    this.customerService.getGenericProductSorted(this.pageNumber, this.pageSize, this.sortColumn).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result[0];
      this.MenuCount = data.RecordCount;
      this.dataSource.data = data.Result;
      this.dataSource = new MatTableDataSource(this.dataSource.data);
    });
  }
}
