import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../../../shared/services/profilesetting.service';
import { AppState } from 'src/app/shared/app-state';

@Component({
  selector: 'app-titledialog',
  templateUrl: './titledialog.component.html',
  styleUrls: ['./titledialog.component.scss']
})
export class TitledialogComponent implements OnInit {
  public titleForm: FormGroup;
  returnTitle: any;
  titleReq: boolean;
  buttonDisabled: boolean;
  constructor(
    private state: AppState,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TitledialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public profileService: ProfileService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.returnTitle = this.data[0].user_Title;
    this.titleForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required])]
    });
  }
  saveUpdates(title) {
    if (title) {
      this.returnTitle = title;
      const body = {
        Id: this.state.user.User.Id,
        FirstName: this.state.user.User.FirstName,
        LastName: this.state.user.User.LastName,
        Title: title,
        PasswordHash: this.state.user.User.PasswordHash,
        ApprovalStatusId: 2,
        ImageUrl: this.state.user.User.ImageUrl,
        Email: this.state.user.User.Email,
        PhoneNumber: this.state.user.User.PhoneNumber,
        UserName: this.state.user.User.UserName,
        UserId: this.state.user.User.UserId,
        Roles: this.state.user.User.Roles,
        Clients: this.state.user.User.Clients,
        WP_Pwd_Hash: this.state.user.User.WP_Pwd_Hash,
        WP_User_Id: this.state.user.User.WP_User_Id,
        EmailConfirmed: this.state.user.User.EmailConfirmed,
        SecurityStamp: this.state.user.User.SecurityStamp,
        PhoneNumberConfirmed: this.state.user.User.EmailConfirmed,
        TwoFactorEnabled: this.state.user.User.TwoFactorEnabled,
        LockoutEndDateUtc: this.state.user.User.LockoutEndDateUtc,
        LockoutEnabled: this.state.user.User.LockoutEnabled,
        AccessFailedCount: this.state.user.User.AccessFailedCount,
        ResetCode: this.state.user.User.ResetCode,
        User_Client: [],
        CityId: this.state.user.User.CityId,
        StateId: this.state.user.User.StateId,
        IsSAASDriver: this.state.user.User.IsSAASDriver,
        CreatedBy: this.state.user.User.CreatedBy,
        CreatedOn: this.state.user.User.CreatedOn,
        ModifiedOn: this.state.user.User.ModifiedOn,
        ModifiedBy: this.state.user.User.ModifiedBy
      };
      this.state.user.User.Title = title;
      localStorage.setItem('user', JSON.stringify(this.state));
      this.buttonDisabled = true;
      this.profileService
        .profileSettingedit(body, this.state.user.User.Id)
        .subscribe(
          res => {},
          error => {
            // alert(JSON.stringify(error));
            this.buttonDisabled = false;
          }
        );
      this.dialogRef.close(this.returnTitle);
    } else {
      this.titleReq = true;
      setTimeout(() => {
        this.titleReq = false;
      }, 3000);
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.titleForm.controls[controlName].hasError(errorName);
  };
}
