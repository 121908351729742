import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatDialog, MatSort, MatDialogConfig } from '@angular/material';
import { environment } from 'src/environments/environment';
import { AccountCreationDialogComponent } from '../account-creation-dialog/account-creation-dialog.component';
import { CustomerFilterComponent } from '../customers/customerFilter/customerFilter.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ExcelServicesService } from 'src/app/shared/services/excel-services.service';
import { AddeditDateComponent } from './addedit-date/addedit-date.component';
import { AppState } from 'src/app/shared/app-state';
import { Client } from 'src/app/models/clients.model';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { DeletedialogComponent } from '../deletedialog/deletedialog.component';
import { delay } from 'rxjs/operators';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { ModalSpinnerComponent } from './modal-spinner/modal-spinner.component';
import { SpinnerService } from 'src/app/core/spinner.service';
import { GlobalService } from 'src/app/shared/app.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SaasconfirmComponent } from 'src/app/saasconfirm/saasconfirm.component';
import { SAASService } from 'src/app/shared/services/saas.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { TimezoneService } from 'src/app/shared/pipes/timezone.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { ArrayType } from '@angular/compiler';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, AfterViewInit, AfterViewChecked {
  clients: any[];
  User: any;
  alluserdetails: any;
  RecordCount: any;
  pageSize: number;
  isAddNewAccountVisible: boolean;
  VisibleForAdminOnly: boolean;
  GenericProductViewAdminOnly: boolean;
  isCustomerAddFilterVisible: boolean;
  isCustomerExportVisible: boolean;
  // data: any = [];

  name: any;
  clientname: any;
  selectedPageSize = [10, 25, 50, 100];
  typedata: any;
  ClientId: any;
  filterCount = [];
  showButton: boolean;
  showBubbles: boolean;
  addfilter: boolean;
  listdata: Client[];
  buttonExport: boolean = false;
  pageNumber: number;
  globalfacilitator: any;
  globalPickupTypeid: any;
  globalfacilityGroup: any;
  globalselectedMenu: any;
  globalclientId: any;
  globalPickupType: any;
  globalEmail: any;
  globalClientType: any;
  globalClientName: any;
  globalPickupTypeName: any;
  globalStatus: any;
  info: any;
  filterDriver: any;
  filterFacilitator: any;
  filterPickupType: any;
  filterFacilitygroup: any;
  filtermodal: boolean;
  facilitatorGroups: any[];
  facilitators: any[];
  clientNames: any[];
  pickupTypes: any[];
  token: any;
  exportData: string;
  checkBoxTrue: boolean;
  VisibleForAdminBulkMenu: boolean;
  GrabView: boolean;
  saasFG = true;
  userRole: any;
  editClientID: any;
  yearlyPlan: boolean;
  approvalStatusData: any;
  faclityCount: any;
  globalselectedMenuName: any;
  relaodinfo: any;
  frlogin: boolean;
  reloadtrue = false;
  menuTypes: any;
  SAASFeature: boolean;
  globalFacilitatorName: any;
  Visibledriverservice = true;
  globalFacilitatorGroupName: any;
  showResetButton: boolean;
  userRoleType;

  constructor(
    private spinnerService: SpinnerService,
    private AdminService: AdmintoolsService,
    private cdRef: ChangeDetectorRef,
    public timezone: TimezoneService,
    private excelService: ExcelServicesService,
    private globalService: GlobalService,
    private _rdata: modalDataService,
    public dialog: MatDialog,
    private state: AppState,
    private router: Router,
    private pricingModule: PricingModuleServiceService,
    private route: ActivatedRoute,
    private observedata: ClientFilterService,
    private sassService: SAASService,
    private CustomerService: CustomerService
  ) { }
  public facility_overview_count = {
    fr: 0,
    f: 0,
    fg: 0,
    np: 0,
  };
  selected: any;
  user: any;
  statuses: string[] = ['Active', 'Inactive'];
  clientTypes: string[] = ['Facility', 'Facilitator', 'Facility Group', 'Nonprofit', 'MRF'];
  driverServices: string[] = ['Goodr', 'Roadie', 'SAAS', 'Postmates'];
  displayedColumns: string[] = [
    'ClientID',
    'ClientName',
    // "Admin",
    'Email',
    'FacilityGroup',
    'Facilitators',
    'ClientType',
    // 'pickupType',
    'DriverService',
    'Status',
    'Menu',
    'MenuLink',
    // "Detail",
    // 'Delete'
  ];
  // data = Object.assign(ELEMENT_DATA);
  // dataSource = new MatTableDataSource<Client>(this.data);
  selection = new SelectionModel<Client>(true, []);

  dataSource = new MatTableDataSource<Client>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  filteredData: any;
  sortColumn: any = null;

  ngOnInit() {
    this.userRole = this.state.user.User.Roles[0].Name;
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
      if (this.relaodinfo.reloadclientdata === true) {
        this.reloadtrue = true;
        this.getCustomerCounts();
        this.getCustomers();
      }
    });
    this.observedata.basicInfoObserve.subscribe((res) => {
      const info = JSON.parse(res);
      if (info) {
        if (Array.isArray(info.facilitatorGroup) && info.facilitatorGroup.length > 0) {
          info.facilitatorGroup.sort(function (a, b) {
            if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
              return -1;
            }
            if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        }
        if (Array.isArray(info.facilitator) && info.facilitator.length > 0) {
          info.facilitator.sort(function (a, b) {
            if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
              return -1;
            }
            if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        }
      }
      this.facilitatorGroups = info.facilitatorGroup;
      this.facilitators = info.facilitator;
      this.clientNames = info.facility;
    });
    this.observedata.basicFeatureInfoObserve.subscribe((res) => {
      const featureInfo = JSON.parse(res);
      if (featureInfo.featureData) {
        for (const element of featureInfo.featureData) {
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            this.SAASFeature = false;
          } else {
            this.SAASFeature = true;
          }
        }
      }
    });
    this.CustomerService.getMenuTypes().subscribe((menuRes) => {
      const menuTypescategories = menuRes['Result'];
      this.menuTypes = menuTypescategories;
    });
    this.AdminService.getUserStatus().subscribe((adminRes) => {
      const statuses = adminRes['Result'];

      this.statuses = statuses.filter((value) => value.Title !== 'Trash');
    });
    this.CustomerService.v2_getclientcategories().subscribe((clientRes) => {
      const clientCategories = clientRes['Result'];

      if (!this.SAASFeature) {
        let clientTypeArray = clientCategories.filter((cat) => cat.Title !== 'SAAS Facility' && cat.Title !== 'SAAS Facility Group');

        clientTypeArray.forEach((element) => {
          if (element.Title === 'Facility') {
            this.clientTypes[0] = element;
          } else if (element.Title === 'Facility Group') {
            this.clientTypes[1] = element;
          } else if (element.Title === 'Facilitator') {
            this.clientTypes[2] = element;
          } else if (element.Title === 'Non Profit') {
            this.clientTypes[3] = element;
          } else if (element.Title === 'MRF') {
            this.clientTypes[4] = element;
          } else {
          }
        });
      } else {
        // this.clientTypes = clientCategories;

        let clientTypeArray = [...clientCategories];

        clientTypeArray.forEach((element) => {
          if (element.Title === 'Facility') {
            this.clientTypes[0] = element;
          } else if (element.Title === 'Facility Group') {
            this.clientTypes[1] = element;
          } else if (element.Title === 'Facilitator') {
            this.clientTypes[2] = element;
          } else if (element.Title === 'Non Profit') {
            this.clientTypes[3] = element;
          } else if (element.Title === 'MRF') {
            this.clientTypes[4] = element;
          } else if (element.Title === 'SAAS Facility') {
            this.clientTypes[5] = element;
          } else if (element.Title === 'SAAS Facility Group') {
            this.clientTypes[6] = element;
          } else {
          }
        });
      }
    });

    this.userRoleType = this.state.user.User.Roles[0].Name;

    this.CustomerService.getPickupTypeList().subscribe((pickupTypeRes) => {
      const pickupTypes = pickupTypeRes['Result'];
      this.pickupTypes = pickupTypes;
    });
    this.selection.isSelected = this.isChecked.bind(this);
    this.observedata.basicClientinfoObserve.subscribe((res) => {
      // getting  the filtered data from observble
      this.info = JSON.parse(res);
      this.globalfacilitator = this.info.globalfacilitator;
      this.globalPickupTypeid = this.info.globalPickupTypeid;
      this.globalfacilityGroup = this.info.globalfacilityGroup;
      this.globalselectedMenu = this.info.globalselectedMenu;
      this.globalclientId = this.info.globalclientId;
      this.globalPickupType = this.info.globalPickupType;
      this.globalEmail = this.info.globalEmail;
      this.globalClientType = this.info.globalClientType;
      this.globalClientName = this.info.globalClientName;
      this.globalStatus = this.info.globalStatus;
      this.pageNumber = this.info.pageNumber;
      this.pageSize = this.info.pageSize;
      this.sortColumn = this.info.sortColumn;
      this.filterDriver = this.info.filterDriver;
      this.filterFacilitator = this.info.filterFacilitator;
      this.filterPickupType = this.info.filterPickupType;
      this.filterFacilitygroup = this.info.filterFacilitygroup;
      this.globalPickupTypeName = this.info.globalPickupTypeName;
      this.globalFacilitatorGroupName = this.info.globalFacilitatorGroupName;
      this.globalFacilitatorName = this.info.globalFacilitatorName;

      if (!this.sortColumn && (this.userRoleType == 'Admin' || this.userRoleType == 'Billing Admin' || this.userRoleType == 'Read-Only Admin') ) {
        this.sortColumn = '-ClientId';
      }
      if (!this.sortColumn && this.userRoleType !== 'Admin') {
        this.sortColumn = 'CategoryTitle';
      }
      this.filterCount = [];
      for (const item in this.info) {
        if (this.info[item]) {
          if (this.info[item] !== null) {
            if (
              this.info[item] !== undefined &&
              item !== 'filterFacilitygroup' &&
              item !== 'filterFacilitator' &&
              item !== 'filterPickupType' &&
              item !== 'filterDriver' &&
              item !== 'sortColumn' &&
              item !== 'pageSize' &&
              item !== 'pageNumber'
            ) {
              this.filterCount.push(this.info[item]);
            }
          }
        }
      }
    });
    this.user = this.state.user;
    if (this.user.User.Roles[0].Name === 'Facilitator') {
      this.frlogin = true;
    }
    if (this.user.User.Roles[0].Name === 'Facility Group') {
      if (this.user.User.Clients.length > 0) {
        if (
          this.user.User.Clients[0].ClientType === 'SAAS Facility Group' ||
          this.user.User.Clients[0].ClientType === 'SAAS Facility'
        ) {
          this.saasFG = true;
        } else {
          this.saasFG = false;
        }
      } else {
        this.saasFG = false;
      }
    }
    this.isAddNewAccountVisible = getFeature('addNewAccount');
    this.isCustomerAddFilterVisible = getFeature('customerAddFilter');
    this.isCustomerExportVisible = getFeature('customerExport');
    this.ClientId = this.route.snapshot.params['id'];
    this.showButton = getFeature('accountdelete');
    this.VisibleForAdminOnly = getFeature('accountdelete');
    this.GenericProductViewAdminOnly = getFeature('genericproductview');
    this.VisibleForAdminBulkMenu = getFeature('bulkMenuItemDeleted');
    this.GrabView = getFeature('VisibleForAdminOnlyGrab');
    this.Visibledriverservice = getFeature('driverservices');
    if (getFeature('customer') == true) {
      this.getCustomerCounts();
      this.getCustomers();
    }

    this.checkFilters();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const displayedRows = this.dataSource.connect().value.length;
    let isAllSelected = numSelected === displayedRows;

    if (isAllSelected) {
      isAllSelected = this.isAllDisplayedSelected();
    }
    return isAllSelected;
  }

  isAllDisplayedSelected() {
    let isAllDisplayedSelected = true;

    if (this.selection.selected.length === 0) {
      return this.isAllSelected();
    }

    this.dataSource.connect().value.some((element) => {
      if (!this.selection.isSelected(element)) {
        isAllDisplayedSelected = false;
        return isAllDisplayedSelected;
      }
    });
    return isAllDisplayedSelected;
  }
  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el) => el['ClientId'] === row.ClientId);
    if (found) {
      return true;
    }
    return false;
  }
  checked(row: any) {
    this.selection.select(row);
    var found = this.selection.selected.find((el) => el['ClientId'] === row.ClientId);
    if (found) {
      return true;
    }
    return false;
  }
  unchecked(row: any) {
    var found = this.selection.selected.find((el) => el['ClientId'] === row.ClientId);
    this.selection.deselect(found);
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
    if (found) {
      return true;
    } else {
      return false;
    }
  }
  deleteCustomerAccount() {
    if (this.userRole === 'Facility Group' || this.userRole === 'Facility' || (this.userRole === 'Admin' && this.selection.selected.length === 1)) {
      if (this.selection.selected[0]['ClientType'] === 'SAAS Facility' || this.selection.selected[0]['ClientType'] === 'SAAS Facility Group') {
        // if (statusdata.Title === 'Active') {
        // }
        if (this.state.user.User.Roles[0].Name === 'Facility') {
          this.editClientID = this.state.user.User.Clients[0].ClientId;
        } else if (this.userRole === 'Admin') {
          if (this.selection.selected[0]['FacilityGroupId']) {
            this.editClientID = this.selection.selected[0]['FacilityGroupId'];
          } else {
            this.editClientID = this.selection.selected[0].ClientId;
          }
        } else {
          this.editClientID = this.state.user['FacilityGroupId'];
        }
        this.sassService.getSubDetails(this.editClientID).subscribe((data) => {
          // this.clientData = data.Result[0];
          if (data.Result[0].StripePlanId) {
            this.pricingModule.getsubscriptionSAAS(data.Result[0].StripePlanId).subscribe((dataprice) => {
              let stripeData;
              stripeData = dataprice['Result'];
              for (const status of stripeData) {
                if (data.Result[0].StripePlanId === status.StripePlanId) {
                  if (status.SubscriptionType === 'month') {
                    // this.monthlyPrice = status.Price;
                    this.yearlyPlan = false;
                  } else {
                    // this.yearlyPrice = status.Price;
                    this.yearlyPlan = true;
                  }
                }
              }
            });
            // this.saasfg = true;
            // this.noPlaning = false;
            // if (this.clientData.StripePlanId === 'price_1H2W2rICi4RDu2OllIjS8nqt') {
            // if (data.Result[0].StripePlanId === environment.yearlyKey) {
            //   this.yearlyPlan = true;
            // } else {
            //   this.yearlyPlan = false;
            // }
            // } else {
            //   this.noPlaning = true;
            // }
            this.CustomerService.getAllFilteredCustomers(
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              this.editClientID,
              null,
              null,
              null,
              null
            ).subscribe((data) => {
              if (data.Result.length > 0) {
                data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
                this.faclityCount = data.Result.length;
              } else {
                this.faclityCount = 1;
              }
              // this.multipleFGdata = data.Result;
              // this.dialogRef.close();
              const dialogRef = this.dialog.open(SaasconfirmComponent, {
                width: '500px',
                // height: '440px',
                data: [
                  { faclityCount: this.faclityCount },
                  { yearlyPlan: this.yearlyPlan },
                  { active: 'Inactive' },
                  { type: this.selection.selected[0].ClientType },
                ],
                panelClass: 'custom-editSub-container',
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.removeSelectedRows();
                }
              });
              // }
            });
          } else {
            // this.noPlaning = true;
            this.removeSelectedRows();
          }
        });
      } else {
        this.removeSelectedRows();
      }
    } else {
      this.removeSelectedRows();
    }
  }
  removeSelectedRows() {
    const dialogRef = this.dialog.open(DeletedialogComponent, {
      width: '700px',
      // height: '320px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ ClientData: this.selection.selected }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pageNumber = 1;
        this.getCustomers();
        this.state.isSpinnerVisible = false;
        this.checkBoxTrue = false;
        this.getCustomerCounts();
        this.selection = new SelectionModel<Client>(true, []);
      }
    });
  }
  ngAfterViewChecked() {
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
      this.cdRef.detectChanges();
    }
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // this.selection.clear()  :
    this.isViewableSelected() ? this.deselectRows() : this.selectRows();
  }
  isViewableSelected() {
    return this.isAllSelected() || this.isAllDisplayedSelected();
  }

  deselectRows() {
    const itemsToBeUnselected = this.dataSource.connect().value;
    itemsToBeUnselected.forEach((element) => {
      var found = this.selection.selected.find((el) => el['ClientId'] === element.ClientId);
      this.selection.deselect(found);
    });
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
  }

  selectRows() {
    const currentlyDisplayedRows = this.dataSource.connect().value;

    for (let index = 0; index < currentlyDisplayedRows.length; index++) {
      this.selection.select(currentlyDisplayedRows[index]);
    }
  }

  exportAsXLSX(): void {
    this.token = this.state.user.User.Id;
    let filterString = '?parameterModel.uID=' + this.token;
    if (this.globalClientName) {
      filterString += '&parameterModel.clientName=' + this.globalClientName;
    }
    if (this.globalEmail) {
      filterString += '&parameterModel.email=' + this.globalEmail;
    }
    if (this.globalClientType) {
      filterString += '&parameterModel.categoryTitle=' + this.globalClientType;
    }
    if (this.globalPickupType) {
      filterString += '&parameterModel.driverServices=' + this.globalPickupType;
    }
    if (this.globalselectedMenu) {
      filterString += '&parameterModel.menutypes=' + this.globalselectedMenu;
    }
    if (this.globalclientId) {
      filterString += '&parameterModel.clientId=' + this.globalclientId;
    }
    if (this.globalfacilityGroup) {
      filterString += '&parameterModel.facilityGroupId=' + this.globalfacilityGroup;
    }
    if (this.globalfacilitator) {
      filterString += '&parameterModel.facilitatorId=' + this.globalfacilitator;
    }
    if (this.globalPickupTypeid) {
      filterString += '&parameterModel.PickupType=' + this.globalPickupTypeid;
    }
    if (this.globalStatus) {
      filterString += '&parameterModel.ApprovalStatus=' + this.globalStatus;
    }
    if (this.sortColumn) {
      filterString += '&parameterModel.SortColumn=' + this.sortColumn;
    }
    // let timeZone;
    // var time = new Date();
    // timeZone = this.timezone.transform(time);
    // if (timeZone) {
    //   filterString += '&parameterModel.timeZone=' + timeZone;
    // }
    this.exportData = this.globalService.ClientExports + filterString;
  }

  getCustomerCounts() {
    /* this is for API optimization start call*/
    this.CustomerService.getAllFilteredCustomersCount().subscribe(
      (data) => {
        if (this.reloadtrue === true) {
          this.relaodinfo.reloadclientdata = false;
          this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
        }
        this.facility_overview_count.f = data.TotalFacilitiesCount;
        this.facility_overview_count.fr = data.TotalFacilitatorsCount;
        this.facility_overview_count.fg = data.TotalFacilityGroupsCount;
        this.facility_overview_count.np = data.TotalNonProfitsCount;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    //  API end call

    // this.CustomerService.getAllFilteredCustomers(1, 1, null, null, "Facility", null, null, null, null, null, null, null).subscribe(
    //   data => {
    //     this.facility_overview_count.f = data.RecordCount;
    //   },
    //   error => {
    //     this.state.isSpinnerVisible = false;
    //     // alert(JSON.stringify(error));
    //   }
    // );
    // this.CustomerService.getAllFilteredCustomers(1, 1, null, null, "Facilitator", null, null, null, null, null, null, null).subscribe(
    //   data => {
    //     this.facility_overview_count.fr = data.RecordCount;
    //   },
    //   error => {
    //     this.state.isSpinnerVisible = false;
    //     // alert(JSON.stringify(error));
    //   }
    // );
    // this.CustomerService.getAllFilteredCustomers(1, 1, null, null, "Facility Group", null, null, null, null, null, null, null).subscribe(
    //   data => {
    //     this.facility_overview_count.fg = data.RecordCount;
    //   },
    //   error => {
    //     this.state.isSpinnerVisible = false;
    //     // alert(JSON.stringify(error));
    //   }
    // );
    // this.CustomerService.getAllFilteredCustomers(1, 1, null, null, "Non Profit", null, null, null, null, null, null, null).subscribe(
    //   data => {
    //     this.facility_overview_count.np = data.RecordCount;
    //   },
    //   error => {
    //     this.state.isSpinnerVisible = false;
    //     // alert(JSON.stringify(error));
    //   }
    // );
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  resetCustomers() {
    var spinnerRef = this.spinnerService.start();
    if (this.userRoleType == 'Admin' || this.userRoleType == 'Billing Admin') {
      this.sortColumn = '-ClientId';
    } else {
      // console.log('Reset Filter');
      this.sortColumn = 'CategoryTitle';
    }

    this.CustomerService.getAllFilteredCustomers(
      1,
      this.pageSize,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      this.sortColumn
    ).subscribe((data) => {
      this.filterCount.length = 0;
      this.spinnerService.stop(spinnerRef);
      this.globalfacilitator = null;
      this.globalPickupTypeid = null;
      this.globalfacilityGroup = null;
      this.globalselectedMenu = null;
      this.globalclientId = null;
      this.globalPickupType = null;
      this.globalEmail = null;
      this.globalClientType = null;
      this.globalClientName = null;
      this.filterDriver = null;
      this.filterFacilitator = null;
      this.filterPickupType = null;
      this.filterFacilitygroup = null;
      this.globalStatus = null;
      this.sortColumn = this.sortColumn;
      this.pageNumber = 1;
      this.pageSize = 10;
      this.globalPickupTypeName = null;

      this.info.filterDriver = this.filterDriver;
      this.info.filterFacilitator = this.filterFacilitator;
      this.info.filterPickupType = this.filterPickupType;
      this.info.filterFacilitygroup = this.filterFacilitygroup;
      this.info.globalfacilitator = this.globalfacilitator;
      this.info.globalPickupTypeid = this.globalPickupTypeid;
      this.info.globalfacilityGroup = this.globalfacilityGroup;
      this.info.globalselectedMenu = this.globalselectedMenu;
      this.info.globalclientId = this.globalclientId;
      this.info.globalPickupTypeName = this.globalPickupTypeName;

      this.info.globalPickupType = this.globalPickupType;
      this.info.globalEmail = this.globalEmail;
      this.info.globalClientType = this.globalClientType;
      this.info.globalClientName = this.globalClientName;
      this.info.globalStatus = this.globalStatus;
      this.info.sortColumn = this.sortColumn;
      this.info.pageNumber = this.pageNumber;
      this.info.pageSize = this.pageSize;
      this.observedata.updatebasicClientinfo(JSON.stringify(this.info));
      // this.filteredData = data;
      this.RecordCount = data.RecordCount;
      this.dataSource = data.Result;
      this.state.isSpinnerVisible = true;
      this.addfilter = false;
      this.ClientId = data.Result[0].ClientId;
      const facilitator = data.Result.filter(function (client) {
        return client.ClientType && client.ClientType === 'Facility';
      });
      this.typedata = facilitator;
      const clients: Client[] = [];
      for (let i = 0; i <= data['Result'].length - 1; i++) {
        const client: Client = data['Result'][i];
        if (client.MenuType) {
          client.MenuType = client.MenuType;
        } else {
          //TODO Remove hardcode empty menutype
          client.MenuType = 'NoMenu';
        }
        clients.push(client);
        // this.excelExport.push(pickUnit);
      }
      this.dataSource = new MatTableDataSource(clients);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.showResetButton = false;
  }
  filterClose() {
    this.filtermodal = false;
  }
  filterOpen() {
    this.filtermodal = true;
  }
  closeAddFilter(closeData) {
    if (closeData === 'clientName') {
      this.globalClientName = null;
    } else if (closeData === 'clientId') {
      this.globalclientId = null;
    } else if (closeData === 'Facilitator') {
      this.globalfacilitator = null;
      this.filterFacilitator = null;
    } else if (closeData === 'PicktypeId') {
      this.globalPickupTypeid = null;
      this.filterPickupType = null;
    } else if (closeData === 'filterFacilitygroup') {
      this.globalfacilityGroup = null;
      this.filterFacilitygroup = null;
    } else if (closeData === 'Email') {
      this.globalEmail = null;
    } else if (closeData === 'DriverService') {
      this.globalPickupType = null;
      this.filterDriver = null;
    } else if (closeData === 'MenuType') {
      this.globalselectedMenu = null;
    } else if (closeData === 'selectedClientType') {
      this.globalClientType = null;
    } else if (closeData === 'globalStatus') {
      this.globalStatus = null;
    }
    this.addfilter = false;
    this.filterCount = [];
    const data = {
      clientName: this.globalClientName,
      clientId: this.globalclientId,
      Facilitator: this.globalfacilitator,
      PickupType: this.globalPickupTypeid,
      selectedfacilityGroup: this.globalfacilityGroup,
      Email: this.globalEmail,
      DriverService: this.globalPickupType,
      MenuType: this.globalselectedMenu,
      selectedClientType: this.globalClientType,
      selectedStatus: this.globalStatus,
    };
    for (const item in data) {
      if (data[item]) {
        if (data[item] !== null) {
          if (data[item] !== undefined) {
            this.filterCount.push(data[item]);
          }
        }
      }
    }
    // this.pageSize = 10;
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getAllFilteredCustomers(
      this.pageNumber,
      this.pageSize,
      this.globalClientName,
      // this.filteredData.Administrator,
      this.globalEmail,
      // this.filteredData.FacilityGroup,
      this.globalClientType,
      this.globalPickupType,
      this.globalselectedMenu,
      this.globalclientId,
      this.globalfacilityGroup,
      this.globalfacilitator,
      this.globalPickupTypeid,
      this.globalStatus,
      this.sortColumn
    ).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.info.filterDriver = this.filterDriver;
      this.info.filterFacilitator = this.filterFacilitator;
      this.info.filterPickupType = this.filterPickupType;
      this.info.filterFacilitygroup = this.filterFacilitygroup;
      this.info.globalClientName = this.globalClientName;
      this.info.globalEmail = this.globalEmail;
      this.info.globalClientType = this.globalClientType;
      this.info.globalPickupType = this.globalPickupType;
      this.info.globalselectedMenu = this.globalselectedMenu;
      this.info.globalclientId = this.globalclientId;
      this.info.globalfacilityGroup = this.globalfacilityGroup;
      this.info.globalfacilitator = this.globalfacilitator;
      this.info.globalPickupTypeid = this.globalPickupTypeid;
      this.info.globalStatus = this.globalStatus;
      this.info.pageNumber = 1;
      // store the  Client filter data to observble
      this.observedata.updatebasicClientinfo(JSON.stringify(this.info));

      this.RecordCount = data.RecordCount;
      this.dataSource = data.Result;
      this.ClientId = data.Result[0].ClientId;
      const facilitator = data.Result.filter(function (client) {
        return client.ClientType && client.ClientType === 'Facility';
      });
      this.typedata = facilitator;
      const clients: Client[] = [];
      for (let i = 0; i <= data['Result'].length - 1; i++) {
        const client: Client = data['Result'][i];
        if (client.MenuType) {
          client.MenuType = client.MenuType;
        } else {
          //TODO Remove hardcode empty menutype
          client.MenuType = 'NoMenu';
        }
        clients.push(client);
        // this.excelExport.push(pickUnit);
      }
      this.dataSource = new MatTableDataSource(clients);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  getCustomers() {
    this.addfilter = false;
    if (!this.pageNumber || !this.pageSize) {
      this.pageNumber = 1;
      this.pageSize = 10;
    }
    if (this.reloadtrue === false) {
      var spinnerRef = this.spinnerService.start();
    }
    // if(this.userRole !== "Admin"){
    //   this.globalClientType = 'CategoryTitle';
    // }
    this.CustomerService.getAllFilteredCustomers(
      this.pageNumber,
      this.pageSize,
      this.globalClientName,
      // this.filteredData.Administrator,
      this.globalEmail,
      // this.filteredData.FacilityGroup,
      this.globalClientType,
      this.globalPickupType,
      this.globalselectedMenu,
      this.globalclientId,
      this.globalfacilityGroup,
      this.globalfacilitator,
      this.globalPickupTypeid,
      this.globalStatus,
      this.sortColumn
    ).subscribe((data) => {
      if (this.reloadtrue === false) {
        this.spinnerService.stop(spinnerRef);
      }
      if (this.reloadtrue === true) {
        this.relaodinfo.reloadclientdata = false;
        this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
      }
      this.RecordCount = data.RecordCount;
      // this.dataSource = data.Result;
      this.ClientId = data.Result[0].ClientId;
      const facilitator = data.Result.filter(function (client) {
        return client.ClientType && client.ClientType === 'Facility';
      });
      this.typedata = facilitator;
      const clients: Client[] = [];
      for (let i = 0; i <= data['Result'].length - 1; i++) {
        const client: Client = data['Result'][i];
        if (client.MenuType) {
          client.MenuType = client.MenuType;
        } else {
          //TODO Remove hardcode empty menutype
          client.MenuType = 'NoMenu';
        }
        clients.push(client);
        // this.excelExport.push(pickUnit);
      }
      // this.dataSource.data = clients;
      this.dataSource = new MatTableDataSource(clients);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  createaccounttype() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(AccountCreationDialogComponent, {
      width: '1220px',
      height: '785px',
      panelClass: 'account-creation-dialog',
      disableClose: true,
      data: {},
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((selection) => {
      if (selection) {
        this.selected = selection;
      } else {
        // User clicked 'Cancel' or clicked outside the dialog
      }
    });
  }
  mapColumnName(columnName) {
    if (columnName == 'ClientName') {
      return 'clientName';
    }
    if (columnName == 'ClientID') {
      return 'ClientId';
    }
    if (columnName == 'ClientType') {
      return 'CategoryTitle';
    }
    if (columnName == 'DriverService') {
      return 'DriverServices';
    }
    if (columnName == 'Admin') {
      return 'Contact';
    }
    if (columnName == 'Email') {
      return 'Email';
    }
    if (columnName == 'FacilityGroup') {
      return 'FacilityGroup';
    }
    if (columnName == 'Facilitators') {
      return 'Facilitator';
    }
    if (columnName == 'Menu') {
      return 'MenuTypes';
    }
    if (columnName == 'Status') {
      return 'ApprovalStatus';
    }
  }
  sortTable(event) {
    var spinnerRef = this.spinnerService.start();
    var userRole = this.user.User.Roles[0].Name;

    this.sortColumn = this.mapColumnName(event.active);
    if (this.sortColumn == 'Email') {
      this.sortColumn = 'CityState';
    }

    if (this.sortColumn != null) {
      if (event.direction == 'desc') {
        this.sortColumn = '-' + this.sortColumn;
      }
      this.info.sortColumn = this.sortColumn;

      this.info.pageNumber = 1;
      // store the  sortColumn data to observble
      this.observedata.updatebasicClientinfo(JSON.stringify(this.info));
      if (userRole !== 'Admin') {
        this.pageSize = 10;
      }

      this.CustomerService.getAllFilteredCustomers(
        1,
        this.pageSize,
        this.globalClientName,
        // this.filteredData.Administrator,
        this.globalEmail,
        // this.filteredData.FacilityGroup,
        this.globalClientType,
        this.globalPickupType,
        this.globalselectedMenu,
        this.globalclientId,
        this.globalfacilityGroup,
        this.globalfacilitator,
        this.globalPickupTypeid,
        this.globalStatus,
        this.sortColumn
      ).subscribe((data) => {
        this.spinnerService.stop(spinnerRef);
        this.dataSource = data.Result;
        this.clientname = data.Result.Name;
        const clients: Client[] = [];
        for (let i = 0; i <= data['Result'].length - 1; i++) {
          const client: Client = data['Result'][i];
          if (client.MenuType) {
            client.MenuType = client.MenuType;
          } else {
            //TODO Remove hardcode empty menutype
            client.MenuType = 'NoMenu';
          }
          clients.push(client);
          // this.excelExport.push(pickUnit);
        }
        this.dataSource = new MatTableDataSource(clients);
        this.RecordCount = data.RecordCount;
      });
    }
  }
  customerFilter() {
    const dialogRef = this.dialog.open(CustomerFilterComponent, {
      disableClose: true,
      // width: '780px',
      // height: '760px',
      width: '750px',
      height: '794px',
      panelClass: 'custom-Filterdialog-container',
      data: {
        clientName: this.globalClientName,
        customerEmail: this.globalEmail,
        selectedClientType: this.globalClientType,
        selectedPickupType: this.globalPickupType,
        selectedMenu: this.globalselectedMenu,
        clientId: this.globalclientId,
        facilityGroupClient: this.globalfacilityGroup,
        facilitatorClient: this.globalfacilitator,
        Pickuptypeid: this.globalPickupTypeid,
        selectedStatus: this.globalStatus,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.filteredData = result;
      if (this.filteredData !== undefined) {
        this.filterCount = [];
        for (const item in this.filteredData) {
          if (this.filteredData[item]) {
            if (this.filteredData[item] !== null) {
              if (
                this.filteredData[item] !== undefined &&
                item !== 'filterFacilitygroup' &&
                item !== 'filterFacilitator' &&
                item !== 'filterPickupType' &&
                item !== 'filterDriver'
              ) {
                this.filterCount.push(this.filteredData[item]);
              }
            }
          }
        }

        var spinnerRef = this.spinnerService.start();
        this.globalClientName = this.filteredData.ClientName;
        this.globalEmail = this.filteredData.Email;
        this.globalClientType = this.filteredData.ClientType;
        this.globalPickupType = this.filteredData.PickupType;
        this.globalselectedMenu = this.filteredData.SelectedMenuType;
        this.globalclientId = this.filteredData.clientId;
        this.globalfacilityGroup = this.filteredData.facilityGroup;
        this.globalfacilitator = this.filteredData.facilitator;
        this.globalPickupTypeid = this.filteredData.pickuptypeid;
        this.globalStatus = this.filteredData.Status;
        this.filterDriver = this.filteredData.filterDriver;
        this.filterPickupType = this.filteredData.filterPickupType;
        this.filterFacilitator = this.filteredData.filterFacilitator;
        this.filterFacilitygroup = this.filteredData.filterFacilitygroup;

        this.info.globalClientName = this.globalClientName;
        this.info.globalEmail = this.globalEmail;
        this.info.globalClientType = this.globalClientType;
        this.info.globalPickupType = this.globalPickupType;
        this.info.globalselectedMenu = this.globalselectedMenu;
        this.info.globalclientId = this.globalclientId;
        this.info.globalfacilityGroup = this.globalfacilityGroup;
        this.info.globalfacilitator = this.globalfacilitator;
        this.info.globalPickupTypeid = this.globalPickupTypeid;
        this.info.globalStatus = this.globalStatus;
        this.info.pageNumber = 1;
        this.info.filterDriver = this.filterDriver;
        this.info.filterFacilitator = this.filterFacilitator;
        this.info.filterPickupType = this.filterPickupType;
        this.info.filterFacilitygroup = this.filterFacilitygroup;
        // store the  Client filter data to observble

        this.observedata.updatebasicClientinfo(JSON.stringify(this.info));

        if (!this.pageSize) {
          this.pageSize = 10;
        }
        this.CustomerService.getAllFilteredCustomers(
          1,
          this.pageSize,
          this.globalClientName,
          // this.filteredData.Administrator,
          this.globalEmail,
          // this.filteredData.FacilityGroup,
          this.globalClientType,
          this.globalPickupType,
          this.globalselectedMenu,
          this.globalclientId,
          this.globalfacilityGroup,
          this.globalfacilitator,
          this.globalPickupTypeid,
          this.globalStatus,
          this.sortColumn
        ).subscribe((data) => {
          this.spinnerService.stop(spinnerRef);
          this.dataSource = data.Result;
          this.clientname = data.Result.Name;
          const clients: Client[] = [];
          for (let i = 0; i <= data['Result'].length - 1; i++) {
            const client: Client = data['Result'][i];
            if (client.MenuType) {
              client.MenuType = client.MenuType;
            } else {
              //TODO Remove hardcode empty menutype
              client.MenuType = 'NoMenu';
            }
            clients.push(client);
            // this.listdata = clients;
            // this.addfilter=true;
            // this.excelExport.push(pickUnit);
          }

          this.dataSource = new MatTableDataSource(clients);
          this.dataSource.sort = this.sort;
          this.listdata = clients;
          this.addfilter = true;
          this.RecordCount = data.RecordCount;
        });
      }
    });
  }
  addeditdate() {
    const dialogRef = this.dialog.open(AddeditDateComponent, {
      width: '800px',
      height: '700px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  // openmodelspinner() {
  //   const dialogRef = this.dialog.open(ModalSpinnerComponent, {
  //     width: "100px",
  //     height: "100px",
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     dialogRef.close();
  //   });
  // }
  facility_menuView() {
    this.router.navigate(['FacilityMenuView']);
  }
  genericProductView() {
    this.router.navigate(['GenericProducts']);
  }

  redirectUserDetails(id) {
    if (id.ClientType == undefined) {
      this.router.navigate(['FacilityDetails/' + id.ClientId + '']);
    } else if (id.ClientType == 'Facility' || id.ClientType == 'SAAS Facility') {
      this.router.navigate(['FacilityDetails/' + id.ClientId + '']);
    } else if (id.ClientType == 'Facilitator') {
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
    } else if (id.ClientType == 'Facility Group' || id.ClientType == 'SAAS Facility Group') {
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
    } else if (id.ClientType == 'Non Profit') {
      // this.router.navigate(['FacilityDetails/' + id.ClientId + '']);
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
    } else if (id.ClientType === 'MRF') {
      // this.router.navigate(['FacilityDetails/' + id.ClientId + '']);
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
    }
    // this.router.navigate(['Products/' + this.form.clientId + '']);
  }

  applyInputFilter(type, event) {
    if (type === 'globalClientName' || type === 'globalEmail') {
      this.handleTableFilter(type, event.target.value);
    }
  }

  applyActiveFilter(type, value) {
    this.globalStatus = 'Active';
    this.handleTableFilter(type, value);
  }

  handleTableFilter = (category, value) => {
    if (category === 'globalClientName') {
      this.globalClientName = value;
    }

    if (category === 'globalEmail') {
      this.globalEmail = value;
    }

    if (category === 'globalfacilitator') {
      if (value === '') {
        this.globalFacilitatorName = value;
      } else {
        const facilitator = this.facilitators.find((facility) => facility.ClientId === value);
        if (facilitator && value !== '') {
          this.globalFacilitatorName = facilitator.Name;
        }
      }
    }
    if (category === 'globalPickupType') {
      const pickupType = this.pickupTypes.find((pickupType) => pickupType.ID === value);
      if (pickupType) {
        this.globalPickupTypeName = pickupType.Title;
      }
    }
    if (category === 'globalfacilityGroup') {
      if (value === '') {
        this.globalFacilitatorGroupName = value;
      } else {
        const facilityGroup = this.facilitatorGroups.find((fg) => fg.ClientId === value);
        if (facilityGroup) {
          this.globalFacilitatorGroupName = facilityGroup.Name;
        }
      }
    }
    this[category] = value;

    var spinnerRef = this.spinnerService.start();
    this.info.globalClientName = this.globalClientName;
    this.info.globalEmail = this.globalEmail;
    this.info.globalClientType = this.globalClientType;
    this.info.globalPickupType = this.globalPickupType;
    this.info.globalselectedMenu = this.globalselectedMenu;
    this.info.globalclientId = this.globalclientId;
    this.info.globalfacilityGroup = this.globalfacilityGroup;
    this.info.globalfacilitator = this.globalfacilitator;
    this.info.globalPickupTypeid = this.globalPickupTypeid;
    this.info.globalStatus = this.globalStatus;
    this.info.pageNumber = 1;
    this.info.filterDriver = this.filterDriver;
    this.info.filterFacilitator = this.filterFacilitator;
    this.info.filterPickupType = this.filterPickupType;
    this.info.filterFacilitygroup = this.filterFacilitygroup;
    this.info.globalPickupTypeName = this.globalPickupTypeName;
    this.info.globalFacilitatorGroupName = this.globalFacilitatorGroupName;
    this.info.globalFacilitatorName = this.globalFacilitatorName;
    // store the  Client filter data to observble

    this.observedata.updatebasicClientinfo(JSON.stringify(this.info));

    if (!this.pageSize) {
      this.pageSize = 10;
    }
    this.CustomerService.getAllFilteredCustomers(
      1,
      this.pageSize,
      this.globalClientName,
      // this.filteredData.Administrator,
      this.globalEmail,
      // this.filteredData.FacilityGroup,
      this.globalClientType,
      this.globalPickupType,
      this.globalselectedMenu,
      this.globalclientId,
      this.globalfacilityGroup,
      this.globalfacilitator,
      this.globalPickupTypeid,
      this.globalStatus,
      this.sortColumn
    ).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dataSource = data.Result;
      this.clientname = data.Result.Name;
      const clients: Client[] = [];
      for (let i = 0; i <= data['Result'].length - 1; i++) {
        const client: Client = data['Result'][i];
        if (client.MenuType) {
          client.MenuType = client.MenuType;
        } else {
          //TODO Remove hardcode empty menutype
          client.MenuType = 'NoMenu';
        }
        clients.push(client);
        // this.listdata = clients;
        // this.addfilter=true;
        // this.excelExport.push(pickUnit);
      }

      this.dataSource = new MatTableDataSource(clients);
      this.dataSource.sort = this.sort;
      this.listdata = clients;
      this.addfilter = true;
      this.RecordCount = data.RecordCount;
    });

    this.checkFilters();
  };
  //pagination
  onChangePage(e) {
    var spinnerRef = this.spinnerService.start();
    e ? this.pageNumber = e : null;
    this.selectedPageSize = [10, 25, 50, 100];
    this.info.pageNumber = this.pageNumber;
    this.info.pageSize = this.pageSize;
    this.observedata.updatebasicClientinfo(JSON.stringify(this.info));
    this.CustomerService.getAllFilteredCustomers(
      this.pageNumber,
      this.pageSize,
      this.globalClientName,
      this.globalEmail,
      this.globalClientType,
      this.globalPickupType,
      this.globalselectedMenu,
      this.globalclientId,
      this.globalfacilityGroup,
      this.globalfacilitator,
      this.globalPickupTypeid,
      this.globalStatus,
      this.sortColumn
    ).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.dataSource = data.Result;
        this.clientname = data.Result.Name;
        const clients: Client[] = [];
        for (let i = 0; i <= data['Result'].length - 1; i++) {
          const client: Client = data['Result'][i];
          if (client.MenuType) {
            client.MenuType = client.MenuType;
          } else {
            //TODO Remove hardcode empty menutype
            client.MenuType = 'NoMenu';
          }

          clients.push(client);
          // this.excelExport.push(pickUnit);
        }
        // this.dataSource.data = clients;
        this.dataSource = new MatTableDataSource(clients);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        this.RecordCount = data.RecordCount;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        this.spinnerService.stop(spinnerRef);
      }
    );
  }

  checkFilters() {
    if (
      this.globalClientName ||
      this.globalEmail ||
      this.globalfacilityGroup ||
      this.globalfacilitator ||
      this.globalClientType ||
      this.globalStatus ||
      this.globalselectedMenu ||
      this.globalPickupType
    ) {
      this.showResetButton = true;
    } else {
      this.showResetButton = false;
    }
  }
}
const ELEMENT_DATA: Client[] = [];
