import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ConditionalExpr } from "@angular/compiler";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "src/app/shared/app-state";
import { CustomerService } from "../../../shared/services/customer.service";
import { AdmintoolsService } from "../../../shared/services/admintools.service";
import { Router } from "@angular/router";
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';

@Component({
  selector: 'app-delete-menu',
  templateUrl: './delete-menu.component.html',
  styleUrls: ['./delete-menu.component.scss']
})
export class DeleteMenuComponent implements OnInit {

  dataSource: any;
  pageSize: number;
  RecordCount: any;
  filteredData: any;
  buttonDisabled: boolean;
  infoData: any;
  constructor(
    public dialogRef: MatDialogRef<DeleteMenuComponent>,
    private route: ActivatedRoute,
    private state: AppState,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private pickupService: PickUpService,
    private AdminService: AdmintoolsService, private observerdata: modalDataService
    ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }
  deleteUser(name) {
    // this.buttonDisabled = true;
    this.dialogRef.close(name);
    // this.router.navigate(['pickupDetails/' + this.data[0].id + '']);
  }
 
}
