import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donation-recepits',
  templateUrl: './donation-recepits.component.html',
  styleUrls: ['./donation-recepits.component.scss']
})
export class DonationRecepitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
