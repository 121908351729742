import { OnetimebillingsuccessComponent } from '../onetimebillingsuccess/onetimebillingsuccess.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import * as moment from 'moment-timezone';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';
declare var Stripe: any;
@Component({
  selector: 'app-onetimebillingcardpayment',
  templateUrl: './onetimebillingcardpayment.component.html',
  styleUrls: ['./onetimebillingcardpayment.component.scss'],
})
export class OnetimebillingcardpaymentComponent implements OnInit {
  modalclose: string;
  email: string;
  firstname: string;
  lastname: string;
  cardNumber: string;
  cvc: string;
  coupenYes = false;
  stripe: any;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;

  message: string;
  yearlyPay: any;
  onetimebilling: any;
  OneTimeItem: any;
  varyplanId: any;
  totalpaid: any;
  totalitemCost = 0;
  portalAccessOTP: string;
  PickupWeight: any;
  existinguser: boolean;
  npclientid: any;
  // roadiesvalid: any;
  coupendata: any = '';
  coupenValue = [];
  validcoupensdata: any;
  totalfinalpaid: number;
  errorcoupen: boolean;
  DeliveryPartner: any;
  PickupAfter: any;
  PickupBefore: any;
  errorcouponexpired: boolean;
  couponExpired: any = '';
  validcoupensexeeddata: boolean;

  constructor(
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private PromotionCodeService: PricingPromotionCodeService,
    private router: Router,
    private sassService: SAASService,
    public _rdata: modalDataService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('otpuser')) === 'existuser') {
      this.existinguser = true;
    } else {
      this.existinguser = false;
    }
    this.onetimebilling = JSON.parse(localStorage.getItem('onetimebilling'));
    this.OneTimeItem = JSON.parse(localStorage.getItem('OneTimeItem'));
    this.portalAccessOTP = localStorage.getItem('portalaccesOTP');
    this.PickupWeight = this.OneTimeItem.map(
      (r) => r.Quantity * r.Weight
    ).reduce((sum, current) => Number(sum) + Number(current));
    let body = {
      // Roadies: this.onetimebilling[0].selectdate,
      PickupAfter: this.onetimebilling[0].PickupAfter,
      PickupBefore: this.onetimebilling[0].PickupBefore,
      PickupAddress: this.onetimebilling[0].pickupAddress1,
      CityId: this.onetimebilling[0].pickupCity,
      StateId: this.onetimebilling[0].pickupState,
      PickupWeight: this.PickupWeight,
    };
    this.sassService.onetimebillingStripePlan(body).subscribe((data) => {
      this.varyplanId = data.PlanId;
      this.totalpaid = data.Price;
      this.totalfinalpaid = data.Price;
      this.npclientid = data.NPClientId;
      // this.roadiesvalid = data.Roadies;
      this.DeliveryPartner = data.DeliveryPartner;
      this.PickupAfter = data.PickupAfter.split('Z')[0];
      this.PickupBefore = data.PickupBefore.split('Z')[0];
    });

    this.OneTimeItem.pop();
    this.totalitemCost = this.OneTimeItem.map(
      (r) => r.Quantity * r.Price
    ).reduce((sum, current) => Number(sum) + Number(current));
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();

    this.cardNumberElement = elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number-element');
    this.cardNumberElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardExpiryElement = elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry-element');
    this.cardExpiryElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardCvcElement = elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc-element');
    this.cardCvcElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  Coupenyes() {
    this.coupenYes = true;
  }
  validCoupen() {
    const payload = {
      PromoCodeName: this.coupendata,
      ValidateOTP: true,
      OTPPrice: this.totalpaid,
    };
    this.PromotionCodeService.validCoupen(payload).subscribe(
      (data) => {
        if (
          data.message ===
          'Coupon duration is month which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is year which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (data.message === 'Coupon was in error') {
          this.checkForError();
        } else if (
          data.message === 'Invalidate Coupon due to price paramters'
        ) {
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.validcoupensexeeddata = true;
          setTimeout(() => {
            this.validcoupensexeeddata = false;
          }, 3000);
        } else if (data.message === 'Coupon is not once Duration Type') {
          this.checkForError();
        } else if (
          data.message.includes('Coupon validity is already expired')
        ) {
          this.couponExpired = data.message;
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.errorcouponexpired = true;
          setTimeout(() => {
            this.errorcouponexpired = false;
          }, 5000);
        } else if (
          data.message ===
          'Object reference not set to an instance of an object.'
        ) {
          this.checkForError();
        } else {
          this.validcoupensdata = 'Coupon Applied';
          this.coupenValue = [];
          var r = /\d+/g;
          // var s = "you can enter 333 maximum 500 choices";
          var m;
          while ((m = r.exec(data.message)) != null) {
            this.coupenValue.push(m[0]);
          }
          this.totalfinalpaid = this.totalpaid - this.coupenValue[0];
        }
      },
      (error) => {
        // alert(JSON.stringify(error));
      }
    );
  }
  goToBillSuccess() {
    if (this.validcoupensdata) {
      this.coupendata = this.coupendata;
    } else {
      this.coupendata = null;
    }
    this.stripe.createToken(this.cardNumberElement).then((result) => {
      if (result.token) {
        if (result.token.id) {
          let finalpickup = [];
          for (let data of this.OneTimeItem) {
            let payloaditem = {
              ItemName: data.Name,
              ItemRetailValue: data.Price,
              ItemWeight: data.Weight,
              Quantity: data.Quantity,
            };
            finalpickup.push(payloaditem);
          }
          let finalstartdate;
          let finalenddtae;
          // if (this.roadiesvalid === true) {
          // finalstartdate = this.onetimebilling[0].PickupAfter + '.00';
          // finalenddtae = this.onetimebilling[0].PickupBefore + '.00';
          // } else {
          //   finalstartdate = null;
          //   finalenddtae = null;
          // }
          let payload;
          if (this.existinguser !== true) {
            payload = {
              OneTimeClient: {
                CompanyTitle: this.onetimebilling[0].CompanyTitle,
                FirstName: this.onetimebilling[0].FirstName,
                LastName: this.onetimebilling[0].LastName,
                Email: this.onetimebilling[0].Email,
                PhoneNumber: this.onetimebilling[0].PhoneNumber,
                TaxID: this.onetimebilling[0].TaxID,
                ResidentialAddress1: this.onetimebilling[0].Address1,
                ResidentialAddress2: this.onetimebilling[0].Address2,
                ResidentialState: this.onetimebilling[0].State,
                ResidentialCity: this.onetimebilling[0].City,
                ResidentialCountry: this.onetimebilling[0].Country,
                ResidentialZipCode: this.onetimebilling[0].ZipCode,
                PickupAddress1: this.onetimebilling[0].pickupAddress1,
                PickupAddress2: this.onetimebilling[0].pickupAddress2,
                PickupState: this.onetimebilling[0].pickupState,
                PickupCity: this.onetimebilling[0].pickupCity,
                PickupCountry: this.onetimebilling[0].pickupCountry,
                PickupZipCode: this.onetimebilling[0].pickupZipCode,
                Token: result.token.id,
                PlanId: this.varyplanId,
                PromoCodeName: this.coupendata,
              },
              OneTimeItem: finalpickup,
              Pickup: false,
              DeliveryPartner: this.DeliveryPartner,
              NPClientId: this.npclientid,
              // Roadies: this.roadiesvalid,
              PickupAfter: this.PickupAfter,
              PickupBefore: this.PickupBefore,
            };
          } else {
            payload = {
              OneTimeClient: {
                Email: this.onetimebilling[0].Email,
                PickupAddress1: this.onetimebilling[0].pickupAddress1,
                PickupAddress2: this.onetimebilling[0].pickupAddress2,
                PickupState: this.onetimebilling[0].pickupState,
                PickupCity: this.onetimebilling[0].pickupCity,
                PickupCountry: this.onetimebilling[0].pickupCountry,
                PickupZipCode: this.onetimebilling[0].pickupZipCode,
                Token: result.token.id,
                PlanId: this.varyplanId,
                PromoCodeName: this.coupendata,
              },
              OneTimeItem: finalpickup,
              Pickup: true,
              DeliveryPartner: this.DeliveryPartner,
              NPClientId: this.npclientid,
              // Roadies: this.roadiesvalid,
              PickupAfter: this.PickupAfter,
              PickupBefore: this.PickupBefore,
            };
          }
          const spinnerRef = this.spinnerService.start();
          this.sassService.onetimebilling(payload).subscribe(
            (data) => {
              if (
                data.message === 'Payment Done' ||
                data.message === undefined
              ) {
                localStorage.removeItem('onetimebilling');
                localStorage.removeItem('OneTimeItem');
                this.router.navigate(['Pickupsuccess']);
              } else {
                alert(data.message);
              }
              this.spinnerService.stop(spinnerRef);
            },
            (error) => {
              // alert(JSON.stringify(error));
              if (error) {
                alert("Sorry, payment failed! Click on 'Pay' to continue");
              }
              this.spinnerService.stop(spinnerRef);
            }
          );
        }
      }
    });
  }
  goTOSuccessPopup() {
    const dialogRef = this.dialog.open(OnetimebillingsuccessComponent, {
      width: '338px',
      height: '480px',
      panelClass: 'custom-editSub-container',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  // goToBillSuccess() {
  //   this.router.navigate(["Pickupsuccess"]);
  // }
  backtoreg() {
    this.router.navigate(['PickupRegistration']);
  }

  checkForError() {
    this.validcoupensdata = '';
    this.coupenValue = [];
    this.errorcoupen = true;
    setTimeout(() => {
      this.errorcoupen = false;
    }, 3000);
  }
  clearCoupon() {
    this.coupendata = null;
    this.validcoupensdata = '';
    this.coupenValue = [];
    this.errorcoupen = false;
    this.totalfinalpaid = this.totalpaid;
  }
}
