import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './app.service';
import { User } from '../models/user.model';
import { AppState } from '../shared/app-state';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public loginUserUrl = this.globalService.base_url + '?Action=v2_validateclient';

  constructor(private http: HttpClient, private globalService: GlobalService, private accountService: AccountService, public state: AppState) {}
  token = this.getUserToken();
  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
      this.state.user = JSON.parse(localStorage.getItem('user')).user;
      return this.state.user.User.Id;
    } else {
      this.state.isLoggedIn = false;
      this.state.user = null;
      return '';
    }
  }

  login(username: string, password: string): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const payload = {
      UserName: username,
      Password: password,
    };
    return this.http.post<User>(this.globalService.GoodrLogin, payload, httpOptions).pipe();
  }
  Dashboarddetails(): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<User>(this.globalService.Login, httpOptions).pipe();
  }
  openforgotDialog(body): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<User>(this.globalService.ForgotPasswordEmailUrl, body, httpOptions).pipe();
  }
  NonProfitWorkingSeletion(body): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', UID: this.token }),
    };
    return this.http.post<User>(this.globalService.NonProfitWorkingSlots, body, httpOptions).pipe();
  }
  saasUpdate(body): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<User>(this.globalService.SAASConversion, body, httpOptions).pipe();
  }
  addNonProfitWorkingSeletion(body): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', UID: this.token }),
    };
    return this.http.put<User>(this.globalService.NonProfitWorkingSlots, body, httpOptions).pipe();
  }

  deleteNonProfitWorkingSeletion(ID): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', UID: this.token }),
    };
    return this.http.delete<User>(this.globalService.NonProfitWorkingSlots + '/' + ID, httpOptions).pipe();
  }
  //Reset Password Link For User
  resetpassword(body): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', UID: this.token }),
    };
    return this.http.post<User>(this.globalService.ResetPasswordUrl, body, httpOptions).pipe();
  }
  recoveryCode(body): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<User>(this.globalService.ForgotPasswordResetCodeUrl, body, httpOptions).pipe();
  }
  forgorPasswordCode(body): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<User>(this.globalService.ForgotPasswordUrl, body, httpOptions).pipe();
  }

  setpwd(queryStr: string): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<User>(this.globalService.loginpwdUrl + queryStr, null, httpOptions).pipe();
  }

  getUserDetails(userId: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.AspNetUsers + '/' + userId, httpOptions).pipe();
  }
  getClientsData(clientTypeId: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.ClientCategories + '/' + clientTypeId, httpOptions).pipe();
  }
}
