import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ConditionalExpr } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { SpinnerService } from 'src/app/core/spinner.service';

@Component({
  selector: 'app-delete-category-dialog',
  templateUrl: './delete-category-dialog.component.html',
  styleUrls: ['./delete-category-dialog.component.scss']
})
export class DeleteCategoryDialogComponent implements OnInit {
  buttonDisabled: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeleteCategoryDialogComponent>,
    private route: ActivatedRoute,
    private state: AppState,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private CustomerService: CustomerService,
    private spinnerService: SpinnerService,

  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

  deleteItem() {
    var spinnerRef = this.spinnerService.start();

    const payload = {
      $id: this.data[0].SectionId.$id,
      SectionId: this.data[0].SectionId.SectionId,
      Name: this.data[0].SectionId.Name,
      Description: this.data[0].SectionId.Description,
      Weight: this.data[0].SectionId.Description,
      ApprovalStatus: null,
      PickupRequest_Product: [],
      ProductSections1: [],
      ProductSections2: null,
      Products: [],
      CreatedBy: null,
      CreatedOn: null,
      ModifiedOn: null,
      ApprovalStatusId: null,
      ParentId: null,
      IsGoodrCategory: false
    };
    this.buttonDisabled = true;
    this.CustomerService.deleteProductSections(this.data[0].SectionId.SectionId).subscribe(
      data => {
        this.state.isSpinnerVisible = false;
        {
          let res = 'success';
          this.dialogRef.close(res);
          this.spinnerService.stop(spinnerRef);
        }
      }
      ,
      error => {
        if (error.statusText === 'Internal Server Error') {
          this.buttonDisabled = false;
          this.spinnerService.stop(spinnerRef);
      }
    }
    );
  }
}
