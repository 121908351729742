import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccountCreationDialogComponent } from '../account-creation-dialog/account-creation-dialog.component';
import { MatDialog, MatDialogConfig, MatSort, MatDialogRef } from '@angular/material';
import { DeletedialogComponent } from '../deletedialog/deletedialog.component';
import { EdituserComponent } from '../user/edituser/edituser.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AccountService } from 'src/app/shared/account.service';
import { AppState } from 'src/app/shared/app-state';
import { EditUserDetailsComponent } from '../user/editUserDetails/editUserDetails.component';
import { DeleteUserComponent } from '../user/delete-user/delete-user.component';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { ResetPasswordSuccessComponent } from '../user/reset-password-success/reset-password-success.component';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { TrackRoutePickedUpComponent } from '../Track_Pickup/track-route-picked-up/track-route-picked-up.component';

export interface PeriodicElement {
  AccountName: string;
  AccountType: string;
  TaskPerformed: string;
}
export interface PeriodicElements {
  AccountName: string;
  AccountType: string;
}
const ELEMENT_DATA: PeriodicElements[] = [];

const ELEMENT_DATAS: PeriodicElement[] = [];
@Component({
  selector: 'app-customer-main-view',
  templateUrl: './customer-main-view.component.html',
  styleUrls: ['./customer-main-view.component.scss'],
})
export class CustomerViewDetailsComponent implements OnInit {
  info: any;
  disabledForAdminDriver: boolean;
  frrunderfg = true;
  userRole: any;
  sameuserlogin: boolean;
  infostate: any;
  pickupstatesdata: any;
  citydataID: any;
  driverCity: any;
  driverRole: boolean;
  saasDriver: boolean;
  [x: string]: any;
  alluser: any;
  clients: any[];
  driverState: any;
  User: any;
  deleteData = [];
  UserId: number;
  alluserdetails: any;
  userData: any;
  clientData: any;
  loginForm: any;
  msgdata: any;
  isLoggedfalse: boolean;
  userupdate: boolean;
  loginError: any;
  modalclose: string;
  forgotForm: any;
  user: any;

  constructor(
    public dialog: MatDialog,
    private ClientFilterdata: ClientFilterService,
    private observedata: modalDataService,
    private sassService: SAASService,
    private AdminService: AdmintoolsService,
    private router: Router,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private state: AppState,
    private formBuilder: FormBuilder,
    private spinnerService: SpinnerService
  ) { }
  showButton: boolean;
  ShowButtonForAdmin: boolean;
  NotVisibleForAdminDriver: boolean;
  displayedColumns: string[] = [
    'AccountName',
    'AccountType',
    //  "Role"
  ];
  displayedColumnsforrecentactivity: string[] = ['AccountName', 'AccountType', 'TaskPerformed'];
  dataSources = new MatTableDataSource<PeriodicElement>(ELEMENT_DATAS);
  dataSource = new MatTableDataSource<PeriodicElements>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.userRole = this.state.user.User.Roles[0].Name;
    this.observedata.basicUserinfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
    });
    this.forgotForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'), Validators.required]),
      ],
    });
    this.showButton = getFeature('accountdelete');
    this.ShowButtonForAdmin = getFeature('edituserdetails');
    var spinnerRef = this.spinnerService.start();
    this.accountService.getUserDetails(this.route.snapshot.params['id']).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        // this.state.user = data.Result[0];
        this.userData = data.Result[0];
        if (this.userData.Roles[0].Name === 'Driver') {
          this.driverRole = true;
          this.saasDriver = false;

          this.ClientFilterdata.basicCityInfoObserve.subscribe((res) => {
            this.infostate = JSON.parse(res);
            this.pickupstatesdata = this.infostate.statedata;
            for (var i = 0; i < this.pickupstatesdata.length; i++) {
              if (this.pickupstatesdata[i].ID == this.userData.StateId) {
                this.driverState = this.pickupstatesdata[i].Title;
              }
            }
            if (this.userData.StateId && this.userData.StateId !== null && this.userData.StateId !== undefined) {
              this.sassService.pickupcitydetails(this.userData.StateId).subscribe((data) => {
                this.citydataID = data['Result'];
                for (var i = 0; i < this.citydataID.length; i++) {
                  if (this.citydataID[i].ID == this.userData.CityId) {
                    this.driverCity = this.citydataID[i].Title;
                  }
                }
              });
            }
          });
        }

        if (this.userData.Roles[0].Name === 'Facility') {
          if (this.state.user.User.Email === this.userData.Email) {
            this.sameuserlogin = true;
          }
        }

        if (this.userRole === 'Facilitator') {
          if (this.userData.Roles[0].Name === 'Facilitator') {
            this.frrunderfg = false;
            if (this.state.user.User.Email === this.userData.Email) {
              this.sameuserlogin = true;
            }
          } else if (this.userData.Roles[0].Name === 'Facility') {
            if (this.userData.Clients.length === 1) {
              if (this.userData.Clients[0].FacilitatorId && this.userData.Clients[0].FacilityGroupId) {
                this.frrunderfg = false;
                this.sameuserlogin = false;
              } else if (this.userData.Clients[0].FacilitatorId) {
                this.frrunderfg = true;
                this.sameuserlogin = true;
              } else {
                this.frrunderfg = false;
                this.sameuserlogin = false;
              }
            } else {
              this.frrunderfg = false;
              this.sameuserlogin = false;
            }
          } else {
            this.frrunderfg = false;
            this.sameuserlogin = false;
          }
        } else {
          if (this.userData.Roles[0].Name === 'Non Profit') {
            if (this.state.user.User.Email === this.userData.Email) {
              this.sameuserlogin = true;
            }
          }
        }
        if (this.userData.Roles[0].Name == 'Driver') {
          this.disabledForAdminDriver = false;
        } else {
          this.disabledForAdminDriver = true;
        }

        this.clientData = this.userData.Clients;
        this.dataSource = this.clientData.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));

        if (this.userData.Roles[0].Name == 'Admin' || this.userData.Roles[0].Name == 'Billing Admin' || this.userData.Roles[0].Name == 'Read-Only Admin') {
          this.user = this.state.user;

          this.clientData = this.user.User.Clients;
          this.dataSource = this.clientData.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
        }

        if (this.clientData.length !== 0 && this.clientData[0].ClientTypeId) {
          this.accountService.getClientsData(this.clientData[0].ClientTypeId).subscribe(
            (data) => {
              this.clientData = data.Result;
            },
            (error) => {
              this.state.isSpinnerVisible = false;
              // alert(JSON.stringify(error));
            }
          );
        }
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    // this.v2_getAlluser();
    this.dataSources.sort = this.sort;
    this.dataSources.paginator = this.paginator;
  }
  removelDialog() {
    const dialogRef = this.dialog.open(DeletedialogComponent, {
      width: '650px',
      // height: "300px",
      panelClass: 'custom-Filterdialog-container',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  // resetpassword(){
  //   const dialogRef = this.dialog.open(ResetPasswordSuccessComponent, {
  //     width: "650px",
  //     height: "400px",
  //     data: {}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {});
  // }
  resetpassword(formValues: { Email: string }) {
    if (this.forgotForm) {
      const body = {
        Email: this.userData.Email,
      };
      this.accountService.resetpassword(body).subscribe((data) => {
        this.msgdata = data.message;
        if (this.msgdata === 'Email does not exist') {
          this.isLoggedfalse = true;
          setTimeout(() => {
            this.isLoggedfalse = false;
          }, 3000);
        } else {
          // const dialogConfig = new MatDialogConfig();
          // dialogConfig.disableClose = true;
          // dialogConfig.autoFocus = true;
          // this.dialogRef.close();
          // const dialogRef = this.dialog.open(ResetPasswordSuccessComponent, {
          //   width: "650px",
          //   height: "400px",
          //   disableClose: true,
          //   data: this.userData.Email
          // });
          // dialogRef.afterClosed().subscribe(result => {
          // });
        }
        alert('A password reset message sent to your email address. Please check your email.');
      });
    }
  }
  edituserdetails() {
    const dialogRef = this.dialog.open(EditUserDetailsComponent, {
      width: '900px',
      // height: "650px",
      panelClass: 'custom-Filteredituserdialog-container',
      data: this.userData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      var spinnerRef = this.spinnerService.start();
      this.accountService.getUserDetails(this.route.snapshot.params['id']).subscribe(
        (data) => {
            // this.state.user = data.Result[0];
            this.userData = data.Result[0];
            if (this.userData.Roles[0].Name === 'Driver') {
              this.disabledForAdminDriver = false;
              this.driverRole = true;
              this.saasDriver = false;
              this.ClientFilterdata.basicCityInfoObserve.subscribe((res) => {
                this.infostate = JSON.parse(res);
                this.pickupstatesdata = this.infostate.statedata;
                for (var i = 0; i < this.pickupstatesdata.length; i++) {
                  if (this.pickupstatesdata[i].ID == this.userData.StateId) {
                    this.driverState = this.pickupstatesdata[i].Title;
                  }
                }
                if (this.userData.StateId && this.userData.StateId !== null && this.userData.StateId !== undefined) {
                  this.sassService.pickupcitydetails(this.userData.StateId).subscribe((data) => {
                    this.citydataID = data['Result'];
                    for (var i = 0; i < this.citydataID.length; i++) {
                      if (this.citydataID[i].ID == this.userData.CityId) {
                        this.driverCity = this.citydataID[i].Title;
                      }
                    }
                  });
                }
              });
            } else {
              this.saasDriver = true;
              this.disabledForAdminDriver = true;
            }
            this.clientData = this.userData.Clients;
            this.dataSource = this.clientData;
            // for (var i = 0; i < this.userData.length; i++) {
            //   // this.alluserdetails = this.userData[i];
            // }

            if (this.userData.Roles[0].Name == 'Admin' || this.userData.Roles[0].Name == 'Billing Admin' || this.userData.Roles[0].Name == 'Read-Only Admin') {
              this.user = this.state.user;

              this.clientData = this.user.User.Clients;
              this.dataSource = this.clientData.sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
            }

            if (this.clientData.length != 0) {
              this.accountService.getClientsData(this.clientData[0].ClientTypeId).subscribe(
                (data) => {
                  this.clientData = data.Result;
                },
                (error) => {
                  this.state.isSpinnerVisible = false;
                  // alert(JSON.stringify(error));
                }
              );
            }
            this.spinnerService.stop(spinnerRef);
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          this.spinnerService.stop(spinnerRef);
          // alert(JSON.stringify(error));
        }
      );
    });
  }
  backtoUsers() {
    this.router.navigate(['users']);
  }

  // Filter data
  applyFilter(filterValue: string) {
    this.dataSources.filter = filterValue.trim().toLowerCase();
  }
  deleteUser(Id) {
    this.deleteData.push(this.userData);
    const dialogRef = this.dialog.open(DeleteUserComponent, {
      width: '700px',
      panelClass: 'custom-Filterdialog-container',
      data: { Id: this.deleteData, UserId: this.UserId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.state.isSpinnerVisible = false;
      if (result === 'res') {
        this.router.navigate(['users/']);
      }
    });
    // this.router.navigate(['users/']);
  }
  redirectUserDetails(id) {
    this.router.navigate(['CustomerViewDetails/' + id.UserId + '']);
  }
  permissionsCheck() {
    if (this.userRole === 'Admin' && this.userData.Roles[0].Name === 'Billing Admin')
      return false
    else if (this.userRole === 'Billing Admin')
      return true
    else if (this.ShowButtonForAdmin || this.sameuserlogin)
      return true
  }
}
