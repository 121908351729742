import { ConstantPool } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { SpinnerService } from 'src/app/core/spinner.service';
import { PhoneFilterPipe } from 'src/app/shared/pipes/phone.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';

@Component({
  selector: 'app-add-contract-dialog',
  templateUrl: './add-contract-dialog.component.html',
  styleUrls: ['./add-contract-dialog.component.scss']
})
export class AddContractDialogComponent implements OnInit {
  allClients: any;
  selectedClient: any;
  allpickupList: any;
  pickupList: any;
  pickupType: any;
  contractPeriods: any;
  didCreateNewContract: boolean;
  clientRequired: boolean;
  pickupTypeRequired: boolean;
  contractPerPriceRequired: boolean;
  contractExtraPriceRequired: boolean;
  noOfContractRequired: boolean;
  startDateRequired: boolean;
  contractPeriodRequired: boolean;
  fnameRequired: boolean;
  lnameRequired: boolean;
  phoneRequired: boolean;
  emailRequired: boolean;
  invalidEmail: boolean;
  showStartDateRequired: boolean;
  phoneValid: boolean;
  contractPerPrice: string;
  contractExtraPrice: number;
  noOfContract: string;
  showStartDate: any;
  showEndDate: any;
  contractPeriod: any;
  fname: string;
  lname: string;
  phone: string;
  email: string;
  facilityRoleId: string;
  contractNetDays: number = 15;
  contractDiscount: number = 0;
  contractNetDaysRequired: boolean;
  contractDiscountRequired: boolean;
  contractDiscountRequiredError: boolean;
  selectedClientObj: any;
  allNetTermsList: any = [0, 15, 30, 60];
  endDateError: boolean;
  info: any;
  newDate: any;
  constructor(public dialogRef: MatDialogRef<AddContractDialogComponent>,
    private pickupService: PickUpService,
    private ClientFilterdata: ClientFilterService,
    private spinnerService: SpinnerService,
    private AdminService: AdmintoolsService,
    private customerServices: CustomerService,
    public phoneFilterPipe: PhoneFilterPipe,
  ) { }

  ngOnInit() {

    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      if (this.info.facility.length > 0) {
        this.allClients = this.info.facility.filter((value) => value.ApprovalStatus === 'Active' && value.PickupType[0] !== 'One Time Pickup');
        if (this.info.facility.filter((value) => value.ApprovalStatus === 'Active' && value.PickupType[0] !== 'One Time Pickup').length === 1) {
        }

      }
    });

    if (this.allClients.length > 0) {
      this.allClients = this.allClients.sort(function (a, b) {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
          return -1;
        }
        if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }

    this.pickupService.getPickupFilterList().subscribe((d) => {
      const list = d.Result;
      const index = list.findIndex((p) => p['Title'] === 'One Time Pickup');
      if (index > 1) {
        list.splice(index, 1);
      }
      this.allpickupList = list;
    });

    this.pickupService.getContractPeriod().subscribe((d) => {
      const list1 = d.Result;
      this.contractPeriods = list1;
    });
    this.AdminService.getAccountType().subscribe(
      (data) => {
        let results = data.Result;
        results.forEach(element => {
          if (element.Name == 'Facility') this.facilityRoleId = element.Id;
        });
      });
  }
  isvalid() {
    if (this.selectedClient == null) {
      this.clientRequired = true;
      setTimeout(() => {
        this.clientRequired = false;
      }, 3000);
    } else if (this.pickupType == null) {
      this.pickupTypeRequired = true;
      setTimeout(() => {
        this.pickupTypeRequired = false;
      }, 3000);
    } else if (this.noOfContract == undefined || this.noOfContract == '') {
      this.noOfContractRequired = true;
      setTimeout(() => {
        this.noOfContractRequired = false;
      }, 3000);
    } else if (this.contractPeriod == undefined) {
      this.contractPeriodRequired = true;
      setTimeout(() => {
        this.contractPeriodRequired = false;
      }, 3000);
    } else if (this.contractPerPrice == null || this.contractPerPrice == undefined) {
      this.contractPerPriceRequired = true;
      setTimeout(() => {
        this.contractPerPriceRequired = false;
      }, 3000);
    } else if (this.contractExtraPrice == undefined || this.contractExtraPrice == null) {
      this.contractExtraPriceRequired = true;
      setTimeout(() => {
        this.contractExtraPriceRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount < 0 || this.contractDiscount >= 101) {
      this.contractDiscountRequiredError = true;
      setTimeout(() => {
        this.contractDiscountRequiredError = false;
      }, 3000);
    } else if (this.showStartDate == undefined || this.showStartDate == '') {
      this.startDateRequired = true;
      setTimeout(() => {
        this.startDateRequired = false;
      }, 3000);
    }
    else if (this.contractNetDays == undefined || this.contractNetDays == null) {
      this.contractNetDaysRequired = true;
      setTimeout(() => {
        this.contractNetDaysRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount == undefined || this.contractDiscount == null) {
      this.contractDiscountRequired = true;
      setTimeout(() => {
        this.contractDiscountRequired = false;
      }, 3000);

    } else if (this.fname == undefined || this.fname == '') {
      this.fnameRequired = true;
      setTimeout(() => {
        this.fnameRequired = false;
      }, 3000);
    }
    else if (this.lname == undefined || this.lname == '') {
      this.lnameRequired = true;
      setTimeout(() => {
        this.lnameRequired = false;
      }, 3000);
    } else if (this.phone == undefined || this.phone == '') {
      this.phoneRequired = true;
      setTimeout(() => {
        this.phoneRequired = false;
      }, 3000);
    } else if (this.email == undefined || this.email == '') {
      this.emailRequired = true;
      setTimeout(() => {
        this.emailRequired = false;
      }, 3000);
    } else if (this.validateEmail() == false) {
      this.invalidEmail = true;
      setTimeout(() => {
        this.invalidEmail = false;
      }, 3000);
    } else if (this.phone.length !== 12) {
      this.phoneValid = true;
      setTimeout(() => {
        this.phoneValid = false;
      }, 3000);
    } else if (this.showStartDate !== '' && this.showEndDate !== '' && this.showEndDate !== undefined && moment(this.showStartDate).isAfter(this.showEndDate) == true) {
      this.endDateError = true;
      setTimeout(() => {
        this.endDateError = false;
      }, 3000);
    } else {
      this.createContract()
    }

  }
  
  createContract() {
    let endDate, startDate;
    
    // startDate = moment
    //   .tz(this.showStartDate, 'YYYY-MM-DDTHH:mm:ss', this.selectedClientObj.TimeZone)
    //   .utc()
    //   .format('YYYY-MM-DDTHH:mm:ss');

    startDate = moment(this.showStartDate).format('YYYY-MM-DDTHH:mm:ss');
    startDate = startDate.split("Z")[0];

    if (this.showEndDate !== '' && this.showEndDate !== undefined) {
      // endDate = moment
      //   .tz(this.showEndDate, 'YYYY-MM-DDTHH:mm:ss', this.selectedClientObj.TimeZone)
      //   .utc()
      //   .format('YYYY-MM-DDTHH:mm:ss');

      endDate = moment(this.showEndDate).format('YYYY-MM-DDTHH:mm:ss');
      endDate = endDate.split("Z")[0];
    } else endDate = ""
    var spinnerRef = this.spinnerService.start();


    let payload = {
      "ClientId": this.selectedClient,
      "PickupTypeId": this.pickupType,
      "Contract_Price_Per_Pickup": this.contractPerPrice,
      "Extra_Price_Per_Pickup": this.contractExtraPrice,
      "No_Of_Contracted_Pickups": this.noOfContract,
      "ContractPeriodId": this.contractPeriod.ID,
      "Start_Date": startDate,
      "End_Date": endDate,
      "Email": this.email,
      "ApprovalStatusId": 2,
      "Netdays": this.contractNetDays,
      "DiscountInPercent": this.contractDiscount
    }
    this.pickupService.addContract(payload).subscribe((data) => {
      let payload2 = {
        "Clients": [{ "ClientId": this.selectedClient }],
        "Email": this.email,
        "FirstName": this.fname,
        "IsSAASDriver": false,
        "LastName": this.lname,
        "OnClientCreation": false,
        "PhoneNumber": this.phone.replace(/[_-]/g, ''),
        "RoleId": this.facilityRoleId,
        "IsBillingUser": true,
        "ContractId": data.ID
      }


      this.AdminService.createUser(payload2).subscribe((response) => {
        if ('error' in response) {
          this.spinnerService.stop(spinnerRef);
          if (response['message'] == 'User already exist') {
            this.dialogRef.close("Done");
            this.AdminService.createQuickbookCustomer(this.selectedClient).subscribe((response) => {
              console.log(response);
            });
          } else alert(response['message']);
        } else {
          this.dialogRef.close("Done");
          this.spinnerService.stop(spinnerRef);
          this.AdminService.createQuickbookCustomer(this.selectedClient).subscribe((response) => {
            console.log(response);
          },
            (er) => {
              console.log(er);
            });
        }
      },
        (error) => {
          this.spinnerService.stop(spinnerRef);
          alert(error.error.Message);
        });
    },
      (err) => {
        this.spinnerService.stop(spinnerRef);
        alert(err.error.Message);
      });
  }
  onChangeEndDate(date) {
    let newD = new Date(date)
    this.showEndDate = newD;
  }
  onChangeStartDate(date) {
    this.showStartDate = new Date(date);
  }
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.email) === false) return false;
    else return true;
  }
  selectClient(e) {
    this.pickupList = [];
    this.selectedClientObj = this.allClients.filter((ele) => (this.selectedClient == ele.ClientId))
    this.selectedClientObj = this.selectedClientObj[0]
    let pickupTypes = this.selectedClientObj.PickupType;
    console.log(this.selectedClientObj)
    let date = new Date();
    // date.toLocaleString('en-US', { timeZone: this.selectedClientObj.TimeZone });

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hr = date.getHours();
    let min = date.getMinutes();
    let newDate = moment(new Date(year, month, day - 1, hr, min)).tz(this.selectedClientObj.TimeZone);
    // newDate.set({ hour: 0, minute: 0 })

    newDate.format();
    this.newDate = moment(newDate).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
    pickupTypes.forEach(element => {
      if (element == "Food" || element == "Organic Recycling") {
        let record = this.allpickupList.filter((p) => p['Title'] === element)
        this.pickupList = record;
      }
    });
  }
}
