import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AdmintoolsService } from './admintools.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FeatureTogglesGuard implements CanActivate {
  app_env: string = environment.app_env;
  constructor(
    private router: Router,
    private AdminService: AdmintoolsService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.AdminService.FeatureToggles().pipe(
      map((res) => {
        for (const element of res.Result) {
          if (element.Title === 'OTP' && element.EnableFeature === false) {
            this.router.navigate(['/error']);
          }
        }
        // if (res.Result[0].Title === 'OTP') {
        //   if (res.Result[0].EnableFeature === false) {
        //     this.router.navigate(['/error']);
        //   }
        // }
        return !!res;
      })
    );
  }
}
