import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material";
import { AccountcompletionMenuDialogComponent } from "../accountcompletion-menu-dialog/accountcompletion-menu-dialog.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-unsuccessful-menu-dialog",
  templateUrl: "./unsuccessful-menu-dialog.component.html",
  styleUrls: ["./unsuccessful-menu-dialog.component.scss"]
})
export class UnsuccessfulMenuDialogComponent implements OnInit {
  modalclose: string;
  clientId: any;
  successData: any;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UnsuccessfulMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.successData = this.data;
    this.clientId = this.successData.clientId;
  }

  editItem() {
    this.dialogRef.close();
    this.router.navigate(["FacilityMenuView/" + this.clientId + ""]);
  }
  completeItem() {
    this.dialogRef.close();
    this.router.navigate(["FacilityDetails/" + this.clientId + ""]);
  }

  accountcompletion() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(AccountcompletionMenuDialogComponent, {
      width: "700px",
      height: "570px",
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
}
