import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/core/menu.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'dateCalendar',
  templateUrl: './dateCalendar.component.html',
  styleUrls: ['./dateCalendar.component.scss']
})
export class DateCalendarComponent implements OnInit {
  date: string;
  selecteddate: Date;
  constructor(private router: Router, private menuService: MenuService) { }
  @Input() childdateMessagedata: string;
  @Output() messageEvent = new EventEmitter<string>();

  ngOnInit() {

  }

  selectedDate(e) {
    this.childdateMessagedata = moment(e).format('ll');
    this.messageEvent.emit(this.childdateMessagedata);
  }
}
