import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // public base_url = 'http://40.76.24.23/Goodr.API_deploy/api/GoodrApi/'
  public base_url = environment.environmentUrl;
  public DeliveryStatus = this.base_url + 'DeliveryStatus';
  public getcustomerurl = this.base_url + 'Clients';
  public genericProducts = this.base_url + 'Products?GenericProducts=True';
  public Clients = this.base_url + 'Clients';
  public PortalClient = this.base_url + 'PortalClient';
  public FeatureToggles = this.base_url + 'FeatureToggles';
  public ClientsCount = this.base_url + 'ClientsCount';
  public OrganicRecyclingPickupType = this.base_url + 'OrganicRecyclingPickupType';
  public SeparatedCompost = this.base_url + 'SeparatedCompost';
  public ClientExports = this.base_url + 'ClientExports';
  public UserExports = this.base_url + 'UserExports';
  public DeliveryExports = this.base_url + 'DeliveryExports';
  public DownloadReceipt = this.base_url + 'DownloadReceipt';
  public AspNetRoles = this.base_url + 'AspNetRoles';
  public VersionCheckAPI = this.base_url + 'Versions';
  public inviteUser = this.base_url + 'InviteUser';
  public SelectUser = this.base_url + 'SelectUser';
  public updateUser = this.base_url + 'UpdateUser';
  public AspNetUsers = this.base_url + 'AspNetUsers';
  public PortalUser = this.base_url + 'PortalUser';
  public Status = this.base_url + 'ApprovalStatus';
  public ClientCategories = this.base_url + 'ClientCategories';
  public Deliveries = this.base_url + 'Deliveries';
  public NPAlerts = this.base_url + 'Alerts';
  public PortalDelivery = this.base_url + 'PortalDelivery';
  public DeliveriesCount = this.base_url + 'DeliveriesCount';
  public Dasboard = this.base_url + 'DashBoard';
  public CompostDashBoard = this.base_url + 'CompostDashBoard';
  public RoadiesPickup = this.base_url + 'RoadiesPickup';
  public AutoServiceProvider = this.base_url + 'AutoServiceProvider';
  public PostmatesPickup = this.base_url + 'PostmatesPickup';
  public Login = this.base_url + 'Login';
  public ClientTypes = this.base_url + 'ClientTypes';
  public getcustomerdetailsurl = this.base_url + '';
  public loginUserUrl = this.base_url + '?Action=v2_validateclient';
  public loginpwdUrl = this.base_url + '?Action=v2_resetpassword';
  public trackPickupUrl = this.base_url + 'Deliveries';
  public userDetailUrl = this.base_url + 'AspNetUsers';
  public clientTypeUrl = this.base_url + 'clientTypes';
  public ProductSections = this.base_url + 'ProductSections';
  public MenuTypes = this.base_url + 'MenuTypes';
  public clientdetailsurl = this.base_url + 'AspNetUsers?pagingparametermodel.clientid=';
  public SAASClientUrl = this.base_url + 'AspNetUsers?pagingparametermodel.';
  public FGSAASClientUrl = this.base_url + 'SaasDriver';
  public getAccountTypesurl = this.base_url + 'ClientCategories';
  public productDetailUrl = this.base_url + 'Products?action=pagingparametermodel';
  public SendRequestUrl = this.base_url + 'SendRequest';
  public PickupRequestProducts = this.base_url + 'PickupRequestProducts';
  public editMenuCompostRequest = this.base_url + 'PickupRequestOrganicRecycling';
  public ForgotPasswordEmailUrl = this.base_url + 'ForgotPasswordEmail';
  public NonProfitWorkingSlots = this.base_url + 'NonProfitWorkingSlots';
  public SAASConversion = this.base_url + 'SAASConversion';
  public ResetPasswordUrl = this.base_url + 'ResetPasswordByAdmin';
  public ForgotPasswordResetCodeUrl = this.base_url + 'ForgotPasswordResetCode';
  public ForgotPasswordUrl = this.base_url + 'ForgotPassword';
  public productUrl = this.base_url + 'Products';
  public categoriesUrl = this.base_url + 'ProductSections';
  public Roles = this.base_url + 'AspNetRoles';
  public ApprovalStatus = this.base_url + 'ApprovalStatus';
  public PickupType = this.base_url + 'DriverServices';
  public CompostPickupTypes = this.base_url + 'PickupTypes';
  public NotificationTypes = this.base_url + 'NotificationTypes';
  public UpdatePassword = this.base_url + 'UpdatePassword';
  public PickupRequests = this.base_url + 'PickupRequests';
  // public GrabUrlForClient = "http://gdr-dev-api.eastus.cloudapp.azure.com/Goodr.API_deploy/api/GoodrApi/?Action=v2_getgrabclients";
  public GrabClientUrl = this.base_url + 'GrabClientDetails';
  public GrabUrl = this.base_url + 'GrabMenuFetch';
  public nonprofitlistUrl = this.base_url + 'NonProfitsList';
  public mrflistUrl = this.base_url + 'NonProfitsList?mrf=true&State=';
  public pickupfilterlistUrl = this.base_url + 'PickupTypes';
  public regenerateDonationURL = this.base_url + 'PDFGenerator';
  public PDFForDonation = this.base_url + '';
  public register = this.base_url + 'SAASClient';
  public Stripe = this.base_url + 'Stripe';
  public StripePlanURL = this.base_url + 'StripePlan';
  public OneTimePickup = this.base_url + 'OneTimePickup';
  public pickupstatesURL = this.base_url + 'States';
  public VehicleTypes = this.base_url + 'VehicleTypes';
  public Regions = this.base_url + 'Regions';
  public cityURL = this.base_url + 'City';
  public pickupcityURL = this.base_url + 'City?pagingparametermodel.stateId=';
  public NonSAASFG = this.base_url + 'PortalClient?pagingparametermodel.nonPaginateClient=true&pagingparametermodel.saaSFG=true';
  public SAASFG = this.base_url + 'PortalClient?pagingparametermodel.nonPaginateClient=true&pagingparametermodel.saaSFG=false';
  public SAASPlans = this.base_url + 'SAASPlans?pagingparametermodel.ApprovalStatus=Active';
  public SAASPlanStripePlanId = this.base_url + 'SAASPlans?pagingparametermodel.StripePlanId=';
  public SAASPlansput = this.base_url + 'SAASPlans';
  public PickupPrice = this.base_url + 'PickupPrice';
  public ValidateCoupon = this.base_url + 'ValidateCoupon';
  public PromotionCode = this.base_url + 'StripeCoupons';
  public GoodrLogin = this.base_url + 'GoodrLogin';
  public getAllUsersClients = this.base_url + 'PortalClient?pagingparametermodel.nonPaginateClient=true';
  public NonProfitAvailability = this.base_url + 'NonProfitAvailability';
  // public allClients = this.base_url + 'PortalClient?pagingparametermodel.nonPaginateClient=true';
  public distanceMatrix = this.base_url + 'DistanceMatrix';
  public PortalPickupContract = this.base_url + 'PortalPickupContract';
  public ContractPeriod = this.base_url + 'ContractPeriods';  
  public PickupContract = this.base_url + 'PickupContracts';
  public ContractBilling = this.base_url + 'ContractBillings';
  public QuickbookAPI = this.base_url + 'Quickbooks';
  public invoicePaymentStatus = this.base_url + 'PaymentStatusTypes';
  public invoice = this.base_url + 'Invoice';

  constructor() {}
}
