import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'time24to12'
})
export class Time24to12Pipe implements PipeTransform {
  // transform(value: any, ...args: any[]): any {
    // return null;
  // }
   transform(date: any): any {
    if (date) {
      const updateDate = moment(date, ['HH.mm']).format('hh:mm a');
      return updateDate;
      }
    return null;
  }
}
