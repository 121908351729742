import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AppState } from 'src/app/shared/app-state';
import { MyOrderByPipe } from 'src/app/shared/pipes/sort.pipe';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'app-addeditfilter',
  templateUrl: './addeditfilter.component.html',
  styleUrls: ['./addeditfilter.component.scss']
})
export class AddeditfilterComponent implements OnInit {
  ClientData: any;
  ClientList: any;
  approvalStatusData: any;
  approvalStatusList: any;
  rolesData: any;
  rolesList: any;
  firstName: any;
  lastName: any;
  customerEmail: any;
  selectedAccount: any;
  selectedStatus: any;
  selectedRole: any;
  selectedAccountName: any;
  infoallclients: any;

  constructor(
    private formBuilder: FormBuilder,
    public orderService: MyOrderByPipe,
    public dialogRef: MatDialogRef<AddeditfilterComponent>,
    private ClientFilterdata: ClientFilterService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private AdminService: AdmintoolsService,
    private state: AppState
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.firstName = this.data['FirstName'];
    this.lastName = this.data['LastName'];
    this.customerEmail = this.data['Email'];
    // this.ClientData = this.state.user.User.Clients;
    this.ClientFilterdata.basicInfoObserveclient.subscribe(res => {
      this.infoallclients = JSON.parse(res);
    });
    // this.AdminService.getClientwithoutPagination().subscribe(
    //   clientData => {
    this.ClientData = this.infoallclients.allClients;
    const clients = [];
    for (let i = 0; i < this.ClientData.length; i++) {
      const clients_data = {
        ClientId: this.ClientData[i].ClientId,
        Name: this.ClientData[i].Name
      };
      clients.push(clients_data);
    }
    this.ClientList = clients;
    for (var i = 0; i < this.ClientList.length; i++) {
      if (this.ClientList[i].ClientId == this.data['Account']) {
        this.selectedAccount = this.ClientList[i].ClientId;
        this.selectedAccountName = this.ClientList[i].Name;
      }
    }
    // });
    this.selectedStatus = this.data['Status'];
    this.selectedRole = this.data['Role'];
    this.AdminService.getUserStatus().subscribe(
      data => {
        this.approvalStatusData = data;
        const status = [];
        for (let i = 0; i < data['Result'].length; i++) {
          this.approvalStatusData = data['Result'][i];
          const status_data = {
            Id: this.approvalStatusData.ID,
            Title: this.approvalStatusData.Title
          };
          status.push(status_data);
        }
        // this.approvalStatusList = status;
        this.approvalStatusList = status.filter(
          value => value.Title !== 'Trash'
        );
        for (var i = 0; i < this.approvalStatusList.length; i++) {
          if (this.approvalStatusList[i].Title == this.data['Status']) {
            this.selectedStatus = this.approvalStatusList[i].Title;
          }
        }
        this.approvalStatusList = this.orderService.transform(
          this.approvalStatusList,
          'Title'
        );
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );

    this.AdminService.getRoles().subscribe(
      data => {
        this.rolesData = data;
        const roles = [];
        for (let i = 0; i < data['Result'].length; i++) {
          this.rolesData = data['Result'][i];
          const roles_data = {
            Id: this.rolesData.Id,
            Name: this.rolesData.Name
          };
          roles.push(roles_data);
        }
        this.rolesList = roles;
        for (var i = 0; i < this.rolesList.length; i++) {
          if (this.rolesList[i].Name == this.data['Role']) {
            this.selectedRole = this.rolesList[i].Name;
          }
        }
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  selectedaccount() {
    for (const row of this.ClientList) {
      if (row.ClientId === this.selectedAccount) {
        this.selectedAccount = row.ClientId;
        this.selectedAccountName = row.Name;
      }
    }
  }
  closeAddFilter(closeData) {
    if (closeData === 'firstName') {
      this.firstName = null;
    } else if (closeData === 'lastName') {
      this.lastName = null;
    } else if (closeData === 'customerEmail') {
      this.customerEmail = null;
    } else if (closeData === 'selectedAccount') {
      this.selectedAccount = null;
    } else if (closeData === 'selectedRole') {
      this.selectedRole = null;
    } else if (closeData === 'selectedStatus') {
      this.selectedStatus = null;
    }
  }
  userFilter() {
    if (this.firstName === undefined) {
      this.firstName = null;
    }
    if (this.lastName === undefined) {
      this.lastName = null;
    }
    if (this.customerEmail === undefined) {
      this.customerEmail = null;
    }
    if (this.selectedAccount === undefined) {
      this.selectedAccount = null;
    }
    if (this.selectedRole === undefined) {
      this.selectedRole = null;
    }
    if (this.selectedStatus === undefined) {
      this.selectedStatus = null;
    }
    const data = {
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.customerEmail,
      Account: this.selectedAccount,
      Role: this.selectedRole,
      Status: this.selectedStatus,
      selectedAccountName: this.selectedAccountName
    };
    this.dialogRef.close(data);
  }
}
