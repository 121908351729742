import { Component, OnInit, Inject } from '@angular/core';
import { EditBusinessDialogComponent } from '../../edit-business-dialog/edit-business-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { AccountCreationDialogComponent } from '../../account-creation-dialog/account-creation-dialog.component';
import { FacilityAccountDialogComponent } from '../../facility-account-dialog/facility-account-dialog.component';
import { CreateMenuDialogComponent } from '../../create-menu-dialog/create-menu-dialog.component';
import { EdituserComponent } from '../../user/edituser/edituser.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';

@Component({
  selector: 'app-activebreadcrumb',
  templateUrl: './activebreadcrumb.component.html',
  styleUrls: ['./activebreadcrumb.component.scss'],
})
export class ActivebreadcrumbComponent implements OnInit {
  modalclose: string;
  info: any;
  newAccountstatus: boolean;
  businessstatus: boolean;
  createMenustatus: boolean;
  userDetailsstatus: boolean;
  ImportStatus: boolean = true;
  showCreateMenuOption: boolean;
  showUserDetail: boolean;

  constructor(
    public dialog: MatDialog,
    public _rdata: modalDataService,
    public dialogRef: MatDialogRef<FacilityAccountDialogComponent>,
    public BackRef: MatDialogRef<AccountCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.data['importstatus'];
    if (this.data['importstatus'] === false) {
      this.ImportStatus = false;
    }
    this._rdata.basicInfo2Observe.subscribe((res) => {
      this.info = JSON.parse(res);

      if (this.info.facility_type) {
        if (this.info.facility_type === 'Facility' && this.info.pickupTypes.includes(1)) {
          this.showCreateMenuOption = true;
        } else {
          this.showCreateMenuOption = false;
        }

        if (this.info.facility_type === 'MRF') {
          this.showUserDetail = false;
        } else {
          this.showUserDetail = true;
        }
      }

      if (this.info.accountTypetrue === true) {
        this.newAccountstatus = true;
      }
      if (this.info.business_Detail === true) {
        this.businessstatus = true;
      }
      if (this.info.user_Details === true) {
        this.userDetailsstatus = true;
      }
      if (this.info.create_Menu === true) {
        if (this.info.facility_type === 'Facility') {
          this.createMenustatus = true;
        }
      }
    });
  }
  selectAccountType() {
    if (this.businessstatus) {
      this.dialogRef.close(this.modalclose);
      const dialogRef = this.dialog.open(EditBusinessDialogComponent, {
        width: '1220px',
        height: '785px',
        panelClass: 'business-detail-container',
        data: {},
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  createaccounttype() {
    this.dialogRef.close(this.modalclose);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(AccountCreationDialogComponent, {
      width: '1220px',
      height: '785px',
      // disableClose: true,
      panelClass: 'account-creation-dialog',
      data: {},
    });
    dialogRef.afterClosed().subscribe((selection) => {});
  }

  createNewAccount() {
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(FacilityAccountDialogComponent, {
      width: '1220px',
      height: '785px',
      // height: "530px",
      // disableClose: true,
      panelClass: 'custom-Adddialog-container',
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  edituserdetails() {
    if (this.userDetailsstatus) {
      this.dialogRef.close(this.modalclose);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef.close(this.modalclose);
      const dialogRef = this.dialog.open(EdituserComponent, {
        width: '1220px',
        height: '785px',
        // disableClose: true,
        // height: "530px",this.facility_type
        panelClass: 'user-details-dialog',
        data: {},
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
  createmenus() {
    if (this.createMenustatus) {
      this.dialogRef.close(this.modalclose);
      const dialogRef = this.dialog.open(CreateMenuDialogComponent, {
        width: '1220px',
        height: '785px',
        // height: "570px",
        // disableClose: true,
        panelClass: 'create-menu-dialog',
        data: {
          importstatus: this.ImportStatus,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  showCreateMenu() {
    this.showCreateMenuOption = true;
  }

  hideCreateMenu() {
    this.showCreateMenuOption = false;
  }

  hideUserDetails() {
    this.showUserDetail = false;
  }

  showUserDetails() {
    this.showUserDetail = true;
  }
}
