import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'datepostFormatPipe'
})
export class DatePostFormatPipe implements PipeTransform {
  transform(date, zone) {
    if (moment(date).isValid()) {
      let zonedata;
      if (zone) {
        zonedata = zone;
      } else {
        zonedata = 'America/New_York';
      }
      // var m = moment.tz('2020-12-18T04:00', 'America/Chicago');
      // m.format(); // 2013-11-18T11:55:00-05:00
      // m.startOf('day').format(); // 2013-11-18T00:00:00-05:00
      // m.tz('America/New_York').format(); // 2013-11-18T06:00:00+01:00
      // m.startOf('day').format(); // 2013-11-18T00:00:00+01:00
      // console.log(m);
      // console.log(
      //   m.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00')
      // );
      // let datatime = date;
      // const abbr = moment.tz.zone(zonedata).abbr(360);

      // if (abbr == 'MST') {
      //   var m = moment.tz(date, zonedata);
      //   m.format();
      //   const updateDate = m
      //     .tz('America/New_York')
      //     // .subtract(60, 'minutes')
      //     .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
      //   return updateDate;
      // } else {
        var m = moment.tz(date, zonedata);
        m.format();
        const updateDate = m
          .tz('America/New_York')
          .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
        return updateDate;
      }
      // const updateDate = moment(date)
      //   // .tz(timezone)
      //   .tz('America/New_York')
      //   .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss')
      //   .toString();
      // moment.utc(date).clone().tz('America/New_York')
      // .format().split('T')[0] + 'T' + moment.utc(date).clone().tz('America/New_York')
      //   .format().split('T')[1].split('-')[0].split('+')[0];
    // }
    return null;
  }
}
