import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { AppState } from 'src/app/shared/app-state';
import { environment } from 'src/environments/environment';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';

@Component({
  selector: 'app-receipt-success',
  templateUrl: './receipt-success.component.html',
  styleUrls: ['./receipt-success.component.scss']
})
export class ReceiptSuccessComponent implements OnInit, OnDestroy {
  portalAccess = 'false';
  yearlyPay: any;
  confDate: string;
  user: any;
  editClientID: any;
  clientData: any;
  yearlyPlan: boolean;

  constructor(
    private router: Router,
    private state: AppState,
    private sassService: SAASService,
    private pricingModule: PricingModuleServiceService
  ) {}

  ngOnInit() {
    JSON.parse(localStorage.getItem('updateheckout'));
    this.portalAccess = localStorage.getItem('updateheckout');
    if (this.portalAccess === 'true') {
      this.user = this.state.user;
      if (this.user.User.Roles[0].Name === 'Facility Group') {
        if (this.user.User.Clients[0].DriverService === 'SAAS') {
          this.editClientID = this.user.FacilityGroupId;
        }
      }
      if (this.user.User.Roles[0].Name === 'Facility') {
        if (this.user.User.Clients[0].DriverService === 'SAAS') {
          this.editClientID = this.user.User.Clients[0].ClientId;
        }
      }
      this.sassService.getSubDetails(this.editClientID).subscribe(data => {
        this.clientData = data.Result[0];
        this.pricingModule
          .getsubscriptionSAAS(this.clientData.StripePlanId)
          .subscribe(res => {
            let price;
            price = res['Result'];
            for (const status of price) {
              if (this.clientData.StripePlanId === status.StripePlanId) {
                if (status.SubscriptionType === 'month') {
                  // saasprice = status.Price;
                  this.yearlyPlan = false;
                  this.confDate = moment(new Date())
                    .add(1, 'M')
                    .format('MM/DD/YY');
                } else {
                  // saasprice = status.Price;
                  this.yearlyPlan = true;
                  this.confDate = moment(new Date())
                    .add(1, 'y')
                    .format('MM/DD/YY');
                }
              }
            }
          });
        // if (this.clientData.StripePlanId === environment.yearlyKey) {
        //   this.yearlyPlan = true;
        //   this.confDate = moment(new Date()).add(1, 'y').format('MM/DD/YY');
        // } else {
        //   this.yearlyPlan = false;
        //   this.confDate = moment(new Date()).add(1, 'M').format('MM/DD/YY');
        // }
      });
    } else {
      this.yearlyPay = JSON.parse(localStorage.getItem('accountType'));
      if (this.yearlyPay === 'montly') {
        this.confDate = moment(new Date())
          .add(1, 'M')
          .format('MM/DD/YY');
      } else {
        this.confDate = moment(new Date())
          .add(1, 'y')
          .format('MM/DD/YY');
      }
    }
  }
  ngOnDestroy() {
    localStorage.removeItem('accountType');
    localStorage.removeItem('multiplefacilities');
    localStorage.removeItem('saasFG');
  }
  goToCheckoutPage() {
    this.router.navigate(['checkout']);
  }
}
