import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment-timezone';
import { modalDataService } from 'src/app/shared/services/modalData.service';

@Component({
  selector: 'app-time-select-dialog',
  templateUrl: './time-select-dialog.component.html',
  styleUrls: ['./time-select-dialog.component.scss'],
})
export class TimeSelectDialogComponent implements OnInit {
  StartTime: any;
  EndTime: any;
  futureValid: boolean;
  minValid: boolean;
  ShowStartTime: any;
  ShowEndTime: any;
  show24HourFormat: boolean = true;
  timeError: boolean = false;
  timeErrorMsg;

  constructor(
    public dialogRef: MatDialogRef<TimeSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private observedData: modalDataService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.StartTime = new Date();
    this.EndTime = new Date();

    if (this.data[0].value.StartTime) {
      this.ShowStartTime = moment(this.data[0].value.StartTime, ['HH.mm']).format('hh:mm a');

      let startTimeArray = this.data[0].value.StartTime.split(':');
      this.StartTime.setHours(startTimeArray[0]);
      this.StartTime.setMinutes(startTimeArray[1]);
    } else {
      this.StartTime.setHours(0, 0, 0, 0);
    }

    if (this.data[0].value.EndTime) {
      this.ShowEndTime = moment(this.data[0].value.EndTime, ['HH.mm']).format('hh:mm a');

      let endTimeArray = this.data[0].value.EndTime.split(':');
      this.EndTime.setHours(endTimeArray[0]);
      this.EndTime.setMinutes(endTimeArray[1]);
    } else {
      this.EndTime.setHours(0, 0, 0, 0);
    }
    // this.StartTime = this.data[0].value.StartTime;
    // this.EndTime = this.data[0].value.EndTime;
  }
  onChangeFromDate(date) {
    // this.fromDate = date;
  }
  onChangeToDate(date) {
    // this.toDate = date;
  }
  popupClose() {
    this.timeError = false;
    if (!this.StartTime || !this.EndTime) {
      if (!this.StartTime) {
        this.timeError = true;
        this.timeErrorMsg = 'Select Open Time';
        setTimeout(() => {
          this.timeError = false;
        }, 4000);
      } else {
        this.timeError = true;
        this.timeErrorMsg = 'Select Close Time';
        setTimeout(() => {
          this.timeError = false;
        }, 4000);
      }
    } else {
      let selectedDate = new Date();
      selectedDate.setMinutes(0);
      selectedDate.setHours(0);
      let selectedfirstValue;
      selectedfirstValue = this.ConvertTime(this.StartTime).split(':')[0];

      let selectedSecondValue;
      selectedSecondValue = this.ConvertTime(this.StartTime).split(':')[1];
      selectedDate.setHours(selectedDate.getHours() + selectedfirstValue) +
        ':' +
        selectedDate.setMinutes(selectedDate.getMinutes() + selectedSecondValue);
      let selectedEndDate = new Date();
      selectedEndDate.setMinutes(0);
      selectedEndDate.setHours(0);
      let selectedEndValue;
      selectedEndValue = this.ConvertTime(this.EndTime).split(':')[0];

      let selectedEndSecondValue;
      selectedEndSecondValue = this.ConvertTime(this.EndTime).split(':')[1];
      selectedEndDate.setHours(selectedEndDate.getHours() + selectedEndValue) +
        ':' +
        selectedEndDate.setMinutes(selectedEndDate.getMinutes() + selectedEndSecondValue);
      let dt1 = new Date(selectedDate);
      let dt2 = new Date(selectedEndDate);

      if (this.endAfterStart(dt1, dt2) === true) {
        if (this.diff_hours(dt1, dt2) >= 60) {
          const data = {
            From: ('0' + this.StartTime.getHours()).slice(-2) + ':' + (this.StartTime.getMinutes() < 10 ? '0' : '') + this.StartTime.getMinutes(),
            To: ('0' + this.EndTime.getHours()).slice(-2) + ':' + (this.EndTime.getMinutes() < 10 ? '0' : '') + this.EndTime.getMinutes(),
            WorkingDay: this.data[0].value.WorkingDay,
          };
          this.dialogRef.close(data);
        } else {
          this.minValid = true;
          setTimeout(() => {
            this.minValid = false;
          }, 4000);
        }
      } else {
        this.futureValid = true;
        setTimeout(() => {
          this.futureValid = false;
        }, 4000);
      }
    }
  }

  diff_hours(dt2, dt1) {
    var diff = Math.abs(dt1.getTime() - dt2.getTime());
    return diff / 60000;
  }

  endAfterStart(dt1, dt2) {
    var startDate = new Date(dt1);
    var endDate = new Date(dt2);

    return endDate.getTime() >= startDate.getTime();
  }

  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }
  onChangeEndTime(time) {
    this.EndTime = this.ConvertTime(time);
    // this.showStartTime = moment(this.pickupStartTime, ["HH.mm"]).format("hh:mm a");
    // this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
  onChangeTime(time) {
    this.StartTime = this.ConvertTime(time);
    // this.workingdays.map(value => {
    //  if(value.Id === this.WorkingDaysIDChecked){
    // value.StartTime = this.showstartTime;
    //  }
    // }
    // );
    // this.showStartTime = moment(this.pickupStartTime, ["HH.mm"]).format("hh:mm a");
    // this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
}
