import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { SavePickupmodalComponent } from '../../../save-pickupmodal/save-pickupmodal.component';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import * as moment from 'moment-timezone';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';

declare var Stripe: any;
export interface PeriodicElement {
  name: string;
  Cost: number;
  Weight: number;
  Quantity: number;
}
@Component({
  selector: 'app-onetimepickupregistration',
  templateUrl: './onetimepickupregistration.component.html',
  styleUrls: ['./onetimepickupregistration.component.scss'],
})
export class OnetimepickupregistrationComponent implements OnInit, OnDestroy {
  public numberFormZip: FormGroup;
  public numberForm: FormGroup;
  taxForm: any;
  EmailFG: any;
  imgSrc = '../../assets/images/editicon.png';
  pickupTypeID = [];
  pickupTypeIDFG = [];
  sendRequest: any = [];
  sendRequestElement: any;
  citydataID: any;

  allDeliveryStatus = [
    {
      ID: 1,
      Title: 'Facility',
    },
    {
      ID: 2,
      Title: 'Facility Group',
    },
  ];
  success: boolean;
  dataedit: boolean;
  CompanyTitle: any;
  FirstName: any;
  LastName: any;
  selectedType: any;
  Email: any;
  i = 0;
  PhoneNumber: any;
  PhoneNumberFG: any;
  createDetailsdata = true;
  TaxID: any;
  TaxIDFG: any;
  Address1: any;
  index: any;
  Address2FG: any;
  Address1FG: any;
  Address2: any;
  State: any;
  StateFG: any;
  City: any;
  showedData = [];
  ZipCode: any;
  requireFirstName: boolean;
  requireLastName: boolean;
  requireCompanyTitle: boolean;
  requireselectedType: boolean;
  requireEmail: boolean;
  checkselectedType = false;
  requirePhoneNumber: boolean;
  multipleFGadd = [];
  facilityFGadd = [];
  requireTaxID: boolean;
  requireAddress1: boolean;
  requireState: boolean;
  requireCity: boolean;
  requireZipCode: boolean;
  invalidEmail: boolean;
  Country = 'USA';
  requireCountry: boolean;
  dataerror: string;
  NotVisibileFG: boolean = true;
  VisibleForFG: boolean;
  facility: boolean = true;
  facilitygroup: boolean;
  CompanyTitleFG: any;
  CityFG: any;
  ZipCodeFG: any;
  CountryFG: any;
  fcailityValidation: boolean;
  multipleFGaddFinal: any[];
  requireField: boolean;
  productsData = [];
  dataerrortrue: boolean;
  public createForm: FormGroup;
  displayedColumns: string[] = ['name', 'Price', 'Weight', 'Quantity'];
  displayedColumnsCompost: string[] = ['Title', 'Weight', 'add'];
  ELEMENT_DATA: PeriodicElement[] = [];
  dataSource: any;
  transaction: any = [];
  editbletrue: boolean;
  totalItems = 0;
  requestDataDisabled: boolean;
  onetimebilling: any;
  sameAddress: any;
  pickupCountry = 'USA';
  pickupAddress1: any;
  pickupAddress2: any;
  pickupState: any;
  pickupCity: any;
  pickupZipCode: any;
  requireitems: boolean;
  requirpickup: boolean;
  totalitemCost = 0;
  billingAddress: boolean;
  pickupAddress: boolean;
  pickupitems: boolean;
  requirepickupAddress1: boolean;
  requirepickupState: boolean;
  requirepickupCity: boolean;
  requirepickupZipCode: boolean;
  requirepickupCountry: boolean;
  pickupfinalitems: boolean;
  modalclose: string;
  email: string;
  firstname: string;
  lastname: string;
  cardNumber: string;
  cvc: string;
  stripe: any;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;
  pickupstatesdata: any;
  pickupcitydata: any;
  ID: any;
  pickupstat: any;
  pickupcitydataID: any;
  message: string;
  yearlyPay: any;
  OneTimeItem: any;
  cardpayment: boolean;
  PickupWeight: any;
  varyplanId: any;
  totalpaid: any;
  requireitemsView: boolean;
  selectdate: boolean;
  pickupStartDate: any;
  pickupEndDate: any;
  pickupStartTime: any;
  showStartTime: string;
  showStartDate: Date;
  pickupEndTime: string;
  showEndTime: string;
  showEndDate: Date;
  rodies: boolean;
  roadies: boolean;
  diffTimeless: boolean;
  futureStartValid: boolean;
  futureValid: boolean;
  npId: any;
  selectedDate: any;
  selectedEndTime: any;
  selectedStartTime: string;
  selectedEndDate: any;
  info: any;
  infoClients: any;
  couponCodeDiv: boolean = false;
  coupendata: any = '';
  coupenValue = [];
  errorcoupen: boolean;
  validcoupensdata: any;
  totalfinalpaid: number;
  couponData: boolean;
  PickupAfter: any;
  PickupBefore: any;
  deliverPartner: any;
  DeliveryPartner: any;
  errorcouponexpired: boolean;
  couponExpired: any = '';
  validcoupensexeeddata: boolean;

  constructor(
    private customerServices: CustomerService,
    public dialog: MatDialog,
    public dateFormat: dateFormatPipe,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sassService: SAASService,
    private _rdata: modalDataService,
    private ClientFilterdata: ClientFilterService,
    private spinnerService: SpinnerService,
    private PromotionCodeService: PricingPromotionCodeService
  ) {}

  ngOnInit() {
    this.couponCodeDiv = false;
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.infoClients = JSON.parse(res);
    });
    this._rdata.basicInfo1Observe.subscribe((res) => {
      this.info = JSON.parse(res);
    });
    this.info.pickupsPage = true;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this.pickupstates();
    if (!this.pickupStartDate && !this.pickupEndDate) {
      this.defaultdatetime();
    }
    document.getElementById('cardpayment').style.display = 'none';
    this.onetimebilling = JSON.parse(localStorage.getItem('onetimebilling'));
    let products = JSON.parse(localStorage.getItem('OneTimeItem'));
    if (products) {
      this.productsData = products;
      this.dataSource = new MatTableDataSource<Element>(this.productsData);
      this.totalItems = this.productsData.length - 1;
      this.totalitemCost = this.productsData
        .map((r) => r.Quantity * r.Price)
        .reduce((sum, current) => Number(sum) + Number(current));
    } else {
      const body = {
        Name: '',
        Quantity: '',
        Price: '',
        wasClicked: true,
        Weight: '',
        imgSrc: '../../assets/images/editicon.png',
        index: 1,
        newitem: true,
        deleteoption: false,
      };
      this.productsData.push(body);
      this.dataSource = this.productsData;
    }
    if (this.onetimebilling) {
      this.CompanyTitle = this.onetimebilling[0].CompanyTitle;
      this.FirstName = this.onetimebilling[0].FirstName;
      this.LastName = this.onetimebilling[0].LastName;
      this.selectedType = this.onetimebilling[0].CompanyTitle;
      this.Email = this.onetimebilling[0].Email;
      this.keyPressFirstName(this.Email);
      this.PhoneNumber = this.onetimebilling[0].PhoneNumber;
      this.TaxID = this.onetimebilling[0].TaxID;
      this.Country = this.onetimebilling[0].Country;
      this.Address1 = this.onetimebilling[0].Address1;
      this.Address2 = this.onetimebilling[0].Address2;
      this.State = this.onetimebilling[0].State;
      this.City = this.onetimebilling[0].City;
      this.ZipCode = this.onetimebilling[0].ZipCode;
      (this.sameAddress = this.onetimebilling[0].sameAddress),
        (this.pickupCountry = this.onetimebilling[0].pickupCountry),
        (this.pickupAddress1 = this.onetimebilling[0].pickupAddress1),
        (this.pickupAddress2 = this.onetimebilling[0].pickupAddress2),
        (this.pickupState = this.onetimebilling[0].pickupState),
        (this.pickupCity = this.onetimebilling[0].pickupCity),
        (this.pickupZipCode = this.onetimebilling[0].pickupZipCode);
      this.selectdate = this.onetimebilling[0].selectdate;
      this.pickupStartDate = this.onetimebilling[0].PickupAfter;
      this.pickupEndDate = this.onetimebilling[0].PickupBefore;
      if (this.pickupStartDate) {
        this.pickupStartTime = this.pickupStartDate
          .split('T')[1]
          .split('+')[0]
          .split('-')[0];
        this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
          'hh:mm a'
        );
        this.showStartDate = new Date(
          moment(this.pickupStartDate).format('YYYY/MM/DD')
        );
      } else {
        this.defaultdatetime();
      }
      if (this.pickupEndDate) {
        this.pickupEndTime =
          new Date(this.pickupEndDate).getHours() +
          ':' +
          new Date(this.pickupEndDate).getMinutes() +
          ':' +
          '00';
        this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format(
          'hh:mm a'
        );
        this.showEndDate = new Date(
          moment(this.pickupEndDate).format('YYYY/MM/DD')
        );
      } else {
        this.defaultdatetime();
      }
      // this.selectedpickupcity();
      this.sassService.pickupcitydetails(this.State).subscribe((data) => {
        this.citydataID = data['Result'];
        for (var i = 0; i < this.citydataID.length; i++) {
          if (this.citydataID[i].ID === this.City) {
            this.City = this.citydataID[i].ID;
          }
        }
      });
      this.sassService.pickupcitydetails(this.pickupState).subscribe((data) => {
        this.pickupcitydataID = data['Result'];
        for (var i = 0; i < this.pickupcitydataID.length; i++) {
          if (this.pickupcitydataID[i].ID == this.pickupCity) {
            this.pickupCity = this.pickupcitydataID[i].ID;
          }
        }
      });
    }
    this.createForm = this.formBuilder.group({
      quantity: ['', Validators.compose([Validators.required])],
      Weight: ['', Validators.compose([Validators.required])],
      Price: ['', Validators.compose([Validators.required])],
    });
    this.numberForm = this.formBuilder.group({
      number: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
    this.numberFormZip = this.formBuilder.group({
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
        ]),
      ],
    });
    this.taxForm = this.formBuilder.group({
      tax: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
    // this.OneTimeItem.pop();
    // console.log(this.OneTimeItem)
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();
    this.cardNumberElement = elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number-element');
    this.cardNumberElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardExpiryElement = elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry-element');
    this.cardExpiryElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardCvcElement = elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc-element');
    this.cardCvcElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    if (this.OneTimeItem) {
      this.totalitemCost = this.OneTimeItem.map(
        (r) => r.Quantity * r.Price
      ).reduce((sum, current) => Number(sum) + Number(current));
    }
  }
  selectedAddress() {
    if (this.sameAddress === true) {
      (this.pickupCountry = this.Country),
        (this.pickupAddress1 = this.Address1),
        (this.pickupAddress2 = this.Address2),
        (this.pickupState = this.State),
        (this.pickupCity = this.City),
        (this.pickupZipCode = this.ZipCode);
      this.sassService.pickupcitydetails(this.pickupState).subscribe((data) => {
        this.pickupcitydataID = data['Result'];
        for (var i = 0; i < this.pickupcitydataID.length; i++) {
          if (this.pickupcitydataID[i].ID == this.pickupCity) {
            this.pickupCity = this.pickupcitydataID[i].ID;
            // this.selectedpickupcity();
          }
        }
      });
    } else {
      (this.pickupCountry = this.pickupCountry),
        (this.pickupAddress1 = this.pickupAddress1),
        (this.pickupAddress2 = this.pickupAddress2),
        (this.pickupState = this.pickupState),
        (this.pickupCity = this.pickupCity),
        (this.pickupZipCode = this.pickupZipCode);
      this.sassService.pickupcitydetails(this.pickupState).subscribe((data) => {
        this.pickupcitydataID = data['Result'];
        for (var i = 0; i < this.pickupcitydataID.length; i++) {
          if (this.pickupcitydataID[i].ID == this.pickupCity) {
            this.pickupCity = this.pickupcitydataID[i].ID;
          }
        }
      });
    }
  }
  onSearchChange(event) {
    this.dataedit = true;
    this.success = false;
  }
  keyPressFirstName(event: any) {
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateClient: true,
        OneTimeClient: { Email: event },
      };
      this.sassService.otpreigster(body1).subscribe((data) => {
        if (data.message === 'Client already exist') {
          this.dataerrortrue = true;
          this.dataerror = data.message;
        } else {
          this.dataerrortrue = false;
        }
      });
    } else {
      this.dataerrortrue = false;
    }
  }

  validCoupen() {
    this.validcoupensdata = '';
    const payload = {
      PromoCodeName: this.coupendata,
      ValidateOTP: true,
      OTPPrice: this.totalpaid,
    };
    this.PromotionCodeService.validCoupen(payload).subscribe(
      (data) => {
        if (
          data.message ===
          'Coupon duration is month which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is year which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (data.message === 'Coupon was in error') {
          this.checkForError();
        } else if (
          data.message === 'Invalidate Coupon due to price paramters'
        ) {
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.validcoupensexeeddata = true;
          setTimeout(() => {
            this.validcoupensexeeddata = false;
          }, 3000);
        } else if (data.message === 'Coupon is not once Duration Type') {
          this.checkForError();
        } else if (
          data.message.includes('Coupon validity is already expired')
        ) {
          this.couponExpired = data.message;
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.errorcouponexpired = true;
          setTimeout(() => {
            this.errorcouponexpired = false;
          }, 5000);
        } else if (
          data.message ===
          'Object reference not set to an instance of an object.'
        ) {
          this.checkForError();
        } else {
          if (data.message.includes('Coupon is Valid')) {
            this.validcoupensdata = 'Coupon Applied';
            this.coupenValue = [];
            var r = /\d+/g;
            // var s = "you can enter 333 maximum 500 choices";
            var m;
            while ((m = r.exec(data.message)) != null) {
              this.coupenValue.push(m[0]);
            }

            this.totalfinalpaid = this.totalpaid - this.coupenValue[0];
          } else {
            alert('Something wrong with Coupon');
          }
        }
      },
      (error) => {
        // alert(JSON.stringify(error));
      }
    );
  }
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.Email) === false) {
      return false;
    }
    return true;
  }
  validateEmailFG() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.EmailFG) === false) {
      return false;
    }
    return true;
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  addItems(transaction, i) {
    if (
      transaction.Quantity > 0 &&
      transaction.Weight > 0 &&
      transaction.Price > 0
    ) {
      if (
        transaction.Quantity &&
        transaction.Price &&
        transaction.Weight &&
        transaction.Name
      ) {
        this.addItemsList(transaction, i);
      } else {
        transaction.nameShowValidators = true;
        setTimeout(() => {
          transaction.nameShowValidators = false;
        }, 3000);
      }
    } else {
      transaction.showValidators = true;
      setTimeout(() => {
        transaction.showValidators = false;
      }, 3000);
    }
  }

  addItemsList(transaction, i) {
    if (transaction.wasClicked === false) {
      transaction.wasClicked = true;
      transaction.buttonDisabled = false;
      transaction.showValidators = false;
      if (transaction.quantityZero === true) {
        transaction.QuanbuttonDisabled = false;
      }
      if (transaction.weightZero === true) {
        transaction.WeigthbuttonDisabled = false;
      }
      // }
      transaction.imgSrc = '../../assets/images/editicon.png';
    } else {
      if (transaction.wasClicked === true) {
        transaction.wasClicked = false;
      }
      transaction.buttonDisabled = true;
      transaction.imgSrc = '../../assets/images/circle12.png';
      // if (transaction.Sections[0].Name === 'New Items') {
      transaction.newitem = false;
      transaction.deleteoption = true;
      // this.index = i + 1;
      const sendbody = {
        Name: transaction.Name,
        Quantity: transaction.Quantity,
        Sections: [{ SectionId: 0, Name: 'New Items' }],
        Price: transaction.Price,
        Weight: transaction.Weight,
        imgSrc: '../../assets/images/circle12.png',
        wasClicked: transaction.wasClicked,
        index: transaction.index,
        newitem: transaction.newitem,
        deleteoption: transaction.deleteoption,
      };
      const body = {
        Name: '',
        Quantity: '',
        Price: '',
        wasClicked: true,
        Weight: '',
        imgSrc: '../../assets/images/editicon.png',
        index: transaction.index + 1,
        newitem: true,
        deleteoption: false,
      };
      for (let i = 0; i < this.productsData.length; i++) {
        if (transaction.ProductId === 0) {
          if (transaction.index === this.productsData[i].index) {
            this.productsData.splice(i, 1);
            this.productsData.push(sendbody);
          }
        }
      }
      let newitemcount = 0;
      for (let i = 0; i < this.productsData.length; i++) {
        if (this.productsData[i].newitem === true) {
          newitemcount++;
        }
      }
      if (newitemcount === 0) {
        this.productsData.push(body);
      }
      this.totalItems = this.productsData.length - 1;
      this.totalitemCost = this.productsData
        .map((r) => r.Quantity * r.Price)
        .reduce((sum, current) => Number(sum) + Number(current));
      this.dataSource = new MatTableDataSource<Element>(this.productsData);
    }
  }
  deletePickup(product) {
    // const index = this.productsData.indexOf(product);
    // this.productsData.splice(index, 1);
    for (let i = 0; i < this.productsData.length; i++) {
      if (product.index === this.productsData[i].index) {
        this.productsData.splice(i, 1);
      }
    }
    for (let i = 0; i < this.showedData.length; i++) {
      if (product.index === this.showedData[i].index) {
        this.showedData.splice(i, 1);
      }
    }
    this.totalItems = this.productsData.length - 1;
    this.totalitemCost = this.productsData
      .map((r) => r.Quantity * r.Price)
      .reduce((sum, current) => Number(sum) + Number(current));
    this.dataSource = new MatTableDataSource<Element>(this.productsData);
    this.sendRequestElement = new MatTableDataSource<Element>(this.showedData);
  }
  createDetails() {
    if (this.PhoneNumber) {
      this.numberForm.controls.number.setValue(numberSetMask(this.PhoneNumber));
    }
    if (
      this.CompanyTitle &&
      this.CompanyTitle !== undefined &&
      this.CompanyTitle !== null
    ) {
      if (
        this.FirstName &&
        this.FirstName !== undefined &&
        this.FirstName !== null
      ) {
        if (
          this.LastName &&
          this.LastName !== undefined &&
          this.LastName !== null
        ) {
          if (this.TaxID && this.TaxID !== undefined && this.TaxID !== null) {
            // if (this.PhoneNumber && this.PhoneNumber !== undefined && this.PhoneNumber !== null) {
            if (this.numberForm.valid) {
              if (
                this.Email &&
                this.Email !== undefined &&
                this.Email !== null
              ) {
                if (this.dataerrortrue === false) {
                  if (this.validateEmail() === true) {
                    this.billingAddress = true;
                    // this.roadies = false;
                    this.createDetailsdata = false;
                    this.pickupAddress = false;
                    this.facilityFGadd = [];
                    const body1 = {
                      CompanyTitle: this.CompanyTitle,
                      FirstName: this.FirstName,
                      LastName: this.LastName,
                      Account: this.selectedType,
                      Email: this.Email,
                      PhoneNumber: this.PhoneNumber,
                      TaxID: this.TaxID,
                      pickupCountry: this.pickupCountry,
                      pickupAddress1: this.pickupAddress1,
                      pickupAddress2: this.pickupAddress2,
                      pickupState: this.pickupState,
                      pickupCity: this.pickupCity,
                      pickupZipCode: this.pickupZipCode,
                      sameAddress: this.sameAddress,
                      Country: this.Country,
                      Address1: this.Address1,
                      Address2: this.Address2,
                      State: this.State,
                      City: this.City,
                      ZipCode: this.ZipCode,
                    };

                    this.facilityFGadd.push(body1);
                    localStorage.setItem(
                      'onetimebilling',
                      JSON.stringify(this.facilityFGadd)
                    );
                    localStorage.setItem(
                      'OneTimeItem',
                      JSON.stringify(this.productsData)
                    );
                  } else {
                    this.invalidEmail = true;
                    setTimeout(() => {
                      this.invalidEmail = false;
                    }, 3000);
                  }
                }
              } else {
                this.requireEmail = true;
                setTimeout(() => {
                  this.requireEmail = false;
                }, 3000);
              }
            } else {
              this.requirePhoneNumber = true;
              setTimeout(() => {
                this.requirePhoneNumber = false;
              }, 3000);
            }
          } else {
            this.requireTaxID = true;
            setTimeout(() => {
              this.requireTaxID = false;
            }, 3000);
          }
        } else {
          this.requireLastName = true;
          setTimeout(() => {
            this.requireLastName = false;
          }, 3000);
        }
      } else {
        this.requireFirstName = true;
        setTimeout(() => {
          this.requireFirstName = false;
        }, 3000);
      }
    } else {
      this.requireCompanyTitle = true;
      setTimeout(() => {
        this.requireCompanyTitle = false;
      }, 3000);
    }
  }
  // selectedpickupcity() {
  //   let body = {
  //     DriverService: true,
  //     StateId: this.pickupState,
  //     CityId: this.pickupCity,
  //   };
  //   this.sassService.onetimebillingStripePlan(body).subscribe((data) => {
  //     console.log(data.Roadies);
  //     if (data.Roadies === true) {
  //       this.selectdate = true;
  //       if (!this.pickupStartDate && !this.pickupEndDate) {
  //         this.defaultdatetime();
  //       }
  //     } else {
  //       this.selectdate = false;
  //     }
  //   });
  // }
  onChangeEndDate(date) {
    this.selectedEndDate = date;
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  onChangeEndTime(time) {
    this.pickupEndTime = this.ConvertTime(time);
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  //Date change event(end date-delivery)
  updateEndDateTime(selectedEndDate, pickupEndTime) {
    if (selectedEndDate) {
      selectedEndDate = selectedEndDate;
    } else {
      selectedEndDate = new Date(this.pickupEndDate);
    }
    selectedEndDate.setMinutes(0);
    selectedEndDate.setHours(0);
    let selectedfirstValue;
    selectedfirstValue = pickupEndTime.split(':')[0];

    let selectedSecondValue;
    selectedSecondValue = pickupEndTime.split(':')[1];
    selectedEndDate.setHours(selectedEndDate.getHours() + selectedfirstValue) +
      ':' +
      selectedEndDate.setMinutes(
        selectedEndDate.getMinutes() + selectedSecondValue
      );
    this.selectedEndDate = selectedEndDate;

    let selectedEndDateSplit;
    this.selectedEndTime = moment(selectedEndDate).format().toString();
    selectedEndDateSplit =
      this.selectedEndTime.split('T')[0] +
      'T' +
      this.selectedEndTime.split('T')[1].split('-')[0].split('+')[0];
    this.pickupEndDate = selectedEndDateSplit;
  }
  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }

  onChangeDate(date) {
    this.selectedDate = date;
    this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
  onChangeTime(time) {
    this.pickupStartTime = this.ConvertTime(time);
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
      'hh:mm a'
    );
    this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
  //Date change event(start date-pickup)
  updateDateTime(selectedDate, pickupStartTime) {
    if (selectedDate) {
      selectedDate = selectedDate;
    } else {
      selectedDate = new Date(this.pickupStartDate);
    }
    selectedDate.setMinutes(0);
    selectedDate.setHours(0);
    let selectedfirstValue;
    selectedfirstValue = pickupStartTime.split(':')[0];

    let selectedSecondValue;
    selectedSecondValue = pickupStartTime.split(':')[1];
    selectedDate.setHours(selectedDate.getHours() + selectedfirstValue) +
      ':' +
      selectedDate.setMinutes(selectedDate.getMinutes() + selectedSecondValue);
    this.selectedDate = selectedDate;
    this.selectedStartTime = moment(selectedDate).format().toString();
    let selectedStartDateSplit;
    selectedStartDateSplit =
      this.selectedStartTime.split('T')[0] +
      'T' +
      this.selectedStartTime.split('T')[1].split('-')[0].split('+')[0];
    this.pickupStartDate = selectedStartDateSplit;
  }
  defaultdatetime() {
    this.pickupStartDate = new Date();
    const startDate = new Date(
      this.dateFormat.transform(this.pickupStartDate.toISOString(),'America/New_York')
    );
    this.pickupStartDate =
      moment(startDate)
        .add(1, 'hours')
        .add(30, 'minutes')
        .format()
        .toString()
        .split('T')[0] +
      'T' +
      moment(startDate)
        .add(1, 'hours')
        .add(30, 'minutes')
        .format()
        .toString()
        .split('T')[1]
        .split('+')[0]
        .split('-')[0];
    this.pickupStartTime = this.pickupStartDate
      .split('T')[1]
      .split('+')[0]
      .split('-')[0];
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
      'hh:mm a'
    );
    this.showStartDate = new Date(
      moment(this.pickupStartDate).format('YYYY/MM/DD')
    );
    this.pickupEndDate = new Date(this.pickupStartDate);
    this.pickupEndDate =
      moment(this.pickupEndDate)
        .add(2, 'hours')
        .format()
        .toString()
        .split('T')[0] +
      'T' +
      moment(this.pickupEndDate)
        .add(2, 'hours')
        .format()
        .toString()
        .split('T')[1]
        .split('+')[0]
        .split('-')[0];
    this.pickupEndTime =
      new Date(this.pickupEndDate).getHours() +
      ':' +
      new Date(this.pickupEndDate).getMinutes() +
      ':' +
      '00';
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.showEndDate = new Date(
      moment(this.pickupEndDate).format('YYYY/MM/DD')
    );
  }
  pickupdata() {
    if (
      this.pickupAddress1 &&
      this.pickupAddress1 !== undefined &&
      this.pickupAddress1 !== null
    ) {
      if (
        this.pickupState &&
        this.pickupState !== undefined &&
        this.pickupState !== null
      ) {
        if (
          this.pickupCity &&
          this.pickupCity !== undefined &&
          this.pickupCity !== null &&
          this.pickupCity !== 'Select City'
        ) {
          // if (this.pickupZipCode && this.pickupZipCode !== undefined && this.pickupZipCode !== null) {
          if (this.numberFormZip.valid) {
            if (
              this.pickupCountry &&
              this.pickupCountry !== undefined &&
              this.pickupCountry !== null
            ) {
              // console.log(this.selectdate)
              // if(this.selectdate === true){
              // this.roadies = true;
              // this.billingAddress = false;
              // this.createDetailsdata = false;
              // this.pickupAddress = false;
              // this.pickupitems = false;
              // } else {
              this.billingAddress = false;
              this.createDetailsdata = false;
              this.pickupAddress = false;
              this.pickupitems = true;
              // this.roadies = false;
              // }
              this.facilityFGadd = [];
              const body1 = {
                CompanyTitle: this.CompanyTitle,
                FirstName: this.FirstName,
                LastName: this.LastName,
                Account: this.selectedType,
                Email: this.Email,
                PhoneNumber: this.PhoneNumber,
                TaxID: this.TaxID,
                pickupCountry: this.pickupCountry,
                pickupAddress1: this.pickupAddress1,
                pickupAddress2: this.pickupAddress2,
                pickupState: this.pickupState,
                pickupCity: this.pickupCity,
                pickupZipCode: this.pickupZipCode,
                Country: this.Country,
                sameAddress: this.sameAddress,
                Address1: this.Address1,
                Address2: this.Address2,
                State: this.State,
                City: this.City,
                ZipCode: this.ZipCode,
              };
              this.facilityFGadd.push(body1);
              localStorage.setItem(
                'onetimebilling',
                JSON.stringify(this.facilityFGadd)
              );
              localStorage.setItem(
                'OneTimeItem',
                JSON.stringify(this.productsData)
              );
            } else {
              this.requirepickupCountry = true;
              setTimeout(() => {
                this.requirepickupCountry = false;
              }, 3000);
            }
          } else {
            this.requirepickupZipCode = true;
            setTimeout(() => {
              this.requirepickupZipCode = false;
            }, 3000);
          }
        } else {
          this.requirepickupCity = true;
          setTimeout(() => {
            this.requirepickupCity = false;
          }, 3000);
        }
      } else {
        this.requirepickupState = true;
        setTimeout(() => {
          this.requirepickupState = false;
        }, 3000);
      }
    } else {
      this.requirepickupAddress1 = true;
      setTimeout(() => {
        this.requirepickupAddress1 = false;
      }, 3000);
    }
  }
  onSearchChangename(event, transaction) {
    transaction.nameenter = true;
  }
  onSearchChangeweigth(event, transaction) {
    transaction.weigthenter = true;
  }
  onSearchChangeprice(event, transaction) {
    transaction.priceenter = true;
  }
  onSearchChangequanty(event, transaction) {
    transaction.quantyenter = true;
  }
  billingdata() {
    if (
      this.Address1 &&
      this.Address1 !== undefined &&
      this.Address1 !== null
    ) {
      if (this.State && this.State !== undefined && this.State !== null) {
        if (
          this.City &&
          this.City !== undefined &&
          this.City !== null &&
          this.City !== 'Select City'
        ) {
          // if (this.ZipCode && this.ZipCode !== undefined && this.ZipCode !== null) {
          if (this.numberFormZip.valid) {
            if (
              this.Country &&
              this.Country !== undefined &&
              this.Country !== null
            ) {
              this.billingAddress = false;
              // this.roadies = false;
              this.createDetailsdata = false;
              this.couponData = false;
              this.pickupAddress = true;
              this.pickupitems = false;
              this.pickupfinalitems = false;
              this.cardpayment = false;
              document.getElementById('cardpayment').style.display = 'none';
              this.facilityFGadd = [];
              const body1 = {
                CompanyTitle: this.CompanyTitle,
                FirstName: this.FirstName,
                LastName: this.LastName,
                Account: this.selectedType,
                Email: this.Email,
                PhoneNumber: this.PhoneNumber,
                TaxID: this.TaxID,
                pickupCountry: this.pickupCountry,
                pickupAddress1: this.pickupAddress1,
                pickupAddress2: this.pickupAddress2,
                sameAddress: this.sameAddress,
                pickupState: this.pickupState,
                pickupCity: this.pickupCity,
                pickupZipCode: this.pickupZipCode,
                Country: this.Country,
                Address1: this.Address1,
                Address2: this.Address2,
                State: this.State,
                City: this.City,
                ZipCode: this.ZipCode,
              };
              this.facilityFGadd.push(body1);
              localStorage.setItem(
                'onetimebilling',
                JSON.stringify(this.facilityFGadd)
              );
              localStorage.setItem(
                'OneTimeItem',
                JSON.stringify(this.productsData)
              );
            } else {
              this.requireCountry = true;
              setTimeout(() => {
                this.requireCountry = false;
              }, 3000);
            }
          } else {
            this.requireZipCode = true;
            setTimeout(() => {
              this.requireZipCode = false;
            }, 3000);
          }
        } else {
          this.requireCity = true;
          setTimeout(() => {
            this.requireCity = false;
          }, 3000);
        }
      } else {
        this.requireState = true;
        setTimeout(() => {
          this.requireState = false;
        }, 3000);
      }
    } else {
      this.requireAddress1 = true;
      setTimeout(() => {
        this.requireAddress1 = false;
      }, 3000);
    }
  }
  viewDetails() {
    this.billingAddress = false;
    // this.roadies = false;
    this.createDetailsdata = true;
    this.couponData = false;
    this.pickupAddress = false;
    this.pickupitems = false;
    this.pickupfinalitems = false;
    this.cardpayment = false;
    document.getElementById('cardpayment').style.display = 'none';
  }
  viewbillingDetails() {
    this.billingAddress = true;
    // this.roadies = false;
    this.couponData = false;
    this.createDetailsdata = false;
    this.pickupAddress = false;
    this.pickupitems = false;
    this.pickupfinalitems = false;
    this.cardpayment = false;
    document.getElementById('cardpayment').style.display = 'none';
  }
  viewcoupenOrder() {
    this.billingAddress = false;
    // this.roadies = false;
    this.createDetailsdata = false;
    this.pickupAddress = false;
    this.pickupitems = false;
    this.pickupfinalitems = false;
    this.cardpayment = false;
    this.couponData = true;
    document.getElementById('cardpayment').style.display = 'none';
  }
  cardfinalpaymentmeth() {
    this.billingAddress = false;
    // this.roadies = false;
    this.createDetailsdata = false;
    this.pickupAddress = false;
    this.pickupitems = false;
    this.pickupfinalitems = false;
    this.cardpayment = true;
    this.couponData = false;
    document.getElementById('cardpayment').style.display = 'block';
  }
  viewpickupDetailsdata() {
    this.couponData = false;
    this.billingAddress = false;
    // this.roadies = false;
    this.createDetailsdata = false;
    this.pickupAddress = true;
    this.pickupitems = false;
    this.pickupfinalitems = false;
    this.cardpayment = false;
    document.getElementById('cardpayment').style.display = 'none';
  }
  viewpickupDetails() {
    this.couponData = false;
    // if(this.selectdate === true){
    //   this.billingAddress = false;
    //   this.roadies = true;
    //   this.createDetailsdata = false;
    //   this.pickupAddress = false;
    //   this.pickupitems = false;
    //   this.pickupfinalitems = false;
    //   this.cardpayment = false;
    //   document.getElementById('cardpayment').style.display = 'none';

    // } else {
    this.billingAddress = false;
    // this.roadies = false;
    this.createDetailsdata = false;
    this.pickupAddress = true;
    this.pickupitems = false;
    this.pickupfinalitems = false;
    this.cardpayment = false;
    document.getElementById('cardpayment').style.display = 'none';
    // }
  }
  // roadiesData(){
  //   this.billingAddress = false;
  //   this.roadies = false;
  //   this.createDetailsdata = false;
  //   this.pickupAddress = false;
  //   this.pickupitems = true;
  //   this.pickupfinalitems = false;
  //   this.cardpayment = false;
  //   document.getElementById('cardpayment').style.display = 'none';
  // }
  finalpickupDetails() {
    this.billingAddress = false;
    // this.roadies = false;
    this.createDetailsdata = false;
    this.pickupAddress = false;
    this.couponData = false;
    this.pickupitems = true;
    this.pickupfinalitems = false;
    this.cardpayment = false;
    document.getElementById('cardpayment').style.display = 'none';
  }
  pickupitemdata() {
    if (this.productsData.length > 1) {
      let clickitems = 0;
      this.productsData.map((value) => {
        if (
          value.nameenter === true &&
          value.priceenter === true &&
          value.weigthenter === true &&
          value.quantyenter === true &&
          value.imgSrc === '../../assets/images/editicon.png'
        ) {
          value.clickinput = true;
          clickitems++;
          setTimeout(() => {
            value.clickinput = false;
          }, 3000);
        } else {
          value.clickinput = false;
        }
      });
      if (clickitems === 0) {
        this.showedData = [];
        for (let item of this.productsData) {
          if (item.Name && item.Price && item.Quantity && item.Weight) {
            this.showedData.push({
              Name: item.Name,
              Price: item.Price,
              Quantity: item.Quantity,
              Weight: item.Weight,
              buttonDisabled: item.buttonDisabled,
              clickinput: item.clickinput,
              deleteoption: item.deleteoption,
              imgSrc: item.imgSrc,
              index: item.index,
              nameenter: item.nameenter,
              newitem: item.newitem,
              priceenter: item.priceenter,
              quantyenter: item.quantyenter,
              wasClicked: item.wasClicked,
              weigthenter: item.weigthenter,
            });
          }
        }
        this.sendRequestElement = new MatTableDataSource<Element>(
          this.showedData
        );
        // if (this.selectdate === true) {
        //   this.roadies = true;
        // } else {
        //   this.roadies = false;
        // }
        this.billingAddress = false;
        // this.roadies = false;
        this.createDetailsdata = false;
        this.pickupAddress = false;
        this.pickupitems = false;
        this.couponData = false;
        this.pickupfinalitems = true;
        // this.roadies = true;
        this.cardpayment = false;
        document.getElementById('cardpayment').style.display = 'none';
        localStorage.setItem('OneTimeItem', JSON.stringify(this.productsData));
      }
    } else {
      this.requireitems = true;
      setTimeout(() => {
        this.requireitems = false;
      }, 3000);
    }
  }
  viewitems() {
    this.billingAddress = false;
    // this.roadies = false;
    this.createDetailsdata = false;
    this.pickupAddress = false;
    this.pickupitems = false;
    this.pickupfinalitems = true;
    this.cardpayment = false;
    this.couponData = false;
    document.getElementById('cardpayment').style.display = 'none';
  }
  diff_hours(dt2, dt1) {
    var diff = Math.abs(dt1.getTime() - dt2.getTime());
    return diff / 60000;
  }

  diff_hoursStart(dt3, dt1) {
    var diff = Math.abs(dt1.getTime() - dt3.getTime());
    return diff / 60000;
  }
  endAfterStart(dt1, dt2) {
    var startDate = new Date(dt1);
    var endDate = new Date(dt2);
    return endDate.getTime() >= startDate.getTime();
  }
  cardpaymenyview() {
    if (this.productsData.length > 1) {
      // if (this.selectdate === true) {
      let dt1 = new Date(this.pickupStartDate);
      let dt2 = new Date(this.pickupEndDate);
      let dt3 = new Date(this.dateFormat.transform(new Date().toISOString(),'America/New_York'));
      if (this.endAfterStart(dt1, dt2) === true) {
        if (this.diff_hoursStart(dt3, dt1) >= 60) {
          if (this.diff_hours(dt1, dt2) >= 120) {
            this.goodroadies();
          } else {
            this.diffTimeless = true;
            setTimeout(() => {
              this.diffTimeless = false;
            }, 4000);
            // this.pickupDetails();
          }
        } else {
          this.futureStartValid = true;
          setTimeout(() => {
            this.futureStartValid = false;
          }, 4000);
        }
      } else {
        this.futureValid = true;
        setTimeout(() => {
          this.futureValid = false;
        }, 4000);
      }
      // } else {
      //   this.goodroadies();
      // }

      // this.cardpayment = true;
      // document.getElementById('cardpayment').style.display = 'block';
      // this.billingAddress = false;
      // // this.roadies = false;
      // this.createDetailsdata = false;
      // this.pickupAddress = false;
      // this.pickupitems = false;
      // this.pickupfinalitems = false;
      // this.PickupWeight = this.productsData
      // .map(r => r.Quantity * r.Weight)
      // .reduce((sum, current) => Number(sum) + Number(current));
      // let body = {
      //   CityId: this.pickupCity,
      //   StateId: this.pickupState,
      //   PickupWeight: this.PickupWeight
      // }
      // this.sassService.onetimebillingStripePlan(body).subscribe(
      //   data => {
      //     this.varyplanId = data.PlanId;
      //     this.totalpaid = data.Price;
      //   });
    } else {
      this.requireitemsView = true;
      setTimeout(() => {
        this.requireitemsView = false;
      }, 3000);
    }
  }
  goodroadies() {
    let finalstartdate;
    let finalenddtae;
    // if (this.selectdate === true) {
    finalstartdate = this.pickupStartDate;
    finalenddtae = this.pickupEndDate;
    // } else {
    //   finalstartdate = null;
    //   finalenddtae = null;
    // }
    this.PickupWeight = this.productsData
      .map((r) => r.Quantity * r.Weight)
      .reduce((sum, current) => Number(sum) + Number(current));
    let bodystripe = {
      Roadies: this.selectdate,
      PickupAfter: finalstartdate,
      PickupBefore: finalenddtae,
      PickupAddress: this.pickupAddress1,
      CityId: this.pickupCity,
      StateId: this.pickupState,
      PickupWeight: this.PickupWeight,
    };
    this.sassService.onetimebillingStripePlan(bodystripe).subscribe((data) => {
      if (data.PlanId) {
        this.cardpayment = false;
        document.getElementById('cardpayment').style.display = 'none';
        this.couponData = true;
        this.billingAddress = false;
        // this.roadies = false;
        this.createDetailsdata = false;
        this.pickupAddress = false;
        this.pickupitems = false;
        this.pickupfinalitems = false;
        this.varyplanId = data.PlanId;
        this.totalpaid = data.Price;
        this.npId = data.NPClientId;
        this.DeliveryPartner = data.DeliveryPartner;
        this.PickupAfter = data.PickupAfter.split('.')[0];
        this.PickupBefore = data.PickupBefore.split('.')[0];
      } else {
        alert(data.message);
      }
    });
  }
  selectedstate() {
    this.sassService.pickupcitydetails(this.State).subscribe((data) => {
      this.citydataID = data['Result'];
      this.City = '';
    });
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.pickupstatesdata = data['Result'];
    });
  }
  selectedpickupstate() {
    this.sassService.pickupcitydetails(this.pickupState).subscribe((data) => {
      this.pickupcitydataID = data['Result'];
      this.pickupCity = '';
    });
  }
  cardfinalpayment() {
    if (this.validcoupensdata) {
      this.coupendata = this.coupendata;
    } else {
      this.coupendata = null;
    }
    this.stripe.createToken(this.cardNumberElement).then((result) => {
      if (result.token) {
        if (result.token.id) {
          let finalpickup = [];
          this.productsData.pop();
          for (let data of this.productsData) {
            let payload = {
              ItemName: data.Name,
              ItemRetailValue: data.Price,
              ItemWeight: data.Weight,
              Quantity: data.Quantity,
            };
            finalpickup.push(payload);
          }
          let finalstartdate;
          let finalenddtae;
          // if (this.selectdate === true) {
          // finalstartdate = this.pickupStartDate + '.00';
          // finalenddtae = this.pickupEndDate + '.00';
          // } else {
          //   finalstartdate = null;
          //   finalenddtae = null;
          // }
          const payload = {
            OneTimeClient: {
              CompanyTitle: this.CompanyTitle,
              FirstName: this.FirstName,
              LastName: this.LastName,
              Email: this.Email,
              PhoneNumber: this.PhoneNumber,
              TaxID: this.TaxID,
              ResidentialAddress1: this.Address1,
              ResidentialAddress2: this.Address2,
              ResidentialState: this.State,
              ResidentialCity: this.City,
              ResidentialCountry: this.Country,
              ResidentialZipCode: this.ZipCode,
              PickupAddress1: this.pickupAddress1,
              PickupAddress2: this.pickupAddress2,
              PickupState: this.pickupState,
              PickupCity: this.pickupCity,
              PickupCountry: this.pickupCountry,
              PickupZipCode: this.pickupZipCode,
              Token: result.token.id,
              PlanId: this.varyplanId,
              PromoCodeName: this.coupendata,
            },
            OneTimeItem: finalpickup,
            Pickup: false,
            DeliveryPartner: this.DeliveryPartner,
            NPClientId: this.npId,
            // Roadies: this.selectdate,
            PickupAfter: this.PickupAfter,
            PickupBefore: this.PickupBefore,
          };
          const spinnerRef = this.spinnerService.start();
          this.sassService.onetimebillingAdmin(payload).subscribe(
            (data) => {
              if (data.message === 'Payment Done') {
                this.infoClients.facilitator = '';
                this.infoClients.facilitatorGroup = '';
                this.infoClients.facility = '';
                this.infoClients.nonprofits = '';
                this.ClientFilterdata.updateBasicInfo(
                  JSON.stringify(this.infoClients)
                );
                localStorage.removeItem('onetimebilling');
                localStorage.removeItem('OneTimeItem');
                const dialogRef = this.dialog.open(SavePickupmodalComponent, {
                  width: '700px',
                  panelClass: 'custom-savedialog-container',
                  data: {
                    DeliveryId: 'OTP',
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {});
              } else {
                alert(data.message);
              }
              this.spinnerService.stop(spinnerRef);
            },
            (error) => {
              // alert(JSON.stringify(error));
              if (error) {
                alert("Sorry, payment failed! Click on 'Pay' to continue");
              }
              this.spinnerService.stop(spinnerRef);
              // this.buttonDisabled = false;
            }
          );
        }
      }
    });
  }
  ngOnDestroy() {
    this.info.pickupsPage = false;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
  }

  showCoupon() {
    this.couponCodeDiv = true;
  }

  checkForError() {
    this.validcoupensdata = '';
    this.coupenValue = [];
    this.errorcoupen = true;
    setTimeout(() => {
      this.errorcoupen = false;
    }, 3000);
  }

  clearCoupon() {
    this.validcoupensdata = '';
    this.coupendata = null;
    this.errorcoupen = false;
  }
}
