import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'CharIndex' })
export class CharIndexPipe implements PipeTransform {
  transform(value: string): string {
    if (value !== undefined) {
      if (value !== null) {
        const newVal = value.replace(/[^\w\s]/gi, '');
        return this.titleCase(newVal);
      }
    }
  }
  titleCase(value) {
    const str = value.toString();
    return str.charAt(0);
  }
}
