import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { OnetimebillingsuccessComponent } from 'src/app/SAAS/regModule/onetimebilling/onetimebillingsuccess/onetimebillingsuccess.component';
declare var Stripe: any;
@Component({
  selector: 'app-onetimepickupbillcardpay',
  templateUrl: './onetimepickupbillcardpay.component.html',
  styleUrls: ['./onetimepickupbillcardpay.component.scss']
})
export class OnetimepickupbillcardpayComponent implements OnInit {

  modalclose: string;
  email: string;
  firstname: string;
  lastname: string;
  cardNumber: string;
  cvc: string;
  stripe: any;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;

  message: string;
  yearlyPay: any;
  onetimebilling: any;
  OneTimeItem: any;

  faclityCount: any;
  totalitemCost: any;
  constructor(public dialog: MatDialog, private cd: ChangeDetectorRef,
    private router: Router, private sassService: SAASService, public _rdata: modalDataService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.onetimebilling = JSON.parse(localStorage.getItem('onetimebilling'));
    this.OneTimeItem = JSON.parse(localStorage.getItem('OneTimeItem'));
    this.OneTimeItem.pop();
    this.totalitemCost = this.OneTimeItem
      .map(r => r.Quantity * r.Price)
      .reduce((sum, current) => Number(sum) + Number(current));
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();

    this.cardNumberElement = elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number-element');
    this.cardNumberElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardExpiryElement = elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry-element');
    this.cardExpiryElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardCvcElement = elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc-element');
    this.cardCvcElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  goToBillSuccess() {
    this.stripe.createToken(this.cardNumberElement).then(result => {
      if (result.token) {
      if (result.token.id) {
        // let planid;
        // if (this.yearlyPay === 'montly') {
        //   planid = 'price_HON4TdbdJWPeVz';
        // } else {
        //   planid = 'price_HON5NBmCH4W13i';
        // }
        // for testing pupose start
        // if (this.yearlyPay === 'montly') {
        //   planid = 'price_1H2W1OICi4RDu2OlWM9hgNwq';
        // } else {
        //   planid = 'price_1H2W2rICi4RDu2OllIjS8nqt';
        // }
        // for testing pupose end
        let finalpickup = [];
        for (let data of this.OneTimeItem) {
          let payload = {
            ItemName : data.Name,
            ItemRetailValue : data.Price,
            ItemWeight: data.Weight,
            Quantity: data.Quantity,
          };
          finalpickup .push(payload)
        };
        const payload = {
          OneTimeClient:
          {
              CompanyTitle: this.onetimebilling[0].CompanyTitle,
              FirstName: this.onetimebilling[0].FirstName,
              LastName: this.onetimebilling[0].LastName,
              Email: this.onetimebilling[0].Email,
              PhoneNumber: this.onetimebilling[0].PhoneNumber,
              TaxID: this.onetimebilling[0].TaxID,
              ResidentialAddress1: this.onetimebilling[0].Address1,
              ResidentialAddress2: this.onetimebilling[0].Address2,
              ResidentialState: this.onetimebilling[0].State,
              ResidentialCity: this.onetimebilling[0].City,
              ResidentialCountry: this.onetimebilling[0].Country,
              ResidentialZipCode: this.onetimebilling[0].ZipCode,
              PickupAddress1:"hadapsar",
              PickupAddress2:null,
              PickupState:"MH",
              PickupCity:"Pune",
              PickupCountry:"India",
              PickupZipCode:"898989",
              Token: result.token.id,
              PlanId: environment.onetimeKey
          },
          OneTimeItem: finalpickup,
          Pickup: false
        };
        const spinnerRef = this.spinnerService.start();
        this.sassService.onetimebilling(payload).subscribe(
          data => {
            if (data.message === 'Payment Done') {
              localStorage.setItem('updateheckout', JSON.stringify(false));
              this.router.navigate(["Pickupsuccess"]);
            } else {
              alert(data.message);
            }
            this.spinnerService.stop(spinnerRef);
          },
          error => {
            // alert(JSON.stringify(error));
            if (error) {
              alert("Sorry, payment failed! Click on 'Pay' to continue");
            }
            this.spinnerService.stop(spinnerRef);
            // this.buttonDisabled = false;
          }
        );
      }
    }
    });

  }
  goTosignPage(){
    this.router.navigate(["OTPRegistration"]);
  }
  goTOSuccessPopup() {
    const dialogRef = this.dialog.open(OnetimebillingsuccessComponent,
      {
        width: '338px',
        height: '480px',
        panelClass: 'custom-editSub-container',
      });
    dialogRef.afterClosed().subscribe(result => {
    }
    );
  }
  // goToBillSuccess() {
  //   this.router.navigate(["Pickupsuccess"]);
  // }
  backtoreg() {
    this.router.navigate(["OTPRegistration"]);
  }
  gotorequestsuccess() {
    this.router.navigate(["OTPReqSuccess"]);

  }
}
