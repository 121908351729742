import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ConditionalExpr } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';

@Component({
  selector: 'app-delete-product-dialog',
  templateUrl: './delete-product-dialog.component.html',
  styleUrls: ['./delete-product-dialog.component.scss'],
})
export class DeleteProductDialogComponent implements OnInit {
  buttonDisabled: boolean;
  [x: string]: any;
  itemName: any;
  itemPrice: any;
  itemWeight: any;
  categories: any;
  selectedCategory: any;
  isPagination: any;
  requestData: any;
  ProductArray = [];
  categoryName: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteProductDialogComponent>,
    private route: ActivatedRoute,
    private state: AppState,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private CustomerService: CustomerService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {}
  deleteItem() {
    if (this.data['Count'] === 0) {
      this.dialogRef.close();
      alert("Zero Item's Deleted");
    } else {
      this.deletemenus();
    }
  }
  deletemenus() {
    // this.data['productId'].forEach(item => {
    //   this.ProductArray.push(item.ProductId);
    // });
    // console.log(this.data);

    this.CustomerService.deleteItem(this.data['productId']).subscribe((data) => {
      this.dialogRef.close('res');
    });
  }
}
