import { Component, OnInit } from '@angular/core';
import { EditcheckoutComponent } from '../editcheckout/editcheckout.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  MatPaginator,
  MatSort,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
@Component({
  selector: 'app-editbilling',
  templateUrl: './editbilling.component.html',
  styleUrls: ['./editbilling.component.scss'],
})
export class EditbillingComponent implements OnInit {
  modalclose: string;
  public numberFormZip: FormGroup;
  info: any;
  ClientId: any;
  clientData: any;
  address: any;
  address1: any;
  city: any;
  stateCode: any;
  email: any;
  mobile: any;
  zip: any;
  country: any;
  noPlaning: boolean;
  yearlyPlan: boolean;
  sassinfodata: boolean;
  faclityCount: any;
  accountTypeMY: any;
  multipleFGdata: any;
  updtateclient: boolean;
  updateinfo: any;
  monthlyPrice: any;
  yearlyPrice: any;
  showCouponDiv: boolean = false;
  coupendata: any = null;
  planID: any;
  validcoupensdata: string;
  coupenValue: any[];
  totalfinal: any;
  totalfinalpaid: any;
  errormes: any;
  errorcoupen: boolean;
  coupendataEnter: any;
  enterCoupon: boolean = false;
  errorcouponexpired: boolean;
  couponExpired: any = '';
  validcoupensexeeddata: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public _rdata: modalDataService,
    private sassService: SAASService,
    private customerService: CustomerService,
    private PromotionCodeService: PricingPromotionCodeService,
    private pricingModule: PricingModuleServiceService,
    public dialogRef: MatDialogRef<EditbillingComponent>
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    let pickupTypefinal;
    this.showCouponDiv = false;

    this.numberFormZip = this.formBuilder.group({
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
        ]),
      ],
    });
    // this.pickupstates();
    this._rdata.basicupdateInfo2Observe.subscribe((res) => {
      this.updateinfo = JSON.parse(res);

      if (this.updateinfo.SAASupdate.PickupType.length > 0) {
        if (this.updateinfo.SAASupdate.PickupType.length === 2) {
          pickupTypefinal = 'combined';
        } else if (this.updateinfo.SAASupdate.PickupType[0].ID === 2) {
          pickupTypefinal = 'Organic';
        } else {
          pickupTypefinal = 'Food';
        }
      } else {
        pickupTypefinal = 'combined';
      }
      if (this.updateinfo) {
        this.updtateclient = true;
        if (this.updateinfo.updateclient === true) {
          this.ClientId = this.updateinfo.SAASupdate.ClientId;
          if (this.ClientId) {
            this.getById();
          }
        } else {
          this.updtateclient = false;
          this._rdata.basicInfo2Observe.subscribe((res) => {
            this.info = JSON.parse(res);
            // console.log(this.info);
            if (
              this.info.pickupTypes !== '' &&
              this.info.pickupTypes !== null &&
              this.info.pickupTypes !== undefined
            ) {
              if (this.info.pickupTypes.length > 0) {
                if (this.info.pickupTypes.length === 2) {
                  pickupTypefinal = 'combined';
                } else if (this.info.pickupTypes[0] === 2) {
                  pickupTypefinal = 'Organic';
                } else {
                  pickupTypefinal = 'Food';
                }
              }
            }
            if (
              this.info.PickupTypeSfg !== '' &&
              this.info.PickupTypeSfg !== null &&
              this.info.PickupTypeSfg !== undefined
            ) {
              if (this.info.PickupTypeSfg.length > 0) {
                if (this.info.PickupTypeSfg.length === 2) {
                  pickupTypefinal = 'combined';
                } else if (this.info.PickupTypeSfg[0] === 'Organic Recycling') {
                  pickupTypefinal = 'Organic';
                } else {
                  pickupTypefinal = 'Food';
                }
              }
            }
            if (this.info.facility_type === 'SAAS Facility Group') {
              this.multipleFGdata = JSON.parse(
                localStorage.getItem('multiplefacilities')
              );
              this.faclityCount = this.multipleFGdata.length;
              this.address = this.info.address;
              this.address1 = this.info.businessAddress;
              // this.city = this.info.City;
              // this.stateCode = this.info.state;
              this.mobile = this.info.Phone;
              this.email = this.info.adminEmail;
              this.zip = this.info.zip;
              this.country = this.info.country;
              this.selectedstorestate();
              this.pickupstates();
            } else if (this.info.facility_type === 'SAAS Facility') {
              const body = [
                {
                  CompanyTitle: this.info.accountName,
                  FirstName: this.info.FirstName,
                  LastName: this.info.LastName,
                  Account: this.info.selectedType,
                  Email: this.info.adminEmail,
                  PhoneNumber: this.info.Phone,
                  TaxID: this.info.TaxID,
                  Country: this.info.country,
                  Address1: this.info.address,
                  Address2: this.info.businessAddress,
                  // State: this.info.State,
                  // City: this.info.City,
                  StateId: this.info.State,
                  CityId: this.info.City,
                  ZipCode: this.info.zip,
                  PickupType: this.info.sendpickupTypes,
                  MenuEdit: this.info.menuEdit,
                },
              ];
              this.multipleFGdata = body;
              this.faclityCount = 1;
              this.address = this.info.address;
              this.address1 = this.info.businessAddress;
              // this.city = this.info.City;
              // this.stateCode = this.info.state;
              this.mobile = this.info.Phone;
              this.email = this.info.adminEmail;
              this.zip = this.info.zip;
              this.country = this.info.country;
              this.selectedstorestate();
              this.pickupstates();
            }
            // this.ClientId = this.info.clientId;
            // if (this.ClientId) {
            //   this.getById();
            // }
          });
        }
      } else {
        this.updtateclient = false;
      }
    });
    this.accountTypeMY = JSON.parse(localStorage.getItem('accountType'));
    // console.log(this.accountTypeMY);

    this.getPricingRated(pickupTypefinal);
  }
  validCoupen() {
    if (
      this.coupendataEnter === undefined ||
      this.coupendataEnter === null ||
      this.coupendataEnter == ''
    ) {
      this.enterCoupon = true;
      setTimeout(() => {
        this.enterCoupon = false;
      }, 2000);
    } else {
      const payload = {
        PromoCodeName: this.coupendataEnter,
        PlanId: this.planID,
        ValidateOTP: false,
      };
      this.PromotionCodeService.validCoupen(payload).subscribe((data) => {
        // console.log(this.totalfinal);
        // console.log(this.totalfinalpaid);
        if (
          data.message ===
          'Coupon duration is month which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is year which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (data.message === 'Coupon was in error') {
          this.checkForError();
        } else if (
          data.message === 'Invalidate Coupon due to price paramters'
        ) {
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.validcoupensexeeddata = true;
          setTimeout(() => {
            this.validcoupensexeeddata = false;
          }, 3000);
        } else if (
          data.message.includes('Coupon validity is already expired')
        ) {
          this.couponExpired = data.message;
          this.coupendata = null;
          this.validcoupensdata = '';
          if (this.info) {
            this.info.coupendata = '';
            this.info.totalfinalpaid = '';
          }
          if (this.updateinfo) {
            this.updateinfo.coupendata = '';
            this.updateinfo.totalfinalpaid = '';
          }
          this.coupenValue = [];
          this.totalfinalpaid = this.totalfinal;
          this.errorcouponexpired = true;
          setTimeout(() => {
            this.errorcouponexpired = false;
          }, 3000);
        } else if (
          data.message ===
          'Object reference not set to an instance of an object.'
        ) {
          this.checkForError();
        } else {
          if (data.message.includes('Coupon is Valid')) {
            this.coupendata = this.coupendataEnter;
            this.validcoupensdata = 'Coupon Applied';
            this.coupenValue = [];
            var r = /\d+/g;
            // var s = "you can enter 333 maximum 500 choices";
            var m;
            while ((m = r.exec(data.message)) != null) {
              this.coupenValue.push(m[0]);
            }
            // console.log(this.totalfinalpaid);
            // console.log(this.totalfinal);
            this.totalfinalpaid = this.totalfinal - this.coupenValue[0];
            // console.log(this.totalfinalpaid);
            // console.log(this.totalfinal);
          } else {
            alert('Something wrong with Coupon');
          }
        }
      });
    }
  }
  getPricingRated(pickuptype) {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      // console.log(data);
      let priceData;
      priceData = data['Result'];
      for (const status of priceData) {
        if (status.SubscriptionType === 'month') {
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.monthlyPrice = status.Price;
              if (this.accountTypeMY == 'montly') {
                this.planID = status.StripePlanId;
              }
              // console.log(this.monthlyPrice);
              // console.log(this.planID);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.monthlyPrice = status.Price;
              if (this.accountTypeMY == 'montly') {
                this.planID = status.StripePlanId;
              }
              // console.log(this.monthlyPrice);
              // console.log(this.planID);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.monthlyPrice = status.Price;
            if (this.accountTypeMY == 'montly') {
              this.planID = status.StripePlanId;
            }
            // console.log(this.monthlyPrice);
            // console.log(this.planID);
          }
          // console.log(this.yearlyPrice)
        } else {
          // console.log(pickuptype)
          // console.log(status.PickupType)
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            // console.log(status.PickupType)
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.yearlyPrice = status.Price;
              if (this.accountTypeMY == 'yearly') {
                this.planID = status.StripePlanId;
              }
              // console.log(this.yearlyPrice);
              // console.log(this.planID);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.yearlyPrice = status.Price;
              if (this.accountTypeMY == 'yearly') {
                this.planID = status.StripePlanId;
              }
              // console.log(this.yearlyPrice);
              // console.log(this.planID);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.yearlyPrice = status.Price;
            if (this.accountTypeMY == 'yearly') {
              this.planID = status.StripePlanId;
            }
            // console.log(this.yearlyPrice);
            // console.log(this.planID);
          }
          // console.log(this.monthlyPrice)
        }
      }
      // console.log(this.planID);
      // console.log(this.accountTypeMY);
      if (this.accountTypeMY === 'montly' && this.faclityCount) {
        this.totalfinalpaid = this.faclityCount * this.monthlyPrice;
        this.totalfinal = this.faclityCount * this.monthlyPrice;
        // console.log(this.totalfinalpaid);
        // console.log(this.totalfinal);
      } else {
        this.totalfinalpaid = this.faclityCount * this.yearlyPrice;
        this.totalfinal = this.faclityCount * this.yearlyPrice;
        // console.log(this.totalfinalpaid);
        // console.log(this.totalfinal);
      }
      // console.log(this.planID);

      //   this.info.coupendata = this.coupendata;
      // this.info.totalfinalpaid = this.totalfinalpaid;
      if (this.info) {
        if (
          this.info.coupendata !== null &&
          this.info.coupendata !== undefined &&
          this.info.coupendata
        ) {
          this.coupendata = this.info.coupendata;
          this.coupendataEnter = this.info.coupendata;
          this.showCouponDiv = true;
          // this.getPricingRated()
          this.validCoupen();
        }
      }
      if (this.updateinfo) {
        if (
          this.updateinfo.coupendata !== null &&
          this.updateinfo.coupendata !== undefined &&
          this.updateinfo.coupendata
        ) {
          this.coupendata = this.updateinfo.coupendata;
          this.coupendataEnter = this.info.coupendata;
          this.showCouponDiv = true;
          // this.getPricingRated()
          this.validCoupen();
        }
      }
    });
  }
  getById() {
    this.sassService.getSubDetails(this.ClientId).subscribe((data) => {
      this.clientData = data.Result[0];
      this.address = this.clientData.Address;
      this.address1 = this.clientData.Address2;
      this.city = this.clientData.City;
      this.stateCode = this.clientData.State;
      this.mobile = this.clientData.Phone;
      this.email = this.clientData.Email;
      this.zip = this.clientData.EINNumber;
      this.country = this.clientData.Country;
      if (this.updateinfo.updateclient === true) {
        localStorage.setItem(
          'NOofFacility',
          JSON.stringify(data.Result.length)
        );
        this.multipleFGdata = data.Result;
      } else if (this.info) {
        if (this.info.account_type === 'Facility') {
          this.multipleFGdata = data.Result;
        }
      }
      // this. selectedstate();
    });
    this.customerService
      .getAllFilteredCustomers(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.ClientId,
        null,
        null,
        null,
        null
      )
      .subscribe((data) => {
        data.Result = data.Result.filter(
          (value) => value.ApprovalStatus === 'Active'
        );
        if (data.Result.length > 0) {
          localStorage.setItem(
            'NOofFacility',
            JSON.stringify(data.Result.length)
          );
          // data.Result = data.Result.filter(value => value.ApprovalStatus === 'Active');
          this.faclityCount = data.Result.length;
          // console.log(this.faclityCount);
          // if (this.user.User.Roles[0].Name === 'Facility Group') {
          //   if (this.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
          if (this.info) {
            if (this.info.account_type === 'Facility') {
            }
          } else if (this.updateinfo.updateclient !== true) {
          } else {
            this.multipleFGdata = data.Result;
          }
          //   }
          // }
        } else {
          this.faclityCount = 1;
          // console.log(this.faclityCount);
        }
        if (this.accountTypeMY === 'montly' && this.faclityCount) {
          this.totalfinalpaid = this.faclityCount * this.monthlyPrice;
          this.totalfinal = this.faclityCount * this.monthlyPrice;
          // console.log(this.totalfinalpaid);
          // console.log(this.totalfinal);
        } else {
          this.totalfinalpaid = this.faclityCount * this.yearlyPrice;
          this.totalfinal = this.faclityCount * this.yearlyPrice;
          // console.log(this.totalfinalpaid);
          // console.log(this.totalfinal);
        }
      });
  }
  // pickupstates() {
  //   this.sassService.pickupstatedetails().subscribe(
  //     data => {
  //       this.pickupstatesdata = data['Result'];
  //       for (var i = 0; i < this.pickupstatesdata.length; i++) {
  //         if (this.pickupstatesdata[i].ID == this.stateCode) {
  //           this.stateCode = this.pickupstatesdata[i].ID;
  //           console.log(this.stateCode)
  //         }
  //       }
  //     });
  // }
  // selectedstate() {
  //   this.sassService.pickupcitydetails(this.stateCode).subscribe(
  //     data => {
  //       this.citydataID = data['Result'];
  //       for (var i = 0; i < this.citydataID.length; i++) {
  //         if (this.citydataID[i].ID == this.city) {
  //           this.city = this.citydataID[i].ID;
  //           console.log(this.city)

  //         }
  //       }
  //     });

  // }
  keyPressZipCode(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  goToCheckout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    if (this.info) {
      this.info.coupendata = this.coupendata;
      this.info.totalfinalpaid = this.totalfinalpaid;
      this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    }
    if (this.updateinfo) {
      this.updateinfo.coupendata = this.coupendata;
      this.updateinfo.totalfinalpaid = this.totalfinalpaid;
      this._rdata.updateclientBasicInfo2(JSON.stringify(this.updateinfo));
    }

    // localStorage.setItem('promocodedata', JSON.stringify(this.coupendata));
    // localStorage.setItem('finalvaluepay', JSON.stringify(this.totalfinalpaid));

    const dialogRef = this.dialog.open(EditcheckoutComponent, {
      width: '700px',
      height: '530px',
      panelClass: 'custom-editdialog-container',
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe((selection) => {});
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      for (var i = 0; i < data['Result'].length; i++) {
        if (data['Result'][i].ID === this.info.state) {
          this.stateCode = data['Result'][i].Title;
        }
      }
    });
  }
  selectedstorestate() {
    this.sassService.pickupcitydetails(this.info.state).subscribe((data) => {
      for (var i = 0; i < data['Result'].length; i++) {
        if (data['Result'][i].ID == this.info.city) {
          this.city = data['Result'][i].Title;
        }
      }
    });
  }

  showCoupon() {
    this.showCouponDiv = true;
  }

  checkForError() {
    this.coupendata = null;
    this.validcoupensdata = '';
    if (this.info) {
      this.info.coupendata = '';
      this.info.totalfinalpaid = '';
    }
    if (this.updateinfo) {
      this.updateinfo.coupendata = '';
      this.updateinfo.totalfinalpaid = '';
    }
    this.coupenValue = [];
    this.totalfinalpaid = this.totalfinal;
    this.errorcoupen = true;
    setTimeout(() => {
      this.errorcoupen = false;
    }, 3000);
  }

  clearCoupon() {
    this.validcoupensdata = '';
    this.coupendataEnter = null;
    this.coupendata = null;
    this.errorcoupen = false;
    this.totalfinalpaid = this.totalfinal;
  }
}
