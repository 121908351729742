import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { SAASService } from "src/app/shared/services/saas.service";
import { PricingPromotionCodeService } from "src/app/shared/services/pricing-promotion-code.service";
import { ClientFilterService } from "src/app/shared/services/clientFilter.service";
import { count } from "rxjs-compat/operator/count";
@Component({
  selector: "app-otp-pricing-create-popup",
  templateUrl: "./otp-pricing-create-popup.component.html",
  styleUrls: ["./otp-pricing-create-popup.component.scss"]
})
export class OtpPricingCreatePopupComponent implements OnInit {
  citydataID: any;
  Stateid = null;
  pickupstatesdata: any;
  pickupCity = null;
  vehicleTypes = [];
  vehicleTypeid: any = null;
  regionsdata = [];
  regionTypeid = null;
  itemPrice: any;
  vehicleField: boolean;
  requireFieldPrice: boolean;
  requireField: boolean;
  requireDeliveryField: boolean;
  buttonDisabled: boolean;
  info: any;

  constructor(
    private sassService: SAASService,
    private ClientFilterdata: ClientFilterService,
    private promotionCode: PricingPromotionCodeService,
    public dialogRef: MatDialogRef<OtpPricingCreatePopupComponent>
  ) {}
  ngOnInit() {
    // this.pickupstates();
    this.pickupregion();
    this.vehicleTypesdata();
    // this.defaultcity();
    this.ClientFilterdata.basicCityInfoObserve.subscribe(res => {
      this.info = JSON.parse(res);
      this.pickupstatesdata = this.info.statedata;
    });
  }

  addNewItem() {
    if (this.regionTypeid === "Select Region") {
      this.regionTypeid = null;
    }
    if (this.pickupCity === "Select City") {
      this.pickupCity = null;
    }
    if (this.vehicleTypeid === "Select Vehicle Type") {
      this.vehicleTypeid = null;
    }

    if (this.Stateid === "Select State") {
      this.Stateid = null;
    }
    if (this.vehicleTypeid && this.vehicleTypeid !== null) {
      if (
        this.itemPrice &&
        this.itemPrice !== undefined &&
        this.itemPrice !== null
      ) {
        if (
          (this.regionTypeid && this.regionTypeid !== null) ||
          (this.pickupCity && this.pickupCity !== null) ||
          (this.Stateid && this.Stateid !== null)
        ) {
          this.buttonDisabled = true;
          let payload;
          payload = {
            Price: this.itemPrice,
            StateId: this.Stateid,
            RegionId: this.regionTypeid,
            CityId: this.pickupCity,
            VehicleId: this.vehicleTypeid
          };

          this.promotionCode.createOTP(payload).subscribe(
            data => {
              if (data.Result) {
                this.dialogRef.close("res");
              } else {
                alert(data.message);
                this.buttonDisabled = false;
              }
            },
            error => {
              // alert(JSON.stringify(error));
              this.buttonDisabled = false;
            }
          );
        } else {
          this.requireField = true;
          setTimeout(() => {
            this.requireField = false;
          }, 5000);
        }
      } else {
        this.requireFieldPrice = true;
        setTimeout(() => {
          this.requireFieldPrice = false;
        }, 5000);
      }
    } else {
      this.vehicleField = true;
      setTimeout(() => {
        this.vehicleField = false;
      }, 5000);
    }
  }
  close() {
    this.dialogRef.close();
  }
  pickupregion() {
    this.sassService.Regions().subscribe(data => {
      this.regionsdata = data["Result"];
      // for (var i = 0; i < this.pickupstatesdata.length; i++) {
      //   if (this.pickupstatesdata[i].ID == this.data[0].customer[0].StateId) {
      //     this.Stateid = this.pickupstatesdata[i].ID;
      //   }
      // }
    });
  }
  vehicleTypesdata() {
    this.sassService.VehicleTypes().subscribe(data => {
      this.vehicleTypes = data["Result"];
      // for (var i = 0; i < this.pickupstatesdata.length; i++) {
      //   if (this.pickupstatesdata[i].ID == this.data[0].customer[0].StateId) {
      //     this.Stateid = this.pickupstatesdata[i].ID;
      //   }
      // }
    });
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe(data => {
      this.pickupstatesdata = data["Result"];
      // for (var i = 0; i < this.pickupstatesdata.length; i++) {
      //   if (this.pickupstatesdata[i].ID == this.data[0].customer[0].StateId) {
      //     this.Stateid = this.pickupstatesdata[i].ID;
      //   }
      // }
    });
  }
  // defaultcity() {
  //   this.sassService.citydetails().subscribe((data) => {
  //     this.citydataID = data['Result'];
  //     // this.pickupCity = 'Select City';
  //   });
  // }
  selectedstate() {
    this.sassService.pickupcitydetails(this.Stateid).subscribe(data => {
      this.citydataID = data["Result"];
      this.pickupCity = "Select City";
    });
  }
  selectedregion() {
    if (this.regionTypeid === "Select Region") {
      this.pickupstates();
    } else {
      let count = 0;
      this.sassService
        .pickupstatedetailsbyid(this.regionTypeid)
        .subscribe(data => {
          this.pickupstatesdata = data["Result"];
          for (var i = 0; i < this.pickupstatesdata.length; i++) {
            if (this.pickupstatesdata[i].ID === this.Stateid) {
              this.Stateid = this.pickupstatesdata[i].ID;
              count++;
            }
          }
          if (count === 0) {
            this.Stateid = "Select State";
          }
        });
    }
  }
}
