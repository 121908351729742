import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { from } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { InviteuserdialogComponent } from '../inviteuserdialog/inviteuserdialog.component';
import { EdituserComponent } from '../edituser/edituser.component';
import { AddeditfilterComponent } from '../addeditfilter/addeditfilter.component';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
export interface PeriodicElements {
  LastName: string;
  FirstName: string;
  Email: any;
  Role: string;
  Status: string;
  LastActivity: any;
  Delete: any;
}
export interface PeriodicElement {
  LastName: string;
  FirstName: string;
  AccountName: string;
  AccountType: string;
  TaskPerformed: string;
  Role: string;
  Date: any;
  Time: any;

}
const ELEMENT_DATA: PeriodicElements[] = [
  { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },

  // { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  // { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  // { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  // { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  // { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
  // { FirstName: 'Horace', LastName: 'Williams', Email: 'Horace@michaelspizza.com', Role: 'Admin', Status: 'Active', LastActivity: '02-07-19 9:15AM Est', Delete: '' },
];
const ELEMENT_DATAS: PeriodicElement[] = [
  { FirstName: 'Horace', LastName: 'Williams', AccountName: 'Michaels Pizza', AccountType: 'Facility', TaskPerformed: 'Submitted A Pickup', Role: 'Admin', Date: '02-07-2019', Time: '9:15am Est', }
]
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  alluser: any;
  clients: any[];
  User: any;
  alluserdetails: any;
  constructor(public dialog: MatDialog, private AdminService: AdmintoolsService) { }
  displayedColumns: string[] = ['FirstName', 'LastName', 'Email', 'Role', 'Status', 'LastActivity', 'Delete'];
  displayedColumnsforrecentactivity: string[] = ['FirstName', 'LastName', 'AccountName', 'AccountType', 'TaskPerformed', 'Role', 'Date', 'Time', 'Delete'];
  dataSource = new MatTableDataSource<PeriodicElements>(ELEMENT_DATA);
  dataSources = new MatTableDataSource<PeriodicElement>(ELEMENT_DATAS);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.v2_getAlluser();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  opentransferownershiplDialog() {
    const dialogRef = this.dialog.open(InviteuserdialogComponent, {
      width: '640px', height: '450px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  edituserdetails() {
    const dialogRef = this.dialog.open(EdituserComponent, {
      width: '700px', height: '680px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  addeditfilter() {
    const dialogRef = this.dialog.open(AddeditfilterComponent, {
      width: '900px', height: '710px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  // Filter data
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // Fetching User Details
  v2_getAlluser() {
    this.AdminService.v2_getAllUser()
      .subscribe(
        data => {
          this.alluser = data;
          this.clients = Array.of(this.alluser.User)
          for (var i = 0; i < this.User.length; i++) {
            this.alluserdetails = this.User[i];
          }
        })
  }
}


