import { Component, OnInit, Inject } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material";
import { ResetDialogComponent } from "../reset-dialog/reset-dialog.component";
import { AccountService } from "src/app/shared/account.service";
import { AppState } from "src/app/shared/app-state";
@Component({
  selector: "app-recovery-dialog",
  templateUrl: "./recovery-dialog.component.html",
  styleUrls: ["./recovery-dialog.component.scss"]
})
export class RecoveryDialogComponent implements OnInit {
  modalclose: string;
  public recoveryForm: FormGroup;
  selectedEmoji: string;
  res: any;
  forgotemail: any;
  recoveryId: any;
  recoveryToken: any;
  msgdata: any;
  isLoggedfalse: boolean;
  isLoggeddata: boolean;
  isLoggeddatavalid: boolean;
  constructor(
    private state: AppState,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RecoveryDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.forgotemail = this.data;
    this.recoveryForm = this.formBuilder.group({
      recoveryCode: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
          Validators.required
        ])
      ]
    });
  }
  recoveryCodedata(code) {
    const body = {
      Email: this.forgotemail,
      ResetCode: code
    };
    this.accountService.recoveryCode(body).subscribe(res => {
      this.msgdata = res.message;
      if (this.msgdata === undefined) {
        this.recoveryId = res.Id;
        this.recoveryToken = res.Token;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        this.dialogRef.close(this.modalclose);
        const dialog = this.dialog.open(ResetDialogComponent, {
          width: "600px",
          height: "420px",
          disableClose: true,
          data: [
            {
              recoveryId: this.recoveryId
            },
            {
              recoveryToken: this.recoveryToken
            }
          ]
        });
        dialog.afterClosed().subscribe(selection => {
          if (selection) {
            this.selectedEmoji = selection;
          }
        });
      } else if (
        this.msgdata === "In-valid Reset code" ||
        "Reset code Required") {
          this.msgdata = "Please enter valid recovery code"
          this.isLoggedfalse = true;
      }
      setTimeout(() => {
        this.isLoggedfalse = false;
      }, 3000);
    },  error => {
      this.msgdata = "Please enter valid recovery code"
      this.isLoggedfalse = true;
    }
    );
  }
  ResendCode() {
    const body = {
      Email: this.forgotemail
    };
    this.accountService.openforgotDialog(body).subscribe(res => {
      this.msgdata = res.message;
      if (this.msgdata === "Email does not exist") {
      } else {
        this.isLoggeddatavalid = true;
      }
      setTimeout(() => {
        this.isLoggeddatavalid = false;
      }, 3000);
    });
  }
  confirmSelection() {
    this.dialogRef.close(this.modalclose);
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.recoveryForm.controls[controlName].hasError(errorName);
  };
}
