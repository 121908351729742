import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SpinnerService } from 'src/app/core/spinner.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';

@Component({
  selector: 'app-edit-shipment-dialog',
  templateUrl: './edit-shipment-dialog.component.html',
  styleUrls: ['./edit-shipment-dialog.component.scss']
})
export class EditShipmentDialogComponent implements OnInit {
  shipmentID: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public dialogRef: MatDialogRef<EditShipmentDialogComponent>,
    private pickupService: PickUpService,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.shipmentID = this.data['ShippingID']
  }
  updateShipment() {
    var spinnerRef = this.spinnerService.start();
    let payload = {
      shippingId: this.shipmentID,
      DeliveryId: this.data['DeliveryId']
    }

    this.pickupService.updateShipment(payload).subscribe(
      (data) => {
        console.log(data);
        if (data['message'] == "Roadies ShippingId information saved successfully.") {
          this.dialogRef.close("Done");
          this.spinnerService.stop(spinnerRef);
        } else {
          this.spinnerService.stop(spinnerRef);
          alert(data['message']);
        }
      },
      (error) => {
        console.log(error)
      });
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
