import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material";
import { Router } from "@angular/router";

@Component({
  selector: "app-invite-user-success-dialog",
  templateUrl: "./invite-user-success-dialog.component.html",
  styleUrls: ["./invite-user-success-dialog.component.scss"]
})
export class InviteUserSuccessDialogComponent implements OnInit {
  modalclose: string;
  clientId: any;
  successData: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<InviteUserSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {}
  done() {
    this.dialogRef.close();
  }
}
