import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PricingPromotionCodeService } from '../../../shared/services/pricing-promotion-code.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-promotion-code-pricing-create',
  templateUrl: './promotion-code-pricing-create.component.html',
  styleUrls: ['./promotion-code-pricing-create.component.scss'],
})
export class PromotionCodePricingCreateComponent implements OnInit {
  promoname;
  enddate;
  minDate = new Date();
  type;
  amount;
  types = ['Yearly', 'Monthly', 'One Time Pickup'];
  frequency;

  requireFieldPromoName: boolean = false;
  requireFieldType: boolean = false;
  requireFieldDate: boolean = false;
  requireFieldAmount: boolean = false;
  requireFieldEndDate: boolean = false;
  requireFieldFrequency: boolean = false;

  startDate: any;
  buttonDisabled: boolean;
  constructor(
    public dialogRef: MatDialogRef<PromotionCodePricingCreateComponent>,
    private promotionCode: PricingPromotionCodeService
  ) {}

  ngOnInit() {}

  onChangeDate(date) {
    this.enddate = date;
  }

  createCoupon() {
    this.startDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
    let TodayDate = new Date();
    let newEndDate = new Date(this.enddate);
    // console.log(this.enddate);
    // console.log(TodayDate);

    // //let formattedEndDate = moment(newEndDate).format('YYYY/MM/DD');
    // console.log(newEndDate);
    // console.log(newEndDate.getHours());
    //console.log(formattedEndDate);

    this.requireFieldPromoName = false;
    this.requireFieldDate = false;
    this.requireFieldEndDate = false;
    this.requireFieldType = false;
    this.requireFieldAmount = false;

    if (
      this.promoname == undefined ||
      this.promoname == null ||
      this.promoname == ''
    ) {
      this.requireFieldPromoName = true;
    } else if (
      this.enddate == undefined ||
      this.enddate == null ||
      this.enddate == ''
    ) {
      this.requireFieldDate = true;
    } else if (newEndDate < TodayDate) {
      this.requireFieldEndDate = true;
    } else if (this.type == undefined || this.type == null || this.type == '') {
      this.requireFieldType = true;
    } else if (
      (this.type == 'Monthly' || this.type == 'Yearly') &&
      (this.frequency == undefined ||
        this.frequency == null ||
        this.frequency == '')
    ) {
      this.requireFieldFrequency = true;
    } else if (
      this.amount == undefined ||
      this.amount == null ||
      this.amount == ''
    ) {
      this.requireFieldAmount = true;
    } else {
      this.buttonDisabled = true;
      if (this.type == 'Monthly') {
        this.enddate = moment(this.enddate).format('YYYY-MM-DD');
        let coupounDetails = {
          PromoName: this.promoname,
          DurationType: 'month',
          DiscountAmount: this.amount,
          NoOfRecurre: this.frequency,
          ValidationStartDate: this.startDate,
          ValidationEndDate: this.enddate + 'T23:59:59',
        };

        this.promotionCode.createCouponCode(coupounDetails).subscribe(
          (response) => {
            this.dialogRef.close('res');
          },
          (error) => {
            // alert(JSON.stringify(error));
            this.buttonDisabled = false;
          }
        );
      } else {
        this.enddate = moment(this.enddate).format('YYYY-MM-DD');
        let coupounDetails = {
          PromoName: this.promoname,
          DurationType: this.type === 'Yearly' ? 'year' : 'once',
          DiscountAmount: this.amount,
          NoOfRecurre: this.type === 'Yearly' ? this.frequency : null,
          ValidationStartDate: this.startDate,
          ValidationEndDate: this.enddate + 'T23:59:59',
        };
        this.promotionCode
          .createCouponCode(coupounDetails)
          .subscribe((response) => {
            this.dialogRef.close('res');
          });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }
}
