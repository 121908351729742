import { Component, OnInit } from '@angular/core';
import { ExcelServicesService } from '../../shared/services/excel-services.service'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EditDashboardDialogComponent } from '../edit-dashboard-dialog/edit-dashboard-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-edit-dashboard',
  templateUrl: './edit-dashboard.component.html',
  styleUrls: ['./edit-dashboard.component.scss']
})
export class EditDashboardComponent implements OnInit {

  public chartType = 'bar';
  excel = [];
  opentitleDialog() {
    const dialogRef = this.dialog.open(EditDashboardDialogComponent, {
      width: '760px', height: '530px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }

  public chartDatasets: Array<any> = [
    { data: [65, 59, 157, 81, 56, 55, 65, 59, 157, 81, 56, 55], label: 'My First dataset' },
    { data: [11, 12, 157, 13, 14, 15, 65, 59, 157, 81, 56, 55], label: 'My Second dataset' },
  ];

  public chartLabels: Array<any> = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  public chartColors: Array<any> = [
    {
      backgroundColor: [
        '#5e7098',
        '#5e7098',
        '#5e7098',
        '#5e7098',
        '#5e7098',
        '#5e7098',
        '#83ac66',
        '#5e7098',
        '#5e7098',
        '#5e7098',
        '#83ac66',
        '#5e7098'
      ],
      borderColor: [
        '#83ac66',
        '#83ac66',
        '#83ac66',
        '#83ac66',
        '#83ac66',
        '#5e7098',
        '#83ac66',
        '#5e7098',
        '#83ac66',
        '#5e7098',
        '#83ac66',
        '#83ac66'
      ],
      borderWidth: 0,
    },
    {
      backgroundColor: [
        '#83ac66',
        '#83ac66',
        '#83ac66',
        '#83ac66',
        '#83ac66',
        '#5e7098',
        '#83ac66',
        '#5e7098',
        '#83ac66',
        '#5e7098',
        '#83ac66',
        '#83ac66'
      ],

      borderColor: [
        'rgba(255, 125, 158, 1)',
        'rgba(3, 111, 184, 1)',
        'rgba(255, 255, 137, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(126, 243, 243, 1)',
        'rgba(255, 210, 115, 1)'
      ],
      borderWidth: 0,
    },
  ];

  public chartOptions: any = {
    responsive: true,
    scales: {

      xAxes:
        [{
          stacked: true,

          barPercentage: 0.2

        }],
      yAxes: [
        {
          stacked: true,
          barThickness: 4
        }
      ]
    }
  };

  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
  constructor(private excelService: ExcelServicesService, private http: HttpClient, public dialog: MatDialog) {
    // this.getJSON().subscribe(data => {    ----- future purpose API----
    //   data.forEach(row => {  
    //     this.excel.push(row);  
    //   });  
    //  });  
  }

  timePeriods = [
    'Bronze age',
    'Iron age',
    'Middle ages',
    'Early modern period',
    'Long nineteenth century'
  ];
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.timePeriods, event.previousIndex, event.currentIndex);
  }

  data: any = [{
    eid: 'e101',
    ename: 'ravi',
    esal: 1000
  }, {
    eid: 'e102',
    ename: 'ram',
    esal: 2000
  }, {
    eid: 'e103',
    ename: 'rajesh',
    esal: 3000
  }];
  exportAsXLSX(): void {
    //  this.excelService.exportAsExcelFile(this.excel, 'sample');     ----- future purpose API----
    this.excelService.exportAsExcelFile(this.data, 'sample');
  }
  // public getJSON(): Observable<any> {  
  //   return this.http.get('https://api.myjson.com/bins/zg8of');  
  // }  

  ngOnInit() {

  }

}

