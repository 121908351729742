import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Inject, DoCheck } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { SpinnerService } from 'src/app/core/spinner.service';

@Component({
  selector: 'app-modal-spinner',
  templateUrl: './modal-spinner.component.html',
  styleUrls: ['./modal-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSpinnerComponent implements AfterViewInit {
  message: Subject<string> = new BehaviorSubject(this.data['isLoading']);
  dialog: any;
  constructor(private spinnerService: SpinnerService, private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<ModalSpinnerComponent>, @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngAfterViewInit(): void {
    if (this.data['isLoading'] === false) {
      this.dialogRef.close();
      this.message.next(this.data['isLoading'])
      //  setTimeout(() => this.dialogRef.close());
      setTimeout(() => {
        this.dialogRef.close()
      }, 100);
    }
  }
  //   // ngAfterViewInit() {
  //   //   if(this.data['isLoading'] === false){
  //   //     setTimeout(() => {
  //   //       this.dialogRef.close();
  //   //         }, 0);
  //   //         this.cdr.detectChanges();
  //   //     this.dialogRef.close();
  //   //   }
 //   // }
}