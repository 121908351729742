import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ConditionalExpr } from "@angular/compiler";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "src/app/shared/app-state";
import { CustomerService } from "../../../shared/services/customer.service";
import { AdmintoolsService } from "../../../shared/services/admintools.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {

  dataSource: any;
  pageSize: number;
  RecordCount: any;
  filteredData: any;
  accountArray = [];
  buttonDisabled: boolean;
  constructor(
    public dialogRef: MatDialogRef<DeleteUserComponent>,
    private route: ActivatedRoute,
    private state: AppState,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private CustomerService: CustomerService, 
    private AdminService: AdmintoolsService,
    ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getuserdata();
  }
  getuserdata() {
    this.AdminService.getUsers(1, this.pageSize).subscribe(data => {
      this.filteredData = data;
      this.dataSource = data.Result;
      this.dataSource = data.Result[0].FirstName;
      this.RecordCount = data.RecordCount;
    })
  }
  deleteUser() {
    this.buttonDisabled = true;
    this.data['Id'].forEach(item => {
      this.accountArray.push(item.Id);
    });
    for (var i = 0; i < this.accountArray.length; i++) {
      this.CustomerService.deleteUser(this.accountArray[i]).subscribe(
        data => {
          this.state.isSpinnerVisible = false;
          {
            this.dialogRef.close('res');
          }
        });
    }
  }
  redirectUserDetails(id) {
    this.router.navigate(["CustomerViewDetails/" + id.UserId + ""]);
  }
 
}


