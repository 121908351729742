import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'app-update-category-data-dialog',
  templateUrl: './update-category-data-dialog.component.html',
  styleUrls: ['./update-category-data-dialog.component.scss']
})
export class UpdateCategoryDataDialogComponent implements OnInit {
  categoryName: any;
  buttonDisabled: boolean;
  errorMessage: boolean;
  invaliddata: boolean;
  errorMessageduplicate: boolean;

  constructor(
    public dialogRef: MatDialogRef<UpdateCategoryDataDialogComponent>,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private customerService: CustomerService,
    private state: AppState,
    public capitailizepipe: specialPipe,
  ) { }

  ngOnInit() {
    this.categoryName = this.data[0].CategoryData.Name;
  }
  // POST-NEW-ITEM
  updateCategory() {
    if (this.categoryName) {
      const payload = {
        Name: this.capitailizepipe.transform(this.categoryName.replace(/^\s+/g, '')),
        Description: this.capitailizepipe.transform(this.categoryName.replace(/^\s+/g, '')),
        CreatedBy: this.data[0].CategoryData.CreatedBy,
        CreatedOn: this.data[0].CategoryData.CreatedOn,
        ModifiedBy: this.data[0].CategoryData.ModifiedBy,
        ModifiedOn: this.data[0].CategoryData.ModifiedOn,
        ApprovalStatusId: this.data[0].CategoryData.ApprovalStatusId,
        IsGoodrCategory: this.data[0].CategoryData.IsGoodrCategory,
        ParentId: this.data[0].CategoryData.ParentId,
        SectionId: this.data[0].CategoryData.SectionId,
        ClientId: this.data[0].CategoryData.ClientId
      };
      this.buttonDisabled = true;
      this.customerService.updateCategory(payload).subscribe(
        data => {
          if (data === 'Duplicate Entry') {
            this.buttonDisabled = false;
            this.errorMessageduplicate = true;
            setTimeout(() => {
              this.errorMessageduplicate = false;
            }, 3000);
          } else {
            let res = 'success';
            this.dialogRef.close(res);
            this.state.isSpinnerVisible = false;
          }
        },
        error => {
          if (error.statusText === 'Internal Server Error') {
            this.buttonDisabled = false;
            this.errorMessage = true;
            this.state.isSpinnerVisible = false;
          }
        }
      );
    } else {
      this.invaliddata = true;
      setTimeout(() => {
        this.invaliddata = false;
      }, 3000);
    }
  }
}
