import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateFormatPipe'
})
export class dateFormatPipe implements PipeTransform {
  transform(date: string, zone) {
    if (moment(date).isValid()) {
      let zonetime;
      if (zone) {
        zonetime = zone;
      } else {
        zonetime = 'America/New_York';
      }
      //   const  offset = -4.0;
      //   const  clientDate = new Date(date);
      //   const   utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
      // //   EST format
      //   const  serverDate = new Date(utc + (3600000 * offset));
      //   const myDate = new Date(serverDate);
      //   myDate.setHours(myDate.getHours() + 4) + ':' +  myDate.setMinutes(myDate.getMinutes() + 30);
      //   date = moment(myDate).format('MM/DD/YY LT');
      //   return date;
      const abbr = moment.tz.zone(zonetime).abbr(360);

      if (abbr == 'MST') {
        const updateDate = moment
        .utc(date)
        .clone()
        .tz(zonetime)
        // .add(60,'minutes')
        .format('MM/DD/YY LT')
        .toString();
      return updateDate;
      } else {
        const updateDate = moment
          .utc(date)
          .clone()
          .tz(zonetime)
          .format('MM/DD/YY LT')
          .toString();
        return updateDate;
      }
    }
    return null;
  }
}
