import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AppState } from 'src/app/shared/app-state';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { SpinnerService } from 'src/app/core/spinner.service';
import { timeFormatPipe } from 'src/app/shared/pipes/time.service';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { TimeZoneabbrService } from 'src/app/shared/pipes/time-zoneabbr.service';
import { PickUps } from 'src/app/models/pickups.model';
@Component({
  selector: 'app-track-route-picked-up',
  templateUrl: './track-route-picked-up.component.html',
  styleUrls: ['./track-route-picked-up.component.scss'],
})
export class TrackRoutePickedUpComponent implements OnInit {
  pickupsData: any;
  totalWeight: number;
  totalCost: number;
  totalTax: number;
  receiverData: any;
  istaxValueVisible: boolean;
  foodPickupType: boolean;
  CompostWeight: any;
  OrganicWeight: any;
  PaperWeight: any;
  totalCompostWeight: any;
  OrganicPickupType: boolean;
  sepaterdCompost: boolean;
  FoodSeparateTYpe: any;
  combinedCompost: boolean;
  CombinedTotalWeight: any;
  modifiedTime: any;
  timeZoneabbr: any;
  totalItemValue: any;
  totalItemValueWithUnit: string;
  totalQuantity: Number;
  orderScheduled:any;
  constructor(
    private spinnerService: SpinnerService,
    public timeDispalyFormatPipe: timeFormatPipe,
    private pickupService: PickUpService,
    private customerService: CustomerService,
    public timeZoneabbrivation: TimeZoneabbrService,
    public dateFormat: dateFormatPipe,
    private state: AppState,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.istaxValueVisible = getFeature('taxValue');
    var spinnerRef = this.spinnerService.start();
    this.pickupService.getPickUpTrack(this.route.snapshot.params['id']).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.pickupsData = data['Result'];
        if (this.pickupsData[0].PickupRequest.length > 0) {
          if (this.pickupsData[0].PickupRequest[0].ScheduleDate) {
            this.orderScheduled = this.dateFormat.transform(this.pickupsData[0].PickupRequest[0].ScheduleDate, this.pickupsData[0].PickupRequest[0].Clients[0].TimeZone);
          }
          if (this.pickupsData[0].PickupRequest[0].PickupType[0].Title === 'Organic Recycling') {
            this.OrganicPickupType = true;
            if (this.pickupsData[0].PickupRequest[0].OrganicRecyclingPickupType[0].Title === 'Separated') {
              this.sepaterdCompost = true;
              this.FoodSeparateTYpe = this.pickupsData[0].PickupRequest[0].PickupType[0].Title;
              if (this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling.length > 0) {
                this.totalCompostWeight = this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling.map((r) => r.Weight).reduce(
                  (sum, current) => Number(sum) + Number(current)
                );
              }
              // this.CompostWeight = this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling[0].CompostWeight;
              // this.OrganicWeight = this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling[0].OrganicWeight;
              // this.PaperWeight = this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling[0].PaperWeight;
              // this.totalCompostWeight = this.CompostWeight + this.OrganicWeight + this.PaperWeight;
            } else {
              this.FoodSeparateTYpe = this.pickupsData[0].PickupRequest[0].PickupType[0].Title;
              this.combinedCompost = true;
              if (this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling.length > 0) {
                this.CombinedTotalWeight = this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling[0].Weight;
              }
            }
          } else {
            this.foodPickupType = true;
          }
          if (this.pickupsData[0].PickupRequest[0].NonProfitReceiverId !== null) {
            this.getNonProfitReceiver(this.pickupsData[0].PickupRequest[0].NonProfitReceiverId);
          }
        } else {
          this.foodPickupType = true;
        }
        // if (this.pickupsData[0].modifiedTime) {
        //   this.pickupsData[0].modifiedTime = this.pickupsData[0].ModifiedOn.split('T')[1];
        // } else {
        //   this.pickupsData[0].modifiedTime = null;
        // }
        console.log(this.pickupsData[0].ModifiedOn);
        this.timeZoneabbr = this.timeZoneabbrivation.transform(this.pickupsData[0].PickupRequest[0].Clients[0].TimeZone);
        if (this.pickupsData[0].ModifiedOn) {
          this.modifiedTime = this.timeDispalyFormatPipe.transform(
            this.pickupsData[0].ModifiedOn,
            this.pickupsData[0].PickupRequest[0].Clients[0].TimeZone
          );
          this.pickupsData[0].modifiedDate = this.pickupsData[0].ModifiedOn.split('T')[0];
          this.pickupsData[0].modifiedTime = this.pickupsData[0].ModifiedOn.split('T')[1];
        } else {
          this.pickupsData[0].modifiedDate = null;
          this.pickupsData[0].modifiedTime = null;
        }
        if (this.pickupsData[0].PickupDate) {
          this.pickupsData[0].PickupDate = this.dateFormat.transform(
            this.pickupsData[0].PickupDate,
            this.pickupsData[0].PickupRequest[0].Clients[0].TimeZone
          );
        }
        if (this.pickupsData[0].DeliveryDate) {
          this.pickupsData[0].DeliveryDate = this.dateFormat.transform(
            this.pickupsData[0].DeliveryDate,
            this.pickupsData[0].PickupRequest[0].Clients[0].TimeZone
          );
        }
        // if (this.pickupsData[0].ModifiedOn) {
        //   this.modifiedTime = this.dateFormat.transform(
        //     this.pickupsData[0].ModifiedOn,
        //     this.pickupsData[0].PickupRequest[0].Clients[0].TimeZone
        //   );
        // }
        this.totalItemValue = 0;
        this.totalWeight = 0;
        this.totalCost = 0;
        this.totalQuantity = 0;
        for (let i = 0; i <= this.pickupsData[0].PickupRequest[0]['PickupRequest_Product'].length - 1; i++) {
          const pickUnit: PickUps = this.pickupsData[0].PickupRequest[0]['PickupRequest_Product'][i];

          let pickupDetails = this.pickupsData[0].PickupRequest[0]['PickupRequest_Product'][i];
          const itemValue = pickupDetails.Quantity * pickupDetails.Cost;
          this.totalItemValue = this.totalItemValue + itemValue;
          this.totalCost = this.totalItemValue.toFixed(2);
          //weight-calculation
          this.totalQuantity = Number(this.totalQuantity + pickupDetails.Quantity);
          const itemWeightValue = pickupDetails.Quantity * pickupDetails.Weight;
          this.totalWeight = this.totalWeight + itemWeightValue;
        }
        // for (const data of this.pickupsData[0].PickupRequest[0]
        //   .PickupRequest_Product) {
        //   //weight-calculation
        //   this.totalWeight = this.totalWeight + data.Weight;
        //   //cost-calculation
        //   this.totalCost = this.totalCost + data.Cost;
        //   //tax-calculation
        // }
        // this.totalCost = parseFloat(this.totalCost.toFixed(2));
        this.totalTax = this.totalCost / 2;
        this.state.pickups = data;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        this.spinnerService.stop(spinnerRef);
      }
    );
  }

  getNonProfitReceiver(id) {
    this.customerService.getNonProfitReceiver(id).subscribe(
      (data) => {
        this.receiverData = data['Result'][0];
      },
      (error) => {
        this.state.isSpinnerVisible = false;
      }
    );
  }
  goToBackPage = () => this.router.navigate(['pickupDetails/' + this.route.params['_value'].id]);
}
