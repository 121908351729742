import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AppState } from 'src/app/shared/app-state';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { ConstantPool } from '@angular/compiler';
import { MyOrderByPipe } from 'src/app/shared/pipes/sort.pipe';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'app-pickupsFilter',
  templateUrl: './pickupsFilter.component.html',
  styleUrls: ['./pickupsFilter.component.scss']
})
export class PickupsFilterComponent implements OnInit {
  info: any;
  DeliveryId: any;
  PickupData: any;
  filterStatus: any;
  status: any;
  Recipient: any;
  ClientData: any;
  RecipientData: any;
  selectedclient: any;
  selectedRecipient: any;
  ClientList: any;
  RecipientList: any;
  StatusData: any;
  StatusList: any = [];
  selectedstatus: any;
  VisibleForAdmin: boolean;
  VisibleForNP: boolean;
  selectedRecipientName: any;
  selectedclientName: any;
  PickupList: any;
  selectedPickup: any;
  driverService: any;
  // selectedDriver: any;
  selDriverservice: any;
  featureinfo: any;
  OTPFeature = true;
  SAASFeature = true;
  infoallclients: any;
  Visibledriverservice = true;
  constructor(
    private formBuilder: FormBuilder,
    public orderService: MyOrderByPipe,
    public dialogRef: MatDialogRef<PickupsFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private AdminService: AdmintoolsService,
    private customerServices: CustomerService,
    private pickupService: PickUpService,
    private ClientFilterdata: ClientFilterService,
    private state: AppState
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe(res => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'OTP' && element.EnableFeature === false) {
            this.OTPFeature = false;
          }
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            this.SAASFeature = false;
          }
        }
      }
      // }
    });
    this.VisibleForAdmin = getFeature('PickupFilter');
    this.VisibleForNP = getFeature('RecipientList');
    this.DeliveryId = this.data['DeliveryId'];
    this.Visibledriverservice = getFeature('driverservices');
    // this.ClientData = this.state.user.User.Clients;
    this.ClientFilterdata.basicInfoObserveclient.subscribe(res => {
      this.infoallclients = JSON.parse(res);
    });
    // this.AdminService.getClientwithoutPagination().subscribe(clientData => {
    this.ClientData = this.infoallclients.allClients;
    const clients = [];
    for (let i = 0; i < this.ClientData.length; i++) {
      const clients_data = {
        ClientId: this.ClientData[i].ClientId,
        Name: this.ClientData[i].Name
      };
      clients.push(clients_data);
    }
    this.ClientList = clients;
    for (var i = 0; i < this.ClientList.length; i++) {
      if (this.ClientList[i].ClientId == this.data['selectedclient']) {
        this.selectedclient = this.ClientList[i].ClientId;
        this.selectedclientName = this.ClientList[i].Name;
      }
    }
    // });
    this.pickupService.getRecipientList().subscribe(
      data => {
        this.Recipient = data;
        const recipients = [];
        for (let i = 0; i < this.Recipient.length; i++) {
          this.RecipientData = this.Recipient[i];
          const recipients_data = {
            RecipientId: this.RecipientData.ClientId,
            Name: this.RecipientData.Name
          };
          recipients.push(recipients_data);
        }
        this.RecipientList = recipients;
        for (var i = 0; i < this.RecipientList.length; i++) {
          if (
            this.RecipientList[i].RecipientId == this.data['selectedRecipient']
          ) {
            this.selectedRecipient = this.RecipientList[i].RecipientId;
            this.selectedRecipientName = this.RecipientList[i].Name;
          }
        }
        this.RecipientList = this.orderService.transform(
          this.RecipientList,
          'Name'
        );
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );

    this.customerServices.getPickupTypeList().subscribe(data => {
      this.driverService = data.Result;
      // if (this.SAASFeature === false) {
      //   this.driverService = this.driverService.filter(
      //     value => value.Title !== 'SAAS'
      //   );
      // }
      this.selDriverservice = this.data['selDriverservice'];
    });

    // Pickup Type
    this.pickupService.getPickupFilterList().subscribe(data => {
      this.PickupList = data.Result;
      if (this.OTPFeature === false) {
        this.PickupList = this.PickupList.filter(picuptype => {
          return picuptype.Title !== 'One Time Pickup';
        });
      }
      this.selectedPickup = this.data['selectedPickup'];
    });
    // Close Pickup Type
    this.pickupService.getStatusList().subscribe(
      data => {
        this.StatusData = data;
        const status = [];
        for (let i = 0; i < data['Result'].length; i++) {
          this.StatusData = data['Result'][i];
          if (this.StatusData.Title === 'OnRoute') {
            this.StatusData.Title = 'Food Acquired';
          } else if (this.StatusData.Title === 'PickedUp') {
            this.StatusData.Title = 'Loading Truck';
          }
          const status_data = {
            Id: this.StatusData.ID,
            Title: this.StatusData.Title
          };
          status.push(status_data);
        }
        //this.StatusList = status;

        status.forEach((element) => {
          if (element['Title'] === 'Pending') {
            this.StatusList[0] = element;
          } else if (element['Title'] === 'UnClaimed') {
            this.StatusList[1] = element;
          } else if (element['Title'] === 'Claimed') {
            this.StatusList[2] = element;
          } else if (element['Title'] === 'Loading Truck') {
            this.StatusList[3] = element;
          } else if (element['Title'] === 'Food Acquired') {
            this.StatusList[4] = element;
          } else if (element['Title'] === 'Delivered') {
            this.StatusList[5] = element;
          } else if (element['Title'] === 'Cancelled') {
            this.StatusList[6] = element;
          }
        });

        for (var i = 0; i < this.StatusList.length; i++) {
          if (this.data['selectedstatus'] === 'OnRoute') {
            this.data['selectedstatus'] = 'Food Acquired';
          } else if (this.data['selectedstatus'] === 'PickedUp') {
            this.data['selectedstatus'] = 'Loading Truck';
          }
          if (this.StatusList[i].Title === this.data['selectedstatus']) {
            this.selectedstatus = this.StatusList[i].Title;
          }
        }
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  selectedclientfilter() {
    for (var i = 0; i < this.ClientList.length; i++) {
      if (this.ClientList[i].ClientId == this.selectedclient) {
        this.selectedclientName = this.ClientList[i].Name;
      }
    }
  }
  selectedRecipientfilter() {
    for (var i = 0; i < this.RecipientList.length; i++) {
      if (this.RecipientList[i].RecipientId == this.selectedRecipient) {
        this.selectedRecipientName = this.RecipientList[i].Name;
      }
    }
  }
  closePickups(closeData) {
    if (closeData === 'delivery') {
      this.DeliveryId = null;
    } else if (closeData === 'Status') {
      this.selectedstatus = null;
    } else if (closeData === 'Client') {
      this.selectedclient = null;
    } else if (closeData === 'Recipient') {
      this.selectedRecipient = null;
    } else if (closeData === 'Pickup') {
      this.selectedPickup = null;
    } else if (closeData === 'driverservice') {
      this.selDriverservice = null;
    }
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  searchFilter() {
    if (this.DeliveryId === undefined) {
      this.DeliveryId = null;
    }
    if (this.selectedstatus === undefined) {
      this.selectedstatus = null;
    }
    if (this.selectedclient === undefined) {
      this.selectedclient = null;
    }
    if (this.selectedRecipient === undefined) {
      this.selectedRecipient = null;
    }
    if (this.selectedPickup === undefined) {
      this.selectedPickup = null;
    }
    if (this.selDriverservice === undefined) {
      this.selDriverservice = null;
    }

    const data = {
      Delivery: this.DeliveryId,
      Status: this.selectedstatus,
      Client: this.selectedclient,
      Recipient: this.selectedRecipient,
      RecipientName: this.selectedRecipientName,
      Pickup: this.selectedPickup,
      DriverService: this.selDriverservice
    };
    this.dialogRef.close(data);
  }
}
