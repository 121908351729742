import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { PickUps } from 'src/app/models/pickups.model';
import { AppState } from 'src/app/shared/app-state';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import * as moment from 'moment-timezone';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';

import { EditMenuComponent } from '../edit-menu/edit-menu.component';
import { DatePostFormatPipe } from 'src/app/shared/pipes/dateformat.service';
import { SuccesPopupComponent } from 'src/app/SAAS/regModule/succes-popup/succes-popup.component';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { DateDispalyFormatPipeService } from 'src/app/shared/pipes/date-dispaly-format-pipe.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { TimezonedatepostService } from 'src/app/shared/pipes/timezonedatepost.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { orCustomMaterialList } from 'src/app/shared/services/or-custom-material-list.model';
import { DeleteMenuComponent } from '../delete-menu/delete-menu.component';
import { SaveEditMenumodalComponent } from '../save-edit-menumodal/save-edit-menumodal.component';
import { MatTableDataSource } from '@angular/material/table';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
@Component({
  selector: 'app-pickupeditable',
  templateUrl: './pickupeditable.component.html',
  styleUrls: ['./pickupeditable.component.scss'],
})
export class PickupeditableComponent implements OnInit, AfterViewInit {
  info: any;
  DeliveryId: any;
  filterStatus: any;
  status: any;
  Recipient: any;
  ClientData: any;
  RecipientData: any;
  selectedclient: any;
  selectedRecipient: any;
  ClientList: any;
  RecipientList: any;
  StatusData: any;
  StatusList: any = [];
  selectedstatus: any;
  VisibleForAdmin: boolean;
  VisibleForNP: boolean;
  taxValue: any;
  weight: any;
  CreatedOn: any;
  PickupDate: any;
  DeliveryDate: any;
  DriverServices: any;
  pickup: any;
  requestStatus: any;
  selectedDriver: any = '';
  selectedDropOfLocationObject: any;
  recipient: any;
  roadiesPickup: boolean;
  saasclient: boolean;
  drivers: any = [];
  nonprofitsDisabled = false;
  CreatedOnStartTime: any;
  CreatedOnStartDate: Date;
  selectedDate: any;
  requireTimeField: boolean;
  selectedCreatedOnTime: string;
  DeliveryStartDate: Date;
  DeliveryStartTime: string;
  selectedDeliveryDate: any;
  selectedDeliveryTime: string;
  PickupDateStartDate: Date;
  selectedPickupDate: any;
  selectedPickupTime: string;
  PickupStartTime: string;
  goodrDriver = true;
  CreatedOnDate: any;
  statusSet: boolean;
  RecipientSet: boolean;
  UpdateForAdmin: boolean;
  selectedSGDriver: string;
  selectedRecipientNone: boolean;
  showCreatedStartTime: any;
  showDeliveryStartTime: any;
  showPickupStartTime: any;
  showDeliveryStartDate: Date;
  showCreatedOnStartDate: Date;
  showPickupDateStartDate: Date;
  OrganicPickupType: boolean;
  foodPickupType: boolean;
  combinedCompost: boolean;
  SeparateCompost: boolean;
  driverSet = false;
  error: any;
  pickups: PickUps;
  ShippingID: any;
  nonprofitUpdate: boolean;
  totalItemValue: number;
  totalWeight: number;
  totalQuantity: number;
  totalItemValueWithUnit: string;
  totalWeightwithUnit: string;
  roadiesPickupAfter: string;
  roadiesPickupBefore: string;
  editblePickup: boolean;
  pickupIdData: any;
  pickupAfter: any;
  pickupBefore: any;
  postmate: boolean;
  editblePostmate: boolean;
  PostmateId: any;
  postmaterrors: string;
  cancelPostmate: boolean;
  relaodinfo: any;
  saaseditblemenu = true;
  editmenuPostmate = true;
  roadieseditblePickup = true;
  editmenusvalid: boolean;
  postmateRoadies: boolean;
  PickupAfterStartTime: any;
  PickupAfterStartDate: Date;
  showPickupAfterStartDate: Date;
  PickupBeforeStartTime: any;
  PickupBeforeStartDate: Date;
  showPickupBeforeStartDate: Date;
  showPickupAfterStartTime: string;
  showPickupBeforeStartTime: string;
  driverService: any;
  driverServicetitle: any;
  notassigndriver: boolean;
  roadiesPos: boolean;
  roadiePostmatedrop: boolean;
  drivertitile: any;
  selectDriverservice: boolean;
  selectedDateafter: any;
  selectedDatebefore: any;
  ShippingStatusID: any;
  featureinfo: any;
  RoadiePostmatesFeature: boolean;
  selectedCreatedOnTimenp: any;
  newDate: any;
  showPickupDetails: boolean;
  showEditPickupItems: boolean;
  showAddItemsDiv: boolean;
  newItemName;
  newItemCost;
  newItemWeight;
  newItemQuantity;
  categoriesData;
  selectedCategory;

  //Edit Pickup variables
  displayedColumnsOR: string[] = ['nameOR','weightOR','quantityOR','ContaminatedWeight','ContaminatedQuantity','editAdd'];
  displayedColumns: string[] = ['name', 'Cost', 'Weight', 'Quantity'];
  displayedColumns1: string[] = ['name1', 'category1', 'Cost1', 'Weight1', 'Quantity1'];
  dataSource2;
  displayedColumnsCompost: string[] = ['Title', 'Weight', 'QuantityOR','ContaminatedWeight','ContaminatedQuantity','add'];
  ELEMENT_DATA: PickupeditableComponent[] = [];
  dataSource: any;
  transaction: any = [];
  pickupRequestdata: any = [];
  sendRequest: any = [];
  editPickup: any;
  deleteRequest: any = [];
  totalItems = 0;
  public createForm: FormGroup;
  imgSrc = '../../assets/images/addicon.png';
  wasClicked = false;
  requestData: { PickupRequest_Product: any; ClientId: number };
  dataedit: boolean;
  requestDataDisabled: boolean;
  success: boolean;
  SeparatedCombined: any;
  deleteSource: any;
  confirmbutton: boolean;
  showEditble: boolean;
  reqestType: any;
  CombinedRequest: boolean;
  Separated: boolean;
  foodRequest: boolean;
  separeatePickupID: any;
  combinedPickupID: any;
  separatedCompost: any;
  dataSourceCombined: any;
  dataSourceCombinedData: any;
  totalItemValue1: number;
  totalWeight1: number;
  totalQuantity1: number;
  totalItemValueWithUnit1: string;
  totalWeightwithUnit1: string;
  ShippingIDEdit: any;
  nonprofitUpdate1: boolean;
  error1: any;
  spinnerRef: any;
  editRelaodinfo: any;
  disableUpdateMenuList: any;
  ClientId: any;
  categories: any[];
  originalData: any;
  newItem: any;
  dataSource1: any;
  newAddedItems = [];
  newAddedItemsData;
  clientsItemsList;
  showError: boolean;
  searchItemName;
  searchCategoryName;
  duplicateItemReqError: boolean;
  newProductId: any;
  pickupRequestId;
  filteredDataSF;
  noresultFound: any;
  dataSourceOR: any;
  previousValues: object;
  confirmCheckPath: string = '../../../assets/images/confirmcheck.png';
  cancelXPath: string = '../../../assets/images/redx.png';
  addIcon: string = '../../../assets/images/addicon.png';
  editIcon: string = '../../../assets/images/pickupediticon.png';
  isLoadingDrivers: boolean = false;

  constructor(
    private spinnerService: SpinnerService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PickupeditableComponent>,
    public postDateService: DatePostFormatPipe,
    public dialog: MatDialog,
    private AdminService: AdmintoolsService,
    private customerService: CustomerService,
    public timezonedatepostService: TimezonedatepostService,
    private ClientFilterdata: ClientFilterService,
    private dateDispalyFormatPipeService: DateDispalyFormatPipeService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private _rdata: modalDataService,
    private pickupService: PickUpService,
    private state: AppState,
    public capitailizepipe: specialPipe
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (!this.data[1]) {
      this.showEditPickupItems = false;
      this.showPickupDetails = true;
    }
    var spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'Roadie/Postmates' && element.EnableFeature === true) {
            this.RoadiePostmatesFeature = true;
          } else {
            this.RoadiePostmatesFeature = false;
          }
        }
      }
    });
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
    });
    this.UpdateForAdmin = getFeature('PickupMenuUpdate');
    this.pickupService.getPickUpDetail(this.data[0].pickupdata.DeliveryId).subscribe((data) => {
      this.driverServicedata(spinnerRef);
      let pickups;
      pickups = data.Result;
      this.saaseditblemenu = false;
      this.editmenuPostmate = false;
      this.roadieseditblePickup = false;
      this.pickupIdData = pickups[0];

      if (!this.pickupIdData.TotalWeight) {
        this.pickupIdData.TotalWeight = 0;
      }

      this.ClientId = pickups[0].PickupRequest[0].Clients[0].ClientId;
      this.pickupRequestId = pickups[0].PickupRequest[0].RequestId;

      if (this.data[1]) if (this.data[1].showEditPickupItems == true) this.editPickupMenu();
      const listproduct = [];
      this.reqestType = this.pickupIdData.PickupRequest[0].PickupType[0].Title;
      if (this.reqestType === 'CombinedEditMenu') {
        this.CombinedRequest = true;
      } else if (this.reqestType === 'Organic Recycling') {
        this.Separated = true;
      } else if (this.reqestType === 'Food') {
        this.foodRequest = true;
        // this.getClientPickupList();
      }

      if (this.CombinedRequest === true) {
        this.pickupIdData.PickupRequest[0].PickupRequest_OrganicRecycling.map((value) => {
          value.SeparatedCompost[0] = { Description: 'Combined' };
          value.Weight = value.Weight * 0.0625;
          if (value.wasClicked === false) {
            value.imgSrc = '../../assets/images/addicon.png';
            value.buttonDisabled = false;
          } else {
            value.imgSrc = '../../assets/images/addicon.png';
          }
          listproduct.push(value);
          this.dataSourceCombinedData = listproduct;
          this.deleteSource = this.dataSourceCombinedData;
          this.totalItems = this.deleteSource.length;
        });
      }
      if (this.Separated === true) {
        // var spinnerRef = this.spinnerService.start();
        this.pickupIdData.PickupRequest[0].PickupRequest_OrganicRecycling.map((value) => {
          value.Weight = value.Weight * 0.0625;
          if (value.wasClicked === false) {
            value.imgSrc = '../../assets/images/addicon.png';
            value.buttonDisabled = false;
          } else {
            value.imgSrc = '../../assets/images/addicon.png';
          }
          listproduct.push(value);
          // this.dataSourceCombinedData = listproduct;
          // this.deleteSource = this.dataSourceCombinedData;
          // this.totalItems = this.deleteSource.length;
        });
        this.pickupService.SeparatedCompost().subscribe((data) => {
          // this.spinnerService.stop(spinnerRef);
          this.separatedCompost = orCustomMaterialList(data.Result);
          var filteredArray = this.separatedCompost.filter((client) => {
            return (
              listproduct.filter((editble) => {
                return editble.SeparatedCompost[0].ID == client.ID;
              }).length == 0
            );
          });
          this.dataSourceCombinedData = listproduct;
          this.deleteSource = this.dataSourceCombinedData;
          this.totalItems = this.deleteSource.length;
        });
      }
      if (this.pickupIdData.PickupRequest[0].PickupType.length > 0) {
        if (this.pickupIdData.PickupRequest[0].PickupType[0].Title === 'Organic Recycling') {
          this.OrganicPickupType = true;
          if (this.pickupIdData.PickupRequest[0].OrganicRecyclingPickupType.length > 0) {
            if (this.pickupIdData.PickupRequest[0].OrganicRecyclingPickupType[0].Title === 'Combined') {
              this.combinedCompost = false;
              localStorage.setItem('EditMenuType', JSON.stringify('CombinedEditMenu'));
            } else {
              localStorage.setItem('EditMenuType', JSON.stringify('SeparatedEditMenu'));
              this.SeparateCompost = true;
            }
          }
          this.foodPickupType = true;
          // this.OrganicPickupType = false;
          // localStorage.setItem('EditMenuType', JSON.stringify('foodTypeEditMenu'));
        } else {
          localStorage.setItem('EditMenuType', JSON.stringify('foodTypeEditMenu'));
          this.foodPickupType = true;
          this.OrganicPickupType = false;
        }
      } else {
        localStorage.setItem('EditMenuType', JSON.stringify('foodTypeEditMenu'));
        this.foodPickupType = true;
        this.OrganicPickupType = false;
      }
      if (this.pickupIdData.PickupRequest[0].Clients[0].DriverServices.length > 0) {
        if (this.pickupIdData.PickupRequest[0].DriverServices.length > 0) {
          this.drivertitile = this.pickupIdData.PickupRequest[0].DriverServices[0].Title;
          this.DriverServices = this.pickupIdData.PickupRequest[0].DriverServices[0].Title;
        } else {
          this.drivertitile = this.pickupIdData.PickupRequest[0].Clients[0].DriverServices[0].Title;
          this.DriverServices = this.pickupIdData.PickupRequest[0].Clients[0].DriverServices[0].Title;
        }
      }

      this.newDate = this.dateDispalyFormatPipeService.transform(new Date(), this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
      //set-date
      if (this.pickupIdData.DeliveryDate !== '' && this.pickupIdData.DeliveryDate !== null) {
        let finalDeliveryDateSplit;
        finalDeliveryDateSplit = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.DeliveryDate,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.selectedDeliveryTime = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.DeliveryDate,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.DeliveryStartTime = finalDeliveryDateSplit.split('T')[1];
        this.showDeliveryStartTime = moment(finalDeliveryDateSplit.split('T')[1], ['HH.mm']).format('hh:mm a');
        this.DeliveryStartDate = new Date(finalDeliveryDateSplit);
        this.showDeliveryStartDate = new Date(finalDeliveryDateSplit);
      }
      if (this.pickupIdData.CreatedOn !== '' && this.pickupIdData.CreatedOn !== null) {
        let finalselectedstaredDateSplit;
        finalselectedstaredDateSplit = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.CreatedOn,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.selectedCreatedOnTime = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.CreatedOn,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.CreatedOnStartTime = finalselectedstaredDateSplit.split('T')[1];
        this.showCreatedStartTime = moment(finalselectedstaredDateSplit.split('T')[1], ['HH.mm']).format('hh:mm a');
        this.CreatedOnStartDate = new Date(finalselectedstaredDateSplit);
        this.showCreatedOnStartDate = new Date(finalselectedstaredDateSplit);
      }
      if (this.pickupIdData.PickupDate !== '' && this.pickupIdData.PickupDate !== null) {
        let finalPickupDateDateSplit;
        finalPickupDateDateSplit = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.PickupDate,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.selectedPickupTime = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.PickupDate,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.PickupStartTime = finalPickupDateDateSplit.split('T')[1];
        this.showPickupStartTime = moment(finalPickupDateDateSplit.split('T')[1], ['HH.mm']).format('hh:mm a');
        this.PickupDateStartDate = new Date(finalPickupDateDateSplit);
        this.showPickupDateStartDate = new Date(finalPickupDateDateSplit);
      }
      if (this.pickupIdData.PickupAfter) {
        let finalselectedstaredDateSplit;
        finalselectedstaredDateSplit = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.PickupAfter,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.roadiesPickupAfter = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.PickupAfter,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.PickupAfterStartTime = finalselectedstaredDateSplit.split('T')[1];
        this.showPickupAfterStartTime = moment(finalselectedstaredDateSplit.split('T')[1], ['HH.mm']).format('hh:mm a');
        this.PickupAfterStartDate = new Date(finalselectedstaredDateSplit);
        this.showPickupAfterStartDate = new Date(moment(finalselectedstaredDateSplit).format('YYYY/MM/DD'));
      } else {
        if (this.DriverServices === 'Roadie/Postmates' || this.DriverServices === 'Roadie') {
          let startdate = new Date();
          const startDate = new Date(
            this.timezonedatepostService.transform(startdate.toISOString(), this.pickupIdData.PickupRequest[0].Clients[0].TimeZone)
          );
          this.roadiesPickupAfter = moment(startDate)
            // .add(1, 'hours')
            .add(5, 'minutes')
            .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
          this.PickupAfterStartTime = this.roadiesPickupAfter.split('T')[1];
          this.showPickupAfterStartTime = moment(this.roadiesPickupAfter.split('T')[1], ['HH.mm']).format('hh:mm a');
          this.PickupAfterStartDate = new Date();
          this.showPickupAfterStartDate = new Date(moment(this.roadiesPickupAfter).format('YYYY/MM/DD'));
        }
      }
      if (this.pickupIdData.PickupBefore) {
        let finalselectedbeforeDateSplit;
        finalselectedbeforeDateSplit = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.PickupBefore,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.roadiesPickupBefore = this.dateDispalyFormatPipeService.transform(
          this.pickupIdData.PickupBefore,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
        this.PickupBeforeStartTime = finalselectedbeforeDateSplit.split('T')[1];
        this.showPickupBeforeStartTime = moment(finalselectedbeforeDateSplit.split('T')[1], ['HH.mm']).format('hh:mm a');
        this.PickupBeforeStartDate = new Date(finalselectedbeforeDateSplit);
        this.showPickupBeforeStartDate = new Date(moment(finalselectedbeforeDateSplit).format('YYYY/MM/DD'));
      } else {
        if (this.DriverServices === 'Roadie/Postmates' || this.DriverServices === 'Roadie') {
          this.roadiesPickupBefore = moment(this.roadiesPickupAfter)
            .add(2, 'hours')
            .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
          this.PickupBeforeStartTime = this.roadiesPickupBefore.split('T')[1];
          this.showPickupBeforeStartTime = moment(this.roadiesPickupBefore.split('T')[1], ['HH.mm']).format('hh:mm a');
          this.PickupBeforeStartDate = new Date();
          this.showPickupBeforeStartDate = new Date(moment(this.roadiesPickupBefore).format('YYYY/MM/DD'));
        }
      }
      this.DeliveryId = this.pickupIdData.DeliveryId;
      this.taxValue = this.pickupIdData.taxValue / 2;
      this.weight = this.pickupIdData.weight;

      // this.AdminService.getGoodrDrivers().subscribe(
      //   (data) => {
      //     this.drivers = data.Result;
      //     if (this.drivers.length > 0 && this.pickupIdData.Driver.length > 0) {
      //       for (var i = 0; i < this.drivers.length; i++) {
      //         if (this.drivers[i].Id === this.pickupIdData.Driver[0].Id) {
      //           this.selectedDriver = this.drivers[i].Id;
      //         }
      //       }
      //     }
      //   },
      //   (error) => {
      //     this.state.isSpinnerVisible = false;
      //     alert(JSON.stringify(error));
      //   }
      // );

      this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
        this.info = JSON.parse(res);
        if (this.info.facility.length > 0) {
          this.ClientData = this.info.facility;
        }
      });

      this.selectedstatus = this.pickupIdData.Status[0].Title;
      const clients = [];
      if (this.DriverServices === 'Roadie/Postmates') {
        if (
          this.pickupIdData.Status[0].Title === 'Pending' &&
          (this.pickupIdData.PickupRequest[0].DriverServices === null ||
            this.pickupIdData.PickupRequest[0].DriverServices.length === 0 ||
            this.pickupIdData.PickupRequest[0].DriverServices === undefined)
        ) {
          this.notassigndriver = true;
        }
        this.roadiePostmatedrop = true;
        if (this.pickupIdData.PickupRequest[0].DriverServices.length > 0) {
          if (
            this.pickupIdData.Status[0].Title === 'Pending' &&
            (this.pickupIdData.PickupRequest[0].DriverServices[0].Title === null ||
              this.pickupIdData.PickupRequest[0].DriverServices[0].Title === undefined)
          ) {
            this.notassigndriver = true;
          }
          if (this.pickupIdData.PickupRequest[0].DriverServices[0].Title === 'Roadie') {

            if (this.pickupIdData && this.pickupIdData.Driver.length > 0) {
              this.roadiesPickup = true;
              this.roadiesPos = true;
            }

            if (this.pickupIdData.PickupRequest[0].PickupType.length > 0) {
              if (
                this.pickupIdData.Status[0].Title === 'Pending' ||
                this.pickupIdData.Status[0].Title === 'UnClaimed'
              ) {
                this.roadieseditblePickup = false;
              } else if (this.pickupIdData.Status[0].Title === 'Cancelled') {
                this.checkRecipientPermission('cancelPostmate');
                this.roadieseditblePickup = true;
              } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
                this.cancelPostmate = false;
                this.roadieseditblePickup = false;
              } else {
                this.roadieseditblePickup = true;
              }
            }

            if (this.pickupIdData.Status[0].Title === 'UnClaimed') {
              this.editblePickup = false;
            } else {
              this.checkRecipientPermission('editblePickup');
            }
            this.goodrDriver = false;
            this.selectedSGDriver = 'DriverState=' + this.pickupIdData.PickupRequest[0].Clients[0].State;
          } else {
            this.postmate = true;
            this.goodrDriver = false;
            if (this.pickupIdData.PickupRequest[0].PickupType.length > 0) {
              if (this.pickupIdData.Status[0].Title === 'Pending') {
                this.editmenuPostmate = false;
              } else {
                this.editmenuPostmate = true;
              }
            }
            if (this.pickupIdData.Status[0].Title === 'Pending') {
              this.editblePostmate = false;
            } else if (this.pickupIdData.Status[0].Title === 'Cancelled' && this.pickupIdData.PostmateId) {
              this.checkRecipientPermission('cancelPostmate');
            } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
              this.checkRecipientPermission('cancelPostmate');
            } else if (this.pickupIdData.Status[0].Title === 'Cancelled') {
              this.editblePostmate = false;
            } else {
              this.checkRecipientPermission('editblePostmate');
            }
          }
        } else {
          this.postmateRoadies = true;
          this.roadiesPos = true;
          this.goodrDriver = false;
        }

      } else if (this.DriverServices === 'Roadie' || this.DriverServices === 'Postmates') {
        this.roadiesPos = true;
        this.roadiePostmatedrop = true;

        if (this.DriverServices === 'Roadie') {
          this.roadiesPickup = true;

          if (
            this.pickupIdData.Status[0].Title === 'Pending' ||
            this.pickupIdData.Status[0].Title === 'UnClaimed'
          ) {
            this.roadieseditblePickup = false;
          } else if (this.pickupIdData.Status[0].Title === 'Cancelled') {
            this.checkRecipientPermission('cancelPostmate');
            this.roadieseditblePickup = true;
          } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
            this.cancelPostmate = false;
            this.roadieseditblePickup = false;
          } else {
            this.roadieseditblePickup = true;
          }

          if (this.pickupIdData.Status[0].Title === 'UnClaimed' || this.pickupIdData.Status[0].Title === 'Pending') {
            this.editblePickup = false;
          } else {
            this.checkRecipientPermission('editblePickup');
          }
          this.goodrDriver = false;
          this.selectedSGDriver = 'DriverState=' + this.pickupIdData.PickupRequest[0].Clients[0].State;
        } else {
          this.postmate = true;
          this.goodrDriver = false;
          if (this.pickupIdData.PickupRequest[0].PickupType.length > 0) {
            if (this.pickupIdData.Status[0].Title === 'Pending') {
              this.editmenuPostmate = false;
            } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
              this.editmenuPostmate = false;
            } else {
              this.editmenuPostmate = true;
            }
          }
          if (this.pickupIdData.Status[0].Title === 'Pending') {
            this.editblePostmate = false;
          } else if (this.pickupIdData.Status[0].Title === 'Cancelled' && this.pickupIdData.PostmateId) {
            this.checkRecipientPermission('cancelPostmate');
          } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
            this.checkRecipientPermission('cancelPostmate');
          } else if (this.pickupIdData.Status[0].Title === 'Cancelled') {
            this.editblePostmate = false;
          } else {
            this.checkRecipientPermission('editblePostmate');
          }
        }
      } else if (this.DriverServices === 'SAAS') {
        if (this.pickupIdData.PickupRequest[0].PickupType.length > 0) {
          this.saaseditblemenu = false;
        }
        this.selectedSGDriver = 'sAASDriver=true&pagingparametermodel.nonPaginatedUsers=true';
        this.saasclient = true;
      } else if (this.DriverServices === 'Postmates') {
        this.postmate = true;
        if (this.pickupIdData.PickupRequest[0].PickupType.length > 0) {
          if (this.pickupIdData.Status[0].Title === 'Pending') {
            this.editmenuPostmate = false;
          } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
            this.editmenuPostmate = false;
          } else {
            this.editmenuPostmate = true;
          }
        }
        if (this.pickupIdData.Status[0].Title === 'UnClaimed') {
          this.editblePostmate = false;
        } else if (this.pickupIdData.Status[0].Title === 'Cancelled' && this.pickupIdData.PostmateId) {
          this.checkRecipientPermission('cancelPostmate');
        } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
          this.checkRecipientPermission('cancelPostmate');
        } else if (this.pickupIdData.Status[0].Title === 'Cancelled') {
          this.editblePostmate = false;
        } else {
          this.editblePostmate = true;
        }
      } else {
        if (this.pickupIdData.PickupRequest[0].PickupType.length > 0) {
          this.saaseditblemenu = false;
        }
        this.selectedSGDriver = 'DriverState=' + this.pickupIdData.PickupRequest[0].Clients[0].State;
      }
      
      this.isLoadingDrivers = true;
      this.AdminService.getUsersByDriver(this.selectedSGDriver).subscribe(
        (data) => {
        this.drivers = data.Result;
        
        if (this.pickupIdData.Driver.length > 0) {
          for (var i = 0; i < this.drivers.length; i++) {
            if (this.drivers[i].Id === this.pickupIdData.Driver[0].Id) {
              this.selectedDriver = this.drivers[i].Id;
            }
          }
        }

        this.isLoadingDrivers = false;
      },
      (error) => {
        this.isLoadingDrivers = false;
      });

      for (let i = 0; i < this.ClientData.length; i++) {
        const clients_data = {
          ClientId: this.ClientData[i].ClientId,
          Name: this.ClientData[i].Name,
        };
        clients.push(clients_data);
      }
      this.ClientList = clients;
      for (var i = 0; i < this.ClientList.length; i++) {
        if (this.ClientList[i].ClientId == this.pickupIdData.PickupRequest[0].Clients[0].ClientId) {
          this.selectedclient = this.ClientList[i].ClientId;
        }
      }
      var addressState = this.pickupIdData.PickupRequest[0].Clients[0].State;

      if (this.OrganicPickupType === true) {
        this.pickupService.getMrfClientList(addressState).subscribe(
          (res) => {
            this.Recipient = res;
            if (this.Recipient.length > 0) {
              this.Recipient = this.Recipient.filter((value) => value.ApprovalStatus[0].Title === 'Active');
            }
            const recipients = [];
            if (this.Recipient.length > 0) {
              for (let i = 0; i < this.Recipient.length; i++) {
                this.RecipientData = this.Recipient[i];
                const recipients_data = {
                  RecipientId: this.RecipientData.ClientId,
                  Name: this.RecipientData.Name,
                };
                recipients.push(recipients_data);
              }
              this.RecipientList = recipients;
              for (var i = 0; i < this.RecipientList.length; i++) {
                if (this.RecipientList[i].RecipientId == this.pickupIdData.PickupRequest[0].NonProfitReceiverId) {
                  this.selectedRecipient = this.RecipientList[i].RecipientId;
                }
              }
            } else {
              this.selectedRecipientNone = true;
            }
          },
          (error) => {
            // alert(JSON.stringify(error));
          }
        );
      } else {
        this.pickupService.getRecipientFilterList(addressState).subscribe(
          (res) => {
            this.Recipient = res;
            if (this.Recipient.length > 0) {
              this.Recipient = this.Recipient.filter((value) => value.ApprovalStatus[0].Title === 'Active');
            }
            const recipients = [];
            if (this.Recipient.length > 0) {
              for (let i = 0; i < this.Recipient.length; i++) {
                this.RecipientData = this.Recipient[i];
                const recipients_data = {
                  RecipientId: this.RecipientData.ClientId,
                  Name: this.RecipientData.Name,
                };
                recipients.push(recipients_data);
              }
              this.RecipientList = recipients;
              for (var i = 0; i < this.RecipientList.length; i++) {
                if (this.RecipientList[i].RecipientId == this.pickupIdData.PickupRequest[0].NonProfitReceiverId) {
                  this.selectedRecipient = this.RecipientList[i].RecipientId;
                }
              }
            } else {
              this.selectedRecipientNone = true;
            }
          },
          (error) => {
            // alert(JSON.stringify(error));
          }
        );
      }

      this.pickupService.getStatusList().subscribe(
        (data) => {
          this.StatusData = data;
          const status = [];
          for (let i = 0; i < data['Result'].length; i++) {
            this.StatusData = data['Result'][i];
            if (this.StatusData.Title === 'OnRoute') {
              if (this.OrganicPickupType === true) {
                this.StatusData.Title = 'Acquired';
              } else {
                this.StatusData.Title = 'Food Acquired';
              }
            } else if (this.StatusData.Title === 'PickedUp') {
              this.StatusData.Title = 'Loading Truck';
            }
            const status_data = {
              Id: this.StatusData.ID,
              Title: this.StatusData.Title,
            };
            status.push(status_data);
          }
          // this.StatusList = status;
          if (this.OrganicPickupType === true) {
            status.forEach((element) => {
              if (element['Title'] === 'Pending') {
                this.StatusList[0] = element;
              } else if (element['Title'] === 'UnClaimed') {
                this.StatusList[1] = element;
              } else if (element['Title'] === 'Claimed') {
                this.StatusList[2] = element;
              } else if (element['Title'] === 'Loading Truck') {
                this.StatusList[3] = element;
              } else if (element['Title'] === 'Acquired') {
                this.StatusList[4] = element;
              } else if (element['Title'] === 'Delivered') {
                this.StatusList[5] = element;
              } else if (element['Title'] === 'Cancelled') {
                this.StatusList[6] = element;
              }
            });
          } else {
            status.forEach((element) => {
              if (element['Title'] === 'Pending') {
                this.StatusList[0] = element;
              } else if (element['Title'] === 'UnClaimed') {
                this.StatusList[1] = element;
              } else if (element['Title'] === 'Claimed') {
                this.StatusList[2] = element;
              } else if (element['Title'] === 'Loading Truck') {
                this.StatusList[3] = element;
              } else if (element['Title'] === 'Food Acquired') {
                this.StatusList[4] = element;
              } else if (element['Title'] === 'Delivered') {
                this.StatusList[5] = element;
              } else if (element['Title'] === 'Cancelled') {
                this.StatusList[6] = element;
              }
            });
          }
          if (this.DriverServices === 'Roadie/Postmates' || this.DriverServices === 'Roadie' || this.DriverServices === 'Postmates') {
            if (this.pickupIdData.Status[0].Title === 'PickedUp') {
              this.StatusList = this.StatusList.filter(
                (value) =>
                  value.Title !== 'Food Acquired' &&
                  value.Title !== 'Pending' &&
                  value.Title !== 'UnClaimed' &&
                  value.Title !== 'Claimed' &&
                  value.Title !== 'Delivered'
              );
            } else if (this.pickupIdData.Status[0].Title === 'OnRoute') {
              this.StatusList = this.StatusList.filter(
                (value) =>
                  value.Title !== 'Loading Truck' &&
                  value.Title !== 'Pending' &&
                  value.Title !== 'UnClaimed' &&
                  value.Title !== 'Claimed' &&
                  value.Title !== 'Delivered'
              );
            } else if (this.pickupIdData.Status[0].Title === 'Claimed') {
              this.StatusList = this.StatusList.filter(
                (value) =>
                  value.Title !== 'Food Acquired' &&
                  value.Title !== 'Pending' &&
                  value.Title !== 'Loading Truck' &&
                  value.Title !== 'UnClaimed' &&
                  value.Title !== 'Delivered'
              );
            } else if (this.pickupIdData.Status[0].Title === 'UnClaimed') {
              this.StatusList = this.StatusList.filter(
                (value) =>
                  value.Title !== 'Food Acquired' &&
                  value.Title !== 'Pending' &&
                  value.Title !== 'Loading Truck' &&
                  value.Title !== 'Claimed' &&
                  value.Title !== 'Delivered'
              );
            } else if (this.pickupIdData.Status[0].Title === 'Delivered') {
              this.StatusList = this.StatusList.filter(
                (value) =>
                  value.Title !== 'Food Acquired' &&
                  value.Title !== 'Pending' &&
                  value.Title !== 'Loading Truck' &&
                  value.Title !== 'UnClaimed' &&
                  value.Title !== 'Claimed'
              );
            }
            // if (this.roadiesPos === true) {
            else
              this.StatusList = this.StatusList.filter(
                (value) =>
                  // value.Title !== 'Food Acquired' && value.Title !== 'Loading Truck' && value.Title !== 'Claimed' && value.Title !== 'Delivered'
                  value.Title !== 'Food Acquired' && value.Title !== 'Loading Truck' && value.Title !== 'Claimed'
              );
            // }
          }
          if (
            this.pickupIdData.PickupRequest[0].DriverServices[0].Title === 'Goodr' ||
            this.pickupIdData.PickupRequest[0].DriverServices[0].Title === 'SAAS'
          ) {
            this.StatusList = this.StatusList.filter((value) => value.Title !== 'Pending');
          }
          // if (this.postmate === true) {
          //   this.StatusList = this.StatusList.filter(
          //     value =>
          //       value.Title !== 'Loading Truck' &&
          //       value.Title !== 'Food Acquired' &&
          //       value.Title !== 'Acquired' &&
          //       value.Title !== 'Delivered'
          //   );
          // }
          for (var i = 0; i < this.StatusList.length; i++) {
            if (this.StatusList[i].Id === this.pickupIdData.Status[0].ID) {
              this.selectedstatus = this.StatusList[i].Title;
            }
          }
          if (this.postmate === true) {
            if (this.selectedstatus === 'OnRoute') {
              if (this.OrganicPickupType === true) {
                this.selectedstatus = 'Acquired';
              } else {
                this.selectedstatus = 'Food Acquired';
              }
            } else if (this.selectedstatus === 'PickedUp') {
              this.selectedstatus = 'Loading Truck';
            }
          }
          if (this.selectedstatus === 'Delivered') {
            this.nonprofitsDisabled = true;
          }
        },
        (error) => {
          // alert(JSON.stringify(error));
        }
      );
    });
  }

  checkRecipientPermission(key){
    if (this.pickupIdData && this.pickupIdData.Driver.length > 0) {
    } else {

      if (key == 'editblePickup') {
        this.editblePickup = true;
      }
      if (key == 'cancelPostmate') {
        this.cancelPostmate = true;
      }
      if (key == 'editblePostmate') {
        this.editblePostmate = true;
      }

      if (this.DriverServices == 'Roadie') {
        this.editblePickup = false;
        this.editblePostmate = false;
        this.cancelPostmate = false;
      }
    }
  }


  ngAfterViewInit() {}
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  getselectedstatus(value) {
    this.selectedstatus = value;
    this.statusSet = true;
  }
  // getselectedRecipient(value) {
  //   this.RecipientSet = true;
  // }
  getselectedDriver(value) {
    this.driverSet = true;
  }
  onChangeDatepickupAfter(date) {
    this.selectedDateafter = date;
    this.updateDateTimepickupAfter(this.selectedDateafter, this.PickupAfterStartTime);
  }
  onChangeTimepickupAfter(time) {
    this.PickupAfterStartTime = this.ConvertTime(time);
    this.showPickupAfterStartTime = moment(this.PickupAfterStartTime, ['HH.mm']).format('hh:mm a');
    this.updateDateTimepickupAfter(this.selectedDateafter, this.PickupAfterStartTime);
  }
  updateDateTimepickupAfter(selectedDate, PickupAfterStartTime) {
    if (selectedDate) {
      selectedDate = new Date(selectedDate);
      this.showPickupAfterStartDate = new Date(moment(selectedDate).format('YYYY/MM/DD'));
    } else {
      if (this.pickupIdData.PickupAfter) {
        selectedDate = new Date(this.pickupIdData.PickupAfter);
        this.PickupAfterStartDate = selectedDate;
        this.showPickupAfterStartDate = new Date(moment(selectedDate).format('YYYY/MM/DD'));
      } else {
        selectedDate = new Date();
        this.PickupAfterStartDate = selectedDate;
        this.showPickupAfterStartDate = new Date(moment(selectedDate).format('YYYY/MM/DD'));
      }
    }
    if (PickupAfterStartTime === undefined) {
      this.PickupAfterStartTime = selectedDate.getHours() + ':' + selectedDate.getMinutes() + ':' + '00';
      PickupAfterStartTime = this.PickupStartTime;
    } else {
      PickupAfterStartTime = PickupAfterStartTime;
    }
    this.selectedDateafter = selectedDate;
    this.roadiesPickupAfter =
      moment(selectedDate).format().split('T')[0] + 'T' + moment(selectedDate).format().split('T')[1].split('-')[0].split('+')[0];
    this.showPickupAfterStartTime = moment(this.roadiesPickupAfter.split('T')[1], ['HH.mm']).format('hh:mm a');
  }

  onChangeDatepickupBefore(date) {
    this.selectedDatebefore = date;
    this.updateDateTimepickupBefore(this.selectedDatebefore, this.PickupBeforeStartTime);
  }
  onChangeTimepickupBefore(time) {
    this.PickupBeforeStartTime = this.ConvertTime(time);
    this.showPickupBeforeStartTime = moment(this.PickupBeforeStartTime, ['HH.mm']).format('hh:mm a');
    this.updateDateTimepickupBefore(this.selectedDatebefore, this.PickupBeforeStartTime);
  }
  updateDateTimepickupBefore(selectedDate, PickupBeforeStartTime) {
    if (selectedDate) {
      selectedDate = new Date(selectedDate);
      this.showPickupBeforeStartDate = new Date(moment(selectedDate).format('YYYY/MM/DD'));
    } else {
      if (this.pickupIdData.PickupBefore) {
        selectedDate = new Date(this.pickupIdData.PickupBefore);
        this.PickupBeforeStartDate = selectedDate;
        this.showPickupBeforeStartDate = new Date(moment(selectedDate).format('YYYY/MM/DD'));
      } else {
        selectedDate = new Date();
        this.PickupBeforeStartDate = selectedDate;
        this.showPickupBeforeStartDate = new Date(moment(selectedDate).format('YYYY/MM/DD'));
      }
    }
    if (PickupBeforeStartTime === undefined) {
      this.PickupBeforeStartTime = selectedDate.getHours() + ':' + selectedDate.getMinutes() + ':' + '00';
      PickupBeforeStartTime = this.PickupStartTime;
    } else {
      PickupBeforeStartTime = PickupBeforeStartTime;
    }
    this.selectedDatebefore = selectedDate;
    this.roadiesPickupBefore =
      moment(selectedDate).format().split('T')[0] + 'T' + moment(selectedDate).format().split('T')[1].split('-')[0].split('+')[0];
    this.showPickupBeforeStartTime = moment(this.roadiesPickupBefore.split('T')[1], ['HH.mm']).format('hh:mm a');
  }

  onChangeDate(date) {
    this.selectedDate = date;
    this.CreatedOnStartTime = this.ConvertTime(date);
    this.showCreatedStartTime = moment(this.CreatedOnStartTime, ['HH.mm']).format('hh:mm a');
    this.updateDateTime(this.selectedDate, this.CreatedOnStartTime);
  }
  onChangeTime(time) {
    this.CreatedOnStartTime = this.ConvertTime(time);
    this.showCreatedStartTime = moment(this.CreatedOnStartTime, ['HH.mm']).format('hh:mm a');
    this.updateDateTime(this.selectedDate, this.CreatedOnStartTime);
  }
  updateDateTime(selectedDate, CreatedOnStartTime) {
    if (selectedDate) {
      selectedDate = new Date(selectedDate);
      this.showCreatedOnStartDate = new Date(selectedDate);
    } else {
      if (this.pickupIdData.CreatedOn) {
        selectedDate = new Date(this.pickupIdData.CreatedOn);
        this.CreatedOnStartDate = selectedDate;
        this.showCreatedOnStartDate = new Date(selectedDate);
      } else {
        selectedDate = new Date();
        this.CreatedOnStartDate = selectedDate;
        this.showCreatedOnStartDate = new Date(selectedDate);
      }
    }
    if (CreatedOnStartTime === undefined) {
      this.CreatedOnStartTime = selectedDate.getHours() + ':' + selectedDate.getMinutes() + ':' + '00';
      CreatedOnStartTime = this.PickupStartTime;
    } else {
      CreatedOnStartTime = CreatedOnStartTime;
    }
    this.selectedDate = selectedDate;
    this.selectedCreatedOnTime =
      moment(selectedDate).format().split('T')[0] + 'T' + moment(selectedDate).format().split('T')[1].split('-')[0].split('+')[0];
    this.showCreatedStartTime = moment(this.selectedCreatedOnTime.split('T')[1], ['HH.mm']).format('hh:mm a');
  }
  onChangeDeliveryDate(date) {
    this.selectedDeliveryDate = date;
    this.DeliveryStartTime = this.ConvertTime(date);
    this.showDeliveryStartTime = moment(this.DeliveryStartTime, ['HH.mm']).format('hh:mm a');
    this.updateDeliveryDateTime(this.selectedDeliveryDate, this.DeliveryStartTime);
  }
  updateDeliveryDateTime(selectedDeliveryDate, DeliveryStartTime) {
    if (selectedDeliveryDate) {
      selectedDeliveryDate = new Date(selectedDeliveryDate);
      this.showDeliveryStartDate = new Date(selectedDeliveryDate);
    } else {
      if (this.pickupIdData.DeliveryDate) {
        selectedDeliveryDate = new Date(this.pickupIdData.DeliveryDate);
        this.DeliveryStartDate = selectedDeliveryDate;
        this.showDeliveryStartDate = new Date(selectedDeliveryDate);
      } else {
        selectedDeliveryDate = new Date();
        this.DeliveryStartDate = selectedDeliveryDate;
        this.showDeliveryStartDate = new Date(selectedDeliveryDate);
      }
    }
    if (DeliveryStartTime === undefined) {
      this.DeliveryStartTime = selectedDeliveryDate.getHours() + ':' + selectedDeliveryDate.getMinutes() + ':' + '00';
      DeliveryStartTime = this.DeliveryStartTime;
    } else {
      DeliveryStartTime = DeliveryStartTime;
    }
    this.selectedDeliveryDate = selectedDeliveryDate;
    this.selectedDeliveryTime =
      moment(selectedDeliveryDate).format().split('T')[0] + 'T' + moment(selectedDeliveryDate).format().split('T')[1].split('-')[0].split('+')[0];
    this.showDeliveryStartTime = moment(this.selectedDeliveryTime.split('T')[1], ['HH.mm']).format('hh:mm a');
  }

  onChangePickupDate(date) {
    this.selectedPickupDate = date;
    this.PickupStartTime = this.ConvertTime(date);
    this.showPickupStartTime = moment(this.PickupStartTime, ['HH.mm']).format('hh:mm a');
    this.updatePickupDateTime(this.selectedPickupDate, this.PickupStartTime);
  }
  onChangePickupTime(time) {
    this.PickupStartTime = this.ConvertTime(time);
    this.showPickupStartTime = moment(this.PickupStartTime, ['HH.mm']).format('hh:mm a');
    this.updatePickupDateTime(this.selectedPickupDate, this.PickupStartTime);
  }
  updatePickupDateTime(selectedPickupDate, PickupStartTime) {
    if (selectedPickupDate) {
      selectedPickupDate = new Date(selectedPickupDate);
      this.showPickupDateStartDate = new Date(selectedPickupDate);
    } else {
      if (this.pickupIdData.PickupDate) {
        selectedPickupDate = new Date(this.pickupIdData.PickupDate);
        this.PickupDateStartDate = selectedPickupDate;
        this.showPickupDateStartDate = new Date(selectedPickupDate);
      } else {
        selectedPickupDate = new Date();
        this.PickupDateStartDate = selectedPickupDate;
        this.showPickupDateStartDate = new Date(selectedPickupDate);
      }
    }
    if (PickupStartTime === undefined) {
      this.PickupStartTime = selectedPickupDate.getHours() + ':' + selectedPickupDate.getMinutes() + ':' + '00';
      PickupStartTime = this.PickupStartTime;
    } else {
      PickupStartTime = PickupStartTime;
    }

    this.selectedPickupDate = selectedPickupDate;
    this.selectedPickupTime =
      moment(selectedPickupDate).format().split('T')[0] + 'T' + moment(selectedPickupDate).format().split('T')[1].split('-')[0].split('+')[0];
    this.showPickupStartTime = moment(this.selectedPickupTime.split('T')[1], ['HH.mm']).format('hh:mm a');
  }

  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }
  driverServicedata(spinnerRef) {
    this.customerService.getPickupTypeList().subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.driverService = data.Result;
      if (this.pickupIdData.PickupRequest[0].DriverServices.length > 0) {
        this.driverServicetitle = this.pickupIdData.PickupRequest[0].DriverServices[0].Title;
      }
      // if (this.SAASFeature === false) {
      this.driverService = this.driverService.filter((value) => value.Title === 'Roadie' || value.Title === 'Postmates');
      // }
    });
  }
  UpdateNonProfitReceiver() {
    if (this.pickupIdData.ShippingID && this.pickupIdData.Status[0].Title !== 'Cancelled' && this.pickupIdData.Status[0].Title === 'UnClaimed') {
      this.UpdatePickUpRoadiesStatus();
    } else {
      for (const idx in this.RecipientList) {
        if (this.RecipientList[idx].RecipientId === parseInt(this.selectedRecipient)) {
          this.selectedDropOfLocationObject = this.RecipientList[idx];
        }
      }
      if (this.selectedCreatedOnTime) {
        this.selectedCreatedOnTimenp = this.postDateService.transform(
          this.selectedCreatedOnTime,
          this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
        );
      } else {
        this.selectedCreatedOnTimenp = null;
      }
      let driverId;
      if (this.pickupIdData.PickupRequest[0].DriverServices.length > 0) {
        driverId = this.pickupIdData.PickupRequest[0].DriverServices[0].ID;
      } else {
        driverId = null;
      }
      let DriverServicedata;
      if (this.driverServicetitle) {
        for (const status of this.driverService) {
          if (this.driverServicetitle === status.Title) {
            DriverServicedata = status.ID;
          }
        }
      } else {
        DriverServicedata = null;
      }
      let payload = {
        ClientId: this.pickupIdData.PickupRequest[0].Clients[0].ClientId,
        CreatedBy: this.pickupIdData.CreatedBy,
        CreatedOn: this.selectedCreatedOnTimenp,
        Date: this.pickupIdData.PickupRequest[0].Date,
        IsProcessed: this.pickupIdData.PickupRequest[0].IsProcessed,
        ModifiedBy: this.pickupIdData.PickupRequest[0].ModifiedBy,
        ModifiedOn: this.pickupIdData.PickupRequest[0].ModifiedOn,
        NonProfitReceiverId: parseInt(this.selectedRecipient),
        Recipient: this.selectedDropOfLocationObject.Name,
        RequestId: this.pickupIdData.PickupRequest[0].RequestId,
        SchedueleDate: this.pickupIdData.PickupRequest[0].SchedueleDate,
        WP_Request_Id: this.pickupIdData.PickupRequest[0].WP_Request_Id,
        PickupTypeId: this.pickupIdData.PickupRequest[0].PickupType[0].ID,
        DriverServiceId: DriverServicedata,
        OrganicRecyclingPickupTypeId: this.pickupIdData.PickupRequest[0].OrganicRecyclingPickupTypeId,
      };

      this.pickupService.updatePickupRequest(payload).subscribe((pickupRequest) => {
        this.RecipientSet = true;
        this.relaodinfo.reloadmenuData = true;
        this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
      });
    }
  }
  closeModal() {
    this.dialogRef.close();
  }
  UpdatePickUpStatus() {
    var spinnerRef = this.spinnerService.start();
    if (this.roadiesPickupAfter) {
      this.roadiesPickupAfter = this.postDateService.transform(this.roadiesPickupAfter, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
    } else {
      this.roadiesPickupAfter = null;
    }
    if (this.roadiesPickupBefore) {
      this.roadiesPickupBefore = this.postDateService.transform(this.roadiesPickupBefore, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
    } else {
      this.roadiesPickupBefore = null;
    }
    if (this.selectedCreatedOnTime) {
      this.selectedCreatedOnTime = this.postDateService.transform(this.selectedCreatedOnTime, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
    } else {
      this.selectedCreatedOnTime = null;
    }
    if (this.selectedDeliveryTime) {
      this.selectedDeliveryTime = this.postDateService.transform(this.selectedDeliveryTime, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
    } else {
      this.selectedDeliveryTime = null;
    }
    if (this.selectedPickupTime) {
      this.selectedPickupTime = this.postDateService.transform(this.selectedPickupTime, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
    } else {
      this.selectedPickupTime = null;
    }
    for (let deliveryIdx in this.StatusList) {
      if (this.StatusList[deliveryIdx].Title === this.selectedstatus) {
        this.pickupIdData.Status[0] = Object.assign({}, this.StatusList[deliveryIdx]);
        this.requestStatus = this.pickupIdData.Status[0].Title;
        let payload = Object.assign({}, this.pickupIdData);
        if (
          this.pickupIdData.PickupRequest[0].DriverServices[0].Title === 'Goodr' ||
          this.pickupIdData.PickupRequest[0].DriverServices[0].Title === 'SAAS'
        ) {
          this.roadiesPickupAfter = null;
          this.roadiesPickupBefore = null;
        } else {
          this.roadiesPickupAfter = this.roadiesPickupAfter;
          this.roadiesPickupBefore = this.roadiesPickupBefore;
        }
        if (payload.DispatchNotification) {
          if (payload.DispatchNotification.length > 0) {
            payload.DispatchNotificationId = payload.DispatchNotification[0].DispatchNotificationId;
          }
        }
        (payload.DeliveryId = this.DeliveryId),
          (payload.PickupRequest_Product = []),
          (payload.PickupRequest = []),
          (payload.StatusId = this.pickupIdData.Status[0].Id);
        payload.DriverId = this.selectedDriver;
        payload.Status = [];
        payload.PickupAfter = this.roadiesPickupAfter;
        payload.PickupBefore = this.roadiesPickupBefore;
        payload.CreatedOn = this.selectedCreatedOnTime;
        payload.DeliveryDate = this.selectedDeliveryTime;
        payload.OrganicRecyclingPickupTypeId = this.pickupIdData.PickupRequest[0].OrganicRecyclingPickupTypeId;
        payload.PickupDate = this.selectedPickupTime;
        payload.DonationReceiptURL = this.pickupIdData.DonationReceiptURL;
        payload.DonationReceiver = this.pickupIdData.DonationReceiver;
        payload.IsNotificationToBeSent = false;
        // payload.TotalWeight = this.pickupIdData.TotalWeight;

        if (payload.DriverId) {
          for (var i = 0; i < this.drivers.length; i++) {
            if (this.drivers[i].Id === payload.DriverId) {
              this.pickupIdData.Driver = [];
              this.pickupIdData.Driver.push(this.drivers[i]);
            }
          }
        }

        this.pickupService.updateDelivery(payload).subscribe(
          (res) => {
            this.spinnerService.stop(spinnerRef);
            this.dialogRef.close(res);
          },
          (error) => {
            this.state.isSpinnerVisible = false;
          }
        );
      }
    }
  }
  UpdatePickUp() {

    if (this.DriverServices === 'Roadie') {
      this.UpdatePickUpStatus();
    } else if (this.statusSet !== true) {
      this.UpdatePickUpStatus();
    } else if (this.selectedRecipient) {
      if (this.selectedDriver) {
        this.UpdatePickUpStatus();
      } else {
        alert('Please select driver');
        // this.selectedstatus = 'UnClaimed';
      }
    } else {
      alert('Please select the Nonprofit');
    }
  }

  // roadies status
  UpdatePickUpRoadiesStatus() {
    this.RecipientSet = true;
    const pickups: PickUps[] = [];
    this.totalItemValue = 0;
    this.totalWeight = 0;
    this.totalQuantity = 0;
    if (this.roadiesPickupBefore) {
      this.roadiesPickupBefore = this.postDateService.transform(this.roadiesPickupBefore, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
    }
    if (this.roadiesPickupAfter) {
      this.roadiesPickupAfter = this.postDateService.transform(this.roadiesPickupAfter, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
    }
    for (let i = 0; i <= this.pickupIdData.PickupRequest[0]['PickupRequest_Product'].length - 1; i++) {
      const pickUnit: PickUps = this.pickupIdData.PickupRequest[0]['PickupRequest_Product'][i];

      let pickupDetails = this.pickupIdData.PickupRequest[0]['PickupRequest_Product'][i];
      //total value calculation
      const itemValue = pickupDetails.Quantity * pickupDetails.Cost;
      this.totalItemValue = this.totalItemValue + itemValue;
      this.totalItemValueWithUnit = '$ ' + this.totalItemValue.toFixed(2);
      //weight-calculation
      const itemWeightValue = pickupDetails.Quantity * pickupDetails.Weight;
      this.totalWeight = this.totalWeight + itemWeightValue;
      this.totalWeightwithUnit = this.totalWeight + '';

      //weight-calculation
      this.totalQuantity = this.totalQuantity + pickupDetails.Quantity;

      pickups.push(pickUnit);
    }
    if (this.pickupIdData.ShippingID) {
      this.ShippingID = this.pickupIdData.ShippingID;
    } else {
      this.ShippingID = 0;
    }
    if (this.ShippingID) {
      this.nonprofitUpdate = true;
    }
    let payloaddata = {
      update: this.nonprofitUpdate,
      RecipientId: this.selectedRecipient,
      Quantity: this.totalQuantity,
      Value: this.totalItemValue,
      Weight: this.totalWeightwithUnit,
      Description: 'Pickup Request From Client ' + this.pickupIdData.PickupRequest[0].Clients[0].Name,
      ClientId: this.pickupIdData.PickupRequest[0].ClientId,
      Request_Id: this.pickupIdData.RequestId,
      DeliveryBetweenEnd: this.roadiesPickupBefore,
      DeliveryBetweenStart: this.roadiesPickupAfter,
      PickupAfter: this.roadiesPickupAfter,
      ShipmentId: this.ShippingID,
      Status: null,
    };
    this.pickupService.updateRoadiesDelivery(payloaddata).subscribe(
      (data) => {
        if ('error' in data) {
          this.error = data['message'];
          setTimeout(() => {
            this.selectedstatus = 'Pending';
          });
          if (this.error === 'unprocessable request parameters') {
            alert('Unprocessable request parameters');
          } else {
            alert(this.error);
          }
        } else {
          this.dialogRef.close('res');
        }
      },
      (error) => {
        this.state.isSpinnerVisible = false;
      }
    );
  }
  goTodriver() {
    if (this.driverServicetitle === 'Roadie') {
      this.roadiesPos = true;
    } else {
      this.roadiesPos = false;
    }
  }
  goTopostmateupdate() {
    let cancelData;
    let claimedData;
    if (this.selectedstatus === 'Cancelled') {
      cancelData = true;
    } else if (this.selectedstatus === 'UnClaimed') {
      claimedData = true;
    }
    if (this.selectedstatus === 'UnClaimed' || this.selectedstatus === 'Cancelled') {
      if (this.selectedRecipient === null || this.selectedRecipient === undefined || !this.selectedRecipient) {
        let strMsg = 'Select Nonprofit';
        alert(strMsg);
        setTimeout(() => {
          this.selectedstatus = 'Pending';
        });
      } else {
        if (this.RoadiePostmatesFeature === true) {
          this.postroadiesapi(cancelData, claimedData);
        } else {
          if (this.drivertitile === 'Postmates') {
            this.postmateUpadtea(cancelData, claimedData);
          } else if (claimedData === true && this.pickupIdData.Status[0].Title === 'Pending') {
            this.UpdatePickUpRoadiesStatus();
          } else if (cancelData === true) {
            this.goToroadiesCancelled();
          } else {
            alert('Action already Submitted');
            setTimeout(() => {
              this.selectedstatus = 'Pending';
            });
          }
        }
      }
    }
  }

  postroadiesapi(cancelData, claimedData) {
    if (this.driverServicetitle !== undefined && this.driverServicetitle !== null && this.driverServicetitle) {
      if (this.pickupIdData.PickupRequest[0].DriverServices.length > 0) {
        if (this.driverServicetitle === 'Postmates') {
          this.postmateUpadtea(cancelData, claimedData);
        } else {
          if (claimedData === true && this.pickupIdData.Status[0].Title === 'Pending') {
            this.UpdatePickUpRoadiesStatus();
          } else if (cancelData === true) {
            this.goToroadiesCancelled();
          } else {
            alert('Action already Submitted');
            setTimeout(() => {
              this.selectedstatus = 'Pending';
            });
          }
        }
      } else {
        let DriverServicedata;
        for (const status of this.driverService) {
          if (this.driverServicetitle === status.Title) {
            DriverServicedata = status.ID;
          }
        }
        for (const idx in this.RecipientList) {
          if (this.RecipientList[idx].RecipientId === parseInt(this.selectedRecipient)) {
            this.selectedDropOfLocationObject = this.RecipientList[idx];
          }
        }
        if (this.selectedCreatedOnTime) {
          this.selectedCreatedOnTime = this.postDateService.transform(
            this.selectedCreatedOnTime,
            this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
          );
        } else {
          this.selectedCreatedOnTime = null;
        }
        let driverId;
        if (this.pickupIdData.PickupRequest[0].DriverServices.length > 0) {
          driverId = this.pickupIdData.PickupRequest[0].DriverServices[0].ID;
        } else {
          driverId = null;
        }
        let payload = {
          ClientId: this.pickupIdData.PickupRequest[0].Clients[0].ClientId,
          CreatedBy: this.pickupIdData.CreatedBy,
          CreatedOn: this.selectedCreatedOnTime,
          Date: this.pickupIdData.PickupRequest[0].Date,
          IsProcessed: this.pickupIdData.PickupRequest[0].IsProcessed,
          ModifiedBy: this.pickupIdData.PickupRequest[0].ModifiedBy,
          ModifiedOn: this.pickupIdData.PickupRequest[0].ModifiedOn,
          NonProfitReceiverId: parseInt(this.selectedRecipient),
          Recipient: this.selectedDropOfLocationObject.Name,
          RequestId: this.pickupIdData.PickupRequest[0].RequestId,
          SchedueleDate: this.pickupIdData.PickupRequest[0].SchedueleDate,
          WP_Request_Id: this.pickupIdData.PickupRequest[0].WP_Request_Id,
          PickupTypeId: this.pickupIdData.PickupRequest[0].PickupType[0].ID,
          DriverServiceId: DriverServicedata,
          OrganicRecyclingPickupTypeId: this.pickupIdData.PickupRequest[0].OrganicRecyclingPickupTypeId,
        };
        
        this.pickupService.updatePickupRequest(payload).subscribe((pickupRequest) => {
          if (this.driverServicetitle === 'Postmates') {
            this.postmateUpadtea(cancelData, claimedData);
          } else {
            if (claimedData === true && this.pickupIdData.Status[0].Title === 'Pending') {
              this.UpdatePickUpRoadiesStatus();
            } else {
              this.goToroadiesCancelled();
            }
          }
        });
      }
    } else {
      this.selectDriverservice = true;
      setTimeout(() => {
        this.selectDriverservice = false;
      }, 3000);
    }
  }
  // roadies canceld
  goToroadiesCancelled() {
    if (this.pickupIdData.ShippingID) {
      this.ShippingID = this.pickupIdData.ShippingID;
    } else {
      this.ShippingID = 0;
    }
    for (let deliveryIdx in this.StatusList) {
      if (this.StatusList[deliveryIdx].Title === this.selectedstatus) {
        this.pickupIdData.Status[0] = Object.assign({}, this.StatusList[deliveryIdx]);
      }
    }

    let deliveryTitle = 'Cancelled';
    if (deliveryTitle === 'Cancelled') {

      if (this.ShippingID === 0) {
        this.ShippingStatusID = this.pickupIdData.Status[0].ID;
      } else {
        this.ShippingStatusID = 'Cancelled';
      }

      let payloaddata = {
        RecipientId: this.pickupIdData.PickupRequest[0].NonProfitReceiverId,
        Quantity: this.totalQuantity,
        Value: this.totalItemValue,
        Weight: this.totalWeightwithUnit,
        Description: 'Pickup Request From Client ' + this.pickupIdData.PickupRequest[0].Clients[0].Name,
        ClientId: this.pickupIdData.PickupRequest[0].ClientId,
        Request_Id: this.pickupIdData.RequestId,
        DeliveryBetweenEnd: this.pickupIdData.PickupBefore,
        DeliveryBetweenStart: this.pickupIdData.PickupAfter,
        ShipmentId: this.ShippingID,
        Status: this.ShippingStatusID,
        DonationReceiptURL: this.pickupIdData.DonationReceiptURL,
        DonationReceiver: this.pickupIdData.DonationReceiver,
      };
      if (this.ShippingID === 0) {
        if (this.roadiesPickupAfter) {
          this.roadiesPickupAfter = this.postDateService.transform(this.roadiesPickupAfter, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
        } else {
          this.roadiesPickupAfter = null;
        }
        if (this.roadiesPickupBefore) {
          this.roadiesPickupBefore = this.postDateService.transform(this.roadiesPickupBefore, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
        } else {
          this.roadiesPickupBefore = null;
        }
        if (this.selectedCreatedOnTime) {
          this.selectedCreatedOnTime = this.postDateService.transform(
            this.selectedCreatedOnTime,
            this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
          );
        } else {
          this.selectedCreatedOnTime = null;
        }
        if (this.selectedDeliveryTime) {
          this.selectedDeliveryTime = this.postDateService.transform(
            this.selectedDeliveryTime,
            this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
          );
        } else {
          this.selectedDeliveryTime = null;
        }
        if (this.selectedPickupTime) {
          this.selectedPickupTime = this.postDateService.transform(this.selectedPickupTime, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
        } else {
          this.selectedPickupTime = null;
        }

        let payload = Object.assign({}, this.pickupIdData);
        payload.StatusId = this.pickupIdData.Status[0].Id;
        payload.ShippingID = 0;
        payload.PickupAfter = this.roadiesPickupAfter;
        payload.PickupBefore = this.roadiesPickupBefore;
        payload.CreatedOn = this.selectedCreatedOnTime;
        payload.DeliveryDate = this.selectedDeliveryTime;
        payload.PickupDate = this.selectedPickupTime;
        this.pickupService.updateDelivery(payload).subscribe(
          (data) => {
            this.dialogRef.close('res');
            let finalData;
            finalData = 'Your Request successfully Cancelled!';
            const dialogRef = this.dialog.open(SuccesPopupComponent, {
              width: '580px',
              // height: '480px',
              data: { ClientData: finalData },
              panelClass: 'custom-editSub-container',
            });
            dialogRef.afterClosed().subscribe((result) => {});
          },
          (error) => {
            this.state.isSpinnerVisible = false;
          }
        );
      } else if (this.ShippingID) {
        this.pickupService.updateRoadiesDelivery(payloaddata).subscribe(
          (data) => {
            if (data.message === 'The remote server returned an error: (400) Bad Request.') {
              alert('Status cannot be changed');
            } else {
              this.dialogRef.close('res');
            }
          },
          (error) => {
            this.state.isSpinnerVisible = false;
          }
        );
      } else {
        this.pickupService.PostmatesPickupDelivery(payloaddata).subscribe(
          (data) => {
            if (data.message === 'The remote server returned an error: (400) Bad Request.') {
              alert('Status cannot be changed');
            } else if (data.message) {
              alert(data.message);
            } else {
    
              let finalData;
              finalData = 'Your Request successfully Cancelled!';
              const dialogRef = this.dialog.open(SuccesPopupComponent, {
                width: '480px',
                data: { ClientData: finalData },
                panelClass: 'custom-editSub-container',
              });
              dialogRef.afterClosed().subscribe((result) => {});

              this.selectedstatus = this.pickup[0].Status[0].Title;
              this.requestStatus = this.pickup[0].Status[0].Title;
  
            }
          },
          (error) => {
            this.state.isSpinnerVisible = false;
          }
        );
      }
    } else if (deliveryTitle !== 'UnClaimed') {
      alert('Action Not Allowed');
    }
    //   }
    // }
  }
  postmateUpadtea(cancelData, claimedData) {
    if (cancelData === true) {
      if (this.pickupIdData.TotalWeight) {
        this.goTopostmateRequest();
      } else {
        this.editmenusvalid = true;
        setTimeout(() => {
          this.editmenusvalid = false;
        }, 3000);
        setTimeout(() => {
          this.selectedstatus = 'Pending';
        });
      }
    }

    else if (claimedData === true) {
      if (this.pickupIdData.TotalWeight) {
        if (this.selectedRecipient === null || this.selectedRecipient === undefined || !this.selectedRecipient) {
          let strMsg = 'Please select Nonprofit';
          alert(strMsg);
          setTimeout(() => {
            this.selectedstatus = 'Pending';
          });
          for (const status of this.StatusList) {
            if (status.Title == 'Pending') {
              this.selectedstatus = Object.assign(status.Title);
              if (this.selectedstatus === 'OnRoute') {
                if (this.OrganicPickupType === true) {
                  this.selectedstatus = 'Acquired';
                } else {
                  this.selectedstatus = 'Food Acquired';
                }
              } else if (this.selectedstatus === 'PickedUp') {
                this.selectedstatus = 'Loading Truck';
              }
            }
          }
        } else {
          if (
            this.pickupIdData.Status[0].Title === 'Claimed' ||
            this.pickupIdData.Status[0].Title === 'PickedUp' ||
            this.pickupIdData.Status[0].Title === 'Delivered' ||
            this.pickupIdData.Status[0].Title === 'OnRoute' ||
            this.pickupIdData.Status[0].Title === 'Delivered'
          ) {
            this.selectedstatus = this.pickupIdData.Status[0].Title;
            if (this.selectedstatus === 'OnRoute') {
              if (this.OrganicPickupType === true) {
                this.selectedstatus = 'Acquired';
              } else {
                this.selectedstatus = 'Food Acquired';
              }
            } else if (this.selectedstatus === 'PickedUp') {
              this.selectedstatus = 'Loading Truck';
            }
          } else {
            this.UpdatePickUppostmateStatus();
          }
        }
      } else {
        this.editmenusvalid = true;
        setTimeout(() => {
          this.editmenusvalid = false;
        }, 3000);
        setTimeout(() => {
          this.selectedstatus = 'Pending';
        });
      }
    }
  }
  // postmate cancel
  goTopostmateRequest() {
    if (this.postmate === true) {
      if (this.selectedstatus !== 'Pending') {
        this.selectedstatus = 'Cancelled';
      }
    }
    if (this.pickupIdData.PostmateId) {
      this.PostmateId = this.pickupIdData.PostmateId;
    } else {
      this.PostmateId = 0;
    }
    if (this.selectedstatus === 'Cancelled') {
      let payloaddata = {
        Request_Id: this.pickupIdData.RequestId,
        PostmateId: this.pickupIdData.PostmateId,
        Cancel: true,
      };

      if (this.PostmateId === 0 || this.pickupIdData.PostmateId === null || this.pickupIdData.PostmateId === '0') {

        if (this.roadiesPickupAfter) {
          this.roadiesPickupAfter = this.postDateService.transform(this.roadiesPickupAfter, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
        } else {
          this.roadiesPickupAfter = null;
        }
        if (this.roadiesPickupBefore) {
          this.roadiesPickupBefore = this.postDateService.transform(this.roadiesPickupBefore, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
        } else {
          this.roadiesPickupBefore = null;
        }
        if (this.selectedCreatedOnTime) {
          this.selectedCreatedOnTime = this.postDateService.transform(
            this.selectedCreatedOnTime,
            this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
          );
        } else {
          this.selectedCreatedOnTime = null;
        }
        if (this.selectedDeliveryTime) {
          this.selectedDeliveryTime = this.postDateService.transform(
            this.selectedDeliveryTime,
            this.pickupIdData.PickupRequest[0].Clients[0].TimeZone
          );
        } else {
          this.selectedDeliveryTime = null;
        }
        if (this.selectedPickupTime) {
          this.selectedPickupTime = this.postDateService.transform(this.selectedPickupTime, this.pickupIdData.PickupRequest[0].Clients[0].TimeZone);
        } else {
          this.selectedPickupTime = null;
        }
        let statusid;
        for (var i = 0; i < this.StatusList.length; i++) {
          if (this.StatusList[i].Title === this.selectedstatus) {
            statusid = this.StatusList[i].Id;
          }
        }
        let payload = Object.assign({}, this.pickupIdData);
        payload.StatusId = statusid;
        payload.PostmateId = 0;
        payload.PickupAfter = this.roadiesPickupAfter;
        payload.PickupBefore = this.roadiesPickupBefore;
        payload.CreatedOn = this.selectedCreatedOnTime;
        payload.DeliveryDate = this.selectedDeliveryTime;
        payload.PickupDate = this.selectedPickupTime;

        this.pickupService.updateDelivery(payload).subscribe(
          (data) => {
            if ('error' in data) {
              this.error = data['message'];
              alert(this.error);
              this.selectedstatus = 'Pending';
            } else {
              this.dialogRef.close('res');
              let finalData;
              finalData = 'Your Request successfully Cancelled!';
              const dialogRef = this.dialog.open(SuccesPopupComponent, {
                width: '480px',
                data: { ClientData: finalData },
                panelClass: 'custom-editSub-container',
              });
              dialogRef.afterClosed().subscribe((result) => {});
            }
          },
          (error) => {
            this.state.isSpinnerVisible = false;
          }
        );
      } else {
        this.pickupService.PostmatesPickupDelivery(payloaddata).subscribe(
          (data) => {
            if ('error' in data) {
              this.error = data['message'];
              if (this.error === 'The remote server returned an error: (400) Bad Request.') {
                alert('Status cannot be changed');
              } else {
                this.postmaterrors = data['message'];
              }
            } else {
              this.dialogRef.close('res');
              let finalData;
              finalData = 'Your Request successfully Cancelled!';
              const dialogRef = this.dialog.open(SuccesPopupComponent, {
                width: '480px',
                data: { ClientData: finalData },
                panelClass: 'custom-editSub-container',
              });
              dialogRef.afterClosed().subscribe((result) => {});
            }
          },
          (error) => {
            this.state.isSpinnerVisible = false;
          }
        );
      }
    }
  }
  // postmate status
  UpdatePickUppostmateStatus() {
    let payloaddata = {
      Request_Id: this.pickupIdData.RequestId,
    };
    this.pickupService.PostmatesPickupDelivery(payloaddata).subscribe((data) => {
      if ('error' in data) {
        setTimeout(() => {
          this.selectedstatus = 'Pending';
        });
        this.error = data['message'];
        if (data['Postmate Errors']['pickup_address']) {
          this.postmaterrors = 'Pickup location address not valid';
        } else if (data['Postmate Errors']['dropoff_address']) {
          this.postmaterrors = 'Recipient location address not valid';
        } else if (data['Postmate Errors']['dropoff_phone_number'] && data['Postmate Errors']['pickup_phone_number']) {
          this.postmaterrors = 'Pickup and Recipient Phone numbers are not valid';
        } else if (data['Postmate Errors']['dropoff_phone_number']) {
          this.postmaterrors = 'Recipient location phone number is not valid';
        } else if (data['Postmate Errors']['pickup_phone_number']) {
          this.postmaterrors = 'Pickup location phone number is not valid ';
        } else {
          alert(data['message']);
        }
        this.selectedstatus = 'UnClaimed';
      } else {
        this.dialogRef.close('res');
        let finalData;
        finalData = 'Successfully Claimed';
        const dialogRef = this.dialog.open(SuccesPopupComponent, {
          width: '480px',
          data: { ClientData: finalData },
          panelClass: 'custom-editSub-container',
        });
        dialogRef.afterClosed().subscribe((result) => {});
      }
    });
  }
  editPickupMenu() {
    this.deleteRequest = [];
    this.dataSource = [];
    this.dataSourceCombinedData = [];
    var spinnerRef = this.spinnerService.start();
    this.showPickupDetails = false;
    this.showEditPickupItems = true;
    this.pickupService.getPickUpDetail(this.data[0].pickupdata.DeliveryId).subscribe((data) => {
      let pickups;
      pickups = data.Result;
      this.pickupIdData = pickups[0];

      this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
        this.editRelaodinfo = JSON.parse(res);
      });
      this.createForm = this.formBuilder.group({
        quantity: ['', Validators.compose([Validators.required])],
        Weight: ['', Validators.compose([Validators.required])],
        Cost: ['', Validators.compose([Validators.required])],
      });
      this.dataedit = true;

      this.reqestType = JSON.parse(localStorage.getItem('EditMenuType'));
      if (this.reqestType === 'CombinedEditMenu') {
        this.CombinedRequest = true;
      } else if (this.reqestType === 'SeparatedEditMenu') {
        this.Separated = true;
      } else if (this.reqestType === 'foodTypeEditMenu') {
        this.foodRequest = true;
      }
      this.spinnerService.stop(spinnerRef);
      this.pickupRequest();
    });

    this.sendRequest = [];
  }

  //edit pickup functions

  separetaedRequest() {
    let spinnerRef = this.spinnerService.start();

    this.pickupService.SeparatedCompost().subscribe((data) => {
      this.separatedCompost = orCustomMaterialList(data.Result);
      const orList = [];
      let isDup = false;
      
      this.separatedCompost.forEach((p) => {
        const objVal = {
          Name: p.Title,
          Price: p.Price ? p.Price : '',
          Weight: p.Weight ? p.Weight : '',
          Quantity: p.Quantity ? p.Quantity : '',
          isAdded: false,
          beingAdded: false,
          editMode: false,
          icon: '../../../assets/images/addicon.png',
          ID: p.ID,
          requiredError: false,
          previousValues: {
            Quantity: p.Quantity ? p.Quantity : '',
            Weight: p.Weight ? p.Weight : '',
            Price: p.Price ? p.Price : '',
          },
        };

        isDup = false;
        for (let i in this.dataSourceCombinedData) {
          if (this.dataSourceCombinedData[i].SeparatedCompostId == objVal['ID']) {
           isDup = true;
          }
        }

        if (!isDup) {
          orList.push(objVal);
        }
      });
      this.separatedCompost = orList;
      this.dataSourceOR = new MatTableDataSource(this.separatedCompost);
      this.spinnerService.stop(spinnerRef);
    });
  }

  editTransactionRowOR(value, field, index) {
    const { editMode, Name } = this.dataSourceOR.data[index];
    if (editMode) {
      // edit row in Products To add as well
      const cartIndex = this.newAddedItems.findIndex((p) => p.Name === Name);
      if (cartIndex > -1) {
        this.newAddedItems[cartIndex][field] = value;
      }
    }
    this.dataSourceOR.data[index][field] = value;
  }

  doneEditingOR(transaction, i) {
    if (transaction.Weight === 0 || transaction.Weight === null || transaction.Quantity === 0 || transaction.Quantity === null) {
      this.dataSourceOR.data[i].requiredError = true;
    } else {
      let previousValues = {
        Quantity: this.dataSourceOR.data[i].Quantity,
        Weight: this.dataSourceOR.data[i].Weight,
        Price: this.dataSourceOR.data[i].Price,
      };
      this.dataSourceOR.data[i].editMode = false;
      this.dataSourceOR.data[i].previousValues = previousValues;
    }
  }


  setPreviousValuesOR(i: any){
    this.dataSourceOR.data[i].Quantity = this.dataSourceOR.data[i].previousValues.Quantity;
    this.dataSourceOR.data[i].Weight = this.dataSourceOR.data[i].previousValues.Weight;
    this.dataSourceOR.data[i].Price = this.dataSourceOR.data[i].previousValues.Price;
    this.dataSourceOR.data[i].editMode = false;
  }


  pickupRequest() {
    const listproduct = [];
    if (this.foodRequest === true) {
      this.pickupIdData.PickupRequest[0].PickupRequest_Product.map((value) => {
        if (value.wasClicked === false) {
          value.imgSrc = '../../assets/images/addicon.png';
          value.buttonDisabled = false;
          value.isAdded = false;
        } else {
          value.imgSrc = '../../assets/images/addicon.png';
        }
        listproduct.push(value);
        this.dataSource = listproduct;
        this.deleteSource = this.dataSource;
        this.totalItems = this.deleteSource.length;
      });
      //this.getClientPickupList();
    }
    if (this.CombinedRequest === true) {
      this.pickupIdData.PickupRequest[0].PickupRequest_OrganicRecycling.map((value) => {
        value.SeparatedCompost[0] = { Description: 'Combined' };
        value.Weight = value.Weight * 0.0625;
        if (value.wasClicked === false) {
          value.imgSrc = '../../assets/images/addicon.png';
          value.buttonDisabled = false;
        } else {
          value.imgSrc = '../../assets/images/addicon.png';
        }
        listproduct.push(value);
        this.dataSourceCombinedData = listproduct;
        this.deleteSource = this.dataSourceCombinedData;
        this.totalItems = this.deleteSource.length;
      });
    }
    if (this.Separated === true) {
      var spinnerRef = this.spinnerService.start();
      this.pickupIdData.PickupRequest[0].PickupRequest_OrganicRecycling.map((value) => {
        value.Weight = value.Weight * 0.0625;
        if (value.wasClicked === false) {
          value.imgSrc = '../../assets/images/addicon.png';
          value.buttonDisabled = false;
        } else {
          value.imgSrc = '../../assets/images/addicon.png';
        }
        listproduct.push(value);
      });
      this.pickupService.SeparatedCompost().subscribe((data) => {
        this.spinnerService.stop(spinnerRef);
        this.separatedCompost = orCustomMaterialList(data.Result);
        var filteredArray = this.separatedCompost.filter((client) => {
          return (
            listproduct.filter((editble) => {
              return editble.SeparatedCompost[0].ID == client.ID;
            }).length == 0
          );
        });
        this.dataSourceCombinedData = listproduct;
        this.deleteSource = this.dataSourceCombinedData;
        this.totalItems = this.deleteSource.length;
      });
    }
  }

  async addItemToListOR(transaction, ind) {    
    if (transaction.Weight === 0 || transaction.Weight === '' || transaction.Quantity === 0 || transaction.Quantity === '' || transaction.Weight == null || transaction.Quantity == null) {
      this.dataSourceOR.data[ind].requiredError = true;
      return null;
    }

    if (transaction.isAdded && !transaction.editMode) {
      // toggle edit mode and return
      this.dataSourceOR.data[ind].editMode = true;
      let previousValues = {
        Quantity: this.dataSourceOR.data[ind].Quantity,
        Weight: this.dataSourceOR.data[ind].Weight,
        Price: this.dataSourceOR.data[ind].Price,
      };
      this.dataSourceOR.data[ind].previousValues = previousValues;
      this.previousValues = transaction;
      return null;
    }
    if (transaction.isAdded && transaction.editMode) {
      this.dataSourceOR.data[ind].editMode = false;
      return null;
    }
    if (transaction.isAdded) {
      return null;
    }
    const copyOfProductList = JSON.parse(JSON.stringify(this.dataSourceOR.data));
    const index = await copyOfProductList.findIndex((p) => transaction.ID === p.ID);
    if (index > -1) {
      copyOfProductList[index].isAdded = true;
      copyOfProductList[index].icon = this.editIcon;
      this.dataSourceOR.data = copyOfProductList;
    }
    await this.newAddedItems.push(transaction);
    return null;
  }

  addItemsCompost(transaction, value) {
    transaction.editMode = true;
    if (transaction.editble === true) {
      if (transaction.Weight > 0 && transaction.Quantity > 0) {
        if (transaction.Weight && transaction.Quantity) {
          if (transaction.wasClicked === false) {
            transaction.wasClicked = true;
            transaction.buttonDisabled = false;
            transaction.showValidators = false;
            transaction.imgSrc = '../../assets/images/addicon.png';
            for (let i = 0; i < this.sendRequest.length; i++) {
              if (transaction.ID === this.sendRequest[i].ID) {
                this.sendRequest.splice(i, 1);
              }
            }
          } else {
            transaction.editMode = false;
            transaction.isAdded = true;
            transaction.wasClicked = false;
            transaction.buttonDisabled = true;
            transaction.imgSrc = '../../assets/images/pickupediticon.png';
            const body = {
              Name: transaction.SeparatedCompost[0].Description,
              SeparatedCompostId: transaction.SeparatedCompostId,
              RequestId: transaction.RequestId,
              PickupImageURL: transaction.PickupImageURL,
              ID: transaction.ID,
              Weight: transaction.Weight,
              Quantity: transaction.Quantity,
              ContaminatedQuantity: transaction.ContaminatedQuantity,
              ContaminatedWeight: transaction.ContaminatedWeight,
            };
            this.sendRequest.push(body);
          }
          this.dataedit = false;
          this.success = true;
        } else {
          transaction.showValidators = true;
          setTimeout(() => {
            transaction.showValidators = false;
          }, 3000);
        }
      } else {
        transaction.showValidators = true;
        setTimeout(() => {
          transaction.showValidators = false;
        }, 3000);
      }
    } else {
      transaction.showEditble = true;
      setTimeout(() => {
        transaction.showEditble = false;
      }, 3000);
    }
  }

  addItems(transaction, value) {
    transaction.editMode = true;

    if (transaction.editble === true) {
      if (value > 0 && transaction.Weight > 0 && transaction.Cost > 0) {
        if (value && transaction.Cost && transaction.Weight) {
          if (transaction.wasClicked === false) {
            transaction.wasClicked = true;
            transaction.buttonDisabled = false;
            transaction.showValidators = false;
            transaction.imgSrc = '../../assets/images/addicon.png';
            for (let i = 0; i < this.sendRequest.length; i++) {
              if (transaction.ProductId === this.sendRequest[i].ProductId) {
                this.sendRequest.splice(i, 1);
              }
            }
          } else {
            transaction.isAdded = true;
            transaction.editMode = false;
            transaction.wasClicked = false;
            transaction.buttonDisabled = true;
            transaction.imgSrc = '../../assets/images/pickupediticon.png';
            const body = {
              Name: transaction.Product[0].Name,
              Quantity: +value,
              RequestId: transaction.RequestId,
              ProductId: transaction.ProductId,
              SectionId: transaction.SectionId,
              Description: transaction.Section[0].Description,
              Cost: transaction.Cost,
              Weight: transaction.Weight,
            };
            this.sendRequest.push(body);
          }
          this.dataedit = false;
          this.success = true;
        } else {
          transaction.showValidators = true;
          setTimeout(() => {
            transaction.showValidators = false;
          }, 3000);
        }
      } else {
        transaction.showValidators = true;
        setTimeout(() => {
          transaction.showValidators = false;
        }, 3000);
      }
    } else {
      transaction.showEditble = true;
      setTimeout(() => {
        transaction.showEditble = false;
      }, 3000);
    }
  }
  requestConfirmCompostList() {
    let request = [];
    request = this.sendRequest;
    let deleterequest = [];
    deleterequest = this.deleteRequest;
    if (request.length > 0 || deleterequest.length > 0) {
      if (deleterequest.length > 0) {
        for (let i = 0; i < deleterequest.length; i++) {
          let deleteData = deleterequest[i];
          // this.dialogRef.close('res');
          this.pickupService.deleteCompostMenuItem(deleteData.ID).subscribe((data) => {});
        }
      }
      if (request.length > 0) {
        this.pickupRequest();

        for (let i = 0; i < request.length; i++) {
          let data = request[i];
          if (data.ID) {
            let payload = {
              CreatedBy: this.pickupIdData.CreatedBy,
              CreatedOn: this.pickupIdData.CreatedOn,
              RequestId: data.RequestId,
              ID: data.ID,
              Weight: data.Weight * 16,
              SeparatedCompostId: data.SeparatedCompostId,
              PickupImageURL: data.PickupImageURL,
              Quantity: data.Quantity,
              ContaminatedQuantity: data.ContaminatedQuantity,
              ContaminatedWeight: data.ContaminatedWeight,
            };
            this.pickupService.editMenuCompostRequest(payload).subscribe((data) => {});
          }
        }
        for (let i = 0; i < request.length; i++) {
          let data = request[i];
          if (data.ID === null) {
            let payload = {
              CreatedBy: this.pickupIdData.CreatedBy,
              CreatedOn: this.pickupIdData.CreatedOn,
              RequestId: data.RequestId,
              Weight: data.Weight * 16,
              SeparatedCompostId: data.SeparatedCompostId,
              PickupImageURL: data.PickupImageURL,
              Quantity: data.Quantity,
              ContaminatedQuantity: data.ContaminatedQuantity,
              ContaminatedWeight: data.ContaminatedWeight,
            };
            this.pickupService.addSeparatedItemsRequest(payload).subscribe((data) => {});
          }
        }
      }
      this.editRelaodinfo.reloadmenuData = true;
      this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.editRelaodinfo));
      this.cancelPickupEdit();
      const dialogRef = this.dialog.open(SaveEditMenumodalComponent, {
        width: '400px',
        height: '300px',
        data: [{ id: this.pickupIdData.DeliveryId }],
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      this.confirmbutton = true;
      setTimeout(() => {
        this.confirmbutton = false;
      }, 3000);
    }
  }

  requestConfirmList() {
    let request = [];
    request = this.sendRequest;
    let deleterequest = [];
    deleterequest = this.deleteRequest;
    var itemsProcessed = 0;
    var deleteProcessed = 0;
    if (request.length > 0 || deleterequest.length > 0) {
      if (request.length > 0) {
        this.spinnerRef = this.spinnerService.start();
        this.pickupRequest();
        this.dataSource.map((value) => {
          value.showValidators = false;
          value.buttonDisabled = false;
          value.wasClicked = true;
        });
        for (let i = 0; i < request.length; i++) {
          let data = request[i];
          let payload = {
            CreatedBy: this.pickupIdData.CreatedBy,
            CreatedOn: this.pickupIdData.CreatedOn,
            RequestId: data.RequestId,
            ProductId: data.ProductId,
            SectionId: data.SectionId,
            Weight: data.Weight,
            Cost: data.Cost,
            Quantity: data.Quantity,
          };

          this.pickupService.editMenuItemsRequest(payload).subscribe(
            (data) => {
              itemsProcessed++;
              if (itemsProcessed === request.length) {
                if (deleterequest.length > 0) {
                  for (let i = 0; i < deleterequest.length; i++) {
                    let deleteData = deleterequest[i];

                    this.pickupService.deleteMenuItem(deleteData.RequestId, deleteData.ProductId).subscribe((data) => {
                      deleteProcessed++;
                      if (deleteProcessed === deleterequest.length) {
                        if (
                          this.pickupIdData.ShippingID &&
                          this.pickupIdData.Status[0].Title !== 'Cancelled' &&
                          this.pickupIdData.Status[0].Title === 'Claimed'
                        ) {
                          this.UpdatePickUpRoadiesStatus1();
                        } else {
                          this.spinnerService.stop(this.spinnerRef);
                          this.savePickup();
                        }
                      }
                    });
                  }
                } else {
                  if (
                    this.pickupIdData.ShippingID &&
                    this.pickupIdData.Status[0].Title !== 'Cancelled' &&
                    this.pickupIdData.Status[0].Title === 'Claimed'
                  ) {
                    this.UpdatePickUpRoadiesStatus1();
                  } else {
                    this.spinnerService.stop(this.spinnerRef);
                    this.savePickup();
                  }
                }
              }
            },
            (error) => {
              this.spinnerService.stop(this.spinnerRef);
            }
          );
        }
      } else {
        this.spinnerRef = this.spinnerService.start();
        if (deleterequest.length > 0) {
          for (let i = 0; i < deleterequest.length; i++) {
            let deleteData = deleterequest[i];

            this.pickupService.deleteMenuItem(deleteData.RequestId, deleteData.ProductId).subscribe((data) => {
              deleteProcessed++;
              if (deleteProcessed === deleterequest.length) {
                if (
                  this.pickupIdData.ShippingID &&
                  this.pickupIdData.Status[0].Title !== 'Cancelled' &&
                  this.pickupIdData.Status[0].Title === 'Claimed'
                ) {
                  this.UpdatePickUpRoadiesStatus1();
                } else {
                  this.spinnerService.stop(this.spinnerRef);
                  this.savePickup();
                }
              }
            });
          }
        }
      }
    } else {
      this.confirmbutton = true;
      setTimeout(() => {
        this.confirmbutton = false;
      }, 3000);
    }
  }

  onSearchChange(event, transaction) {
    transaction.editble = true;
    this.dataedit = true;
    this.success = false;
  }

  savePickup() {
    this.cancelPickupEdit();

    this.editRelaodinfo.reloadmenuData = true;
    this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.editRelaodinfo));
    if (this.data[1]) {
      if (this.data[1].showEditPickupItems == true) {
        let finalData;
        finalData = 'Pickup menu items updated.';
        const dialogRef = this.dialog.open(SuccesPopupComponent, {
          width: '480px',
          data: { ClientData: finalData },
          panelClass: 'custom-editSub-container',
        });
        dialogRef.afterClosed().subscribe((result) => {});
      }
    } else {
      const dialogRef = this.dialog.open(SaveEditMenumodalComponent, {
        width: '400px',
        height: '300px',
        data: [{ id: this.pickupIdData.DeliveryId }],
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
  UpdatePickUpRoadiesStatus1() {
    this.pickupService.getPickUpDetail(this.pickupIdData.DeliveryId).subscribe((data) => {
      this.editPickup = data.Result;
      const pickups: PickUps[] = [];
      this.totalItemValue1 = 0;
      this.totalWeight1 = 0;
      this.totalQuantity1 = 0;
      for (let i = 0; i <= this.editPickup[0].PickupRequest[0]['PickupRequest_Product'].length - 1; i++) {
        const pickUnit: PickUps = this.editPickup[0].PickupRequest[0]['PickupRequest_Product'][i];

        let pickupDetails = this.editPickup[0].PickupRequest[0]['PickupRequest_Product'][i];
        //total value calculation
        const itemValue = pickupDetails.Quantity * pickupDetails.Cost;
        this.totalItemValue1 = this.totalItemValue1 + itemValue;
        this.totalItemValueWithUnit1 = '$ ' + this.totalItemValue1.toFixed(2);
        //weight-calculation
        const itemWeightValue = pickupDetails.Quantity * pickupDetails.Weight;
        this.totalWeight1 = this.totalWeight1 + itemWeightValue;
        // this.totalWeightwithUnit = this.totalWeight + " Oz";
        this.totalWeightwithUnit1 = this.totalWeight1 + '';

        //weight-calculation
        this.totalQuantity1 = this.totalQuantity1 + pickupDetails.Quantity;

        pickups.push(pickUnit);
      }
      if (this.pickupIdData.ShippingID) {
        this.ShippingIDEdit = this.pickupIdData.ShippingID;
      } else {
        this.ShippingIDEdit = 0;
      }
      if (this.ShippingIDEdit) {
        this.nonprofitUpdate1 = true;
      }
      let payloaddata = {
        CreatedBy: this.pickupIdData.CreatedBy,
        CreatedOn: this.pickupIdData.CreatedOn,
        // RecipientName: this.pickup[0].PickupRequest[0].Recipient,
        update: this.nonprofitUpdate1,
        RecipientId: this.pickupIdData.PickupRequest[0].NonProfitReceiverId,
        Quantity: this.totalQuantity1,
        Value: this.totalItemValue1,
        Weight: this.totalWeightwithUnit1,
        Description: 'Pickup Request From Client ' + this.pickupIdData.PickupRequest[0].Clients[0].Name,
        ClientId: this.pickupIdData.PickupRequest[0].ClientId,
        Request_Id: this.pickupIdData.RequestId,
        // DeliveryBetweenEnd: PickupBeforedata.toISOString(),
        DeliveryBetweenEnd: this.postDateService.transform(
          this.pickupIdData.PickupBefore,
          this.pickupIdData.PickupRequest[0].PickupRequest[0].Clients[0].TimeZone
        ),
        DeliveryBetweenStart: this.postDateService.transform(
          this.pickupIdData.PickupAfter,
          this.pickupIdData.PickupRequest[0].PickupRequest[0].Clients[0].TimeZone
        ),
        PickupAfter: this.postDateService.transform(
          this.pickupIdData.PickupAfter,
          this.pickupIdData.PickupRequest[0].PickupRequest[0].Clients[0].TimeZone
        ),
        ShipmentId: this.ShippingIDEdit,
        Status: null,
      };
      this.pickupService.updateRoadiesDelivery(payloaddata).subscribe(
        (data) => {
          this.spinnerService.stop(this.spinnerRef);
          if ('error' in data) {
            this.error1 = data['message'];
            if (this.error1 === 'unprocessable request parameters') {
              alert('Unprocessable request parameters');
            } else {
              alert(this.error1);
            }
          }
          this.savePickup();
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
        }
      );
    });
  }
  deletemenuCompost(product) {
    const dialogRef = this.dialog.open(DeleteMenuComponent, {
      width: '700px',
      // height: "520px",
      data: [{ productData: product, Type: 'Compost' }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const index = this.deleteSource.indexOf(product);
        for (let i = 0; i < this.deleteRequest.length; i++) {
          if (product.ID === this.deleteRequest[i].ID) {
            this.deleteRequest.splice(i, 1);
          }
        }
        const body = {
          RequestId: product.RequestId,
          ID: product.ID,
        };
        this.deleteRequest.push(body);
        this.deleteSource.splice(index, 1);
        this.dataSourceCombinedData = new MatTableDataSource<Element>(this.deleteSource);
        this.totalItems = this.deleteSource.length;
      }
    });
  }
  ngOnDestroy() {
    this.pickupIdData.PickupRequest[0].PickupRequest_OrganicRecycling.map((value) => {
      value.editble = false;
      value.Weight = value.Weight * 16;
    });
  }
  deletemenu(product) {
    const dialogRef = this.dialog.open(DeleteMenuComponent, {
      width: '700px',
      // height: "520px",
      data: [
        {
          productData: product,
          id: this.pickupIdData.DeliveryId,
          Type: 'food',
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const index = this.deleteSource.indexOf(product);
        for (let i = 0; i < this.deleteRequest.length; i++) {
          if (product.ProductId === this.deleteRequest[i].ProductId) {
            this.deleteRequest.splice(i, 1);
          }
        }
        const body = {
          RequestId: product.RequestId,
          ProductId: product.ProductId,
        };
        this.deleteRequest.push(body);
        this.deleteSource.splice(index, 1);
        this.dataSource = new MatTableDataSource<Element>(this.deleteSource);
        this.totalItems = this.deleteSource.length;
      }
    });
  }

  cancelPickupEdit() {
    if (this.data[1]) {
      this.dialogRef.close();
    } else {
      this.showPickupDetails = true;
      this.showEditPickupItems = false;
      this.showAddItemsDiv = false;
    }
  }
  checkEdit() {
    this.disableUpdateMenuList = true;
    if (this.foodRequest === true) {
      this.pickupIdData.PickupRequest[0].PickupRequest_Product.map((value) => {
        if (value.wasClicked === false) {
          this.disableUpdateMenuList = false;
        }
      });
    }
    if (this.CombinedRequest === true || this.Separated === true) {
      this.pickupIdData.PickupRequest[0].PickupRequest_OrganicRecycling.map((value) => {
        if (value.wasClicked === false) {
          this.disableUpdateMenuList = false;
        }
      });
    }
    if (this.deleteRequest.length > 0) {
      this.disableUpdateMenuList = false;
    }
    return this.disableUpdateMenuList;
  }
  numberOnly(event, decimalAllow): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (decimalAllow !== false) {
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) return false;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }
  checkImg(img) {
    if (img.split('/images/') === 'pickupediticon.png') {
      return true;
    }
    if (img.split('/images/') === 'addicon.png') {
      return false;
    }
  }

  getClientPickupList() {
    let spinnerRef = this.spinnerService.start();
    this.pickupService.getClientsProductList(this.ClientId).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        if (Array.isArray(data.Result)) {
          const sourceOfData = [];
          // this.pickupTypeID = 1;
          const cats = [];
          const copyOfProducts = JSON.parse(JSON.stringify(data.Result));
          copyOfProducts.forEach((p) => {
            const categ = p.Sections[0].Name;
            if (!cats.includes(categ)) {
              cats.push(categ);
            }
            const objVal = {
              ...p,
              Price: p.Cost ? p.Price : '',
              Cost: p.Price,
              Weight: p.Weight ? p.Weight : '',
              Quantity: p.Quantity ? p.Quantity : '',
              isAdded: false,
              beingAdded: false,
              editMode: false,
              icon: '../../../assets/images/addicon.png',
              SectionId: p.Sections[0].SectionId,
              requiredError: false,
              previousValues: {
                Quantity: p.Quantity ? p.Quantity : '',
                Weight: p.Weight ? p.Weight : '',
                Price: p.Price ? p.Price : '',
              },
              showValidators: false,
            };

            if (!this.dataSource.some((vendor) => vendor.Product[0].Name == p.Name && vendor.Section[0].Name == p.Sections[0].Name)) {
              return sourceOfData.push(objVal);
            }
          });
          this.categories = cats;
          // this.showEmpty = false;
          sourceOfData.sort(function (a, b) {
            if (a.Name < b.Name) {
              return -1;
            }
            if (a.Name > b.Name) {
              return 1;
            }
            return 0;
          });

          this.clientsItemsList = sourceOfData;
          this.dataSource1 = new MatTableDataSource(sourceOfData);
          this.originalData = this.dataSource;

          let newData = [{ itemnameheading: '' }];

          this.dataSource2 = new MatTableDataSource(newData);
          // this.showData = true;
          // this.newItem.index = sourceOfData.length;
        }
        return null;
      },
      () => this.spinnerService.stop(spinnerRef)
    );
  }

  addItemsInList(transaction, index) {
    if (
      transaction.Cost &&
      transaction.Quantity &&
      transaction.Weight &&
      transaction.Cost != 0 &&
      transaction.Quantity != 0 &&
      transaction.Weight != 0
    ) {

      this.newAddedItems.push(transaction);
      this.newAddedItemsData = new MatTableDataSource<Element>(this.newAddedItems);
      this.clientsItemsList.splice(index, 1);

      this.dataSource1 = new MatTableDataSource<Element>(this.clientsItemsList);
    } else {
      transaction.requiredError = true;
    }
  }

  addNewItems() {
    if (this.reqestType == 'SeparatedEditMenu') {
      this.separetaedRequest();
    } else {
      this.getClientPickupList();
      this.getClientCategoryData();

      let newData = [];
      this.dataSource2 = new MatTableDataSource(newData);

      this.newAddedItems = [];
      this.newAddedItemsData = new MatTableDataSource<Element>(this.newAddedItems);
      this.resetNewItem();
    }
    
    this.showPickupDetails = false;
    this.showEditPickupItems = false;
    this.showAddItemsDiv = true;
  }

  getClientCategoryData() {
    let spinnerRef = this.spinnerService.start();
    this.customerService.getAllCategories(null, 100, null, this.ClientId).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.categoriesData = data['Result'];
    });
  }

  editItemsInList(transaction, i) {
    transaction.editMode = true;
  }

  addEditedItemsInList(transaction, i) {
    if (
      transaction.Cost &&
      transaction.Quantity &&
      transaction.Weight &&
      transaction.Cost != 0 &&
      transaction.Quantity != 0 &&
      transaction.Weight != 0
    ) {
      transaction.editMode = false;
    } else {
      transaction.requiredError = true;
    }
  }

  removeItemFromAddItemList(transaction, index) {
    transaction.Cost = null;
    transaction.Weight = null;
    transaction.Quantity = null;

    this.clientsItemsList.unshift(transaction);
    this.clientsItemsList = this.clientsItemsList.sort(function (a, b) {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
    this.dataSource1 = new MatTableDataSource<Element>(this.clientsItemsList);

    this.newAddedItems.splice(index, 1);
    this.newAddedItemsData = new MatTableDataSource<Element>(this.newAddedItems);
  }

  addNewItemInList() {
    this.showError = true;
    
    if (
      this.newItemName &&
      this.selectedCategory &&
      this.newItemCost &&
      this.newItemWeight &&
      this.newItemQuantity &&
      this.newItemCost > 0 &&
      this.newItemQuantity > 0 &&
      this.newItemWeight > 0
    ) {
      const payload = {
        Name: this.capitailizepipe.transform(this.newItemName.replace(/^\s+/g, '')),
        Price: this.newItemCost,
        Weight: this.newItemWeight,
        Category: null,
        Description: null,
        DirectCost: 1,
        IsGoodrProduct: false,
        ApprovalStatusId: 2,
        WP_Product_Id: null,
        CreatedBy: null,
        CreatedOn: null,
        ModifiedBy: null,
        ModifiedOn: null,
        ProductSections: [
          {
            SectionId: this.selectedCategory && this.selectedCategory != null ? Number(this.selectedCategory) : null,
          },
        ],

        Clients: [{ ClientId: this.ClientId }],
      };

      var spinnerRef = this.spinnerService.start();
      this.customerService.addNewFoodItem(payload).subscribe((data) => {
        if (data['ProductId']) {
          this.newProductId = data['ProductId'];
        }
        if (data['message'] === 'Duplicate Entry') {
          this.duplicateItemReqError = true;
          setTimeout(() => {
            this.duplicateItemReqError = false;
          }, 5000);
        } else {
          this.createNewItem();
        }
        this.spinnerService.stop(spinnerRef);
      });
    } else {
      this.showError = true;
    }
  }

  createNewItem() {
    let sectionName = this.categoriesData.filter((cat) => {
      return cat.SectionId == this.selectedCategory;
    });
    let itemDetails = {
      Name: this.newItemName,
      Cost: this.newItemCost,
      Weight: this.newItemWeight,
      Quantity: this.newItemQuantity,
      SectionId: this.selectedCategory,
      Sections: [{ Name: sectionName[0].Name }],
      ProductId: this.newProductId,
    };

    this.newAddedItems.push(itemDetails);
    this.newAddedItemsData = new MatTableDataSource<Element>(this.newAddedItems);
    this.resetNewItem();
  }

  addNewItemsInPickupList() {
    if (this.newAddedItems.length > 0) {
      if (this.reqestType == 'SeparatedEditMenu') {
        this.addItemsInLoopOR(0);
      } else {
        this.addItemsInLoop(0);
      }
    }
  }

  addItemsInLoop(index) {
    let payload = {
      RequestId: this.pickupRequestId,
      ProductId: this.newAddedItems[index].ProductId,
      SectionId: this.newAddedItems[index].SectionId,
      Weight: this.newAddedItems[index].Weight,
      Cost: this.newAddedItems[index].Cost,
      Quantity: this.newAddedItems[index].Quantity,
      ContaminatedWeight: this.newAddedItems[index].ContaminatedWeight,
      ContaminatedQuantity: this.newAddedItems[index].ContaminatedQuantity,
    };
    
    var spinnerRef = this.spinnerService.start();
    this.pickupService.addItemsInRequest(payload).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        if (this.newAddedItems.length > index + 1) {
          this.addItemsInLoop(index + 1);
        } else {
          this.newAddedItems = [];
          this.savePickup();
        }
      },
      (error) => {
        this.spinnerService.stop(spinnerRef);
      }
    );
  }

  addItemsInLoopOR(index) {
    let payload = {
      RequestId: this.pickupRequestId,
      Weight: (this.newAddedItems[index].Weight * 16),
      Quantity: this.newAddedItems[index].Quantity,
      ContaminatedWeight: this.newAddedItems[index].ContaminatedWeight,
      ContaminatedQuantity: this.newAddedItems[index].ContaminatedQuantity,
      SeparatedCompostId: this.newAddedItems[index].ID
    };

    var spinnerRef = this.spinnerService.start();
    this.pickupService.addSeparatedItemsRequest(payload).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        if (this.newAddedItems.length > index + 1) {
          this.addItemsInLoopOR(index + 1);
        } else {
          this.newAddedItems = [];
          this.savePickup();
        }
      },
      (error) => {
        this.spinnerService.stop(spinnerRef);
      }
    );
  }

  clearItem(itm, pickupType) {
    this.dataSourceOR.data.map((p) => {
        if (p.Name == itm.Name) {
          p.isAdded = false;
          p.Weight = '';
          p.Quantity='';
          p.editMode = false;
          p.icon = this.addIcon;
        }
      });

      this.dataSourceOR.data.sort(function (a, b) {
        return b.isAdded - a.isAdded;
      });
      let newProductList = this.newAddedItemsData.filter((i) => i.Name !== itm.Name);
      this.newAddedItemsData = newProductList;
  }

  resetNewItem() {
    this.newItemName = null;
    this.newItemCost = null;
    this.newItemWeight = null;
    this.newItemQuantity = null;
    this.selectedCategory = undefined;
    this.showError = false;
  }

  filterTable(value, category) {
    this.noresultFound = category;
    let filteredData = this.clientsItemsList.filter((data) => {
      if (category == 'Category') return data.Sections[0]['Name'].toLowerCase().includes(value.toLowerCase());
      else return data[category].toLowerCase().includes(value.toLowerCase());
    });

    this.filteredDataSF = filteredData;
    filteredData = filteredData.sort(function (a, b) {
      return b.isAdded - a.isAdded;
    });
    this.dataSource1 = new MatTableDataSource(filteredData);
  }

  clearSearch(type) {
    if (type == 'Name') {
      this.filterTable('', 'Name');
      this.searchItemName = '';
    } else {
      this.filterTable('', 'Category');
      this.searchCategoryName = '';
    }
  }

  backToEditItems() {
    if (this.data[1]) {
      this.dialogRef.close();
    } else {
      this.showPickupDetails = false;
      this.showEditPickupItems = true;
      this.showAddItemsDiv = false;
    }
  }
}
