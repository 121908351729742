import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AppState } from 'src/app/shared/app-state';
import { Router, ActivatedRoute } from '@angular/router';

import { SpinnerService } from 'src/app/core/spinner.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { modalDataService } from '../shared/services/modalData.service';

@Component({
  selector: 'app-saasconfirm',
  templateUrl: './saasconfirm.component.html',
  styleUrls: ['./saasconfirm.component.scss']
})
export class SaasconfirmComponent implements OnInit {
  address: string;
  address1: string;
  activeData = true;
  city: string;
  stateCode: string;
  mobile: string;
  email: string;
  clientData: any;
  zip: any;
  country: any;
  requireZipCode: boolean;
  requireCountry: boolean;
  requireCity: boolean;
  requireState: boolean;
  requireAddress1: boolean;
  requireTaxID: boolean;
  invalidEmail: boolean;
  requireEmail: boolean;
  dataerror: string;
  user: any;
  UserId: any;
  ClientId: any;
  dissuccess = true;
  yearlyPlan: boolean;
  noPlaning: boolean;
  info: any;
  roletype: boolean;
  constructor(
    public dialogRef: MatDialogRef<SaasconfirmComponent>,
    public _rdata: modalDataService,
    private state: AppState,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private sassService: SAASService,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this._rdata.basicInfo2Observe.subscribe(res => {
      this.info = JSON.parse(res);
    });
    if (this.data[2]) {
      if (this.data[2].active === 'Inactive') {
        this.activeData = false;
      } else {
        this.activeData = true;
      }
    }
    if (this.data[3]) {
      if (this.data[3].type !== 'Facility Group') {
        this.roletype = true;
      } else {
        this.roletype = false;
      }
    } else {
      this.roletype = true;
    }
  }
  cancelSub() {
    this.dialogRef.close('res');
  }
  close() {
    this.info = '';
    this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    this.dialogRef.close();
  }
}
