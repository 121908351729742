import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pickup-in-route',
  templateUrl: './pickup-in-route.component.html',
  styleUrls: ['./pickup-in-route.component.scss']
})
export class PickupInRouteComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  view_Pickup_List() {
    this.router.navigate(['TrackPickupList']);
  }
}
