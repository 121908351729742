import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import * as moment from 'moment-timezone';
import { CustomerService } from '../../../shared/services/customer.service';
import { AppState } from 'src/app/shared/app-state';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { EditphotodialogComponent } from '../../profileinfo/editphotodialog/editphotodialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { SpinnerService } from 'src/app/core/spinner.service';
import { DatePostFormatPipe } from 'src/app/shared/pipes/dateformat.service';
import { TimeSelectDialogComponent } from 'src/app/time-select-dialog/time-select-dialog.component';
import { AccountService } from 'src/app/shared/account.service';
import { timeFormatPipe } from 'src/app/shared/pipes/time.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SaasconfirmComponent } from 'src/app/saasconfirm/saasconfirm.component';
import { environment } from 'src/environments/environment';
import { SaasconfirmmsgComponent } from '../saasconfirmmsg/saasconfirmmsg.component';
import { EditMYearlyComponent } from '../edit-myearly/edit-myearly.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
declare var require: any;
import { orCustomMaterialList } from 'src/app/shared/services/or-custom-material-list.model';

@Component({
  selector: 'app-edit-customer-business-dialog',
  templateUrl: './edit-customer-business-dialog.component.html',
  styleUrls: ['./edit-customer-business-dialog.component.scss'],
})
export class EditCustomerBusinessDialogComponent implements OnInit {
  fileToupload: File = null;
  modalclose: string;
  public titleForm: FormGroup;
  public numberForm: FormGroup;
  public numberFormZip: FormGroup;
  user: any;
  dataSource: any;
  selectedType: any;
  allDeliveryStatus: any;
  workingBooln = false;
  buttonDisabled: boolean;
  NPBylocation: boolean;
  MRFBylocation: boolean;
  NonProfitWorkingSlots = [];
  selectedWorkingSlots = [];
  DriverServices: any;
  facilityGroupClient: number;
  facilatorGroup: boolean;
  facilityGroup: boolean;
  facilitatorClient: any;
  facilitatorClients: any;
  facilityGroupClients: any;
  driverService: any;
  State: any;
  driverSelected: any;
  facilityRole: boolean;
  radioOptions: string;
  radioOptionsFR: string;
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  profileImageUrl: any;
  grabId: any;
  editphotoDisabled: boolean;
  number: any;
  MenuEditFlag: boolean;
  facilityAccessFlag: any;
  taxForm: any;
  info: any;
  resultdata: MatDialog;
  uploadImage: any;
  requireField: boolean;
  requirednumber: boolean;
  optiondriverStatus: boolean;
  selectedDriver: any;
  drivers: any;
  selectedSGDriver: string;
  statusData: any;
  status: any;
  statusSelected: any;
  taxId: any;
  nonprofitsClients: any;
  FacilityClients: any;
  userRole: any;
  getallClients: boolean;
  driverNote: boolean;
  waypoint: boolean;
  PickupTypeS: any;
  NotificationTypes: any;
  pickupTypeID: any;
  NotificationTypesID: any;
  selectedTypeMultiple: any[];
  sendpickupTypes = [];
  facilitylogin = true;
  notifiactionTypes = [];
  selectedUsers = [];
  selectPickupValidation: boolean;
  validRoadiesTrue: boolean;
  selectRoadiesValidation: boolean;
  selectPickupNotfication: boolean;
  notificationClients = [];
  notifyUser: boolean;
  requireFieldTax: boolean;
  requireFieldZip: boolean;
  requireFieldState: boolean;
  requireFieldCity: boolean;
  requireFieldAddress: boolean;
  facilitatorRole: boolean;
  WorkingDaysID = [];
  noNotifyUser: boolean;
  otpfacility: boolean = true;
  workingdays = [
    {
      WorkingDay: 0,
      datas: 'Monday',
      StartTime: '',
      EndTime: '',
      checkedId: false,
    },
    {
      WorkingDay: 1,
      datas: 'Tuesday',
      StartTime: '',
      EndTime: '',
      checkedId: false,
    },
    {
      WorkingDay: 2,
      datas: 'Wednesday',
      StartTime: '',
      EndTime: '',
      checkedId: false,
    },
    {
      WorkingDay: 3,
      datas: 'Thursday',
      StartTime: '',
      EndTime: '',
      checkedId: false,
    },
    {
      WorkingDay: 4,
      datas: 'Friday',
      StartTime: '',
      EndTime: '',
      checkedId: false,
    },
    {
      WorkingDay: 5,
      datas: 'Saturday',
      StartTime: '',
      EndTime: '',
      checkedId: false,
    },
    {
      WorkingDay: 6,
      datas: 'Sunday',
      StartTime: '',
      EndTime: '',
      checkedId: false,
    },
  ];
  NumberOfPeopleServed: any;
  NonProfitWorkingValid: boolean;
  peoplesValid: boolean;
  // deliveryOption = true;
  addressDisabledF: boolean;
  fgRoleLogin = true;
  ClientId: any;
  menuedit = true;
  editClientID: any;
  clientData: any;
  noPlaning: boolean;
  yearlyPlan: boolean;
  faclityCount: any;
  selectClient: boolean;
  selectfacClient: boolean;
  saaslist: any;
  nosaaslist: any;
  saasstripeplan: boolean;
  adminRoleLogin = false;
  userIdStripe: any;
  requireFieldDriver: boolean;
  radioOptionsFG: string;
  radioOptionsFacility: string;
  faciltatorOption: boolean;
  requireFieldcountry: boolean;
  saasneed = true;
  pickupstatesdata: any;
  citydataID: any;
  City: string;
  pickupcitydataID: any;
  pickupCity: string;
  onetimepick: boolean;
  Stateid: any;
  driverTitle: any;
  goodrSelected: string;
  updateinfo: any;
  saasnoconvertion: boolean;
  apprStatus: any;
  SAASfgconversion: boolean;
  saasnoconvertionstatus: boolean;
  SAASfgconversionstatus: boolean;
  SAASconversionnotpos: boolean;
  driverServicecustom: any;
  SAASfacilityconversionstatus: boolean;
  SAASfacilityconversion: boolean;
  facilityNo = false;
  adminLogin: boolean;
  subPlanData: any;
  requireFieldWayPoint: boolean;
  separatedCompost: any;
  SeparatedCompostID = [];
  materialValid: boolean;
  featureinfo: any;
  SAASFeature = true;
  OTPFeature = true;
  selectedTypeTitle: any;
  timezoneabbr: string;
  timezone: any;
  zipcodes: any;
  zipupdate: any;
  AgreementFlag = null;
  RoadiePostmatesFeature: boolean;
  Visibledriverservice = true;
  taxLengthError: boolean;
  adminUserRole: boolean;
  requireEmail: boolean;
  invalidEmail: boolean;
  taxIdlength: boolean;
  spinnerRef: any;
  scheduler: boolean;

  options: any = {
    componentRestrictions: { country: ["us"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  }

  constructor(
    private state: AppState,
    private accountService: AccountService,
    public dialog: MatDialog,
    private sassService: SAASService,
    private formBuilder: FormBuilder,
    private ClientFilterdata: ClientFilterService,
    private adminToolServices: AdmintoolsService,
    private spinnerService: SpinnerService,
    private customerServices: CustomerService,
    private pickupService: PickUpService,
    private AdminService: AdmintoolsService,
    public postDateService: DatePostFormatPipe,
    public dateFormat: timeFormatPipe,
    public dialogRef: MatDialogRef<EditCustomerBusinessDialogComponent>,
    public _rdata: modalDataService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private pricingModule: PricingModuleServiceService,
    public CustomerService: CustomerService,
    private sanitizer: DomSanitizer
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.Visibledriverservice = getFeature('driverservices');
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);

      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        // if (this.featureinfo.featureData[0].Title === 'OTP') {
        //   if (this.featureinfo.featureData[1].EnableFeature === false) {
        //     this.OTPFeature = false;
        //   }
        // }
        // if (this.featureinfo.featureData[1].Title === 'SAAS') {
        //   if (this.featureinfo.featureData[1].EnableFeature === false) {
        //     this.SAASFeature = false;
        //   }
        // }
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'OTP' && element.EnableFeature === false) {
            this.OTPFeature = false;
          }
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            this.SAASFeature = false;
          }
          if (element.Title === 'Roadie/Postmates' && element.EnableFeature === true) {
            this.RoadiePostmatesFeature = true;
          } else {
            this.RoadiePostmatesFeature = false;
          }
        }
      }
      // }
    });
    this.getnonprofitAuto();
    this.pickupstates();
    this._rdata.basicupdateInfo2Observe.subscribe((res) => {
      this.updateinfo = JSON.parse(res);
    });
    this.numberFormZip = this.formBuilder.group({
      zip: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])],
    });
    this.state.user = JSON.parse(localStorage.getItem('user')).user;
    this.userRole = this.state.user.User.Roles[0].Name;

    if (this.userRole == 'Admin' || this.userRole == 'Billing Admin') {
      this.adminUserRole = true;
    } else {
      this.adminUserRole = false;
    }
    // if (this.userRole === 'Facility Group' && this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
    if (this.userRole === 'Facility Group') {
      this.addressDisabledF = true;
      this.fgRoleLogin = false;
      this.goodrSelected = 'Goodr';
      this.radioOptionsFG = 'FGNone';
      this.radioOptionsFacility = 'FacilityNone';
    } else if (this.userRole === 'Facility' && this.state.user.User.Clients[0].DriverService === 'SAAS') {
      this.fgRoleLogin = false;
      this.addressDisabledF = true;
      this.facilitylogin = false;
    } else if (this.userRole === 'Admin' || this.userRole === 'Billing Admin') {
      this.adminLogin = true;
      if (this.data[0].customer[0].ClientCategory.length > 0) {
        if (
          this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility' ||
          this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group'
        ) {
          this.goodrSelected = 'SAAS';
          this.driverTitle = 'SAAS';
          this.saasneed = false;
          if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility') {
            this.radioOptionsFacility = 'SAASFacility';
          }
          if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group') {
            this.radioOptionsFG = 'SAASFG';
            this.faciltatorOption = false;
          }
          this.adminRoleLogin = true;
        } else {
          this.radioOptionsFacility = 'FacilityNone';
          if (this.data[0].customer[0].DriverServices.length > 0) {
            if (this.data[0].customer[0].DriverServices[0].Title === 'SAAS') {
              this.saasneed = false;
            } else {
              this.saasneed = true;
            }
          }
          this.radioOptionsFG = 'FGNone';
          this.goodrSelected = 'Goodr';
          this.facilityNo = true;
          if (this.data[0].customer[0].DriverServices.length > 0) {
            if (this.data[0].customer[0].DriverServices[0].Title !== 'SAAS') {
              this.faciltatorOption = true;
            } else {
              this.faciltatorOption = false;
            }
          }
        }
      } else {
        this.radioOptionsFacility = 'FacilityNone';
        this.facilityNo = true;
        this.goodrSelected = 'Goodr';
        this.saasneed = true;
        this.radioOptionsFG = 'FGNone';
        this.faciltatorOption = true;
      }
    } else {
      this.radioOptionsFacility = 'FacilityNone';
      this.goodrSelected = 'Goodr';
      this.radioOptionsFG = 'FGNone';
    }
    this.pickupCity = this.data[0].customer[0].CityId;
    this.Stateid = this.data[0].customer[0].StateId;
    this.getStatusData();
    this.numberForm = this.formBuilder.group({
      number: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
    });
    this.taxForm = this.formBuilder.group({
      tax: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    });
    // this.adminToolServices.getGoodrDrivers().subscribe(
    //   data => {
    //     this.drivers = data.Result;
    //   },
    //   error => {
    //     this.state.isSpinnerVisible = false;
    //     alert(JSON.stringify(error));
    //   }
    // );
    if (this.data[0].customer[0].TimeZone) {
      this.timezone = this.data[0].customer[0].TimeZone;
      this.timezoneabbr = moment.tz.zone(this.data[0].customer[0].TimeZone).abbr(360);
    }
    this.NumberOfPeopleServed = this.data[0].customer[0].NumberOfPeopleServed;
    if (this.data[0].customer[0].MenuType.length > 0) {
      if (this.data[0].customer[0].MenuType[0].Title === 'Grab') {
        this.editphotoDisabled = true;
        this.waypoint = true;
        if (this.data[0].customer[0].ImageUrl == null) {
          this.profileImageUrl = 'assets/images/logo.png';
          this.ImageUrlnull = true;
          this.ImageUrl = false;
        } else {
          this.profileImageUrl = this.data[0].customer[0].ImageUrl;
          this.ImageUrl = true;
          this.ImageUrlnull = false;
        }
      } else {
        this.waypoint = false;
        if (this.data[0].customer[0].Logo == null) {
          this.profileImageUrl = 'assets/images/logo.png';
          this.ImageUrlnull = true;
          this.ImageUrl = false;
        } else {
          this.profileImageUrl = this.data[0].customer[0].Logo;
          this.uploadImage = this.data[0].customer[0].Logo;
          this.ImageUrl = true;
          this.ImageUrlnull = false;
        }
      }
    } else {
      this.editphotoDisabled = false;
      if (this.data[0].customer[0].Logo == null) {
        this.profileImageUrl = 'assets/images/logo.png';
        this.ImageUrlnull = true;
        this.ImageUrl = false;
      } else {
        this.profileImageUrl = this.data[0].customer[0].Logo;
        this.uploadImage = this.data[0].customer[0].Logo;
        this.ImageUrl = true;
        this.ImageUrlnull = false;
      }
    }
    this.facilityAccessFlag = this.data[0].customer[0].InviteUser;
    if (this.data[0].customer[0].ClientCategory[0].Title === 'Non Profit') {
      if (this.data[0].customer[0].HoldHarmlessAgreement) {
        this.AgreementFlag = this.data[0].customer[0].HoldHarmlessAgreement;
      } else {
        this.AgreementFlag = false;
      }
    }
    if (this.data[0].customer[0].FacilityGroupId) {
      this.radioOptions = 'facilitatorGrp';
      this.facilityGroup = true;
      this.facilatorGroup = false;
    } else if (this.data[0].customer[0].FacilitatorId) {
      this.radioOptionsFR = 'facilitatorGrpFR';
      // this.radioOptionsFG = 'FGNone';
      this.radioOptions = 'facilitator';
      this.facilatorGroup = true;
      this.facilityGroup = false;
    } else {
      // this.radioOptionsFG = 'SAASFG';
      this.radioOptions = 'facilityNone';
      this.facilityNo = true;
      this.radioOptionsFR = 'facilityFRNone';
      this.facilatorGroup = false;
      this.facilityGroup = false;
    }
    this.sendpickupTypes = this.data[0].customer[0].PickupType;
    // if (this.sendpickupTypes.length > 0) {
    //   if (this.sendpickupTypes[0].Title === 'One Time Pickup') {
    //     this.onetimepick = true;
    //   } else {
    //     this.onetimepick = false;
    //   }
    // }
    this.notifiactionTypes = this.data[0].customer[0].NotificationTypes;
    if (this.data[0].customer[0].DriverServices.length > 0) {
      this.driverSelected = this.data[0].customer[0].DriverServices[0].ID;
    }
    if (this.data[0].customer[0].ApprovalStatus.length > 0) {
      this.statusSelected = this.data[0].customer[0].ApprovalStatus[0].ID;
      this.apprStatus = this.data[0].customer[0].ApprovalStatus[0].Title;
    }
    // if (this.data[0].customer[0].DriverServices.length > 0) {
    //   this.driverSelected = this.data[0].customer[0].DriverServices[0].Title;
    // }
    this.facilityGroupClient = this.data[0].customer[0].FacilityGroupId;

    // this.facilityGroupClient = this.data[0].customer[0].FacilityGroupId;
    this.facilitatorClient = this.data[0].customer[0].FacilitatorId;
    if (this.data[0].customer[0].ClientCategory.length > 0) {
      if (this.data[0].customer[0].ClientCategory[0].Title === 'Non Profit') {
        this.facilityRole = false;
        this.driverNote = true;
        this.facilitatorRole = false;
        this.NPBylocation = true;
        this.MRFBylocation = false;
        this.data[0].customer[0].DriverServices = [];
        this.driverSelected = '';
      } else if (this.data[0].customer[0].ClientCategory[0].Title === 'MRF') {
        this.facilityRole = false;
        this.driverNote = true;
        this.facilitatorRole = false;
        this.NPBylocation = false;
        this.MRFBylocation = true;
        this.data[0].customer[0].DriverServices = [];
        this.driverSelected = '';
        this.separetaedRequest();
      } else if (
        this.data[0].customer[0].ClientCategory[0].Title === 'Facility Group' ||
        this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group'
      ) {
        this.facilitatorRole = true;
        this.driverNote = false;
        this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
          this.info = JSON.parse(res);
          // if (this.info.facilitatorGroup.length > 0) {
          //   this.facilityGroupClients = this.info.facilitatorGroup.filter(value => value.ApprovalStatus[0].Title === 'Active');
          // }
          if (this.info.facilitator.length > 0) {
            this.facilitatorClients = this.info.facilitator.filter((value) => value.ApprovalStatus === 'Active');
          }
          if (this.info.getallClients === true) {
            //  this.spinnerService.stop(spinnerRef);
          }
        });
      } else if (this.data[0].customer[0].ClientCategory[0].Title === 'Facility') {
        if (this.data[0].customer[0].AllowSchedulePickup === true || this.data[0].customer[0].AllowSchedulePickup === false) {
          this.scheduler = this.data[0].customer[0].AllowSchedulePickup;
        } else {
          this.scheduler = false;
        }
        if (this.data[0].customer[0].MenuEdit === true || this.data[0].customer[0].MenuEdit === false) {
          this.MenuEditFlag = this.data[0].customer[0].MenuEdit;
        } else {
          this.MenuEditFlag = true;
        }
        this.driverNote = true;
        this.facilityRole = true;
        if (this.data[0].customer[0].PickupType[0].Title === 'One Time Pickup') {
          this.otpfacility = false;
        }
        this.facilitatorRole = false;
        // this.NPBylocation = true;
        // var spinnerRef = this.spinnerService.start();
        this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
          this.info = JSON.parse(res);
          // if (this.info.facilitatorGroup.length > 0) {
          //   this.facilityGroupClients = this.info.facilitatorGroup.filter(value => value.ApprovalStatus[0].Title === 'Active');
          // }
          this.customerServices.getnoSAASFGList().subscribe((data) => {
            this.saaslist = data.Result;
            // if (this.driverService) {
            //   for (const status of this.driverService) {
            //     if (this.driverSelected === status.ID) {
            // if (status.Title !== 'SAAS') {
            if (this.data[0].customer[0].ClientCategory[0].Title !== 'SAAS Facility') {
              if (this.data[0].customer[0].DriverServices[0].Title === 'SAAS') {
                this.facilityGroupClients = this.saaslist.filter((value) => value.ApprovalStatus === 'Active' && value.DriverService === 'SAAS');
              } else {
                this.facilityGroupClients = this.saaslist.filter((value) => value.ApprovalStatus === 'Active' && value.DriverService !== 'SAAS');
              }
            }

            // }
            //   }
            // }
            // }
          });
          this.customerServices.getSAASFGList().subscribe((data) => {
            this.nosaaslist = data.Result;
            // if (this.driverService) {
            // for (const status of this.driverService) {
            // if (this.driverSelected === status.ID) {
            // if (status.Title === 'SAAS') {
            if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility') {
              this.facilityGroupClients = this.nosaaslist.filter((value) => value.ApprovalStatus === 'Active');
            }
            // }
            // }
            // }
            // }
          });
          if (this.info.facilitator.length > 0) {
            this.facilitatorClients = this.info.facilitator.filter((value) => value.ApprovalStatus === 'Active');
          }
          if (this.info.getallClients === true) {
            //  this.spinnerService.stop(spinnerRef);
          }
        });
      } else if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility') {
        if (this.data[0].customer[0].AllowSchedulePickup === true || this.data[0].customer[0].AllowSchedulePickup === false) {
          this.scheduler = this.data[0].customer[0].AllowSchedulePickup;
        } else {
          this.scheduler = false;
        }
        if (this.data[0].customer[0].MenuEdit === true || this.data[0].customer[0].MenuEdit === false) {
          this.MenuEditFlag = this.data[0].customer[0].MenuEdit;
        } else {
          this.MenuEditFlag = true;
        }
        this.driverNote = true;
        this.facilityRole = true;
        this.facilitatorRole = false;
        // this.NPBylocation = true;
        // var spinnerRef = this.spinnerService.start();
        this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
          this.info = JSON.parse(res);
          // if (this.info.facilitatorGroup.length > 0) {
          //   this.facilityGroupClients = this.info.facilitatorGroup.filter(value => value.ApprovalStatus[0].Title === 'Active');
          // }
          this.customerServices.getnoSAASFGList().subscribe((data) => {
            this.saaslist = data.Result;
            // if (this.driverService) {
            //   for (const status of this.driverService) {
            //     if (this.driverSelected === status.ID) {
            //       if (status.Title !== 'SAAS') {
            if (this.data[0].customer[0].ClientCategory[0].Title !== 'SAAS Facility') {
              this.facilityGroupClients = this.saaslist.filter((value) => value.ApprovalStatus === 'Active');
            }
            //     }
            //   }
            // }
            // }
          });
          this.customerServices.getSAASFGList().subscribe((data) => {
            this.nosaaslist = data.Result;
            // if (this.driverService) {
            //   for (const status of this.driverService) {
            //     if (this.driverSelected === status.ID) {
            //       if (status.Title === 'SAAS') {
            if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility') {
              this.facilityGroupClients = this.nosaaslist.filter((value) => value.ApprovalStatus === 'Active');
            }
            //   }
            // }
            // }
          });
          if (this.info.facilitator.length > 0) {
            this.facilitatorClients = this.info.facilitator.filter((value) => value.ApprovalStatus === 'Active');
          }
          if (this.info.getallClients === true) {
            //  this.spinnerService.stop(spinnerRef);
          }
        });
      } else {
        this.data[0].customer[0].DriverServices = [];
        this.driverSelected = '';
      }
    }
    if (this.Stateid && this.Stateid !== null) {
      // console.log(this.Stateid);
      this.sassService.pickupcitydetails(this.Stateid).subscribe((data) => {
        this.citydataID = data['Result'];
        for (var i = 0; i < this.citydataID.length; i++) {
          if (this.citydataID[i].ID == this.data[0].customer[0].CityId) {
            this.pickupCity = this.citydataID[i].ID;
            // console.log(this.citydataID[i]);
            this.zipcodes = this.citydataID[i].zip_codes.split('/');
            // console.log(this.zipcodes);
            // console.log(this.data[0].customer[0].ZIP);
            for (var j = 0; j < this.zipcodes.length; j++) {
              // console.log(this.zipcodes[j],this.data[0].customer[0].ZIP);
              if (this.zipcodes[j].replace(/\s/g, '') == this.data[0].customer[0].ZIP) {
                this.zipupdate = this.zipcodes[j];
              }
            }
          }
        }
      });
    }
    this.customerServices.NotificationTypes().subscribe(
      (data) => {
        this.NotificationTypes = data.Result;
        // this.NotificationTypes = this.NotificationTypes.filter(value => value.Title !== 'NotifyUser' );
        // if (this.driverService) {
        //   for (const status of this.driverService) {
        //     if (status.Title === 'SAAS') {
        //     } else if (status.Title === 'Goodr') {
        //     } else {
        //       for (var j = 0; j < this.NotificationTypes.length; j++) {
        //         if (this.NotificationTypes[j].Title === 'NotifyDriver') {
        //           this.NotificationTypes[j]['disabled'] = true;
        //           this.NotificationTypes[j]['checkedId'] = false;
        //         }
        //       }
        //     }
        //   }
        // }
        for (var j = 0; j < this.NotificationTypes.length; j++) {
          for (var i = 0; i < this.data[0].customer[0].NotificationType.length; i++) {
            // this.pickupTypeID = this.info.pickupTypes;
            if (this.data[0].customer[0].NotificationType[i].ID == this.NotificationTypes[j].ID) {
              if (this.data[0].customer[0].NotificationType[i].Title === 'NotifyUser') {
                this.notifyUser = true;
              }
              this.NotificationTypes[j]['checkedId'] = true;
            }
          }
        }
        var notifiactionTypes = [];
        for (var i = 0; i < this.data[0].customer[0].NotificationType.length; i++) {
          notifiactionTypes.push(this.data[0].customer[0].NotificationType[i].ID);
        }
        this.NotificationTypesID = notifiactionTypes;
        this.customerServices.InviteUserNotification(this.data[0].customer[0].ClientId).subscribe(
          (data) => {
            this.notificationClients = data.Result;
            this.selectedTypeMultiple = [];
            this.selectedUsers = this.data[0].customer[0].User_Client;
            for (var i = 0; i < this.selectedUsers.length; i++) {
              if (this.selectedUsers[i].NotifyUser === true) {
                this.selectedTypeMultiple.push(this.selectedUsers[i].Id);
              }
            }
          },
          (error) => {
            this.state.isSpinnerVisible = false;
          }
        );
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );

    this.customerServices.CompostPickupTypeList().subscribe(
      (data) => {
        this.PickupTypeS = data.Result;
        if (this.driverService) {
          for (const status of this.driverService) {
            if (this.driverSelected === status.ID) {
              if (status.Title === 'Roadie' || status.Title === 'Postmates' || status.Title === 'Roadie/Postmates') {
                for (var j = 0; j < this.PickupTypeS.length; j++) {
                  if (this.PickupTypeS[j].Description === 'Organic Recycling Pickup Type') {
                    this.PickupTypeS[j]['disabled'] = true;
                    this.PickupTypeS[j]['checkedId'] = false;
                  }
                }
              }
            }
          }
        }
        // this.PickupTypeS = this.PickupTypeS.filter(value =>
        //   value.Title !== 'One Time Pickup');
        // if(this.data[0].customer[0].PickupType[i].Title === 'One Time Pickup'){
        // this.PickupTypeS = this.PickupTypeS.filter(value =>
        //   value.Title === 'One Time Pickup');
        // }
        if (this.OTPFeature === false) {
          this.PickupTypeS = this.PickupTypeS.filter((value) => value.Title !== 'One Time Pickup');
        }
        for (var j = 0; j < this.PickupTypeS.length; j++) {
          for (var i = 0; i < this.data[0].customer[0].PickupType.length; i++) {
            if (this.data[0].customer[0].PickupType[i].Title === 'One Time Pickup') {
              this.PickupTypeS = this.PickupTypeS.filter((value) => value.Title === 'One Time Pickup');
              // if (this.PickupTypeS[j].Title === 'One Time Pickup') {
              //   this.PickupTypeS[j]['disabled'] = false;
              // } else {
              //   this.PickupTypeS[j]['disabled'] = true;
              // }
            } else {
              if (this.PickupTypeS[j].Title === 'One Time Pickup') {
                this.PickupTypeS[j]['disabled'] = true;
              } else {
                this.PickupTypeS[j]['disabled'] = false;
              }
            }
          }
        }
        let count = 0;
        for (var j = 0; j < this.PickupTypeS.length; j++) {
          for (var i = 0; i < this.data[0].customer[0].PickupType.length; i++) {
            if (this.PickupTypeS[j].ID === this.data[0].customer[0].PickupType[i].ID) {
              if (this.PickupTypeS[j].Description === 'Food Pickup Type') {
                count++;
              } else {
              }
            }
          }
        }
        if (count === 1) {
          this.menuedit = true;
        } else {
          this.menuedit = false;
          this.MenuEditFlag = true;
        }
        for (var j = 0; j < this.PickupTypeS.length; j++) {
          for (var i = 0; i < this.data[0].customer[0].PickupType.length; i++) {
            // this.pickupTypeID = this.info.pickupTypes;
            if (this.data[0].customer[0].PickupType[i].ID == this.PickupTypeS[j].ID) {
              this.PickupTypeS[j]['checkedId'] = true;
            }
          }
        }
        var sendpickupTypes = [];
        for (var i = 0; i < this.data[0].customer[0].PickupType.length; i++) {
          sendpickupTypes.push(this.data[0].customer[0].PickupType[i].ID);
        }
        this.pickupTypeID = sendpickupTypes;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
      }
    );
    this.customerServices.getPickupTypeList().subscribe(
      (data) => {
        this.driverService = data.Result;
        if (this.PickupTypeS) {
          if (this.data[0].customer[0].DriverServices.length > 0) {
            if (
              this.data[0].customer[0].DriverServices[0].Title === 'Roadie' ||
              this.data[0].customer[0].DriverServices[0].Title === 'Roadie/Postmates' ||
              this.data[0].customer[0].DriverServices[0].Title === 'Postmates'
            ) {
              for (var k = 0; k < this.PickupTypeS.length; k++) {
                if (this.PickupTypeS[k].Title === 'Organic Recycling') {
                  this.PickupTypeS[k]['disabled'] = true;
                  this.PickupTypeS[k]['checkedId'] = false;
                }
              }
            }
          }
        }
        this.driverServicecustom = data.Result;
        if (this.data[0].customer[0].ClientCategory[0].Title === 'Facility') {
          if (this.RoadiePostmatesFeature === true) {
            this.driverService = this.driverService.filter((value) => value.Title !== 'Roadie' && value.Title !== 'Postmates');
          }
          if (this.data[0].customer[0].DriverServices.length > 0) {
          } else {
            this.driverSelected = this.driverService[0].ID;
          }
        }
        if (this.data[0].customer[0].ClientCategory[0].Title === 'Facility Group') {
          this.driverService = this.driverService.filter((value) => value.Title === 'Goodr' || value.Title === 'SAAS');
        }
        if (this.userRole === 'Facility' && this.state.user.User.Clients[0].DriverService === 'SAAS') {
          for (var j = 0; j < this.driverService.length; j++) {
            if (this.driverService[j].Title === 'SAAS') {
              this.driverService[j]['disabled'] = false;
            } else {
              this.driverService[j]['disabled'] = true;
            }
          }
        } else if (this.userRole === 'Facility Group') {
          for (var j = 0; j < this.driverService.length; j++) {
            this.driverService[j]['disabled'] = true;
          }
        } else {
          if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility') {
            for (var j = 0; j < this.driverService.length; j++) {
              if (this.driverSelected === this.driverService[j].ID) {
                if (this.driverService[j].Title === 'SAAS') {
                  this.driverService = this.driverService.filter((value) => value.Title === 'SAAS');
                } else {
                  this.driverService = this.driverService.filter((value) => value.Title !== 'SAAS');
                }
              }
            }
          }
          // this.driverService = this.driverService.filter(value => value.Title !== 'SAAS');
        }
        // if (this.userRole === 'Facility' || 'Facility Group' && this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
        if (this.userRole === 'Facility' || 'Facility Group') {
          this.AdminService.getUsersSASSByDriver().subscribe((data) => {
            this.drivers = data;
          });
        } else {
          this.selectedSGDriver = 'sAASDriver=true&pagingparametermodel.nonPaginatedUsers=true';
          this.AdminService.getUsersByDriver(this.selectedSGDriver).subscribe((data) => {
            this.drivers = data.Result;
          });
        }
        for (const status of this.driverService) {
          if (this.driverSelected === status.ID) {
            if (status.Title === 'SAAS') {
              status['disabled'] = false;
              this.optiondriverStatus = true;
              if (this.data[0].customer[0].SAASDriver.length > 0) {
                this.selectedDriver = this.data[0].customer[0].SAASDriver[0].Id;
              }
            } else if (status.Title === 'Goodr') {
              this.optiondriverStatus = false;
            } else {
              this.optiondriverStatus = false;
              if (this.NotificationTypes) {
                for (var j = 0; j < this.NotificationTypes.length; j++) {
                  if (this.NotificationTypes[j].Title === 'NotifyDriver') {
                    this.NotificationTypes[j]['disabled'] = true;
                    this.NotificationTypes[j]['checkedId'] = false;
                  }
                }
              }
            }
          }
        }
        // this.driverSelected = this.driverService[0].ID;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );

    this.customerServices.getAccountType().subscribe(
      (data) => {
        this.allDeliveryStatus = data['Result'];
        let detail = this.allDeliveryStatus;
        for (var status of detail) {
          // this.val = status.Title;
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    this.v2_getAllCustomers();
    this.user = this.data[0].customer;

    this.user[0].Country = 'USA';
    if (this.user[0].ClientCategory.length > 0) {
      this.selectedType = this.user[0].ClientCategory[0].ClientCategoryId;
    }
    this.titleForm = this.formBuilder.group({
      ClientId: ['', Validators.compose([Validators.required])],
      EINNumber: ['', Validators.compose([Validators.required])],
      Name: ['', Validators.compose([Validators.required])],
      Address: ['', Validators.compose([Validators.required])],
      Address2: ['', Validators.compose([Validators.required])],
      City: ['', Validators.compose([Validators.required])],
      State: ['', Validators.compose([Validators.required])],
      Zip: ['', Validators.compose([Validators.required])],
    });
    this.getPricingRated();
  }
  separetaedRequest() {
    this.pickupService.SeparatedCompost().subscribe((data) => {
      this.separatedCompost = orCustomMaterialList(data.Result);
      if (this.data[0].customer[0].SeparatedCompost) {
        for (var j = 0; j < this.separatedCompost.length; j++) {
          for (var i = 0; i < this.data[0].customer[0].SeparatedCompost.length; i++) {
            if (this.data[0].customer[0].SeparatedCompost[i].ID === this.separatedCompost[j].ID) {
              this.SeparatedCompostID.push(this.data[0].customer[0].SeparatedCompost[i].ID);
              this.separatedCompost[j]['checkedId'] = true;
            }
          }
        }
      }
    });
  }
  onCheckboxmaterial(event, value) {
    if (event.checked) {
      this.SeparatedCompostID.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.SeparatedCompostID.indexOf(value);
      if (index > -1) {
        this.SeparatedCompostID.splice(index, 1);
      }
    }
  }
  getPricingRated() {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      this.subPlanData = data['Result'];
    });
  }
  UpdateAccountType(value, event) {
    this.selectedType = value;
  }
  keyPressZipCode(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.pickupstatesdata = data['Result'];
      for (var i = 0; i < this.pickupstatesdata.length; i++) {
        if (this.pickupstatesdata[i].ID == this.data[0].customer[0].StateId) {
          this.Stateid = this.pickupstatesdata[i].ID;
        }
      }
    });
  }
  selectedstate(cb) {
    this.sassService.pickupcitydetails(this.Stateid).subscribe((data) => {
      this.citydataID = data['Result'];
      this.pickupCity = '';
      this.zipupdate = '';
      cb();
    });
  }
  selectedcity() {
    for (const status of this.citydataID) {
      if (status.ID === this.pickupCity) {
        this.timezone = status.time_zone;
        this.timezoneabbr = moment.tz.zone(status.time_zone).abbr(360);
        this.zipcodes = status.zip_codes.split('/');
      }
    }
  }
  selectedzip() {
    // console.log(this.zipupdate.replace(/\s/g, ''));
  }
  pickupState(pickupState: any) {
    throw new Error('Method not implemented.');
  }
  onCheckboxworking(event, value) {
    // this.WorkingDaysIDChecked = value.Id;
    this.workingBooln = true;
    if (event.checked) {
      this.WorkingDaysID.push(value);
    }

    if (!event.checked) {
      let index = this.WorkingDaysID.indexOf(value);
      if (index > -1) {
        this.WorkingDaysID.splice(index, 1);
      }
    }
    // for (var i = 0; i < this.NonProfitWorkingSlots.length; i++) {
    //   // this.NotificationTypesID = this.info.NotificationTypes;
    //   if (this.NonProfitWorkingSlots[i]) {
    //     this.NonProfitWorkingSlots[i]['checkedId'] = true;
    //   }
    // }
  }
  isFacilitySAAS(event) {
    if (event.value === 'SAASFacility') {
      const detail = this.allDeliveryStatus;
      for (const status of detail) {
        if (status.Title === 'SAAS Facility') {
          this.selectedType = status.ClientCategoryId;
        }
      }
      // } else {
      //   this.driverService = this.driverService.filter(value => value.Title !== 'SAAS');
      this.optiondriverStatus = true;
      const detaildriver = this.driverServicecustom;
      for (const status of detaildriver) {
        if (status.Title === 'SAAS') {
          this.driverSelected = status.ID;
          this.driverTitle = status.Title;
        }
      }
      this.driverService = this.driverServicecustom.filter((value) => value.Title === 'SAAS');
      this.saasneed = false;
      this.conditionsSAAS();
    } else {
      this.optiondriverStatus = false;
      const detail = this.allDeliveryStatus;
      for (const status of detail) {
        if (status.Title === 'Facility') {
          this.selectedType = status.ClientCategoryId;
        }
      }
      this.facilityGroupClients = this.saaslist.filter((value) => value.ApprovalStatus === 'Active');
      this.facilityGroupClient = this.data[0].customer[0].FacilityGroupId;
      // const detaildriver = this.driverServicecustom;
      // for (const status of detaildriver) {
      //   if (status.Title === 'Goodr') {
      //     this.driverSelected = status.ID;
      //     this.driverTitle = status.Title;
      //   }
      // }
      this.driverService = this.driverServicecustom;
      this.saasneed = true;
      this.conditionsSAAS();
    }
    if (this.statusSelected === this.data[0].customer[0].ApprovalStatus[0].ID) {
      let appstate;
      for (const appstatus of this.status) {
        if (appstatus.ID == this.statusSelected) {
          appstate = appstatus.Title;
        }
      }
      if (appstate === 'Inactive' && this.goodrSelected === 'Goodr') {
        this.SAASconversionnotpos = true;
        const detail = this.allDeliveryStatus;
        for (const status of detail) {
          if (status.Title === 'Facility') {
            this.selectedType = status.ClientCategoryId;
          }
        }
        this.optiondriverStatus = false;
        const detaildriver = this.driverServicecustom;
        for (const status of detaildriver) {
          if (status.Title === this.data[0].customer[0].DriverServices[0].Title) {
            this.driverSelected = status.ID;
            this.driverTitle = status.Title;
          }
        }
        // this.driverService = this.driverServicecustom.filter(
        //   value => value.Title !== 'SAAS'
        // );
        this.saasneed = true;
        setTimeout(() => {
          this.radioOptionsFacility = 'FacilityNone';
        });
        setTimeout(() => {
          this.SAASconversionnotpos = false;
        }, 5000);
      } else {
        if (this.goodrSelected === 'Goodr') {
          if (this.radioOptionsFacility === 'SAASFacility') {
            // this.faciltatorOption = false;
            this.confirmationMsg(event.value);
          }
        } else {
          if (this.radioOptionsFacility === 'FacilityNone') {
            // this.faciltatorOption = true;
            this.confirmationMsg(event.value);
          } else {
            // this.faciltatorOption = false;
          }
        }
      }
    } else {
      this.SAASfacilityconversion = true;
      setTimeout(() => {
        if (this.radioOptionsFacility === 'FacilityNone') {
          this.radioOptionsFacility = 'SAASFacility';
          this.optiondriverStatus = true;
          const detaildriver = this.driverServicecustom;
          for (const status of detaildriver) {
            if (status.Title === 'SAAS') {
              this.driverSelected = status.ID;
              this.driverTitle = status.Title;
            }
          }
          this.driverService = this.driverServicecustom.filter((value) => value.Title === 'SAAS');
          this.saasneed = false;
        } else {
          this.radioOptionsFacility = 'FacilityNone';
          this.optiondriverStatus = false;
          const detaildriver = this.driverServicecustom;
          for (const status of detaildriver) {
            if (status.Title === 'Goodr') {
              this.driverSelected = status.ID;
              this.driverTitle = status.Title;
            }
          }
          this.driverService = this.driverServicecustom.filter((value) => value.Title !== 'SAAS');
          this.saasneed = true;
        }
      });
      setTimeout(() => {
        this.SAASfacilityconversion = false;
      }, 3000);
    }
  }
  isFacilityGroupSAAS(event) {
    if (event.value === 'SAASFG') {
      const detail = this.allDeliveryStatus;
      for (const status of detail) {
        if (status.Title === 'SAAS Facility Group') {
          this.selectedType = status.ClientCategoryId;
        }
      }
      const detaildriver = this.driverService;
      for (const status of detaildriver) {
        if (status.Title === 'SAAS') {
          this.driverSelected = status.ID;
          this.driverTitle = status.Title;
        }
      }
    } else {
      const detail = this.allDeliveryStatus;
      for (const status of detail) {
        if (status.Title === 'Facility Group') {
          this.selectedType = status.ClientCategoryId;
        }
      }
      const detaildriver = this.driverService;
      for (const status of detaildriver) {
        if (status.Title === 'Goodr') {
          this.driverSelected = status.ID;
          this.driverTitle = status.Title;
        }
      }
    }
    if (this.statusSelected === this.data[0].customer[0].ApprovalStatus[0].ID) {
      let appstate;
      for (const appstatus of this.status) {
        if (appstatus.ID == this.statusSelected) {
          appstate = appstatus.Title;
        }
      }
      if (appstate === 'Inactive' && this.goodrSelected === 'Goodr') {
        this.SAASconversionnotpos = true;
        const detail = this.allDeliveryStatus;
        for (const status of detail) {
          if (status.Title === 'Facility Group') {
            this.selectedType = status.ClientCategoryId;
          }
        }
        this.optiondriverStatus = false;
        const detaildriver = this.driverServicecustom;
        for (const status of detaildriver) {
          if (status.Title === this.data[0].customer[0].DriverServices[0].Title) {
            this.driverSelected = status.ID;
            this.driverTitle = status.Title;
          }
        }
        this.driverService = this.driverServicecustom.filter((value) => value.Title !== 'SAAS');
        this.saasneed = true;
        setTimeout(() => {
          this.radioOptionsFG = 'FGNone';
        });
        setTimeout(() => {
          this.SAASconversionnotpos = false;
        }, 5000);
      } else {
        if (this.goodrSelected === 'Goodr') {
          if (this.radioOptionsFG === 'SAASFG') {
            this.faciltatorOption = false;
            this.saasfgconf(event.value);
          }
        } else {
          if (this.radioOptionsFG === 'FGNone') {
            this.faciltatorOption = true;
            this.saasfgconf(event.value);
          } else {
            this.faciltatorOption = false;
          }
        }
      }
    } else {
      this.SAASfgconversion = true;
      setTimeout(() => {
        if (this.radioOptionsFG === 'FGNone') {
          this.radioOptionsFG = 'SAASFG';
        } else {
          this.radioOptionsFG = 'FGNone';
        }
      });
      setTimeout(() => {
        this.SAASfgconversion = false;
      }, 3000);
    }
  }
  saasfgconf(event) {
    if (event === 'SAASFG') {
      this.buttonDisabled = true;
      this.CustomerService.getAllFilteredCustomers(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.user[0].ClientId,
        null,
        null,
        null,
        null
      ).subscribe((data) => {
        data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
        this.buttonDisabled = false;
        if (data.Result.length > 0) {
          this.confirmationMsg(event);
        } else {
          this.driverSelected = this.data[0].customer[0].DriverServices[0].ID;
          const detail = this.allDeliveryStatus;
          for (const status of detail) {
            if (status.Title === 'Facility Group') {
              this.selectedType = status.ClientCategoryId;
            }
          }
          this.radioOptionsFG = 'FGNone';
          this.saasnoconvertion = true;
          setTimeout(() => {
            this.saasnoconvertion = false;
          }, 5000);
        }
      });
    } else {
      this.buttonDisabled = true;
      if (this.state.user.User.Roles[0].Name === 'Facility') {
        this.editClientID = this.state.user.User.Clients[0].ClientId;
      } else if (this.userRole === 'Admin') {
        if (this.facilityGroupClient) {
          this.editClientID = this.facilityGroupClient;
        } else {
          this.editClientID = this.data[0].customer[0].ClientId;
        }
      } else {
        this.editClientID = this.state.user['FacilityGroupId'];
      }
      this.sassService.getSubDetails(this.editClientID).subscribe((data) => {
        this.buttonDisabled = false;
        this.clientData = data.Result[0];
        if (this.clientData.StripePlanId) {
          this.confirmationMsg(event);
        } else {
        }
      });
    }
  }
  confirmationMsg(event) {
    // if (this.goodrSelected !== this.driverTitle) {
    const dialogRef = this.dialog.open(SaasconfirmmsgComponent, {
      width: '500px',
      // height: '440px',
      data: [{ fgtype: event }],
      disableClose: true,
      panelClass: 'custom-editSub-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // if (result) {
      if (result === 'SAASActive') {
        this.updateUserDetails();
      } else if (result === 'SAASinActive') {
        this.statusSelected = this.data[0].customer[0].ApprovalStatus[0].ID;
        // this.dialogRef.close();
      } else if (event === 'SAASFacility') {
        if (result === event) {
          for (var j = 0; j < this.driverService.length; j++) {
            if (this.driverService[j].Title === 'SAAS') {
              this.driverService[j]['disabled'] = false;
              this.driverSelected = this.driverService[j].ID;
              this.driverTitle = this.driverService[j].Title;
            } else {
              this.driverService[j]['disabled'] = true;
            }
          }
          this.conditionsSAAS();
          this.facilityGroupClient = null;
        } else {
          this.driverService = this.driverServicecustom;
          // const detaildriver = this.driverServicecustom;
          // for (const status of detaildriver) {
          //   if (status.Title === 'Goodr') {
          //     this.driverSelected = status.ID;
          //   }
          // }
          // this.driverService = this.driverServicecustom;
          const detail = this.allDeliveryStatus;
          for (const status of detail) {
            if (status.Title === 'Facility') {
              this.selectedType = status.ClientCategoryId;
            }
          }
          this.saasneed = true;
          this.conditionsSAAS();
          this.radioOptionsFacility = 'FacilityNone';
        }
      } else if (event === 'FacilityNone') {
        if (result === event) {
          this.facilityGroupClient = null;
        } else {
          this.radioOptionsFacility = 'SAASFacility';
          const detail = this.allDeliveryStatus;
          for (const status of detail) {
            if (status.Title === 'SAAS Facility') {
              this.selectedType = status.ClientCategoryId;
            }
          }
          this.optiondriverStatus = true;
          const detaildriver = this.driverServicecustom;
          for (const status of detaildriver) {
            if (status.Title === 'SAAS') {
              this.driverSelected = status.ID;
              this.driverTitle = status.Title;
            }
          }
          this.driverService = this.driverServicecustom.filter((value) => value.Title === 'SAAS');
          this.saasneed = false;
          this.conditionsSAAS();
        }
      } else if (event === 'SAASFG') {
        if (result === event) {
        } else {
          this.faciltatorOption = true;
          this.radioOptionsFG = 'FGNone';
          const detail = this.allDeliveryStatus;
          for (const status of detail) {
            if (status.Title === 'Facility Group') {
              this.selectedType = status.ClientCategoryId;
            }
          }
        }
      } else {
        if (result === event) {
        } else {
          this.faciltatorOption = false;
          this.radioOptionsFG = 'SAASFG';
          const detail = this.allDeliveryStatus;
          for (const status of detail) {
            if (status.Title === 'SAAS Facility Group') {
              this.selectedType = status.ClientCategoryId;
            }
          }
        }
      }
    });
  }
  onNotificationsChange(event, value, status) {
    if (event.checked) {
      this.NotificationTypesID.push(value);
      // if (status.Title === 'NotifyUser') {
      //   this.notifyUser = true;
      // } else {
      //   this.notifyUser = false;
      // }
    }
    if (!event.checked) {
      // if (status.Title === 'NotifyUser') {
      //   this.notifyUser = false;
      // }
      //  else {
      //   this.notifyUser = true;
      // }
      // this.selectPickupType = value;
      let index = this.NotificationTypesID.indexOf(value);
      if (index > -1) {
        this.NotificationTypesID.splice(index, 1);
      }
    }
    for (var j = 0; j < this.NotificationTypes.length; j++) {
      for (var i = 0; i < this.NotificationTypesID.length; i++) {
        // this.pickupTypeID = this.info.pickupTypes;
        if (this.NotificationTypesID[i] == this.NotificationTypes[j].ID) {
          if (this.NotificationTypes[j].Title === 'NotifyUser') {
            this.notifyUser = true;
          } else {
            this.notifyUser = false;
          }
        }
      }
    }
    if (this.NotificationTypesID.length === 0) {
      this.notifyUser = false;
    }
  }
  onCheckboxChagen(event, value) {
    if (event.checked) {
      this.pickupTypeID.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.pickupTypeID.indexOf(value);
      if (index > -1) {
        this.pickupTypeID.splice(index, 1);
      }
    }
    for (var i = 0; i < this.data[0].customer[0].PickupType.length; i++) {
      if (this.data[0].customer[0].PickupType[i].Title === 'One Time Pickup') {
        if (this.pickupTypeID.length === 0) {
          this.pickupTypeID = [];
          for (var j = 0; j < this.PickupTypeS.length; j++) {
            if (this.PickupTypeS[j].Title === 'One Time Pickup') {
              // this.PickupTypeS[j]['disabled'] = false;
            } else {
              this.PickupTypeS[j]['checkedId'] = true;
              this.pickupTypeID.push(this.PickupTypeS[j].ID);
            }
          }
        } else {
          this.pickupTypeID = [];
          for (var j = 0; j < this.PickupTypeS.length; j++) {
            if (this.PickupTypeS[j].Title === 'One Time Pickup') {
              this.PickupTypeS[j]['checkedId'] = true;
              this.pickupTypeID.push(this.PickupTypeS[j].ID);
            } else {
              this.PickupTypeS[j]['checkedId'] = false;
            }
          }
        }
      }
    }
    let count = 0;
    for (var j = 0; j < this.PickupTypeS.length; j++) {
      for (var i = 0; i < this.pickupTypeID.length; i++) {
        if (this.PickupTypeS[j].ID === this.pickupTypeID[i]) {
          if (this.PickupTypeS[j].Description === 'Food Pickup Type') {
            count++;
          } else {
          }
        }
      }
    }
    if (count === 1) {
      this.menuedit = true;
    } else {
      this.menuedit = false;
      this.MenuEditFlag = true;
    }
  }
  DriverUpdate() {}
  getStatusData() {
    this.AdminService.getStatus().subscribe((data) => {
      this.statusData = data;
      this.status = data.Result;
      this.status = data.Result.filter((value) => value.Title !== 'Trash');
    });
  }
  onSelectionChangeStatus(event) {
    this.statusSelected = event.value;
    let appstate;
    for (const appstatus of this.status) {
      if (appstatus.ID == this.statusSelected) {
        appstate = appstatus.Title;
      }
    }
    if (
      this.data[0].customer[0].ClientCategory[0].Title === 'Facility Group' ||
      this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group'
    ) {
      if (
        (this.radioOptionsFG === 'SAASFG' && this.goodrSelected === 'SAAS') ||
        (this.radioOptionsFG === 'FGNone' && this.goodrSelected === 'Goodr')
      ) {
        if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group' && appstate !== 'Inactive') {
          this.CustomerService.getAllFilteredCustomers(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.user[0].ClientId,
            null,
            null,
            null,
            null
          ).subscribe((data) => {
            data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
            if (data.Result.length > 0) {
              // this.confirmationMsg(event);
            } else {
              this.statusSelected = this.data[0].customer[0].ApprovalStatus[0].ID;
              this.saasnoconvertionstatus = true;
              setTimeout(() => {
                this.saasnoconvertionstatus = false;
              }, 5000);
            }
          });
        }
      } else {
        this.SAASfgconversionstatus = true;
        setTimeout(() => {
          this.statusSelected = this.data[0].customer[0].ApprovalStatus[0].ID;
        });
        setTimeout(() => {
          this.SAASfgconversionstatus = false;
        }, 3000);
      }
    }
    if (this.data[0].customer[0].ClientCategory[0].Title === 'Facility' || this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility') {
      if (
        (this.radioOptionsFacility === 'SAASFacility' && this.goodrSelected === 'SAAS') ||
        (this.radioOptionsFacility === 'FacilityNone' && this.goodrSelected === 'Goodr')
      ) {
        if (this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility' && appstate !== 'Inactive') {
          // this.CustomerService.getAllFilteredCustomers(
          //   null, null, null, null, null, null, null, null, this.user[0].ClientId, null, null, null
          // ).subscribe(data => {
          //   data.Result = data.Result.filter(value => value.ApprovalStatus === 'Active');
          //   if (data.Result.length > 0) {
          //     // this.confirmationMsg(event);
          //   } else {
          //     this.statusSelected = this.data[0].customer[0].ApprovalStatus[0].ID;
          //     this.saasnoconvertionstatus = true;
          //     setTimeout(() => {
          //       this.saasnoconvertionstatus = false;
          //     }, 5000);
          //   }
          // });
        }
      } else {
        this.SAASfacilityconversionstatus = true;
        setTimeout(() => {
          this.statusSelected = this.data[0].customer[0].ApprovalStatus[0].ID;
        });
        setTimeout(() => {
          this.SAASfacilityconversionstatus = false;
        }, 3000);
      }
    }
  }
  isFacilityGroup(event) {
    if (event.value === 'facilitator') {
      this.facilitatorClients.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : ((b.Name.toLowerCase() > a.Name.toLowerCase()) ? -1 : 0))
      this.facilatorGroup = true;
      this.facilityGroup = false;
    } else if (event.value === 'facilitatorGrpFR') {
      this.facilatorGroup = true;
      this.facilityGroup = false;
    } else if (event.value === 'facilitatorGrp') {
      this.facilityGroupClients.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : ((b.Name.toLowerCase() > a.Name.toLowerCase()) ? -1 : 0))
      this.facilityGroup = true;
      this.facilatorGroup = false;
    } else {
      this.facilityGroup = false;
      this.facilatorGroup = false;
      this.facilitatorClient = null;
      this.facilityGroupClient = null;
    }
  }
  edituserdetails() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
  }
  v2_getAllCustomers() {
    this.CustomerService.v2_getAllCustomers().subscribe((data) => {
      this.dataSource = data.Result;
    });
  }
  isDriverService(event) {
    this.driverSelected = event.value;
    this.conditionsSAAS();
  }
  conditionsSAAS() {
    for (const status of this.driverService) {
      if (this.driverSelected === status.ID) {
        if (status.Title === 'SAAS') {
          for (var k = 0; k < this.PickupTypeS.length; k++) {
            this.PickupTypeS[k]['disabled'] = false;
          }
          this.driverTitle = status.Title;
          this.saasneed = false;
          // this.facilityAccessFlag = true;
          const detail = this.allDeliveryStatus;
          for (const status of detail) {
            if (status.ClientCategoryId === this.selectedType) {
              this.selectedTypeTitle = status.Title;
            }
          }
          if (this.selectedTypeTitle === 'SAAS Facility') {
            if (this.nosaaslist) {
              this.facilityGroupClients = this.nosaaslist.filter((value) => value.ApprovalStatus === 'Active');
            }
          } else {
            if (this.saaslist) {
              this.facilityGroupClients = this.saaslist.filter((value) => value.ApprovalStatus === 'Active' && value.DriverService === 'SAAS');
            }
          }
          this.adminRoleLogin = true;
          if (this.userRole === 'Facility Group') {
            this.radioOptions = 'facilitatorGrp';
            this.facilityGroup = true;
          }
          this.radioOptionsFR = 'facilityFRNone';
          this.facilatorGroup = false;
          // this.facilitatorClient = null;
          this.NotificationTypesID = [];
          for (const status of this.NotificationTypes) {
            if (status.Title === 'NotifyAdmin') {
              this.NotificationTypesID.push(status.ID);
            }
            if (status.Title === 'NotifyDriver') {
              this.NotificationTypesID.push(status.ID);
            }
          }
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            for (var i = 0; i < this.NotificationTypesID.length; i++) {
              if (this.NotificationTypesID[i] === this.NotificationTypes[j].ID) {
                this.NotificationTypes[j]['checkedId'] = true;
                this.NotificationTypes[j]['disabled'] = false;
              }
            }
          }
          this.optiondriverStatus = true;
          this.selectedSGDriver = 'sAASDriver=true&pagingparametermodel.nonPaginatedUsers=true';
          this.AdminService.getUsersByDriver(this.selectedSGDriver).subscribe((data) => {
            this.drivers = data.Result;
          });
        } else {
          if (this.radioOptions === 'facilitator') {
            this.facilatorGroup = true;
            this.facilityGroup = false;
          } else if (this.radioOptions === 'facilitatorGrpFR') {
            this.facilatorGroup = true;
            this.facilityGroup = false;
          } else if (this.radioOptions === 'facilitatorGrp') {
            this.facilityGroup = true;
            this.facilatorGroup = false;
          } else {
            this.facilityGroup = false;
            this.facilatorGroup = false;
          }
          this.saasneed = true;
          // this.facilityAccessFlag = true;
          this.adminRoleLogin = false;
          this.driverTitle = status.Title;
          if (this.saaslist) {
            if (this.saaslist.length > 0) {
              this.facilityGroupClients = this.saaslist.filter((value) => value.ApprovalStatus === 'Active' && value.DriverService !== 'SAAS');
            }
          }
          this.selectedSGDriver = 'goodrDriver=true&pagingparametermodel.nonPaginatedUsers=true';
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            this.NotificationTypes[j]['disabled'] = false;
          }
          this.optiondriverStatus = false;
          //     this.selectedDriver = null;
          if (status.Title === 'Goodr') {
            for (var k = 0; k < this.PickupTypeS.length; k++) {
              this.PickupTypeS[k]['disabled'] = false;
            }
            this.NotificationTypesID = [];
            for (const status of this.NotificationTypes) {
              if (status.Title === 'NotifyAdmin') {
                this.NotificationTypesID.push(status.ID);
              }
              if (status.Title === 'NotifyDriver') {
                this.NotificationTypesID.push(status.ID);
              }
            }
            for (var j = 0; j < this.NotificationTypes.length; j++) {
              for (var i = 0; i < this.NotificationTypesID.length; i++) {
                if (this.NotificationTypesID[i] === this.NotificationTypes[j].ID) {
                  this.NotificationTypes[j]['checkedId'] = true;
                  this.NotificationTypes[j]['disabled'] = false;
                }
              }
            }
          } else {
            for (var j = 0; j < this.NotificationTypes.length; j++) {
              if (this.NotificationTypes[j].Title === 'NotifyDriver') {
                this.NotificationTypes[j]['disabled'] = true;
                this.NotificationTypes[j]['checkedId'] = false;
              }
            }
            this.NotificationTypesID = [];
            for (const status of this.NotificationTypes) {
              if (status.Title === 'NotifyAdmin') {
                this.NotificationTypesID.push(status.ID);
              }
            }
            for (var j = 0; j < this.NotificationTypes.length; j++) {
              for (var i = 0; i < this.NotificationTypes.length; i++) {
                if (this.NotificationTypesID[i] === this.NotificationTypes[j].ID) {
                  this.NotificationTypes[j]['checkedId'] = true;
                }
              }
            }
            for (var k = 0; k < this.PickupTypeS.length; k++) {
              if (this.PickupTypeS[k].Title === 'Organic Recycling') {
                this.PickupTypeS[k]['disabled'] = true;
                this.PickupTypeS[k]['checkedId'] = false;
              }
            }
            this.pickupTypeID = [];
            for (const status of this.PickupTypeS) {
              if (status.Title === 'Food') {
                this.pickupTypeID.push(status.ID);
              }
            }
            for (var l = 0; l < this.PickupTypeS.length; l++) {
              for (var p = 0; p < this.PickupTypeS.length; p++) {
                if (this.pickupTypeID[p] === this.PickupTypeS[l].ID) {
                  this.PickupTypeS[l]['checkedId'] = true;
                }
              }
            }
            // for (var j = 0; j < this.PickupTypeS.length; j++) {
            //   for (var i = 0; i < this.pickupTypeID.length; i++) {
            //     // this.pickupTypeID = this.info.pickupTypes;
            //     if (this.PickupTypeS[j].ID === this.pickupTypeID[i]) {
            //       if (
            //         this.PickupTypeS[j].Description ===
            //         'Organic Recycling Pickup Type'
            //       ) {
            //         this.validRoadiesTrue = true;
            //       } else {
            //         this.validRoadiesTrue = false;
            //       }
            //       // this.accountdetails();
            //     }
            //   }
            // }
            // if (this.validRoadiesTrue === true) {
            //   this.selectRoadiesValidation = true;
            //   this.pickupTypeID = [];
            //   for (const status of this.PickupTypeS) {
            //     if (status.Title === 'Food') {
            //       this.pickupTypeID.push(status.ID);
            //     }
            //   }
            //   for (var j = 0; j < this.PickupTypeS.length; j++) {
            //     for (var i = 0; i < this.pickupTypeID.length; i++) {
            //       if (this.pickupTypeID[i] === this.PickupTypeS[j].ID) {
            //         this.PickupTypeS[j]['checkedId'] = true;
            //       } else {
            //         this.PickupTypeS[j]['checkedId'] = false;
            //       }
            //     }
            //   }
            //   setTimeout(() => {
            //     this.selectRoadiesValidation = false;
            //   }, 3000);
            // }
          }
        }
      }
    }
  }

  onChange(event) {}
  EditbusinessDetails() {
    this.user[0].Email = this.user[0].Email ? this.user[0].Email.trim() : this.user[0].Email;
    this.user[0].Name = this.user[0].Name ? this.user[0].Name.trim() : this.user[0].Name;
    this.user[0].EINNumber = this.user[0].EINNumber ? this.user[0].EINNumber.trim() : this.user[0].EINNumber;
    this.user[0].Address = this.user[0].Address ? this.user[0].Address.trim() : this.user[0].Address;
    this.user[0].Address2 = this.user[0].Address2 ? this.user[0].Address2.trim() : this.user[0].Address2;
    this.user[0].Phone = this.user[0].Phone ? this.user[0].Phone.trim() : this.user[0].Phone;
    this.user[0].DriverNotes = this.user[0].DriverNotes !== null && this.user[0].DriverNotes !== '' ? this.user[0].DriverNotes.trim() : this.user[0].DriverNotes;

    if (this.user[0].Email) {
      if (this.validateEmail()) {
        if (this.data[0].customer[0].ClientCategory[0].Title !== 'MRF') {
          if (this.user[0].EINNumber) {
            if (this.user[0].EINNumber.includes('-')) {
              if (this.user[0].EINNumber.length == 10) {
                this.EditCustomerDetails();
              }
            }
            if (!this.user[0].EINNumber.includes('-')) {
              if (this.user[0].EINNumber.length == 9) {
                this.EditCustomerDetails();
              } else {
                this.taxIdlength = true;
                setTimeout(() => {
                  this.taxIdlength = false;
                }, 3000);
              }
            }
          } else {
            this.requireFieldTax = true;
            setTimeout(() => {
              this.requireFieldTax = false;
            }, 3000);
          }

          // if (this.user[0].EINNumber) {
          //   this.EditCustomerDetails();
          // } else if (this.user[0].EINNumber == '') {
          //   this.requireFieldTax = true;
          //   setTimeout(() => {
          //     this.requireFieldTax = false;
          //   }, 3000);
          // } else {
          //   this.taxLengthError = true;
          //   setTimeout(() => {
          //     this.taxLengthError = false;
          //   }, 3000);
          // }
        } else {
          this.EditCustomerDetails();
        }
      } else {
        this.invalidEmail = true;
        setTimeout(() => {
          this.invalidEmail = false;
        }, 3000);
      }
    } else {
      this.requireEmail = true;
      setTimeout(() => {
        this.requireEmail = false;
      }, 3000);
    }
  }
  EditCustomerDetails() {
    if (this.userRole === 'Facility Group' || this.userRole === 'Facility') {
      if (this.data[0].customer[0].ClientCategory.length > 0) {
        if (
          this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group' ||
          this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility'
          // this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS'
        ) {
          if (this.statusSelected !== this.data[0].customer[0].ApprovalStatus[0].ID) {
            for (const statusdata of this.status) {
              if (this.statusSelected === statusdata.ID) {
                // if (statusdata.Title === 'Active') {
                // }
                if (this.state.user.User.Roles[0].Name === 'Facility') {
                  this.editClientID = this.state.user.User.Clients[0].ClientId;
                } else if (this.userRole === 'Admin') {
                  if (this.facilityGroupClient) {
                    this.editClientID = this.facilityGroupClient;
                  } else {
                    this.editClientID = this.data[0].customer[0].ClientId;
                  }
                } else {
                  this.editClientID = this.state.user['FacilityGroupId'];
                }
                this.sassService.getSubDetails(this.editClientID).subscribe((data) => {
                  this.clientData = data.Result[0];
                  if (this.clientData.StripePlanId) {
                    this.noPlaning = false;
                    this.saasstripeplan = true;
                    let saasprice;
                    // for (const status of this.subPlanData) {
                    this.pricingModule.getsubscriptionSAAS(this.clientData.StripePlanId).subscribe((data) => {
                      let price;
                      price = data['Result'];
                      for (const status of price) {
                        if (this.clientData.StripePlanId === status.StripePlanId) {
                          if (status.SubscriptionType === 'month') {
                            saasprice = status.Price;
                            this.yearlyPlan = false;
                          } else {
                            saasprice = status.Price;
                            this.yearlyPlan = true;
                          }
                        }
                      }
                    });
                    this.CustomerService.getAllFilteredCustomers(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      this.editClientID,
                      null,
                      null,
                      null,
                      null
                    ).subscribe((data) => {
                      if (data.Result.length > 0) {
                        data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
                        this.faclityCount = data.Result.length;
                      } else {
                        this.faclityCount = 1;
                      }
                      // this.multipleFGdata = data.Result;
                      // this.dialogRef.close();
                      const dialogRef = this.dialog.open(SaasconfirmComponent, {
                        width: '500px',
                        // height: '440px',
                        data: [
                          { faclityCount: this.faclityCount },
                          { yearlyPlan: this.yearlyPlan },
                          { active: statusdata.Title },
                          {
                            type: this.data[0].customer[0].ClientCategory[0].Title,
                          },
                          { saasPrice: saasprice },
                        ],
                        panelClass: 'custom-editSub-container',
                      });
                      dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                          // if (this.userRole === 'Facility Group') {
                          //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
                          //     this.userIdStripe = this.state.user.User.UserId;
                          //     this.ClientId = this.state.user['FacilityGroupId'];
                          //   }
                          // }
                          // if (this.userRole === 'Facility') {
                          //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
                          //     this.userIdStripe = this.state.user.User.UserId;
                          //     this.ClientId = this.state.user.User.Clients[0].ClientId;
                          //   }
                          // }
                          // if (this.userRole === 'Admin') {
                          //   this.userIdStripe = null;
                          //   if (this.facilityGroupClient) {
                          //     this.ClientId = this.facilityGroupClient;
                          //   } else {
                          //     this.ClientId = this.data[0].customer[0].ClientId;
                          //   }
                          // }
                          // let payloadStripe;
                          // if (this.userRole === 'Admin') {
                          //   payloadStripe = {
                          //     QuantityChange: true,
                          //     Update: true,
                          //     ClientId: this.ClientId,
                          //   };
                          // } else {
                          //   payloadStripe = {
                          //     QuantityChange: true,
                          //     Update: true,
                          //     ClientId: this.ClientId,
                          //     UserId: this.userIdStripe
                          //   };
                          // }

                          // this.sassService.striping(payloadStripe).subscribe(
                          //   data => {
                          //     if (data.message === 'Updated Successfully') {
                          this.updateDetails();
                          // } else {
                          //   alert(data.message);
                          // }
                          // }
                          // });
                        }
                      });
                      // }
                    });
                  } else {
                    this.noPlaning = true;
                    this.updateDetails();
                  }
                });
              }
            }
          } else {
            this.updateDetails();
          }
        } else {
          this.updateDetails();
        }
      } else {
        this.updateDetails();
      }
    } else if (this.userRole === 'Admin') {
      if (this.data[0].customer[0].DriverServices.length > 0) {
        const detail = this.allDeliveryStatus;
        for (const status of detail) {
          if (status.ClientCategoryId === this.selectedType) {
            this.selectedTypeTitle = status.Title;
          }
        }
        if (this.selectedTypeTitle === 'SAAS Facility' || this.selectedTypeTitle === 'SAAS Facility Group') {
          if (this.statusSelected !== this.data[0].customer[0].ApprovalStatus[0].ID) {
            for (const appstatus of this.status) {
              if (appstatus.ID == this.statusSelected) {
                this.apprStatus = appstatus.Title;
              }
            }
            for (const statusdata of this.status) {
              if (this.statusSelected === statusdata.ID) {
                // if (statusdata.Title === 'Active') {
                // }
                if (this.state.user.User.Roles[0].Name === 'Facility') {
                  this.editClientID = this.state.user.User.Clients[0].ClientId;
                } else if (this.userRole === 'Admin') {
                  if (this.facilityGroupClient) {
                    this.editClientID = this.facilityGroupClient;
                  } else {
                    this.editClientID = this.data[0].customer[0].ClientId;
                  }
                } else {
                  this.editClientID = this.state.user['FacilityGroupId'];
                }
                this.buttonDisabled = true;
                this.sassService.getSubDetails(this.editClientID).subscribe((data) => {
                  this.clientData = data.Result[0];
                  if (this.clientData.StripePlanId) {
                    this.noPlaning = false;
                    this.saasstripeplan = true;
                    // if (this.clientData.StripePlanId === 'price_1H2W2rICi4RDu2OllIjS8nqt') {
                    let saasprice;
                    // for (const status of this.subPlanData) {
                    this.pricingModule.getsubscriptionSAAS(this.clientData.StripePlanId).subscribe((data) => {
                      let price;
                      price = data['Result'];
                      for (const status of price) {
                        if (this.clientData.StripePlanId === status.StripePlanId) {
                          if (status.SubscriptionType === 'month') {
                            saasprice = status.Price;
                            this.yearlyPlan = false;
                          } else {
                            saasprice = status.Price;
                            this.yearlyPlan = true;
                          }
                        }
                      }
                    });
                    this.CustomerService.getAllFilteredCustomers(
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      this.editClientID,
                      null,
                      null,
                      null,
                      null
                    ).subscribe((data) => {
                      this.buttonDisabled = false;
                      if (data.Result.length > 0) {
                        data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
                        this.faclityCount = data.Result.length;
                      } else {
                        this.faclityCount = 1;
                      }
                      // this.multipleFGdata = data.Result;
                      // this.dialogRef.close();
                      const dialogRef = this.dialog.open(SaasconfirmComponent, {
                        width: '500px',
                        // height: '440px',
                        data: [
                          { faclityCount: this.faclityCount },
                          { yearlyPlan: this.yearlyPlan },
                          { active: statusdata.Title },
                          {
                            type: this.data[0].customer[0].ClientCategory[0].Title,
                          },
                          { saasPrice: saasprice },
                        ],
                        panelClass: 'custom-editSub-container',
                      });
                      dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                          // this.updateDetails();
                          // if (this.userRole === 'Facility Group') {
                          //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
                          //     this.userIdStripe = this.state.user.User.UserId;
                          //     this.ClientId = this.state.user['FacilityGroupId'];
                          //   }
                          // }
                          // if (this.userRole === 'Facility') {
                          //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
                          //     this.userIdStripe = this.state.user.User.UserId;
                          //     this.ClientId = this.state.user.User.Clients[0].ClientId;
                          //   }
                          // }
                          // if (this.userRole === 'Admin') {
                          //   this.userIdStripe = null;
                          //   if (this.facilityGroupClient) {
                          //     this.ClientId = this.facilityGroupClient;
                          //   } else {
                          //     this.ClientId = this.data[0].customer[0].ClientId;
                          //   }
                          // }
                          // let payloadStripe;
                          // if (this.data[0].customer[0].ApprovalStatus[0].Title === 'Active') {
                          //   if (this.userRole === 'Admin') {
                          //     payloadStripe = {
                          //       CancelSubscription: true,
                          //       Update: true,
                          //       ClientId: this.ClientId
                          //     };
                          //   } else {
                          //     payloadStripe = {
                          //     CancelSubscription: true,
                          //     Update: true,
                          //     ClientId: this.ClientId,
                          //     UserId: this.userIdStripe
                          //     };
                          //   }
                          // payloadStripe = {
                          //   CancelSubscription: true,
                          //   Update: true,
                          //   ClientId: this.ClientId,
                          //   UserId: this.userIdStripe
                          // };
                          // } else {
                          //   if (this.userRole === 'Admin') {
                          //     payloadStripe = {
                          //       QuantityChange: true,
                          //       Update: true,
                          //       ClientId: this.ClientId,
                          //     };
                          //   } else {
                          //     payloadStripe = {
                          //       QuantityChange: true,
                          //       Update: true,
                          //       ClientId: this.ClientId,
                          //       UserId: this.userIdStripe
                          //     };
                          //   }
                          // }
                          // this.sassService.striping(payloadStripe).subscribe(
                          //   data => {
                          //     if (data.message === 'Updated Successfully') {
                          this.updateDetails();
                          //   } else {
                          //     alert(data.message);
                          //   }
                          // });
                        }
                      });
                      // }
                    });
                  } else {
                    this.buttonDisabled = false;
                    this.noPlaning = true;
                    // this.updateDetails();
                    this.confirmationMsg('SAASActive');
                  }
                });
              }
            }
          } else if (this.facilityGroupClient != this.data[0].customer[0].FacilityGroupId) {
            if (this.facilityGroupClient) {
              for (const appstatus of this.status) {
                if (appstatus.ID == this.statusSelected) {
                  this.apprStatus = appstatus.Title;
                }
              }
              for (const statusdata of this.status) {
                if (this.statusSelected === statusdata.ID) {
                  // if (statusdata.Title === 'Active') {
                  // }
                  if (this.state.user.User.Roles[0].Name === 'Facility') {
                    this.editClientID = this.state.user.User.Clients[0].ClientId;
                  } else if (this.userRole === 'Admin') {
                    if (this.facilityGroupClient) {
                      this.editClientID = this.facilityGroupClient;
                    } else {
                      this.editClientID = this.data[0].customer[0].ClientId;
                    }
                  } else {
                    this.editClientID = this.state.user['FacilityGroupId'];
                  }
                  this.buttonDisabled = true;
                  this.sassService.getSubDetails(this.editClientID).subscribe((data) => {
                    this.clientData = data.Result[0];
                    if (this.clientData.StripePlanId) {
                      this.noPlaning = false;
                      this.saasstripeplan = true;
                      // if (this.clientData.StripePlanId === 'price_1H2W2rICi4RDu2OllIjS8nqt') {
                      let saasprice;
                      this.pricingModule.getsubscriptionSAAS(this.clientData.StripePlanId).subscribe((data) => {
                        let price;
                        price = data['Result'];
                        for (const status of price) {
                          if (this.clientData.StripePlanId === status.StripePlanId) {
                            if (status.SubscriptionType === 'month') {
                              saasprice = status.Price;
                              this.yearlyPlan = false;
                            } else {
                              saasprice = status.Price;
                              this.yearlyPlan = true;
                            }
                          }
                        }
                      });
                      this.CustomerService.getAllFilteredCustomers(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        this.editClientID,
                        null,
                        null,
                        null,
                        null
                      ).subscribe((data) => {
                        this.buttonDisabled = false;
                        if (data.Result.length > 0) {
                          data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
                          this.faclityCount = data.Result.length;
                        } else {
                          this.faclityCount = 1;
                        }
                        // this.multipleFGdata = data.Result;
                        // this.dialogRef.close();
                        const dialogRef = this.dialog.open(SaasconfirmComponent, {
                          width: '500px',
                          // height: '440px',
                          data: [
                            { faclityCount: this.faclityCount },
                            { yearlyPlan: this.yearlyPlan },
                            { active: statusdata.Title },
                            {
                              type: this.data[0].customer[0].ClientCategory[0].Title,
                            },
                            { saasPrice: saasprice },
                          ],
                          panelClass: 'custom-editSub-container',
                        });
                        dialogRef.afterClosed().subscribe((result) => {
                          if (result) {
                            // if (this.userRole === 'Facility Group') {
                            //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
                            //     this.userIdStripe = this.state.user.User.UserId;
                            //     this.ClientId = this.state.user['FacilityGroupId'];
                            //   }
                            // }
                            // if (this.userRole === 'Facility') {
                            //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
                            //     this.userIdStripe = this.state.user.User.UserId;
                            //     this.ClientId = this.state.user.User.Clients[0].ClientId;
                            //   }
                            // }
                            // if (this.userRole === 'Admin') {
                            //   this.userIdStripe = null;
                            //   if (this.facilityGroupClient) {
                            //     this.ClientId = this.facilityGroupClient;
                            //   } else {
                            //     this.ClientId = this.data[0].customer[0].ClientId;
                            //   }
                            // }
                            // let payloadStripe;
                            // if (this.userRole === 'Admin') {
                            //   payloadStripe = {
                            //     QuantityChange: true,
                            //     Update: true,
                            //     ClientId: this.ClientId,
                            //   };
                            // } else{
                            //   payloadStripe = {
                            //     QuantityChange: true,
                            //     Update: true,
                            //     ClientId: this.ClientId,
                            //     UserId: this.userIdStripe
                            //   };
                            // }
                            // this.sassService.striping(payloadStripe).subscribe(
                            //   data => {
                            //     if (data.message === 'Updated Successfully') {
                            this.updateDetails();
                            //   } else {
                            //     alert(data.message);
                            //   }
                            //   // }
                            // });
                          }
                        });
                        // }
                      });
                    } else {
                      this.buttonDisabled = false;
                      this.noPlaning = true;
                      // this.updateDetails();
                      this.confirmationMsg('SAASActive');
                    }
                  });
                }
              }
            } else {
              this.updateDetails();
            }
          } else {
            this.updateDetails();
          }
        } else {
          this.updateDetails();
        }
      } else {
        this.updateDetails();
      }
    } else {
      this.updateDetails();
    }
    // if (
    //   this.user[0].Name &&
    //   this.user[0].Address &&
    //   this.user[0].City &&
    //   this.user[0].State &&
    //   this.user[0].ZIP &&
    //   this.user[0].EINNumber
    // ) {
  }
  fgDriverService(event) {
    this.driverSelected = event.value;
    const detaildriver = this.driverServicecustom;
    for (const status of detaildriver) {
      if (status.ID === this.driverSelected) {
        // this.driverSelected = status.ID;
        let fgdriver;
        fgdriver = status.Title;
        if (fgdriver !== 'SAAS') {
          this.faciltatorOption = true;
        } else {
          this.faciltatorOption = false;
        }
      }
    }
  }
  updateDetails() {
    if (this.user[0].Name) {
      if (this.user[0].Address) {
        if (this.pickupCity && this.pickupCity !== 'Select City') {
          if (this.Stateid) {
            // if (this.user[0].ZIP) {
            if (this.zipupdate !== '00000' && this.zipupdate !== 'Select Zip' && this.zipupdate) {
              if (this.user[0].Country) {
                // if (this.user[0].EINNumber) {
                if (this.user[0].Phone) {
                  this.numberForm.controls.number.setValue(numberSetMask(this.user[0].Phone));
                }
                if (this.numberForm.valid) {
                  if (
                    this.data[0].customer[0].ClientCategory[0].Title === 'Facility' ||
                    this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility'
                  ) {
                    // if (this.driverService) {
                    //   for (const status of this.driverService) {
                    //     if (status.Title === 'SAAS') {
                    //       if (this.radioOptionsFacility !== 'FacilityNone') {
                    //         this.driverSelected = status.ID;
                    //         this.driverTitle = status.Title;
                    //       }
                    //     } else {
                    //       // this.driverSelected = status.ID;
                    //       // this.driverTitle = status.Title;
                    //     }
                    //   }
                    // }
                    if (this.radioOptions === 'facilitatorGrp') {
                      let driverser;
                      let fgser = null;
                      if (this.driverService) {
                        for (const status of this.driverService) {
                          if (status.ID === this.driverSelected) {
                            driverser = status.Title;
                          }
                        }
                      }
                      for (const status of this.saaslist) {
                        if (status.ClientId == this.facilityGroupClient) {
                          fgser = status.DriverService;
                        }
                      }
                      if (this.facilityGroupClient) {
                        // this is for check the driver service SAAS or Goodr clients
                        if (this.selectedTypeTitle === 'Facility') {
                          if (driverser === 'SAAS' && fgser === 'SAAS') {
                            this.facilityGroupClient = this.facilityGroupClient;
                            this.finalfacility();
                          } else if (driverser !== 'SAAS' && fgser !== 'SAAS') {
                            this.facilityGroupClient = this.facilityGroupClient;
                            this.finalfacility();
                          } else {
                            this.facilityGroupClient = null;
                            this.facilityGroupClient = this.data[0].customer[0].FacilityGroupId;
                            this.selectClient = true;
                            setTimeout(() => {
                              this.selectClient = false;
                            }, 3000);
                          }
                        } else {
                          this.finalfacility();
                        }
                      } else {
                        this.selectClient = true;
                        setTimeout(() => {
                          this.selectClient = false;
                        }, 3000);
                        // alert('Please select any of the Food Type');
                      }
                    } else if (this.radioOptions === 'facilitator') {
                      if (this.facilitatorClient) {
                        this.finalfacility();
                      } else {
                        this.selectClient = true;
                        setTimeout(() => {
                          this.selectClient = false;
                        }, 3000);
                        // alert('Please select any of the Food Type');
                      }
                    } else {
                      this.finalfacility();
                    }
                  } else if (this.data[0].customer[0].ClientCategory[0].Title === 'MRF') {
                    let valid_counts = 0;
                    let i = 1;
                    // this.NonProfitWorkingSlots = [];
                    // if (this.WorkingDaysID.length > 0 || this.NonProfitWorkingSlots.length > 0) {
                    //   this.WorkingDaysID.map(value => {
                    //     if (value['checkedId'] === true) {
                    //       if (value.StartTime && value.EndTime) {
                    //         // this.NonProfitWorkingSlots.push(value);
                    //       } else {
                    //         value['showValid'] = true;
                    //         valid_counts = i++;
                    //         setTimeout(() => {
                    //           value['showValid'] = false;
                    //         }, 3000);
                    //       }
                    //     }
                    //   }
                    //   );

                    // if (this.workingBooln === false && this.NonProfitWorkingSlots.length > 0) {
                    //   this.updateUserDetails();
                    // } else if (this.WorkingDaysID.length > 0) {
                    this.WorkingDaysID.map((value) => {
                      if (value['checkedId'] === true) {
                        if (value.StartTime && value.EndTime) {
                          // this.NonProfitWorkingSlots.push(value);
                        } else {
                          value['showValid'] = true;
                          valid_counts = i++;
                          setTimeout(() => {
                            value['showValid'] = false;
                          }, 3000);
                        }
                      }
                    });
                    if (valid_counts === 0) {
                      if (this.SeparatedCompostID[0]) {
                        this.updateUserDetails();
                      } else {
                        this.materialValid = true;
                        setTimeout(() => {
                          this.materialValid = false;
                        }, 3000);
                      }
                    }
                    // }
                    // else {
                    //   this.NonProfitWorkingValid = true;
                    //   setTimeout(() => {
                    //     this.NonProfitWorkingValid = false;
                    //   }, 3000);
                    // }

                    // } else {
                    //   this.NonProfitWorkingValid = true;
                    //   setTimeout(() => {
                    //     this.NonProfitWorkingValid = false;
                    //   }, 3000);
                    // }
                  } else if (this.data[0].customer[0].ClientCategory[0].Title === 'Non Profit') {
                    let valid_counts = 0;
                    let i = 1;
                    // this.NonProfitWorkingSlots = [];
                    // if (this.WorkingDaysID.length > 0 || this.NonProfitWorkingSlots.length > 0) {
                    //   this.WorkingDaysID.map(value => {
                    //     if (value['checkedId'] === true) {
                    //       if (value.StartTime && value.EndTime) {
                    //         // this.NonProfitWorkingSlots.push(value);
                    //       } else {
                    //         value['showValid'] = true;
                    //         valid_counts = i++;
                    //         setTimeout(() => {
                    //           value['showValid'] = false;
                    //         }, 3000);
                    //       }
                    //     }
                    //   }
                    //   );
                    if (
                      this.NumberOfPeopleServed &&
                      this.NumberOfPeopleServed !== undefined &&
                      this.NumberOfPeopleServed !== null &&
                      this.NumberOfPeopleServed > 0
                    ) {
                      // if (this.workingBooln === false && this.NonProfitWorkingSlots.length > 0) {
                      //   this.updateUserDetails();
                      // } else if (this.WorkingDaysID.length > 0) {
                      this.WorkingDaysID.map((value) => {
                        if (value['checkedId'] === true) {
                          if (value.StartTime && value.EndTime) {
                            // this.NonProfitWorkingSlots.push(value);
                          } else {
                            value['showValid'] = true;
                            valid_counts = i++;
                            setTimeout(() => {
                              value['showValid'] = false;
                            }, 3000);
                          }
                        }
                      });
                      if (valid_counts === 0) {
                        this.updateUserDetails();
                      }
                      // }
                      // else {
                      //   this.NonProfitWorkingValid = true;
                      //   setTimeout(() => {
                      //     this.NonProfitWorkingValid = false;
                      //   }, 3000);
                      // }
                    } else {
                      this.peoplesValid = true;
                      setTimeout(() => {
                        this.peoplesValid = false;
                      }, 3000);
                    }
                    // } else {
                    //   this.NonProfitWorkingValid = true;
                    //   setTimeout(() => {
                    //     this.NonProfitWorkingValid = false;
                    //   }, 3000);
                    // }
                  } else if (
                    this.data[0].customer[0].ClientCategory[0].Title === 'Facility Group' ||
                    this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group'
                  ) {
                    // if(this.data[0].customer[0].ClientCategory[0].Title ===
                    //   'SAAS Facility Group'){
                    //     if (this.driverService) {
                    //       for (const status of this.driverService) {
                    //         if (status.Title === 'Goodr') {
                    //           if (this.radioOptionsFG === 'FGNone') {
                    //             this.driverSelected = status.ID;
                    //             this.driverTitle = status.Title;
                    //           }
                    //         } else if (status.Title === 'SAAS') {
                    //           if (this.radioOptionsFG !== 'FGNone') {
                    //             this.driverSelected = status.ID;
                    //             this.driverTitle = status.Title;
                    //           }
                    //         }
                    //       }
                    //     }
                    // }
                    if (this.radioOptionsFG === 'FGNone') {
                      if (this.radioOptionsFR === 'facilitatorGrpFR') {
                        if (this.facilitatorClient) {
                          this.updateUserDetails();
                        } else {
                          this.selectfacClient = true;
                          setTimeout(() => {
                            this.selectfacClient = false;
                          }, 3000);
                          // alert('Please select any of the Food Type');
                        }
                      } else {
                        this.updateUserDetails();
                      }
                    } else {
                      this.updateUserDetails();
                    }
                  } else {
                    this.updateUserDetails();
                  }
                } else {
                  this.requirednumber = true;
                  setTimeout(() => {
                    this.requirednumber = false;
                  }, 3000);
                }
                // } else {
                //   this.requireFieldTax = true;
                //   setTimeout(() => {
                //     this.requireFieldTax = false;
                //   }, 3000);
                // }
              } else {
                this.requireFieldcountry = true;
                setTimeout(() => {
                  this.requireFieldcountry = false;
                }, 3000);
              }
            } else {
              this.requireFieldZip = true;
              setTimeout(() => {
                this.requireFieldZip = false;
              }, 3000);
            }
          } else {
            this.requireFieldState = true;
            setTimeout(() => {
              this.requireFieldState = false;
            }, 3000);
          }
        } else {
          this.requireFieldCity = true;
          setTimeout(() => {
            this.requireFieldCity = false;
          }, 3000);
        }
      } else {
        this.requireFieldAddress = true;
        setTimeout(() => {
          this.requireFieldAddress = false;
        }, 3000);
      }
    } else {
      this.requireField = true;
      setTimeout(() => {
        this.requireField = false;
      }, 3000);
    }
  }
  finalfacility() {
    if (this.NotificationTypesID[0]) {
      if (this.pickupTypeID[0]) {
        for (const status of this.driverService) {
          if (this.driverSelected === status.ID) {
            if (status.Title === 'Roadie') {
              for (var j = 0; j < this.PickupTypeS.length; j++) {
                for (var i = 0; i < this.pickupTypeID.length; i++) {
                  if (this.PickupTypeS[j].ID === this.pickupTypeID[i]) {
                    if (this.PickupTypeS[j].Description === 'Organic Recycling Pickup Type') {
                      this.validRoadiesTrue = true;
                    } else {
                      this.validRoadiesTrue = false;
                    }
                  }
                }
              }
              if (this.validRoadiesTrue === true) {
                this.selectRoadiesValidation = true;
                this.pickupTypeID = [];
                for (const status of this.PickupTypeS) {
                  if (status.Title === 'Food') {
                    this.pickupTypeID.push(status.ID);
                  }
                }
                for (var j = 0; j < this.PickupTypeS.length; j++) {
                  for (var i = 0; i < this.pickupTypeID.length; i++) {
                    if (this.pickupTypeID[i] == this.PickupTypeS[j].ID) {
                      this.PickupTypeS[j]['checkedId'] = true;
                    } else {
                      this.PickupTypeS[j]['checkedId'] = false;
                    }
                  }
                }
                setTimeout(() => {
                  this.selectRoadiesValidation = false;
                  this.accountdetails();
                }, 3000);
              } else {
                this.accountdetails();
              }
            } else {
              this.accountdetails();
            }
          }
        }
      } else {
        this.selectPickupValidation = true;
        setTimeout(() => {
          this.selectPickupValidation = false;
        }, 3000);
        // alert('Please select any of the Food Type');
      }
    } else {
      this.selectPickupNotfication = true;
      setTimeout(() => {
        this.selectPickupNotfication = false;
      }, 3000);
      // alert('Please select any of the Food Type');
    }
  }
  accountdetails() {
    if (this.driverSelected) {
      for (const status of this.driverService) {
        if (this.driverSelected === status.ID) {
          if (status.Title === 'SAAS') {
            if (this.selectedDriver) {
              if (this.data[0].customer[0].MenuType.length) {
                if (this.data[0].customer[0].MenuType[0].Title === 'Grab') {
                  if (this.user[0].WayPointID) {
                    this.updateUserDetails();
                  } else {
                    this.requireFieldWayPoint = true;
                    setTimeout(() => {
                      this.requireFieldWayPoint = false;
                    }, 3000);
                  }
                } else {
                  this.updateUserDetails();
                }
              } else {
                this.updateUserDetails();
              }
            } else {
              this.requireFieldDriver = true;
              setTimeout(() => {
                this.requireFieldDriver = false;
              }, 3000);
              // this.selectedDriver = this.drivers[0].Id;
              // this.updateUserDetails();
            }
          } else {
            this.selectedDriver = null;
            if (this.data[0].customer[0].MenuType.length > 0) {
              if (this.data[0].customer[0].MenuType[0].Title === 'Grab') {
                if (this.user[0].WayPointID) {
                  this.updateUserDetails();
                } else {
                  this.requireFieldWayPoint = true;
                  setTimeout(() => {
                    this.requireFieldWayPoint = false;
                  }, 3000);
                }
              } else {
                this.updateUserDetails();
              }
            } else {
              this.updateUserDetails();
            }
          }
        }
      }
    }
  }
  getnonprofitAuto() {
    this.spinnerRef = this.spinnerService.start(true);

    this.customerServices.getClientsProducttDetail(this.data[0].customer[0].ClientId).subscribe((data) => {
      this.spinnerService.stop(this.spinnerRef);

      this.NonProfitWorkingSlots = data.Result;
      for (var j = 0; j < this.workingdays.length; j++) {
        for (var i = 0; i < this.NonProfitWorkingSlots.length; i++) {
          if (this.NonProfitWorkingSlots[i].WorkingDay == this.workingdays[j].WorkingDay) {
            // future reference code
            // var zipTimezones = require('zipcode-to-timezone');
            // const zipLookup = zipTimezones.lookup(
            //   this.data[0].customer[0].ZIP
            // );
            // console.log(zipLookup);
            this.workingdays[j]['StartTime'] = moment
              .utc(this.NonProfitWorkingSlots[i].StartTime)
              .clone()
              .tz(this.timezone)
              .format('HH:mm')
              .toString();
            this.workingdays[j]['EndTime'] = moment.utc(this.NonProfitWorkingSlots[i].EndTime).clone().tz(this.timezone).format('HH:mm').toString();
            // let startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            // this.workingdays[j]['StartTime'] = this.dateFormat.transform(moment(startDate).format().toString().split('T')[0] + 'T' +
            //   moment(startDate).add(this.NonProfitWorkingSlots[i].StartTime.split(':')[0], 'hours').add(this.NonProfitWorkingSlots[i]
            //     .StartTime.split(':')[1], 'minutes').format().toString().split('T')[1].split('+')[0].split('-')[0]);
            // this.workingdays[j]['EndTime'] = this.dateFormat.transform(moment(startDate).format().toString().split('T')[0] + 'T' +
            //   moment(startDate).add(this.NonProfitWorkingSlots[i].EndTime.split(':')[0], 'hours').add(this.NonProfitWorkingSlots[i]
            //     .EndTime.split(':')[1], 'minutes').format().toString().split('T')[1].split('+')[0].split('-')[0]);
            // moment(this.NonProfitWorkingSlots[i].EndTime, ["HH.mm"]).format("hh:mm a");
            this.workingdays[j]['checkedId'] = true;
          }
        }
      }
      this.workingdays.map((value) => {
        if (value['checkedId'] === true) {
          this.WorkingDaysID.push(value);
        }
      });
    });
  }

  selectTimePopup(value) {
    const dialogRef = this.dialog.open(TimeSelectDialogComponent, {
      width: '650px',
      // height: '500px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ value }],
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let upadtedWorkingDay;
        this.workingdays.map((val) => {
          if (val.WorkingDay === result.WorkingDay) {
            val.StartTime = result.From;
            val.EndTime = result.To;
            val.checkedId = true;
            upadtedWorkingDay = val.datas;
          }
        });

        const found = this.WorkingDaysID.some((el) => el.WorkingDay == result.WorkingDay);

        if (!found) {
          let newWorkingDay = {
            StartTime: result.From,
            EndTime: result.To,
            checkedId: true,
            WorkingDay: result.WorkingDay,
            datas: upadtedWorkingDay,
          };

          this.WorkingDaysID.push(newWorkingDay);
        }

        this.WorkingDaysID.map((valData) => {
          if (valData.WorkingDay === result.WorkingDay) {
            valData.StartTime = result.From;
            valData.EndTime = result.To;
          }
        });
      }
    });
  }
  updateUserDetails() {
    if (this.user[0].Phone === null) {
      this.number = this.user[0].Phone;
    } else {
      this.number = this.user[0].Phone.replace(/[_-]/g, '');
    }
    if (this.user[0].EINNumber === null) {
      this.taxId = this.user[0].EINNumber;
    } else {
      this.taxId = this.user[0].EINNumber.replace(/[_-]/g, '');
    }
    let fgdriver;
    if (this.selectedTypeTitle === 'Facility Group') {
      for (const status of this.driverService) {
        if (status.ID === this.driverSelected) {
          // this.driverSelected = status.ID;
          fgdriver = status.Title;
        }
      }
      if (fgdriver !== 'SAAS') {
        this.facilitatorClient = this.facilitatorClient;
      } else {
        this.facilitatorClient = null;
      }
    }
    // this.selectedType = this.user[0].ClientCategory[0].ClientCategoryId;
    // if (this.user[0].DriverServices.length > 0) {
    //   this.DriverServices = this.user[0].DriverServices[0].ID;
    // }
    let user_Client = [];
    if (this.selectedTypeMultiple) {
      let missing = this.selectedUsers.filter((item) => this.selectedTypeMultiple.indexOf(item.Id) < 0);
      //  let user_Client = [];
      //  if (this.facilityRole === true) {
      for (var i = 0; i < this.selectedTypeMultiple.length; i++) {
        user_Client.push({
          Id: this.selectedTypeMultiple[i],
          NotifyUser: true,
        });
      }
      for (var i = 0; i < missing.length; i++) {
        user_Client.push({
          Id: missing[i].Id,
          NotifyUser: false,
        });
      }

      if (this.noNotifyUser === true) {
        const finaluserClient = [];
        for (var i = 0; i < user_Client.length; i++) {
          finaluserClient.push({
            Id: user_Client[i].Id,
            NotifyUser: false,
          });
        }
        user_Client = finaluserClient;
      }
    }
    if (this.pickupTypeID) {
      var sendpickupTypes = [];
      for (var i = 0; i < this.pickupTypeID.length; i++) {
        sendpickupTypes.push({ ID: this.pickupTypeID[i] });
      }
      this.sendpickupTypes = sendpickupTypes;
    }
    if (this.NotificationTypes) {
      for (var j = 0; j < this.NotificationTypes.length; j++) {
        if (this.NotificationTypesID) {
          for (var i = 0; i < this.NotificationTypesID.length; i++) {
            // this.pickupTypeID = this.info.pickupTypes;
            if (this.NotificationTypesID[i] == this.NotificationTypes[j].ID) {
              if (this.NotificationTypes[j].Title !== 'NotifyUser') {
                this.noNotifyUser = true;
              } else {
                this.noNotifyUser = false;
              }
            }
          }
        }
      }
      var notifiactionTypes = [];
      if (this.NotificationTypesID) {
        for (var i = 0; i < this.NotificationTypesID.length; i++) {
          notifiactionTypes.push({ ID: this.NotificationTypesID[i] });
        }
      }
      this.notifiactionTypes = notifiactionTypes;
    }
    var MRFmaterialdata = [];
    for (var i = 0; i < this.SeparatedCompostID.length; i++) {
      MRFmaterialdata.push({ ID: this.SeparatedCompostID[i] });
    }
    // var zipTimezones = require('zipcode-to-timezone');
    // const zipLookup = zipTimezones.lookup(this.user[0].ZIP);
    let payload = {
      ClientId: this.user[0].ClientId,
      ClientCategories: [
        {
          ClientCategoryId: this.selectedType,
        },
      ],
      Name: this.user[0].Name,
      PickupType: this.sendpickupTypes,
      NotificationTypes: this.notifiactionTypes,
      Phone: this.number,
      Address: this.user[0].Address,
      Address2: this.user[0].Address2,
      City: this.user[0].City,
      State: this.user[0].State,
      CityId: this.pickupCity,
      StateId: this.Stateid,
      ZIP: this.zipupdate.replace(/\s/g, ''),
      Country: this.user[0].Country,
      Email: this.user[0].Email,
      Web: this.user[0].Web,
      EINNumber: this.taxId,
      DriverNotes: this.user[0].DriverNotes,
      ContactId: this.user[0].ContactId,
      HasFridgeStorage: this.user[0].HasFridgeStorage,
      NumberOfPeopleServed: this.NumberOfPeopleServed,
      WasteExpenditure: this.user[0].WasteExpenditure,
      ClientTypeId: this.user[0].ClientTypeId,
      TimeZone: this.timezone,
      ApprovalStatusId: this.statusSelected,
      SeparatedCompost: MRFmaterialdata,
      // WayPointID: 0,
      WayPointID: this.user[0].WayPointID,
      StripeCustomerId: this.user[0].StripeCustomerId,
      StripePlanId: this.user[0].StripePlanId,
      StripeSubscriptionId: this.user[0].StripeSubscriptionId,
      WP_Client_Id: 0,
      MenuEdit: this.MenuEditFlag,
      InviteUser: this.facilityAccessFlag,
      HoldHarmlessAgreement: this.AgreementFlag,
      Logo: this.uploadImage,
      ImageUrl: this.user[0].ImageUrl,
      FacilitatorId: this.facilitatorClient,
      FacilityGroupId: this.facilityGroupClient,
      MenuTypeId: this.user[0].MenuTypeId,
      CreatedOn: this.user[0].CreatedOn,
      CreatedBy: this.user[0].CreatedBy,

      // "ClientCategories":this.user[0].ClientCategory[0].Title,
      // ClientCategories: this.user,
      DriverServices: [
        {
          ID: this.driverSelected,
        },
      ],
      User_Client: user_Client,
      SAASDriverId: this.selectedDriver,
      AllowSchedulePickup: this.scheduler,
    };
    this.buttonDisabled = true;
    if (this.data[0].customer[0].ClientCategory[0].Title === 'Non Profit' || this.data[0].customer[0].ClientCategory[0].Title === 'MRF') {
      let request = [];
      this.WorkingDaysID.map((selvalue) => {
        this.NonProfitWorkingSlots.map((NP) => {
          if (selvalue.WorkingDay === NP.WorkingDay) {
            selvalue.ID = NP.ID;
          }
        });
      });
      request = this.WorkingDaysID.map((x) => Object.assign({}, x));
      // var zipTimezones = require('zipcode-to-timezone');
      // const zipLookup = zipTimezones.lookup(this.user[0].ZIP);
      // console.log(zipLookup);
      for (let i = 0; i < request.length; i++) {
        let datatime = request[i];
        var m = moment.tz(moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.StartTime, this.timezone);
        m.format();
        datatime.StartTime = m.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
        var n = moment.tz(moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime, this.timezone);
        n.format();
        datatime.EndTime = n.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
      }
      for (let i = 0; i < request.length; i++) {
        let dataFinal = request[i];
        if (dataFinal.ID === null || dataFinal.ID === undefined) {
          let payloadNP = {
            ClientId: this.data[0].customer[0].ClientId,
            WorkingDay: dataFinal.WorkingDay,
            StartTime: dataFinal.StartTime,
            // moment
            //   .tz(
            //     moment(new Date()).format('YYYY-MM-DD') +
            //       'T' +
            //       dataFinal.StartTime,
            //     zipLookup
            //   )
            //   .utc()
            //   .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00'),
            // moment(new Date()).format('YYYY-MM-DD') +
            // 'T' +
            // this.ConvertTime(dataFinal.StartTime),
            EndTime: dataFinal.EndTime,

            // moment
            //   .tz(
            //     moment(new Date()).format('YYYY-MM-DD') +
            //       'T' +
            //       dataFinal.EndTime,
            //     zipLookup
            //   )
            //   .utc()
            //   .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00')
            // moment(new Date()).format('YYYY-MM-DD') +
            // 'T' +
            // this.ConvertTime(dataFinal.EndTime)
          };

          this.accountService.NonProfitWorkingSeletion(payloadNP).subscribe((data) => {});
        }
      }
      for (let i = 0; i < request.length; i++) {
        let dataPut = request[i];
        if (dataPut.ID) {
          let payloadNP = {
            ClientId: this.data[0].customer[0].ClientId,
            ID: dataPut.ID,
            WorkingDay: dataPut.WorkingDay,
            StartTime: dataPut.StartTime,
            // moment
            //   .tz(
            //     moment(new Date()).format('YYYY-MM-DD') +
            //       'T' +
            //       dataPut.StartTime,
            //     zipLookup
            //   )
            //   .utc()
            //   .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00'),
            // moment(new Date()).format('YYYY-MM-DD') +
            // 'T' +
            // this.ConvertTime(dataPut.StartTime),
            EndTime: dataPut.EndTime,

            // moment
            // moment
            //   .tz(
            //     moment(new Date()).format('YYYY-MM-DD') + 'T' + dataPut.EndTime,
            //     zipLookup
            //   )
            //   .utc()
            //   .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00')
            // moment(new Date()).format('YYYY-MM-DD') +
            // 'T' +
            // this.ConvertTime(dataPut.EndTime)
          };
          // this.dialogRef.close('res');
          this.accountService.addNonProfitWorkingSeletion(payloadNP).subscribe((data) => {});
        }
      }
      let delNP = [];
      delNP = this.NonProfitWorkingSlots.filter((obj) => this.WorkingDaysID.every((s) => s.WorkingDay !== obj.WorkingDay));
      for (let i = 0; i < delNP.length; i++) {
        let dataDel = delNP[i];
        if (dataDel.ID) {
          this.accountService.deleteNonProfitWorkingSeletion(dataDel.ID).subscribe((data) => {});
        }
      }
    }
    const detail = this.allDeliveryStatus;
    for (const status of detail) {
      if (status.ClientCategoryId === this.selectedType) {
        this.selectedTypeTitle = status.Title;
      }
    }
    if (
      this.data[0].customer[0].ClientCategory[0].Title === 'Facility Group' ||
      this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility Group'
    ) {
      for (const status of this.driverService) {
        if (status.ID === this.driverSelected) {
          this.driverTitle = status.Title;
        }
      }
      if (
        this.selectedTypeTitle === this.data[0].customer[0].ClientCategory[0].Title &&
        this.apprStatus === this.data[0].customer[0].ApprovalStatus[0].Title
      ) {
        this.customerUpdate(payload);
      } else if (this.selectedTypeTitle !== this.data[0].customer[0].ClientCategory[0].Title) {
        if (this.selectedTypeTitle === 'SAAS Facility Group' && this.apprStatus === 'Active') {
          this.paymentmethod(payload);
        } else {
          let payloadsaas;
          payloadsaas = {
            SaasToNormal: true,
            ClientId: this.user[0].ClientId,
          };
          this.accountService.saasUpdate(payloadsaas).subscribe((data) => {
            if (data.Result) {
              let payloadassign = Object.assign({}, data.Result[0]);
              payload.StripeCustomerId = payloadassign.StripeCustomerId;
              payload.StripePlanId = payloadassign.StripePlanId;
              payload.StripeSubscriptionId = payloadassign.StripeSubscriptionId;
              this.customerUpdate(payload);
            } else {
              alert(data.message);
              this.buttonDisabled = false;
            }
          });
        }
      } else if (this.statusSelected !== this.data[0].customer[0].ApprovalStatus[0].ID && this.selectedTypeTitle === 'SAAS Facility Group') {
        if (this.apprStatus === 'Active') {
          this.paymentmethod(payload);
        } else {
          this.customerUpdate(payload);
        }
      } else {
        this.customerUpdate(payload);
      }
    }

    //   if (this.goodrSelected !== this.driverTitle) {
    //     if (
    //       this.driverTitle === 'SAAS' &&
    //       this.selectedTypeTitle === 'SAAS Facility Group'
    //     ) {
    //       console.log(this.driverTitle);
    //       this.paymentmethod(payload);
    //     } else if (
    //       this.data[0].customer[0].ClientCategory[0].Title ===
    //       'SAAS Facility Group'
    //     ) {
    //       let payloadsaas;
    //       payloadsaas = {
    //         SaasToNormal: true,
    //         ClientId: this.user[0].ClientId
    //       };
    //       this.accountService.saasUpdate(payloadsaas).subscribe(data => {
    //         if (data.Result) {
    //           let payloadassign = Object.assign({}, data.Result[0]);
    //           payload.StripeCustomerId = payloadassign.StripeCustomerId;
    //           payload.StripePlanId = payloadassign.StripePlanId;
    //           payload.StripeSubscriptionId = payloadassign.StripeSubscriptionId;
    //           this.customerUpdate(payload);
    //         } else {
    //           alert(data.message);
    //           this.buttonDisabled = false;
    //         }
    //       });
    //     } else {
    //       this.customerUpdate(payload);
    //     }
    //   } else if (
    //     this.apprStatus === 'Active' &&
    //     this.noPlaning === true &&
    //     this.driverTitle === 'SAAS'
    //   ) {
    //     this.paymentmethod(payload);
    //   } else {
    //     this.customerUpdate(payload);
    //   }
    // }
    else if (
      this.data[0].customer[0].ClientCategory[0].Title === 'Facility' ||
      this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility'
    ) {
      for (const status of this.driverService) {
        if (status.ID === this.driverSelected) {
          this.driverTitle = status.Title;
        }
      }

      // if (this.goodrSelected !== this.driverTitle) {
      // if (
      //   this.statusSelected !== this.data[0].customer[0].ApprovalStatus[0].ID
      // ) {
      //   this.customerUpdate(payload);
      // } else {
      // if (
      //   this.driverTitle === 'SAAS' &&
      //   this.selectedTypeTitle === 'SAAS Facility' &&
      //   (this.facilityGroupClient === null ||
      //     !this.facilityGroupClient ||
      //     this.facilityGroupClient === undefined)
      // ) {
      //   console.log(this.selectedTypeTitle);
      //   console.log(this.apprStatus);
      //   this.paymentmethod(payload);
      // } else if (
      //   this.driverTitle === 'SAAS' &&
      //   this.selectedTypeTitle === 'SAAS Facility' &&
      //   this.facilityGroupClient != this.data[0].customer[0].FacilityGroupId
      // ) {
      //   let payloadsaas;
      //   payloadsaas = {
      //     NormalToSaas: true,
      //     ClientId: this.user[0].ClientId,
      //     SAASFGId: this.facilityGroupClient
      //   };
      //   this.accountService.saasUpdate(payloadsaas).subscribe(data => {
      //     if (data.Result) {
      //       let payloadassign = Object.assign({}, data.Result[0]);
      //       this.driverSelected = data.Result[0].DriverServices[0].ID;
      //       payload.StripeCustomerId = payloadassign.StripeCustomerId;
      //       payload.StripePlanId = payloadassign.StripePlanId;
      //       payload.StripeSubscriptionId = payloadassign.StripeSubscriptionId;
      //       this.customerUpdate(payload);
      //     } else {
      //       alert(data.message);
      //       this.buttonDisabled = false;
      //     }
      //   });
      // } else
      if (
        this.selectedTypeTitle === this.data[0].customer[0].ClientCategory[0].Title &&
        this.apprStatus === this.data[0].customer[0].ApprovalStatus[0].Title
      ) {
        this.customerUpdate(payload);
      } else if (this.selectedTypeTitle !== this.data[0].customer[0].ClientCategory[0].Title) {
        // facility to saas facility convertion
        if (this.selectedTypeTitle === 'SAAS Facility' && this.apprStatus === 'Active') {
          if (this.facilityGroupClient === null || !this.facilityGroupClient || this.facilityGroupClient === undefined) {
            this.paymentmethod(payload);
          } else if (this.facilityGroupClient != this.data[0].customer[0].FacilityGroupId) {
            let payloadsaas;
            payloadsaas = {
              NormalToSaas: true,
              ClientId: this.user[0].ClientId,
              SAASFGId: this.facilityGroupClient,
            };
            this.accountService.saasUpdate(payloadsaas).subscribe((data) => {
              if (data.Result) {
                let payloadassign = Object.assign({}, data.Result[0]);
                this.driverSelected = data.Result[0].DriverServices[0].ID;
                payload.StripeCustomerId = payloadassign.StripeCustomerId;
                payload.StripePlanId = payloadassign.StripePlanId;
                payload.StripeSubscriptionId = payloadassign.StripeSubscriptionId;
                this.customerUpdate(payload);
              } else {
                alert(data.message);
                this.buttonDisabled = false;
              }
            });
          } else {
          }
        }
        if (this.selectedTypeTitle === 'Facility') {
          let payloadsaas;
          payloadsaas = {
            SaasToNormal: true,
            ClientId: this.user[0].ClientId,
          };
          this.accountService.saasUpdate(payloadsaas).subscribe((data) => {
            if (data.Result) {
              let payloadassign = Object.assign({}, data.Result[0]);
              payload.StripeCustomerId = payloadassign.StripeCustomerId;
              payload.StripePlanId = payloadassign.StripePlanId;
              payload.StripeSubscriptionId = payloadassign.StripeSubscriptionId;
              this.customerUpdate(payload);
            } else {
              alert(data.message);
              this.buttonDisabled = false;
            }
          });
        }
      } else if (this.apprStatus !== this.data[0].customer[0].ApprovalStatus[0].Title) {
        if (this.apprStatus === 'Active') {
          if (this.selectedTypeTitle === 'SAAS Facility') {
            if (this.facilityGroupClient === null || !this.facilityGroupClient || this.facilityGroupClient === undefined) {
              this.paymentmethod(payload);
            } else if (this.facilityGroupClient != this.data[0].customer[0].FacilityGroupId) {
              let payloadsaas;
              payloadsaas = {
                NormalToSaas: true,
                ClientId: this.user[0].ClientId,
                SAASFGId: this.facilityGroupClient,
              };
              this.accountService.saasUpdate(payloadsaas).subscribe((data) => {
                if (data.Result) {
                  let payloadassign = Object.assign({}, data.Result[0]);
                  this.driverSelected = data.Result[0].DriverServices[0].ID;
                  payload.StripeCustomerId = payloadassign.StripeCustomerId;
                  payload.StripePlanId = payloadassign.StripePlanId;
                  payload.StripeSubscriptionId = payloadassign.StripeSubscriptionId;
                  this.customerUpdate(payload);
                } else {
                  alert(data.message);
                  this.buttonDisabled = false;
                }
              });
            } else {
              this.customerUpdate(payload);
            }
          } else {
            this.customerUpdate(payload);
          }
        } else {
          this.customerUpdate(payload);
        }
      }

      //  if (
      //   this.selectedTypeTitle === 'Facility' &&
      //   this.data[0].customer[0].ClientCategory[0].Title === 'SAAS Facility'
      // ) {
      //   let payloadsaas;
      //   payloadsaas = {
      //     SaasToNormal: true,
      //     ClientId: this.user[0].ClientId
      //   };
      //   this.accountService.saasUpdate(payloadsaas).subscribe(data => {
      //     if (data.Result) {
      //       let payloadassign = Object.assign({}, data.Result[0]);
      //       payload.StripeCustomerId = payloadassign.StripeCustomerId;
      //       payload.StripePlanId = payloadassign.StripePlanId;
      //       payload.StripeSubscriptionId = payloadassign.StripeSubscriptionId;
      //       this.customerUpdate(payload);
      //     } else {
      //       alert(data.message);
      //       this.buttonDisabled = false;
      //     }
      //   });
      // }
      // //    else  if (this.driverTitle === 'SAAS' && this.facilityGroupClient === null || !this.facilityGroupClient ||
      // //   this.facilityGroupClient === undefined) {
      // //   this.paymentmethod(payload);
      // // }
      // else if (
      //   this.selectedTypeTitle === 'Facility' &&
      //   this.facilityGroupClient === null &&
      //   this.facilityNo === false
      // ) {
      //   console.log(this.selectedTypeTitle);
      //   this.paymentmethod(payload);
      // } else if (
      //   this.apprStatus === 'Active' &&
      //   this.noPlaning === true &&
      //   this.driverTitle === 'SAAS'
      // ) {
      //   console.log(this.selectedTypeTitle);
      //   this.paymentmethod(payload);
      // } else {
      //   console.log(this.selectedTypeTitle);
      //   this.customerUpdate(payload);
      // }
      // }
      // if (this.apprStatus === 'Active' && this.noPlaning === true) {
      //   this.paymentmethod(payload);
      // } else {
      //   console.log(this.data[0].customer[0].ClientCategory[0].Title)
      //   this.customerUpdate(payload);
      // }
    } else {
      this.customerUpdate(payload);
    }
  }
  paymentmethod(payload) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.updateinfo.SAASupdate = payload;
    this.updateinfo.updateclient = true;
    this.updateinfo.drivertitle = this.data[0].customer[0].ClientCategory[0].Title;
    this.dialogRef.close(this.modalclose);
    this._rdata.updateclientBasicInfo2(JSON.stringify(this.updateinfo));
    const dialogRef = this.dialog.open(EditMYearlyComponent, {
      width: '700px',
      height: '690px',
      data: {},
      panelClass: 'custom-editdialog-container',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((selection) => {});
  }
  customerUpdate(payload) {
    //Spinner Implementation
    this.spinnerRef = this.spinnerService.start(true);

    this.CustomerService.updateCustomerDetails(payload).subscribe(
      (data) => {
        this.spinnerService.stop(this.spinnerRef);
        if (this.data[0].customer[0].ClientCategory[0].Title === 'Facility') {
          this.info.facilitator = '';
          this.info.facilitatorGroup = '';
          this.info.facility = '';
          this.info.nonprofits = '';
          this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
          this.getClients();
        }
        if (this.userRole === 'Facility Group') {
          if (this.state.user.User.Clients[0].DriverService === 'SAAS') {
            this.userIdStripe = this.state.user.User.UserId;
            this.ClientId = this.state.user['FacilityGroupId'];
          }
        }
        if (this.userRole === 'Facility') {
          if (this.state.user.User.Clients[0].DriverService === 'SAAS') {
            this.userIdStripe = this.state.user.User.UserId;
            this.ClientId = this.state.user.User.Clients[0].ClientId;
          }
        }
        if (this.userRole === 'Admin') {
          this.userIdStripe = null;
          if (this.facilityGroupClient) {
            this.ClientId = this.facilityGroupClient;
          } else {
            this.ClientId = this.data[0].customer[0].ClientId;
          }
        }
        if (this.userRole === 'Facility Group' || this.userRole === 'Facility' || (this.userRole === 'Admin' && this.saasstripeplan === true)) {
          if (this.statusSelected !== this.data[0].customer[0].ApprovalStatus[0].ID) {
            let payloadStripe;
            if (this.data[0].customer[0].ApprovalStatus[0].Title === 'Active') {
              if (this.userRole === 'Admin') {
                payloadStripe = {
                  CancelSubscription: true,
                  Update: true,
                  ClientId: this.ClientId,
                };
              } else {
                payloadStripe = {
                  CancelSubscription: true,
                  Update: true,
                  ClientId: this.ClientId,
                  UserId: this.userIdStripe,
                };
              }
            } else {
              if (this.userRole === 'Admin') {
                payloadStripe = {
                  QuantityChange: true,
                  Update: true,
                  ClientId: this.ClientId,
                };
              } else {
                payloadStripe = {
                  QuantityChange: true,
                  Update: true,
                  ClientId: this.ClientId,
                  UserId: this.userIdStripe,
                };
              }
            }
            this.sassService.striping(payloadStripe).subscribe((data) => {
              // }
            });
          } else if (this.facilityGroupClient != this.data[0].customer[0].FacilityGroupId && this.driverTitle === 'SAAS') {
            let payloadStripe;
            if (this.userRole === 'Admin') {
              payloadStripe = {
                QuantityChange: true,
                Update: true,
                ClientId: this.ClientId,
              };
            } else {
              payloadStripe = {
                QuantityChange: true,
                Update: true,
                ClientId: this.ClientId,
                UserId: this.userIdStripe,
              };
            }
            this.sassService.striping(payloadStripe).subscribe((data) => {
              // }
            });
          }
        }
        // }
        let localUser = JSON.parse(localStorage.getItem('user'));
        for (let i = 0; i < localUser.user.User.Clients.length; i++) {
          if (localUser.user.User.Clients[i].ClientId === data['Result'][0].ClientId) {
            localUser.user.User.Clients.splice(i, 1);
            break;
          }
        }
        localUser.user.User.Clients.push(data['Result'][0]);
        localStorage.setItem('user', JSON.stringify(localUser));
        this.state.user = JSON.parse(localStorage.getItem('user')).user;
        this.dialogRef.close();
        this.state.isSpinnerVisible = false;
        // const res = 'res';
        const res = [
          {
            res: 'res',
            NP: this.data[0].customer[0].ClientCategory[0].Title === 'Non Profit' || this.data[0].customer[0].ClientCategory[0].Title === 'MRF',
          },
        ];
        this.dialogRef.close(res);
      },
      (error) => {
        this.dialogRef.close();
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        this.buttonDisabled = false;
      }
    );
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }
  // get nonpagination clients
  getClients() {
    this.AdminService.getClientwithoutPagination().subscribe(
      (clients) => {
        let facilityGroupClient;
        const facilitator = clients.Result.filter((client) => {
          return client.ClientType === 'Facilitator';
        });
        this.facilitatorClients = facilitator;
        facilityGroupClient = clients.Result.filter((client) => {
          return client.ClientType === 'Facility Group' || client.ClientType === 'SAAS Facility Group';
        });
        // this.facilityGroupClients = facilityGroupClient;
        const Facility = clients.Result.filter((client) => {
          return client.ClientType === 'Facility' || client.ClientType === 'SAAS Facility';
        });
        this.FacilityClients = Facility;
        const nonprofitsClient = clients.Result.filter((client) => {
          return client.ClientType === 'Non Profit';
        });
        this.nonprofitsClients = nonprofitsClient;
        if (this.userRole === 'Facility Group') {
          // this.deliveryOption = false;
          this.AdminService.FacilityGroupClients(this.state.user.User.Email).subscribe((data) => {
            facilityGroupClient = data.Result[0].Clients;
            this.facilityGroupClients = data.Result[0].Clients;
            this.info.facilitatorGroup = facilityGroupClient;
            this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
          });
        } else {
          if (facilityGroupClient) {
            this.info.facilitatorGroup = facilityGroupClient;
          }
        }
        if (this.facilitatorClients) {
          this.info.facilitator = this.facilitatorClients;
        }
        if (this.nonprofitsClients) {
          this.info.nonprofits = this.nonprofitsClients;
        }
        if (this.FacilityClients) {
          this.info.facility = this.FacilityClients;
        }
        this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        this.getallClients = false;
        this.info.getallClients = this.getallClients;
        this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
      }
    );
  }
  editphotoDialog() {
    const dialogRef = this.dialog.open(EditphotodialogComponent, {
      width: '540px',
      //  height: '400px',
      data: [
        {
          user_photo: this.profileImageUrl,
        },
        {
          Client: this.user[0],
        },
      ],
      panelClass: 'custom-editdialog-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
        this.profileImageUrl = this.profileImageUrl;
      } else {
        this.profileImageUrl = result[0].user_photo;
        this.uploadImage = result[1].upload_photo;
        this.ImageUrl = true;
        this.ImageUrlnull = false;
      }
    });
  }

  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.user[0].Email) === false) {
      return false;
    }
    return true;
  }

  AddressChange(address: any) {
   let finalResult = {};
   let dd = address.address_components;
   finalResult['address1'] = '';
   this.user[0].Address = '';
   this.user[0].Address2 = '';
   this.user[0].ZIP = '';
   this.Stateid = '';
   this.pickupCity = '';
   let componentType;
   let cityid = '';

   for (let component in dd) {
       componentType = dd[component].types[0];

      switch (componentType) {
         case "street_number": {
           this.user[0].Address2 = `${dd[component].long_name} ${finalResult['address1']}`;           
           break;
         }

         case "route": {
           this.user[0].Address += dd[component].short_name;
           break;
         }

         case "postal_code": {
           console.log("ZIP :: ", dd[component].long_name);
          //  this.user[0].ZIP = dd[component].long_name;
          setTimeout(() => {
            for (let i in this.zipcodes) {
              if (parseInt(this.zipcodes[i]) == dd[component].long_name) {
                this.zipupdate = this.zipcodes[i];
              }
            }
          }, 2000);

           break;
         }

         case "locality" || "sublocality":
           console.log("CITY :: ", dd[component].long_name);
              cityid = dd[component].long_name;
              if (cityid == "New York") {
                cityid = 'New York City'
              }
           break;

          case "administrative_area_level_3":
           console.log("CITY :: ", dd[component].long_name);
            cityid = dd[component].long_name;
            if (cityid == "New York") {
              cityid = 'New York City'
            }
           break;

          case "sublocality_level_1":
           console.log("CITY :: ", dd[component].long_name);
            cityid = dd[component].long_name;
            if (cityid == "New York") {
              cityid = 'New York City'
            }
           break;

         case "administrative_area_level_1": {
           console.log("STATE :: ", dd[component].long_name);
            for (let i in this.pickupstatesdata) {
               if (this.pickupstatesdata[i].Title == dd[component].long_name) {
                  this.Stateid = this.pickupstatesdata[i].ID;
                  
               }
            }
           break;
         }

      }
   }

   this.selectedstate(()=>{
    for (let i in this.citydataID) {
      if (this.citydataID[i].Title == cityid) {
        this.pickupCity = this.citydataID[i].ID;
         this.selectedcity();
      }
   }
  });
}
}
