import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/shared/app-state';
import { CustomerService } from '../../../shared/services/customer.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SpinnerService } from 'src/app/core/spinner.service';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-grabreport',
  templateUrl: './grabreport.component.html',
  styleUrls: ['./grabreport.component.scss']
})
export class GrabreportComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  nodata: any;
  applyFilter(filterValue: string) {
    this.GrabClients.filter = filterValue.trim().toLowerCase();
  }
  AdminService: any;
  GrabClientsdata: any;
  GrabClients: any;
  StatusUpdate: boolean;
  constructor(private state: AppState,
    private spinnerService: SpinnerService, private router: Router,

    private CustomerService: CustomerService, private http: HttpClient, ) { }

  ngOnInit() {
    this.getGrabMenu();
  }
  goToCustomerPage() {
    this.router.navigate(["Customers"]);
  }
  getGrabMenu() {
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getGrabMenu()
      .subscribe(
        data => {
          this.spinnerService.stop(spinnerRef);
          if (data) {
            this.state.isSpinnerVisible = false;
            this.GrabClientsdata = data;
            this.GrabClients = this.GrabClientsdata.ClientDetails;
            this.GrabClients = new MatTableDataSource(this.GrabClientsdata.ClientDetails);
            setTimeout(() => {
              this.StatusUpdate = false;
            }, 1500);
          }
        });
  }
}
