import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { OtpPricingCreatePopupComponent } from '../otp-pricing-create-popup/otp-pricing-create-popup.component';
import { OtpPricingEditPopupComponent } from '../otp-pricing-edit-popup/otp-pricing-edit-popup.component';
import { OtpPricingDeletePopupComponent } from '../otp-pricing-delete-popup/otp-pricing-delete-popup.component';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';
import { SpinnerService } from 'src/app/core/spinner.service';

@Component({
  selector: 'app-otp-pricing-module',
  templateUrl: './otp-pricing-module.component.html',
  styleUrls: ['./otp-pricing-module.component.scss'],
})
export class OtpPricingModuleComponent implements OnInit, AfterViewInit {
  ELEMENT_DATA = [];
  pageNumber: number;
  pageSize: number;
  selectedPageSize = [10, 25, 50, 100];
  sortColumn: any = null;
  RecordCount: any;
  displayedColumns: string[] = [
    'id',
    'region',
    'state',
    'city',
    'vehicleType',
    'price',
    'actions',
  ];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private dialog: MatDialog,
    private promotionCode: PricingPromotionCodeService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.getPromotionOTP();
  }
  getPromotionOTP() {
    if (!this.pageNumber || !this.pageSize) {
      this.pageNumber = 1;
      this.pageSize = 10;
    }
    var spinnerRef = this.spinnerService.start();
    this.promotionCode
      .getOTPCode(this.pageNumber, this.pageSize, this.sortColumn)
      .subscribe((data) => {
        this.spinnerService.stop(spinnerRef);
        this.RecordCount = data.RecordCount;
        this.dataSource = new MatTableDataSource(data.Result);
        this.paginator.pageIndex = this.pageNumber - 1;
      });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  createNew() {
    const dialogRef = this.dialog.open(OtpPricingCreatePopupComponent, {
      width: '600px',

      data: {},
      panelClass: 'custom-editpopup-container',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getPromotionOTP();
      }
    });
  }

  editPopup(otpdata) {
    const dialogRef = this.dialog.open(OtpPricingEditPopupComponent, {
      width: '600px',
      data: [{ otpdata }],
      panelClass: 'custom-editpopup-container',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getPromotionOTP();
      }
    });
  }

  deletePopup(otpdata) {
    const dialogRef = this.dialog.open(OtpPricingDeletePopupComponent, {
      width: '500px',
      data: [{ confirmMsg: 'You want to delete this ?' }],
      panelClass: 'custom-editpopup-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deletePromotionOTP(otpdata);
      }
    });
  }
  deletePromotionOTP(otpdata) {
    this.promotionCode
      .deleteOTP(otpdata.PickupPriceId)
      .subscribe((response) => {
        this.getPromotionOTP();
      });
  }
  //pagination
  onChangePage(e) {
    var spinnerRef = this.spinnerService.start();
    this.pageNumber = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.selectedPageSize = [10, 25, 50, 100];
    this.promotionCode
      .getOTPCode(this.pageNumber, this.pageSize, this.sortColumn)
      .subscribe(
        (data) => {
          this.spinnerService.stop(spinnerRef);
          this.dataSource = new MatTableDataSource(data.Result);
          this.paginator.pageIndex = this.pageNumber - 1;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.RecordCount = e.length;
        },
        (error) => {
          // alert(JSON.stringify(error));
          this.spinnerService.stop(spinnerRef);
        }
      );
  }
}
