import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from 'src/app/core/menu.service';
import { FormControl } from '@angular/forms';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import * as moment from 'moment-timezone';
@Component({
  selector: 'datePicker',
  templateUrl: './datePicker.component.html',
  styleUrls: ['./datePicker.component.scss']
})
export class DatePickerComponent implements OnInit {
  pickupResult: any;
  disStatus = false;
  info: any;
  requestStatusinVisible = false;
  requestStatus: boolean;
  infoDate: any;
  minDate: Date;
  minCurrentDate: Date;
  constructor(
    private router: Router,
    private menuService: MenuService,
    private pickupService: PickUpService,
    private route: ActivatedRoute,
    private _rdata: modalDataService
  ) { }
  @Input() childMessage: string;
  @Output() messageEvent = new EventEmitter<string>();

  ngOnInit() {
    this.minCurrentDate = new Date();
    this._rdata.basicInfoDateObserve.subscribe(res => {
      this.infoDate = JSON.parse(res);
      if (this.infoDate.requestStatusinVis === true) {
        this.requestStatusinVisible = getFeature('requestStatus');
        if (!this.requestStatusinVisible) {
          this.requestStatus = true;
        }
      }
      // console.log(this.requestStatus);
    });
    this._rdata.basicInfo1Observe.subscribe(res => {
      this.info = JSON.parse(res);
      if (this.info.pickupsPage === true) {
        this.minDate = new Date(
          moment(this.minCurrentDate).format('YYYY/MM/DD')
        );
      }
      if (this.info.StatusId) {
        if (
          // this.info.StatusId !== 'UnClaimed' &&
          this.info.StatusId !== 'Pending'
        ) {
          this.disStatus = true;
        } else {
          this.disStatus = false;
        }
        this.info.StatusId = '';
        this._rdata.updateBasicInfo1(JSON.stringify(this.info));
      }
    });
    // this.info.requestStatusinVis = '';
    // this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    if (location.href.includes('pickupDetails')) {
      if (this.route.snapshot.params.id) {
        this.pickupService
          .getPickUpDetail(this.route.snapshot.params.id)
          .subscribe(data => {
            this.pickupResult = data.Result;
            // if (
            //   this.pickupResult[0].PickupRequest[0].DriverServices.length ==
            //     0 &&
            //   this.pickupResult[0].Status[0].Title !== 'Cancelled'
            // ) {
            //   this.disStatus = false;
            // } else 
            if (
              (this.pickupResult[0].PickupRequest[0].Clients[0].DriverServices[0]
                .Title === 'Roadie/Postmates' || this.pickupResult[0].PickupRequest[0].DriverServices[0]
                  .Title === 'Roadie') &&
              this.pickupResult[0].Status[0].Title !== 'Pending'
              // &&  this.pickupResult[0].Status[0].Title !== 'UnClaimed'
            ) {
              this.disStatus = true;
            } else {
              this.disStatus = false;
            }
          });
      }
    }
  }
  onDateChangeChange(e) {
    this.messageEvent.emit(e.value);
  }
}