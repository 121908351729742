import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pickup-request-submission',
  templateUrl: './pickup-request-submission.component.html',
  styleUrls: ['./pickup-request-submission.component.scss']
})
export class PickupRequestSubmissionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  trackOrder() {
    this.router.navigate(['trackpickup']);
  }
  Pickups_List() {
    this.router.navigate(['Pickups']);
  }
}
