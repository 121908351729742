import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ConditionalExpr } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.scss'],
})
export class AddCategoryDialogComponent implements OnInit {
  itemName: any;
  requireFieldPrice: boolean;
  requireFieldWeight: boolean;
  requireFieldCategory: boolean;

  itemPrice: any;
  itemWeight: any;
  categories: any;
  selectedCategory: any;
  isPagination: any;
  requestData: any;
  requireField: boolean;
  ClientId: any;
  ClientIdData: any;
  IsGoodrProduct: any;
  buttonDisabled: boolean;
  errorMessageduplicate: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    private route: ActivatedRoute,
    private state: AppState,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private CustomerService: CustomerService,
    public capitailizepipe: specialPipe
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    if (this.data) {
      this.IsGoodrProduct = false;
      this.ClientId = this.data;
      this.ClientIdData = [
        {
          ClientId: this.data,
        },
      ];
    } else {
      this.ClientId = 0;
      this.ClientIdData = [];
      this.IsGoodrProduct = true;
    }
    // this.requestData = JSON.parse(localStorage.getItem("requestData"));
    this.isPagination = true;
    this.getCategories(this.isPagination, this.ClientId);
  }
  //GET-CATEGORIES
  getCategories(isPagination, ClientId) {
    this.isPagination = true;
    this.CustomerService.getCategories(isPagination, ClientId).subscribe((data) => {
      this.categories = data['Result'];
    });
  }

  getSelectedCategory(value) {
    this.selectedCategory = value;
  }

  //POST-NEW-ITEM
  addNewItem() {
    // if (
    //   this.itemName &&
    //   this.itemPrice &&
    //   this.itemWeight &&
    //   this.selectedCategory
    // ) {
    // if (
    //   this.itemName !== undefined &&
    //   this.itemPrice !== undefined &&
    //   this.itemWeight !== undefined &&
    //   this.selectedCategory !== undefined
    // ) {
    if (this.itemName !== undefined && this.itemName && this.itemName !== null) {
      if (this.itemPrice !== undefined && this.itemPrice && this.itemPrice !== null && this.itemPrice > 0) {
        if (this.itemWeight !== undefined && this.itemWeight && this.itemWeight !== null && this.itemWeight > 0) {
          if (this.selectedCategory !== undefined && this.selectedCategory && this.selectedCategory !== null) {
            const payload = {
              Name: this.capitailizepipe.transform(this.itemName.replace(/^\s+/g, '')),
              Price: this.itemPrice,
              Weight: this.itemWeight,
              Category: this.selectedCategory,
              Description: null,
              DirectCost: 1,
              IsGoodrProduct: this.IsGoodrProduct,
              ApprovalStatusId: 2,
              WP_Product_Id: null,
              CreatedBy: null,
              CreatedOn: null,
              ModifiedBy: null,
              ModifiedOn: null,
              ProductSections: [
                {
                  SectionId: this.selectedCategory,
                },
              ],
              Clients: this.ClientIdData,
            };
            this.buttonDisabled = true;
            this.CustomerService.addNewFoodItem(payload).subscribe((data) => {
              if (data['message'] === 'Duplicate Entry') {
                this.buttonDisabled = false;
                this.errorMessageduplicate = true;
                setTimeout(() => {
                  this.errorMessageduplicate = false;
                }, 5000);
              } else {
                let res = 'success';
                this.dialogRef.close(res);
                this.state.isSpinnerVisible = false;
              }
            });
          } else {
            this.buttonDisabled = false;
            this.requireFieldCategory = true;
            setTimeout(() => {
              this.requireFieldCategory = false;
            }, 3000);
          }
        } else {
          this.buttonDisabled = false;
          this.requireFieldWeight = true;
          setTimeout(() => {
            this.requireFieldWeight = false;
          }, 3000);
        }
      } else {
        this.buttonDisabled = false;
        this.requireFieldPrice = true;
        setTimeout(() => {
          this.requireFieldPrice = false;
        }, 3000);
      }
    }
    // else {
    //   this.buttonDisabled = false;
    //   this.requireField = true;
    //   setTimeout(() => {
    //     this.requireField = false;
    //   }, 3000);
    // }
    // }
    else {
      this.buttonDisabled = false;
      this.requireField = true;
      setTimeout(() => {
        this.requireField = false;
      }, 3000);
    }
  }
}
