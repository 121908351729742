import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PickupCancellationModalComponent } from '../pickup-cancellation-modal/pickup-cancellation-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trackpickup',
  templateUrl: './trackpickup.component.html',
  styleUrls: ['./trackpickup.component.scss']
})
export class TrackpickupComponent implements OnInit {
  constructor(private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
  }
  make_Updates() {
    const dialogRef = this.dialog.open(PickupCancellationModalComponent, {
      width: '700px', height: '500px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  view_Pickup_List() {
    this.router.navigate(['TrackPickupList']);
  }
}
