import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from '@angular/material';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ForgotSuccessDialogComponent } from '../forgot-success-dialog/forgot-success-dialog.component';
import { PasswordStrengthValidator } from '../PasswordStrengthValidators';
import { AccountService } from 'src/app/shared/account.service';
import { AppState } from 'src/app/shared/app-state';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );
    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-reset-dialog',
  templateUrl: './reset-dialog.component.html',
  styleUrls: ['./reset-dialog.component.scss']
})
export class ResetDialogComponent implements OnInit {
  passwordForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  modalclose: string;
  selectedDialog: string;
  setpassword = true;
  confirmerror = false;
  disabled = true;
  token: any;
  forgotId: any;
  constructor(
    private state: AppState,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ResetDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {
    this.passwordForm = this.formBuilder.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            PasswordStrengthValidator
          ])
        ],
        confirmPassword: new FormControl({ value: '', disabled: this.disabled })
      }
      // { validator: this.checkPasswords }
    );
  }
  ngOnInit() {
    this.forgotId = this.data[0].recoveryId;
    this.token = this.data[1].recoveryToken;
  }
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  keyPresspassword() {
    if (this.passwordForm.valid === true) {
      // this.disabled = false;
      this.passwordForm.controls['confirmPassword'].enable();
    } else {
      this.passwordForm.controls['confirmPassword'].disable();
      // this.disabled = true;
    }
    if (this.passwordForm.valid === true && this.setpassword === true) {
      if (
        this.passwordForm.controls['confirmPassword'].value ===
        this.passwordForm.controls['password'].value
      ) {
        this.setpassword = false;
      }
    } else {
      this.setpassword = true;
    }
    if (this.passwordForm.controls['confirmPassword'].value) {
      if (
        this.passwordForm.controls['confirmPassword'].value ===
        this.passwordForm.controls['password'].value
      ) {
        this.confirmerror = false;
      } else {
        this.confirmerror = true;
      }
    }
  }
  keyPressconpassword() {
    this.confirmerror = true;
    if (this.passwordForm.valid === true && this.setpassword === true) {
      if (
        this.passwordForm.controls['confirmPassword'].value ===
        this.passwordForm.controls['password'].value
      ) {
        this.setpassword = false;
        this.confirmerror = false;
      }
    } else {
      this.setpassword = true;
      this.confirmerror = true;
    }
  }
  openforgotDialog(resetpassword) {
    if (this.passwordForm) {
      const body = {
        Token: this.token,
        Id: this.forgotId,
        Password: resetpassword,
        IsInvited: false
      };
      this.accountService.forgorPasswordCode(body).subscribe(
        data => {},
        error => {
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
        }
      );
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef.close(this.modalclose);
      const dialog = this.dialog.open(ForgotSuccessDialogComponent, {
        width: '600px',
        // height: "361px",
        disableClose: true,
        data: 'This text is passed into the dialog!'
      });
      dialog.afterClosed().subscribe(selection => {
        if (selection) {
          this.selectedDialog = selection;
        } else {
        }
      });
    }
  }

  confirmSelection() {
    this.dialogRef.close(this.modalclose);
  }
}
