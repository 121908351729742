import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment-timezone';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { TimezonedatepostService } from 'src/app/shared/pipes/timezonedatepost.service';
import { MyOrderByPipe } from 'src/app/shared/pipes/sort.pipe';
import { DatePostFormatPipe } from 'src/app/shared/pipes/dateformat.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { ConfirmPickupsRequestDialogComponent } from '../confirm-pickups-request-dialog/confirm-pickups-request-dialog.component';
import { A11yModule } from '@angular/cdk/a11y';
import { orCustomMaterialList } from 'src/app/shared/services/or-custom-material-list.model';
import { CustomerService } from 'src/app/shared/services/customer.service';

@Component({
  selector: 'app-pickups-request',
  templateUrl: './pickups-request.component.html',
  styleUrls: ['./pickups-request.component.scss'],
})
export class PickupsRequestComponent implements OnInit {
  nomenu = false;
  menu = true;
  clientsData: any;
  info: any;
  displayedColumns: string[] = ['name', 'category', 'retailValue', 'weight', 'quantity'];
  displayedColumnsOR: string[] = ['nameOR', 'weightOR', 'quantityOR','ContaminatedWeight','ContaminatedQuantity','editAdd'];
  reviewDisplayedColumns: string[] = ['num', 'reviewName', 'reviewcategory', 'reviewRetailValue', 'reviewWeight', 'reviewQuantity', 'reviewIcons'];
  reviewDisplayedColumnsOR: string[] = ['num', 'name', 'weight', 'quantity','ContaminatedWeight','ContaminatedQuantity','icons'];
  mobileColumns: string[] = ['name', 'quantity'];
  confirmCheckPath: string = '../../../assets/images/confirmcheck.png';
  cancelXPath: string = '../../../assets/images/redx.png';
  addIcon: string = '../../../assets/images/addicon.png';
  editIcon: string = '../../../assets/images/pickupediticon.png';
  foodrequest = false;
  combined: boolean;
  addItemsPhase: boolean;
  reviewPickupPhase: boolean;
  public headerText: string;
  public showPickupType: boolean;
  public showProgressBtns: boolean;
  public showOrganicOptions: boolean;
  VisibleForFacility: boolean;
  separated: boolean;
  combinedd: boolean;
  public originalData: any;
  OrganicRecycling: boolean;
  Foodpickup: boolean = true;
  clientId: any;
  foodIsSelected: boolean;
  recyclingIsSelected: boolean;
  RoadiePostmatesFeature: boolean;
  CombinedData = [];
  dataSource: any;
  dataSourceOR: any;
  dataSourceCombined: any;
  dataSourceCombinedData: any;
  SeparatedCombined: any;
  separatePickupID: any;
  combinedPickupID: any;
  foodRequest: boolean;
  compostRequest: boolean;
  CombinedRequest: boolean;
  separatedCompost: any;
  modalPhase: string = 'pickupDetails';
  pickupTypeID: any;
  public combinedId: any;
  productList: any;
  requestType: any;
  pickupTypeSelected: boolean;
  showData: boolean;
  previousValues: object;
  categories: any;
  newItem: any;
  categoryControl = new FormControl();
  SeparatedCompostID: any;
  filteredDataSF: [];
  newItemReqError: boolean = false;
  duplicateItemReqError: boolean = false;
  showEmpty: boolean = true;
  serachTextSF = '';
  serachTextSFCategory = '';
  noresultFound: any;
  categoriesData: any;
  selectedCategory: any;
  public totalItemCost: number;
  public totalWeight: number;
  public totalQuantity: number;
  public createForm: FormGroup;
  public Products: [];
  public ProductsToAdd: any;
  public r: any;
  facilityAccessFlag: any;
  scheduleDate: any;
  schedulePickupErrorMsg: any;
  oneYrDate: any;
  newDate: any;
  constructor(
    public dialogRef: MatDialogRef<PickupsRequestComponent>,
    public dialog: MatDialog,
    public capitalizepipe: specialPipe,
    public orderService: MyOrderByPipe,
    public timezonedatepostService: TimezonedatepostService,
    public postDateService: DatePostFormatPipe,
    private pickupService: PickUpService,
    private ClientFilterdata: ClientFilterService,
    private spinnerService: SpinnerService,
    private customerService: CustomerService,
    public capitailizepipe: specialPipe,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private observedData: modalDataService

  ) { }
  public form = {
    data: null,
  };

  ngOnInit() {
    this.facilityAccessFlag = true;
    this.showData = false;
    this.ProductsToAdd = [];
    this.pickupTypeSelected = false;
    this.requestType = null;
    this.headerText = 'Pickup Details';
    this.modalPhase = 'pickupDetails';
    this.newItem = {
      ID: 'new_' + Math.random(),
      Name: '',
      Quantity: '',
      RequestId: 0,
      ProductId: 0,
      Cost: '',
      wasClicked: true,
      Weight: '',
      isAdded: false,
      editMode: false,
      icon: this.editIcon,
      beingAdded: false,
      Price: '',
      selectedCategory: this.selectedCategory
    };
    const spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      if (this.info.facility.length > 0) {
        this.clientsData = this.info.facility.filter((value) => value.ApprovalStatus === 'Active' && value.PickupType[0] !== 'One Time Pickup');
        if (this.info.facility.filter((value) => value.ApprovalStatus === 'Active' && value.PickupType[0] !== 'One Time Pickup').length === 1) {
          this.form.data = this.info.facility.filter((value) => value.ApprovalStatus === 'Active' && value.PickupType[0] !== 'One Time Pickup')[0];
          this.clientsdataChange(this.form);
          if (this.separated == true) this.selectItemOption('separated');
          else this.selectItemOption('combined');
        }

        this.spinnerService.stop(spinnerRef);
      }
    });
  }

  clientsdataChange(value) {
    this.productList = value.data;
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hr = date.getHours();
    let min = date.getMinutes();
    let scheduleDate = moment.tz(new Date(year, month, day, hr + 1, min), this.productList.TimeZone);
    scheduleDate.format();
    this.scheduleDate = moment(scheduleDate).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');

    let oneYrDate = moment.tz(new Date(year + 1, month, day, hr, min), this.productList.TimeZone);
    oneYrDate.format();
    this.oneYrDate = oneYrDate.format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');

    let newDate = moment.tz(new Date(year, month, day, hr + 1, min), this.productList.TimeZone);
    newDate.format();
    const abbr = moment.tz.zone(this.productList.TimeZone).abbr(360);

    this.newDate = newDate.format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');

    if (value.data.MenuType == null) {
      const id = value.data.ClientId;
      if (id) {
        this.clientId = id;
      }
      if (value.data.PickupType && value.data.PickupType[0] == 'Organic Recycling') {
        this.nomenu = false;
        this.menu = true;
        this.showPickupType = true;
        this.combined = true;
        this.separated = true;
        this.foodrequest = true;
        this.requestType = 'separated';
        this.selectItemOption('separated');
      }
      else {
        this.nomenu = true;
        this.menu = false;
      }
    } else {
      const id = value.data.ClientId;
      if (id) {
        this.clientId = id;
      }
      this.foodrequest = false;
      this.combined = false;
      this.menu = true;
      this.nomenu = false;
      const pickupType = value.data.PickupType;
      if (pickupType.length == 1) {
        this.showPickupType = true;
        pickupType.map((pt) => {
          this.pickupTypeSelected = false;
          this.requestType = pt;
          if (pt === 'Food') {
            this.foodrequest = false;
            this.separated = false;
            if (pickupType.length == 1 && pt === 'Food') {
              this.requestType = 'combined';
              this.selectItemOption('combined');
            }
          }
          if (pt === 'Organic Recycling') {
            this.combined = true;
            this.separated = true;
            this.foodrequest = true;

            if (pickupType.length == 1 && pt === 'Organic Recycling') {
              this.requestType = 'separated';
              this.selectItemOption('separated');
            }
          }
        });
      } else if (pickupType.length == 2) {
        this.showPickupType = true;
        pickupType.map((pt) => {
          this.requestType = pt;
          this.combined = false;
          this.separated = true;
          this.foodrequest = true;
        });
      } else {
        this.foodrequest = false;
        this.combined = false;
        this.showPickupType = false;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  popupClose() {
    this.dialogRef.close();
  }

  separetaedRequest() {
    let spinnerRef = this.spinnerService.start();

    this.pickupService.SeparatedCompost().subscribe((data) => {
      this.separatedCompost = orCustomMaterialList(data.Result);

      const orList = [];
      this.separatedCompost.forEach((p) => {
        const objVal = {
          Name: p.Title,
          Price: p.Price ? p.Price : '',
          Weight: p.Weight ? p.Weight : '',
          Quantity: p.Quantity ? p.Quantity : '',
          isAdded: false,
          beingAdded: false,
          editMode: false,
          icon: '../../../assets/images/addicon.png',
          ID: p.ID,
          requiredError: false,
          previousValues: {
            Quantity: p.Quantity ? p.Quantity : '',
            Weight: p.Weight ? p.Weight : '',
            Price: p.Price ? p.Price : '',
          },
        };
        orList.push(objVal);
      });
      this.separatedCompost = orList;

      this.showEmpty = false;
      this.dataSourceOR = new MatTableDataSource(this.separatedCompost);
      this.originalData = this.dataSourceOR;
      this.showData = true;
      this.newItem.index = this.separatedCompost.length;
      this.spinnerService.stop(spinnerRef);
    });
  }
  getClientCategoryData() {
    let spinnerRef = this.spinnerService.start();
    this.customerService.getAllCategories(null, 100, null, this.clientId).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.categoriesData = data['Result'];
    });
  }
  pickupRequest() {
    let spinnerRef = this.spinnerService.start();
    this.pickupService.getClientsProductList(this.clientId).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        if (Array.isArray(data.Result)) {
          const sourceOfData = [];
          this.pickupTypeID = 1;
          const cats = [];
          const copyOfProducts = JSON.parse(JSON.stringify(data.Result));
          copyOfProducts.forEach((p) => {
            const categ = p.Sections[0].Name;
            if (!cats.includes(categ)) {
              cats.push(categ);
            }
            const objVal = {
              ...p,
              Price: p.Price ? p.Price : '',
              Weight: p.Weight ? p.Weight : '',
              Quantity: p.Quantity ? p.Quantity : '',
              isAdded: false,
              beingAdded: false,
              editMode: false,
              icon: '../../../assets/images/addicon.png',
              SectionId: p.Sections[0].SectionId,
              requiredError: false,
              previousValues: {
                Quantity: p.Quantity ? p.Quantity : '',
                Weight: p.Weight ? p.Weight : '',
                Price: p.Price ? p.Price : '',
              },
            };
            return sourceOfData.push(objVal);
          });
          this.categories = cats;
          this.showEmpty = false;
          sourceOfData.sort(function (a, b) {
            if (a.Name < b.Name) {
              return -1;
            }
            if (a.Name > b.Name) {
              return 1;
            }
            return 0;
          });

          this.dataSource = new MatTableDataSource(sourceOfData);
          this.originalData = this.dataSource;
          this.showData = true;
          this.newItem.index = sourceOfData.length;
        }
        return null;
      },
      () => this.spinnerService.stop(spinnerRef)
    );
  }
  selectItemOption(val) {
    this.requestType = val;
    this.pickupTypeSelected = true;
    return null;
  }
  loadClientData() {
    this.getClientCategoryData();
    this.headerText = 'Add Items';
    this.addItemsPhase = true;
    if (this.requestType === 'separated') {
      this.separetaedRequest();
    } else {
      this.pickupRequest();
    }
    this.dataSourceCombinedData = this.CombinedData;
  }

  editTransactionRow(value, field, index) {
    const { editMode, ProductId } = this.dataSource.data[index];
    if (editMode) {
      
      const cartIndex = this.ProductsToAdd.findIndex((p) => p.ProductId === ProductId);
      if (cartIndex > -1) {
        this.ProductsToAdd[cartIndex][field] = value;
        this.dataSource.data[index][field] = value;
      }
    } else this.dataSource.data[index][field] = value;
  }
  editTransactionRowOR(value, field, index) {
    const { editMode, Name } = this.dataSourceOR.data[index];
    if (editMode) {
      // edit row in Products To add as well
      const cartIndex = this.ProductsToAdd.findIndex((p) => p.Name === Name);
      if (cartIndex > -1) {
        this.ProductsToAdd[cartIndex][field] = value;
      }
    }
    this.dataSourceOR.data[index][field] = value;
  }
  numberOnly(event, decimalAllow): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (decimalAllow !== false) {
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) return false;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  setPreviousValues(i) {
    this.dataSource.data[i].Quantity = this.dataSource.data[i].previousValues.Quantity;
    this.dataSource.data[i].Weight = this.dataSource.data[i].previousValues.Weight;
    this.dataSource.data[i].Price = this.dataSource.data[i].previousValues.Price;
    this.dataSource.data[i].editMode = false;
  }

  setPreviousValuesOR(i) {
    this.dataSourceOR.data[i].Quantity = this.dataSourceOR.data[i].previousValues.Quantity;
    this.dataSourceOR.data[i].Weight = this.dataSourceOR.data[i].previousValues.Weight;
    this.dataSourceOR.data[i].Price = this.dataSourceOR.data[i].previousValues.Price;
    this.dataSourceOR.data[i].editMode = false;
  }

  doneEditing(transaction, i) {
    if (
      transaction.Quantity === 0 ||
      transaction.Quantity == '' ||
      transaction.Quantity == '0' ||
      transaction.Weight === 0 ||
      transaction.Price === 0 ||
      transaction.Quantity === null ||
      transaction.Weight === null ||
      transaction.Price === null
    ) {
      this.dataSource.data[i].requiredError = true;
    } else {
      this.dataSource.data[i] = transaction;
      let previousValues = {
        Quantity: this.dataSource.data[i].Quantity,
        Weight: this.dataSource.data[i].Weight,
        Price: this.dataSource.data[i].Price,
      };
      this.dataSource.data[i].editMode = false;
      this.dataSource.data[i].previousValues = previousValues;
    }
  }

  doneEditingOR(transaction, i) {

   //   transaction.Weight === 0 ||
   // transaction.Weight === null || 
    if (transaction.Quantity === 0 || transaction.Quantity === null) {
      this.dataSourceOR.data[i].requiredError = true;
    } else {
      let previousValues = {
        Quantity: this.dataSourceOR.data[i].Quantity,
        Weight: this.dataSourceOR.data[i].Weight,
        Price: this.dataSourceOR.data[i].Price,
        ContaminatedWeight: transaction.ContaminatedWeight,
        ContaminatedQuantity: transaction.ContaminatedQuantity
      };
      this.dataSourceOR.data[i].editMode = false;
      this.dataSourceOR.data[i].previousValues = previousValues;
      this.ProductsToAdd[i].previousValues = previousValues;
      this.ProductsToAdd[i].ContaminatedWeight = transaction.ContaminatedWeight;
      this.ProductsToAdd[i].ContaminatedQuantity = transaction.ContaminatedQuantity;
    }
  }
  loadPickupListData() {
    let finalList = [];
    this.ProductsToAdd.map((r) => {
      if (r.Weight >= 0) {
        finalList.push(r);
      }
    });
    this.ProductsToAdd = finalList;
    this.modalPhase = 'reviewPickup';
    this.headerText = 'Review Pickup';

    if (this.requestType === 'separated')
      this.totalWeight = this.ProductsToAdd.map((r) => (r ? r.Weight : null)).reduce((sum, current) => Number(sum) + Number(current));
    else {
      this.totalWeight = this.ProductsToAdd.map((r) => (r ? r.Weight * r.Quantity : null)).reduce((sum, current) => Number(sum) + Number(current));
      this.totalItemCost = this.ProductsToAdd.map((r) => (r ? r.Quantity * r.Price : null)).reduce((sum, current) => Number(sum) + Number(current));
      this.totalQuantity = this.ProductsToAdd.map((r) => (r ? r.Quantity : null)).reduce((sum, current) => Number(sum) + Number(current));
    }
  }
  checkScheduleOnOff() {
    if (this.productList) {
      if (this.productList.AllowSchedulePickup) {
        if (this.productList.AllowSchedulePickup == true) {
          return true
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }
  escapeState() {
     console.log(this.modalPhase)
    if (this.modalPhase === 'pickupDetails') {
      this.requestType = null;
      this.pickupTypeSelected = false;
      this.clientId = null;
    }
    if (this.modalPhase === 'schedulePickup') {
      this.headerText = 'Pickup Details';
      this.modalPhase = 'pickupDetails';
      this.addItemsPhase = false;
      this.showData = false;
      this.ProductsToAdd = [];
      this.dataSource = [];
      this.dataSourceOR = [];
    }
    if (this.modalPhase === 'addItems') {
      this.filterTable('', 'Name');
      this.newItem = {
        ID: 'new_' + Math.random(),
        Name: '',
        Quantity: '',
        RequestId: 0,
        ProductId: 0,
        Cost: '',
        wasClicked: true,
        Weight: '',
        editMode: false,
        isAdded: false,
        icon: this.editIcon,
        index: this.dataSource.data.length + 1,
        beingAdded: false,
        Price: '',
        selectedCategory: this.selectedCategory
      };

      if (this.productList.AllowSchedulePickup) {
        if (this.productList.AllowSchedulePickup == true) {
          this.modalPhase = 'schedulePickup';
          this.headerText = 'Schedule Pickup';
        }
      } else {
        this.headerText = 'Pickup Details';
        this.modalPhase = 'pickupDetails';
        this.addItemsPhase = false;
        this.showData = false;
        this.ProductsToAdd = [];
        this.dataSource = [];
        this.dataSourceOR = [];
      }


    }
    if (this.modalPhase === 'reviewPickup') {
      this.headerText = 'Add Items';
      this.modalPhase = 'addItems';
      this.showData = true;
      this.addItemsPhase = true;
      if (this.requestType === 'separated') {
        this.originalData.data.sort(function (a, b) {
          return b.isAdded - a.isAdded;
        });
        this.originalData.data.map((p) => {
         //  if (p.Weight === 0) {
         //    p.isAdded = false;
         //    p.editMode = false;
         //    p.icon = this.addIcon;
         //  }
        });
      } else {
        this.dataSource.data.sort(function (a, b) {
          return b.isAdded - a.isAdded;
        });
        this.dataSource.data.map((p) => {
          if (p.Weight === 0 && p.Price === 0 && p.Quantity === 0) {
            p.isAdded = false;
            p.Weight = '';
            p.editMode = false;
            p.Price = '';
            p.Quantity = '';
            p.icon = this.addIcon;
          }
        });
      }
    }
    return null;
  }
  async addItemToList(transaction, ind) {
    if (
      transaction.Quantity === 0 ||
      transaction.Quantity == '0' ||
      transaction.Quantity == '' ||
      transaction.Weight === 0 ||
      transaction.Price === 0 ||
      transaction.Quantity === '' ||
      transaction.Weight === '' ||
      transaction.Price === ''
    ) {
      this.dataSource.data[ind].requiredError = true;
      return null;
    }
    if (transaction.isAdded && !transaction.editMode) {
      this.dataSource.data[ind].editMode = true;
      let previousValues = {
        Quantity: this.dataSource.data[ind].Quantity,
        Weight: this.dataSource.data[ind].Weight,
        Price: this.dataSource.data[ind].Price,
      };
      this.dataSource.data[ind].previousValues = previousValues;

      this.previousValues = JSON.parse(JSON.stringify(transaction));
      return null;
    }
    if (transaction.isAdded && transaction.editMode) {
      this.dataSource.data[ind].editMode = false;
      return null;
    }
    if (transaction.isAdded) {
      return null;
    }

    const index = await this.originalData.data.findIndex((p) => transaction.ProductId === p.ProductId);
    const index1 = await this.originalData.data.findIndex((p) => transaction.Name === p.Name);
    if (index > -1 || index1 > -1) {
      this.originalData.data[index].isAdded = true;
      this.originalData.data[index].icon = this.editIcon;
      this.originalData.data[index].Quantity = transaction.Quantity;
      this.originalData.data[index].Weight = transaction.Weight;
      this.originalData.data[index].Price = transaction.Price;
    }
    await this.ProductsToAdd.push(transaction);
    this.filterTable('', 'Name');
    return null;
  }

  async addItemToListOR(transaction, ind) {
   //   transaction.Weight === 0 ||
    if (transaction.Weight === '' || transaction.Quantity === 0 || transaction.Quantity === '' || transaction.Weight == null || transaction.Quantity == null) {
      this.dataSourceOR.data[ind].requiredError = true;
      return null;
    }

    if (transaction.isAdded && !transaction.editMode) {
      // toggle edit mode and return
      this.dataSourceOR.data[ind].editMode = true;
      let previousValues = {
        Quantity: this.dataSourceOR.data[ind].Quantity,
        Weight: this.dataSourceOR.data[ind].Weight,
        Price: this.dataSourceOR.data[ind].Price,
      };
      this.dataSourceOR.data[ind].previousValues = previousValues;
      this.previousValues = transaction;
      return null;
    }
    if (transaction.isAdded && transaction.editMode) {
      this.dataSourceOR.data[ind].editMode = false;
      return null;
    }
    if (transaction.isAdded) {
      return null;
    }
    const copyOfProductList = JSON.parse(JSON.stringify(this.dataSourceOR.data));
    const index = await copyOfProductList.findIndex((p) => transaction.ID === p.ID);
    if (index > -1) {
      copyOfProductList[index].isAdded = true;
      copyOfProductList[index].icon = this.editIcon;
      this.dataSourceOR.data = copyOfProductList;
    }
    await this.ProductsToAdd.push(transaction);
    return null;
  }

  popupconfirmodal(data, pickuptype, Status) {
    const dialogRef = this.dialog.open(ConfirmPickupsRequestDialogComponent, {
      width: '700px',
      maxWidth: '35vw',
      panelClass: 'custom-pickup-container',
      data: {
        DeliveryId: data,
        pickupType: pickuptype,
        isSchedulePickup: Status[0].Title == 'Scheduled' ? true : false
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  createPickupList() {
    const spinnerRef = this.spinnerService.start();
    const data = [],
      data1 = [];
    let pickupStartDate = new Date();
    const startDate = new Date(this.timezonedatepostService.transform(pickupStartDate.toISOString(), this.productList.TimeZone));

    for (let i = 0; i < this.ProductsToAdd.length; i++) {
      const item = this.ProductsToAdd[i];
      const { Name, Quantity, ProductId, SectionId, Price: Cost, Weight, ID, selectedCategory } = item;
      let newSectionId;

      if (!SectionId) newSectionId = Number(selectedCategory);
      else newSectionId = SectionId;
      const Product = [
        {
          Name: ProductId ? null : this.capitalizepipe.transform(Name),
        },
      ];
      data.push({
        Product,
        Quantity,
        ProductId,
        SectionId: newSectionId,
        ContaminatedWeight: item.ContaminatedWeight,
        ContaminatedQuantity: item.ContaminatedQuantity,
        Cost,
        Weight,
        RequestId: 0,
      });
      data1.push({
        PickupImageURL: null,
        SeparatedCompostId: ID,
        Weight: Weight * 16,
        Quantity: Quantity,
        ContaminatedWeight: item.ContaminatedWeight,
        ContaminatedQuantity: item.ContaminatedQuantity
      });
    }
    
    /*
     * TODO: Set conditionals for postmates
     */
    const { DriverServices } = this.productList;
    const PickupTypeId = this.pickupTypeID;
    let PickupAfter;
    let DeliveryBetweenEnd;
    if (DriverServices === 'Roadie' || DriverServices === 'Roadie/Postmates') {
      PickupAfter =
        moment(startDate)
          .add(5, 'minutes')
          .format()
          .toString()
          .split('T')[0] +
        'T' +
        moment(startDate)
          .add(5, 'minutes')
          .format()
          .toString()
          .split('T')[1]
          .split('+')[0]
          .split('-')[0];
      PickupAfter =
        moment(startDate).add(5, 'minutes').format().toString().split('T')[0] +
        'T' +
        moment(startDate).add(5, 'minutes').format().toString().split('T')[1].split('+')[0].split('-')[0];
      DeliveryBetweenEnd = new Date(PickupAfter);
      DeliveryBetweenEnd =
        moment(DeliveryBetweenEnd).add(2, 'hours').format().toString().split('T')[0] +
        'T' +
        moment(DeliveryBetweenEnd).add(2, 'hours').format().toString().split('T')[1].split('+')[0].split('-')[0];
      PickupAfter = this.postDateService.transform(PickupAfter, this.productList.TimeZone);
      DeliveryBetweenEnd = this.postDateService.transform(DeliveryBetweenEnd, this.productList.TimeZone);
    } else {
      PickupAfter = null;
      DeliveryBetweenEnd = null;
    }
    let body = {
      ClientId: this.clientId,
      PickupAfter,
      DeliveryBetweenStart: PickupAfter,
      DeliveryBetweenEnd,
      PickupTypeId: this.requestType === 'separated' ? 2 : 1,
    };
    if (this.facilityAccessFlag == false) {
      const abbr = moment.tz.zone(this.productList.TimeZone).abbr(360);

      let newTime = moment(this.scheduleDate).format('YYYY-MM-DDTHH:mm:ss');
      let convertToUTCFormat = moment
        .tz(newTime, 'YYYY-MM-DDTHH:mm:ss', this.productList.TimeZone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
      body['ScheduleDate'] = convertToUTCFormat.split("Z")[0];
      body['SchedulePickup'] = true;
    }
    if (this.requestType === 'separated') {
      body['OrganicRecyclingPickupTypeId'] = 1;
      body['PickupRequest_OrganicRecycling'] = data1;
    } else body['PickupRequest_Product'] = data;

    this.pickupService.addItemsRequest(JSON.stringify(body)).subscribe((data) => {
      if (!data.message || data.message === '') {
        this.spinnerService.stop(spinnerRef);
        const { DeliveryId, PickupRequest, Status } = data;

        this.popupClose();
        this.popupconfirmodal(DeliveryId, PickupRequest[0].PickupType[0].ID, Status);
      }
    });
  }
  checkNonProfitAvailability(date, clientId) {
    this.schedulePickupErrorMsg = ''
    let spinnerRef = this.spinnerService.start();

    this.pickupService.getNonProfits(date, clientId).subscribe((data) => {
      if (data.toString() == 'The nonprofits are available to receive your pickup.') {
        this.modalPhase = 'addItems';
        this.spinnerService.stop(spinnerRef);
        return this.loadClientData();
      } else if (data.toString() == 'Nonprofit are not available in same state') {
        this.spinnerService.stop(spinnerRef);
        return this.schedulePickupErrorMsg = 'Nonprofit not available in same state';
      } else if (data.toString() == 'The nonprofits are not available to receive your pickup. Please contact Goodr admin') {
        this.spinnerService.stop(spinnerRef);
        return this.schedulePickupErrorMsg = 'There are no nonprofits available in your area. Please contact Goodr at';
      }
      else {
        let nextAvailableSlot = data.toString().split('is ')[1].split('.')[0];
        let w = moment.tz(nextAvailableSlot, this.productList.TimeZone);
        this.schedulePickupErrorMsg = 'There are no nonprofits available at the scheduled time. Please reschedule your pickup for ' + w.format('M/DD/YYYY, h:mm a') + '.';
      }
      this.spinnerService.stop(spinnerRef);
    },
      (error) => {
        console.log(error)
      });
  }
  goToNextPhase(e) {
    e.preventDefault();

    if (this.modalPhase === 'pickupDetails') {
      if (this.productList.AllowSchedulePickup) {
        if (this.productList.AllowSchedulePickup == true) {
          this.modalPhase = 'schedulePickup';
          this.headerText = 'Schedule Pickup';
        }
      } else {
        this.modalPhase = 'addItems';
        this.headerText = 'Add Items';
        return this.loadClientData();
      }

      this.showEmpty = true;
      return
    }
    if (this.productList.AllowSchedulePickup) {
      if (this.productList.AllowSchedulePickup == true) {
        if (this.modalPhase === 'schedulePickup') {
          if (this.facilityAccessFlag == false) {
            let newTime = moment(this.scheduleDate).format('YYYY-MM-DDTHH:mm:ss');
            let convertToUTCFormat = moment
              .tz(newTime, 'YYYY-MM-DDTHH:mm:ss', this.productList.TimeZone)
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss');
            this.checkNonProfitAvailability(convertToUTCFormat.split("Z")[0], this.clientId);
          } else this.checkNonProfitAvailability(null, this.clientId);
        }
      }
    }

    if (this.modalPhase === 'addItems') {
      this.filterTable('', 'Name');
      if (this.ProductsToAdd.length > 0) {
        this.headerText = 'Review Pickup';
        return this.loadPickupListData();
      }
      return null;
    }
    if (this.modalPhase === 'reviewPickup') {
      if (this.ProductsToAdd.length > 0) {
        // set loader
        this.headerText = 'Creating Pickup...';
        return this.createPickupList();
      }
      return null;
    }
  }
  filterTable(value, category) {
    this.noresultFound = category;
    const filteredData = this.originalData.data.filter((data) => {
      if (category == 'Category')
        return data.Sections[0]['Name'].toLowerCase().includes(value.toLowerCase());
      else
        return data[category].toLowerCase().includes(value.toLowerCase());
    });
    this.filteredDataSF = filteredData;
    filteredData.sort(function (a, b) {
      return b.isAdded - a.isAdded;
    });
    this.dataSource = new MatTableDataSource(filteredData);
  }
  editNewItem = (value, field) => {
    this.newItem[field] = value;
    return null;
  };
    checkDuplicate() {
    let cateogryName = '';
    this.newItem.selectedCategory = this.selectedCategory;
    this.categoriesData.map((i) => {
      if (this.selectedCategory == i.SectionId.toString())
        cateogryName = i.Name;

      if (this.selectedCategory == "null")
        cateogryName = 'Other';
    })
    if (this.selectedCategory == 'null')
      this.newItem.Sections = [{ SectionId: 0, Name: cateogryName }]
    else
      this.newItem.Sections = [{ SectionId: this.selectedCategory, Name: cateogryName }]
      this.duplicateItemReqError = false;
      
      if (this.newItem.Name !== '' 
        && this.newItem.Weight !== '' 
        && this.newItem.Weight != '0' 
        && this.newItem.Weight != 0 
        && this.newItem.Quantity !== '' 
        && this.newItem.Quantity != '0' 
        && this.newItem.Quantity != 0 
        && this.newItem.Price !== '' 
        && this.newItem.selectedCategory !== undefined 
        && this.newItem.selectedCategory !== '') {

      const spinnerRef = this.spinnerService.start();

      this.addNewItem(spinnerRef);
    } else this.newItemReqError = true;
  }

  addNewItem(spinnerRef) {
    if (this.newItem !== undefined && this.newItem && this.newItem !== null) {
      if (this.newItem.Price !== undefined && this.newItem.Price && this.newItem.Price !== null && this.newItem.Price > 0) {
        if (this.newItem.Weight !== undefined && this.newItem.Weight && this.newItem.Weight !== null && this.newItem.Weight > 0) {
          if (this.selectedCategory !== undefined && this.selectedCategory && this.selectedCategory !== null) {
            const payload = {
              Name: this.capitailizepipe.transform(this.newItem.Name.replace(/^\s+/g, '')),
              Price: this.newItem.Price,
              Weight: this.newItem.Weight,
              Category: null,
              Description: null,
              DirectCost: 1,
              IsGoodrProduct: false,
              ApprovalStatusId: 2,
              WP_Product_Id: null,
              CreatedBy: null,
              CreatedOn: null,
              ModifiedBy: null,
              ModifiedOn: null,
              ProductSections: [
                {
                  SectionId: Number(this.selectedCategory),
                },
              ],

              Clients: [{ ClientId: this.clientId }],
            };
            this.customerService.addNewFoodItem(payload).subscribe((data) => {
              if (data['ProductId']) {
                this.newItem.ProductId = data['ProductId']
              }
              if (data['message'] === 'Duplicate Entry') {
                this.duplicateItemReqError = true;
                setTimeout(() => {
                  this.duplicateItemReqError = false;
                }, 5000);
              } else {
                this.createNewItem()
              }
              this.spinnerService.stop(spinnerRef);
            });
          } else {
            
          }
        } else {
         
        }
      } else {
        
      }
    }
    else {
      
    }
  }

  createNewItem = async () => {
    if (this.newItem.Name === '' || this.newItem.Weight === '' || this.newItem.Price === '' || this.newItem.Quantity === '') {
      this.newItemReqError = true;
      return;
    } else {
      this.newItemReqError = false;
      this.duplicateItemReqError = false;

      const copyOfState = await JSON.parse(JSON.stringify(this.originalData.data));
      this.newItem.isAdded = true;
      await copyOfState.push(this.newItem);
      this.originalData.data.push(this.newItem);
      const copyOfProducts = await JSON.parse(JSON.stringify(this.ProductsToAdd));
      await copyOfProducts.push(this.newItem);
      this.ProductsToAdd.push(this.newItem);
      this.dataSource = await new MatTableDataSource(copyOfState);
      this.ProductsToAdd = await copyOfProducts;
      this.newItem = {
        ID: 'new_' + Math.random(),
        Name: '',
        Quantity: '',
        RequestId: 0,
        ProductId: 0,
        Cost: '',
        wasClicked: true,
        Weight: '',
        editMode: false,
        isAdded: false,
        icon: this.editIcon,
        index: this.dataSource.data.length + 1,
        beingAdded: false,
        Price: '',
        selectedCategory: this.selectedCategory
      };
      this.selectedCategory = '';
      this.dataSource.data.sort(function (a, b) {
        return b.isAdded - a.isAdded;
      });
      return null;
    }
  };

  removeItem(itm) {
    if (this.requestType === 'separated') {
      var filtered = this.ProductsToAdd.filter(function (value, index, arr) {
        return value.ID !== itm.ID;
      });
      this.ProductsToAdd = filtered;
      this.dataSourceOR.data.map((p) => {
        if (p.ID === itm.ID) {
          p.isAdded = false;
          p.Weight = '';
          p.editMode = false;
        }
        if (p.Weight === 0) {
          p.isAdded = false;
          p.editMode = false;
          p.icon = this.addIcon;
        }
      });
      if (this.ProductsToAdd.length > 0) {
        this.totalWeight = this.ProductsToAdd.map((r) => (r ? r.Weight * r.Quantity: null)).reduce((sum, current) => Number(sum) + Number(current));
        this.totalQuantity = this.ProductsToAdd.map((r) => (r ? r.Quantity : null)).reduce((sum, current) => Number(sum) + Number(current));
      } else {
        this.totalWeight = 0;
        this.totalWeight = 0;
        this.totalQuantity = 0;
      }
    } else {
      let newProductList = this.ProductsToAdd.filter((i) => i.Name !== itm.Name);
      this.ProductsToAdd = newProductList;

      let Original = this.originalData.data.filter((i) => i.Name !== itm.Name);
      this.originalData.data = Original;

      let newData = this.dataSource.data.filter((i) => i.Name !== itm.Name);
      this.dataSource.data = newData;

      this.dataSource.data.map((p) => {
        if (p.Name === itm.Name) {
          p.isAdded = false;
          p.Weight = 0;
          p.editMode = false;
          p.Price = 0;
          p.Quantity = 0;
        }
        if (p.Weight === 0 && p.Price === 0 && p.Quantity === 0) {
          p.isAdded = false;
          p.Weight = 0;
          p.editMode = false;
          p.Price = 0;
          p.Quantity = 0;
          p.icon = this.addIcon;
        }
      });
      if (this.ProductsToAdd.length > 0) {
        this.totalWeight = this.ProductsToAdd.map((r) => (r ? r.Weight * r.Quantity : null)).reduce((sum, current) => Number(sum) + Number(current));
        this.totalItemCost = this.ProductsToAdd.map((r) => (r ? r.Quantity * r.Price : null)).reduce((sum, current) => Number(sum) + Number(current));
      } else {
        this.totalItemCost = 0;
        this.totalQuantity = 0;
        this.totalWeight = 0;
      }
    }
  }
  clearItem(itm, pickupType) {
    if (pickupType === 'SF') {
      this.dataSource.data.map((p) => {
        if (p.Name == itm.Name) {
          p.isAdded = false;
          p.Weight = 0;
          p.editMode = false;
          p.Price = 0;
          p.Quantity = 0;
          p.icon = this.addIcon;
        }
      });

      this.dataSource.data.sort(function (a, b) {
        return b.isAdded - a.isAdded;
      });
      let newProductList = this.ProductsToAdd.filter((i) => i.Name !== itm.Name);
      this.ProductsToAdd = newProductList;
    } else {
      this.dataSourceOR.data.map((p) => {
        if (p.Name == itm.Name) {
          p.isAdded = false;
          p.Weight = '';
          p.Quantity='';
          p.editMode = false;
          p.icon = this.addIcon;
        }
      });

      this.dataSourceOR.data.sort(function (a, b) {
        return b.isAdded - a.isAdded;
      });
      let newProductList = this.ProductsToAdd.filter((i) => i.Name !== itm.Name);
      this.ProductsToAdd = newProductList;
    }
  }
  clearSearchvalue() {
    this.serachTextSF = '';
    this.filterTable('', 'Name')
  }
  clearSearchvalueCat() {
    this.serachTextSFCategory = '';
    this.filterTable('', 'Category')
  }
  selectedScheduleType(type) {
    this.facilityAccessFlag = type.value
    this.schedulePickupErrorMsg = '';
  }
  onChangeScheduleDate(date) {
    this.scheduleDate = new Date(date).toISOString();
  }
  changeCategory(){
    let cateogryName = '';
    this.newItem.selectedCategory = this.selectedCategory;
  }
}

