import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ForgotDialogComponent } from './../forgot-dialog/forgot-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PasswordStrengthValidator } from '../PasswordStrengthValidators';
import { AccountService } from 'src/app/shared/account.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AppState } from 'src/app/shared/app-state';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { HelpScreenComponent } from 'src/app/dashboard/templates/help-screen/help-screen.component';
import { CustomerService } from 'src/app/shared/services/customer.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public ownerForm: FormGroup;
  public forgotForm: FormGroup;
  public passwordForm: FormGroup;

  invalidemail = false;
  selectedEmoji: string;
  hide = true;
  loginError = '';
  loginForm: any;
  showValidators: boolean;
  dataValidators: boolean;
  buttonDisabled: boolean;
  loginDiv: boolean;
  forgetPasswordDiv: boolean;
  recoveryCodeDiv: boolean;
  resetPasswordDiv: boolean;
  passwordError: boolean;
  invalidForgotEmail: boolean;
  showForgotPassowrdError: boolean;
  forgotPasswordMsg: any;
  recoveryCode;
  recoveryCodeRequired;
  recoveryCodeResponse;
  showRecoveryCodeMsg;
  resendCodeSuccesfull: boolean;
  recoveryId;
  recoveryToken;
  newPassword;
  confirmPassword;
  passswordDonotMatch: boolean = false;
  successFullDiv: boolean;
  confrmPasswordReq: boolean;
  newPasswordReq: boolean = false;

  constructor(
    private accountService: AccountService,
    private state: AppState,
    private location: Location,
    private ClientFilterdata: ClientFilterService,
    private router: Router,
    private spinnerService: SpinnerService,
    private AdminService: AdmintoolsService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {}
  email: string;
  password: string;
  forgotemail: string;
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialog = this.dialog.open(ForgotDialogComponent, {
      width: '615.8px',
      disableClose: true,
      data: 'This text is passed into the dialog!',
    });
    dialog.afterClosed().subscribe((selection) => {
      if (selection) {
        this.selectedEmoji = selection;
      } else {
        // User clicked 'Cancel' or clicked outside the dialog
      }
    });
  }
  ngOnInit() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      location.href = '/dashboard';
    } else {
      this.ownerForm = this.formBuilder.group({
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
            Validators.required,
          ]),
        ],
        password: [null, Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      });
      this.forgotForm = this.formBuilder.group({
        forgotemail: [
          '',
          Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'), Validators.required]),
        ],
      });
      this.passwordForm = this.formBuilder.group(
        {
          newPassword: [null, Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
          confirmPassword: [null],
        }
        // { validator: this.checkPasswords }
      );
      this.showLoginDiv();
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    if (controlName == 'email' || controlName == 'password') {
      return this.ownerForm.controls[controlName].hasError(errorName);
    }

    if (controlName == 'forgotemail') {
      return this.forgotForm.controls[controlName].hasError(errorName);
    }
  };

  public onCancel = () => {
    this.location.back();
  };
  login(formValues: { email: string; password: string; RememberMe: boolean }) {
    // if (this.ownerForm.invalid) {
    //   this.invalidemail = true;
    //   setTimeout(() => {
    //     this.invalidemail = false;
    //   }, 3000);
    // }
    if (!this.email || this.email == undefined || this.email == '') {
      this.invalidemail = true;
      setTimeout(() => {
        this.invalidemail = false;
      }, 3000);
    } else if (!this.password || this.password == undefined || this.password == '') {
      this.passwordError = true;
      setTimeout(() => {
        this.passwordError = false;
      }, 3000);
    } else {
      if (this.ownerForm.valid) {
        this.showValidators = false;
        this.state.isSpinnerVisible = true;
        this.buttonDisabled = true;
        var spinnerRef = this.spinnerService.start();
        this.accountService.login(formValues.email, formValues.password).subscribe(
          (data) => {
            this.spinnerService.stop(spinnerRef);
            //this.performLoginTask(data);
            if (data && (!data.error || data.error < 1)) {
              this.state.isLoggedIn = true;
              this.state.user = data;
              (<any>window).godrEmail = formValues.email;
              (<any>window).godrRole = this.state.user.User.Roles[0].Name;

              localStorage.setItem('user', JSON.stringify(this.state));
              spinnerRef = this.spinnerService.start();
              this.customerService.getAllUsersClients().subscribe(
                (data) => {
                  this.spinnerService.stop(spinnerRef);
                  if (data) {
                    let localUser = JSON.parse(localStorage.getItem('user'));
                    localUser.user.User.Clients = data['Result'];
                    localStorage.setItem('user', JSON.stringify(localUser));
                    this.state.user = JSON.parse(localStorage.getItem('user')).user;

                    this.router.navigate(['dashboard']);
                  } else {
                  }
                },
                (error) => {
                  this.spinnerService.stop(spinnerRef);
                  alert(error);
                }
              );
            } else {
              this.loginError = data.message;
              this.buttonDisabled = false;
              this.dataValidators = true;
              setTimeout(() => {
                this.dataValidators = false;
              }, 3000);
            }
          },
          (error) => {
            this.state.isSpinnerVisible = false;
            // alert(JSON.stringify(error));
            this.spinnerService.stop(spinnerRef);
            this.buttonDisabled = false;
          }
        );
      } else {
        this.showValidators = true;
        setTimeout(() => {
          this.showValidators = false;
        }, 3000);
      }
    }
  }
  private normalizeUserData() {
    this.state.user.clients.forEach((c) => {
      c.id = Number(c.id);
    });
    if (this.state.user.error) {
      this.state.user.error = Number(this.state.user.error);
    }
    if (this.state.user.profile) {
      this.state.user.profile.id = Number(this.state.user.profile.id);
    }
  }
  performLoginTask(data) {
    this.state.isSpinnerVisible = false;
    if (data && (!data.error || data.error < 1)) {
      this.state.isLoggedIn = true;
      this.state.user = data;
      // this.invalidemail=true;
      // this.normalizeUserData();

      this.state.user = data;

      localStorage.setItem('user', JSON.stringify(this.state));

      // if (formValues.RememberMe) {
      //   localStorage.setItem('state', JSON.stringify(this.state));
      // }
      this.router.navigate(['dashboard']);
      // if (this.state.user.User.Roles[0].Name === 'Facility' || this.state.user.User.Roles[0].Name === 'Facility Group') {
      //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
      //     const dialogRef = this.dialog.open(HelpScreenComponent, {
      //       width: '620px',
      //       panelClass: 'custom-savedialog-container',
      //       data: [{
      //         role: this.state.user.User.Roles[0].Name
      //       }]
      //     });
      //     dialogRef.afterClosed().subscribe(result => {
      //     });
      //   }
      // }
    } else {
      this.loginError = data.message;
      this.buttonDisabled = false;
      this.dataValidators = true;
      setTimeout(() => {
        this.dataValidators = false;
      }, 3000);
    }
  }

  showLoginDiv() {
    this.loginDiv = true;
    this.forgetPasswordDiv = false;
    this.recoveryCodeDiv = false;
    this.resetPasswordDiv = false;
    this.forgotemail = '';
    this.recoveryCode = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.successFullDiv = false;
  }
  showForgetPassword() {
    this.loginDiv = false;
    this.forgetPasswordDiv = true;
    this.recoveryCodeDiv = false;
    this.resetPasswordDiv = false;
    this.invalidemail = false;
    this.passwordError = false;
    this.successFullDiv = false;
  }

  confirmEmailId() {
    // this.showRecoveryCodeDiv();

    if (!this.forgotemail || this.forgotemail == '' || this.forgotemail == undefined) {
      this.invalidForgotEmail = true;
      setTimeout(() => {
        this.invalidForgotEmail = false;
      }, 3000);
    } else {
      if (this.forgotForm.valid) {
        var spinnerRef = this.spinnerService.start();
        const body = {
          Email: this.forgotemail,
        };
        this.accountService.openforgotDialog(body).subscribe((data) => {
          this.spinnerService.stop(spinnerRef);
          this.forgotPasswordMsg = data.message;
          if (this.forgotPasswordMsg === 'Email does not exist') {
            this.showForgotPassowrdError = true;
            setTimeout(() => {
              this.showForgotPassowrdError = false;
            }, 3000);
          } else {
            this.showRecoveryCodeDiv();
          }
        });
      }
    }
  }

  verifyRecoveryCode() {
    if (!this.recoveryCode || this.recoveryCode.length < 6 || this.recoveryCode == undefined) {
      this.recoveryCodeRequired = true;
      setTimeout(() => {
        this.recoveryCodeRequired = false;
      }, 3000);
    } else {
      var spinnerRef = this.spinnerService.start();
      const body = {
        Email: this.forgotemail,
        ResetCode: this.recoveryCode,
      };
      this.accountService.recoveryCode(body).subscribe(
        (res) => {
          this.spinnerService.stop(spinnerRef);
          this.recoveryCodeResponse = res.message;
          if (this.recoveryCodeResponse === undefined) {
            this.recoveryId = res.Id;
            this.recoveryToken = res.Token;

            this.showResetPasswordDiv();
          } else if (this.recoveryCodeResponse === 'In-valid Reset code' || 'Reset code Required') {
            this.recoveryCodeResponse = 'Please enter valid recovery code';
            this.showRecoveryCodeMsg = true;
          }
          setTimeout(() => {
            this.showRecoveryCodeMsg = false;
          }, 3000);
        },
        (error) => {
          this.recoveryCodeResponse = 'Please enter valid recovery code';
          this.showRecoveryCodeMsg = true;
        }
      );
    }
  }

  resendRecoveryCode() {
    var spinnerRef = this.spinnerService.start();
    const body = {
      Email: this.forgotemail,
    };
    this.accountService.openforgotDialog(body).subscribe((res) => {
      this.spinnerService.stop(spinnerRef);
      this.recoveryCodeResponse = res.message;
      if (this.recoveryCodeResponse === 'Email does not exist') {
      } else {
        this.resendCodeSuccesfull = true;
      }
      setTimeout(() => {
        this.resendCodeSuccesfull = false;
      }, 3000);
    });
  }

  setNewPassword() {
    if (this.newPassword.length == 0) {
      this.newPasswordReq = true;
      setTimeout(() => {
        this.newPasswordReq = false;
      }, 3000);
    } else {
      if (this.confirmPassword.length == 0) {
        this.confrmPasswordReq = true;
        setTimeout(() => {
          this.confrmPasswordReq = false;
        }, 3000);
      } else {
        if (this.passwordForm.valid) {
          if (this.newPassword === this.confirmPassword) {
            const payload = {
              Token: this.recoveryToken,
              Id: this.recoveryId,
              Password: this.newPassword,
              IsInvited: false,
            };

            var spinnerRef = this.spinnerService.start();
            this.accountService.forgorPasswordCode(payload).subscribe(
              (data) => {
                this.spinnerService.stop(spinnerRef);
                this.showSuccessfullDiv();
              },
              (error) => {
                this.spinnerService.stop(spinnerRef);
                alert(JSON.stringify(error));
              }
            );
          } else {
            this.passswordDonotMatch = true;
            setTimeout(() => {
              this.passswordDonotMatch = false;
            }, 3000);
          }
        } else if (this.confirmPassword == '' || this.confirmPassword == undefined) {
          this.passswordDonotMatch = true;
          setTimeout(() => {
            this.passswordDonotMatch = false;
          }, 3000);
        } else {
        }
      }
    }
  }

  showRecoveryCodeDiv() {
    this.loginDiv = false;
    this.forgetPasswordDiv = false;
    this.recoveryCodeDiv = true;
    this.resetPasswordDiv = false;
    this.successFullDiv = false;
  }

  showResetPasswordDiv() {
    this.loginDiv = false;
    this.forgetPasswordDiv = false;
    this.recoveryCodeDiv = false;
    this.resetPasswordDiv = true;
    this.successFullDiv = false;
  }

  showSuccessfullDiv() {
    this.loginDiv = false;
    this.forgetPasswordDiv = false;
    this.recoveryCodeDiv = false;
    this.resetPasswordDiv = false;
    this.successFullDiv = true;
  }
}
