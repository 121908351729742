import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { OnDestroy } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { Bank, BANKS, Role } from '../../profileinfo/transferownershipdialog/demo-data';
@Component({
  selector: 'app-transferownershipdialog',
  templateUrl: './transferownershipdialog.component.html',
  styleUrls: ['./transferownershipdialog.component.scss']
})
export class TransferownershipdialogComponent implements OnInit {
  radioOptions: string = 'option 2';


  public emailForm: FormGroup;
  /** list of banks */
  protected banks: Bank[] = BANKS;
  protected Role: Role[] = Role;

  /** control for the selected bank for server side filtering */
  public bankServerSideCtrl: FormControl = new FormControl();
  public roleServerSideCtrl: FormControl = new FormControl();

  /** control for filter for server side. */
  public bankServerSideFilteringCtrl: FormControl = new FormControl();
  public roleServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  /** list of banks filtered after simulating server side search */
  public filteredServerSideBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  public filteredServerSiderole: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  exting = [
    { "name": "no", ID: "D1", "checked": true },
    { "name": "yes", ID: "D2", "checked": false }
  ]
  existingadmin: boolean;
  existingadminno = true;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<TransferownershipdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.emailForm = this.formBuilder.group({

      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        Validators.required])],

    });
    this.bankServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.banks) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
        }),
        delay(500)
      )
      .subscribe(filteredBanks => {
        this.searching = false;
        this.filteredServerSideBanks.next(filteredBanks);
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });
    this.roleServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.Role) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.Role.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
        }),
        delay(500)
      )
      .subscribe(filteredBanks => {
        this.searching = false;
        this.filteredServerSiderole.next(filteredBanks);
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.emailForm.controls[controlName].hasError(errorName);

  }
  onSelectionChange(event) {
    if (event.value == 'option 1') {
      this.existingadmin = true;
      this.existingadminno = false;
    } else {
      this.existingadminno = true;
      this.existingadmin = false;
    }

  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
