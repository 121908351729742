import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-succes-popup',
  templateUrl: './succes-popup.component.html',
  styleUrls: ['./succes-popup.component.scss']
})
export class SuccesPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SuccesPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
