import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ConditionalExpr } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'app-add-main-category-dialog',
  templateUrl: './add-main-category-dialog.component.html',
  styleUrls: ['./add-main-category-dialog.component.scss']
})
export class AddMainCategoryDialogComponent implements OnInit {
  itemName: any;
  itemPrice: any;
  itemWeight: any;
  categories: any;
  selectedCategory: any;
  isPagination: any;
  requestData: any;
  categoryName: any;
  invaliddata: boolean;
  errorMessage: boolean;
  buttonDisabled: boolean;
  clientid: any;
  IsGoodrCategory: boolean;
  errorMessageduplicate: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddMainCategoryDialogComponent>,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private CustomerService: CustomerService,
    private state: AppState,
    public capitailizepipe: specialPipe,
  ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() { }

  //POST-NEW-ITEM
  addNewCategory() {
    if (this.data) {
      this.IsGoodrCategory = false;
    } else {
      this.IsGoodrCategory = true;
    }
    if (this.categoryName) {
      const payload = {
        Name: this.capitailizepipe.transform(this.categoryName.replace(/^\s+/g, '')),
        Description: this.categoryName.replace(/^\s+/g, ''),
        CreatedBy: null,
        CreatedOn: null,
        ModifiedBy: null,
        ModifiedOn: null,
        ApprovalStatusId: null,
        ParentId: null,
        IsGoodrCategory: this.IsGoodrCategory,
        ClientId: this.data
      };
      this.buttonDisabled = true;
      this.CustomerService.addNewCategory(payload).subscribe(
        data => {
          // this.buttonDisabled = true;
          if (data === 'Duplicate Entry') {
            this.buttonDisabled = false;
            this.errorMessageduplicate = true;
            setTimeout(() => {
              this.errorMessageduplicate = false;
            }, 3000);
          } else {
            let res = 'success';
            this.dialogRef.close(res);
            this.state.isSpinnerVisible = false;
          }
        },
        error => {
          if (error.statusText === 'Internal Server Error') {
            this.buttonDisabled = false;
            this.errorMessage = true;
            this.state.isSpinnerVisible = false;
          }
        }
      );
    } else {
      this.invaliddata = true;
      setTimeout(() => {
        this.invaliddata = false;
      }, 3000);
    }
  }
}
