import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class modalDataService {
  basicInfoDate = {
    requestStatusinVis: ''
  };
  basicinfotoggle = {
    checkToggle: ''
  };
  basicinfo1 = {
    client: '',
    status: '',
    Delivery: '',
    Recipient: '',
    pageNumber: '',
    pageSize: '',
    finalFromDate: '',
    finalToDate: '',
    sortColumn: '',
    facilitatorId: '',
    facilityGroupId: '',
    StatusId: '',
    selectedRecipientName: '',
    selectedclientName: '',
    active: '',
    pickupsPage: '',
    Pickup: '',
    DriverService: ''
  };
  basicupdateinfo2 = {
    coupendata: null,
    totalfinalpaid: '',
    SAASupdate: '',
    updateclient: '',
    drivertitle: ''
  };
  basicreloaddataupdateinfo2 = {
    reloadData: false,
    reloadphotoData: false,
    reloadmenuData: false,
    reloadclientdata: false
  };
  basicinfo2 = {
    // cityName: '',
    AgreementFlag: 'true',
    timezone: '',
    SeparatedCompostID: '',
    profileImageUrl: '',
    coupendata: '',
    totalfinalpaid: '',
    nosaasFG: '',
    accountName: '',
    facility_type: '',
    isUnderFacilityGroup: '',
    isUnderFacilityGroupFR: '',
    isUnderFacilatorGroup: '',
    email: '',
    driverService: '',
    account_type: 'Facility',
    NofPeoples: '',
    saascreatemenu: '',
    city: '',
    entityName: '',
    country: 'USA',
    pickupTypes: '',
    NotificationTypes: '',
    taxId: '',
    businessAddress: '',
    address: '',
    zip: '',
    state: '',
    Phone: '',
    PhoneNumber: '',
    facilityAccess: '',
    NonProfitWorkingSlots: '',
    adminEmail: '',
    adminLastName: '',
    adminFirstName: '',
    selectedAccountType: '',
    roleId: '',
    isMenuEdit: '',
    FacilitatorId: '',
    FacilityGroupId: '',
    FacilityGroupFRId: '',
    new_account: '',
    accountTypetrue: '',
    business_Detail: '',
    user_Details: '',
    create_Menu: '',
    selectedDriver: '',
    driverNotes: '',
    clientId: '',
    userId: '',
    menutype: '',
    WayPointID: '',
    scheduler:'',
  };
  basicUserinfo = {
    globalStatus: '',
    globalRole: '',
    globalAccount: '',
    globalEmail: '',
    globalLastName: '',
    globalFirstName: '',
    pageNumber: '',
    pageSize: '',
    sortColumn: '',
    selectedAccountName: ''
  };
  private basicupdateInfo2Data = new BehaviorSubject<string>(
    JSON.stringify(this.basicupdateinfo2)
  );
  private basicreloaddataInfo2Data = new BehaviorSubject<string>(
    JSON.stringify(this.basicreloaddataupdateinfo2)
  );
  private basicInfo2Data = new BehaviorSubject<string>(
    JSON.stringify(this.basicinfo2)
  );
  private basicInfo1Data = new BehaviorSubject<string>(
    JSON.stringify(this.basicinfo1)
  );
  private basicInfotoggle = new BehaviorSubject<string>(
    JSON.stringify(this.basicinfotoggle)
  );
  private basicInfoDateData = new BehaviorSubject<string>(
    JSON.stringify(this.basicInfoDate)
  );
  private basicUserinfoData = new BehaviorSubject<string>(
    JSON.stringify(this.basicUserinfo)
  );
  basicreloaddataInfo2Observe = this.basicreloaddataInfo2Data.asObservable();
  basicupdateInfo2Observe = this.basicupdateInfo2Data.asObservable();
  basicInfo2Observe = this.basicInfo2Data.asObservable();
  basicInfo1Observe = this.basicInfo1Data.asObservable();
  basicInfotoggleObserve = this.basicInfotoggle.asObservable();
  basicUserinfoObserve = this.basicUserinfoData.asObservable();
  basicInfoDateObserve = this.basicInfoDateData.asObservable();
  updateBasicInfo2(data: string) {
    this.basicInfo2Data.next(data);
  }
  resetBasicInfo2(data: string) {
    this.basicInfo2Data.next(JSON.stringify(this.basicinfo2));
  }
  updatereloaddataclientBasicInfo2(data: string) {
    this.basicreloaddataInfo2Data.next(data);
  }
  updateclientBasicInfo2(data: string) {
    this.basicupdateInfo2Data.next(data);
  }
  resetclientBasicInfo2(data: string) {
    this.basicupdateInfo2Data.next(JSON.stringify(this.basicupdateinfo2));
  }

  updateBasicInfo1(data: string) {
    this.basicInfo1Data.next(data);
  }
  updateBasicInfotoggle(data: string) {
    this.basicInfotoggle.next(data);
  }
  updateBasicInfoDate(data: string) {
    this.basicInfoDateData.next(data);
  }
  resetBasicInfo1(data: string) {
    this.basicInfo1Data.next(JSON.stringify(this.basicUserinfo));
  }
  updatebasicUserinfo(data: string) {
    this.basicUserinfoData.next(data);
  }
  resetbasicUserinfo(data: string) {
    this.basicUserinfoData.next(JSON.stringify(this.basicUserinfo));
  }
}
