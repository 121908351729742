import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AppState } from 'src/app/shared/app-state';
import { Router, ActivatedRoute } from '@angular/router';
import { SuccesPopupComponent } from '../../succes-popup/succes-popup.component';
import { SpinnerService } from 'src/app/core/spinner.service';
import { SAASService } from 'src/app/shared/services/saas.service';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  address: string;
  address1: string;
  city: string;
  stateCode: string;
  mobile: string;
  email: string;
  clientData: any;
  zip: any;
  country: any;
  requireZipCode: boolean;
  requireCountry: boolean;
  requireCity: boolean;
  requireState: boolean;
  requireAddress1: boolean;
  requireTaxID: boolean;
  invalidEmail: boolean;
  requireEmail: boolean;
  dataerror: string;
  user: any;
  UserId: any;
  ClientId: any;
  dissuccess = true;
  yearlyPlan: boolean;
  noPlaning: boolean;
  constructor(public dialogRef: MatDialogRef<DeletePopupComponent>,
    private state: AppState, private route: ActivatedRoute, private router: Router, public dialog: MatDialog, private sassService: SAASService, private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }
  cancelSub() {
    const payload = {
      CancelSubscription: true,
      Update: true,
      ClientId: this.data[0].ClientId,
      UserId: this.data[1].UserId,
    };

    const spinnerRef = this.spinnerService.start();
    this.sassService.striping(payload).subscribe(
      data => {
        let finalData;
        if (data.message === 'Updated Successfully') {
          finalData = 'Successfully Cancelled';
          this.dialogRef.close('res');
          this.spinnerService.stop(spinnerRef);
          const dialogRef = this.dialog.open(SuccesPopupComponent,
            {
              width: '480px',
              // height: '480px',
              data: { ClientData: finalData },
              panelClass: 'custom-editSub-container',
            });
          dialogRef.afterClosed().subscribe(result => {
          }
          );
        } else {
          this.dialogRef.close();
          alert(data.message);
        }
        this.spinnerService.stop(spinnerRef);
      },
      error => {
        this.spinnerService.stop(spinnerRef);
        // this.buttonDisabled = false;
      }
    );
  }
}
