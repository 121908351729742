import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { getFeature } from 'src/app/shared/services/featureGuard.model';

@Component({
  selector: 'app-customerFilter',
  templateUrl: './customerFilter.component.html',
  styleUrls: ['./customerFilter.component.scss'],
})
export class CustomerFilterComponent implements OnInit {
  clientCategories: any;
  clientCategoriesList: any;
  pickupTypeData: any;
  pickupTypeList: any;
  rolesData: any;
  state: any;
  rolesList: any;
  clientName: any;
  administratorName: any;
  customerEmail: any;
  facilityGroupId: any;
  selectedClientType: any;
  selectedPickupType: any;
  facilitatorClients: any;
  facilityGroupClients: any;
  menus: any;
  selectedMenu: any;
  clientId: any;
  facilityGroupClient: any;
  facilitatorClient: any;
  menuTypes: any;
  menuCategoriesList: any;
  info: any;
  approvalStatusData: any;
  approvalStatusList: any[];
  selectedStatus: any;
  driverSelected: any;
  selectedFacilitator: any;
  selectedFacilityGroup: any;
  featureinfo: any;
  SAASFeature: boolean;
  PickupList: any;
  selectedPickup: any;
  Pickuptypeid: any;
  Visibledriverservice = true;

  constructor(
    private AdminService: AdmintoolsService,
    private formBuilder: FormBuilder,
    private pickupService: PickUpService,
    private ClientFilterdata: ClientFilterService,
    public dialogRef: MatDialogRef<CustomerFilterComponent>,
    private CustomerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.Visibledriverservice = getFeature('driverservices');
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      this.facilityGroupClients = this.info.facilitatorGroup;
      this.facilitatorClients = this.info.facilitator;
    });
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);

      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            this.SAASFeature = false;
          }
        }
      }
    });

    this.pickupService.getPickupFilterList().subscribe((data) => {
      this.PickupList = data.Result;
      // this.selectedPickup = this.data['Pickuptypeid'];
      for (var i = 0; i < this.PickupList.length; i++) {
        if (this.PickupList[i].ID === this.data['Pickuptypeid']) {
          this.Pickuptypeid = this.PickupList[i].ID;
          this.selectedPickup = this.PickupList[i].Title;
        }
      }
    });
    this.clientName = this.data['clientName'];
    this.clientId = this.data['clientId'];
    this.customerEmail = this.data['customerEmail'];
    for (var i = 0; i < this.facilitatorClients.length; i++) {
      if (
        this.facilitatorClients[i].ClientId === this.data['facilitatorClient']
      ) {
        this.facilitatorClient = this.facilitatorClients[i].ClientId;
        this.selectedFacilitator = this.facilitatorClients[i].Name;
      }
    }
    this.selectedStatus = this.data['selectedStatus'];
    for (var i = 0; i < this.facilityGroupClients.length; i++) {
      if (
        this.facilityGroupClients[i].ClientId ===
        this.data['facilityGroupClient']
      ) {
        this.facilityGroupClient = this.facilityGroupClients[i].ClientId;
        this.selectedFacilityGroup = this.facilityGroupClients[i].Name;
      }
    }
    this.CustomerService.getMenuTypes().subscribe(
      (data) => {
        this.menuTypes = data;
        const menuTypescategories = [];
        for (let i = 0; i < data.Result.length; i++) {
          this.menuTypes = data.Result[i];
          const menusdata = {
            ID: this.menuTypes.ID,
            Title: this.menuTypes.Title,
          };
          menuTypescategories.push(menusdata);
        }
        this.menuCategoriesList = menuTypescategories;
        for (var i = 0; i < this.menuCategoriesList.length; i++) {
          if (this.menuCategoriesList[i].Title === this.data['selectedMenu']) {
            this.selectedMenu = this.menuCategoriesList[i].Title;
          }
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    this.AdminService.getUserStatus().subscribe(
      (data) => {
        this.approvalStatusData = data;
        const status = [];
        for (let i = 0; i < data['Result'].length; i++) {
          this.approvalStatusData = data['Result'][i];
          const status_data = {
            Id: this.approvalStatusData.ID,
            Title: this.approvalStatusData.Title,
          };
          status.push(status_data);
        }
        // this.approvalStatusList = status;
        this.approvalStatusList = status.filter(
          (value) => value.Title !== 'Trash'
        );
        for (var i = 0; i < this.approvalStatusList.length; i++) {
          if (this.approvalStatusList[i].Title == this.data['Status']) {
            this.selectedStatus = this.approvalStatusList[i].Title;
          }
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    this.CustomerService.v2_getclientcategories().subscribe(
      (res) => {
        this.clientCategories = res;
        const categories = [];
        for (let i = 0; i < res['Result'].length; i++) {
          this.clientCategories = res['Result'][i];
          const categories_data = {
            ClientCategoryId: this.clientCategories.ClientCategoryId,
            Title: this.clientCategories.Title,
          };
          categories.push(categories_data);
        }
        this.clientCategoriesList = categories;
        if (this.SAASFeature === false) {
          this.clientCategoriesList = this.clientCategoriesList.filter(
            (value) =>
              value.Title !== 'SAAS Facility' &&
              value.Title !== 'SAAS Facility Group'
          );
        }
        for (var i = 0; i < this.clientCategoriesList.length; i++) {
          if (
            this.clientCategoriesList[i].Title ==
            this.data['selectedClientType']
          ) {
            this.selectedClientType = this.clientCategoriesList[i].Title;
          }
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    this.CustomerService.getPickupTypeList().subscribe(
      (data) => {
        this.pickupTypeData = data;
        const pickupType = [];
        for (let i = 0; i < data['Result'].length; i++) {
          this.pickupTypeData = data['Result'][i];
          const pickupType_data = {
            Id: this.pickupTypeData.ID,
            Title: this.pickupTypeData.Title,
          };
          pickupType.push(pickupType_data);
        }
        this.pickupTypeList = pickupType;
        // if (this.SAASFeature === false) {
        //   this.pickupTypeList = this.pickupTypeList.filter(
        //     value => value.Title !== 'SAAS'
        //   );
        // }
        for (var i = 0; i < this.pickupTypeList.length; i++) {
          if (this.pickupTypeList[i].Id === this.data['selectedPickupType']) {
            this.selectedPickupType = this.pickupTypeList[i].Id;
            this.driverSelected = this.pickupTypeList[i].Title;
          }
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  selectedDriver() {
    for (const pickupType of this.pickupTypeList) {
      if (pickupType.Id === this.selectedPickupType) {
        this.selectedPickupType = pickupType.Id;
        this.driverSelected = pickupType.Title;
      }
    }
  }
  selectedfacilitator() {
    for (const row of this.facilitatorClients) {
      if (row.ClientId === this.facilitatorClient) {
        this.facilitatorClient = row.ClientId;
        this.selectedFacilitator = row.Name;
      }
    }
  }
  selectedPickuptype() {
    for (const row of this.PickupList) {
      if (row.ID === this.Pickuptypeid) {
        this.Pickuptypeid = row.ID;
        this.selectedPickup = row.Title;
      }
    }
  }
  selectedfacilitygroup() {
    for (const row of this.facilityGroupClients) {
      if (row.ClientId === this.facilityGroupClient) {
        this.facilityGroupClient = row.ClientId;
        this.selectedFacilityGroup = row.Name;
      }
    }
  }
  closeAddFilter(closeData) {
    if (closeData === 'clientName') {
      this.clientName = null;
    } else if (closeData === 'clientId') {
      this.clientId = null;
    } else if (closeData === 'Facilitator') {
      this.facilitatorClient = null;
    } else if (closeData === 'pickuptypenu') {
      this.Pickuptypeid = null;
    } else if (closeData === 'selectedfacilityGroup') {
      this.facilityGroupClient = null;
    } else if (closeData === 'Email') {
      this.customerEmail = null;
    } else if (closeData === 'DriverService') {
      this.selectedPickupType = null;
    } else if (closeData === 'MenuType') {
      this.selectedMenu = null;
    } else if (closeData === 'selectedClientType') {
      this.selectedClientType = null;
    } else if (closeData === 'selectedStatus') {
      this.selectedStatus = null;
    }
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  customerFilter() {
    if (this.clientName === undefined) {
      this.clientName = null;
    }
    if (this.administratorName === undefined) {
      this.administratorName = null;
    }
    if (this.customerEmail === undefined) {
      this.customerEmail = null;
    }
    if (this.facilityGroupId === undefined) {
      this.facilityGroupId = null;
    }
    if (this.selectedClientType === undefined) {
      this.selectedClientType = null;
    }
    if (this.selectedPickupType === undefined) {
      this.selectedPickupType = null;
    }
    if (this.selectedMenu === undefined) {
      this.selectedMenu = null;
    }
    if (this.clientId === undefined) {
      this.clientId = null;
    }
    if (this.facilityGroupClient === undefined) {
      this.facilityGroupClient = null;
    }
    if (this.facilitatorClient === undefined) {
      this.facilitatorClient = null;
    }
    if (this.selectedStatus === undefined) {
      this.selectedStatus = null;
    }
    const data = {
      ClientName: this.clientName,
      Administrator: this.administratorName,
      Email: this.customerEmail,
      FacilityGroup: this.facilityGroupId,
      ClientType: this.selectedClientType,
      PickupType: this.selectedPickupType,
      SelectedMenuType: this.selectedMenu,
      clientId: this.clientId,
      facilityGroup: this.facilityGroupClient,
      facilitator: this.facilitatorClient,
      pickuptypeid: this.Pickuptypeid,
      Status: this.selectedStatus,
      filterDriver: this.driverSelected,
      filterFacilitator: this.selectedFacilitator,
      filterFacilitygroup: this.selectedFacilityGroup,
      filterPickupType: this.selectedPickup,
    };
    this.dialogRef.close(data);
  }
}
