import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { PromotionCodePricingCreateComponent } from '../promotion-code-pricing-create/promotion-code-pricing-create.component';
import { PromotionCodePricingDeleteComponent } from '../promotion-code-pricing-delete/promotion-code-pricing-delete.component';
import { PromotionCodePricingEditComponent } from '../promotion-code-pricing-edit/promotion-code-pricing-edit.component';
import { PricingPromotionCodeService } from '../../../shared/services/pricing-promotion-code.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import * as moment from 'moment-timezone';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';

@Component({
  selector: 'app-promotion-code-pricing',
  templateUrl: './promotion-code-pricing.component.html',
  styleUrls: ['./promotion-code-pricing.component.scss']
})
export class PromotionCodePricingComponent implements OnInit, AfterViewInit {
  ELEMENT_DATA: any = [];
  pageNumber: number;
  pageSize: number;
  selectedPageSize = [10, 25, 50, 100];
  sortColumn: any = null;
  RecordCount: any;
  displayedColumns: string[] = [
    'promoname',
    'type',
    'frequency',
    'startdate',
    'enddate',
    'discount',
    'actions'
  ];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public dateFormat: dateFormatPipe,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private promotionCode: PricingPromotionCodeService
  ) {}

  ngOnInit() {
    this.getPromotionCodes();
  }

  getPromotionCodes() {
    if (!this.pageNumber || !this.pageSize) {
      this.pageNumber = 1;
      this.pageSize = 10;
    }
    var spinnerRef = this.spinnerService.start();
    this.promotionCode
      .getPromotionCode(this.pageNumber, this.pageSize, this.sortColumn)
      .subscribe(data => {
        this.spinnerService.stop(spinnerRef);
        this.RecordCount = data.RecordCount;
        data.Result.forEach(y => {
          let startdate;
          startdate = new Date(
            this.dateFormat.transform(y.ValidationStartDate, 'America/New_York')
          );
          y.ValidationStartDate = moment(startdate).format('MM/DD/YY');
          let enddate;
          enddate = new Date(
            this.dateFormat.transform(y.ValidationEndDate, 'America/New_York')
          );
          y.ValidationEndDate = moment(enddate).format('MM/DD/YY');
        });
        this.dataSource = new MatTableDataSource(data.Result);
        this.paginator.pageIndex = this.pageNumber - 1;
      });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  createNew() {
    const dialogRef = this.dialog.open(PromotionCodePricingCreateComponent, {
      width: '600px',

      data: {},
      panelClass: 'custom-editdialog-container',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPromotionCodes();
      }
    });
  }

  editPopup() {
    const dialogRef = this.dialog.open(PromotionCodePricingEditComponent, {
      width: '600px',
      data: {},
      panelClass: 'custom-editdialog-container',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPromotionCodes();
      }
    });
  }

  deletePopup(promoCodeId) {
    const dialogRef = this.dialog.open(PromotionCodePricingDeleteComponent, {
      width: '500px',
      data: { Id: promoCodeId },
      panelClass: 'custom-editdialog-container',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPromotionCodes();
      }
    });
  }

  copyPromoCode(val, id) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    document.getElementById(id).style.display = 'block';
    setTimeout(() => {
      document.getElementById(id).style.display = 'none';
    }, 2000);
  }
  //pagination
  onChangePage(e) {
    var spinnerRef = this.spinnerService.start();
    this.pageNumber = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.selectedPageSize = [10, 25, 50, 100];
    this.promotionCode
      .getPromotionCode(this.pageNumber, this.pageSize, this.sortColumn)
      .subscribe(
        data => {
          this.spinnerService.stop(spinnerRef);
          data.Result.forEach(y => {
            let startdate;
            startdate = new Date(
              this.dateFormat.transform(
                y.ValidationStartDate,
                'America/New_York'
              )
            );
            y.ValidationStartDate = moment(startdate).format('MM/DD/YY');
            let enddate;
            enddate = new Date(
              this.dateFormat.transform(y.ValidationEndDate, 'America/New_York')
            );
            y.ValidationEndDate = moment(enddate).format('MM/DD/YY');
          });
          this.dataSource = new MatTableDataSource(data.Result);
          this.paginator.pageIndex = this.pageNumber - 1;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.RecordCount = e.length;
        },
        error => {
          // alert(JSON.stringify(error));
          this.spinnerService.stop(spinnerRef);
        }
      );
  }
}
