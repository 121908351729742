import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelServicesService } from 'src/app/shared/services/excel-services.service';
import { AppState } from 'src/app/shared/app-state';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { PickUps } from 'src/app/models/pickups.model';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { CustomerService } from '../../../shared/services/customer.service';
import { AddeditDateComponent } from '../../customers/addedit-date/addedit-date.component';
import * as moment from 'moment-timezone';
import { MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss', '../../dashboard/dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      @font-face {
        font-family: 'ProximaNova-Extrabold';
        src: url('assets/fonts/proxima-nova-fonts/Proxima-Nova-Extrabold.ttf') format('truetype');
      }
      @font-face {
        font-family: 'ProximaNova-Black';
        src: url('assets/fonts/proxima-nova-fonts/Proxima-Nova-Black.ttf') format('truetype');
      }
      @font-face {
        font-family: 'ProximaNova';
        src: url('assets/fonts/proxima-nova-fonts/Proxima-Nova.ttf') format('truetype');
      }
      @font-face {
        font-family: 'ProximaNova-Bold';
        src: url('assets/fonts/proxima-nova-fonts/Proxima-Nova-Bold.ttf') format('truetype');
      }
      @font-face {
        font-family: 'ProximaNova-Light';
        src: url('assets/fonts/proxima-nova-fonts/Proxima-Nova-Light.ttf') format('truetype');
      }
      @font-face {
        font-family: 'ProximaNova-Regular';
        src: url('assets/fonts/proxima-nova-fonts/ProximaNovaRegular.ttf') format('truetype');
      }
      .k-pdf-export .imgStyle {
        width: 15%;
      }
      .export-elements {
        display: none;
      }
      .k-pdf-export .export-elements {
        display: block;
      }
      .k-grid {
        font-family: 'ProximaNova-Extrabold', 'ProximaNova-Black', 'ProximaNova', 'ProximaNova-Light', 'ProximaNova-Bold', 'ProximaNova-Regular';
        font-weight: 900;
      }
      .k-pdf-export .overview-component article h5 {
        font-size: 13px;
        line-height: 15px;
      }
      .k-pdf-export .non-profit-title {
        font-size: 10px;
        font-family: 'ProximaNova', 'Arial', sans-serif;
        line-height: 13px;
        width: 100% !important;
        word-break: break-word;
      }
      .k-pdf-export .desktabView h2 {
        font-size: 16px;
        margin-top: -26px;
      }
      .k-pdf-export .desktabView h4 {
        font-size: 13px;
      }
      .k-pdf-export #middle-data h2 {
        font-size: 35px;
      }
      .k-pdf-export .middle-data-header span {
        font-size: 18px;
      }
      .k-pdf-export .exta-div {
        height: 40px;
      }
      .k-pdf-export .pdf-border {
        border: none !important;
      }
      .k-pdf-export .tiles-logo-style {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
        margin-top: 5px;
      }
      .k-pdf-export .overview-component article {
        height: 180px;
      }
      .k-pdf-export .responsiveLogoStyle{
        max-height: 50px !important;
        width: 100% !important;
        margin:auto;
      }
      .k-pdf-export #non-profit-wrapper{
        margin-top: 8%;
      }
      .k-pdf-export .non-profit-container{
       height: 100px !important;
       display:flex;
      }
      .k-pdf-export .responsiveLogoStyleOuter{
        display:flex;
        height: 100px !important;
        width: 100px !important;
      }
    `,
  ],
})
export class ImpactComponent implements OnInit {
  user: any;
  excel = [];
  imgSrc = '../../../../assets/images/Group35.svg';
  imgSrclogo = 'assets/images/logo.png';
  imgSrcForPDF = '../../../../assets/svg/Goodr_Logo_Grey_and_White_Tagline.svg';
  imgSrcForDefaultPDF = '../../../assets/images/logo.png';
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  listNonProfit = [];
  lowestEarningMonth: string;
  accountService: any;
  clientusers: any;
  dashboardData: any = {};
  dashboardGetDataFinal = [];
  dashboardGetDataFinalOR = [];
  downlaod = false;
  dashboard: any;
  clientdata: any;
  editDate: any;
  selectFromDate: string;
  clientId: any = 'all';
  selecteToddate: string;
  finalFromDate: any;
  finalToDate: any;
  deliveryData: PickUps;
  RecordCount: any;
  listdata: PickUps[];
  addfilter: boolean;
  profileImageUrl: any;
  clientname: any;
  ImageClientUrl: boolean;
  ImageClientUrlnull: boolean;
  selectedClientName: any;
  VisibleForAdminOnly: boolean;
  ToDate: string;
  FromDate: string;
  dashboardGetData: PickUps;
  dashboardGetDataOR: PickUps;
  selectedpickupType: any = 'Surplus Food';
  pickupid: any;
  PickupList: any;
  facilitatorId: any;
  totalItems = [];
  facilityGroupId: any;
  spinnerRef: any;
  pickuptypeClients: any;
  HideForNPFR: boolean;
  newNpArray = [];
  MRFList = [];
  @ViewChild('pdf', { static: true }) kendo;
  profileImage: any;
  PickupListData: any;
  ToDatePdf: string;
  FromDatePdf: string;
  featureinfo: any;
  OTPFeature: boolean;
  disableExport: boolean = false;
  now: any = moment().format('MMM DD, YYYY HH:mm');
  spinnerRef1: any;
  showClientDropdown: boolean = true;
  TotalMealCount: any;
  TotalFoodDelivered: any;
  TotalCarbonDioxide: any;
  CarsKeptoffRoad: any;
  HomesEnergyUse: any;
  TreeSeedlingsGrown: any;
  constructor(
    private pickupService: PickUpService,
    private spinnerService: SpinnerService,
    private excelService: ExcelServicesService,
    private ClientFilterdata: ClientFilterService,
    private router: Router,
    private state: AppState,
    private CustomerService: CustomerService,
    private AdminService: AdmintoolsService,
    public dialog: MatDialog
  ) { }
  exportAsXLSX(): void {
    //  this.excelService.exportAsExcelFile(this.excel, 'sample');  ----- future purpose API----
    const excelExport: any = [];
    const exportableDashboardData: any = {};
    exportableDashboardData.MealSearved = this.dashboard.MealCount;
    exportableDashboardData.FoodDelivered = this.dashboard.MealWeight;
    exportableDashboardData.CO2 = this.dashboard.CO2;
    for (let i = 0; i < this.dashboard.Earninigs.length; i++) {
      exportableDashboardData[this.dashboard.Earninigs[i][0] + '_Saving'] = this.dashboard.Earninigs[i][1];
    }
    for (let i = 0; i < this.dashboard.FoodItems.length; i++) {
      exportableDashboardData['Wasted_Food_Count_For' + this.dashboard.FoodItems[i][0]] = this.dashboard.FoodItems[i][1];
    }
    excelExport.push(exportableDashboardData);
    this.spinnerService.stop(this.spinnerRef);
    this.excelService.exportAsExcelFile(excelExport, 'Report');
  }

  ngOnInit() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    this.pickuptypeClients = localUser.user.User.Clients.filter((client) => client.ClientType !== 'Non Profit' && client.ClientType !== 'MRF');
    this.pickuptypeClients.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : ((b.Name.toLowerCase() > a.Name.toLowerCase()) ? -1 : 0))
    if (this.pickuptypeClients.length === 1) {
      this.showClientDropdown = false;
    } else {
      this.showClientDropdown = true;
    }
    // this.spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'OTP' && element.EnableFeature === false) {
            this.OTPFeature = false;
          }
        }
      }
    });
    // this.kendo.element.nativeElement.hidden = true;
    const now = new Date();
    // Till now Default date range prevMonthFirstDate - prevMonthLastDate but according requirment changed the Prsent date to Last year date
    const presentDate = new Date();
    var pastYear = now.getFullYear() - 1;
    const prevYearDate = now.setFullYear(pastYear);
    this.ToDate = moment(presentDate).format('MMM DD YYYY');
    this.FromDate = moment(prevYearDate).format('MMM DD YYYY');
    this.ToDatePdf = moment(presentDate).format('MMM DD, YYYY');
    this.FromDatePdf = moment(prevYearDate).format('MMM DD, YYYY');
    this.VisibleForAdminOnly = getFeature('nonProfitData');
    this.HideForNPFR = getFeature('estimatedTax');
    this.pickupType();
    this.user = this.state.user;
    if (this.user.User.ImageUrl == null) {
      this.ImageUrlnull = true;
      this.ImageUrl = false;
    } else {
      this.ImageUrl = true;
      if (this.user.User.ImageUrl) {
        this.profileImage = this.user.User.ImageUrl;
      }
    }
  }
  pickupType() {
    this.pickupService.getPickupFilterList().subscribe((data) => {
      this.PickupListData = data.Result;
      if (this.OTPFeature === false) {
        this.PickupListData = this.PickupListData.filter((picuptype) => {
          return picuptype.Title !== 'One Time Pickup';
        });
      }
      this.PickupList = this.PickupListData;
      for (const status of this.PickupListData) {
        if (status.Title === 'Food') {
          status.Title = 'Surplus Food';
        }
      }
      this.clientlist();
    });
  }
  clientlist() {
    if (this.pickuptypeClients.length === 1) {
      this.clientId = this.pickuptypeClients[0].ClientId;
      this.selectedClientName = this.pickuptypeClients[0].Name;
      this.loadDashboardData(this.pickuptypeClients[0].ClientId);
    } else this.loadDashboardData('')
  }
  editDashboard() {
    this.router.navigate(['editDashboard']);
  }
  addEditdate() {
    const dialogRef = this.dialog.open(AddeditDateComponent, {
      width: '800px',
      // height: '700px',
      panelClass: 'custom-datedialog-container',
      data: [
        {
          FromDate: this.FromDate,
          ToDate: this.ToDate,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.spinnerService.stop(this.spinnerRef);
      this.editDate = result;
      if (this.editDate != undefined) {
        // this.spinnerRef = this.spinnerService.start();
        this.FromDate = moment(this.editDate.From).format('MMM DD YYYY');
        this.ToDate = moment(this.editDate.To).format('MMM DD YYYY');
        this.ToDatePdf = moment(this.editDate.To).format('MMM DD, YYYY');
        this.FromDatePdf = moment(this.editDate.From).format('MMM DD, YYYY');
        // this.selectFromDate =  moment(this.FromDate).format('MMM Do YYYY');
        // this.selecteToddate =  moment(this.ToDate).format('MMM Do YYYY');
        if (this.clientId === 'all') {
          this.getDashboardData(null);
        } else {
          this.getDashboardData(this.clientId);
        }
      }
    });
  }

  loadDashboardData(val) {
    const facilitator = this.user.User.Clients.filter((client) => {
      return client.ClientId == this.clientId;
    });
    if (facilitator.length > 0) {
      this.clientname = facilitator[0].Name;
    } else {
      this.clientname = '';
    }
    if (this.clientId === 'all') {
      this.PickupList = this.PickupListData;
      this.getDashboardData(null);
    } else {
      this.getDashboardData(this.clientId);
    }
  }
  getDashboardData(clientId) {
    // const clientnameofnp = facilitator.Name;
    var facilityGroupId = null;
    var facilitatorId = null;
    if (['Facility', 'Facility Group', 'Facilitator', 'Admin'].indexOf(this.user.User.Roles[0].Name) != -1) {
      // const element = document.getElementById('selectClientDropDown');
      // element.setAttribute('disabled', '');
    }
    if (this.clientId === 'all') {
      this.PickupList = this.PickupListData;
      this.profileImageUrl = '';
      clientId = null;
      this.pullDeliveriesData(facilitatorId, facilityGroupId, clientId, 'Food');
    } else {
      for (let i = 0; i < this.pickuptypeClients.length; i++) {
        if (this.pickuptypeClients[i].ClientId == clientId) {
          if (this.pickuptypeClients[i].PickupType !== null) {
            if (this.pickuptypeClients[i].PickupType.length > 0) {
              this.selectedpickupType = this.pickuptypeClients[i].PickupType[0];
            } else {
              this.selectedpickupType = null;
            }
          } else {
            this.selectedpickupType = null;
          }
          if (this.pickuptypeClients[i].Name) {
            this.selectedClientName = this.pickuptypeClients[i].Name;
          }
          if (this.pickuptypeClients[i].Logo) {
            this.profileImageUrl = this.pickuptypeClients[i].Logo;
            this.ImageClientUrl = true;
          } else if (this.pickuptypeClients[i].ImageUrl) {
            this.profileImageUrl = this.pickuptypeClients[i].ImageUrl;
            this.ImageClientUrl = true;
          } else {
            this.profileImageUrl = null;
          }
          this.facilityGroupId = null;
          this.facilitatorId = null;
          if (this.pickuptypeClients[i].ClientType === 'Facility Group') {
            this.facilityGroupId = clientId;
            clientId = null;
          } else if (this.pickuptypeClients[i].ClientType === 'Facilitator') {
            this.facilitatorId = clientId;
            clientId = null;
          }
          this.pullDeliveriesData(this.facilitatorId, this.facilityGroupId, clientId, this.selectedpickupType);
        }
      }
    }
  }
  pullDeliveriesData(facilitatorId, facilityGroupId, clientId, selectedpickupType) {
    this.newNpArray = [];
    this.MRFList = [];
    this.spinnerRef = this.spinnerService.start();
    if (selectedpickupType == 'Food') {
      selectedpickupType = 'Surplus Food';
    }
    for (const status of this.PickupListData) {
      if (status.Title === selectedpickupType) {
        this.pickupid = status.ID;
        this.selectedpickupType = status.ID
      }
    }
    if (getFeature('dashboard') === true) {
      this.clientusers = this.user.User.Clients;
      const selectedDate = new Date(this.FromDate);
      const ToDate = new Date(this.ToDate);
      ToDate.setHours(23);
      ToDate.setMinutes(59);
      ToDate.setSeconds(59);
      selectedDate.setHours(0);
      selectedDate.setMinutes(0);
      selectedDate.setSeconds(0);

      const selectedNDate = new Date(selectedDate);
      const selectedEndNDate = new Date(ToDate);
      const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
      const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];

      this.finalFromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
      this.finalToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';

      if (facilityGroupId !== null || facilitatorId !== null) {
        this.selectedpickupType = 1;
      }
      this.pickupService.dashboardData(clientId, facilityGroupId, facilitatorId, this.finalFromDate, this.finalToDate, this.pickupid).subscribe(
        (data) => {
          this.dashboardGetData = data['SFDashboard'];
          this.dashboardGetDataOR = data['ORDashboard'];

          if (data['SFDashboard'] !== null && data['ORDashboard'] !== null) {
            let MealCount1 = data['SFDashboard'].TotalMealCount && Number(data['SFDashboard'].TotalMealCount);
            let MealCount2 = data['ORDashboard'].TotalMealCount && Number(data['ORDashboard'].TotalMealCount)
            this.TotalMealCount = MealCount1 + MealCount2;

            let FoodDelivered1 = data['SFDashboard'].TotalFoodDelivered && Number(data['SFDashboard'].TotalFoodDelivered);
            let FoodDelivered2 = data['ORDashboard'].TotalFoodDelivered && Number(data['ORDashboard'].TotalFoodDelivered)
            this.TotalFoodDelivered = FoodDelivered1 + FoodDelivered2;

            let CarbonDioxide1 = data['SFDashboard'].TotalCarbonDioxide && Number(data['SFDashboard'].TotalCarbonDioxide);
            let CarbonDioxide2 = data['ORDashboard'].TotalCarbonDioxide && Number(data['ORDashboard'].TotalCarbonDioxide)
            this.TotalCarbonDioxide = CarbonDioxide1 + CarbonDioxide2;

            let CarsKeptoffRoad1 = data['SFDashboard'].CarsKeptoffRoad && Number(data['SFDashboard'].CarsKeptoffRoad);
            let CarsKeptoffRoad2 = data['ORDashboard'].CarsKeptoffRoad && Number(data['ORDashboard'].CarsKeptoffRoad)
            this.CarsKeptoffRoad = CarsKeptoffRoad1 + CarsKeptoffRoad2;

            let HomesEnergyUse1 = data['SFDashboard'].HomesEnergyUse && Number(data['SFDashboard'].HomesEnergyUse);
            let HomesEnergyUse2 = data['ORDashboard'].HomesEnergyUse && Number(data['ORDashboard'].HomesEnergyUse)
            this.HomesEnergyUse = HomesEnergyUse1 + HomesEnergyUse2;

            let TreeSeedlingsGrown1 = data['SFDashboard'].TreeSeedlingsGrown && Number(data['SFDashboard'].TreeSeedlingsGrown);
            let TreeSeedlingsGrown2 = data['ORDashboard'].TreeSeedlingsGrown && Number(data['ORDashboard'].TreeSeedlingsGrown)
            this.TreeSeedlingsGrown = TreeSeedlingsGrown1 + TreeSeedlingsGrown2;

            // sf data call
            this.dashboardGetData['NonProfits'].forEach((element) => {
              if (element.ImageUrl) {
                element.ImageUrl = element.ImageUrl;
              }
            });
            this.newNpArray = [];
            this.dashboardGetData['NonProfits'].forEach((element, i) => {
              if (i < 4) {
                this.newNpArray.push(element);
              }
            });

            // OR data call
            this.dashboardGetDataOR['NonProfits'].forEach((element) => {
              if (element.ImageUrl) {
                element.ImageUrl = element.ImageUrl;
              }
              this.MRFList.push(element);

            });
            this.dashboardGetDataOR['TotalDefaulitItems'] = [
              {
                ProductName: 'Organic',
                imgSrc: '../../assets/images/organic.png',
                Weight: '0',
              },
              {
                ProductName: 'Paper',
                imgSrc: '../../assets/images/paper.png',
                Weight: '0',
              },
              {
                ProductName: 'Cardboard',
                imgSrc: '../../assets/images/cardboard.png',
                Weight: '0',
              },
              {
                ProductName: 'Plastic',
                imgSrc: '../../assets/images/plastic.png',
                Weight: '0',
              },
              {
                ProductName: 'Aluminum',
                imgSrc: '../../assets/images/aluminium.png',
                Weight: '0',
              },
              {
                ProductName: 'Pallets',
                imgSrc: '../../assets/images/pallets.png',
                Weight: '0',
              },
              {
                ProductName: 'Single Stream',
                imgSrc: '../../assets/images/combined.png',
                Weight: '0',
              },
              {
                ProductName: 'Glass',
                imgSrc: '../../assets/images/combined.png',
                Weight: '0',
              },
              {
                ProductName: 'Organics',
                imgSrc: '',
                Weight: '0',
              },
            ];
            if (this.dashboardGetDataOR['TotalWastedItems'].length > 0) {
              this.dashboardGetDataOR['TotalWastedItems'].map((value) => {
                if (value.ProductName === 'Organic') {
                  value.imgSrc = '../../assets/images/organic.png';
                } else if (value.ProductName === 'Paper') {
                  value.imgSrc = '../../assets/images/paper.png';
                } else if (value.ProductName === 'Cardboard') {
                  value.imgSrc = '../../assets/images/cardboard.png';
                } else if (value.ProductName === 'Plastic') {
                  value.imgSrc = '../../assets/images/plastic.png';
                } else if (value.ProductName === 'Aluminum') {
                  value.imgSrc = '../../assets/images/aluminium.png';
                } else if (value.ProductName === 'Pallets') {
                  value.imgSrc = '../../assets/images/pallets.png';
                } else if (value.ProductName === 'Single Stream') {
                  value.imgSrc = '../../assets/images/combined.png';
                } else if (value.ProductName === 'Glass' || value.ProductName === 'Organics') {
                  value.imgSrc = '';
                }
              });
              this.dashboardGetDataFinalOR = [];
              for (let index = 0; index < this.dashboardGetDataOR['TotalDefaulitItems'].length; index++) {
                const month = this.dashboardGetDataOR['TotalDefaulitItems'][index];
                let filter = this.dashboardGetDataOR['TotalWastedItems'].filter((item) => {
                  return item.ProductName == month.ProductName;
                });
                if (filter.length > 0) {
                  this.dashboardGetDataFinalOR.push(filter[0]);
                } else {
                }
              }
            } else {
              this.dashboardGetDataFinalOR = this.dashboardGetDataOR['TotalDefaulitItems'];
            }
            this.totalItems = [];
            this.totalItems = this.dashboardGetDataFinalOR.filter((data) => {
              return data.ProductName !== 'Combined';
            });
            this.totalItems.sort(function (a, b) {
              return b.Weight - a.Weight;
            });

          } else if (data['SFDashboard'] !== null) {
            this.TotalMealCount = data['SFDashboard'].TotalMealCount && data['SFDashboard'].TotalMealCount;
            this.TotalFoodDelivered = data['SFDashboard'].TotalFoodDelivered && data['SFDashboard'].TotalFoodDelivered;
            this.TotalCarbonDioxide = data['SFDashboard'].TotalCarbonDioxide && data['SFDashboard'].TotalCarbonDioxide;
            this.CarsKeptoffRoad = data['SFDashboard'].CarsKeptoffRoad && data['SFDashboard'].CarsKeptoffRoad;
            this.HomesEnergyUse = data['SFDashboard'].HomesEnergyUse && data['SFDashboard'].HomesEnergyUse;
            this.TreeSeedlingsGrown = data['SFDashboard'].TreeSeedlingsGrown && data['SFDashboard'].TreeSeedlingsGrown;
            this.dashboardGetData['NonProfits'].forEach((element) => {
              if (element.ImageUrl) {
                element.ImageUrl = element.ImageUrl;
              }
            });
            this.newNpArray = [];
            this.dashboardGetData['NonProfits'].forEach((element, i) => {
              if (i < 4) {
                this.newNpArray.push(element);
              }
            });
          } else if (data['ORDashboard'] !== null) {
            this.TotalMealCount = data['ORDashboard'].TotalMealCount && data['ORDashboard'].TotalMealCount;
            this.TotalFoodDelivered = data['ORDashboard'].TotalFoodDelivered && data['ORDashboard'].TotalFoodDelivered;
            this.TotalCarbonDioxide = data['ORDashboard'].TotalCarbonDioxide && data['ORDashboard'].TotalCarbonDioxide;
            this.CarsKeptoffRoad = data['ORDashboard'].CarsKeptoffRoad && data['ORDashboard'].CarsKeptoffRoad;
            this.HomesEnergyUse = data['ORDashboard'].HomesEnergyUse && data['ORDashboard'].HomesEnergyUse;
            this.TreeSeedlingsGrown = data['ORDashboard'].TreeSeedlingsGrown && data['ORDashboard'].TreeSeedlingsGrown;
            this.dashboardGetDataOR['NonProfits'].forEach((element) => {
              if (element.ImageUrl) {
                element.ImageUrl = element.ImageUrl;
              }
            });
            this.dashboardGetDataOR['NonProfits'].forEach((element, i) => {
              if (i < 4) {
                this.MRFList.push(element);
              }
            });
            this.dashboardGetDataOR['TotalDefaulitItems'] = [
              {
                ProductName: 'Organic',
                imgSrc: '../../assets/images/organic.png',
                Weight: '0',
              },
              {
                ProductName: 'Paper',
                imgSrc: '../../assets/images/paper.png',
                Weight: '0',
              },
              {
                ProductName: 'Cardboard',
                imgSrc: '../../assets/images/cardboard.png',
                Weight: '0',
              },
              {
                ProductName: 'Plastic',
                imgSrc: '../../assets/images/plastic.png',
                Weight: '0',
              },
              {
                ProductName: 'Aluminum',
                imgSrc: '../../assets/images/aluminium.png',
                Weight: '0',
              },
              {
                ProductName: 'Pallets',
                imgSrc: '../../assets/images/pallets.png',
                Weight: '0',
              },
              {
                ProductName: 'Single Stream',
                imgSrc: '../../assets/images/combined.png',
                Weight: '0',
              },
              {
                ProductName: 'Glass',
                imgSrc: '../../assets/images/combined.png',
                Weight: '0',
              },
              {
                ProductName: 'Organics',
                imgSrc: '',
                Weight: '0',
              },
            ];
            if (this.dashboardGetDataOR['TotalWastedItems'].length > 0) {
              this.dashboardGetDataOR['TotalWastedItems'].map((value) => {
                if (value.ProductName === 'Organic') {
                  value.imgSrc = '../../assets/images/organic.png';
                } else if (value.ProductName === 'Paper') {
                  value.imgSrc = '../../assets/images/paper.png';
                } else if (value.ProductName === 'Cardboard') {
                  value.imgSrc = '../../assets/images/cardboard.png';
                } else if (value.ProductName === 'Plastic') {
                  value.imgSrc = '../../assets/images/plastic.png';
                } else if (value.ProductName === 'Aluminum') {
                  value.imgSrc = '../../assets/images/aluminium.png';
                } else if (value.ProductName === 'Pallets') {
                  value.imgSrc = '../../assets/images/pallets.png';
                } else if (value.ProductName === 'Single Stream') {
                  value.imgSrc = '../../assets/images/combined.png';
                } else if (value.ProductName === 'Glass' || value.ProductName === 'Organics') {
                  value.imgSrc = '';
                }
              });
              this.dashboardGetDataFinalOR = [];
              for (let index = 0; index < this.dashboardGetDataOR['TotalDefaulitItems'].length; index++) {
                const month = this.dashboardGetDataOR['TotalDefaulitItems'][index];
                let filter = this.dashboardGetDataOR['TotalWastedItems'].filter((item) => {
                  return item.ProductName == month.ProductName;
                });
                if (filter.length > 0) {
                  this.dashboardGetDataFinalOR.push(filter[0]);
                } else {
                }
              }
            } else {
              this.dashboardGetDataFinalOR = this.dashboardGetDataOR['TotalDefaulitItems'];
            }
            this.totalItems = [];
            this.totalItems = this.dashboardGetDataFinalOR.filter((data) => {
              return data.ProductName !== 'Combined';
            });
            this.totalItems.sort(function (a, b) {
              return b.Weight - a.Weight;
            });
          } else if (data['SFDashboard'] == null && data['ORDashboard'] == null) {
            this.selectedpickupType = null
          }
          this.spinnerService.stop(this.spinnerRef);
        },

        (error) => {
          this.spinnerService.stop(this.spinnerRef);
        }
      );
    }
  }
  loadPickupData() {
    this.spinnerRef = this.spinnerService.start();
    this.clientlist();
    this.clientId = 'all';
    this.clientname = '';
    if (this.clientId === 'all') this.getDashboardData(null);

  }
  downloadPDF1() {
    this.kendo.element.nativeElement.hidden = false;
    this.kendo.saveAs('ImpactReport.pdf');
    setTimeout(() => {
      this.kendo.element.nativeElement.hidden = true;
    }, 1000);
  }
}
