import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { PassworddialogComponent } from '../passworddialog/passworddialog.component';
import { EmailchangedialogComponent } from '../emailchangedialog/emailchangedialog.component';
import { NumberchangedialogComponent } from '../numberchangedialog/numberchangedialog.component';
import { TransferownershipdialogComponent } from '../transferownershipdialog/transferownershipdialog.component';
import { DeleteaccountdialogComponent } from '../deleteaccountdialog/deleteaccountdialog.component';
import { RemoveaccountdialogComponent } from '../removeaccountdialog/removeaccountdialog.component';
import { EditphotodialogComponent } from '../editphotodialog/editphotodialog.component';
import { TitledialogComponent } from './titledialog/titledialog.component';
import { AppState } from 'src/app/shared/app-state';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { AccountService } from 'src/app/shared/account.service';
import { HelpScreenComponent } from '../../templates/help-screen/help-screen.component';
import { LogoHeaderComponent } from '../../../dashboard/templates/logo-header/logo-header.component';

@Component({
  selector: 'app-profile-setings',
  templateUrl: './profile-setings.component.html',
  styleUrls: ['./profile-setings.component.scss'],
})
export class ProfileSetingsComponent implements OnInit {
  user: any;
  radioOptions = 'option 1';
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  displayedColumns: string[] = ['name', 'type', 'Role'];
  dialogRef: MatDialogRef<TitledialogComponent>;
  profileuser: any;
  profilePhoneNumber: any;
  profileAccountDetails: any;
  clientAccountRequestElement: any;
  profileImageUrl: any;
  editClientID: any;
  editSubscription = false;
  firstNameLetter: any;
  lastNameLetter: any;

  @ViewChild(LogoHeaderComponent, { static: true }) child: any;

  constructor(
    private location: Location,
    private router: Router,
    private state: AppState,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    this.user = this.state.user;
    if (this.user.User) {
      this.firstNameLetter = this.user.User.FirstName.charAt(0);
      this.lastNameLetter = this.user.User.LastName.charAt(0);
    }

    if (this.user.User.Roles[0].Name === 'Facility Group') {
      if (this.user.User.Clients) {
        if (
          this.user.User.Clients[0].ClientType === 'SAAS Facility Group' ||
          this.user.User.Clients[0].ClientType === 'SAAS Facility'
        ) {
          this.editSubscription = true;
          this.editClientID = this.user.FacilityGroupId;
        } else {
          this.editSubscription = false;
        }
      } else {
        this.editSubscription = false;
      }
    }
    if (this.user.User.Roles[0].Name === 'Facility') {
      if (this.user.User.Clients[0].ClientType) {
        if (this.user.User.Clients[0].ClientType === 'SAAS Facility' && this.user.User.Clients[0].StripeCustomerId) {
          this.editSubscription = true;
          this.editClientID = this.user.User.Clients[0].ClientId;
        } else {
          this.editSubscription = false;
        }
      } else {
        this.editSubscription = false;
      }
    }
    this.profileuser = this.user.User.Title;
    this.profilePhoneNumber = this.user.User.PhoneNumber;
    this.profileImageUrl = this.user.User.ImageUrl;
    if (this.user.User.ImageUrl == null) {
      // this.profileImageUrl = 'assets/images/logo.png';
      this.ImageUrlnull = true;
      this.ImageUrl = false;
    } else {
      // this.ImageUrl = true;
      this.profileImageUrl = this.user.User.ImageUrl;
      this.ImageUrlnull = false;
      this.ImageUrl = true;
    }
    this.profileAccountDetails = this.user.User.Clients;
    this.clientAccountRequestElement = new MatTableDataSource<Element>(this.profileAccountDetails);
  }
  openpasswordDialog(): void {
    const dialogRef = this.dialog.open(PassworddialogComponent, {
      width: '600px',
      // height: '550px',
      panelClass: 'custom-Filterdialog-container',
      data: {
        user_password: this.user.User.Password,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openemailDialog(): void {
    // const dialogRef = this.dialog.open(EmailchangedialogComponent, {
    // width: '700px', height: '330px',
    // data: {
    // user_email: this.user.User.UserName
    // }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  opennumberlDialog() {
    const dialogRef = this.dialog.open(NumberchangedialogComponent, {
      width: '700px',
      //height: '330px',
      panelClass: 'custom-Filterdialog-container',
      data: [
        {
          user_number: this.profilePhoneNumber,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
        this.profilePhoneNumber = this.profilePhoneNumber;
      } else {
        this.profilePhoneNumber = result;
      }
    });
  }

  opentransferownershiplDialog() {
    const dialogRef = this.dialog.open(TransferownershipdialogComponent, {
      width: '692px',
      height: '470px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  opentHelpScreenContent() {
    if (this.state.user.User.Roles[0].Name === 'Facility' || this.state.user.User.Roles[0].Name === 'Facility Group') {
      if (
        this.state.user.User.Clients[0].ClientType === 'SAAS Facility' ||
        this.state.user.User.Clients[0].ClientType === 'SAAS Facility Group'
      ) {
        const dialogRef = this.dialog.open(HelpScreenComponent, {
          width: '620px',
          panelClass: 'custom-savedialog-container',
          data: [
            {
              role: this.state.user.User.Roles[0].Name,
            },
          ],
        });
        dialogRef.afterClosed().subscribe((result) => {});
      }
    }
  }
  // opentHelpScreenContent() {
  //   const dialogRef = this.dialog.open(HelpScreenComponent, {
  //     width: '692px', height: '470px',
  //     data: {}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }
  deleteaccountDialog() {
    const dialogRef = this.dialog.open(DeleteaccountdialogComponent, {
      width: '660px',
      height: '330px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  removelDialog() {
    const dialogRef = this.dialog.open(RemoveaccountdialogComponent, {
      width: '600px',
      height: '330px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  opentitleDialog() {
    const dialogRef = this.dialog.open(TitledialogComponent, {
      width: '600px',
      // height: '330px',
      panelClass: 'custom-Filterdialog-container',
      data: [
        {
          user_Title: this.profileuser,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
        this.profileuser = this.profileuser;
      } else {
        this.profileuser = result;
      }
    });
  }

  editphotoDialog() {
    const dialogRef = this.dialog.open(EditphotodialogComponent, {
      width: '540px',
      //height: '400px',
      panelClass: 'custom-Filterdialog-container',
      data: [
        {
          user_photo: this.profileImageUrl,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
        this.profileImageUrl = this.profileImageUrl;
      } else {
        this.child.checkProfile();
        this.profileImageUrl = result;
        if (this.profileImageUrl) {
          this.state.user.User.ImageUrl = this.profileImageUrl;
        } else {
          this.profileImageUrl = this.state.user.User.ImageUrl;
          this.accountService.getUserDetails(this.user.User.UserId).subscribe(
            (data) => {
              this.profileImageUrl = data.Result[0].ImageUrl;
            },
            (error) => {
              this.state.isSpinnerVisible = false;
              // alert(JSON.stringify(error));
            }
          );
        }
      }
    });
  }
}
