import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnetimebillingcardpaymentComponent } from '../onetimebillingcardpayment/onetimebillingcardpayment.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { MatTableDataSource } from '@angular/material/table';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import * as moment from 'moment-timezone';
import { modalDataService } from 'src/app/shared/services/modalData.service';
export interface PeriodicElement {
  name: string;
  Price: number;
  Weight: number;
  Quantity: number;
}
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  public numberForm: FormGroup;
  public numberFormZip: FormGroup;
  taxForm: any;
  EmailFG: any;
  imgSrc = '../../assets/images/editicon.png';
  pickupTypeID = [];
  pickupTypeIDFG = [];
  sendRequest: any = [];
  sendRequestElement: any;

  allDeliveryStatus = [
    {
      ID: 1,
      Title: 'Facility'
    },
    {
      ID: 2,
      Title: 'Facility Group'
    }
  ];
  success: boolean;
  dataedit: boolean;
  CompanyTitle: any;
  FirstName: any;
  LastName: any;
  selectedType: any;
  Email: any;
  i = 0;
  PhoneNumber: any;
  PhoneNumberFG: any;
  TaxID: any;
  TaxIDFG: any;
  Address1: any;
  index: any;
  Address2FG: any;
  Address1FG: any;
  Address2: any;
  State: any;
  StateFG: any;
  City: any;
  ZipCode: any;
  requireFirstName: boolean;
  requireLastName: boolean;
  requireCompanyTitle: boolean;
  requireselectedType: boolean;
  requireEmail: boolean;
  checkselectedType = false;
  requirePhoneNumber: boolean;
  multipleFGadd = [];
  facilityFGadd = [];
  requireTaxID: boolean;
  requireAddress1: boolean;
  requireState: boolean;
  requireCity: boolean;
  requireZipCode: boolean;
  invalidEmail: boolean;
  Country: any;
  requireCountry: boolean;
  dataerror: string;
  NotVisibileFG: boolean = true;
  VisibleForFG: boolean;
  facility: boolean = true;
  facilitygroup: boolean;
  CompanyTitleFG: any;
  CityFG: any;
  ZipCodeFG: any;
  pickupStartTime: any;
  CountryFG: any;
  fcailityValidation: boolean;
  multipleFGaddFinal: any[];
  requireField: boolean;
  productsData = [];
  dataerrortrue: boolean;
  public createForm: FormGroup;
  displayedColumns: string[] = ['name', 'Price', 'Weight', 'Quantity'];
  displayedColumnsCompost: string[] = ['Title', 'Weight', 'add'];
  ELEMENT_DATA: PeriodicElement[] = [];
  dataSource: any;
  transaction: any = [];
  editbletrue: boolean;
  totalItems = 0;
  requestDataDisabled: boolean;
  onetimebilling: any;
  sameAddress: any;
  pickupCountry: any;
  pickupEndDate: any;
  pickupAddress1: any;
  pickupAddress2: any;
  pickupState: any;
  pickupCity: any;
  pickupZipCode: any;
  requireitems: boolean;
  requirpickup: boolean;
  totalitemCost: number;
  openbilladdress: boolean = true;
  openpickaddress: boolean;
  pickupstatesdata: any;
  showStartDate: any;
  pickupcitydata: any;
  pickupEndTime: any;
  ID: any;
  pickupstat: any;
  pickupcitydataID: any;
  pickupStartDate: any;
  showStartTime: string;
  citydataID: any;
  requirepickupCity: boolean;
  requirepickupCountry: boolean;
  requirepickupState: boolean;
  existinguser: any;
  newuser: boolean;
  requirepickupZipCode: boolean;
  showEndTime: string;
  showEndDate: any;
  PickupWeight: number;
  selectedDate: any;
  selectedStartTime: string;
  selectedEndDate: any;
  selectedEndTime: string;
  selectdate: boolean;
  finalstartDtae: any;
  finalendDtae: any;
  finalstartDate: any;
  finalendDate: any;
  futureValid: boolean;
  futureStartValid: boolean;
  diffTimeless: boolean;
  info: any;
  requirepickupAddress1: boolean;
  timezone: any;
  zipcodes: any;
  constructor(
    private customerServices: CustomerService,
    public dialog: MatDialog,
    public dateFormat: dateFormatPipe,
    private _rdata: modalDataService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sassService: SAASService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this._rdata.basicInfo1Observe.subscribe(res => {
      this.info = JSON.parse(res);
    });
    this.info.pickupsPage = true;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this.pickupstates();
    if (JSON.parse(localStorage.getItem('otpuser')) === 'existuser') {
      this.existinguser = true;
      // this.selectedpickupcityexist();
    } else {
      this.existinguser = false;
      // this.selectedpickupcity();
    }
    this.onetimebilling = JSON.parse(localStorage.getItem('onetimebilling'));
    let products = JSON.parse(localStorage.getItem('OneTimeItem'));
    if (products) {
      this.productsData = products;
      this.totalitemCost = this.productsData
        .map(r => r.Quantity * r.Price)
        .reduce((sum, current) => Number(sum) + Number(current));
      this.dataSource = new MatTableDataSource<Element>(this.productsData);
      this.totalItems = this.productsData.length - 1;
      this.CompanyTitle = this.onetimebilling[0].CompanyTitle;
      this.FirstName = this.onetimebilling[0].FirstName;
      this.LastName = this.onetimebilling[0].LastName;
      this.selectedType = this.onetimebilling[0].CompanyTitle;
      this.sameAddress = this.onetimebilling[0].sameAddress;
      this.Email = this.onetimebilling[0].Email;
      this.keyPressFirstName(this.Email);
      this.PhoneNumber = this.onetimebilling[0].PhoneNumber;
      this.TaxID = this.onetimebilling[0].TaxID;
      this.Country = this.onetimebilling[0].Country;
      this.Address1 = this.onetimebilling[0].Address1;
      this.Address2 = this.onetimebilling[0].Address2;
      this.State = this.onetimebilling[0].State;
      this.City = this.onetimebilling[0].City;
      this.ZipCode = this.onetimebilling[0].ZipCode;
      (this.pickupCountry = this.onetimebilling[0].pickupCountry),
        (this.pickupAddress1 = this.onetimebilling[0].pickupAddress1),
        (this.pickupAddress2 = this.onetimebilling[0].pickupAddress2),
        (this.pickupState = this.onetimebilling[0].pickupState),
        (this.pickupCity = this.onetimebilling[0].pickupCity),
        (this.pickupZipCode = this.onetimebilling[0].pickupZipCode);
      this.selectdate = this.onetimebilling[0].selectdate;
      this.pickupStartDate = this.onetimebilling[0].PickupAfter;
      this.pickupEndDate = this.onetimebilling[0].PickupBefore;
      if (this.pickupStartDate) {
        this.pickupStartTime = this.pickupStartDate
          .split('T')[1]
          .split('+')[0]
          .split('-')[0];
        this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
          'hh:mm a'
        );
        this.showStartDate = new Date(
          moment(this.pickupStartDate).format('YYYY/MM/DD')
        );
      }
      if (this.pickupEndDate) {
        this.pickupEndTime =
          new Date(this.pickupEndDate).getHours() +
          ':' +
          new Date(this.pickupEndDate).getMinutes() +
          ':' +
          '00';
        this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format(
          'hh:mm a'
        );
        this.showEndDate = new Date(
          moment(this.pickupEndDate).format('YYYY/MM/DD')
        );
      }
      // this.selectedpickupcity();
      this.sassService.pickupcitydetails(this.State).subscribe(data => {
        this.citydataID = data['Result'];
        for (var i = 0; i < this.citydataID.length; i++) {
          if (this.citydataID[i].ID === this.City) {
            this.City = this.citydataID[i].ID;
          }
        }
      });
      this.sassService.pickupcitydetails(this.pickupState).subscribe(data => {
        this.pickupcitydataID = data['Result'];
        for (var i = 0; i < this.pickupcitydataID.length; i++) {
          if (this.pickupcitydataID[i].ID == this.pickupCity) {
            this.pickupCity = this.pickupcitydataID[i].ID;
          }
        }
      });
    } else {
      this.Country = 'USA';
      this.pickupCountry = 'USA';
      const body = {
        Name: '',
        Quantity: '',
        Price: '',
        wasClicked: true,
        Weight: '',
        imgSrc: '../../assets/images/editicon.png',
        index: 1,
        newitem: true,
        deleteoption: false
      };
      this.productsData.push(body);
      this.dataSource = this.productsData;
      this.defaultdatetime();
    }
    this.createForm = this.formBuilder.group({
      quantity: ['', Validators.compose([Validators.required])],
      Weight: ['', Validators.compose([Validators.required])],
      Price: ['', Validators.compose([Validators.required])]
    });
    this.numberForm = this.formBuilder.group({
      number: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12)
        ])
      ]
    });
    this.numberFormZip = this.formBuilder.group({
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ]
    });
    this.taxForm = this.formBuilder.group({
      tax: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12)
        ])
      ]
    });
  }
  onSearchChangename(event, transaction) {
    transaction.nameenter = true;
  }
  onSearchChangeweigth(event, transaction) {
    transaction.weigthenter = true;
  }
  onSearchChangeprice(event, transaction) {
    transaction.priceenter = true;
  }
  onSearchChangequanty(event, transaction) {
    transaction.quantyenter = true;
  }

  selectedAddress() {
    if (this.sameAddress === true) {
      this.sassService.pickupcitydetails(this.State).subscribe(data => {
        this.pickupcitydataID = data['Result'];
        for (var i = 0; i < this.pickupcitydataID.length; i++) {
          if (this.pickupcitydataID[i].ID === this.City) {
            this.pickupCity = this.pickupcitydataID[i].ID;
            if (this.existinguser === true) {
              // this.selectedpickupcityexist();
            } else {
              // this.selectedpickupcity();
            }
          }
        }
      });
      (this.pickupCountry = this.Country),
        (this.pickupAddress1 = this.Address1),
        (this.pickupAddress2 = this.Address2),
        (this.pickupState = this.State),
        (this.pickupZipCode = this.ZipCode);
    } else {
      (this.pickupCountry = ''),
        (this.pickupAddress1 = ''),
        (this.pickupAddress2 = ''),
        (this.pickupState = ''),
        (this.pickupCity = ''),
        (this.pickupZipCode = '');
    }
  }
  // selectedpickupcityexist() {
  //   if (this.pickupCity !== 'Select City') {
  //     let body = {
  //       DriverService: true,
  //       StateId: this.pickupState,
  //       CityId: this.pickupCity,
  //       Email: JSON.parse(localStorage.getItem('otpemail'))
  //     }
  //     this.sassService.onetimebillingStripePlan(body).subscribe(
  //       data => {
  //         console.log(data.Roadies)
  //         if (data.Roadies === true) {
  //           this.selectdate = true;
  //           if (!this.pickupStartDate && !this.pickupEndDate) {
  //             this.defaultdatetime();
  //           }
  //         } else {
  //           this.selectdate = false;
  //         }
  //       });
  //   }
  // }
  // selectedpickupcity() {
  //   if (this.pickupCity !== 'Select City') {
  //     let body = {
  //       DriverService: true,
  //       StateId: this.pickupState,
  //       CityId: this.pickupCity,
  //     }
  //     this.sassService.onetimebillingStripePlan(body).subscribe(
  //       data => {
  //         console.log(data.Roadies)
  //         if (data.Roadies === true) {
  //           this.selectdate = true;
  //           if (!this.pickupStartDate && !this.pickupEndDate) {
  //             this.defaultdatetime();
  //           }
  //         } else {
  //           this.selectdate = false;
  //         }
  //       });
  //   }
  // }
  selectedcity() {
    for (const status of this.citydataID) {
      if (status.ID === this.pickupCity) {
        this.timezone = status.time_zone;
        // this.timezoneabbr = moment.tz.zone(status.time_zone).abbr(360);
        this.zipcodes = status.zip_codes.split('/');
      }
    }
  }
  defaultdatetime() {
    this.pickupStartDate = new Date();
    const startDate = new Date(
      this.dateFormat.transform(
        this.pickupStartDate.toISOString(),
        this.timezone
      )
    );
    this.pickupStartDate =
      moment(startDate)
        .add(1, 'hours')
        .add(30, 'minutes')
        .format()
        .toString()
        .split('T')[0] +
      'T' +
      moment(startDate)
        .add(1, 'hours')
        .add(30, 'minutes')
        .format()
        .toString()
        .split('T')[1]
        .split('+')[0]
        .split('-')[0];
    this.pickupStartTime = this.pickupStartDate
      .split('T')[1]
      .split('+')[0]
      .split('-')[0];
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
      'hh:mm a'
    );
    this.showStartDate = new Date(
      moment(this.pickupStartDate).format('YYYY/MM/DD')
    );
    this.pickupEndDate = new Date(this.pickupStartDate);
    this.pickupEndDate =
      moment(this.pickupEndDate)
        .add(2, 'hours')
        .format()
        .toString()
        .split('T')[0] +
      'T' +
      moment(this.pickupEndDate)
        .add(2, 'hours')
        .format()
        .toString()
        .split('T')[1]
        .split('+')[0]
        .split('-')[0];
    this.pickupEndTime =
      new Date(this.pickupEndDate).getHours() +
      ':' +
      new Date(this.pickupEndDate).getMinutes() +
      ':' +
      '00';
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.showEndDate = new Date(
      moment(this.pickupEndDate).format('YYYY/MM/DD')
    );
  }
  keyPressFirstName(event: any) {
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateClient: true,
        OneTimeClient: { Email: event }
      };
      this.sassService.otpreigster(body1).subscribe(data => {
        if (data.message === 'Client already exist') {
          this.dataerrortrue = true;
          this.dataerror = data.message;
        } else {
          this.dataerrortrue = false;
        }
      });
    } else {
      this.dataerrortrue = false;
    }
  }
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.Email) === false) {
      return false;
    }
    return true;
  }
  billingclick() {
    // this.openbilladdress = true;
    if (this.openbilladdress === true) {
      this.openbilladdress = false;
    } else {
      this.openbilladdress = true;
    }
  }
  pickupclick() {
    this.openbilladdress = false;
    if (this.openpickaddress === true) {
      this.openpickaddress = false;
    } else {
      this.openpickaddress = true;
    }
  }
  validateEmailFG() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.EmailFG) === false) {
      return false;
    }
    return true;
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  keyPressZipCode(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  goToSucessReg() {
    if (this.PhoneNumber) {
      this.numberForm.controls.number.setValue(numberSetMask(this.PhoneNumber));
    }
    //  if (this.ZipCode) {
    //   this.numberFormZip.controls.number.setValue(numberSetMask(this.ZipCode));
    // }
    if (
      this.CompanyTitle &&
      this.CompanyTitle !== undefined &&
      this.CompanyTitle !== null
    ) {
      if (
        this.FirstName &&
        this.FirstName !== undefined &&
        this.FirstName !== null
      ) {
        if (
          this.LastName &&
          this.LastName !== undefined &&
          this.LastName !== null
        ) {
          if (this.TaxID && this.TaxID !== undefined && this.TaxID !== null) {
            // if (this.PhoneNumber && this.PhoneNumber !== undefined && this.PhoneNumber !== null) {
            if (this.numberForm.valid) {
              if (
                this.Email &&
                this.Email !== undefined &&
                this.Email !== null
              ) {
                if (this.validateEmail() === true) {
                  if (this.dataerrortrue === false) {
                    if (
                      this.Address1 &&
                      this.Address1 !== undefined &&
                      this.Address1 !== null
                    ) {
                      if (
                        this.State &&
                        this.State !== undefined &&
                        this.State !== null
                      ) {
                        if (
                          this.City &&
                          this.City !== undefined &&
                          this.City !== null &&
                          this.City !== 'Select City'
                        ) {
                          // if (this.ZipCode && this.ZipCode !== undefined && this.ZipCode !== null) {
                          if (
                            this.ZipCode &&
                            this.ZipCode !== undefined &&
                            this.ZipCode !== null
                          ) {
                            if (
                              this.Country &&
                              this.Country !== undefined &&
                              this.Country !== null
                            ) {
                              if (
                                this.pickupAddress1 &&
                                this.pickupAddress1 !== undefined &&
                                this.pickupAddress1 !== null
                              ) {
                                if (
                                  this.pickupCity &&
                                  this.pickupCity !== undefined &&
                                  this.pickupCity !== null &&
                                  this.pickupCity !== 'Select City'
                                ) {
                                  if (
                                    this.pickupState &&
                                    this.pickupState !== undefined &&
                                    this.pickupState !== null
                                  ) {
                                    if (
                                      this.pickupZipCode &&
                                      this.pickupZipCode !== undefined &&
                                      this.pickupZipCode !== null
                                    ) {
                                      if (
                                        this.pickupCountry &&
                                        this.pickupCountry !== undefined &&
                                        this.pickupCountry !== null
                                      ) {
                                        if (
                                          this.pickupCountry &&
                                          this.pickupAddress1 &&
                                          this.pickupState &&
                                          this.pickupCity &&
                                          this.pickupZipCode
                                        ) {
                                          if (this.productsData.length > 1) {
                                            let clickitems = 0;
                                            this.productsData.map(value => {
                                              if (
                                                value.nameenter === true &&
                                                value.priceenter === true &&
                                                value.weigthenter === true &&
                                                value.quantyenter === true &&
                                                value.imgSrc ===
                                                  '../../assets/images/editicon.png'
                                              ) {
                                                value.clickinput = true;
                                                clickitems++;
                                                setTimeout(() => {
                                                  value.clickinput = false;
                                                }, 3000);
                                              } else {
                                                value.clickinput = false;
                                              }
                                            });
                                            if (clickitems === 0) {
                                              let dt1 = new Date(
                                                this.pickupStartDate
                                              );
                                              let dt2 = new Date(
                                                this.pickupEndDate
                                              );
                                              let dt3 = new Date(
                                                this.dateFormat.transform(
                                                  new Date().toISOString(),
                                                  this.timezone
                                                )
                                              );

                                              // if (this.pickupTypeID[0]) {
                                              // if (this.selectdate === true) {
                                              if (
                                                this.endAfterStart(dt1, dt2) ===
                                                true
                                              ) {
                                                if (
                                                  this.diff_hoursStart(
                                                    dt3,
                                                    dt1
                                                  ) >= 60
                                                ) {
                                                  if (
                                                    this.diff_hours(dt1, dt2) >=
                                                    120
                                                  ) {
                                                    this.goodroadies();
                                                  } else {
                                                    this.diffTimeless = true;
                                                    setTimeout(() => {
                                                      this.diffTimeless = false;
                                                    }, 4000);
                                                    // this.pickupDetails();
                                                  }
                                                } else {
                                                  this.futureStartValid = true;
                                                  setTimeout(() => {
                                                    this.futureStartValid = false;
                                                  }, 4000);
                                                }
                                              } else {
                                                this.futureValid = true;
                                                setTimeout(() => {
                                                  this.futureValid = false;
                                                }, 4000);
                                              }
                                              // } else {
                                              //   this.goodroadies();
                                              // }
                                              // this.router.navigate(["PickupBilling"]);
                                            }
                                          } else {
                                            this.requireitems = true;
                                            setTimeout(() => {
                                              this.requireitems = false;
                                            }, 3000);
                                          }
                                        } else {
                                          this.requirpickup = true;
                                          this.openpickaddress = true;

                                          setTimeout(() => {
                                            this.requirpickup = false;
                                          }, 3000);
                                        }
                                      } else {
                                        this.requirepickupCountry = true;
                                        this.openpickaddress = true;
                                        setTimeout(() => {
                                          this.requirepickupCountry = false;
                                        }, 3000);
                                      }
                                    } else {
                                      this.requirepickupZipCode = true;
                                      this.openpickaddress = true;
                                      setTimeout(() => {
                                        this.requirepickupZipCode = false;
                                      }, 3000);
                                    }
                                  } else {
                                    this.requirepickupState = true;
                                    this.openpickaddress = true;
                                    setTimeout(() => {
                                      this.requirepickupState = false;
                                    }, 3000);
                                  }
                                } else {
                                  this.requirepickupCity = true;
                                  this.openpickaddress = true;
                                  setTimeout(() => {
                                    this.requirepickupCity = false;
                                  }, 3000);
                                }
                              } else {
                                this.requirepickupAddress1 = true;
                                this.openpickaddress = true;
                                setTimeout(() => {
                                  this.requirepickupAddress1 = false;
                                }, 3000);
                              }
                            } else {
                              this.requireCountry = true;
                              this.openbilladdress = true;
                              setTimeout(() => {
                                this.requireCountry = false;
                              }, 3000);
                            }
                          } else {
                            this.requireZipCode = true;
                            this.openbilladdress = true;
                            setTimeout(() => {
                              this.requireZipCode = false;
                            }, 3000);
                          }
                        } else {
                          this.requireCity = true;
                          this.openbilladdress = true;
                          setTimeout(() => {
                            this.requireCity = false;
                          }, 3000);
                        }
                      } else {
                        this.requireState = true;
                        this.openbilladdress = true;
                        setTimeout(() => {
                          this.requireState = false;
                        }, 3000);
                      }
                    } else {
                      this.requireAddress1 = true;
                      this.openbilladdress = true;
                      setTimeout(() => {
                        this.requireAddress1 = false;
                      }, 3000);
                    }
                  } else {
                    // this.dataerrortrue = true;
                    // setTimeout(() => {
                    //   this.dataerrortrue = false;
                    // }, 3000);
                  }
                } else {
                  this.invalidEmail = true;
                  setTimeout(() => {
                    this.invalidEmail = false;
                  }, 3000);
                }
              } else {
                this.requireEmail = true;
                setTimeout(() => {
                  this.requireEmail = false;
                }, 3000);
              }
            } else {
              this.requirePhoneNumber = true;
              setTimeout(() => {
                this.requirePhoneNumber = false;
              }, 3000);
            }
          } else {
            this.requireTaxID = true;
            setTimeout(() => {
              this.requireTaxID = false;
            }, 3000);
          }
        } else {
          this.requireLastName = true;
          setTimeout(() => {
            this.requireLastName = false;
          }, 3000);
        }
      } else {
        this.requireFirstName = true;
        setTimeout(() => {
          this.requireFirstName = false;
        }, 3000);
      }
    } else {
      this.requireCompanyTitle = true;
      setTimeout(() => {
        this.requireCompanyTitle = false;
      }, 3000);
    }
  }
  diff_hours(dt2, dt1) {
    var diff = Math.abs(dt1.getTime() - dt2.getTime());
    return diff / 60000;
  }

  diff_hoursStart(dt3, dt1) {
    var diff = Math.abs(dt1.getTime() - dt3.getTime());
    return diff / 60000;
  }
  endAfterStart(dt1, dt2) {
    var startDate = new Date(dt1);
    var endDate = new Date(dt2);
    return endDate.getTime() >= startDate.getTime();
  }
  goodroadies() {
    let finalstartdate;
    let finalenddtae;
    // if (this.selectdate === true) {
    finalstartdate = this.pickupStartDate;
    finalenddtae = this.pickupEndDate;
    // } else {
    //   finalstartdate = null;
    //   finalenddtae = null;
    // }
    this.multipleFGadd = [];
    this.facilityFGadd = [];
    const body1 = {
      CompanyTitle: this.CompanyTitle,
      FirstName: this.FirstName,
      LastName: this.LastName,
      Account: this.selectedType,
      Email: this.Email,
      PhoneNumber: this.PhoneNumber,
      TaxID: this.TaxID,
      pickupCountry: this.pickupCountry,
      pickupAddress1: this.pickupAddress1,
      pickupAddress2: this.pickupAddress2,
      pickupState: this.pickupState,
      pickupCity: this.pickupCity,
      pickupZipCode: this.pickupZipCode,
      Country: this.Country,
      Address1: this.Address1,
      Address2: this.Address2,
      State: this.State,
      City: this.City,
      ZipCode: this.ZipCode,
      sameAddress: this.sameAddress,
      PickupAfter: finalstartdate,
      PickupBefore: finalenddtae
      // selectdate: this.selectdate,
    };
    this.facilityFGadd.push(body1);
    this.PickupWeight = this.productsData
      .map(r => r.Quantity * r.Weight)
      .reduce((sum, current) => Number(sum) + Number(current));
    let bodystripe = {
      // Roadies: this.selectdate,
      PickupAfter: finalstartdate,
      PickupBefore: finalenddtae,
      PickupAddress: this.pickupAddress1,
      CityId: this.pickupCity,
      StateId: this.pickupState,
      PickupWeight: this.PickupWeight
    };
    this.sassService.onetimebillingStripePlan(bodystripe).subscribe(data => {
      if (data.PlanId) {
        localStorage.setItem(
          'onetimebilling',
          JSON.stringify(this.facilityFGadd)
        );
        localStorage.setItem('portalaccesOTP', JSON.stringify(false));
        localStorage.setItem('OneTimeItem', JSON.stringify(this.productsData));
        this.router.navigate(['PickupBilling']);
      } else {
        alert(data.message);
      }
    });
  }
  goToPayment() {
    this.router.navigate(['PickupBilling']);
  }
  addItems(transaction, i) {
    if (
      transaction.Quantity > 0 &&
      transaction.Weight > 0 &&
      transaction.Price > 0
    ) {
      if (
        transaction.Quantity &&
        transaction.Price &&
        transaction.Weight &&
        transaction.Name
      ) {
        this.addItemsList(transaction, i);
      } else {
        transaction.nameShowValidators = true;
        setTimeout(() => {
          transaction.nameShowValidators = false;
        }, 3000);
      }
    } else {
      transaction.showValidators = true;
      setTimeout(() => {
        transaction.showValidators = false;
      }, 3000);
    }
  }

  addItemsList(transaction, i) {
    // if (transaction.Quantity > 0 && transaction.Weight > 0 && transaction.Price > 0) {
    // if (transaction.Quantity && transaction.Price && transaction.Weight) {
    if (transaction.wasClicked === false) {
      transaction.wasClicked = true;
      transaction.buttonDisabled = false;
      transaction.showValidators = false;
      if (transaction.quantityZero === true) {
        transaction.QuanbuttonDisabled = false;
      }
      if (transaction.weightZero === true) {
        transaction.WeigthbuttonDisabled = false;
      }
      // }
      transaction.imgSrc = '../../assets/images/editicon.png';
    } else {
      if (transaction.wasClicked === true) {
        transaction.wasClicked = false;
      }
      transaction.buttonDisabled = true;
      transaction.imgSrc = '../../assets/images/circle12.png';
      // if (transaction.Sections[0].Name === 'New Items') {
      transaction.newitem = false;
      transaction.deleteoption = true;
      // this.index = i + 1;
      const sendbody = {
        Name: transaction.Name,
        Quantity: transaction.Quantity,
        Sections: [{ SectionId: 0, Name: 'New Items' }],
        Price: transaction.Price,
        Weight: transaction.Weight,
        imgSrc: '../../assets/images/circle12.png',
        wasClicked: transaction.wasClicked,
        index: transaction.index,
        newitem: transaction.newitem,
        deleteoption: transaction.deleteoption
      };
      const body = {
        Name: '',
        Quantity: '',
        Price: '',
        wasClicked: true,
        Weight: '',
        imgSrc: '../../assets/images/editicon.png',
        index: transaction.index + 1,
        newitem: true,
        deleteoption: false
      };
      for (let i = 0; i < this.productsData.length; i++) {
        if (transaction.ProductId === 0) {
          if (transaction.index === this.productsData[i].index) {
            this.productsData.splice(i, 1);
            this.productsData.push(sendbody);
          }
        }
      }
      let newitemcount = 0;
      for (let i = 0; i < this.productsData.length; i++) {
        if (this.productsData[i].newitem === true) {
          newitemcount++;
        }
      }
      if (newitemcount === 0) {
        this.productsData.push(body);
      }
      this.totalItems = this.productsData.length - 1;
      this.totalitemCost = this.productsData
        .map(r => r.Quantity * r.Price)
        .reduce((sum, current) => Number(sum) + Number(current));
      this.dataSource = new MatTableDataSource<Element>(this.productsData);
    }
    // } else {
    //   transaction.showValidators = true;
    //   setTimeout(() => {
    //     transaction.showValidators = false;
    //   }, 3000);
    // }
    // } else {
    //   transaction.showValidators = true;
    //   setTimeout(() => {
    //     transaction.showValidators = false;
    //   }, 3000);
    // }
  }
  onChangeEndDate(date) {
    this.selectedEndDate = date;
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  onChangeEndTime(time) {
    this.pickupEndTime = this.ConvertTime(time);
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  //Date change event(end date-delivery)
  updateEndDateTime(selectedEndDate, pickupEndTime) {
    if (selectedEndDate) {
      selectedEndDate = selectedEndDate;
    } else {
      selectedEndDate = new Date(this.pickupEndDate);
    }
    selectedEndDate.setMinutes(0);
    selectedEndDate.setHours(0);
    let selectedfirstValue;
    selectedfirstValue = pickupEndTime.split(':')[0];

    let selectedSecondValue;
    selectedSecondValue = pickupEndTime.split(':')[1];
    selectedEndDate.setHours(selectedEndDate.getHours() + selectedfirstValue) +
      ':' +
      selectedEndDate.setMinutes(
        selectedEndDate.getMinutes() + selectedSecondValue
      );
    this.selectedEndDate = selectedEndDate;

    let selectedEndDateSplit;
    this.selectedEndTime = moment(selectedEndDate)
      .format()
      .toString();
    selectedEndDateSplit =
      this.selectedEndTime.split('T')[0] +
      'T' +
      this.selectedEndTime
        .split('T')[1]
        .split('-')[0]
        .split('+')[0];
    this.pickupEndDate = selectedEndDateSplit;
  }
  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }

  onChangeDate(date) {
    this.selectedDate = date;
    this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
  onChangeTime(time) {
    this.pickupStartTime = this.ConvertTime(time);
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
      'hh:mm a'
    );
    this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
  //Date change event(start date-pickup)
  updateDateTime(selectedDate, pickupStartTime) {
    if (selectedDate) {
      selectedDate = selectedDate;
    } else {
      selectedDate = new Date(this.pickupStartDate);
    }
    selectedDate.setMinutes(0);
    selectedDate.setHours(0);
    let selectedfirstValue;
    selectedfirstValue = pickupStartTime.split(':')[0];

    let selectedSecondValue;
    selectedSecondValue = pickupStartTime.split(':')[1];
    selectedDate.setHours(selectedDate.getHours() + selectedfirstValue) +
      ':' +
      selectedDate.setMinutes(selectedDate.getMinutes() + selectedSecondValue);
    this.selectedDate = selectedDate;
    this.selectedStartTime = moment(selectedDate)
      .format()
      .toString();
    let selectedStartDateSplit;
    selectedStartDateSplit =
      this.selectedStartTime.split('T')[0] +
      'T' +
      this.selectedStartTime
        .split('T')[1]
        .split('-')[0]
        .split('+')[0];
    this.pickupStartDate = selectedStartDateSplit;
  }
  deletePickup(product) {
    const index = this.productsData.indexOf(product);
    this.productsData.splice(index, 1);
    this.totalItems = this.productsData.length - 1;
    this.totalitemCost = this.productsData
      .map(r => r.Quantity * r.Price)
      .reduce((sum, current) => Number(sum) + Number(current));
    this.dataSource = new MatTableDataSource<Element>(this.productsData);
  }
  existinguserpickup() {
    if (this.existinguser === true) {
    }
  }
  newuserpickup() {
    if (this.newuser === true) {
      this.existinguser = false;
    }
  }
  cleardata() {
    localStorage.removeItem('onetimebilling');
    localStorage.removeItem('OneTimeItem');
    this.onetimebilling = JSON.parse(localStorage.getItem('onetimebilling'));
    this.onetimebilling = [];
    this.totalItems = 0;
    this.totalitemCost = 0;
    this.productsData = [];
    const body = {
      Name: '',
      Quantity: '',
      Price: '',
      wasClicked: true,
      Weight: '',
      imgSrc: '../../assets/images/editicon.png',
      index: 1,
      newitem: true,
      deleteoption: false
    };
    this.productsData.push(body);
    this.dataSource = this.productsData;
    this.dataSource = new MatTableDataSource<Element>(this.productsData);
    (this.CompanyTitle = ''),
      (this.FirstName = ''),
      (this.LastName = ''),
      (this.selectedType = ''),
      (this.Email = ''),
      (this.PhoneNumber = ''),
      (this.TaxID = ''),
      (this.pickupCountry = ''),
      (this.pickupAddress1 = ''),
      (this.pickupAddress2 = ''),
      (this.pickupState = ''),
      (this.pickupCity = ''),
      (this.pickupZipCode = ''),
      (this.Address1 = ''),
      (this.Address2 = ''),
      (this.State = ''),
      (this.City = ''),
      (this.ZipCode = '');
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe(data => {
      this.pickupstatesdata = data['Result'];
    });
  }
  selectedstate() {
    this.sassService.pickupcitydetails(this.State).subscribe(data => {
      this.citydataID = data['Result'];
      this.City = '';
    });
  }
  selectedpickupstate() {
    this.sassService.pickupcitydetails(this.pickupState).subscribe(data => {
      this.pickupcitydataID = data['Result'];
      this.pickupCity = '';
    });
  }
  goToexistSucessReg() {
    if (
      this.pickupAddress1 &&
      this.pickupAddress1 !== undefined &&
      this.pickupAddress1 !== null
    ) {
      if (
        this.pickupCity &&
        this.pickupCity !== undefined &&
        this.pickupCity !== null &&
        this.pickupCity !== 'Select City'
      ) {
        if (
          this.pickupState &&
          this.pickupState !== undefined &&
          this.pickupState !== null
        ) {
          if (
            this.pickupZipCode &&
            this.pickupZipCode !== undefined &&
            this.pickupZipCode !== null
          ) {
            if (
              this.pickupCountry &&
              this.pickupCountry !== undefined &&
              this.pickupCountry !== null
            ) {
              if (
                this.pickupCountry &&
                this.pickupAddress1 &&
                this.pickupState &&
                this.pickupCity &&
                this.pickupZipCode
              ) {
                if (this.productsData.length > 1) {
                  let clickitems = 0;
                  this.productsData.map(value => {
                    if (
                      value.nameenter === true &&
                      value.priceenter === true &&
                      value.weigthenter === true &&
                      value.quantyenter === true &&
                      value.imgSrc === '../../assets/images/editicon.png'
                    ) {
                      value.clickinput = true;
                      clickitems++;
                      setTimeout(() => {
                        value.clickinput = false;
                      }, 3000);
                    } else {
                      value.clickinput = false;
                    }
                  });
                  if (clickitems === 0) {
                    let dt1 = new Date(this.pickupStartDate);
                    let dt2 = new Date(this.pickupEndDate);
                    let dt3 = new Date(
                      this.dateFormat.transform(
                        new Date().toISOString(),
                        this.timezone
                      )
                    );

                    // if (this.pickupTypeID[0]) {
                    // if (this.selectdate === true) {
                    if (this.endAfterStart(dt1, dt2) === true) {
                      if (this.diff_hoursStart(dt3, dt1) >= 60) {
                        if (this.diff_hours(dt1, dt2) >= 120) {
                          this.existRegister();
                        } else {
                          this.diffTimeless = true;
                          setTimeout(() => {
                            this.diffTimeless = false;
                          }, 4000);
                          // this.pickupDetails();
                        }
                      } else {
                        this.futureStartValid = true;
                        setTimeout(() => {
                          this.futureStartValid = false;
                        }, 4000);
                      }
                    } else {
                      this.futureValid = true;
                      setTimeout(() => {
                        this.futureValid = false;
                      }, 4000);
                    }
                    // } else {
                    //   this.existRegister();
                    // }
                    // this.router.navigate(["PickupBilling"]);
                  }

                  // if (this.pickupTypeID[0]) {
                } else {
                  this.requireitems = true;
                  setTimeout(() => {
                    this.requireitems = false;
                  }, 3000);
                }
              } else {
                this.requirpickup = true;
                this.openpickaddress = true;

                setTimeout(() => {
                  this.requirpickup = false;
                }, 3000);
              }
            } else {
              this.requirepickupCountry = true;
              this.openpickaddress = true;
              setTimeout(() => {
                this.requirepickupCountry = false;
              }, 3000);
            }
          } else {
            this.requireZipCode = true;
            this.openpickaddress = true;
            setTimeout(() => {
              this.requireZipCode = false;
            }, 3000);
          }
        } else {
          this.requirepickupState = true;
          this.openpickaddress = true;
          setTimeout(() => {
            this.requirepickupState = false;
          }, 3000);
        }
      } else {
        this.requirepickupCity = true;
        this.openpickaddress = true;
        setTimeout(() => {
          this.requirepickupCity = false;
        }, 3000);
      }
    } else {
      this.requirepickupAddress1 = true;
      this.openpickaddress = true;
      setTimeout(() => {
        this.requirepickupAddress1 = false;
      }, 3000);
    }
  }
  existRegister() {
    let finalstartdate;
    let finalenddtae;
    // if (this.selectdate === true) {
    finalstartdate = this.pickupStartDate;
    finalenddtae = this.pickupEndDate;
    // } else {
    //   finalstartdate = null;
    //   finalenddtae = null;
    // }
    // if (this.pickupTypeID[0]) {
    this.multipleFGadd = [];
    this.facilityFGadd = [];
    const body1 = {
      Email: JSON.parse(localStorage.getItem('otpemail')),
      pickupCountry: this.pickupCountry,
      pickupAddress1: this.pickupAddress1,
      pickupAddress2: this.pickupAddress2,
      pickupState: this.pickupState,
      pickupCity: this.pickupCity,
      pickupZipCode: this.pickupZipCode,
      PickupAfter: finalstartdate,
      PickupBefore: finalenddtae
      // selectdate: this.selectdate,
    };
    this.facilityFGadd.push(body1);
    this.PickupWeight = this.productsData
      .map(r => r.Quantity * r.Weight)
      .reduce((sum, current) => Number(sum) + Number(current));
    let bodystripe = {
      // Roadies: this.selectdate,
      PickupAfter: finalstartdate,
      PickupBefore: finalenddtae,
      PickupAddress: this.pickupAddress1,
      CityId: this.pickupCity,
      StateId: this.pickupState,
      PickupWeight: this.PickupWeight
    };
    this.sassService.onetimebillingStripePlan(bodystripe).subscribe(data => {
      if (data.PlanId) {
        localStorage.setItem(
          'onetimebilling',
          JSON.stringify(this.facilityFGadd)
        );
        localStorage.setItem('portalaccesOTP', JSON.stringify(false));
        localStorage.setItem('OneTimeItem', JSON.stringify(this.productsData));
        this.router.navigate(['PickupBilling']);
      } else {
        alert(data.message);
      }
    });
  }
  ngOnDestroy() {
    this.info.pickupsPage = false;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
  }
}
