import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DeletedialogComponent } from '../deletedialog/deletedialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { EditCustomerBusinessDialogComponent } from '../customers/edit-customer-business-dialog/edit-customer-business-dialog.component';
import { CustomerService } from '../../shared/services/customer.service';
import { from } from 'rxjs';
import { Client } from 'src/app/models/user.model';
import { AccountService } from 'src/app/shared/account.service';
import * as moment from 'moment-timezone';
import { AppState } from 'src/app/shared/app-state';
import { DeleteFacilitatorAccountComponent } from '../dashboard/delete-facilitator-account/delete-facilitator-account.component';
import { SpinnerService } from 'src/app/core/spinner.service';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { TimeZoneabbrService } from 'src/app/shared/pipes/time-zoneabbr.service';

declare var require: any;

export interface PeriodicElement {
  FirstName: string;
  LastName: string;
  TaskPerformed: string;
  Role: string;
  Date: any;
  Time: any;
}
export interface PeriodicElementsForUser {
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  Role: string;
  Status: any;
  LastActivity: any;
  Delete: any;
}
export interface PeriodicElements {
  FacilityName: string;
  FacilityGroup: string;
  AdminFirstName: string;
  AdminLastName: string;
  EmailAddress: string;
  LastActivity: any;
  ViewButton: any;
}

@Component({
  selector: 'app-customer-facilitator-view',
  templateUrl: './customer-facilitator-view.component.html',
  styleUrls: ['./customer-facilitator-view.component.scss'],
})
export class CustomerFacilitatorViewComponent implements OnInit {
  alluser: any;
  clients: any[];
  User: any;
  alluserdetails: any;
  customer: any;
  ClientId: any;
  NPdisplay: boolean;
  pageSize: number;
  RecordCount: any;
  clientname: any;
  name: any;
  email: any;
  phone: any;
  address: any;
  city: any;
  zip: any;
  userData: any;
  Facilitator: boolean;
  FacilitatorGroup: boolean;
  facilityview: boolean;
  FacilitatorGroupname: any;
  Facilitatorname: any;
  categoryTitle: any;
  address2: any;
  facilitatorClients: any;
  facilitygroupClients: any;
  dataSource: any;
  dataSources: any;
  dataSourcesss: any;
  facilityCount: any;
  profileImageUrl: string;
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  FRView: boolean;
  Name: any;
  status: any;
  taxid: any;
  drivernotes: any;
  nodata: any;
  noUserdata: any;
  stateData: any;
  FRViewOnly: boolean;
  peopleserved: any;
  data: any;
  NonProfitWorkingSlots: any;
  NPTime: any;
  NPEndTime: any;
  WorkingDays: any;
  workingdayshours: any;
  workingdays = [
    {
      WorkingDay: 0,
      datas: 'Monday',
      StartTime: '',
      EndTime: '',
    },
    {
      WorkingDay: 1,
      datas: 'Tuesday',
      StartTime: '',
      EndTime: '',
    },
    {
      WorkingDay: 2,
      datas: 'Wednesday',
      StartTime: '',
      EndTime: '',
    },
    {
      WorkingDay: 3,
      datas: 'Thursday',
      StartTime: '',
      EndTime: '',
    },
    {
      WorkingDay: 4,
      datas: 'Friday',
      StartTime: '',
      EndTime: '',
    },
    {
      WorkingDay: 5,
      datas: 'Saturday',
      StartTime: '',
      EndTime: '',
    },
    {
      WorkingDay: 6,
      datas: 'Sunday',
      StartTime: '',
      EndTime: '',
    },
  ];
  VisibleForNotNP: boolean;
  relaodinfo: any;
  saasfg: boolean;
  adminViewonly: boolean;
  MRFdisplay: boolean;
  SeparatedCompost: any;
  timeZoneabbr: string;
  HoldHarmlessAgreement: any;
  nodatafg: any;
  showDriverNotes = false;
  hideEdit: boolean;
  userRole;
  clientDetails;

  constructor(
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private accountService: AccountService,
    private _rdata: modalDataService,
    private route: ActivatedRoute,
    private state: AppState,
    public timeZoneabbrivation: TimeZoneabbrService,
    private AdminService: AdmintoolsService,
    private router: Router,
    private CustomerService: CustomerService
  ) { }
  displayedColumns: string[] = ['Id', 'FacilityName', 'FacilityGroup', 'EmailAddress', 'LastActivity', 'ViewButton'];
  displayedColumnsforrecentactivity: string[] = ['FirstName', 'TaskPerformed', 'Role', 'Date', 'Time'];
  displayedColumnsforUsers: string[] = [
    'FirstName',
    'EmailAddress',
    'Role',
    'Status',
    'LastActivity',
    // 'Delete'
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.FRView = getFeature('FRViewOnly');
    this.VisibleForNotNP = getFeature('OnlyFacility');
    this.adminViewonly = getFeature('VisibleForAdminOnlyGrab');
    this.state.isSpinnerVisible = true;
    this.facilityClients();
    this.FGClients();
    this.ClientId = this.route.snapshot.params.id;
    this.v2_getAlluser(this.ClientId);
    this.pageSize = 10;
    this.CustomerService.getAllCustomers(1, this.pageSize).subscribe((data) => {
      this.RecordCount = data.RecordCount;
      this.clientname = data.Result.Name;
      const clients: Client[] = [];
      for (let i = 0; i <= data['Result'].length - 1; i++) {
        const client: Client = data['Result'][i];
        if (client.MenuType.length > 0) {
          client.MenuType = client.MenuType[0];
        } else {
          //TODO Remove hardcode empty menutype
          client.MenuType = { ID: 1, Title: 'NoMenu', Description: '' };
        }
        clients.push(client);
        // this.excelExport.push(pickUnit);
      }
    });
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
      if (this.relaodinfo.reloadphotoData === true) {
        this.getFacilityClient();
      } else if (this.relaodinfo.reloadData === true) {
        this.getFacilityClient();
      } else if (this.relaodinfo.reloadphotoData === false && this.relaodinfo.reloadData === false) {
        this.getFacilityClient();
      }
    });
  }
  getFacilityClient() {
    if (this.relaodinfo.reloadphotoData === false && this.relaodinfo.reloadData === false) {
      // var spinnerRef = this.spinnerService.start();
    }
    this.CustomerService.getCustomerDetail(this.ClientId).subscribe((data) => {
      if (this.relaodinfo.reloadphotoData === false && this.relaodinfo.reloadData === false) {
        // this.spinnerService.stop(spinnerRef);
      }
      this.getnonprofitAuto();
      if (this.relaodinfo.reloadData === true) {
        this.relaodinfo.reloadData = false;
        this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
      }
      if (this.relaodinfo.reloadphotoData === true) {
        this.relaodinfo.reloadphotoData = false;
        this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
      }

      this.customer = data['Result'];
      this.clientDetails = this.customer.map((x) => Object.assign({}, x));

      this.timeZoneabbr = this.timeZoneabbrivation.transform(this.customer[0].TimeZone);
      if (this.customer[0].DriverServices.length > 0) {
        if (this.customer[0].DriverServices[0].Title === 'SAAS' && this.customer[0].ClientCategory[0].Title === 'SAAS Facility Group') {
          this.saasfg = true;
        } else {
          this.saasfg = false;
        }
      }
      this.Name = this.customer[0].Name;
      this.email = this.customer[0].Email;
      this.phone = this.customer[0].Phone;
      this.address = this.customer[0].Address;
      this.address2 = this.customer[0].Address2;
      this.city = this.customer[0].City;
      this.stateData = this.customer[0].State;
      this.zip = this.customer[0].ZIP;
      this.taxid = this.customer[0].EINNumber;
      this.drivernotes = this.customer[0].DriverNotes;
      this.HoldHarmlessAgreement = this.customer[0].HoldHarmlessAgreement;
      // console.log(this.HoldHarmlessAgreement)
      this.status = this.customer[0].ApprovalStatus[0].Title;
      this.categoryTitle = this.customer[0].ClientCategory[0].Title;
      this.peopleserved = this.customer[0].NumberOfPeopleServed;
      this.SeparatedCompost = this.customer[0].SeparatedCompost.sort((a, b) => (a.Title > b.Title ? 1 : -1));
      if (this.customer[0].NonProfitWorkingSlot.length > 0) {
        this.workingdayshours = this.customer[0].NonProfitWorkingSlot[0].WorkingDay;
      }
      if (this.customer[0].MenuType.length > 0) {
        if (this.customer[0].MenuType[0].Title === 'Grab') {
          if (this.customer[0].ImageUrl == null) {
            // this.profileImageUrl = '../../../../assets/images/GLogo.jpg';
            this.ImageUrlnull = true;
            this.ImageUrl = false;
          } else {
            this.profileImageUrl = this.customer[0].ImageUrl;
            this.ImageUrl = true;
            this.ImageUrlnull = false;
          }
        } else {
          if (this.customer[0].Logo == null) {
            // this.profileImageUrl = '../../../../assets/images/GLogo.jpg';
            this.ImageUrlnull = true;
            this.ImageUrl = false;
          } else {
            this.profileImageUrl = this.customer[0].Logo;
            // this.profileImageUrl = this.data[0].customer[0].Logo;
            this.ImageUrl = true;
            this.ImageUrlnull = false;
          }
        }
      } else {
        if (this.customer[0].Logo == null) {
          // this.profileImageUrl = '../../../../assets/images/GLogo.jpg';
          this.ImageUrlnull = true;
          this.ImageUrl = false;
        } else {
          this.profileImageUrl = this.customer[0].Logo;
          // this.profileImageUrl = this.data[0].customer[0].Logo;
          this.ImageUrl = true;
          this.ImageUrlnull = false;
        }
      }
      if (this.categoryTitle === 'Facilitator') {
        this.FRViewOnly = true;
        this.facilityview = true;
        this.NPdisplay = false;
        this.MRFdisplay = false;

        this.facilityClients();
      } else if (this.categoryTitle === 'Facility Group' || this.categoryTitle === 'SAAS Facility Group') {
        this.FRViewOnly = false;
        this.facilityview = true;
        this.NPdisplay = false;
        this.MRFdisplay = false;

        this.facilityGroupClients();
      } else if (this.categoryTitle === 'Non Profit') {
        this.facilityview = false;
        this.NPdisplay = true;
        this.MRFdisplay = false;
      } else if (this.categoryTitle === 'MRF') {
        this.facilityview = false;
        this.NPdisplay = false;
        this.MRFdisplay = true;
      }
      if ((this.customer[0].Facilitator && this.customer[0].Facilitator.length) || (this.customer[0].FacilityGroup && this.customer[0].FacilityGroup.length > 0)) {
        this.Facilitator = true;
        this.FacilitatorGroup = true;
      }

      this.userRole = this.state.user.User.Roles[0].Name;
      if (this.userRole == 'Facility' && this.categoryTitle == 'Non Profit') {
        this.hideEdit = false;
      } else if (this.userRole == 'Read-Only Admin') {
        this.hideEdit = false;
      } else this.hideEdit = true;
    });
  }
  onChangePage(e) {
    this.pageSize = e.pageSize;
  }
  getnonprofitAuto() {
    this.CustomerService.getClientsProducttDetail(this.route.snapshot.params.id).subscribe((data) => {
      this.NonProfitWorkingSlots = data.Result;

      for (var j = 0; j < this.workingdays.length; j++) {
        this.workingdays[j].StartTime = '';
        this.workingdays[j].EndTime = '';
      }
      for (var j = 0; j < this.workingdays.length; j++) {
        for (var i = 0; i < this.NonProfitWorkingSlots.length; i++) {
          if (this.NonProfitWorkingSlots[i].WorkingDay == this.workingdays[j].WorkingDay) {

            let fixedDate;
            this.workingdays[j].StartTime = moment
              .utc(this.NonProfitWorkingSlots[i].StartTime)
              .clone()
              .tz(this.customer[0].TimeZone)
              .format('HH:mm')
              .toString();
            this.workingdays[j].EndTime = moment
              .utc(this.NonProfitWorkingSlots[i].EndTime)
              .clone()
              .tz(this.customer[0].TimeZone)
              .format('HH:mm')
              .toString();
            this.workingdays[j]['checkedId'] = true;
          }
        }
      }
    });
  }
  facilityGroupClients() {
    // var spinnerRef = this.spinnerService.start();
    this.CustomerService.getfacilityGroupReceiver(this.route.snapshot.params['id']).subscribe(
      (clients) => {
        if (this.categoryTitle === 'Facility Group') {
          // this.spinnerService.stop(spinnerRef);
          clients.Result = clients.Result.filter((client) => {
            return client.ClientCategory[0].Title === 'Facility';
          });
          this.facilitatorClients = clients.Result;
          this.facilityCount = this.facilitatorClients.length;
          this.nodata = clients.Result.length;
          this.dataSource = this.facilitatorClients;
        } else if (this.categoryTitle === 'SAAS Facility Group') {
          // this.spinnerService.stop(spinnerRef);
          clients.Result = clients.Result.filter((client) => {
            return client.ClientCategory[0].Title === 'SAAS Facility';
          });
          this.facilitatorClients = clients.Result;
          this.facilityCount = this.facilitatorClients.length;
          this.nodata = clients.Result.length;
          this.dataSource = this.facilitatorClients;
        }
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        // this.spinnerService.stop(spinnerRef);
      }
    );
  }
  facilityClients() {
    this.CustomerService.getfacilityReceiver(this.route.snapshot.params['id']).subscribe(
      (clients) => {
        if (this.categoryTitle === 'Facilitator') {
          clients.Result = clients.Result.filter((client) => {
            return client.ClientCategory[0].Title === 'Facility';
          });
          this.facilitatorClients = clients.Result;
          this.facilityCount = this.facilitatorClients.length;
          this.nodata = clients.Result.length;
          this.dataSource = this.facilitatorClients;
        }
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  FGClients() {
    this.CustomerService.getfacilitygroup(this.route.snapshot.params['id']).subscribe(
      (clients) => {
        this.facilitygroupClients = clients.Result;
        // this.facilityCount = this.facilitygroupClients.length;
        this.dataSourcesss = this.facilitygroupClients;
        this.nodatafg = clients.Result.length;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  goToDetailsPage(event) {
    localStorage.setItem('FacilitatorFGroupclientid', this.route.snapshot.params.id);
    this.router.navigate(['FacilityDetails/' + event.ClientId + '']);
  }
  removelDialog() {
    const dialogRef = this.dialog.open(DeletedialogComponent, {
      width: '650px',
      // height: "300px",
      panelClass: 'custom-Filterdialog-container',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  backtoUsers() {
    this.router.navigate(['users']);
  }
  backtoCustomers() {
    this.router.navigate(['Customers']);
  }
  editbusinessdetails() {
    const dialogRef = this.dialog.open(EditCustomerBusinessDialogComponent, {
      width: '984px',
      // height: '980px',
      panelClass: 'custom-Filterdialog-container',
      data: [
        {
          customer: this.customer,
        },
      ],
    });
    // dialogRef.afterClosed().subscribe(result => {
    // });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result[0].res) {
          this.getFacilityClient();
        }
        if (result[0].NP === true) {
          this.getnonprofitAuto();
        }
      } else {
        this.customer = this.clientDetails.map((x) => Object.assign({}, x));
      }
    });
  }
  deleteFacilitatorAccount() {
    const dialogRef = this.dialog.open(DeleteFacilitatorAccountComponent, {
      width: '700px',
      // height: '320px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ ClientData: this.userData }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.categoryGetData();
    });
  }
  v2_getAlluser(id) {
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.v2_getAllUser(id).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dataSources = data.Result;
      this.noUserdata = data.Result.length;
      data.Result.forEach((element) => { });
    });
  }

  showDriverNotesDiv() {
    this.showDriverNotes = !this.showDriverNotes;
    console.log(this.showDriverNotes);
  }

  redirectUserDetails(id) {
    this.router.navigate(['CustomerViewDetails/' + id.UserId + '']);
  }
}
