import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'dateOnlyWithTimezone'
})
export class dateOnlyWithTimezone implements PipeTransform {
    transform(date: string, zone) {
        if (moment(date).isValid()) {
            let zonetime;
            if (zone) zonetime = zone;
            else zonetime = 'America/New_York';

            const abbr = moment.tz.zone(zonetime).abbr(360);
            if (abbr == 'MST') {
                const updateDate = moment.utc(date).clone().tz(zonetime).format('MM/DD/YY').toString();
                return updateDate;
            } else {
                const updateDate = moment.utc(date).clone().tz(zonetime).format('MM/DD/YY').toString();
                return updateDate;
            }
        }
        return null;
    }
}
