import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AppState } from 'src/app/shared/app-state';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { SuccesPopupComponent } from '../succes-popup/succes-popup.component';
import { MatDialog } from '@angular/material';
declare var Stripe: any;

@Component({
  selector: 'app-update-checkout',
  templateUrl: './update-checkout.component.html',
  styleUrls: ['./update-checkout.component.scss']
})
export class UpdateCheckoutComponent implements OnInit {

  email: string;
  firstname: string;
  lastname: string;
  cardNumber: string;
  cvc: string;
  stripe: any;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;

  message: string;
  yearlyPay: any;
  UserId: any;
  ClientId: any;
  user: any;
  clientData: any;
  yearlyPlan: boolean;
  faclityCount: any;
  constructor(
    private cd: ChangeDetectorRef,
    private router: Router, private sassService: SAASService, private route: ActivatedRoute,public dialog: MatDialog,
    private spinnerService: SpinnerService, private state: AppState, private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.ClientId = this.route.snapshot.params.id;
    this.sassService.getSubDetails(this.route.snapshot.params.id).subscribe(
      data => {
        this.clientData = data.Result[0];
        if (this.clientData.StripePlanId === environment.yearlyKey) {
          this.yearlyPlan = true;
        } else {
          this.yearlyPlan = false;
        }
      }
    );
    this.customerService.getAllFilteredCustomers(
      null, null, null, null, null, null, null, null, this.route.snapshot.params.id, null, null, null, null
    ).subscribe(data => {
      if (data.Result.length > 0) {
        data.Result = data.Result.filter(value => value.ApprovalStatus === 'Active');
        this.faclityCount = data.Result.length;
      } else {
        this.faclityCount = 1;
      }
    });
    this.state.user = JSON.parse(localStorage.getItem("user")).user;
    this.user = this.state.user;
    this.UserId = this.user.User.UserId;
    // this.ClientId = this.user.User.Clients[0].ClientId;
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();

    this.cardNumberElement = elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number-element');
    this.cardNumberElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardExpiryElement = elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry-element');
    this.cardExpiryElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardCvcElement = elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc-element');
    this.cardCvcElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  goToProfileedit() {
    this.router.navigate(['EditSubscription/' + this.ClientId + '']);
  }
  goToSucessPage() {
    this.router.navigate(['paymentSuccess']);
  }
  createUser() {
    this.stripe.createToken(this.cardNumberElement).then(result => {
      if (result.token.id) {
        // let planid;
        // if (this.yearlyPay === 'montly') {
        //   planid = 'price_HON4TdbdJWPeVz';
        // } else {
        //   planid = 'price_HON5NBmCH4W13i';
        // }
        const payload = {
          Token: result.token.id,
          Update: true,
          ClientId: this.ClientId,
          UserId: this.UserId
        };
        const spinnerRef = this.spinnerService.start();
        this.sassService.striping(payload).subscribe(
          data => {
            this.cardCvcElement.clear();
            this.cardExpiryElement.clear();
            this.cardNumberElement.clear();
            if (data.message === 'Updated Successfully') {
              const dialogRef = this.dialog.open(SuccesPopupComponent,
                {
                  width: '338px',
                  // height: '480px',
                  data: { ClientData: data.message },
                  panelClass: 'custom-editSub-container',
                });
              dialogRef.afterClosed().subscribe(result => {
              }
              );
              // localStorage.setItem('updateheckout', JSON.stringify(true));
              // this.router.navigate(['paymentSuccess']);
            } else {
              alert(data.message);
            }
            this.spinnerService.stop(spinnerRef);
          },
          error => {
            this.spinnerService.stop(spinnerRef);
            // this.buttonDisabled = false;
            // alert(JSON.stringify(error));
            alert("Sorry, payment failed! Click on 'Pay' to continue");
          }
        );
      }
    });

  }
}
