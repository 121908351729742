import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment-timezone';
import 'rxjs/Rx';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { EditBusinessDialogComponent } from '../edit-business-dialog/edit-business-dialog.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { CustomerService } from '../../shared/services/customer.service';
import { AccountCreationDialogComponent } from '../account-creation-dialog/account-creation-dialog.component';
import { AppState } from 'src/app/shared/app-state';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { MyOrderByPipe } from 'src/app/shared/pipes/sort.pipe';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { TimeSelectDialogComponent } from 'src/app/time-select-dialog/time-select-dialog.component';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { orCustomMaterialList } from 'src/app/shared/services/or-custom-material-list.model';

@Component({
  selector: 'app-facility-account-dialog',
  templateUrl: './facility-account-dialog.component.html',
  styleUrls: ['./facility-account-dialog.component.scss']
})
export class FacilityAccountDialogComponent implements OnInit {
  radioOptions: string;
  radioOptionsFR: string;
  radioOptionsFG: string;
  MenuEditFlag: string;
  facilityAccessFlag: string;
  radioOptions2 = 'option 1';
  modalclose: string;
  info: any;
  selectPickupType: any;
  showEndTime: string;
  facilityGroup: any;
  facilatorGroup: any;
  clientData: any;
  accountType: any;
  state: any;
  allDeliveryStatus: any;
  allClients: any;
  isFaclility: any;
  nosaasFG: boolean;
  account_typeName: any;
  invalidemail = false;
  selectedType = 'Facilitator';
  selectedAccountType: any;
  facilitatorClient: any;
  facilityGroupClient: any;
  facilityGroupClientFR: any;
  NonProfitWorkingSlots = [];
  facilityGroupClients: any;
  facilitatorClients: any;
  selectedfacilityType: any;
  driverService: any;
  driverSelected: any;
  validclient: boolean;
  facilityblock: boolean;
  accountId: any;
  infoClients: any;
  drivers: any;
  DriverServices = [];
  DriverServicesdata = [
    {
      ID: 1,
      Title: 'Goodr'
    },
    {
      ID: 2,
      Title: 'Roadie'
    },
    {
      ID: 3,
      Title: 'SAAS Client'
    }
  ];
  workingdays = [
    {
      WorkingDay: 0,
      datas: 'Monday',
      StartTime: '',
      EndTime: ''
    },
    {
      WorkingDay: 1,
      datas: 'Tuesday',
      StartTime: '',
      EndTime: ''
    },
    {
      WorkingDay: 2,
      datas: 'Wednesday',
      StartTime: '',
      EndTime: ''
    },
    {
      WorkingDay: 3,
      datas: 'Thursday',
      StartTime: '',
      EndTime: ''
    },
    {
      WorkingDay: 4,
      datas: 'Friday',
      StartTime: '',
      EndTime: ''
    },
    {
      WorkingDay: 5,
      datas: 'Saturday',
      StartTime: '',
      EndTime: ''
    },
    {
      WorkingDay: 6,
      datas: 'Sunday',
      StartTime: '',
      EndTime: ''
    }
  ];
  optiondriverStatus: boolean;
  selectedDriver: any;
  selectedSGDriver: string;
  driverStatusSelected: boolean;
  driverNotes: any;
  getClients: any;
  spinnerRef: any;
  driverblock: boolean;
  pickupTypeID = [];
  pickupTypeIDFG = [];
  NotificationTypesID = [];
  PickupTypeS: any;
  PickupTypeSfg = [
    {
      ID: 1,
      Title: 'Food'
    },
    {
      ID: 2,
      Title: 'Organic Recycling'
    }
  ];
  selectPickupValidation: boolean;
  selectRoadiesValidation: boolean;
  validRoadiesTrue: boolean;
  NotificationTypes: any;
  selectPickupNotfication: boolean;
  facilitatorblock: boolean;
  facilityGroupFRClient: any;
  facilatorGroupFR: boolean;
  SAASFG: boolean;
  FGNone: boolean;
  NPBylocation: boolean;
  MRFBylocation: boolean;
  WorkingDaysID = [];
  SeparatedCompostID = [];
  WorkingDaysIDChecked: any;
  showstartTime: string;
  NofPeoples: number;
  peoplesValid: boolean;
  NonProfitWorkingValid: boolean;
  userRole: any;
  frGrouplogin = true;
  fgRoleLogin: boolean;
  selecetFG: boolean;
  menuedit = true;
  activeState: boolean;
  driverEnabled: boolean;
  selectClient: boolean;
  selectfacClient: boolean;
  saasselc = true;
  nosaaslist: any;
  saaslist: any;
  saasFacilityG: boolean;
  separatedCompost: any;
  materiallistValid: boolean;
  featureinfo: any;
  SAASFeature: boolean;
  deliveryTitle: any;
  RoadiePostmatesFeature: boolean;
  constructor(
    private customerServices: CustomerService,
    private adminToolServices: AdmintoolsService,
    private appstate: AppState,
    public _rdata: modalDataService,
    private ClientFilterdata: ClientFilterService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FacilityAccountDialogComponent>,
    public BackRef: MatDialogRef<AccountCreationDialogComponent>,
    private AdminService: AdmintoolsService,
    private orderPipe: MyOrderByPipe,
    private pickupService: PickUpService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe(res => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            this.SAASFeature = false;
          }
          if (element.Title === 'Roadie/Postmates' && element.EnableFeature === true) {
            this.RoadiePostmatesFeature = true;
          } else {
            this.RoadiePostmatesFeature = false;
          }
        }
        // if (this.featureinfo.featureData[1].Title === 'SAAS') {
        //   if (this.featureinfo.featureData[1].EnableFeature === false) {
        //     this.SAASFeature = false;
        //   }
        // }
      }
      // }
    });
    // this.workingdays.map(value => {
    //   value.StartTime = moment(value.StartTime, ["HH.mm"]).format("hh:mm a");
    //   value.EndTime = moment(value.EndTime, ["HH.mm"]).format("hh:mm a");
    // });
    // this.showStartTime = moment(this.pickupStartTime, ["HH.mm"]).format("hh:mm a");
    this.userRole = this.appstate.user.User.Roles[0].Name;
    // this.adminToolServices.getGoodrDrivers().subscribe(
    //   data => {
    //   },
    //   error => {
    //     // this.state.isSpinnerVisible = false;
    //     // alert(JSON.stringify(error));
    //   }
    // );
    this.ClientFilterdata.basicInfoObserve.subscribe(res => {
      this.infoClients = JSON.parse(res);
      this.getClients = this.infoClients.getallClients;
      // if (this.infoClients.facilitatorGroup.length > 0) {
      //   this.facilityGroupClients = this.infoClients.facilitatorGroup.filter(value => value.ApprovalStatus[0].Title === 'Active');
      // }
      if (this.infoClients.facilitator.length > 0) {
        this.facilitatorClients = this.infoClients.facilitator.filter(
          value => value.ApprovalStatus === 'Active'
        );
      }
      if (this.getClients === true) {
        if (this.spinnerRef) {
          // this.spinnerService.stop(this.spinnerRef);
        }
      }
    });
    this.facilityGroup = false;
    this.facilatorGroup = false;
    this._rdata.basicInfo2Observe.subscribe(res => {
      this.info = JSON.parse(res);
      if (this.info.PickupTypeSfg) {
        if (this.info.PickupTypeSfg.length > 0) {
          for (var j = 0; j < this.PickupTypeSfg.length; j++) {
            for (var i = 0; i < this.info.PickupTypeSfg.length; i++) {
              this.pickupTypeIDFG = this.info.PickupTypeSfg;
              if (this.info.PickupTypeSfg[i] == this.PickupTypeSfg[j].Title) {
                this.PickupTypeSfg[j]['checkedId'] = true;
              }
            }
          }
        } else {
          for (const status of this.PickupTypeSfg) {
            if (status.Title === 'Food') {
              this.pickupTypeIDFG.push(status.Title);
            }
          }
          for (var j = 0; j < this.PickupTypeSfg.length; j++) {
            if (this.pickupTypeIDFG[0] === this.PickupTypeSfg[j].Title) {
              this.PickupTypeSfg[j]['checkedId'] = true;
            }
          }
        }
      } else {
        for (const status of this.PickupTypeSfg) {
          if (status.Title === 'Food') {
            this.pickupTypeIDFG.push(status.Title);
          }
        }
        for (var j = 0; j < this.PickupTypeSfg.length; j++) {
          if (this.pickupTypeIDFG[0] === this.PickupTypeSfg[j].Title) {
            this.PickupTypeSfg[j]['checkedId'] = true;
          }
        }
      }
      this.selectedType = this.info.facility_type;
      this.driverSelected = this.info.driverService;
      if (this.selectedType === 'Facility') {
        this.NPBylocation = false;
        this.MRFBylocation = false;
        this.facilityblock = true;
        this.driverblock = true;
        this.facilitatorblock = false;
        // this.facilityData();
        // this.driverSelected = this.info.driverService;
      } else if (this.selectedType === 'SAAS Facility') {
        this.NPBylocation = false;
        this.MRFBylocation = false;
        this.facilityblock = true;
        this.driverblock = true;
        this.facilitatorblock = false;
        // this.facilityData();
        // this.driverSelected = this.info.driverService;
      } else if (this.selectedType === 'SAAS Facility Group') {
        this.saasFacilityG = true;
        this.facilitatorblock = false;
        this.MRFBylocation = false;
        this.facilityblock = false;
        this.driverblock = false;
        this.NPBylocation = false;
      } else if (this.selectedType === 'Non Profit') {
        this.driverblock = true;
        this.MRFBylocation = false;
        this.facilitatorblock = false;
        this.NPBylocation = true;
      } else if (this.selectedType === 'MRF') {
        this.MRFBylocation = true;
        this.driverblock = true;
        this.facilitatorblock = false;
        this.NPBylocation = false;
      } else if (this.selectedType === 'Facility Group') {
        this.customerServices.getPickupTypeList().subscribe(data => {
          this.driverService = data.Result;
          this.driverService = this.driverService.filter(
            value => value.Title === 'Goodr' || value.Title === 'SAAS'
          );
          this.driverSelected = this.info.driverService;
          const detaildriver = this.driverService;
          for (const status of detaildriver) {
            if (status.ID === this.driverSelected) {
              // this.driverSelected = status.ID;
              let fgdriver;
              fgdriver = status.Title;
              if (fgdriver !== 'SAAS') {
                this.nosaasFG = true;
              } else {
                this.nosaasFG = false;
              }
            }
          }
        });
        this.facilitatorblock = true;
        this.facilityblock = false;
        this.NPBylocation = false;
        this.MRFBylocation = false;
        this.driverblock = false;
      } else {
        this.facilityblock = false;
        this.driverblock = false;
        this.facilitatorblock = false;
      }
      if (this.info.isMenuEdit === 'false') {
        this.MenuEditFlag = 'false';
      } else {
        this.MenuEditFlag = 'true';
      }
      if (this.info.facilityAccess === 'false') {
        this.facilityAccessFlag = 'false';
      } else {
        this.facilityAccessFlag = 'true';
      }
      if (this.userRole === 'Facility Group') {
        this.facilityAccessFlag = 'false';
      }
      if (this.info.isUnderFacilityGroup) {
        this.radioOptions = 'facilitatorGrp';
        this.facilityGroup = true;
        this.facilatorGroup = false;
      } else if (this.info.isUnderFacilatorGroup) {
        this.radioOptions = 'facilitatorValue';
        this.facilatorGroup = true;
        this.facilityGroup = false;
      } else {
        this.radioOptions = 'facilityNone';
        this.facilatorGroup = false;
        this.facilityGroup = false;
      }
      if (this.info.isUnderFacilityGroupFR) {
        this.radioOptionsFR = 'facilitatorGrpFR';
        this.facilatorGroupFR = true;
        this.radioOptionsFG = 'SAASFG';
        this.SAASFG = true;
        // this.facilitatorblock = false;
      } else {
        this.radioOptionsFR = 'facitatorNone';
        this.facilatorGroupFR = false;
        this.radioOptionsFG = 'FGNone';
        this.FGNone = false;
      }
      if (this.info.nosaasFG === false) {
        this.nosaasFG = false;
        this.radioOptionsFG = 'SAASFG';
        // this.facilityGroupClients = this.saaslist.filter(value => value.ApprovalStatus[0].Title === 'Active');
      } else {
        this.nosaasFG = true;
        this.radioOptionsFG = 'FGNone';
        // this.
      }
      this.NonProfitWorkingSlots = this.info.NonProfitWorkingSlots;
      for (var j = 0; j < this.workingdays.length; j++) {
        for (var i = 0; i < this.NonProfitWorkingSlots.length; i++) {
          // this.pickupTypeID = this.info.pickupTypes;
          if (
            this.info.NonProfitWorkingSlots[i].WorkingDay ===
            this.workingdays[j].WorkingDay
          ) {
            this.workingdays[j]['StartTime'] = this.NonProfitWorkingSlots[
              i
            ].StartTime;
            this.workingdays[j]['EndTime'] = this.NonProfitWorkingSlots[
              i
            ].EndTime;
            this.workingdays[j]['checkedId'] = true;
          }
        }
      }
      this.facilityGroupFRClient = this.info.FacilityGroupId;
      this.facilityGroupClient = this.info.FacilityGroupId;
      this.facilityGroupClientFR = this.info.FacilityGroupFRId;
      this.facilitatorClient = this.info.FacilitatorId;
      this.account_typeName = this.info.account_typeName;
      this.facilityGroup = this.info.isUnderFacilityGroup;
      this.facilatorGroupFR = this.info.isUnderFacilityGroupFR;
      this.facilatorGroup = this.info.isUnderFacilatorGroup;
      this.NofPeoples = this.info.NofPeoples;
      this.driverNotes = this.info.driverNotes;
    });
    if (this.selectedType === 'Facility') {
      this.facilityData();
      // this.driverSelected = this.info.driverService;
    } else if (this.selectedType === 'SAAS Facility') {
      this.facilityData();
    } else if (this.selectedType === 'MRF') {
      this.separetaedRequest();
    }
    this.allClients = this.appstate.user.User.Clients;
    this.customerServices.getAccountType().subscribe(
      data => {
        this.allDeliveryStatus = data.Result;
        if (this.SAASFeature === false) {
          this.allDeliveryStatus = this.allDeliveryStatus.filter(
            value =>
              value.Title !== 'SAAS Facility' &&
              value.Title !== 'SAAS Facility Group'
          );
        }
        if (this.userRole === 'Facility Group') {
          this.facilityData();
          this.frGrouplogin = false;
          this.facilityblock = true;
          this.driverblock = true;
          this.allDeliveryStatus = this.allDeliveryStatus.filter(
            value =>
              value.Title !== 'Non Profit' &&
              value.Title !== 'MRF' &&
              value.Title !== 'Facilitator' &&
              value.Title !== 'Facility' &&
              value.Title !== 'Facility Group' &&
              value.Title !== 'SAAS Facility Group'
          );
          this.selectedType = 'SAAS Facility';
          this.saasselc = false;
          this.facilityblock = true;
        }
        const detail = this.allDeliveryStatus;
        for (const status of detail) {
          if (status.Title === 'Facility') {
            this.isFaclility = status.ClientCategoryId;
          }
        }
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  separetaedRequest() {
    this.pickupService.SeparatedCompost().subscribe(data => {
      this.separatedCompost  = orCustomMaterialList(data.Result);
      if (this.info.SeparatedCompostID) {
        for (var j = 0; j < this.separatedCompost.length; j++) {
          for (var i = 0; i < this.info.SeparatedCompostID.length; i++) {
            this.SeparatedCompostID = this.info.SeparatedCompostID;
            if (
              this.info.SeparatedCompostID[i] == this.separatedCompost[j].ID
            ) {
              this.separatedCompost[j]['checkedId'] = true;
            }
          }
        }
      }
    });
  }
  facilityData() {
    if (this.selectedType === 'SAAS Facility') {
      this.info.nosaasFG = true;
      this.saasselc = false;
      this.optiondriverStatus = true;
    } else {
      this.info.nosaasFG = false;
      this.saasselc = true;
      this.optiondriverStatus = false;
    }
    this.customerServices.getnoSAASFGList().subscribe(data => {
      this.saaslist = data.Result;
      if (this.driverService) {
        const detail = this.driverService;
        for (const status of detail) {
          if (status.ID === this.driverSelected) {
            this.deliveryTitle = status.Title;
          }
        }
      }
      if (!this.info.nosaasFG) {
        if (this.deliveryTitle === 'SAAS') {
          this.facilityGroupClients = this.saaslist.filter(
            value =>
              value.ApprovalStatus === 'Active' &&
              value.DriverService === 'SAAS'
          );
        } else {
          this.facilityGroupClients = this.saaslist.filter(
            value =>
              value.ApprovalStatus === 'Active' &&
              value.DriverService !== 'SAAS'
          );
        }
        // this.facilityGroupClients = this.saaslist.filter(
        //   value => value.ApprovalStatus === 'Active'
        // );
      } else if (this.info.nosaasFG === false) {
        if (this.deliveryTitle === 'SAAS') {
          this.facilityGroupClients = this.saaslist.filter(
            value =>
              value.ApprovalStatus === 'Active' &&
              value.DriverService === 'SAAS'
          );
        } else {
          this.facilityGroupClients = this.saaslist.filter(
            value =>
              value.ApprovalStatus === 'Active' &&
              value.DriverService !== 'SAAS'
          );
        }
        // this.facilityGroupClients = this.saaslist.filter(
        //   value => value.ApprovalStatus === 'Active'
        // );
      }
      // this.driverService = data.Result;
    });
    this.customerServices.getSAASFGList().subscribe(data => {
      this.nosaaslist = data.Result;
      if (this.info.nosaasFG === true) {
        this.facilityGroupClients = this.nosaaslist.filter(
          value => value.ApprovalStatus === 'Active'
        );
      }
      if (this.saasselc === false) {
        this.facilityGroupClients = this.nosaaslist.filter(
          value => value.ApprovalStatus === 'Active'
        );
      }
    });
    this.customerServices.CompostPickupTypeList().subscribe(
      data => {
        this.PickupTypeS = data.Result;
        this.PickupTypeS = this.PickupTypeS.filter(
          value => value.Title !== 'One Time Pickup'
        );
        if (this.info.pickupTypes) {
          if (this.info.pickupTypes.length > 0) {
            for (var j = 0; j < this.PickupTypeS.length; j++) {
              for (var i = 0; i < this.info.pickupTypes.length; i++) {
                this.pickupTypeID = this.info.pickupTypes;
                if (this.info.pickupTypes[i] == this.PickupTypeS[j].ID) {
                  this.PickupTypeS[j]['checkedId'] = true;
                }
              }
            }
          } else {
            for (const status of this.PickupTypeS) {
              if (status.Title === 'Food') {
                this.pickupTypeID.push(status.ID);
              }
            }
            for (var j = 0; j < this.PickupTypeS.length; j++) {
              if (this.pickupTypeID[0] === this.PickupTypeS[j].ID) {
                this.PickupTypeS[j]['checkedId'] = true;
              }
            }
          }
        } else {
          for (const status of this.PickupTypeS) {
            if (status.Title === 'Food') {
              this.pickupTypeID.push(status.ID);
            }
          }
          for (var j = 0; j < this.PickupTypeS.length; j++) {
            if (this.pickupTypeID[0] === this.PickupTypeS[j].ID) {
              this.PickupTypeS[j]['checkedId'] = true;
            }
          }
        }
        if (this.driverService) {
          if (this.driverService.length > 0) {
            for (const status of this.driverService) {
              if (this.info.driverService === status.ID) {
                if (status.Title === 'SAAS') {
                } else if (status.Title === 'Goodr') {
                } else {
                  for (var j = 0; j < this.PickupTypeS.length; j++) {
                    if (this.PickupTypeS[j].Title === 'Organic Recycling') {
                      this.PickupTypeS[j]['disabled'] = true;
                      this.PickupTypeS[j]['checkedId'] = false;
                    }
                  }
                }
              }
            }
          }
        }
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );

    this.customerServices.NotificationTypes().subscribe(
      data => {
        this.NotificationTypes = data.Result;
        this.NotificationTypes = this.NotificationTypes.filter(
          value => value.Title !== 'NotifyUser'
        );
        if (this.info.NotificationTypes) {
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            for (var i = 0; i < this.info.NotificationTypes.length; i++) {
              this.NotificationTypesID = this.info.NotificationTypes;
              if (
                this.info.NotificationTypes[i] == this.NotificationTypes[j].ID
              ) {
                this.NotificationTypes[j]['checkedId'] = true;
              }
            }
          }
        } else {
          for (const status of this.NotificationTypes) {
            if (status.Title === 'NotifyAdmin') {
              this.NotificationTypesID.push(status.ID);
            }
            if (status.Title === 'NotifyDriver') {
              this.NotificationTypesID.push(status.ID);
            }
          }
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            for (var i = 0; i < this.NotificationTypes.length; i++) {
              if (
                this.NotificationTypesID[i] === this.NotificationTypes[j].ID
              ) {
                this.NotificationTypes[j]['checkedId'] = true;
              }
            }
          }
        }
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );

    this.customerServices.getPickupTypeList().subscribe(
      data => {
        this.driverService = data.Result;
        if(this.RoadiePostmatesFeature === true){
          this.driverService = this.driverService.filter(
            value => value.Title !== 'Roadie' && value.Title !== 'Postmates'
          );
        }
        if (this.selectedType === 'Facility') {
          // this.driverService = this.driverService.filter(
          //   value => value.Title !== 'SAAS'
          // );
          // this.driverSelected = this.driverService[0].ID;
          // this.activeState = true;
          // this.optiondriverStatus = true;
        } else {
          this.driverService = this.driverService.filter(
            value => value.Title === 'SAAS'
          );
          this.driverSelected = this.driverService[0].ID;
        }
        for (const status of this.driverService) {
          if (this.info.driverService === status.ID) {
            if (status.Title === 'SAAS') {
              this.fgRoleLogin = true;
            } else if (status.Title === 'Goodr') {
            } else {
              for (var j = 0; j < this.NotificationTypes.length; j++) {
                if (this.NotificationTypes[j].Title === 'NotifyDriver') {
                  this.NotificationTypes[j]['disabled'] = true;
                  this.NotificationTypes[j]['checkedId'] = false;
                }
              }
              if (this.PickupTypeS) {
                if (this.PickupTypeS.length > 0) {
                  for (var j = 0; j < this.PickupTypeS.length; j++) {
                    if (this.PickupTypeS[j].Title === 'Organic Recycling') {
                      this.PickupTypeS[j]['disabled'] = true;
                      this.PickupTypeS[j]['checkedId'] = false;
                    }
                  }
                }
              }
            }
          }
        }
        if (this.userRole === 'Facility Group') {
          this.driverService = this.driverService.filter(
            value =>
              value.Title !== 'Goodr' &&
              value.Title !== 'Roadie' &&
              value.Title !== 'Postmates'
          );
          this.activeState = true;
          this.driverSelected = this.driverService[0].ID;
          this.optiondriverStatus = true;
        } else {
          // this.driverService = this.driverService.filter(value => value.Title !== 'SAAS');
        }
        if (
          this.userRole === 'Facility' ||
          this.userRole === 'Facility Group'
        ) {
          this.AdminService.getUsersSASSByDriver().subscribe(data => {
            this.drivers = data;
          });
          this.selectedDriver = this.info.selectedDriver;
        } else {
          for (const status of this.driverService) {
            if (this.driverSelected === status.ID) {
              if (status.Title === 'SAAS') {
                this.optiondriverStatus = true;
                this.fgRoleLogin = true;
                this.saasselc = false;
                if (this.selectedType === 'Facility') {
                  if (this.saaslist) {
                    this.facilityGroupClients = this.saaslist.filter(
                      value =>
                        value.ApprovalStatus === 'Active' &&
                        value.DriverService === 'SAAS'
                    );
                  }
                } else {
                  if (this.nosaaslist) {
                    this.facilityGroupClients = this.nosaaslist.filter(
                      value => value.ApprovalStatus === 'Active'
                    );
                  }
                }
                this.selectedSGDriver =
                  'sAASDriver=true&pagingparametermodel.nonPaginatedUsers=true';
                this.AdminService.getUsersByDriver(
                  this.selectedSGDriver
                ).subscribe(data => {
                  this.drivers = data.Result;
                });
                this.selectedDriver = this.info.selectedDriver;
              } else if (status.Title === 'Goodr') {
                if (this.saaslist) {
                  this.facilityGroupClients = this.saaslist.filter(
                    value =>
                      value.ApprovalStatus === 'Active' &&
                      value.DriverService !== 'SAAS'
                  );
                }
                this.fgRoleLogin = false;
                this.optiondriverStatus = false;
              } else {
                if (this.saaslist) {
                  this.facilityGroupClients = this.saaslist.filter(
                    value =>
                      value.ApprovalStatus === 'Active' &&
                      value.DriverService !== 'SAAS'
                  );
                }
                this.fgRoleLogin = false;
                this.optiondriverStatus = false;
                if (this.NotificationTypes) {
                  for (var j = 0; j < this.NotificationTypes.length; j++) {
                    if (this.NotificationTypes[j].Title === 'NotifyDriver') {
                      this.NotificationTypes[j]['disabled'] = true;
                      this.NotificationTypes[j]['checkedId'] = false;
                    }
                  }
                }
              }
            }
          }
        }

        if (!this.info.driverService && !this.driverSelected) {
          this.driverSelected = this.driverService[0].ID;
        }
      },
      error => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  onCheckboxmaterial(event, value) {
    if (event.checked) {
      this.SeparatedCompostID.push(value);
    }
    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.SeparatedCompostID.indexOf(value);
      if (index > -1) {
        this.SeparatedCompostID.splice(index, 1);
      }
    }
  }
  onCheckboxworking(event, value) {
    // this.WorkingDaysIDChecked = value.Id;
    if (event.checked) {
      this.WorkingDaysID.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.WorkingDaysID.indexOf(value);
      if (index > -1) {
        this.WorkingDaysID.splice(index, 1);
      }
    }
    for (var i = 0; i < this.WorkingDaysID.length; i++) {
      // this.NotificationTypesID = this.info.NotificationTypes;
      if (this.WorkingDaysID[i]) {
        this.WorkingDaysID[i]['checkedId'] = true;
      }
    }
  }
  onCheckboxChagen(event, value) {
    if (event.checked) {
      this.pickupTypeID.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.pickupTypeID.indexOf(value);
      if (index > -1) {
        this.pickupTypeID.splice(index, 1);
      }
    }
    let count = 0;
    for (var j = 0; j < this.PickupTypeS.length; j++) {
      for (var i = 0; i < this.pickupTypeID.length; i++) {
        if (this.PickupTypeS[j].ID === this.pickupTypeID[i]) {
          if (this.PickupTypeS[j].Description === 'Food Pickup Type') {
            count++;
          }
        }
      }
    }
    if (count === 1) {
      this.menuedit = true;
    } else {
      this.menuedit = false;
      this.MenuEditFlag = 'true';
    }
  }

  onNotificationsChange(event, value) {
    if (event.checked) {
      this.NotificationTypesID.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.NotificationTypesID.indexOf(value);
      if (index > -1) {
        this.NotificationTypesID.splice(index, 1);
      }
    }
  }

  UpdateAccountType(value, event) {
    if (value === 'Facility') {
      this.facilityGroupClients = [];
      this.facilityData();
      this.facilityblock = true;
      this.saasFacilityG = false;
      this.facilitatorblock = false;
      this.driverblock = true;
      this.NPBylocation = false;
      this.MRFBylocation = false;
      if (!this.getClients === true) {
        // this.spinnerRef = this.spinnerService.start();
      }
    } else if (value === 'SAAS Facility') {
      this.facilityGroupClients = [];
      this.facilityData();
      this.saasFacilityG = false;
      this.facilityblock = true;
      this.facilitatorblock = false;
      this.driverblock = true;
      this.NPBylocation = false;
      this.MRFBylocation = false;
      this.radioOptions = 'facilityNone';
      this.facilatorGroup = false;
      this.facilityGroup = false;
    } else if (value === 'SAAS Facility Group') {
      this.saasFacilityG = true;
      this.facilitatorblock = false;
      this.facilityblock = false;
      this.driverblock = false;
      this.NPBylocation = false;
      this.MRFBylocation = false;
    } else if (value === 'MRF') {
      this.separetaedRequest();
      this.facilityblock = false;
      this.saasFacilityG = false;
      this.driverblock = true;
      this.facilitatorblock = false;
      this.NPBylocation = false;
      this.MRFBylocation = true;
    } else if (value === 'Non Profit') {
      this.facilityblock = false;
      this.saasFacilityG = false;
      this.driverblock = true;
      this.facilitatorblock = false;
      this.NPBylocation = true;
      this.MRFBylocation = false;
    } else if (this.selectedType === 'Facility Group') {
      this.customerServices.getPickupTypeList().subscribe(data => {
        this.driverService = data.Result;
        this.driverService = this.driverService.filter(
          value => value.Title === 'Goodr' || value.Title === 'SAAS'
        );
        this.driverSelected = this.driverService[0].ID;
      });
      this.facilitatorblock = true;
      this.facilityblock = false;
      this.saasFacilityG = false;
      this.driverblock = false;
      this.NPBylocation = false;
      this.MRFBylocation = false;
    } else {
      this.saasFacilityG = false;
      this.facilitatorblock = false;
      this.facilityblock = false;
      this.driverblock = false;
      this.MRFBylocation = false;
      this.NPBylocation = false;
    }
    this.selectedAccountType = value.Title;
    this.selectedfacilityType = value;
  }
  FacilityGroupClient(value, event) {}
  FacilitatorClient(value, event) {}
  DriverUpdate() {}
  isDriverService(event) {
    this.driverSelected = event.value;
    for (const status of this.driverService) {
      if (this.driverSelected === status.ID) {
        if (status.Title === 'SAAS') {
          this.facilityAccessFlag = 'false';
          if (this.nosaaslist && this.selectedType === 'SAAS Facility') {
            this.facilityGroupClients = this.nosaaslist.filter(
              value => value.ApprovalStatus === 'Active'
            );
          } else {
            if (this.driverService) {
              const detail = this.driverService;
              for (const status of detail) {
                if (status.ID === this.driverSelected) {
                  this.deliveryTitle = status.Title;
                }
              }
            }
            if (this.saaslist) {
              if (this.deliveryTitle === 'SAAS') {
                this.facilityGroupClients = this.saaslist.filter(
                  value =>
                    value.ApprovalStatus === 'Active' &&
                    value.DriverService === 'SAAS'
                );
              } else {
                this.facilityGroupClients = this.saaslist.filter(
                  value =>
                    value.ApprovalStatus === 'Active' &&
                    value.DriverService !== 'SAAS'
                );
              }
            }
          }
          // for (var j = 0; j < this.NotificationTypes.length; j++) {
          //   this.NotificationTypes[j]['disabled'] = false;
          // }
          this.NotificationTypesID = [];
          for (const status of this.NotificationTypes) {
            if (status.Title === 'NotifyAdmin') {
              this.NotificationTypesID.push(status.ID);
            }
            if (status.Title === 'NotifyDriver') {
              this.NotificationTypesID.push(status.ID);
            }
          }
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            for (var i = 0; i < this.NotificationTypesID.length; i++) {
              if (
                this.NotificationTypesID[i] === this.NotificationTypes[j].ID
              ) {
                this.NotificationTypes[j]['checkedId'] = true;
                this.NotificationTypes[j]['disabled'] = false;
              }
            }
          }
          this.pickupTypeID = [];
          for (const status of this.PickupTypeS) {
            if (status.Title === 'Food') {
              this.pickupTypeID.push(status.ID);
            }
          }
          for (var j = 0; j < this.PickupTypeS.length; j++) {
            for (var i = 0; i < this.pickupTypeID.length; i++) {
              this.PickupTypeS[j]['disabled'] = false;
              if (this.pickupTypeID[i] === this.PickupTypeS[j].ID) {
                this.PickupTypeS[j]['checkedId'] = true;
              }
            }
          }
          this.fgRoleLogin = true;
          this.saasselc = false;
          // this.radioOptions = 'facilitatorGrp';
          // this.facilityGroup = true;
          // this.facilatorGroup = false;
          this.radioOptions = 'facilityNone';
          this.facilityGroup = false;
          this.facilatorGroup = false;
          this.facilitatorClient = null;
          this.facilityGroupClient = null;
          this.optiondriverStatus = true;
          this.driverStatusSelected = true;
          this.selectedSGDriver =
            'sAASDriver=true&pagingparametermodel.nonPaginatedUsers=true';
          this.AdminService.getUsersByDriver(this.selectedSGDriver).subscribe(
            data => {
              this.drivers = data.Result;
            }
          );
        } else if (status.Title === 'Goodr') {
          this.radioOptions = 'facilityNone';
          this.facilityGroup = false;
          this.facilatorGroup = false;
          this.facilitatorClient = null;
          this.facilityGroupClient = null;
          this.facilityAccessFlag = 'true';
          this.saasselc = true;
          this.NotificationTypesID = [];
          for (const status of this.NotificationTypes) {
            if (status.Title === 'NotifyAdmin') {
              this.NotificationTypesID.push(status.ID);
            }
            if (status.Title === 'NotifyDriver') {
              this.NotificationTypesID.push(status.ID);
            }
          }
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            for (var i = 0; i < this.NotificationTypesID.length; i++) {
              this.PickupTypeS[j]['disabled'] = false;
              if (
                this.NotificationTypesID[i] === this.NotificationTypes[j].ID
              ) {
                this.NotificationTypes[j]['checkedId'] = true;
              }
            }
          }
          this.pickupTypeID = [];
          for (const status of this.PickupTypeS) {
            if (status.Title === 'Food') {
              this.pickupTypeID.push(status.ID);
            }
            // if (status.Title === 'NotifyDriver') {
            //   this.pickupTypeID.push(status.ID);
            // }
          }
          for (var j = 0; j < this.PickupTypeS.length; j++) {
            for (var i = 0; i < this.pickupTypeID.length; i++) {
              if (this.pickupTypeID[i] === this.PickupTypeS[j].ID) {
                this.PickupTypeS[j]['checkedId'] = true;
                this.PickupTypeS[j]['disabled'] = false;
              }
            }
          }
          if (this.driverService) {
            const detail = this.driverService;
            for (const status of detail) {
              if (status.ID === this.driverSelected) {
                this.deliveryTitle = status.Title;
              }
            }
          }
          if (this.saaslist) {
            if (this.saaslist === 'SAAS') {
              this.facilityGroupClients = this.saaslist.filter(
                value =>
                  value.ApprovalStatus === 'Active' &&
                  value.DriverService === 'SAAS'
              );
            } else {
              this.facilityGroupClients = this.saaslist.filter(
                value =>
                  value.ApprovalStatus === 'Active' &&
                  value.DriverService !== 'SAAS'
              );
            }
          }
          this.fgRoleLogin = false;
          this.optiondriverStatus = false;
          this.driverStatusSelected = true;
          this.selectedDriver = null;
        } else {
          this.facilityAccessFlag = 'true';
          this.saasselc = true;
          if (this.driverService) {
            const detail = this.driverService;
            for (const status of detail) {
              if (status.ID === this.driverSelected) {
                this.deliveryTitle = status.Title;
              }
            }
          }
          if (this.saaslist) {
            if (this.saaslist === 'SAAS') {
              this.facilityGroupClients = this.saaslist.filter(
                value =>
                  value.ApprovalStatus === 'Active' &&
                  value.DriverService === 'SAAS'
              );
            } else {
              this.facilityGroupClients = this.saaslist.filter(
                value =>
                  value.ApprovalStatus === 'Active' &&
                  value.DriverService !== 'SAAS'
              );
            }
          }
          this.fgRoleLogin = false;
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            if (this.NotificationTypes[j].Title === 'NotifyDriver') {
              this.NotificationTypes[j]['disabled'] = true;
              this.NotificationTypes[j]['checkedId'] = false;
            }
          }
          this.NotificationTypesID = [];
          for (const status of this.NotificationTypes) {
            if (status.Title === 'NotifyAdmin') {
              this.NotificationTypesID.push(status.ID);
            }
          }
          for (var j = 0; j < this.NotificationTypes.length; j++) {
            for (var i = 0; i < this.NotificationTypes.length; i++) {
              if (
                this.NotificationTypesID[i] === this.NotificationTypes[j].ID
              ) {
                this.NotificationTypes[j]['checkedId'] = true;
              }
            }
          }
          for (var k = 0; k < this.PickupTypeS.length; k++) {
            if (this.PickupTypeS[k].Title === 'Organic Recycling') {
              this.PickupTypeS[k]['disabled'] = true;
              this.PickupTypeS[k]['checkedId'] = false;
            }
          }
          this.pickupTypeID = [];
          for (const status of this.PickupTypeS) {
            if (status.Title === 'Food') {
              this.pickupTypeID.push(status.ID);
            }
          }
          for (var l = 0; l < this.PickupTypeS.length; l++) {
            for (var p = 0; p < this.PickupTypeS.length; p++) {
              if (this.pickupTypeID[p] === this.PickupTypeS[l].ID) {
                this.PickupTypeS[l]['checkedId'] = true;
              }
            }
          }
          // for (var j = 0; j < this.PickupTypeS.length; j++) {
          //   for (var i = 0; i < this.pickupTypeID.length; i++) {
          //     if (this.PickupTypeS[j].ID === this.pickupTypeID[i]) {
          //       if (
          //         this.PickupTypeS[j].Description ===
          //         'Organic Recycling Pickup Type'
          //       ) {
          //         this.validRoadiesTrue = true;
          //       } else {
          //         this.validRoadiesTrue = false;
          //       }
          //       // this.accountdetails();
          //     }
          //   }
          // }
          // if (this.validRoadiesTrue === true) {
          //   this.selectRoadiesValidation = true;
          //   this.pickupTypeID = [];
          //   for (const status of this.PickupTypeS) {
          //     if (status.Title === 'Food') {
          //       this.pickupTypeID.push(status.ID);
          //     }
          //   }
          //   for (var j = 0; j < this.PickupTypeS.length; j++) {
          //     for (var i = 0; i < this.pickupTypeID.length; i++) {
          //       if (this.pickupTypeID[i] == this.PickupTypeS[j].ID) {
          //         this.PickupTypeS[j]['checkedId'] = true;
          //       } else {
          //         this.PickupTypeS[j]['checkedId'] = false;
          //       }
          //     }
          //   }
          //   setTimeout(() => {
          //     this.selectRoadiesValidation = false;
          //   }, 3000);
          // }
          this.optiondriverStatus = false;
          this.driverStatusSelected = false;
          this.selectedDriver = null;
        }
      }
    }
    // if (event.value === 'facilitatorValue') {
    //   this.facilatorGroup = true;
    //   this.facilityGroup = false;
    // } else {
    //   this.facilityGroup = true;
    //   this.facilatorGroup = false;
    // }
  }
  onSelectionChangedriver(ID) {
    this.DriverServices = [{ ID: ID }];
  }
  isFacilityGroupFr(event) {
    if (event.value === 'facilitatorGrpFR') {
      this.facilatorGroupFR = true;
    } else {
      this.facilatorGroupFR = false;
      this.facilitatorClient = null;
      this.facilityGroupClientFR = null;
    }
  }
  isFacilityGroupSAAS(event) {
    if (event.value === 'SAASFG') {
      this.nosaasFG = false;
      // this.radioOptionsFR = 'facitatorNone';
      this.facilatorGroupFR = false;
      // this.radioOptionsFG = 'FGNone'
      // this.driverService = this.driverService.filter(value => value.Title !== 'Goodr' && value.Title !== 'Roadie');
      // this.driverSelected = this.driverService[0].ID;
    } else {
      this.nosaasFG = true;
      // this.radioOptionsFR = 'facitatorNone';
      // this.facilatorGroupFR = false;
      // this.radioOptionsFG = 'FGNone'
      // this.facilitatorClient = null;
      // this.facilityGroupClientFR = null;
    }
  }
  isFacilityGroup(event) {
    if (event.value === 'facilitatorValue') {
      this.facilatorGroup = true;
      this.facilityGroup = false;
    } else if (event.value === 'facilitatorGrp') {
      this.facilityGroup = true;
      this.facilatorGroup = false;
    } else {
      this.facilityGroup = false;
      this.facilatorGroup = false;
      this.facilitatorClient = null;
      this.facilityGroupClient = null;
    }
  }
  isFacilatorGroup(event) {
    if (event.value === 'facilitatorValue') {
      this.facilatorGroup = false;
    } else {
      this.facilatorGroup = true;
    }
  }

  getRoleId(clientCategory) {
    this.AdminService.getAccountType().subscribe(
      data => {
        this.allDeliveryStatus = data.Result;
        for (const status of this.allDeliveryStatus) {
          if (clientCategory === status.Name) {
            localStorage.setItem('roleId', status.Id);
          }
        }
      },
      error => {
        // alert(JSON.stringify(error));
      }
    );
  }
  selectTimePopup(value) {
    const dialogRef = this.dialog.open(TimeSelectDialogComponent, {
      width: '500px',
      // height: '400px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ value }]
      // disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.workingdays.map(val => {
          if (val.WorkingDay === result.WorkingDay) {
            val.StartTime = result.From;
            val.EndTime = result.To;
          }
        });
        this.WorkingDaysID.map(valData => {
          if (valData.WorkingDay === result.WorkingDay) {
            valData.StartTime = result.From;
            valData.EndTime = result.To;
          }
        });
      }
      // this.NonProfitWorkingSlots = this.WorkingDaysID;
      // let delNP = [];
      // delNP = this.NonProfitWorkingSlots.filter(obj => this.WorkingDaysID.every(s => s.WorkingDay !== obj.WorkingDay));
    });
  }

  editbusinessdetails() {
    this.getRoleId(this.selectedType);
    if (this.NonProfitWorkingSlots.length > 0) {
      this.NonProfitWorkingSlots.map(value => {
        value.StartTime = this.ConvertTime(value.StartTime);
        value.EndTime = this.ConvertTime(value.EndTime);
      });
    }
    const detail = this.allDeliveryStatus;
    for (const status of detail) {
      if (this.selectedType === status.Title) {
        //account-type-id
        this.accountId = status.ClientCategoryId;
      }
      if (status.Title === 'Facility') {
        this.isFaclility = status.ClientCategoryId;
      }
    }
    this.info.SeparatedCompostID = this.SeparatedCompostID;
    this.info.NonProfitWorkingSlots = this.NonProfitWorkingSlots;
    this.info.account_type = this.accountId;
    this.info.selectedAccountType = this.selectedAccountType;
    this.info.account_typeName = this.account_typeName;
    this.info.isMenuEdit = this.MenuEditFlag;
    this.info.facilityAccess = this.facilityAccessFlag;
    this.info.facility_type = this.selectedType;
    this.info.business_Detail = true;
    this.info.driverService = this.driverSelected;
    this.info.nosaasFG = this.nosaasFG;
    this.info.NofPeoples = this.NofPeoples;
    this.info.pickupTypes = this.pickupTypeID;
    this.info.PickupTypeSfg = this.pickupTypeIDFG;
    this.info.NotificationTypes = this.NotificationTypesID;
    this.info.selectedDriver = this.selectedDriver;
    if (this.selectedType === 'Facility') {
      this.info.FacilitatorId = this.facilitatorClient;
      this.info.FacilityGroupId = this.facilityGroupClient;
      this.info.isUnderFacilityGroup = this.facilityGroup;
      this.info.isUnderFacilatorGroup = this.facilatorGroup;
    } else if (this.selectedType === 'SAAS Facility') {
      this.info.FacilitatorId = null;
      this.info.FacilityGroupId = this.facilityGroupClient;
      this.info.isUnderFacilityGroup = this.facilityGroup;
      this.info.isUnderFacilatorGroup = this.facilatorGroup;
    } else if (this.selectedType === 'Facility Group') {
      this.info.FacilityGroupFRId = this.facilityGroupClientFR;
      this.info.isUnderFacilityGroupFR = this.facilatorGroupFR;
    } else {
      this.info.FacilitatorId = '';
      this.info.FacilityGroupId = '';
      this.info.isUnderFacilityGroup = '';
      this.info.FacilityGroupFRId = '';
      this.info.isUnderFacilityGroupFR = '';
      this.info.isUnderFacilatorGroup = '';
      this.info.driverService = '';
    }
    this.info.accountTypetrue = true;
    this.info.driverNotes = this.driverNotes;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    if (this.selectedType === 'Facility') {
      setTimeout(() => {
        if (
          this.facilitatorClient === undefined &&
          this.facilityGroupClient === undefined
        ) {
          this.validclient = true;
        } else {
        }
      });
    } else if (this.selectedType === 'Facility Group') {
      setTimeout(() => {
        if (this.facilityGroupClientFR === undefined) {
          this.validclient = true;
        } else {
        }
      });
    }
    const dialogRef = this.dialog.open(EditBusinessDialogComponent, {
      width: "1120px",
      height: "785px",
      panelClass: 'custom-Filterdialog-container',
      data: {}
      // disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
  onCheckboxChagenFG(event, value) {
    if (event.checked) {
      this.pickupTypeIDFG.push(value);
    }

    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.pickupTypeIDFG.indexOf(value);
      if (index > -1) {
        this.pickupTypeIDFG.splice(index, 1);
      }
    }
    // let count = 0;
    // for (var j = 0; j < this.PickupTypeS.length; j++) {
    //   for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
    //     if (this.pickupTypeIDFG[j] === 'Food') {
    //       count++;
    //     } else {
    //     }
    //   }
    // }
    // console.log(this.multipleFGadd)
    // for (var j = 0; j < this.multipleFGadd.length; j++) {
    //   console.log(this.multipleFGadd[j])
    //   // let pickuptype = [];
    //   const pickuptype = [];
    //   for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
    //     pickuptype.push({ Title: this.pickupTypeIDFG[i] });
    //   }
    //  this.multipleFGadd[j].PickupType = pickuptype;
    // }
    // if (count !== 0) {
    //   this.menueditbleFG = true;
    // } else {
    //   this.menueditbleFG = false;
    //   this.menuEditFG = true;
    // }
    // console.log(this.multipleFGadd)
  }
  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }
  selectAccountType() {
    if (
      this.selectedType === 'Facility' ||
      this.selectedType === 'SAAS Facility'
    ) {
      this.pickupTypeIDFG = [];
      this.info.PickupTypeSfg = [];
      if (this.radioOptions === 'facilitatorGrp') {
        if (this.facilityGroupClient) {
          this.facilitycreate();
        } else {
          this.selectClient = true;
          setTimeout(() => {
            this.selectClient = false;
          }, 3000);
          // alert('Please select any of the Food Type');
        }
      } else if (this.radioOptions === 'facilitatorValue') {
        if (this.facilitatorClient) {
          this.facilitycreate();
        } else {
          this.selectClient = true;
          setTimeout(() => {
            this.selectClient = false;
          }, 3000);
          // alert('Please select any of the Food Type');
        }
      } else {
        this.facilitycreate();
      }
    } else if (this.selectedType === 'MRF') {
      let valid_counts = 0;
      let checked_counts = 0;
      let i = 1;
      let j = 1;
      this.workingdays.map(value => {
        if (value['checkedId'] === true) {
          checked_counts = j++;
        }
      });
      this.NonProfitWorkingSlots = [];
      // if (
      //   this.NofPeoples &&
      //   this.NofPeoples !== undefined &&
      //   this.NofPeoples !== null
      // ) {
      // if (checked_counts > 0) {
      this.workingdays.map(value => {
        if (value['checkedId'] === true) {
          if (value.StartTime && value.EndTime) {
            this.NonProfitWorkingSlots.push(value);
          } else {
            value['showValid'] = true;
            valid_counts = i++;
            setTimeout(() => {
              value['showValid'] = false;
            }, 3000);
          }
        }
      });
      if (valid_counts === 0) {
        if (this.SeparatedCompostID[0]) {
          this.editbusinessdetails();
        } else {
          this.materiallistValid = true;
          setTimeout(() => {
            this.materiallistValid = false;
          }, 3000);
        }
      }
      // } else {
      //   this.NonProfitWorkingValid = true;
      //   setTimeout(() => {
      //     this.NonProfitWorkingValid = false;
      //   }, 3000);
      // }
      // } else {
      //   this.peoplesValid = true;
      //   setTimeout(() => {
      //     this.peoplesValid = false;
      //   }, 3000);
      // }
    } else if (this.selectedType === 'Non Profit') {
      let valid_counts = 0;
      let checked_counts = 0;
      let i = 1;
      let j = 1;
      this.workingdays.map(value => {
        if (value['checkedId'] === true) {
          checked_counts = j++;
        }
      });
      this.NonProfitWorkingSlots = [];

      if (
        this.NofPeoples &&
        this.NofPeoples !== undefined &&
        this.NofPeoples !== null &&
        this.NofPeoples !== 0
      ) {
        // if (checked_counts > 0) {
        this.workingdays.map(value => {
          if (value['checkedId'] === true) {
            if (value.StartTime && value.EndTime) {
              this.NonProfitWorkingSlots.push(value);
            } else {
              value['showValid'] = true;
              valid_counts = i++;
              setTimeout(() => {
                value['showValid'] = false;
              }, 3000);
            }
          }
        });
        if (valid_counts === 0) {
          this.editbusinessdetails();
        }
        // } else {
        //   this.NonProfitWorkingValid = true;
        //   setTimeout(() => {
        //     this.NonProfitWorkingValid = false;
        //   }, 3000);
        // }
      } else {
        this.peoplesValid = true;
        setTimeout(() => {
          this.peoplesValid = false;
        }, 3000);
      }
    } else if (this.selectedType === 'Facility Group') {
      if (this.radioOptionsFG === 'SAASFG') {
        this.accountdetails();
      } else {
        if (this.radioOptionsFR === 'facilitatorGrpFR') {
          if (this.facilityGroupClientFR) {
            this.accountdetails();
          } else {
            this.selectfacClient = true;
            setTimeout(() => {
              this.selectfacClient = false;
            }, 3000);
            // alert('Please select any of the Food Type');
          }
        } else {
          this.accountdetails();
        }
      }
      // if (this.radioOptionsFG === 'FGNone') {
      //   if (this.facilityGroupClientFR) {
      //     this.accountdetails();
      //   } else {
      //     this.selectfacClient = true;
      //     setTimeout(() => {
      //       this.selectfacClient = false;
      //     }, 3000);
      //     // alert('Please select any of the Food Type');
      //   }
      // } else {
      //   this.accountdetails();
      // }
    } else if (this.selectedType === 'SAAS Facility Group') {
      this.pickupTypeID = [];
      this.info.pickupTypes = [];
      if (this.pickupTypeIDFG.length > 0) {
        this.accountdetails();
      } else {
        this.selectPickupValidation = true;
        setTimeout(() => {
          this.selectPickupValidation = false;
        }, 3000);
      }
    } else {
      this.accountdetails();
    }
  }
  facilitycreate() {
    if (this.userRole === 'Facility Group') {
      this.facilityGroupClient = this.appstate.user['FacilityGroupId'];
    }
    // for (const status of this.driverService) {
    //   if (this.driverSelected === status.ID) {
    //     if (status.Title === 'SAAS') {
    //       this.fgRoleLogin = true;
    //     }
    //   }
    // }
    // } else
    this.Admincreate();
    // }
    // }
    // }
  }
  Admincreate() {
    if (this.NotificationTypesID[0]) {
      if (this.pickupTypeID[0]) {
        for (const status of this.driverService) {
          if (this.driverSelected === status.ID) {
            if (status.Title === 'Roadie') {
              for (var j = 0; j < this.PickupTypeS.length; j++) {
                for (var i = 0; i < this.pickupTypeID.length; i++) {
                  if (this.PickupTypeS[j].ID === this.pickupTypeID[i]) {
                    if (
                      this.PickupTypeS[j].Description ===
                      'Organic Recycling Pickup Type'
                    ) {
                      this.validRoadiesTrue = true;
                    } else {
                      this.validRoadiesTrue = false;
                    }
                  }
                }
              }
              if (this.validRoadiesTrue === true) {
                this.selectRoadiesValidation = true;
                this.pickupTypeID = [];
                for (const status of this.PickupTypeS) {
                  if (status.Title === 'Food') {
                    this.pickupTypeID.push(status.ID);
                  }
                }
                for (var j = 0; j < this.PickupTypeS.length; j++) {
                  for (var i = 0; i < this.pickupTypeID.length; i++) {
                    if (this.pickupTypeID[i] === this.PickupTypeS[j].ID) {
                      this.PickupTypeS[j]['checkedId'] = true;
                    } else {
                      this.PickupTypeS[j]['checkedId'] = false;
                    }
                  }
                }
                setTimeout(() => {
                  this.selectRoadiesValidation = false;
                  this.accountdetails();
                }, 3000);
              } else {
                this.accountdetails();
              }
            } else {
              this.accountdetails();
            }
          }
        }

        // this.accountdetails();
      } else {
        this.selectPickupValidation = true;
        setTimeout(() => {
          this.selectPickupValidation = false;
        }, 3000);
        // alert('Please select any of the Food Type');
      }
    } else {
      this.selectPickupNotfication = true;
      setTimeout(() => {
        this.selectPickupNotfication = false;
      }, 3000);
      // alert('Please select any of the Food Type');
    }
  }
  accountdetails() {
    if (this.selectedType !== undefined) {
      if (this.selectedType) {
        if (
          this.selectedType === 'Facility' ||
          this.selectedType === 'SAAS Facility'
        ) {
          for (const status of this.driverService) {
            if (this.driverSelected === status.ID) {
              if (status.Title === 'SAAS') {
                if (this.selectedDriver) {
                  this.editbusinessdetails();
                } else {
                  // alert('Please select driver');
                  this.selectedDriver = null;
                  this.driverEnabled = true;
                  setTimeout(() => {
                    this.driverEnabled = false;
                  }, 3000);
                }
              } else {
                this.editbusinessdetails();
              }
            }
          }
        } else {
          this.editbusinessdetails();
        }
      } else {
        this.invalidemail = true;
      }
    } else {
      this.invalidemail = true;
    }
  }
  fgDriverService(event) {
    this.driverSelected = event.value;
    const detaildriver = this.driverService;
    for (const status of detaildriver) {
      if (status.ID === this.driverSelected) {
        // this.driverSelected = status.ID;
        let fgdriver;
        fgdriver = status.Title;
        // this.facilitatorblock = true;
        if (fgdriver !== 'SAAS') {
          this.nosaasFG = true;
        } else {
          this.nosaasFG = false;
        }
        // if (fgdriver !== 'SAAS') {
        //   this.facilitatorblock = true;
        // } else {
        //   this.facilitatorblock = false;
        // }
      }
    }
  }
}
