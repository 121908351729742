import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { User, Client, Category } from '../models/user.model';
import { PickUps } from '../models/pickups.model';

@Injectable()
export class AppState {
    isLoggedIn: boolean;
    user: User;
    pickups: PickUps;
    pickupFilters: Array<string>;
    summaryFilters: Array<string>;
    selectedPickupFilter: string;
    selectedSummaryFilter: string;
    selectedCategory: string;
    selectedClient: string;
    isCategoryContext: boolean;
    categoryContext: Category;
    isDailyLogContext: boolean;
    categories: Category[];
    categoryClients: Client[];
    isOverviewMode: boolean;
    title: string;
    contextTitle: string;
    logContextTitle: string;
    isClientContext: boolean;
    clientContext: Client;
    isSpinnerVisible: boolean;
    spinnerVisible$: Observable<boolean>;

    responseFormatter: (key, value) => any;
    isLoggedfalse: boolean;
     client: Client;

    getClientName(clientId: number) {
        const clients = this.user.clients.filter(c => c.id === clientId);

        if (clients && clients.length > 0) {
            return clients[0].name;
        }

        return '';
    }

    getUserClients(clientId: number) {
        return this.user.clients.filter(c => c.id === clientId);
    }

    getCategoryClients(categories: Category[]) {
        if (categories && categories.length > 0) {
            return this.user.clients.filter(client =>
                categories.some(
                    c =>
                        c.name === client.category ||
                        (c.isClient && c.clientId === client.id)
                )
            );
        }

        return [...this.user.clients];
    }

    getCategoryByName(categoryName: string) {
        try {
            return this.getAllCategories().filter(c => c.name === categoryName)[0];
        } catch {
            return null;
        }
    }

    getAllCategories() {
        const categories: Category[] = this.user.categories
            ? [...this.user.categories]
            : [];
        return categories;
    }

    constructor() {
        this.pickupFilters = ['Last 7 Days', 'Last Month', 'This Year'];
        this.summaryFilters = ['This Month', 'This Year'];

        this.selectedPickupFilter = 'All';
        this.selectedSummaryFilter = 'All';

        this.selectedClient = 'All';

        this.isSpinnerVisible = false;
        this.spinnerVisible$ = new Observable<boolean>(observer => {
            let currentVisibility = this.isSpinnerVisible;

            setInterval(() => {
                if (currentVisibility !== this.isSpinnerVisible) {
                    observer.next(this.isSpinnerVisible);
                    currentVisibility = this.isSpinnerVisible;
                }
            }, 500);
        });

        this.responseFormatter = (key, value) => {
            if (key === 'pickupDate' || key === 'requestDate') {
                return new Date(value);
            }

            if (
                key === 'clientId' ||
                key === 'cost' ||
                key === 'quantity' ||
                key === 'id' ||
                key === 'weight' ||
                key === 'savings'
            ) {
                return Number(value);
            }

            return value;
        };
    }
}
