import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog-component.html',
  styleUrls: ['./delete-user-dialog-component.scss']
})
export class DeleteUserDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
