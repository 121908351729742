import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { RecoveryDialogComponent } from '../recovery-dialog/recovery-dialog.component';
import { ViewEncapsulation } from '@angular/core';
import { AccountService } from '../../shared/account.service';
@Component({
  selector: 'app-forgot-dialog',
  templateUrl: './forgot-dialog.component.html',
  styleUrls: ['./forgot-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotDialogComponent implements OnInit {
  modalclose: string;
  public forgotForm: FormGroup;
  selectedDialog: string;
  loginForm: any;
  state: any;
  msgdata: any;
  isLoggedfalse: boolean;
  userupdate: boolean;
  loginError: any;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<ForgotDialogComponent>,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
          Validators.required
        ])
      ]
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.forgotForm.controls[controlName].hasError(errorName);
  };
  openforgotDialog(formValues: { email: string }) {
    if (this.forgotForm) {
      const body = {
        Email: formValues.email
      };
      this.accountService.openforgotDialog(body).subscribe(data => {
        this.msgdata = data.message;
        if (this.msgdata === 'Email does not exist') {
          this.isLoggedfalse = true;
          setTimeout(() => {
            this.isLoggedfalse = false;
          }, 3000);
        } else {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          this.dialogRef.close(this.modalclose);
          const dialog = this.dialog.open(RecoveryDialogComponent, {
            width: '600px',
            height: '400px',
            disableClose: true,
            data: formValues.email
          });
          dialog.afterClosed().subscribe(selection => {
            if (selection) {
              this.selectedDialog = selection;
            } else {
              // User clicked 'Cancel' or clicked outside the dialog
            }
          });
        }
      });
    }
  }
}
