import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-pickups-request-dialog',
  templateUrl: './confirm-pickups-request-dialog.component.html',
  styleUrls: ['./confirm-pickups-request-dialog.component.scss'],
})
export class ConfirmPickupsRequestDialogComponent implements OnInit {
  data: any;
  pickuptype: any;
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ConfirmPickupsRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.pickuptype = this.modalData['pickuptype'];
  }
  pickupDetails() {
    this.router.navigate(['pickupDetails/' + this.modalData['DeliveryId'] + '']);
    this.dialogRef.close();
  }
  trackPickup() {
    this.router.navigate(['TrackRoutePickedUp/' + this.modalData['DeliveryId'] + '']);
    this.dialogRef.close();
  }
  OTPpickup() {
    this.router.navigate(['Pickups' + '']);
    this.dialogRef.close();
  }
  popupClose() {
    this.dialogRef.close();
  }
}
