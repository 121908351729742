import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'taxIdFilter'})

export class TaxnumberService implements PipeTransform {

  constructor() { }
  transform(value: string): string {
    if (value !== undefined) {
      if (value !== null) {
        const newVal = value.replace(/[^\w\s]/gi, '').toLocaleLowerCase();
        return this.titleCase(newVal);
      }
    }
  }
  titleCase(value) {
    const str = value.toString();
    return `${str.slice(0, 2)}-${str.slice(2, 10)}`;
  }
}
