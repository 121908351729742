import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'dateposttimezonePipe'
})
export class TimezonedatepostService implements PipeTransform {
  transform(date, timezone) {
    if (moment(date).isValid()) {
      let timezonedata;
      if (timezone) {
        timezonedata = timezone;
      } else {
        timezonedata = 'America/New_York';
      }
      const updateDate = moment(date)
        .tz(timezonedata)
        .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss')
        .toString();

      // console.log(date);
      // console.log(moment.tz('2020-12-14 01:00', 'America/New_York'));
      // if (moment(date).isValid()) {
      //   const updateDate = moment(date)
      //     .tz(timezone)
      //     // .tz('America/New_York')
      //     .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss')
      //     .toString();
      //   // moment.utc(date).clone().tz('America/New_York')
      //   // .format().split('T')[0] + 'T' + moment.utc(date).clone().tz('America/New_York')
      //   //   .format().split('T')[1].split('-')[0].split('+')[0];
      return updateDate;
    }
    return null;
  }
}
