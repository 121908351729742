import { EventEmitter, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.module';

@Injectable()
export class SpinnerService {
  constructor(private router: Router, private dialog: MatDialog) {}
  start(message?): MatDialogRef<ProgressSpinnerComponent> {
    const dialogRef = this.dialog.open(ProgressSpinnerComponent, {
      disableClose: true,
      // width: '50px',
      height: '40vh',
      panelClass: 'custom-dialog-container',
      data: message == '' || message == undefined ? 'Loading...' : message,
    });
    return dialogRef;
  }
  stop(ref: MatDialogRef<ProgressSpinnerComponent>) {
    ref.close();
  }
}
