import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material";
import { Router } from "@angular/router";

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['./reset-password-success.component.scss']
})
export class ResetPasswordSuccessComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<ResetPasswordSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
