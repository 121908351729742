import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'estpostFormatPipe'
})
export class EstpostformatService implements PipeTransform {
  transform(date: string) {
    if (moment(date).isValid()) {
      const updateDate =
        moment
          .utc(date)
          .clone()
          .tz('America/New_York')
          .format()
          .split('T')[0] +
        'T' +
        moment
          .utc(date)
          .clone()
          .tz('America/New_York')
          .format()
          .split('T')[1]
          .split('-')[0]
          .split('+')[0];
      return updateDate;
    }
    return null;
  }
}
