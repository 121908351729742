import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-promotion-code-pricing-edit',
  templateUrl: './promotion-code-pricing-edit.component.html',
  styleUrls: ['./promotion-code-pricing-edit.component.scss'],
})
export class PromotionCodePricingEditComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PromotionCodePricingEditComponent>
  ) {}

  ngOnInit() {}

  cancelSub() {
    this.dialogRef.close('res');
  }
  close() {
    this.dialogRef.close();
  }
}
