import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[TaxMask]'
})
export class TaxDirective {

    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        let trimmed = input.value.replace(/\s+/g, '');
        if (trimmed.length > 11) {
            trimmed = trimmed.substr(0, 11);
        }
        trimmed = trimmed.replace(/-/g, '');
        const numbers = [];
        numbers.push(trimmed.substr(0, 2));
        if (trimmed.substr(2, 10) !== "") {
            numbers.push(trimmed.substr(2, 10));
        }
        input.value = numbers.join('-');
    }
}
