import { Injectable } from '@angular/core';
import { HomeComponent } from '../dashboard/home/home.component';
import { UserComponent } from '../dashboard/user/user/user.component';
import { ProfileSetingsComponent } from '../dashboard/profileinfo/profile-setings/profile-setings.component';
import { CustomersComponent } from '../dashboard/customers/customers.component';
import { PickupsComponent } from '../dashboard/pickups/pickups.component';
import { ImpactComponent } from '../dashboard/impact/impact/impact.component';
@Injectable()
export class MenuService {
  constructor() {}
  getMenu(): Array<any> {
    const menu = [
      { name: 'home', component: HomeComponent, path: './home', children: [] },
      {
        name: 'dashboard',
        component: UserComponent,
        path: './dashboard',
        children: []
      },
      {
        name: 'usersAdminView',
        component: ProfileSetingsComponent,
        path: './usersAdminView',
        children: []
      },
      {
        name: 'Customers',
        component: CustomersComponent,
        path: './Customers',
        children: []
      },
      {
        name: 'Pickups',
        component: PickupsComponent,
        path: './Pickups',
        children: []
      },
      {
        name: 'Impact',
        component: ImpactComponent,
        path: './Impact',
        children: []
      }
    ];
    return menu;
  }
}
