import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AppState } from 'src/app/shared/app-state';
import { Router } from '@angular/router';
import { CustomerService } from '../../../shared/services/customer.service';
import { from } from 'rxjs';
@Component({
  selector: 'app-delete-facilitator-account',
  templateUrl: './delete-facilitator-account.component.html',
  styleUrls: ['./delete-facilitator-account.component.scss']
})
export class DeleteFacilitatorAccountComponent implements OnInit {

  constructor(private CustomerService: CustomerService,
    public dialogRef: MatDialogRef<DeleteFacilitatorAccountComponent>,
    private state: AppState, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
onNoClick(): void {
this.dialogRef.close();
}
ngOnInit() {
}
deleteFacilitatorAccount() {
  this.CustomerService.deleteFacilitatorAccount(this.data[0].ClientId).subscribe(
    data => {
      let localUser = JSON.parse(localStorage.getItem("user"));
      for(var i=0; i<localUser.user.User.Clients.length; i++){
        if(localUser.user.User.Clients[i].ClientId == this.data[0].ClientData.ClientId){
          localUser.user.User.Clients.splice(i, 1);
          break;
        }
      }
      localStorage.setItem("user", JSON.stringify(localUser));
      this.state.user = JSON.parse(localStorage.getItem("user")).user;
      this.router.navigate(['Customers/']);
      {
        this.dialogRef.close();
      }
    }
  );
}
}

