import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'stringReplacePipe'
})
export class specialPipe implements PipeTransform {
  transform(namedata): any {
    if (namedata !== undefined && namedata !== null && namedata) {
      return namedata.replace(/(^|\s)\S/g, s => s.toUpperCase());
    }
  }
  // transform(value: string): string {
  //   // let newVal = value.replace(/[^\w\s]/gi, '').toLocaleLowerCase();
  //   if (value) {
  //     let newVal = value.replace("<br />", "").toLocaleLowerCase();
  //     //let newVal = value.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase();
  //     return this.titleCase(newVal);
  //   }
  // }
  // titleCase(str) {
  //   var splitStr = str.toLowerCase().split(' ');
  //   for (var i = 0; i < splitStr.length; i++) {
  //     splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  //   }
  //   return splitStr.join(' ');
  // }
}
