import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppState } from '../app-state';
import { GlobalService } from '../app.service';
import { Observable } from 'rxjs';
import { Pricingcontrol } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class PricingPromotionCodeService {
  constructor(
    private http: HttpClient,
    public state: AppState,
    private globalService: GlobalService
  ) {}

  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(
        localStorage.getItem('user')
      ).isLoggedIn;
      this.state.user = JSON.parse(localStorage.getItem('user')).user;
      return this.state.user.User.Id;
    } else {
      this.state.isLoggedIn = false;
      this.state.user = null;
      return '';
    }
  }

  token = this.getUserToken();

  getPromotionCode(
    pageNumber: any,
    PageSize: any,
    sortColumn: any
  ): Observable<Pricingcontrol> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    let filterString =
      '?pagingparametermodel.pageNumber=' +
      pageNumber +
      '&pagingparametermodel.PageSize=' +
      PageSize;
    if (sortColumn) {
      filterString += '&pagingparametermodel.SortColumn=' + sortColumn;
    }
    return this.http
      .get<Pricingcontrol>(
        this.globalService.PromotionCode + filterString,
        httpOptions
      )
      .pipe();
  }
  getPromotionCodeBypromoId(id) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get<Pricingcontrol>(
        this.globalService.PromotionCode + '/' + id,
        httpOptions
      )
      .pipe();
  }

  getPromotionCodeById(id) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get<Pricingcontrol>(
        this.globalService.PromotionCode + '?Id=' + id,
        httpOptions
      )
      .pipe();
  }

  editOTPData(OTPDetails): Observable<Pricingcontrol> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .put<Pricingcontrol>(
        this.globalService.PickupPrice,
        OTPDetails,
        httpOptions
      )
      .pipe();
  }
  getbyIdOTP(id): Observable<Pricingcontrol> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get<Pricingcontrol>(
        this.globalService.PickupPrice + '?Id=' + id,
        httpOptions
      )
      .pipe();
  }
  getOTPCode(
    pageNumber: any,
    PageSize: any,
    sortColumn: any
  ): Observable<Pricingcontrol> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    let filterString =
      '?pagingparametermodel.pageNumber=' +
      pageNumber +
      '&pagingparametermodel.PageSize=' +
      PageSize;
    if (sortColumn) {
      filterString += '&pagingparametermodel.SortColumn=' + sortColumn;
    }
    return this.http
      .get<Pricingcontrol>(
        this.globalService.PickupPrice + filterString,
        httpOptions
      )
      .pipe();
  }
  createCouponCode(couponDetails) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .post(this.globalService.PromotionCode, couponDetails, httpOptions)
      .pipe();
  }
  createOTP(OTPDetails): Observable<Pricingcontrol> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .post<Pricingcontrol>(
        this.globalService.PickupPrice,
        OTPDetails,
        httpOptions
      )
      .pipe();
  }
  validCoupen(coupen): Observable<Pricingcontrol> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .post<Pricingcontrol>(
        this.globalService.ValidateCoupon,
        coupen,
        httpOptions
      )
      .pipe();
  }

  deleteCoupon(couponId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .delete(this.globalService.PromotionCode + '?Id=' + couponId, httpOptions)
      .pipe();
  }
  deleteOTP(Id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .delete(this.globalService.PickupPrice + '?Id=' + Id, httpOptions)
      .pipe();
  }
}
