import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
declare var Stripe: any;

import {
  MatPaginator,
  MatSort,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from '@angular/material';
import { PaysuccessfulComponent } from '../paysuccessful/paysuccessful.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { AccountService } from 'src/app/shared/account.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
@Component({
  selector: 'app-editcheckout',
  templateUrl: './editcheckout.component.html',
  styleUrls: ['./editcheckout.component.scss']
})
export class EditcheckoutComponent implements OnInit {
  modalclose: string;
  email: string;
  firstname: string;
  multipleFGadd = [];
  multipleFGaddFinal: any[];
  NotificationpickupTypes = [];
  lastname: string;
  cardNumber: string;
  cvc: string;
  stripe: any;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;

  message: string;
  yearlyPay: any;
  pickuparry = [];
  UserId: any;
  ClientId: any;
  clientId: any;

  faclityCount: any;
  info: any;
  updtateclient: boolean;
  updateinfo: any;
  PickupTypeS: any;
  monthlyPrice: any;
  monthlyID: any;
  yearlyPrice: any;
  yearlyID: any;
  totalfinalpaid: any;
  constructor(
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public _rdata: modalDataService,
    private router: Router,
    private sassService: SAASService,
    private spinnerService: SpinnerService,
    private accountService: AccountService,
    public customerService: CustomerService,
    private pricingModule: PricingModuleServiceService,
    public dialogRef: MatDialogRef<EditcheckoutComponent>
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    let pickupTypefinal;

    this.yearlyPay = JSON.parse(localStorage.getItem('accountType'));
    this._rdata.basicupdateInfo2Observe.subscribe(res => {
      this.updateinfo = JSON.parse(res);
      if (this.updateinfo.SAASupdate.PickupType.length > 0) {
        if (this.updateinfo.SAASupdate.PickupType.length === 2) {
          pickupTypefinal = 'combined';
        } else if (this.updateinfo.SAASupdate.PickupType[0].ID === 2) {
          pickupTypefinal = 'Organic';
        } else {
          pickupTypefinal = 'Food';
        }
      } else {
        pickupTypefinal = 'combined';
      }
      if (this.updateinfo.updateclient === true) {
        this.faclityCount = JSON.parse(localStorage.getItem('NOofFacility'));
        this.updtateclient = true;
        this.totalfinalpaid = this.updateinfo.totalfinalpaid;
      } else {
        this.updtateclient = false;
        this._rdata.basicInfo2Observe.subscribe(res => {
          this.info = JSON.parse(res);
          // console.log(this.info);
          if (
            this.info.pickupTypes !== '' &&
            this.info.pickupTypes !== null &&
            this.info.pickupTypes !== undefined
          ) {
            if (this.info.pickupTypes.length > 0) {
              if (this.info.pickupTypes.length === 2) {
                pickupTypefinal = 'combined';
              } else if (this.info.pickupTypes[0] === 2) {
                pickupTypefinal = 'Organic';
              } else {
                pickupTypefinal = 'Food';
              }
            }
          }
          if (
            this.info.PickupTypeSfg !== '' &&
            this.info.PickupTypeSfg !== null &&
            this.info.PickupTypeSfg !== undefined
          ) {
            if (this.info.PickupTypeSfg.length > 0) {
              if (this.info.PickupTypeSfg.length === 2) {
                pickupTypefinal = 'combined';
              } else if (this.info.PickupTypeSfg[0] === 'Organic Recycling') {
                pickupTypefinal = 'Organic';
              } else {
                pickupTypefinal = 'Food';
              }
            }
          }
          this.totalfinalpaid = this.info.totalfinalpaid;
          if (this.info.facility_type === 'SAAS Facility') {
            this.faclityCount = 1;
          } else {
            if (JSON.parse(localStorage.getItem('multiplefacilities'))) {
              this.multipleFGadd = JSON.parse(
                localStorage.getItem('multiplefacilities')
              );
            }
            this.faclityCount = this.multipleFGadd.length;
          }
        });
        // this.UserId = this.info.userId;
        // this.ClientId = this.info.clientId;
        // this.sassService.getSubDetails(this.ClientId).subscribe(
        //   data => {
        //     if (data.Result[0].ClientCategory[0].Title === 'Facility Group') {
        //       this.faclityCount = JSON.parse(localStorage.getItem('NOofFacility'));
        //     } else {
        //       this.faclityCount = 1;
        //     }
        //   }
        // );
      }
    });
    if (this.info) {
      this.totalfinalpaid = this.info.totalfinalpaid;
      if (this.info.facility_type === 'SAAS Facility') {
        this.customerService.CompostPickupTypeList().subscribe(data => {
          this.PickupTypeS = data.Result;
          this.PickupTypeS = this.PickupTypeS.filter(
            value => value.Title !== 'One Time Pickup'
          );
          // if (this.info.pickupTypes) {
          for (var j = 0; j < this.PickupTypeS.length; j++) {
            for (var i = 0; i < this.info.pickupTypes.length; i++) {
              if (this.info.pickupTypes[i] == this.PickupTypeS[j].ID) {
                this.pickuparry.push({
                  Title: this.PickupTypeS[j].Title
                });
              }
            }
          }
          // }
        });
      }
    }
    this.getPricingRated(pickupTypefinal);
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();

    this.cardNumberElement = elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number-element');
    this.cardNumberElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardExpiryElement = elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry-element');
    this.cardExpiryElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardCvcElement = elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc-element');
    this.cardCvcElement.on('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  getPricingRated(pickuptype) {
    this.pricingModule.getPrcingSAAS().subscribe(data => {
      // console.log(data['Result']);
      let priceData;
      priceData = data['Result'];
      for (const status of priceData) {
        if (status.SubscriptionType === 'month') {
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.monthlyPrice = status.Price;
              if (this.yearlyPay == 'montly') {
                this.monthlyID = status.StripePlanId;
              }
              // console.log(this.monthlyPrice);
              // console.log(this.monthlyID);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.monthlyPrice = status.Price;
              if (this.yearlyPay == 'montly') {
                this.monthlyID = status.StripePlanId;
              }
              // console.log(this.monthlyPrice);
              // console.log(this.monthlyID);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.monthlyPrice = status.Price;
            if (this.yearlyPay == 'montly') {
              this.monthlyID = status.StripePlanId;
            }
            // console.log(this.monthlyPrice);
            // console.log(this.monthlyID);
          }
          // console.log(this.yearlyPrice)
        } else {
          // console.log(pickuptype)
          // console.log(status.PickupType)
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            // console.log(status.PickupType)
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.yearlyPrice = status.Price;
              if (this.yearlyPay == 'yearly') {
                this.yearlyID = status.StripePlanId;
              }
              // console.log(this.yearlyPrice);
              // console.log(this.yearlyID);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.yearlyPrice = status.Price;
              if (this.yearlyPay == 'yearly') {
                this.yearlyID = status.StripePlanId;
              }
              // console.log(this.yearlyPrice);
              // console.log(this.yearlyID);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.yearlyPrice = status.Price;
            if (this.yearlyPay == 'yearly') {
              this.yearlyID = status.StripePlanId;
            }
            // console.log(this.yearlyPrice);
            // console.log(this.yearlyID);
          }
          // console.log(this.monthlyPrice)
        }
      }
    });
  }
  goToSucessPage() {
    this.router.navigate(['paymentSuccess']);
  }
  goToBillingPage() {
    this.router.navigate(['billing/' + this.ClientId + '']);
  }
  createUser() {
    this.stripe.createToken(this.cardNumberElement).then(result => {
      if (result.token.id) {
        let planid;
        if (this.yearlyPay === 'montly') {
          planid = this.monthlyID;
        } else {
          planid = this.yearlyID;
        }
        let payload;
        // const payload = {
        //   Token: result.token.id,
        //   PlanId: planid,
        //   ClientId: this.ClientId,
        //   UserId: this.UserId
        // };
        if (this.info.facility_type === 'SAAS Facility') {
          var NotificationpickupTypes = [];
          for (var i = 0; i < this.info.NotificationTypes.length; i++) {
            NotificationpickupTypes.push({
              ID: this.info.NotificationTypes[i]
            });
          }
          this.NotificationpickupTypes = NotificationpickupTypes;
          const body = [
            {
              CompanyTitle: this.info.accountName,
              FirstName: this.info.adminFirstName,
              LastName: this.info.adminLastName,
              Account: this.info.facility_type,
              Email: this.info.adminEmail,
              PhoneNumber: this.info.Phone.replace(/[_-]/g, ''),
              TaxID: this.info.taxId,
              Country: this.info.country,
              Address1: this.info.businessAddress,
              Address2: this.info.address,
              // State: this.info.state,
              //  City: this.info.city,
              StateId: this.info.state,
              CityId: this.info.city,
              ZipCode: this.info.zip,
              PickupType: this.pickuparry,
              MenuEdit: this.info.isMenuEdit,
              SAASDriverId: this.info.selectedDriver
            }
          ];
          const body1 = {
            PromoCodeName: this.info.coupendata,
            Token: result.token.id,
            PlanId: planid,
            SAASClient: body
          };
          payload = body1;
        } else {
          if (JSON.parse(localStorage.getItem('multiplefacilities'))) {
            this.multipleFGadd = JSON.parse(
              localStorage.getItem('multiplefacilities')
            );
          }
          let len = this.multipleFGadd.length;
          for (let i = 0; i < len; i++) {
            delete this.multipleFGadd[i].Id;
          }
          const body = {
            CompanyTitle: this.info.accountName,
            FirstName: this.info.adminFirstName,
            LastName: this.info.adminLastName,
            Account: this.info.facility_type,
            Email: this.info.adminEmail,
            PhoneNumber: this.info.Phone.replace(/[_-]/g, ''),
            TaxID: this.info.taxId,
            Country: this.info.country,
            Address1: this.info.businessAddress,
            Address2: this.info.address,
            // State: this.info.state,
            //  City: this.info.city,
            StateId: this.info.state,
            CityId: this.info.city,
            ZipCode: this.info.zip
          };
          this.multipleFGaddFinal = [body, ...this.multipleFGadd];
          const body1 = {
            PromoCodeName: this.info.coupendata,
            Token: result.token.id,
            PlanId: planid,
            SAASClient: this.multipleFGaddFinal
          };
          payload = body1;
        }
        const spinnerRef = this.spinnerService.start();
        this.sassService.reigsteradmin(payload).subscribe(
          datasaas => {
            if (datasaas.message === undefined) {
              this.info.clientId = datasaas['ClientId'];
              if (this.info.facility_type === 'SAAS Facility') {
                if (this.info.menutype !== 'Custom') {
                  this.customerService.getMenuTypes().subscribe(data => {
                    const menus = data.Result;
                    let menuId;
                    for (const menu of menus) {
                      if (menu.Title === this.info.menutype) {
                        menuId = menu.ID;
                      }
                    }
                    this.customerService
                      .getCustomerDetail(datasaas['ClientId'])
                      .subscribe(dataget => {
                        let payloadassign = Object.assign(
                          {},
                          dataget.Result[0]
                        );
                        payloadassign.MenuTypeId = menuId;
                        payloadassign.WayPointID = this.info.WayPointID;
                        (payloadassign.NotificationTypes = this.NotificationpickupTypes),
                          this.customerService
                            .updateCustomerDetails(payloadassign)
                            .subscribe(data => {});
                      });
                  });
                }
              }
              this._rdata.updateBasicInfo2(JSON.stringify(this.info));
              localStorage.setItem('updateheckout', JSON.stringify(false));
              localStorage.removeItem('multiplefacilities');
              this.gotoSuccess();
            } else {
              alert(datasaas.message);
            }
            this.spinnerService.stop(spinnerRef);
          },
          error => {
            alert(JSON.stringify(error));
            this.spinnerService.stop(spinnerRef);
            // this.buttonDisabled = false;
          }
        );
      }
    });
  }
  updateUser() {
    this.stripe.createToken(this.cardNumberElement).then(result => {
      if (result.token.id) {
        let planid;
        if (this.yearlyPay === 'montly') {
          planid = this.monthlyID;
        } else {
          planid = this.yearlyID;
        }
        const payload = {
          PromoCodeName: this.updateinfo.coupendata,
          Token: result.token.id,
          PlanId: planid,
          NormalToSaas: true,
          ClientId: this.updateinfo.SAASupdate.ClientId
        };
        const spinnerRef = this.spinnerService.start();
        this.accountService.saasUpdate(payload).subscribe(
          data => {
            this.customerUpdate(data.Result);
            this.spinnerService.stop(spinnerRef);
          },
          error => {
            alert(JSON.stringify(error));
            this.spinnerService.stop(spinnerRef);
            // this.buttonDisabled = false;
          }
        );
      }
    });
  }
  customerUpdate(payload) {
    let finalpayload = {
      SeparatedCompost :this.updateinfo.SAASupdate.SeparatedCompost,
      HoldHarmlessAgreement :this.updateinfo.SAASupdate.HoldHarmlessAgreement,
      TimeZone :this.updateinfo.SAASupdate.TimeZone,
      ClientId: this.updateinfo.SAASupdate.ClientId,
      ClientCategories: payload[0].ClientCategory,
      Name: this.updateinfo.SAASupdate.Name,
      PickupType: this.updateinfo.SAASupdate.PickupType,
      NotificationTypes: this.updateinfo.SAASupdate.NotificationTypes,
      Phone: this.updateinfo.SAASupdate.Phone,
      Address: this.updateinfo.SAASupdate.Address,
      Address2: this.updateinfo.SAASupdate.Address2,
      City: this.updateinfo.SAASupdate.City,
      State: this.updateinfo.SAASupdate.State,
      CityId: this.updateinfo.SAASupdate.CityId,
      StateId: this.updateinfo.SAASupdate.StateId,
      ZIP: this.updateinfo.SAASupdate.ZIP,
      Country: this.updateinfo.SAASupdate.Country,
      Email: this.updateinfo.SAASupdate.Email,
      Web: this.updateinfo.SAASupdate.Web,
      EINNumber: this.updateinfo.SAASupdate.EINNumber,
      DriverNotes: this.updateinfo.SAASupdate.DriverNotes,
      ContactId: this.updateinfo.SAASupdate.ContactId,
      HasFridgeStorage: this.updateinfo.SAASupdate.HasFridgeStorage,
      NumberOfPeopleServed: this.updateinfo.SAASupdate.NumberOfPeopleServed,
      WasteExpenditure: this.updateinfo.SAASupdate.WasteExpenditure,
      ClientTypeId: this.updateinfo.SAASupdate.ClientTypeId,
      ApprovalStatusId: this.updateinfo.SAASupdate.ApprovalStatusId,
      // WayPointID: 0,
      WayPointID: this.updateinfo.SAASupdate.WayPointID,
      StripeCustomerId: payload[0].StripeCustomerId,
      StripePlanId: payload[0].StripePlanId,
      StripeSubscriptionId: payload[0].StripeSubscriptionId,
      WP_Client_Id: 0,
      MenuEdit: this.updateinfo.SAASupdate.MenuEdit,
      InviteUser: this.updateinfo.SAASupdate.InviteUser,
      Logo: this.updateinfo.SAASupdate.Logo,
      ImageUrl: this.updateinfo.SAASupdate.ImageUrl,
      FacilitatorId: payload[0].FacilitatorId,
      FacilityGroupId: this.updateinfo.SAASupdate.FacilityGroupId,
      MenuTypeId: this.updateinfo.SAASupdate.MenuTypeId,
      CreatedOn: this.updateinfo.SAASupdate.CreatedOn,
      CreatedBy: this.updateinfo.SAASupdate.CreatedBy,
      DriverServices: payload[0].DriverServices,
      User_Client: this.updateinfo.SAASupdate.User_Client,
      SAASDriverId: this.updateinfo.SAASupdate.SAASDriverId
    };
    this.customerService.updateCustomerDetails(finalpayload).subscribe(
      data => {
        const res = 'res';
        this.dialogRef.close(res);
        localStorage.setItem('updateheckout', JSON.stringify(false));
        this.gotoSuccess();
      },
      error => {
        this.dialogRef.close();
      }
    );
  }
  gotoSuccess() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);

    const dialogRef = this.dialog.open(PaysuccessfulComponent, {
      width: '555px',
      height: '460px',
      panelClass: 'custom-editdialog-container',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(selection => {});
  }
}
