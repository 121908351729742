import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timeZone'
})
export class TimezoneService {
  transform(date) {
    // if (moment(date).isValid()) {
    // console.log(moment.tz.zone('America/Chicago').abbr(360));
    var timeZone = moment.tz.guess();
    // var time = new Date();
    var timeZoneOffset = date.getTimezoneOffset();
    // console.log(timeZoneOffset);
    // console.log(new Date().getTime());
    // console.log(timeZone);
    const updateDate = moment.tz.zone(timeZone).abbr(date.getTime());
    // console.log(moment.tz.zone(timeZone).abbr(timeZoneOffset));
    return updateDate;
    // }
    // return null;
  }
}
