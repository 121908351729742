import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'app-show-gif-dialog',
  templateUrl: './show-gif-dialog.component.html',
  styleUrls: ['./show-gif-dialog.component.scss'],
})
export class ShowGifDialogComponent implements OnInit {
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: MatDialog, public dialogRef: MatDialogRef<ShowGifDialogComponent>) {}

  imgSrc;
  imagePath = '../../../../../assets/GIF/';
  gifSource;

  ngOnInit() {
    console.log(this.data);
    this.imgSrc = this.data[0]['imgSrc'];
    console.log(this.data[0]['imgSrc']);
  }
}
