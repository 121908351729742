import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ForgotDialogComponent } from '../forgot-dialog/forgot-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PasswordStrengthValidator } from '../PasswordStrengthValidators';
import { AccountService } from 'src/app/shared/account.service';
import { AppState } from 'src/app/shared/app-state';
import { PasswordComponent } from './password-dialog/password-dialog.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { SpinnerService } from 'src/app/core/spinner.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
    return invalidCtrl || invalidParent;
  }
}
@Component({
  selector: 'app-background-page',
  templateUrl: './background-page.component.html',
  styleUrls: ['./background-page.component.scss'],
})
export class BackgroundPageComponent implements OnInit {
  public ownerForm: FormGroup;
  invalidemail = false;
  selectedEmoji: string;
  hide = true;
  loginError = '';
  loginForm: any;
  showValidators: boolean;
  passwordForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  modalclose: string;
  selectedDialog: string;
  setpassword = true;
  disabled = true;
  token: any;
  forgotId: any;
  userEmail: any;
  resetCode: any;
  resetpassword: any;
  responseToken: any;
  responseId: any;
  passwordError: string;
  dataValidators: boolean;
  confirmerror = false;
  showPasswordResetDiv: boolean;
  showSuccessfullMessageDiv: boolean;

  constructor(
    private accountService: AccountService,
    private state: AppState,
    private location: Location,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private AdminService: AdmintoolsService,
    private spinnerService: SpinnerService
  ) {
    this.passwordForm = this.formBuilder.group(
      {
        password: [null, Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
        confirmPassword: new FormControl({ value: '', disabled: this.disabled }),
      }
      // { validator: this.checkPasswords }
    );
  }
  email: string;
  password: string;
  // openDialog() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;
  //   const dialog = this.dialog.open(ForgotDialogComponent, {
  //     width: "615.8px",
  //     data: "This text is passed into the dialog!"
  //   });
  //   dialog.afterClosed().subscribe(selection => {
  //     if (selection) {
  //       this.selectedEmoji = selection;
  //     } else {
  //       // User clicked 'Cancel' or clicked outside the dialog
  //     }
  //   });
  // }
  ngOnInit() {
    this.route.snapshot.params['id'];
    let stringToSplit = this.route.snapshot.params['id'];
    let userDataValue = stringToSplit.split('+');
    this.showPasswordResetDiv = true;
    this.resetCode = userDataValue[1];

    var spinnerRef = this.spinnerService.start();
    this.AdminService.getclientDetails(userDataValue[0]).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.userEmail = data.Result[0].Email;
      if (this.userEmail) {
        let payload = {
          Email: this.userEmail,
          ResetCode: this.resetCode,
        };
        this.AdminService.forgotPasswordResetCode(payload).subscribe(
          (response) => {
            this.responseToken = response['Token'];
            this.responseId = response['Id'];
            if (response['message'] === 'In-valid Reset code') {
              this.passwordError = 'Your password reset link has expired. Please contact support@goodr.co for assistance.';
              this.dataValidators = true;
            }
          },
          (error) => {
            // alert(JSON.stringify(error));
          }
        );
      }
    });
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = true;
    // const dialog = this.dialog.open(PasswordComponent, {
    //   width: "615.8px",
    //   height: "420PX",
    //   disableClose: true,
    //   data: {
    //     userId: x[0],
    //     token: x[1]
    //   }
    // });
    // dialog.afterClosed().subscribe(selection => {
    //   if (selection) {
    //     this.selectedEmoji = selection;
    //   } else {
    //     // User clicked 'Cancel' or clicked outside the dialog
    //   }
    // });

    // this.ownerForm = this.formBuilder.group({
    //   email: [
    //     "",
    //     Validators.compose([
    //       Validators.required,
    //       Validators.minLength(8),
    //       Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
    //       Validators.required
    //     ])
    //   ],
    //   password: [
    //     null,
    //     Validators.compose([
    //       Validators.required,
    //       Validators.minLength(8),
    //       PasswordStrengthValidator
    //     ])
    //   ]
    // });
  }
  // public hasError = (controlName: string, errorName: string) => {
  //   return this.ownerForm.controls[controlName].hasError(errorName);
  // };
  // public onCancel = () => {
  //   this.location.back();
  // };
  // login(formValues: { email: string; password: string; RememberMe: boolean }) {
  //   if (this.ownerForm.invalid) {
  //     this.invalidemail = true;
  //   }
  //   if (this.ownerForm.valid) {
  //     this.showValidators = false;
  //     this.state.isSpinnerVisible = true;
  //     this.accountService
  //       .login(formValues.email, formValues.password)
  //       .subscribe(
  //         data => {
  //           this.state.isSpinnerVisible = false;
  //           if (data && (!data.error || data.error < 1)) {
  //             this.state.isLoggedIn = true;
  //             this.state.user = data;
  //             // this.invalidemail=true;
  //             // this.normalizeUserData();

  //             this.state.user = data;

  //             localStorage.setItem("user", JSON.stringify(this.state));

  //             // if (formValues.RememberMe) {
  //             //   localStorage.setItem('state', JSON.stringify(this.state));
  //             // }

  //             this.router.navigate(["dashboard"]);
  //           } else {
  //             this.loginError = data.message;
  //           }
  //         },
  //         error => {
  //           this.state.isSpinnerVisible = false;
  //           alert(JSON.stringify(error));
  //         }
  //       );
  //   } else {
  //     this.showValidators = true;
  //   }
  // }
  // private normalizeUserData() {
  //   this.state.user.clients.forEach(c => {
  //     c.id = Number(c.id);
  //   });
  //   if (this.state.user.error) {
  //     this.state.user.error = Number(this.state.user.error);
  //   }
  //   if (this.state.user.profile) {
  //     this.state.user.profile.id = Number(this.state.user.profile.id);
  //   }
  // }

  keyPresspassword() {
    if (this.passwordForm.valid === true) {
      // this.disabled = false;
      this.passwordForm.controls['confirmPassword'].enable();
    } else {
      this.passwordForm.controls['confirmPassword'].disable();
      // this.disabled = true;
    }
    if (this.passwordForm.valid === true && this.setpassword === true) {
      if (this.passwordForm.controls['confirmPassword'].value === this.passwordForm.controls['password'].value) {
        this.setpassword = false;
      }
    } else {
      this.setpassword = true;
    }
    if (this.passwordForm.controls['confirmPassword'].value) {
      if (this.passwordForm.controls['confirmPassword'].value === this.passwordForm.controls['password'].value) {
        this.confirmerror = false;
      } else {
        this.confirmerror = true;
      }
    }
  }
  keyPressconpassword() {
    this.confirmerror = true;
    if (this.passwordForm.valid === true && this.setpassword === true) {
      if (this.passwordForm.controls['confirmPassword'].value === this.passwordForm.controls['password'].value) {
        this.setpassword = false;
        this.confirmerror = false;
      }
    } else {
      this.setpassword = true;
      this.confirmerror = true;
    }
  }
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  resetUserPassword(userPassword) {
    let payload = {
      Token: this.responseToken,
      Id: this.responseId,
      Password: userPassword,
      IsInvited: true,
    };
    var spinnerRef = this.spinnerService.start();
    this.AdminService.forgotPasswordPost(payload).subscribe((response) => {
      this.spinnerService.stop(spinnerRef);
      if (response['message'] != 'Your password has been updated successfully') {
        document.getElementById('errormessage').innerHTML = response['message'];
      } else {
        this.showSuccessfullMessageDiv = true;
        this.showPasswordResetDiv = false;
      }
    });
  }

  navigateToLogin() {
    this.router.navigate(['login']);
  }
}
