import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../app.service';
import { User } from '../../models/user.model';
import { AppState } from '../app-state';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient, private globalService: GlobalService, public state: AppState) { }

  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem("user"));
    if(localUser){
        this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
        this.state.user = JSON.parse(localStorage.getItem('user')).user;
        return this.state.user.User.Id;
    }
    else{
        this.state.isLoggedIn = false;
        this.state.user = null;
        return "";
    }
  }
  token = this.getUserToken();

  profileSettingedit(body, id): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "UID": this.token })
    };
    return this.http.put<User>(this.globalService.AspNetUsers + '/' + id, body, httpOptions).pipe();
  }
  profilePasswordSetting(body, id): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "UID": this.token })
    };
    return this.http.post<User>(this.globalService.UpdatePassword + '/' + id, body, httpOptions).pipe();
  }
}
