import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pickup-cancellation-modal',
  templateUrl: './pickup-cancellation-modal.component.html',
  styleUrls: ['./pickup-cancellation-modal.component.scss']
})
export class PickupCancellationModalComponent implements OnInit {

  constructor(private router: Router, public dialogRef: MatDialogRef<PickupCancellationModalComponent>,
              @Inject( MAT_DIALOG_DATA ) public data: MatDialog ) { }
  onNoClick(): void {
       this.dialogRef.close();
              }

  ngOnInit() {
  }

}
