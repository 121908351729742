import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-saas-header',
  templateUrl: './saas-header.component.html',
  styleUrls: ['./saas-header.component.scss']
})
export class SAASHeaderComponent implements OnInit {
  imgSrc = '../../../../assets/images/Logo.png';
  ImageUrlnull = true;
  navbarSideOpen: boolean;
  @ViewChild(MatSidenav, { static: true })
  public sidenav: MatSidenav;
  constructor() { }

  ngOnInit() {
  }
  toggleNavbar() {
    this.sidenav.open();
  }
  closeNavbar() {
    this.sidenav.close();
  }
  toggleSideNavbar() {
    this.navbarSideOpen = !this.navbarSideOpen;

  }
}
