import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})

export class NumbersOnlyDirective {
  key;
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    var charCode = event.which || event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57))
      return false;
    if (event.shiftKey) return false;
    return true;
  }
}
