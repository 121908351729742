import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { SAASService } from 'src/app/shared/services/saas.service';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';

@Component({
  selector: 'app-otp-pricing-edit-popup',
  templateUrl: './otp-pricing-edit-popup.component.html',
  styleUrls: ['./otp-pricing-edit-popup.component.scss'],
})
export class OtpPricingEditPopupComponent implements OnInit {
  regionsdata: any;
  vehicleTypes: any;
  pickupstatesdata: any;
  citydataID: any;
  getByidData: any;
  Stateid: any;
  regionTypeid: any;
  vehicleTypeid: any;
  pickupCity: any;
  itemPrice: any;
  RegionId: any;
  VehicleId: any;
  StateId: any;
  CityId: any;
  requireField: boolean;
  requireFieldPrice: boolean;
  vehicleField: boolean;

  constructor(
    public dialogRef: MatDialogRef<OtpPricingEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private sassService: SAASService,
    private promotionCode: PricingPromotionCodeService
  ) {}

  ngOnInit() {
    this.getById();
  }
  getById() {
    this.promotionCode
      .getbyIdOTP(this.data[0].otpdata.PickupPriceId)
      .subscribe((data) => {
        this.getByidData = data.Result[0];
        this.itemPrice = data.Result[0].Price;
        this.RegionId = data.Result[0].RegionId;
        this.VehicleId = data.Result[0].VehicleId;
        this.StateId = data.Result[0].StateId;
        this.CityId = data.Result[0].CityId;

        this.pickupstates();
        this.pickupregion();
        this.vehicleTypesdata();
      });
  }
  pickupregion() {
    this.sassService.Regions().subscribe((data) => {
      this.regionsdata = data['Result'];
      for (var i = 0; i < this.regionsdata.length; i++) {
        if (this.regionsdata[i].ID == this.RegionId) {
          this.regionTypeid = this.regionsdata[i].ID;
        }
      }
    });
  }
  vehicleTypesdata() {
    this.sassService.VehicleTypes().subscribe((data) => {
      this.vehicleTypes = data['Result'];
      for (var i = 0; i < this.vehicleTypes.length; i++) {
        if (this.vehicleTypes[i].VehicleId == this.VehicleId) {
          this.vehicleTypeid = this.vehicleTypes[i].VehicleId;
        }
      }
    });
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.pickupstatesdata = data['Result'];
      for (var i = 0; i < this.pickupstatesdata.length; i++) {
        if (this.pickupstatesdata[i].ID == this.StateId) {
          this.Stateid = this.pickupstatesdata[i].ID;
          this.sassService.pickupcitydetails(this.Stateid).subscribe((data) => {
            this.citydataID = data['Result'];
            for (var i = 0; i < this.citydataID.length; i++) {
              if (this.citydataID[i].ID === this.CityId) {
                this.pickupCity = this.citydataID[i].ID;
              }
            }
          });
        }
      }
    });
  }
  editItem() {
    // this.dialogRef.close('res');
    if (this.regionTypeid === 'Select Region') {
      this.regionTypeid = null;
    }
    if (this.pickupCity === 'Select City') {
      this.pickupCity = null;
    }
    if (this.vehicleTypeid === 'Select Vehicle Type') {
      this.vehicleTypeid = null;
    }
    if (this.vehicleTypeid && this.vehicleTypeid !== null) {
      if (
        this.itemPrice &&
        this.itemPrice !== undefined &&
        this.itemPrice !== null
      ) {
        if (
          (this.regionTypeid && this.regionTypeid !== null) ||
          (this.pickupCity && this.pickupCity !== null) ||
          this.Stateid
        ) {
          let payloadassign = Object.assign({}, this.getByidData);
          (payloadassign.Price = this.itemPrice),
            (payloadassign.StateId = this.Stateid),
            (payloadassign.RegionId = this.regionTypeid),
            (payloadassign.CityId = this.pickupCity),
            (payloadassign.VehicleId = this.vehicleTypeid),
            // let payload;
            // payload = {
            //   Price : this.itemPrice,
            //   StateId : this.Stateid,
            //   RegionId : this.regionTypeid,
            //   CityId : this.pickupCity,
            //   VehicleId : this.vehicleTypeid
            // };
            this.promotionCode.editOTPData(payloadassign).subscribe((data) => {
              if (data.Result) {
                this.dialogRef.close('res');
              } else {
                alert(data.message);
              }
            });
        } else {
          this.requireField = true;
          setTimeout(() => {
            this.requireField = false;
          }, 5000);
        }
      } else {
        this.requireFieldPrice = true;
        setTimeout(() => {
          this.requireFieldPrice = false;
        }, 5000);
      }
    } else {
      this.vehicleField = true;
      setTimeout(() => {
        this.vehicleField = false;
      }, 5000);
    }
  }
  selectedstate() {
    this.sassService.pickupcitydetails(this.Stateid).subscribe((data) => {
      this.citydataID = data['Result'];
      this.pickupCity = null;
    });
  }
  cancelSub() {
    this.dialogRef.close('res');
  }
  close() {
    this.dialogRef.close();
  }
}
