import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ConditionalExpr } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'app-update-category-dialog',
  templateUrl: './update-category-dialog.component.html',
  styleUrls: ['./update-category-dialog.component.scss'],
})
export class UpdateCategoryDialogComponent implements OnInit {
  itemName: any;
  itemPrice: any;
  itemWeight: any;
  categories: any;
  selectedCategory: any;
  isPagination: any;
  requestData: any;
  requireField: boolean;
  requireFieldPrice: boolean;
  requireFieldWeight: boolean;
  requireFieldCategory: boolean;

  ClientId: any;
  ClientIdData: any;
  IsGoodrProduct: any;
  catName: any;
  category: any;
  buttonDisabled: boolean;
  errorMessageduplicate: boolean;
  constructor(
    public dialogRef: MatDialogRef<UpdateCategoryDialogComponent>,
    private route: ActivatedRoute,
    private state: AppState,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public capitailizepipe: specialPipe,
    private CustomerService: CustomerService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    if (this.data[0].productdata.Sections.lenght > 0) {
      this.selectedCategory = this.data[0].productdata.Sections[0].Name;
    }
    this.isPagination = true;
    // this.getCategories(this.isPagination);
    this.category = this.data;
    if (this.data[1].clientId) {
      this.ClientId = this.data[1].clientId;
    } else {
      this.ClientId = null;
    }
    this.getCategories(this.isPagination);
    // this.selectedCategory = this.catName.CreatedBy;
    // this.requestData = JSON.parse(localStorage.getItem("requestData"));
  }
  //GET-CATEGORIES
  getCategories(isPagination) {
    this.isPagination = true;
    this.CustomerService.getCategories(this.isPagination, this.ClientId).subscribe((data) => {
      this.categories = data['Result'];
      for (var i = 0; i < this.categories.length; i++) {
        if (this.categories[i].Name == this.data[0].productdata.Sections[0].Name) {
          this.selectedCategory = this.categories[i].SectionId;
        }
      }
    });
  }

  getSelectedCategory(value) {
    this.selectedCategory = value;
  }

  //POST-NEW-ITEM
  addNewItem() {
    // if (
    //   this.data[0].productdata.Name &&
    //   this.data[0].productdata.Price &&
    //   this.data[0].productdata.Weight &&
    //   this.selectedCategory !== undefined
    // ) {
    if (this.data[0].productdata.Name) {
      if (this.data[0].productdata.Price && this.data[0].productdata.Price > 0) {
        if (this.data[0].productdata.Weight && this.data[0].productdata.Weight > 0) {
          if (this.selectedCategory !== undefined) {
            const payload = {
              Name: this.capitailizepipe.transform(this.data[0].productdata.Name.replace(/^\s+/g, '')),
              Price: this.data[0].productdata.Price,
              Weight: this.data[0].productdata.Weight,
              Description: this.capitailizepipe.transform(this.data[0].productdata.Name.replace(/^\s+/g, '')),
              DirectCost: this.data[0].productdata.DirectCost,
              // Category: this.selectedCategory,
              IsGoodrProduct: this.data[0].productdata.IsGoodrProduct,
              ApprovalStatusId: this.data[0].productdata.ApprovalStatusId,
              WP_Product_Id: this.data[0].productdata.WP_Product_Id,
              CreatedBy: this.data[0].productdata.CreatedBy,
              CreatedOn: this.data[0].productdata.CreatedOn,
              ModifiedBy: this.data[0].productdata.ModifiedBy,
              ModifiedOn: this.data[0].productdata.ModifiedOn,
              ProductId: this.data[0].productdata.ProductId,
              IsActive: this.data[0].productdata.IsActive,
              ProductSections: [
                {
                  SectionId: this.selectedCategory,
                },
              ],
              Clients: [{ ClientId: this.ClientId }],
            };
            this.buttonDisabled = true;
            this.CustomerService.updateFoodItem(payload).subscribe((data) => {
              if (data['message'] === 'Duplicate Entry') {
                this.buttonDisabled = false;
                this.errorMessageduplicate = true;
                setTimeout(() => {
                  this.errorMessageduplicate = false;
                }, 5000);
              } else {
                let res = 'success';
                this.dialogRef.close(res);
                this.state.isSpinnerVisible = false;
              }
            });
          } else {
            this.buttonDisabled = false;
            this.requireFieldCategory = true;
            setTimeout(() => {
              this.requireFieldCategory = false;
            }, 3000);
          }
        } else {
          this.buttonDisabled = false;
          this.requireFieldWeight = true;
          setTimeout(() => {
            this.requireFieldWeight = false;
          }, 3000);
        }
      } else {
        this.buttonDisabled = false;
        this.requireFieldPrice = true;
        setTimeout(() => {
          this.requireFieldPrice = false;
        }, 3000);
      }
    } else {
      this.buttonDisabled = false;
      this.requireField = true;
      setTimeout(() => {
        this.requireField = false;
      }, 3000);
    }
  }
}
