import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SpinnerService } from 'src/app/core/spinner.service';
import { dateOnlyWithTimezone } from 'src/app/shared/pipes/dateOnlyWithTimezone.service';
import { PhoneFilterPipe } from 'src/app/shared/pipes/phone.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { EditContractDialogComponent } from '../edit-contract-dialog/edit-contract-dialog.component';

@Component({
  selector: 'app-edit-invoice-dialog',
  templateUrl: './edit-invoice-dialog.component.html',
  styleUrls: ['./edit-invoice-dialog.component.scss']
})
export class EditInvoiceDialogComponent implements OnInit {
  pickupContract: any;
  allClients: any;
  selectedClient: any;
  contractPerPriceRequired: boolean;
  contractExtraPriceRequired: boolean;
  noOfContractRequired: boolean;
  noOfDeliverRequired: boolean;
  contractPerPrice: number;
  contractExtraPrice: number;
  noOfContract: number;
  noOfDeliver: number;
  showStartDate: any;
  showEndDate: any;
  selectedClientObj: any;
  contractNetDays: number;
  contractDiscount: number;
  contractNetDaysRequired: boolean;
  contractDiscountRequired: boolean;
  contractDiscountRequiredError: boolean;
  invoiceTotalRequired: boolean;
  invoiceTotal: number;
  discountedInvoiceTotal: number;
  allNetDaysList: any = [0, 15, 30];
  noOfExtraPickups: number;
  noOfExtraPickupsRequired: boolean;
  contractID: number;
  constructor(public dialogRef: MatDialogRef<EditContractDialogComponent>,
    private pickupService: PickUpService,
    @Inject(MAT_DIALOG_DATA) public data1: MatDialog,
    private spinnerService: SpinnerService,
    public phoneFilterPipe: PhoneFilterPipe,
    public dateTimeFormat: dateOnlyWithTimezone,
  ) { }

  ngOnInit() {
    var spinnerRef = this.spinnerService.start();
    let localUser = JSON.parse(localStorage.getItem('user'));
    const listOfClients = localUser.user.User.Clients;
    if (listOfClients.length > 0) {
      this.allClients = listOfClients.sort(function (a, b) {
        if (a.Name < b.Name) {
          return -1;
        }
        if (a.Name > b.Name) {
          return 1;
        }
        return 0;
      });
    }

    this.pickupService.getInvoiceById(this.data1['ID']).subscribe((data) => {
      this.pickupContract = data.Result
      this.selectedClient = this.pickupContract[0].ClientId;
      this.contractPerPrice = this.pickupContract[0].Contract_Price_Per_Pickup;
      this.contractExtraPrice = this.pickupContract[0].Extra_Price_Per_Pickup;
      this.showStartDate = this.dateTimeFormat.transform(this.pickupContract[0].Period_Start_Date, this.pickupContract[0].TimeZone);
      this.showEndDate = this.dateTimeFormat.transform(this.pickupContract[0].Period_End_Date, this.pickupContract[0].TimeZone);
      this.noOfContract = this.pickupContract[0].No_Of_Contracted_Pickups;
      this.noOfDeliver = this.pickupContract[0].No_Of_Delivered_Pickups;
      this.noOfExtraPickups = this.pickupContract[0].No_Of_Extra_Pickups
      this.invoiceTotal = this.pickupContract[0].Total_Pickup_Price;
      this.contractNetDays = Number(this.pickupContract[0].NetDays)
      this.contractDiscount = this.pickupContract[0].DiscountInPercent;
      this.contractID = this.pickupContract[0].PickupContractId;
      this.discountedInvoiceTotal = this.pickupContract[0].Discounted_Total_Pickup_Price;
      this.spinnerService.stop(spinnerRef);
    });
  }
  isvalid() {
    if (this.noOfContract == undefined || this.noOfContract == null) {
      this.noOfContractRequired = true;
      setTimeout(() => {
        this.noOfContractRequired = false;
      }, 3000);
    } else if (this.noOfDeliver == null || this.noOfDeliver == undefined) {
      this.noOfDeliverRequired = true;
      setTimeout(() => {
        this.noOfDeliverRequired = false;
      }, 3000);
    } else if (this.contractPerPrice == null || this.contractPerPrice == undefined) {
      this.contractPerPriceRequired = true;
      setTimeout(() => {
        this.contractPerPriceRequired = false;
      }, 3000);
    } else if (this.noOfExtraPickups == undefined || this.noOfExtraPickups == null) {
      this.noOfExtraPickupsRequired = true;
      setTimeout(() => {
        this.noOfExtraPickupsRequired = false;
      }, 3000);
    } else if (this.contractExtraPrice == undefined || this.contractExtraPrice == null) {
      this.contractExtraPriceRequired = true;
      setTimeout(() => {
        this.contractExtraPriceRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount == undefined || this.contractDiscount == null) {
      this.contractDiscountRequired = true;
      setTimeout(() => {
        this.contractDiscountRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount < 0 || this.contractDiscount >= 101) {
      this.contractDiscountRequiredError = true;
      setTimeout(() => {
        this.contractDiscountRequiredError = false;
      }, 3000);
    } else if (this.invoiceTotal == undefined || this.invoiceTotal == null) {
      this.invoiceTotalRequired = true;
      setTimeout(() => {
        this.invoiceTotalRequired = false;
      }, 3000);
    } else {
      var spinnerRef = this.spinnerService.start();
      this.updateInvoice(spinnerRef)
    }

  }
  updateInvoice(spinnerRef) {
    let payload = {
      "ID": this.pickupContract[0].ID,
      "PickupContractId": this.pickupContract[0].PickupContractId,
      "Period_Start_Date": this.pickupContract[0].Period_Start_Date,
      "Period_End_Date": this.pickupContract[0].Period_End_Date,
      "Date": this.pickupContract[0].Date,
      "No_Of_Contracted_Pickups": this.noOfContract,
      "No_Of_Delivered_Pickups": this.noOfDeliver,
      "Contract_Price_Per_Pickup": this.contractPerPrice,
      "No_Of_Extra_Pickups": this.noOfExtraPickups,
      "Extra_Price_Per_Pickup": this.contractExtraPrice,
      "Total_Extra_Pickups_Price": this.noOfExtraPickups *this.contractExtraPrice,
      "Total_Contract_Pickups_Price": this.noOfContract * this.contractPerPrice,
      "Total_Pickup_Price": this.noOfContract * this.contractPerPrice + this.noOfExtraPickups *this.contractExtraPrice,
      "Payment_StatusId": this.pickupContract[0].Payment_StatusId,
      "DiscountInPercent": this.contractDiscount,
      "Discounted_Total_Pickup_Price": this.discountedInvoiceTotal
    }
    this.pickupService.updateInvoice(payload).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dialogRef.close("Done");

    },
      (error) => {
        console.log(error);
        this.spinnerService.stop(spinnerRef);
      });
  }
  changeDiscount() {
    if (this.contractDiscount == null || this.contractDiscount == undefined) {
      this.contractDiscountRequired = true;
      setTimeout(() => {
        this.contractDiscountRequired = false;
      }, 3000);
    } else if (this.contractDiscount < 0 || this.contractDiscount >= 101) {
      this.contractDiscountRequiredError = true;
      let x = (this.invoiceTotal - (this.invoiceTotal / 100 * 0));
      this.discountedInvoiceTotal = Number(x);
      setTimeout(() => {
        this.contractDiscountRequiredError = false;
      }, 3000);
    } else {
      let x = (this.invoiceTotal - (this.invoiceTotal / 100 * this.contractDiscount));
      this.discountedInvoiceTotal = Number(x);
    }
  }
  calculateTotalPrice() {
    if (this.noOfContract == undefined || this.noOfContract == null) {
      this.noOfContractRequired = true;
      setTimeout(() => {
        this.noOfContractRequired = false;
      }, 3000);
    } else if (this.contractPerPrice == null || this.contractPerPrice == undefined) {
      this.contractPerPriceRequired = true;
      setTimeout(() => {
        this.contractPerPriceRequired = false;
      }, 3000);
    } else if (this.noOfExtraPickups == undefined || this.noOfExtraPickups == null) {
      this.noOfExtraPickupsRequired = true;
      setTimeout(() => {
        this.noOfExtraPickupsRequired = false;
      }, 3000);
    } else if (this.contractExtraPrice == undefined || this.contractExtraPrice == null) {
      this.contractExtraPriceRequired = true;
      setTimeout(() => {
        this.contractExtraPriceRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount == undefined || this.contractDiscount == null) {
      this.contractDiscountRequired = true;
      setTimeout(() => {
        this.contractDiscountRequired = false;
      }, 3000);
    }
    else if (this.contractDiscount < 0 || this.contractDiscount >= 101) {
      this.contractDiscountRequiredError = true;
      setTimeout(() => {
        this.contractDiscountRequiredError = false;
      }, 3000);
    } else if (this.invoiceTotal == undefined || this.invoiceTotal == null) {
      this.invoiceTotalRequired = true;
      setTimeout(() => {
        this.invoiceTotalRequired = false;
      }, 3000);
    } else {
      this.invoiceTotal = this.noOfContract * this.contractPerPrice + this.noOfExtraPickups * this.contractExtraPrice
      this.changeDiscount();
    }
  }
}

