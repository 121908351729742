import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { SAASService } from 'src/app/shared/services/saas.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-background-otppassword',
  templateUrl: './background-otppassword.component.html',
  styleUrls: ['./background-otppassword.component.scss'],
})
export class BackgroundOTPPasswordComponent implements OnInit {
  public ownerForm: FormGroup;
  invalidemail: boolean;
  buttonDisabled: boolean;
  showValidators: boolean;
  dataerrortrue: boolean;
  dataerror: string;
  Email: string;
  disablebutton = true;

  constructor(
    private sassService: SAASService,
    private router: Router,
    public dialogRef: MatDialogRef<BackgroundOTPPasswordComponent>
  ) {}

  ngOnInit() {}
  keyPressFirstName(event: any) {
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateClient: true,
        OneTimeClient: { Email: event },
      };
      this.sassService.otpreigster(body1).subscribe((data) => {
        if (data.message !== 'Client already exist') {
          this.dataerrortrue = true;
          this.disablebutton = true;
          this.dataerror = "Client doesn't exist";
        } else {
          this.dataerrortrue = false;
          this.disablebutton = false;
        }
      });
    } else {
      this.dataerrortrue = false;
    }
  }
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.Email) === false) {
      return false;
    }
    return true;
  }

  loginnewform() {
    this.dialogRef.close();
    this.router.navigate(['PickupRegistration']);
    localStorage.setItem('otpemail', JSON.stringify(this.Email));
    localStorage.setItem('otpuser', JSON.stringify('existuser'));
  }
}
