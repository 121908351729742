 export function numberSetMask(event) {
        let trimmed = event.replace(/\s+/g, '');
        if (trimmed.length > 13) {
            trimmed = trimmed.substr(0, 13);
        }
        trimmed = trimmed.replace(/-/g, '');
        const numbers = [];
        numbers.push(trimmed.substr(0, 3));
        if (trimmed.substr(3, 3) !== '') {
            numbers.push(trimmed.substr(3, 3));
        }
        if (trimmed.substr(6, 4) !== '') {
            numbers.push(trimmed.substr(6, 5));
        }
        return event = numbers.join('-');
    }

