import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { MatDialog } from '@angular/material';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { getFeature } from 'src/app/shared/services/featureGuard.model';

@Component({
  selector: 'app-onetimepickuprequest',
  templateUrl: './onetimepickuprequest.component.html',
  styleUrls: ['./onetimepickuprequest.component.scss'],
})
export class OnetimepickuprequestComponent implements OnInit {
  nomenu = false;
  menu = true;
  clientsData: any;
  info: any;
  foodrequest = false;
  createForm: any;
  combined: boolean;
  VisibleForFacility: boolean;
  separated: boolean;
  combinedd: boolean;
  OrganicRecycling: boolean;
  Foodpickup: boolean = true;
  clientdropdown: boolean = false;
  constructor(
    private spinnerService: SpinnerService,
    private ClientFilterdata: ClientFilterService,
    private router: Router,
    private state: AppState,
    public dialog: MatDialog
  ) {}
  public form = {
    data: null,
  };

  clientsdataChange(value) {
    // if (value.data.MenuType == null) {
    //   this.nomenu = true;
    //   this.menu = false;
    // } else {
    //   if (value.data.PickupType.length > 0) {
    //     if (value.data.PickupType[0] === 'Food') {
    //       this.foodrequest = true;
    //       this.nomenu = false;
    //       this.menu = true;
    //       this.separated = false;

    //     }
    //     if (value.data.PickupType[1]) {
    //       if (value.data.PickupType[1] === 'Organic Recycling') {
    //         this.combined = true;
    //         this.separated = true;
    //       }
    //     } else {
    //       this.combined = false;
    //       this.separated = false;

    //     }
    //   } else {
    //     this.foodrequest = false;
    //   }
    // }
    this.router.navigate(['OTPBRequest/' + this.form.data.ClientId + '']);
  }
  onSubmit() {
    localStorage.setItem('Combined', JSON.stringify('foodType'));
    this.router.navigate(['Products/' + this.form.data.ClientId + '']);
  }
  ngOnInit() {
    this.VisibleForFacility = getFeature('foodpickup');
    var spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      if (this.info.facility.length > 0) {
        this.clientsData = this.info.facility.filter(
          (value) => value.PickupType[0] === 'One Time Pickup'
        );
        this.spinnerService.stop(spinnerRef);
      }
    });
  }
  CreatePickupCompost() {
    // let localUser = JSON.parse(localStorage.getItem("user"));
    // localUser.user.User.Clients.push(data['Result'][0])
    localStorage.setItem('Combined', JSON.stringify('CombinedRequest'));
    this.router.navigate(['Products/' + this.form.data.ClientId + '']);
  }
  CreatePickupSeperate() {
    // let localUser = JSON.parse(localStorage.getItem("user"));
    // localUser.user.User.Clients.push(data['Result'][0])
    localStorage.setItem('Combined', JSON.stringify('Separated'));
    this.router.navigate(['Products/' + this.form.data.ClientId + '']);
  }
  CreatePickupNomenu() {
    this.router.navigate(['FacilityDetails/' + this.form.data.ClientId + '']);
  }
  RequestExistingClient() {
    this.clientdropdown = true;
  }
  RequestExistingClients() {
    this.router.navigate(['OTPBRequest']);
  }
  GoToCreatePickup() {
    this.combinedd = false;
    this.Foodpickup = true;
  }
  goToOneTimePickup() {
    this.router.navigate(['OTPRegistration']);
  }
}
