import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root',
})
export class ExcelDashboardService {
  constructor() {}

  public exportAsExcelFile(json: any[], user: any[], graph: any[], excelFileName: string, selectedpickupType): void {
    console.log(json, 'json');
    var ws = XLSX.utils.json_to_sheet(user[0], { header: [], skipHeader: true });
    var ws1 = XLSX.utils.json_to_sheet(user[1], { header: [], skipHeader: true });

    // For SF data sheet Tab(data)
    XLSX.utils.sheet_add_json(ws, [{ A: '', B: '', C: '', D: '', E: '', F: '' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F'],
      skipHeader: true,
      origin: -1,
    });
    XLSX.utils.sheet_add_json(ws, [{ A: 'Most Wasted Items', B: 'Item', C: 'Weight (lbs)' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      skipHeader: true,
      origin: -1,
    });
    /* Write data starting at A2 */
    XLSX.utils.sheet_add_json(ws, json[0], { skipHeader: true, origin: { r: user[0].length + 2, c: 0 }, header: [] });
    /* Write data starting at E2 */
    XLSX.utils.sheet_add_json(ws, [{ A: '', B: '', C: '', D: '', E: '', F: '' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F'],
      skipHeader: true,
      origin: { r: json[0].length + 9, c: 0 },
    });

    XLSX.utils.sheet_add_json(ws, [{ A: 'Estimated Tax Savings' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      skipHeader: true,
      origin: { r: json[0].length + 10, c: 0 },
    });

    XLSX.utils.sheet_add_json(ws, [{ A: 'Date', B: 'Estimated Tax Savings ($)' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      skipHeader: true,
      origin: { r: json[0].length + 11, c: 0 },
    });
    XLSX.utils.sheet_add_json(ws, graph, { skipHeader: true, origin: { r: json[0].length + 12, c: 0 }, header: [] });

    // For OR  data sheet Tab(data1)
    XLSX.utils.sheet_add_json(ws1, [{ A: '', B: '', C: '', D: '', E: '', F: '' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F'],
      skipHeader: true,
      origin: -1,
    });

    XLSX.utils.sheet_add_json(ws1, [{ A: 'Most Wasted Items', B: 'Item', C: 'Weight (lbs)' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      skipHeader: true,
      origin: -1,
    });

    XLSX.utils.sheet_add_json(ws1, json[1], { skipHeader: true, origin: { r: user[1].length + 2, c: 0 }, header: [] });

    XLSX.utils.sheet_add_json(ws1, [{ A: '', B: '', C: '', D: '', E: '', F: '' }], {
      header: ['A', 'B', 'C', 'D', 'E', 'F'],
      skipHeader: true,
      origin: { r: json[1].length + 9, c: 0 },
    });

    //  if(excelFileName ===  'One Time Pickup'){
    //   XLSX.utils.sheet_add_json(ws, [
    //     { A: "Most Wasted Items", B: "Surplus Food Item", C: "Weight" }
    //   ], {header: ["A", "B", "C", "D", "E", "F", "G"], skipHeader: true, origin: -1});
    // }

    /* Append row */
    const workbook: XLSX.WorkBook = { Sheets: { SF: ws, OR: ws1 }, SheetNames: ['SF', 'OR'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, selectedpickupType);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, 'Goodr_Dashboard_Export_' + moment().format('MM-DD-YY') + EXCEL_EXTENSION);
  }
}
