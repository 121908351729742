import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../app.service';
import { PickUps } from 'src/app/models/pickups.model';
import { AppState } from '../app-state';

@Injectable({
  providedIn: 'root',
})
export class PickUpService {
  constructor(private http: HttpClient, private globalService: GlobalService, public state: AppState) { }

  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
      this.state.user = JSON.parse(localStorage.getItem('user')).user;
      return this.state.user.User.Id;
    } else {
      this.state.isLoggedIn = false;
      this.state.user = null;
      return '';
    }
  }
  token = this.getUserToken();
  getPickUp(userId: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.Deliveries, httpOptions).pipe();
  }
  getByDatePickUp(fromDate, toDate): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.Deliveries + '?' + 'From=' + fromDate + '&To=' + toDate, httpOptions).pipe();
  }

  getDashboardData(fromDate, toDate, pageNumber: any, PageSize: any, Client: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.Deliveries + '?' + 'From=' + fromDate + '&To=' + toDate, httpOptions).pipe();
  }
  addItemsRequest(requestDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.SendRequestUrl, requestDetails, httpOptions).pipe();
  }
  editMenuItemsRequest(requestDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.PickupRequestProducts, requestDetails, httpOptions).pipe();
  }
  editMenuCompostRequest(requestDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.editMenuCompostRequest, requestDetails, httpOptions).pipe();
  }
  addSeparatedItemsRequest(requestDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.editMenuCompostRequest, requestDetails, httpOptions).pipe();
  }
  deleteMenuItem(requestId, productId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.delete(this.globalService.PickupRequestProducts + '/' + requestId + '?ProductId=' + productId, httpOptions).pipe();
  }
  deleteCompostMenuItem(requestId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.delete(this.globalService.editMenuCompostRequest + '/' + requestId, httpOptions).pipe();
  }
  getPickUpTrack(deliveryId: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.Deliveries + '/' + deliveryId, httpOptions).pipe();
  }

  getNPfaileddata(RequestId: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.NPAlerts + '?requestid=' + RequestId, httpOptions).pipe();
  }

  getPickUpDetail(pickUpId: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.Deliveries + '/' + pickUpId, httpOptions).pipe();
  }

  getAllDeliveryStatus(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.DeliveryStatus, httpOptions).pipe();
  }
  updateDelivery(payload: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.Deliveries + '/' + payload.DeliveryId, payload, httpOptions).pipe();
  }
  DownloadReceipts(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.PDFForDonation, httpOptions).pipe();
  }
  PostmatesPickupDelivery(payload: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.PostmatesPickup, payload, httpOptions).pipe();
  }
  updatepostmateRoadiesDelivery(payload: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.AutoServiceProvider, payload, httpOptions).pipe();
  }
  updateRoadiesDelivery(payload: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.RoadiesPickup, payload, httpOptions).pipe();
  }
  getProductDetail(clientid: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.productDetailUrl + '&clientid=' + clientid, httpOptions).pipe();
  }
  getNonprofitList(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .get<PickUps>(
        this.globalService.Clients + '?pagingparametermodel.categoryTitle=Non%20Profit&pagingparametermodel.NonPaginateClient=true',
        httpOptions
      )
      .pipe();
  }

  getDeliveries(pageNumber: any, PageSize: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.Deliveries + '?pageNumber=' + pageNumber + '&PageSize=' + PageSize, httpOptions).pipe();
  }

  getClientsProducttDetail(clientid: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.Clients + '/' + clientid, httpOptions).pipe();
  }
  getClientsProductList(clientid: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.productUrl + '?clientId=' + clientid + '&requestproduct=true', httpOptions).pipe();
  }
  OrganicRecyclingPickupType(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.OrganicRecyclingPickupType, httpOptions).pipe();
  }
  SeparatedCompost(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.SeparatedCompost+'?pageSize=30', httpOptions).pipe();
  }
  getNonProfits(date, clientId): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.NonProfitAvailability + '?scheduleat=' + date + '&clientid=' + clientId, httpOptions).pipe();
  }
  getRecipientFilterList(state) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.nonprofitlistUrl + '?pagingparametermodel.state=' + state, httpOptions).pipe();
  }
  getRecipientFilterListByDistanceMatrix(clientId, isNonprofit) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.distanceMatrix + '?clientid=' + clientId + '&isnonprofit=' + isNonprofit, httpOptions).pipe();
  }
  getNpList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.getAllUsersClients + '&pagingparametermodel.categoryTitle=Non%20Profit', httpOptions).pipe();
  }
  getMrfClientList(state) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.mrflistUrl + state, httpOptions).pipe();
  }
  getRecipientList(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.nonprofitlistUrl + '?isRecipient=true', httpOptions).pipe();
  }
  getPickupFilterList(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.pickupfilterlistUrl, httpOptions).pipe();
  }
  RegenerateDonationReceipt(payload): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.regenerateDonationURL, payload, httpOptions).pipe();
  }
  getStatusList(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.DeliveryStatus, httpOptions).pipe();
  }
  dashboardData(Client: any, FacilityGroupId: any, FacilitatorId: any, fromDate: any, toDate: any, PickupTypeId: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?';
    filterString += 'ClientId=' + Client;
    filterString += '&FacilityGroupId=' + FacilityGroupId;
    filterString += '&FacilitatorId=' + FacilitatorId;
    if (fromDate && toDate) {
      filterString += '&From=' + fromDate + '&To=' + toDate;
    }
    return this.http.post<PickUps>(this.globalService.Dasboard + filterString, Client, httpOptions).pipe();
  }
  compostdashboardData(Client: any, FacilityGroupId: any, FacilitatorId: any, fromDate: any, toDate: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?';
    filterString += 'ClientId=' + Client;
    filterString += '&FacilityGroupId=' + FacilityGroupId;
    filterString += '&FacilitatorId=' + FacilitatorId;
    if (fromDate && toDate) {
      filterString += '&From=' + fromDate + '&To=' + toDate;
    }
    return this.http.post<PickUps>(this.globalService.CompostDashBoard + filterString, Client, httpOptions).pipe();
  }
  getFilteredDeliverieCount(pageNumber: any, PageSize: any, fromDate: any, toDate: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?pageNumber=' + pageNumber + '&PageSize=' + PageSize;
    if (fromDate && toDate) {
      filterString += '&pagingparametermodel.From=' + fromDate + '&pagingparametermodel.To=' + toDate;
    }
    return this.http.get<PickUps>(this.globalService.DeliveriesCount + filterString, httpOptions).pipe();
  }
  downloadDeliveries(
    pageNumber: any,
    PageSize: any,
    DeliveryId: any,
    Status: any,
    Client: any,
    driver: any,
    FacilityGroupId: any,
    FacilitatorId: any,
    Recipient: any,
    // PickupName: any,
    Pickup: any,
    driverservice,
    activePickup: any,
    sortColumn: any,
    fromDate: any,
    toDate: any
  ): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // UID: this.token
      }),
    };
    let filterString = '?';
    if (DeliveryId) {
      filterString += '&pagingparametermodel.DeliveryId=' + DeliveryId;
    }
    if (Status) {
      filterString += '&pagingparametermodel.Status=' + Status;
    }
    if (Client) {
      filterString += 'ClientId=' + Client;
    }
    if (driver) {
      filterString += '&pagingparametermodel.DriverServices=' + driver;
    }
    if (FacilityGroupId) {
      filterString += '&pagingparametermodel.FacilityGroupId=' + FacilityGroupId;
    }
    if (FacilitatorId) {
      filterString += '&pagingparametermodel.FacilitatorId=' + FacilitatorId;
    }
    if (Recipient) {
      filterString += '&pagingparametermodel.RecipantId=' + Recipient;
    }
    if (Pickup) {
      filterString += '&pagingparametermodel.PickupType=' + Pickup;
    }
    if (driverservice) {
      filterString += '&pagingparametermodel.DriverService=' + driverservice;
    }
    if (activePickup) {
      filterString += '&pagingparametermodel.ActivePickup=' + activePickup;
    }
    // if (sortColumn) {
    //   filterString += '&pagingparametermodel.SortColumn=' + sortColumn;
    // }
    if (fromDate && toDate) {
      filterString += '&From=' + fromDate + '&To=' + toDate;
    }
    filterString += '&UID=' + this.token;
    return (
      this.http
        //  API optimization
        .post<PickUps>(this.globalService.DownloadReceipt + filterString, httpOptions)
        // .get<PickUps>(
        //   this.globalService.Deliveries +
        //   filterString,
        //   httpOptions
        // )
        .pipe()
    );
  }
  getFilteredDeliveries(
    pageNumber: any,
    PageSize: any,
    DeliveryId: any,
    Status: any,
    Client: any,
    driver: any,
    FacilityGroupId: any,
    FacilitatorId: any,
    Recipient: any,
    // PickupName: any,
    Pickup: any,
    driverservice,
    activePickup: any,
    sortColumn: any,
    fromDate: any,
    toDate: any,
    pendingStatus: any,
    IsContaminated: any
  ): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?pagingparametermodel.pageNumber=' + pageNumber + '&pagingparametermodel.PageSize=' + PageSize;
    if (DeliveryId) {
      filterString += '&pagingparametermodel.DeliveryId=' + DeliveryId;
    }
    if (pendingStatus) {
      filterString += '&pagingparametermodel.PendingPickup=' + pendingStatus;
    }

    if (Status) {
      if (pendingStatus !== true) filterString += '&pagingparametermodel.Status=' + Status;
    }
    if (Client) {
      filterString += '&pagingparametermodel.ClientId=' + Client;
    }
    if (driver) {
      filterString += '&pagingparametermodel.DriverServices=' + driver;
    }
    if (FacilityGroupId) {
      filterString += '&pagingparametermodel.FacilityGroupId=' + FacilityGroupId;
    }
    if (FacilitatorId) {
      filterString += '&pagingparametermodel.FacilitatorId=' + FacilitatorId;
    }
    if (Recipient) {
      filterString += '&pagingparametermodel.RecipantId=' + Recipient;
    }
    if (Pickup) {
      filterString += '&pagingparametermodel.PickupType=' + Pickup;
    }
    if (driverservice) {
      filterString += '&pagingparametermodel.DriverService=' + driverservice;
    }
    if (activePickup) {
      filterString += '&pagingparametermodel.ActivePickup=' + activePickup;
    }
    if (sortColumn) {
      filterString += '&pagingparametermodel.SortColumn=' + sortColumn;
    }
    if (IsContaminated != '') {
      filterString += '&pagingparametermodel.IsContaminated=' + IsContaminated;
    }
    if (fromDate && toDate) {
      filterString += '&pagingparametermodel.From=' + fromDate + '&pagingparametermodel.To=' + toDate;
    }
    return (
      this.http
        //  API optimization
        .get<PickUps>(this.globalService.PortalDelivery + filterString, httpOptions)
        // .get<PickUps>(
        //   this.globalService.Deliveries +
        //   filterString,
        //   httpOptions
        // )
        .pipe()
    );
  }
  updatePickupRequest(payload: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.PickupRequests + '/' + payload.RequestId, payload, httpOptions).pipe();
  }
  getPickupContracts(pageNumber: any, PageSize: any, ClientName: any, PickupTypeid: any, contractId: any, noOfContractedPickups: any, ContractPeriodId: any
    , ContractPricePerPickup: any, ExtraPricePerPickup: any, selectedstatus: any, sortColumn: any, invoiceSelectedstatus: any,
    invoiceDue_FromDate: any, invoiceDue_ToDate: any, invoiceGeneratedFromDate: any, invoiceGeneratedToDate: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?pagingparametermodel.pageNumber=' + pageNumber + '&pagingparametermodel.PageSize=' + PageSize;
    if (contractId) {
      filterString += '&pagingparametermodel.id=' + contractId;
    }
    if (ClientName) {
      filterString += '&pagingparametermodel.clientname=' + ClientName;
    }
    if (PickupTypeid) {
      filterString += '&pagingparametermodel.pickuptypeid=' + PickupTypeid;
    }
    if (noOfContractedPickups) {
      filterString += '&pagingparametermodel.no_of_contracted_pickups=' + noOfContractedPickups;
    }
    if (ContractPeriodId) {
      filterString += '&pagingparametermodel.contractperiodid=' + ContractPeriodId;
    }

    if (ContractPricePerPickup) {
      filterString += '&pagingparametermodel.contract_price_per_pickup=' + ContractPricePerPickup;
    }
    if (ExtraPricePerPickup) {
      filterString += '&pagingparametermodel.extra_price_per_pickup=' + ExtraPricePerPickup;
    }
    if (ContractPricePerPickup) {
      filterString += '&pagingparametermodel.contract_price_per_pickup=' + ContractPricePerPickup;
    }
    if (selectedstatus) {
      filterString += '&pagingparametermodel.ApprovalStatusId=' + selectedstatus;
    }
    if (invoiceSelectedstatus) {
      filterString += '&pagingparametermodel.Invoice_Status=' + invoiceSelectedstatus;
    }
    if (sortColumn) {
      filterString += '&pagingparametermodel.SortColumn=' + sortColumn;
    }
    if (invoiceDue_FromDate) {
      filterString += '&pagingparametermodel.Invoice_Due_Date_From=' + invoiceDue_FromDate;
    }
    if (invoiceDue_ToDate) {
      filterString += '&pagingparametermodel.Invoice_Due_Date_To=' + invoiceDue_ToDate;
    }
    if (invoiceGeneratedFromDate) {
      filterString += '&pagingparametermodel.Last_Invoice_Generated_From=' + invoiceGeneratedFromDate;
    }
    if (invoiceGeneratedToDate) {
      filterString += '&pagingparametermodel.Last_Invoice_Generated_To=' + invoiceGeneratedToDate;
    }


    return this.http.get<PickUps>(this.globalService.PortalPickupContract + filterString, httpOptions).pipe();
  }
  getContractPeriod(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.ContractPeriod, httpOptions).pipe();
  }

  getApprovalStatus(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.ApprovalStatus, httpOptions).pipe();
  }

  getPickupContractById(id): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.PickupContract + '/' + id, httpOptions).pipe();
  }
  addContract(contractDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.PickupContract, contractDetails, httpOptions).pipe();
  }

  updateContract(contractDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.PickupContract, contractDetails, httpOptions).pipe();
  }
  addItemsInRequest(requestDetails) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.PickupRequestProducts, requestDetails, httpOptions).pipe();
  }

  getContractInvoices(pageNumber: any, PageSize: any, ClientName: any, PickupTypeid: any, contractId: any, noOfContractedPickups: any, noOfDeliveredPickup: any,ContractPeriodId: any
    , ContractPricePerPickup: any, ExtraPricePerPickup: any, selectedstatus: any, InvoiceTotal: any, discount: any, netDays: any, subtotal: any, sortColumn: any
    , invoiceGenerated_FromDate: any, invoiceGenerated_ToDate: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };

    let filterString = '?pagingparametermodel.pageNumber=' + pageNumber + '&pagingparametermodel.PageSize=' + PageSize;
    if (contractId) {
      filterString += '&pagingparametermodel.PickupContractId=' + contractId;
    }
    if (ClientName) {
      filterString += '&pagingparametermodel.clientname=' + ClientName;
    }
    if (PickupTypeid) {
      filterString += '&pagingparametermodel.pickuptypeid=' + PickupTypeid;
    }
    if (noOfContractedPickups) {
      filterString += '&pagingparametermodel.no_of_contracted_pickups=' + noOfContractedPickups;
    }
    if (noOfDeliveredPickup) {
      filterString += '&pagingparametermodel.No_Of_Delivered_Pickups=' + noOfDeliveredPickup;
    }
    if (ContractPeriodId) {
      filterString += '&pagingparametermodel.contractperiodid=' + ContractPeriodId;
    }

    if (ContractPricePerPickup) {
      filterString += '&pagingparametermodel.contract_price_per_pickup=' + ContractPricePerPickup;
    }
    if (ExtraPricePerPickup) {
      filterString += '&pagingparametermodel.extra_price_per_pickup=' + ExtraPricePerPickup;
    }
    if (ContractPricePerPickup) {
      filterString += '&pagingparametermodel.contract_price_per_pickup=' + ContractPricePerPickup;
    }
    if (selectedstatus) {
      filterString += '&pagingparametermodel.InvoiceStatus=' + selectedstatus;
    }
    if (InvoiceTotal) {
      filterString += '&pagingparametermodel.Total_Pickup_Price=' + InvoiceTotal;
    }
    if (discount) {
      filterString += '&pagingparametermodel.DiscountInPercent=' + discount;
    }
    if (netDays) {
      filterString += '&pagingparametermodel.NetDays=' + netDays;
    }
    if (subtotal) {
      filterString += '&pagingparametermodel.Total_Pickup_Price=' + subtotal;
    }

    if (sortColumn) {
      filterString += '&pagingparametermodel.SortColumn=' + sortColumn;
    }
    if (invoiceGenerated_FromDate) {
      filterString += '&pagingparametermodel.Date_From=' + invoiceGenerated_FromDate;
    }
    if (invoiceGenerated_ToDate) {
      filterString += '&pagingparametermodel.Date_To=' + invoiceGenerated_ToDate;
    }

    return this.http.get<PickUps>(this.globalService.ContractBilling + filterString, httpOptions).pipe();
  }
  getInvoiceById(id): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.ContractBilling + '/' + id, httpOptions).pipe();
  }
  updateShipment(payload: any): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.RoadiesPickup + '?deliveryId=' + payload.DeliveryId + '&shippingId=' + payload.shippingId, {}, httpOptions).pipe();
  }
  updateInvoice(invoiceDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.ContractBilling, invoiceDetails, httpOptions).pipe();
  }

  getinvoiceStatusList(): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.invoicePaymentStatus, httpOptions).pipe();
  }

  approveInvoices(invoiceDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<PickUps>(this.globalService.invoice, invoiceDetails, httpOptions).pipe();
  }

  fetchStatusInvoice(invoiceDetails): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<PickUps>(this.globalService.invoice, invoiceDetails, httpOptions).pipe();
  }

  generateInvoice(invoice): Observable<PickUps> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<PickUps>(this.globalService.invoice + '?BillingIds=' + invoice, httpOptions).pipe();
  }
}
