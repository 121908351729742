import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _moment from 'moment';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    monthYearLabel: { year: 'numeric' },
  },
};

@Component({
  selector: 'app-date-time-picker-combined',
  templateUrl: './date-time-picker-combined.component.html',
  styleUrls: ['./date-time-picker-combined.component.scss'],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class DateTimePickerCombinedComponent implements OnInit {
  constructor() { }
  @Input()
  value: Date;
  @Input()
  stepHour: number;
  @Input()
  stepMinute: number;
  @Input()
  stepSecond: number;
  @Input()
  enableMeridian: boolean;
  @Input()
  disableMinute: number;
  @Input()
  showSeconds: boolean;
  @Input()
  hideTime: boolean;
  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Input()
  disabled: boolean;
  @Input()
  pickerType: string;
  @Input()
  placeholder: string;
  @Output()
  onChangeDate: EventEmitter<string> = new EventEmitter<string>();
  ngOnInit() {
    if (this.minDate) this.minDate = new Date(this.minDate);
    if (this.maxDate) this.maxDate = new Date(this.maxDate);
  }

  saveNewDate(e) {
    this.onChangeDate.emit(new Date(e._selected).toISOString());
  }
}
