import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[appNumbersWithDecimalOnly]'
})

export class NumbersWithDecimalOnlyDirective {
    key;
    @HostListener('keydown', ['$event']) onKeypress(event: KeyboardEvent) {
        this.key = event.which ? event.which : event.keyCode;
        if ((this.key > 190 && (this.key < 48 || this.key > 57) && this.key !== 46)) {
            return false;
        }
        return true
    }
}
