import { Injectable } from '@angular/core';
import { GlobalService } from '../app.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, Client } from 'src/app/models/user.model';
import { AppState } from '../app-state';

@Injectable({
  providedIn: 'root'
})
export class SAASService {
  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    public state: AppState
  ) {}
  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(
        localStorage.getItem('user')
      ).isLoggedIn;
      this.state.user = JSON.parse(localStorage.getItem('user')).user;
      return this.state.user.User.Id;
    } else {
      this.state.isLoggedIn = false;
      this.state.user = null;
      return '';
    }
  }
  token = this.getUserToken();
  reigster(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<User>(this.globalService.register, body, httpOptions)
      .pipe();
  }
  reigsteradmin(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token
      })
    };
    return this.http
      .post<User>(this.globalService.register, body, httpOptions)
      .pipe();
  }
  otpreigster(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<User>(this.globalService.OneTimePickup, body, httpOptions)
      .pipe();
  }
  onetimebillingAdmin(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token
      })
    };
    return this.http
      .post<User>(this.globalService.OneTimePickup, body, httpOptions)
      .pipe();
  }
  onetimebilling(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<User>(this.globalService.OneTimePickup, body, httpOptions)
      .pipe();
  }
  onetimebillingStripePlan(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<User>(this.globalService.StripePlanURL, body, httpOptions)
      .pipe();
  }
  striping(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token
      })
    };
    return this.http
      .post<User>(this.globalService.Stripe, body, httpOptions)
      .pipe();
  }
  stripingreg(body: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post<User>(this.globalService.Stripe, body, httpOptions)
      .pipe();
  }
  getSubDetails(clientId): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token
      })
    };
    return this.http
      .get<User>(
        this.globalService.getcustomerurl + '/' + clientId,
        httpOptions
      )
      .pipe();
  }
  pickupstatedetails(): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .get<User>(this.globalService.pickupstatesURL, httpOptions)
      .pipe();
  }
  Regions(): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<User>(this.globalService.Regions, httpOptions).pipe();
  }
  VehicleTypes(): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .get<User>(this.globalService.VehicleTypes, httpOptions)
      .pipe();
  }
  pickupstatedetailsbyid(pickupstatID): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .get<User>(
        this.globalService.pickupstatesURL + '?RegionId=' + pickupstatID,
        httpOptions
      )
      .pipe();
  }
  citydetails(): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<User>(this.globalService.cityURL, httpOptions).pipe();
  }
  pickupcitydetails(pickupstatID): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .get<User>(this.globalService.pickupcityURL + pickupstatID, httpOptions)
      .pipe();
  }
}
