import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AppState } from 'src/app/shared/app-state';
import { PickUps } from 'src/app/models/pickups.model';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { SpinnerService } from 'src/app/core/spinner.service';

export interface PeriodicElement {
  name: string;
  Cost: number;
  weight: number;
  Quantity: string;
}

@Component({
  selector: 'app-track-pickup-list',
  templateUrl: './track-pickup-list.component.html',
  styleUrls: ['./track-pickup-list.component.scss']
})
export class TrackPickupListComponent implements OnInit {
  fooditems: any;
  pickupsData: any;
  driverData: any;
  totalWeight: number;
  totalCost: number;
  totalTax: number;
  item: any;
  totalItemValue: number;
  totalDeduction: number;
  istaxValueVisible: boolean;

  @ViewChild('widgetsContent', { static: true }) public widgetsContent: ElementRef<any>;

  displayedColumns: string[] = ['name', 'Cost', 'weight', 'Quantity'];
  displayedColumnsCompost: string[] = ['title', 'weight'];

  dataSource: MatTableDataSource<PickUps>;
  dataSourceCompost: MatTableDataSource<PickUps>;

  allfooditems: any;
  compostView: boolean;
  foodTypeView: boolean;
  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }
  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  constructor(private spinnerService: SpinnerService, private pickupService: PickUpService, private state: AppState, private router: Router, public dialog: MatDialog, private route: ActivatedRoute) { }
  ngOnInit() {
    this.istaxValueVisible = getFeature('taxValue');
    var spinnerRef = this.spinnerService.start();
    this.pickupService.getPickUpTrack(this.route.snapshot.params['id']).subscribe(
      data => {
        this.spinnerService.stop(spinnerRef);
        this.pickupsData = data['Result'];
        const pickups: PickUps[] = [];
        this.totalItemValue = 0;
        if (this.pickupsData[0].PickupRequest[0].PickupType[0].Title === 'Organic Recycling') {
          if (this.pickupsData[0].PickupRequest[0].OrganicRecyclingPickupType[0].Title === 'Separated') {
            this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling.map(value => {
              value.Weight = value.Weight * 0.0625;
            });
            for (let i = 0; i <= this.pickupsData[0].PickupRequest[0]['PickupRequest_OrganicRecycling'].length - 1; i++) {
              const pickUnit: PickUps = this.pickupsData[0].PickupRequest[0]['PickupRequest_OrganicRecycling'][i];

              let pickupDetails = this.pickupsData[0].PickupRequest[0]['PickupRequest_OrganicRecycling'][i];
              //total value calculation
              this.totalItemValue = this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling
                .map(r => r.Weight)
                .reduce((sum, current) => Number(sum) + Number(current));
              //   const itemValue = (pickupDetails.Quantity) *(pickupDetails.Product[0].Price);
              //   this.totalItemValue = this.totalItemValue +itemValue;
              pickups.push(pickUnit);
            }
          } else {
            this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling.map(value => {
              value.SeparatedCompost[0] = { Title: 'Combined' }
              value.Weight = value.Weight * 0.0625;
            });
            for (let i = 0; i <= this.pickupsData[0].PickupRequest[0]['PickupRequest_OrganicRecycling'].length - 1; i++) {
              const pickUnit: PickUps = this.pickupsData[0].PickupRequest[0]['PickupRequest_OrganicRecycling'][i];

              let pickupDetails = this.pickupsData[0].PickupRequest[0]['PickupRequest_OrganicRecycling'][i];
              //total value calculation
              this.totalItemValue = this.pickupsData[0].PickupRequest[0].PickupRequest_OrganicRecycling
                .map(r => r.Weight)
                .reduce((sum, current) => Number(sum) + Number(current));
              pickups.push(pickUnit);
            }
          }
          this.compostView = true;


          this.totalDeduction = this.totalItemValue / 2;
          this.totalItemValue = this.totalItemValue;
          this.dataSourceCompost = new MatTableDataSource(pickups);
        } else {
          this.foodTypeView = true;
          for (let i = 0; i <= this.pickupsData[0].PickupRequest[0]['PickupRequest_Product'].length - 1; i++) {
            const pickUnit: PickUps = this.pickupsData[0].PickupRequest[0]['PickupRequest_Product'][i];

            let pickupDetails = this.pickupsData[0].PickupRequest[0]['PickupRequest_Product'][i];
            //total value calculation
            this.totalItemValue = this.pickupsData[0].PickupRequest[0].PickupRequest_Product
              .map(r => r.Quantity * r.Cost)
              .reduce((sum, current) => Number(sum) + Number(current));
            //   const itemValue = (pickupDetails.Quantity) *(pickupDetails.Product[0].Price);
            //   this.totalItemValue = this.totalItemValue +itemValue;
            pickups.push(pickUnit);
          }

          this.totalDeduction = this.totalItemValue / 2;
          this.totalItemValue = this.totalItemValue;
          this.dataSource = new MatTableDataSource(pickups);
        }
        error => {
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
          this.spinnerService.stop(spinnerRef);
        };
      });
  };

  deletePickup() {
  }
  addFoodItem() {
    this.router.navigate(['Products']);
  }
  track_Order() {
    this.router.navigate(['TrackRoutePickedUp/' + this.route.snapshot.params["id"]]);
  }
}

export interface PeriodicElement {
  name: string;
  weight: number;
  Cost: number;
  Quantity: string;
}
