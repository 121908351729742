import { Component, OnInit } from '@angular/core';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';

@Component({
  selector: 'app-featuretoggle',
  templateUrl: './featuretoggle.component.html',
  styleUrls: ['./featuretoggle.component.scss'],
})
export class FeaturetoggleComponent implements OnInit {
  featureinfo: any;
  RoadiePostmatesFeature = false;
  pricingFeature = false;
  saasFeature = false;
  otpFeature = false;
  EnableFeaturevalue: number;
  titleId: any;

  constructor(
    private ClientFilterdata: ClientFilterService,
    private AdminService: AdmintoolsService
  ) {}

  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (
            element.Title === 'Roadie/Postmates' &&
            element.EnableFeature === true
          ) {
            this.RoadiePostmatesFeature = true;
          }
          if (element.Title === 'OTP' && element.EnableFeature === true) {
            this.otpFeature = true;
          }
          if (element.Title === 'SAAS' && element.EnableFeature === true) {
            this.saasFeature = true;
          }
          if (
            element.Title === 'Admin Pricing Control' &&
            element.EnableFeature === true
          ) {
            this.pricingFeature = true;
          }
        }
      }
    });
  }
  Featurevalue(title) {
    let payload;
    for (const element of this.featureinfo.featureData) {
      if (title === 'OTP') {
        if (this.otpFeature === true) {
          this.EnableFeaturevalue = 1;
        } else {
          this.EnableFeaturevalue = 0;
        }
        if (element.Title === 'OTP') {
          this.titleId = element.ID;
        }
      }
      if (title === 'Admin Pricing Control') {
        if (this.pricingFeature === true) {
          this.EnableFeaturevalue = 1;
        } else {
          this.EnableFeaturevalue = 0;
        }
        if (element.Title === 'Admin Pricing Control') {
          this.titleId = element.ID;
        }
      }
      if (title === 'Roadie/Postmates') {
        if (this.RoadiePostmatesFeature === true) {
          this.EnableFeaturevalue = 1;
        } else {
          this.EnableFeaturevalue = 0;
        }
        if (element.Title === 'Roadie/Postmates') {
          this.titleId = element.ID;
        }
      }
      if (title === 'SAAS') {
        if (this.saasFeature === true) {
          this.EnableFeaturevalue = 1;
        } else {
          this.EnableFeaturevalue = 0;
        }
        if (element.Title === 'SAAS') {
          this.titleId = element.ID;
        }
      }
      payload = {
        EnableFeature: this.EnableFeaturevalue,
        ID: this.titleId,
        Title: title,
      };
    }
    console.log(payload);
    this.AdminService.putFeatureToggles(payload).subscribe(
      (data) => {},
      (error) => {
        // alert(JSON.stringify(error));
      }
    );
  }
}
