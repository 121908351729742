import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-saasconfirmmsg',
  templateUrl: './saasconfirmmsg.component.html',
  styleUrls: ['./saasconfirmmsg.component.scss'],
})
export class SaasconfirmmsgComponent implements OnInit {
  fgnosaas: string;
  saasactive: boolean;

  onNoClick(): void {
    this.dialogRef.close();
  }
  constructor(
    public dialogRef: MatDialogRef<SaasconfirmmsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}

  ngOnInit() {
    // if (this.data[0].fgtype === 'SAASActive') {
    //   this.fgnosaas = 'SAASActive';
    // } else
    if (this.data[0].fgtype === 'SAASActive') {
      this.fgnosaas = 'SAASActive';
    } else if (this.data[0].fgtype === 'SAASFacility') {
      this.fgnosaas = 'SAASFacility';
    } else if (this.data[0].fgtype === 'FacilityNone') {
      this.fgnosaas = 'FacilityNone';
    } else if (this.data[0].fgtype === 'FGNone') {
      this.fgnosaas = 'FGNone';
    } else {
      this.fgnosaas = 'SAASFG';
    }
  }
  close() {
    // this.fgnosaas = false;
    if (this.fgnosaas === 'SAASFG') {
      this.fgnosaas = 'FGNone';
    } else if (this.fgnosaas === 'FGNone') {
      this.fgnosaas = 'SAASFG';
    } else if (this.fgnosaas === 'SAASFacility') {
      this.fgnosaas = 'FacilityNone';
    } else if (this.fgnosaas === 'FacilityNone') {
      this.fgnosaas = 'SAASFacility';
    } else {
      this.fgnosaas = 'SAASinActive';
    }
    this.dialogRef.close(this.fgnosaas);
  }
  ContinueFG() {
    this.dialogRef.close(this.fgnosaas);
  }
}
