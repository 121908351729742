import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import {
  MatPaginator,
  MatSort,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material";
import { Subscription } from 'rxjs/Subscription';
import { ImportMenuDialogComponent } from '../../import-menu-dialog/import-menu-dialog.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-paysuccessful',
  templateUrl: './paysuccessful.component.html',
  styleUrls: ['./paysuccessful.component.scss']
})
export class PaysuccessfulComponent implements OnInit, OnDestroy {
  yearlyPay: any;
  confDate: string;
  modalclose: string;
  info: any;
  ClientId: any;
  saascreatemenu: any;
  updateinfo: any;
  subscription: Subscription;
  relaodinfo: any;
  constructor(public dialogRef: MatDialogRef<PaysuccessfulComponent>, @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public dialog: MatDialog, public _rdata: modalDataService, private router: Router, ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this._rdata.basicreloaddataInfo2Observe.subscribe(res => {
      this.relaodinfo = JSON.parse(res);
    });
    this.subscription = this._rdata.basicupdateInfo2Observe.subscribe(res => {
      this.updateinfo = JSON.parse(res);
      if (this.updateinfo) {
        if (this.updateinfo.updateclient === true) {
          this.ClientId = this.updateinfo.SAASupdate.ClientId;
        } else {
          this.subscription = this._rdata.basicInfo2Observe.subscribe(res => {
            this.info = JSON.parse(res);
            this.ClientId = this.info.clientId;
            this.saascreatemenu = this.info.saascreatemenu;
          });
          this.info.saasFG = '';
          this._rdata.updateBasicInfo2(JSON.stringify(this.info));
        }
      } else {
        this.subscription = this._rdata.basicInfo2Observe.subscribe(res => {
          this.info = JSON.parse(res);
          this.ClientId = this.info.clientId;
          this.saascreatemenu = this.info.saascreatemenu;
        });
        this.info.saasFG = '';
        this._rdata.updateBasicInfo2(JSON.stringify(this.info));
      }
    });
    this.yearlyPay = JSON.parse(localStorage.getItem('accountType'));
    if (this.yearlyPay === 'montly') {
      this.confDate = moment(new Date()).add(1, 'M').format('MM/DD/YY');
    } else {
      this.confDate = moment(new Date()).add(1, 'y').format('MM/DD/YY');
    }
  }
  gotoImportMenuDialog() {
    const dialogRef = this.dialog.open(ImportMenuDialogComponent, {
      width: '700px',
      // height: '450px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: {
        clientId: this.ClientId,
        importstatus: this.data['importstatus']
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
    this.dialogRef.close(this.modalclose);
  }
  done() {
    this.dialogRef.close(this.modalclose);
    if (this.info) {
      if (this.info.facility_type !== 'Facility' && this.info.facility_type !== 'SAAS Facility') {
        this.router.navigate(["CustomerFacilitatorview/" + this.ClientId + ""]);
      } else {
        this.router.navigate(["FacilityDetails/" + this.ClientId + ""]);
      }
    } else {
      if (this.updateinfo) {
        if (this.updateinfo.drivertitle === 'SAAS Facility' || this.updateinfo.drivertitle === 'Facility') {
          this.router.navigate(["FacilityDetails/" + this.ClientId + ""]);
        } else {
          this.router.navigate(["CustomerFacilitatorview/" + this.ClientId + ""]);
        }
        // location.reload();
      }
      this.relaodinfo.reloadData = true;
      this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
    }
    localStorage.removeItem('accountType');
  }
  ngOnDestroy() {
    localStorage.removeItem('accountType');
    this.subscription.unsubscribe();
    this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    this._rdata.resetclientBasicInfo2(JSON.stringify(this.updateinfo));
  }
}

