import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd
} from '@angular/router';
import { AdmintoolsService } from './admintools.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerService } from './customer.service';
import { PickUpService } from './pickup.service';

@Injectable()
export class EditToggleFeatureGuard implements CanActivate {
  app_env: string = environment.app_env;
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private pickupService: PickUpService,
    private AdminService: AdmintoolsService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.AdminService.FeatureToggles().pipe(
      map(res => {
        for (const element of res.Result) {
          // this is for OTP checking
          if (element.Title === 'OTP' && element.EnableFeature === false) {
            if (
              location.href.includes('pickupDetails') ||
              location.href.includes('TrackRoutePickedUp') ||
              location.href.includes('TrackPickupList')
            ) {
              this.pickupService
                .getPickUpDetail(+next.paramMap.get('id'))
                .subscribe(data => {
                  let response;
                  // console.log(data.Result[0].PickupRequest[0].Clients[0])
                  response = data.Result;

                  if (
                    response[0].PickupRequest[0].PickupType[0].Title ===
                    'One Time Pickup'
                  ) {
                    this.router.navigate(['/error']);
                  }
                });
            }
          }
          // this is for SAAS checking
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            if (
              location.href.includes('FacilityDetails') ||
              location.href.includes('CustomerFacilitatorview') ||
              location.href.includes('FacilityMenuView') ||
              location.href.includes('Products')
            ) {
              const id = +next.paramMap.get('id');
              this.customerService
                .getCustomerDetail(+next.paramMap.get('id'))
                .subscribe(data => {
                  if (
                    data['Result'][0].ClientCategory[0].Title ===
                      'SAAS Facility' ||
                    data['Result'][0].ClientCategory[0].Title ===
                      'SAAS Facility Group'
                  ) {
                    this.router.navigate(['/error']);
                  }
                });
            } else if (
              location.href.includes('pickupDetails') ||
              location.href.includes('TrackRoutePickedUp') ||
              location.href.includes('TrackPickupList')
            ) {
              this.pickupService
                .getPickUpDetail(+next.paramMap.get('id'))
                .subscribe(data => {
                  let response;

                  response = data.Result;

                  if (
                    response[0].PickupRequest[0].Clients[0].ClientCategory[0]
                      .Title === 'SAAS Facility'
                  ) {
                    this.router.navigate(['/error']);
                  }
                });
            }
          }
        }
        return !!res;
      })
    );
  }
}
