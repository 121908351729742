import { Injectable } from '@angular/core';
// import { HttpClient } from 'selenium-webdriver/http';
import { GlobalService } from '../app.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/models/user.model';
import { AppState } from '../app-state';

@Injectable({
  providedIn: 'root',
})
export class AdmintoolsService {
  // public getAllUserUrl = this.globalService.base_url + '?Action=v2_getusers';
  // public AspNetUsers = this.globalService.base_url + 'AspNetUsers';
  // public base_url = 'http://gdr-dev-api-3-0.eastus.cloudapp.azure.com/Goodr3.0_deploy/api/AspNetUsers';

  constructor(private http: HttpClient, private globalService: GlobalService, public state: AppState) {}

  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
      this.state.user = JSON.parse(localStorage.getItem('user')).user;
      return this.state.user.User.Id;
    } else {
      this.state.isLoggedIn = false;
      this.state.user = null;
      return '';
    }
  }
  token = this.getUserToken();

  v2_getAllUser(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.AspNetUsers, httpOptions);
  }

  putFeatureToggles(payload: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<User>(this.globalService.FeatureToggles, payload, httpOptions).pipe();
  }

  getUsers(pageNumber: any, PageSize: any): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    // return this.http.get<User>(this.globalService.AspNetUsers, httpOptions);
    return this.http.get<User>(this.globalService.AspNetUsers + '?pageNumber=' + pageNumber + '&PageSize=' + PageSize, httpOptions).pipe();
  }
  getUsersSASSByDriver(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.FGSAASClientUrl, httpOptions).pipe();
  }

  getUsersByDriver(saas): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.SAASClientUrl + saas +'&pagingparametermodel.nonpaginatedusers=true', httpOptions).pipe();
  }
  getStatus(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    // return this.http.get<User>(this.globalService.AspNetUsers, httpOptions);
    return this.http.get<User>(this.globalService.Status, httpOptions).pipe();
  }
  updateUserDetails(payload: any) {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put(this.globalService.AspNetUsers + '/' + payload.Id, payload, httpOptions).pipe();
  }

  getClient(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.Clients, httpOptions).pipe();
  }

  getRoles(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.Roles, httpOptions).pipe();
  }

  getUserStatus(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.ApprovalStatus, httpOptions).pipe();
  }
  getFilteredUsers(
    pageNumber: any,
    PageSize: any,
    FirstName: any,
    LastName: any,
    Email: any,
    Account: any,
    Role: any,
    Status: any,
    sortColumn: any
  ): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    
    let filterString = '?pageNumber=' + pageNumber + '&PageSize=' + PageSize;
    if (FirstName) {
      filterString += '&FirstName=' + FirstName;
    }
    if (LastName) {
      filterString += '&LastName=' + LastName;
    }
    if (Email) {
      filterString += '&Email=' + Email;
    }
    if (Account) {
      filterString += '&clientid=' + Account;
    }
    if (Role) {
      filterString += '&roleName=' + Role;
    }
    if (Status) {
      filterString += '&approvalStatus=' + Status;
    }
    if (sortColumn) {
      filterString += '&SortColumn=' + sortColumn;
    }
    return this.http
      .get<User>(
        // this.globalService.AspNetUsers +
        // API optimization
        this.globalService.PortalUser + filterString,
        httpOptions
      )
      .pipe();
  }
  getVersionAPI(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.VersionCheckAPI, httpOptions).pipe();
  }
  SelectUser(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.SelectUser, httpOptions).pipe();
  }
  FacilityGroupClients(Email: any): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.AspNetUsers + '?Email=' + Email, httpOptions).pipe();
  }
  getAccountType(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.AspNetRoles, httpOptions);
  }
  getClients(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.getcustomerurl, httpOptions);
  }

  createUser(payload: any) {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.inviteUser, payload, httpOptions).pipe();
  }

  updateUser(payload: any) {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.updateUser, payload, httpOptions).pipe();
  }
  grabmenucreation(grabdata: any) {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.GrabUrl, grabdata, httpOptions).pipe();
  }
  getclientDetails(ClientId): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.AspNetUsers + '/' + ClientId, httpOptions);
  }
  forgotPasswordResetCode(payload: any) {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(this.globalService.ForgotPasswordResetCodeUrl, payload, httpOptions).pipe();
  }

  forgotPasswordPost(payload: any) {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.ForgotPasswordUrl, payload, httpOptions).pipe();
  }
  getClientwithoutPickupclientsPagination(pickupTYpe, fromDate, toDate): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .get<User>(
        this.globalService.PortalClient +
          // '?pagingparametermodel.PickupType=' +
          // pickupTYpe +
          '?pagingparametermodel.nonPaginateClient=true&pagingparametermodel.dashboard=true&pagingparametermodel.From=' +
          fromDate +
          '&pagingparametermodel.To=' +
          toDate,
        httpOptions
      )
      .pipe();
  }

  getClientwithoutPagination(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.PortalClient + '?pagingparametermodel.nonPaginateClient=true', httpOptions).pipe();
  }
  FeatureToggles(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.FeatureToggles, httpOptions).pipe();
  }

  getFacilityClientwithoutPagination(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.Clients + '?CategoryTitle=Facility&NonPaginateClient=true', httpOptions).pipe();
  }

  getGoodrDrivers(): Observable<User> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<User>(this.globalService.AspNetUsers + '?RoleName=Driver&NonPaginatedUsers=true', httpOptions).pipe();
  }
  createQuickbookCustomer(clientId: any) {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get(this.globalService.QuickbookAPI + '?clientid='+ clientId, httpOptions).pipe();
  }
}
