import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { modalDataService } from 'src/app/shared/services/modalData.service';

@Component({
  selector: 'app-save-edit-menumodal',
  templateUrl: './save-edit-menumodal.component.html',
  styleUrls: ['./save-edit-menumodal.component.scss']
})
export class SaveEditMenumodalComponent implements OnInit {
  infoData: any;

  constructor(private observerdata: modalDataService, private router: Router, public dialogRef: MatDialogRef<SaveEditMenumodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }
  pickupDetails() {
    // this.router.navigate(['pickupDetails/' + this.data[0].id + '']);
    this.dialogRef.close();
  }

}
