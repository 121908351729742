import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd
} from '@angular/router';
import { AdmintoolsService } from './admintools.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../app-state';

@Injectable()
export class RoleGuardFeatureGuard implements CanActivate {
  app_env: string = environment.app_env;
  constructor(
    private router: Router,
    private AdminService: AdmintoolsService,
    public state: AppState
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.AdminService.FeatureToggles().pipe(
      map(res => {
        let localUser = JSON.parse(localStorage.getItem('user'));
        if (localUser) {
          this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
          this.state.user = JSON.parse(localStorage.getItem('user')).user;
          if (this.state.user.User.Roles[0].Name !== "Billing Admin") {
            this.router.navigate(['/error']);
          }
        }
        return !!res;
      })
    );
  }
}
