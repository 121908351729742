import { Component, OnInit, ViewChild, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { from } from 'rxjs';
import { MatSort } from '@angular/material/sort';
// import { InviteuserdialogComponent } from "../inviteuserdialog/inviteuserdialog.component";
import { InviteuserdialogComponent } from '../../user/inviteuserdialog/inviteuserdialog.component';
import { EdituserComponent } from '../../user/edituser/edituser.component';
import { AddeditfilterComponent } from '../../user/addeditfilter/addeditfilter.component';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { Router } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { ExcelServicesService } from 'src/app/shared/services/excel-services.service';
import { DeleteUserComponent } from '../../user/delete-user/delete-user.component';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { GlobalService } from 'src/app/shared/app.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TimezoneService } from 'src/app/shared/pipes/timezone.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
export interface PeriodicElements {
  Id: any;
  LastName: string;
  FirstName: string;
  ID: number;
  Email: any;
  Role: string;
  Status: string;
  LastActivity: any;
  Delete: any;
  Account: string;
}
export interface PeriodicElement {
  LastName: string;
  FirstName: string;
  ID: number;
  AccountName: string;
  AccountType: string;
  TaskPerformed: string;
  Role: string;
  Date: any;
  Time: any;
}
const ELEMENT_DATA: PeriodicElements[] = [];
const ELEMENT_DATAS: PeriodicElement[] = [
  {
    FirstName: 'Horace',
    ID: 2,
    LastName: 'Williams',
    AccountName: 'Michaels Pizza',
    AccountType: 'Facility',
    TaskPerformed: 'Submitted A Pickup',
    Role: 'Admin',
    Date: '02-07-2019',
    Time: '9:15am Est',
  },
];
@Component({
  selector: 'app-usersAdminView',
  templateUrl: './usersAdminView.component.html',
  styleUrls: ['./usersAdminView.component.scss', '../../dashboard/dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class usersAdminViewComponent implements OnInit, AfterViewInit, AfterViewChecked {
  alluser: any;
  clients: any[];
  Roles: any;
  User: any;
  selectedPageSize = [10, 25, 50, 100];
  userdetails: any;
  RecordCount: any;
  pageSize: number;
  pageNumbe = 1;
  userClientType: any;
  userdetailss: any;
  isInviteNewUserVisible: boolean;
  isUsersAddFiltersVisible: boolean;
  isUsersExportVisible: boolean;
  userInfo: any;
  AccountName = [];
  sortColumn: any = null;
  isLoading = true;
  listdata: any;
  addfilter: boolean;
  buttonExport = false;
  pageNumber: number;
  info: any;
  globalFirstName: any;
  globalLastName: any;
  globalEmail: any;
  globalAccount: any;
  globalRole: any;
  VisibleForAdminOnly: boolean;
  globalStatus: any;
  buttonDisabled: boolean;
  selectedAccountName: any;
  filtermodal: boolean;
  filterCount = [];
  token: any;
  exportData: string;
  facilityInviteUser: any;
  checkBoxTrue: boolean;
  VisibleForAdminBulkMenu: boolean;
  rolesData: any;
  rolesList: any;
  selectedRole: any;
  approvalStatusData: any;
  approvalStatusList: any;
  selectedStatus: any;
  globalAccountName: any;
  showResetButton: boolean;

  constructor(
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private globalService: GlobalService,
    private AdminService: AdmintoolsService,
    private ClientFilterdata: ClientFilterService,
    private cdRef: ChangeDetectorRef,
    private observedata: modalDataService,
    private state: AppState,
    private router: Router,
    private excelService: ExcelServicesService,
    private loaderService: LoaderService,
    public timezone: TimezoneService
  ) { }
  displayedColumns: string[] = [
    'ID',
    'FirstName',
    'LastName',
    'Email',
    'Account',
    'Role',
    'Status',
    // "Delete",
    'Details',
  ];
  displayedColumnsforrecentactivity: string[] = [
    'ID',
    'FirstName',
    'LastName',
    'AccountName',
    'AccountType',
    'Task',
    'Role',
    'Date',
    'Time',
    'Delete',
    'Details',
  ];
  dataSource = new MatTableDataSource<PeriodicElements>(ELEMENT_DATA);
  // data = Object.assign(ELEMENT_DATA);
  // dataSource = new MatTableDataSource<PeriodicElements>(this.data);
  selection = new SelectionModel<PeriodicElements>(true, []);
  dataSources = new MatTableDataSource<PeriodicElement>(ELEMENT_DATAS);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  filteredData: any;
  UserId: number;
  excelExport: any = [];
  showButton: boolean;
  disableForSAAS: boolean;
  infoallclients: any;
  ClientData: any;
  ClientList: any;
  selectedAccount: any;

  ngOnInit() {
    this.selection.isSelected = this.isChecked.bind(this);
    if (this.state.user.IsInviteUser === true && this.state.user.User.Roles[0].Name === 'Facility') {
      this.facilityInviteUser = this.state.user.IsInviteUser;
    }
    // if (this.state.user.User.Roles[0].Name === 'Facility Group') {
    //   if (this.state.user.User.Clients[0].DriverServices[0].Title === 'SAAS') {
    //    this.disableForSAAS = true;
    //   }
    // }

    this.getAllClients();
    this.getAllRoles();
    this.getAllStatus();
    // this.AdminService.getClientwithoutPagination().subscribe(
    //   clientData => {

    // for (var i = 0; i < this.ClientList.length; i++) {
    //   if (this.ClientList[i].ClientId == this.globalAccount) {
    //     this.selectedAccount = this.ClientList[i].ClientId;
    //     this.selectedAccountName = this.ClientList[i].Name;
    //   }
    // }
    this.observedata.basicUserinfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      this.globalStatus = this.info.globalStatus;
      this.globalRole = this.info.globalRole;
      this.globalAccount = this.info.globalAccount;
      this.globalEmail = this.info.globalEmail;
      this.globalLastName = this.info.globalLastName;
      this.globalFirstName = this.info.globalFirstName;
      this.pageNumber = this.info.pageNumber;
      this.pageSize = Number(this.info.pageSize);
      this.sortColumn = this.info.sortColumn;
      this.selectedAccountName = this.info.selectedAccountName;
      this.filterCount = [];
      for (const item in this.info) {
        if (this.info[item]) {
          if (this.info[item] !== null) {
            if (
              this.info[item] !== undefined &&
              item !== 'selectedAccountName' &&
              item !== 'sortColumn' &&
              item !== 'pageSize' &&
              item !== 'pageNumber'
            ) {
              this.filterCount.push(this.info[item]);
            }
          }
        }
      }
    });
    // this.VisibleForAdminOnly = getFeature("accountdelete");
    // this.isUserVisible = getFeature('users');
    this.isLoading = true;
    this.isInviteNewUserVisible = getFeature('inviteNewUser');
    this.isUsersAddFiltersVisible = getFeature('usersAddFilters');
    this.isUsersExportVisible = getFeature('usersExport');
    this.showButton = getFeature('accountdelete');
    this.VisibleForAdminBulkMenu = getFeature('bulkMenuItemDeleted');

    if (getFeature('users') == true) {
      this.getuserdata();
      this.state.isSpinnerVisible = true;
      // this.v2_getAlluser();
    }

    this.checkFilters();
  }

  getAllClients() {
    this.ClientFilterdata.basicInfoObserveclient.subscribe((res) => {
      this.infoallclients = JSON.parse(res);
      this.ClientData = this.infoallclients['allClients'];
      const clients = [];
      for (let i = 0; i < this.ClientData.length; i++) {
        const clients_data = {
          ClientId: this.ClientData[i].ClientId,
          Name: this.ClientData[i].Name,
        };

        clients.push(clients_data);
      }

      this.ClientList = clients;
    });
  }

  getAllRoles() {
    this.AdminService.getRoles().subscribe(
      (data) => {
        this.rolesData = data;
        const roles = [];
        for (let i = 0; i < data['Result'].length; i++) {
          this.rolesData = data['Result'][i];
          const roles_data = {
            Id: this.rolesData.Id,
            Name: this.rolesData.Name,
          };
          roles.push(roles_data);
        }
        this.rolesList = [];

        let rolesTypeArray = [...roles];
        // console.log(rolesTypeArray);

        rolesTypeArray.forEach((element) => {
          if (element.Name === 'Facility') {
            this.rolesList[0] = element;
          } else if (element.Name === 'Facility Group') {
            this.rolesList[1] = element;
          } else if (element.Name === 'Facilitator') {
            this.rolesList[2] = element;
          } else if (element.Name === 'Non Profit') {
            this.rolesList[3] = element;
          } else if (element.Name === 'Admin') {
            this.rolesList[4] = element;
          } else if (element.Name === 'Driver') {
            this.rolesList[5] = element;
          }else if (element.Name === 'Billing Admin') {
            this.rolesList[6] = element;
          }
          else if (element.Name === 'Read-Only Admin') {
            this.rolesList[7] = element;
          } else {
          }
        });

        // console.log(this.rolesData);
        // this.rolesList = roles;

        for (var i = 0; i < this.rolesList.length; i++) {
          if (this.rolesList[i].Name == this.globalRole) {
            this.selectedRole = this.rolesList[i].Name;
          }
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }

  getAllStatus() {
    this.AdminService.getUserStatus().subscribe(
      (data) => {
        this.approvalStatusData = data;
        const status = [];
        for (let i = 0; i < data['Result'].length; i++) {
          this.approvalStatusData = data['Result'][i];
          const status_data = {
            Id: this.approvalStatusData.ID,
            Title: this.approvalStatusData.Title,
          };
          status.push(status_data);
        }
        // this.approvalStatusList = status;
        this.approvalStatusList = status.filter((value) => value.Title !== 'Trash');
        for (var i = 0; i < this.approvalStatusList.length; i++) {
          if (this.approvalStatusList[i].Title == this.globalStatus) {
            this.selectedStatus = this.approvalStatusList[i].Title;
          }
        }
        // this.approvalStatusList = this.orderService.transform(this.approvalStatusList, 'Title');
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }

  removeSelectedRows() {
    const dialogRef = this.dialog.open(DeleteUserComponent, {
      width: '700px',
      // height: "520px",
      panelClass: 'custom-Filterdialog-container',
      data: { Id: this.selection.selected, UserId: this.UserId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pageNumber = 1;
        this.getuserdata();
        this.state.isSpinnerVisible = false;
        this.checkBoxTrue = false;
        this.selection = new SelectionModel<PeriodicElements>(true, []);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const displayedRows = this.dataSource.connect().value.length;
    let isAllSelected = numSelected === displayedRows;

    if (isAllSelected) {
      isAllSelected = this.isAllDisplayedSelected();
    }
    return isAllSelected;
  }

  isAllDisplayedSelected() {
    let isAllDisplayedSelected = true;

    if (this.selection.selected.length === 0) {
      return this.isAllSelected();
    }

    this.dataSource.connect().value.some((element) => {
      if (!this.selection.isSelected(element)) {
        isAllDisplayedSelected = false;
        return isAllDisplayedSelected;
      }
    });
    return isAllDisplayedSelected;
  }
  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el) => el['Id'] === row.Id);
    if (found) {
      return true;
    }
    return false;
  }
  checked(row: any) {
    this.selection.select(row);
    var found = this.selection.selected.find((el) => el['Id'] === row.Id);
    if (found) {
      return true;
    }
    return false;
  }
  unchecked(row: any) {
    var found = this.selection.selected.find((el) => el['Id'] === row.Id);
    this.selection.deselect(found);
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
    if (found) {
      return true;
    } else {
      return false;
    }
  }
  ngAfterViewChecked() {
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
      this.cdRef.detectChanges();
    }
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // this.selection.clear()  :
    this.isViewableSelected() ? this.deselectRows() : this.selectRows();
  }
  isViewableSelected() {
    return this.isAllSelected() || this.isAllDisplayedSelected();
  }

  deselectRows() {
    const itemsToBeUnselected = this.dataSource.connect().value;
    itemsToBeUnselected.forEach((element) => {
      var found = this.selection.selected.find((el) => el['Id'] === element.Id);
      this.selection.deselect(found);
    });
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
  }

  selectRows() {
    const currentlyDisplayedRows = this.dataSource.connect().value;

    for (let index = 0; index < currentlyDisplayedRows.length; index++) {
      this.selection.select(currentlyDisplayedRows[index]);
    }
  }

  exportAsXLSX(): void {
    this.token = this.state.user.User.Id;
    let filterString = '?parameterModel.uID=' + this.token;
    if (this.globalFirstName) {
      filterString += '&parameterModel.FirstName=' + this.globalFirstName;
    }
    if (this.globalLastName) {
      filterString += '&parameterModel.LastName=' + this.globalLastName;
    }
    if (this.globalEmail) {
      filterString += '&parameterModel.Email=' + this.globalEmail;
    }
    if (this.globalAccount) {
      filterString += '&parameterModel.clientid=' + this.globalAccount;
    }
    if (this.globalRole) {
      filterString += '&parameterModel.roleName=' + this.globalRole;
    }
    if (this.globalStatus) {
      filterString += '&parameterModel.approvalStatus=' + this.globalStatus;
    }
    if (this.sortColumn) {
      filterString += '&parameterModel.SortColumn=' + this.sortColumn;
    }
    let timeZone;
    var time = new Date();
    timeZone = this.timezone.transform(time);
    if (timeZone) {
      filterString += '&parameterModel.timeZone=' + timeZone;
    }
    this.exportData = this.globalService.UserExports + filterString;
  }

  resetUserdata() {
    var spinnerRef = this.spinnerService.start();
    this.AdminService.getFilteredUsers(1, this.pageSize, null, null, null, null, null, null, null).subscribe(
      (data) => {
        this.filterCount.length = 0;
        this.spinnerService.stop(spinnerRef);
        this.globalFirstName = null;
        this.globalLastName = null;
        this.globalEmail = null;
        this.globalAccount = null;
        this.globalRole = null;
        this.globalStatus = null;
        this.selectedAccountName = null;
        this.sortColumn = null;
        this.pageNumber = 1;
        this.pageSize = 10;
        this.info.globalFirstName = this.globalFirstName;
        this.info.selectedAccountName = this.selectedAccountName;
        this.info.globalLastName = this.globalLastName;
        this.info.globalEmail = this.globalEmail;
        this.info.globalAccount = this.globalAccount;
        this.info.globalRole = this.globalRole;
        this.info.globalStatus = this.globalStatus;
        this.info.sortColumn = this.sortColumn;
        this.info.pageNumber = this.pageNumber;
        this.info.pageSize = this.pageSize;
        this.observedata.updatebasicUserinfo(JSON.stringify(this.info));
        this.isLoading = false;
        this.state.isSpinnerVisible = true;
        this.addfilter = false;
        this.dataSource = data.Result;
        this.RecordCount = data.RecordCount;
        this.dataSource = new MatTableDataSource(data.Result);
        this.dataSource.sort = this.sort;
      },
      (error) => (this.isLoading = false)
    );

    this.showResetButton = false;
  }
  getuserdata() {
    if (!this.pageNumber || !this.pageSize) {
      this.pageNumber = 1;
      this.pageSize = 10;
    }
    var spinnerRef = this.spinnerService.start();
    // this.AdminService.getUsers(this.pageNumber, this.pageSize).subscribe(data => {
    this.AdminService.getFilteredUsers(
      this.pageNumber,
      this.pageSize,
      this.globalFirstName,
      this.globalLastName,
      this.globalEmail,
      this.globalAccount,
      this.globalRole,
      this.globalStatus,
      this.sortColumn
    ).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.isLoading = false;
        this.state.isSpinnerVisible = true;
        this.addfilter = false;
        this.filteredData = data;
        // this.dataSource = data.Result;
        // this.dataSource = data.Result[0].FirstName;
        this.RecordCount = data.RecordCount;
        // this.dataSource.data = data.Result;
        this.dataSource = new MatTableDataSource(data.Result);
        // this.dataSource = new MatTableDataSource(data.Result);
        this.dataSource.sort = this.sort;
        for (var i = 0; i < data.Result.length; i++) {
          this.userdetailss = data.Result[i].Clients;
          for (var j = 0; j < this.userdetailss.length; j++) {
            if (this.userdetailss[j].ClientCategory.length > 0) {
              this.userClientType = this.userdetailss[j].ClientCategory[0].Title;
            }
          }
        }
      },
      (error) => (this.isLoading = false)
    );
  }
  opentransferownershiplDialog() {
    const dialogRef = this.dialog.open(InviteuserdialogComponent, {
      width: '900px',
      // height: "450px",
      panelClass: 'custom-FilterInvitedialog-container',
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getuserdata();
        this.state.isSpinnerVisible = false;
      }
    });
  }
  edituserdetails() {
    const dialogRef = this.dialog.open(EdituserComponent, {
      width: '700px',
      height: '680px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getuserdata();
      this.state.isSpinnerVisible = false;
    });
  }
  closeAddFilter(closeData) {
    this.isLoading = true;
    if (closeData === 'firstName') {
      this.globalFirstName = null;
    } else if (closeData === 'lastName') {
      this.globalLastName = null;
    } else if (closeData === 'customerEmail') {
      this.globalEmail = null;
    } else if (closeData === 'selectedAccount') {
      this.globalAccount = null;
      this.selectedAccountName = null;
    } else if (closeData === 'selectedRole') {
      this.globalRole = null;
    } else if (closeData === 'selectedStatus') {
      this.globalStatus = null;
    }
    this.filterCount = [];
    const data = {
      FirstName: this.globalFirstName,
      LastName: this.globalLastName,
      Email: this.globalEmail,
      Account: this.globalAccount,
      Role: this.globalRole,
      Status: this.globalStatus,
    };
    for (const item in data) {
      if (data[item]) {
        if (data[item] !== null) {
          if (data[item] !== undefined) {
            this.filterCount.push(data[item]);
          }
        }
      }
    }
    var spinnerRef = this.spinnerService.start();
    this.info.globalStatus = this.globalStatus;
    this.info.globalRole = this.globalRole;
    this.info.globalEmail = this.globalEmail;
    this.info.globalAccount = this.globalAccount;
    this.info.globalLastName = this.globalLastName;
    this.info.globalFirstName = this.globalFirstName;
    this.info.selectedAccountName = this.selectedAccountName;
    this.observedata.updatebasicUserinfo(JSON.stringify(this.info));
    this.AdminService.getFilteredUsers(
      1,
      this.pageSize,
      this.globalFirstName,
      this.globalLastName,
      this.globalEmail,
      this.globalAccount,
      this.globalRole,
      this.globalStatus,
      this.sortColumn
    ).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.isLoading = false;
        this.dataSource = data.Result;
        this.dataSource = new MatTableDataSource(data.Result);
        for (var i = 0; i < data.Result.length; i++) {
          this.userdetails = data.Result[i];
          // this.Roles = this.userdetails.Roles[0].Name;
        }
        this.dataSource.sort = this.sort;
        this.RecordCount = data.RecordCount;
        this.listdata = data.Result;
        this.addfilter = true;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        this.isLoading = false;
        alert(JSON.stringify(error));
      }
    );
  }
  addeditfilter() {
    const dialogRef = this.dialog.open(AddeditfilterComponent, {
      disableClose: true,
      width: '820px',
      // height: "530px",
      panelClass: 'custom-Filterdialog-container',
      data: {
        FirstName: this.globalFirstName,
        LastName: this.globalLastName,
        Email: this.globalEmail,
        Account: this.globalAccount,
        Role: this.globalRole,
        Status: this.globalStatus,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.filteredData = result;
      if (this.filteredData !== undefined) {
        this.filterCount = [];
        for (const item in this.filteredData) {
          if (this.filteredData[item]) {
            if (this.filteredData[item] !== null) {
              if (this.filteredData[item] !== undefined && item !== 'selectedAccountName') {
                this.filterCount.push(this.filteredData[item]);
              }
            }
          }
        }

        var spinnerRef = this.spinnerService.start();
        this.globalStatus = this.filteredData.Status;
        this.globalRole = this.filteredData.Role;
        this.globalAccount = this.filteredData.Account;
        this.globalEmail = this.filteredData.Email;
        this.globalLastName = this.filteredData.LastName;
        this.globalFirstName = this.filteredData.FirstName;
        this.selectedAccountName = this.filteredData.selectedAccountName;

        this.info.selectedAccountName = this.selectedAccountName;
        this.info.globalStatus = this.globalStatus;
        this.info.globalRole = this.globalRole;
        this.info.globalEmail = this.globalEmail;
        this.info.globalAccount = this.globalAccount;
        this.info.globalLastName = this.globalLastName;
        this.info.globalFirstName = this.globalFirstName;
        this.observedata.updatebasicUserinfo(JSON.stringify(this.info));
        if (this.pageSize) {
          this.pageSize = 10;
        }
        this.AdminService.getFilteredUsers(
          1,
          this.pageSize,
          this.globalFirstName,
          this.globalLastName,
          this.globalEmail,
          this.globalAccount,
          this.globalRole,
          this.globalStatus,
          this.sortColumn
        ).subscribe(
          (data) => {
            this.spinnerService.stop(spinnerRef);
            this.dataSource = data.Result;
            this.dataSource = new MatTableDataSource(data.Result);
            for (var i = 0; i < data.Result.length; i++) {
              this.userdetails = data.Result[i];
              // this.Roles = this.userdetails.Roles[0].Name;
            }
            this.dataSource.sort = this.sort;
            this.RecordCount = data.RecordCount;
            this.listdata = data.Result;
            this.addfilter = true;
          },
          (error) => {
            this.state.isSpinnerVisible = false;
            this.isLoading = false;
            // alert(JSON.stringify(error));
            this.spinnerService.stop(spinnerRef);
          }
        );
      }
    });
  }
  filterClose() {
    this.filtermodal = false;
  }
  filterOpen() {
    this.filtermodal = true;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  redirectUserDetails(id) {
    this.buttonDisabled = true;
    this.router.navigate(['CustomerViewDetails/' + id.UserId + '']);
  }
  // Filter data
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  //pagination
  onChangePage(e) {
    var spinnerRef = this.spinnerService.start();
    e ? this.pageNumber = e : null;
    this.selectedPageSize = [10, 25, 50, 100];
    this.isLoading = true;
    this.info.pageNumber = this.pageNumber;
    this.info.pageSize = this.pageSize;
    this.observedata.updatebasicUserinfo(JSON.stringify(this.info));
    this.AdminService.getFilteredUsers(
      this.pageNumber,
      this.pageSize,
      this.globalFirstName,
      this.globalLastName,
      this.globalEmail,
      this.globalAccount,
      this.globalRole,
      this.globalStatus,
      this.sortColumn
    ).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.isLoading = false;
        this.dataSource = data.Result;
        this.dataSource = new MatTableDataSource(data.Result);
        for (var i = 0; i < data.Result.length; i++) {
          this.userdetails = data.Result[i];
          // this.Roles = this.userdetails.Roles[0].Name;
        }
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        this.RecordCount = data.RecordCount;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        this.spinnerService.stop(spinnerRef);
      }
    );
  }

  mapColumnName(columnName) {
    if (columnName == 'ID') {
      return 'UserId';
    }
    if (columnName == 'FirstName') {
      return 'FirstName';
    }
    if (columnName == 'LastName') {
      return 'LastName';
    }
    if (columnName == 'Email') {
      return 'Email';
    }
    if (columnName == 'Account') {
      return 'Client';
    }
    if (columnName == 'Role') {
      return 'RoleName';
    }
    if (columnName == 'Status') {
      return 'ApprovalStatus';
    }
  }

  applyInputFilter(type, event) {
    if (type === 'globalFirstName') {
      this.handleTableFilter(type, event.target.value);
    }

    if (type === 'globalLastName') {
      this.handleTableFilter(type, event.target.value);
    }

    if (type === 'globalEmail') {
      this.handleTableFilter(type, event.target.value);
    }
  }

  handleTableFilter = (category, value) => {
    if (category === 'globalRole') {
      this.globalRole = value;
    }

    if (category === 'globalFirstName') {
      this.globalFirstName = value;
    }

    if (category === 'globalLastName') {
      this.globalLastName = value;
    }

    if (category === 'globalEmail') {
      this.globalEmail = value;
    }

    if (category === 'globalAccount') {
      this.selectedAccountName = value;
      if (value === '') {
        this.globalAccount = value;
      } else {
        const account = this.ClientList.find((client) => client.Name === value);

        if (account && value !== '') {
          this.globalAccount = account.ClientId;
        }
      }
    }

    if (category === 'globalStatus') {
      this.globalStatus = value;
    }

    var spinnerRef = this.spinnerService.start();
    // this.globalStatus = this.filteredData.Status;
    // this.globalRole = this.filteredData.Role;
    // this.globalAccount = this.filteredData.Account;
    // this.globalEmail = this.filteredData.Email;
    // this.globalLastName = this.filteredData.LastName;
    // this.globalFirstName = this.filteredData.FirstName;
    // this.selectedAccountName = this.filteredData.selectedAccountName;

    this.info.selectedAccountName = this.selectedAccountName;
    this.info.globalStatus = this.globalStatus;
    this.info.globalRole = this.globalRole;
    this.info.globalEmail = this.globalEmail;
    this.info.globalAccount = this.globalAccount;
    this.info.globalLastName = this.globalLastName;
    this.info.globalFirstName = this.globalFirstName;
    this.pageNumber = 1;
    this.info.pageNumber = this.pageNumber;
    this.observedata.updatebasicUserinfo(JSON.stringify(this.info));
    if (this.pageSize || this.pageSize == 0) {
      this.pageSize = 10;
    }
    this.AdminService.getFilteredUsers(
      this.pageNumber,
      this.pageSize,
      this.globalFirstName,
      this.globalLastName,
      this.globalEmail,
      this.globalAccount,
      this.globalRole,
      this.globalStatus,
      this.sortColumn
    ).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.dataSource = data.Result;
        this.dataSource = new MatTableDataSource(data.Result);
        for (var i = 0; i < data.Result.length; i++) {
          this.userdetails = data.Result[i];
          // this.Roles = this.userdetails.Roles[0].Name;
        }
        this.dataSource.sort = this.sort;
        this.RecordCount = data.RecordCount;
        this.listdata = data.Result;
        this.addfilter = true;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        this.isLoading = false;
        // alert(JSON.stringify(error));
        this.spinnerService.stop(spinnerRef);
      }
    );

    this.checkFilters();
  };
  sortTable(event) {
    this.isLoading = true;
    var spinnerRef = this.spinnerService.start();
    this.sortColumn = this.mapColumnName(event.active);
    if (this.sortColumn != null) {
      if (event.direction === 'desc') {
        this.sortColumn = '-' + this.sortColumn;
      }
      this.info.sortColumn = this.sortColumn;
      // store the  sortColumn data to observble

      if (!this.pageSize || this.pageSize == 0) {
        this.pageSize = 10;
      }
      
      this.observedata.updatebasicUserinfo(JSON.stringify(this.info));
      this.AdminService.getFilteredUsers(
        this.pageNumber,
        this.pageSize,
        this.globalFirstName,
        this.globalLastName,
        this.globalEmail,
        this.globalAccount,
        this.globalRole,
        this.globalStatus,
        this.sortColumn
      ).subscribe(
        (data) => {
          this.spinnerService.stop(spinnerRef);
          this.isLoading = false;
          this.dataSource = data.Result;
          this.dataSource = new MatTableDataSource(data.Result);
          for (var i = 0; i < data.Result.length; i++) {
            this.userdetails = data.Result[i];
            // this.Roles = this.userdetails.Roles[0].Name;
          }
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.RecordCount = data.RecordCount;
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
          this.spinnerService.stop(spinnerRef);
        }
      );
    }
  }

  checkFilters() {
    if (this.globalFirstName || this.globalLastName || this.globalEmail || this.globalAccount || this.globalRole || this.globalStatus) {
      this.showResetButton = true;
    } else {
      this.showResetButton = false;
    }
  }
}
