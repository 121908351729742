import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CustomMaterialModule } from './core/material.module';
import { AppRoutingModule } from './core/app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LoginComponent } from './loginmodule/login/login.component';
import { UserComponent } from './dashboard/user/user/user.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotDialogComponent } from './loginmodule/forgot-dialog/forgot-dialog.component';
import { MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RecoveryDialogComponent } from './loginmodule/recovery-dialog/recovery-dialog.component';
import { ResetDialogComponent } from './loginmodule/reset-dialog/reset-dialog.component';
import { ForgotSuccessDialogComponent } from './loginmodule/forgot-success-dialog/forgot-success-dialog.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { LogoHeaderComponent } from './dashboard/templates/logo-header/logo-header.component';
import { RequestPickupComponent } from './dashboard/Request_pickup/request-pickup/request-pickup.component';
import { HomeComponent } from './dashboard/home/home.component';
import { PickupsComponent } from './dashboard/pickups/pickups.component';
import { CustomersComponent } from './dashboard/customers/customers.component';
import { ProfileSetingsComponent } from './dashboard/profileinfo/profile-setings/profile-setings.component';
import { PassworddialogComponent } from './dashboard/profileinfo/passworddialog/passworddialog.component';
import { EmailchangedialogComponent } from './dashboard/profileinfo/emailchangedialog/emailchangedialog.component';
import { NumberchangedialogComponent } from './dashboard/profileinfo/numberchangedialog/numberchangedialog.component';
import { TransferownershipdialogComponent } from './dashboard/profileinfo/transferownershipdialog/transferownershipdialog.component';
import { TrackpickupComponent } from './dashboard/Track_Pickup/trackpickup/trackpickup.component';
import { DeleteaccountdialogComponent } from './dashboard/profileinfo/deleteaccountdialog/deleteaccountdialog.component';
import { RemoveaccountdialogComponent } from './dashboard/profileinfo/removeaccountdialog/removeaccountdialog.component';
import { RequestPickupMenuComponent } from './dashboard/Request_pickup/request-pickup-menu/request-pickup-menu.component';
import { NoPickActiveDialogComponent } from './dashboard/Track_Pickup/no-pick-active-dialog/no-pick-active-dialog.component';
import { EditphotodialogComponent } from './dashboard/profileinfo/editphotodialog/editphotodialog.component';
import { ActivebreadcrumbComponent } from './dashboard/templates/activebreadcrumb/activebreadcrumb.component';
import { MenuService } from './core/menu.service';
import { TitledialogComponent } from './dashboard/profileinfo/profile-setings/titledialog/titledialog.component';
import { InviteuserdialogComponent } from './dashboard/user/inviteuserdialog/inviteuserdialog.component';
import { EdituserComponent } from './dashboard/user/edituser/edituser.component';
import { EditUserDetailsComponent } from './dashboard/user/editUserDetails/editUserDetails.component';
import { AddeditfilterComponent } from './dashboard/user/addeditfilter/addeditfilter.component';
import { PickupsFilterComponent } from './dashboard/pickups/pickupsFilter/pickupsFilter.component';
import { CustomerFilterComponent } from './dashboard/customers/customerFilter/customerFilter.component';
import { GlobalService } from './shared/app.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppState } from './shared/app-state';
import { AdmintoolsService } from './shared/services/admintools.service';
import { EditDashboardComponent } from './dashboard/edit-dashboard/edit-dashboard.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditDashboardDialogComponent } from './dashboard/edit-dashboard-dialog/edit-dashboard-dialog.component';
import { DeletedialogComponent } from './dashboard/deletedialog/deletedialog.component';
import { CustomerFacilityDetailsComponent } from './dashboard/customer-facility-details/customer-facility-details.component';
import { CustomerFacilitatorViewComponent } from './dashboard/customer-facilitator-view/customer-facilitator-view.component';
import { EditBusinessDialogComponent } from './dashboard/edit-business-dialog/edit-business-dialog.component';
import { MenuViewComponent } from './dashboard/menu-view/menu-view.component';
import { FacilityAccountDialogComponent } from './dashboard/facility-account-dialog/facility-account-dialog.component';
import { ImportMenuDialogComponent } from './dashboard/import-menu-dialog/import-menu-dialog.component';
import { CreateMenuDialogComponent } from './dashboard/create-menu-dialog/create-menu-dialog.component';
import { CustomerCreateMenuDialogComponent } from './dashboard/create-menu-dialog/customer-create-menu-dialog/customer-create-menu-dialog.component';
import { UpoloadMenuDialogComponent } from './dashboard/upoload-menu-dialog/upoload-menu-dialog.component';
import { UnsuccessfulMenuDialogComponent } from './dashboard/unsuccessful-menu-dialog/unsuccessful-menu-dialog.component';
import { SuccessfulMenuDialogComponent } from './dashboard/successful-menu-dialog/successful-menu-dialog.component';
import { AccountcompletionMenuDialogComponent } from './dashboard/accountcompletion-menu-dialog/accountcompletion-menu-dialog.component';
import { AccountCreationDialogComponent } from './dashboard/account-creation-dialog/account-creation-dialog.component';
import { AddCategoryDialogComponent } from './dashboard/menu-view/add-category-dialog/add-category-dialog.component';
import { CreatePickuplistComponent } from './dashboard/Request_pickup/create-pickuplist/create-pickuplist.component';
import { CustomerProfileSettingsComponent } from './dashboard/profile-setting-view/profile-setting-view.component';
import { ConfirmPickupListComponent } from './dashboard/Request_pickup/confirm-pickup-list/confirm-pickup-list.component';
import { SavePickupmodalComponent } from './dashboard/Request_pickup/save-pickupmodal/save-pickupmodal.component';
import { PickupRequestSubmissionComponent } from './dashboard/Request_pickup/pickup-request-submission/pickup-request-submission.component';
import { usersAdminViewComponent } from './dashboard/usersAdminView/usersAdminView/usersAdminView.component';
import { PickupDetailsComponent } from './dashboard/pickup-details/pickup-details.component';

import { SavedPickupTemplateComponent } from './dashboard/Request_pickup/saved-pickup-template/saved-pickup-template.component';
import { EditPickupTemplateComponent } from './dashboard/Request_pickup/edit-pickup-template/edit-pickup-template.component';
import { SavePickupListmodalComponent } from './dashboard/Request_pickup/save-pickup-listmodal/save-pickup-listmodal.component';
import { PickupCancellationModalComponent } from './dashboard/Track_Pickup/pickup-cancellation-modal/pickup-cancellation-modal.component';
import { TrackPickupListComponent } from './dashboard/Track_Pickup/track-pickup-list/track-pickup-list.component';
import { PickupInRouteComponent } from './dashboard/Track_Pickup/pickup-in-route/pickup-in-route.component';
import { TrackRoutePickedUpComponent } from './dashboard/Track_Pickup/track-route-picked-up/track-route-picked-up.component';

import { DatePickerComponent } from './dashboard/templates/dateTimePicker/datePicker/datePicker.component';
import { TimePickerComponent } from './dashboard/templates/dateTimePicker/timePicker/timePicker.component';
import { ImpactComponent } from './dashboard/impact/impact/impact.component';
import { CustomerViewDetailsComponent } from './dashboard/customer-main-view/customer-main-view.component';
import { PickupOrderCompleteComponent } from './dashboard/Track_Pickup/pickup-order-complete/pickup-order-complete.component';
import { EditCustomerBusinessDialogComponent } from './dashboard/customers/edit-customer-business-dialog/edit-customer-business-dialog.component';
import { DeleteUserDialogComponent } from './dashboard/delete-user-dialog-component/delete-user-dialog-component';
import { AddeditDateComponent } from './dashboard/customers/addedit-date/addedit-date.component';
import { dateFormatPipe } from './shared/pipes/date.service';
import { timeFormatPipe } from './shared/pipes/time.service';
import { TruncatePipe } from './shared/pipes/truncate.service';
import { modalDataService } from './shared/services/modalData.service';
import { AddMainCategoryDialogComponent } from './dashboard/menu-view/add-main-category-dialog/add-main-category-dialog.component';
import { DeleteProductDialogComponent } from './dashboard/menu-view/delete-product-dialog/delete-product-dialog.component';
import { BackgroundPageComponent } from './loginmodule/password-page/background-page.component';
import { PasswordComponent } from './loginmodule/password-page/password-dialog/password-dialog.component';
import { PasswordSuccessDialogComponent } from './loginmodule/password-page/password-dialog/password-success-dialog/password-success-dialog.component';
import { PhoneFilterPipe } from './shared/pipes/phone.service';
import { InviteUserSuccessDialogComponent } from './dashboard/usersAdminView/invite-user-success-dialog/invite-user-success-dialog.component';
import { DeleteCategoryDialogComponent } from './dashboard/menu-view/delete-category-dialog/delete-category-dialog.component';
import { from } from 'rxjs';
import { DeleteUserComponent } from './dashboard/user/delete-user/delete-user.component';
import { LoaderService } from './shared/services/loader.service';
import { DeleteFacilitatorAccountComponent } from './dashboard/dashboard/delete-facilitator-account/delete-facilitator-account.component';
import { DateCalendarComponent } from './dashboard/templates/dateTimePicker/dateCalendar/dateCalendar.component';
import { MyOrderByPipe } from './shared/pipes/sort.pipe';
import { TaxnumberService } from './shared/pipes/taxnumber.service';
import { MatTooltipModule } from '@angular/material';
import { ResetPasswordSuccessComponent } from './dashboard/user/reset-password-success/reset-password-success.component';
import { GrabreportComponent } from './dashboard/customers/grabreport/grabreport.component';
import { GenericProductsComponent } from './dashboard/menu-view/generic-products/generic-products.component';
import { UpdateCategoryDialogComponent } from './dashboard/menu-view/update-category-dialog/update-category-dialog.component';
import { MaskDirective } from './shared/pipes/mask';
import { TaxDirective } from './shared/pipes/taxformat';
import { specialPipe } from './shared/pipes/specialpipe';
import { ClientFilterService } from './shared/services/clientFilter.service';
import { DeleteGrabMenuComponent } from './dashboard/menu-view/delete-grab-menu/delete-grab-menu.component';
import { UpdateCategoryDataDialogComponent } from './dashboard/menu-view/update-category-data-dialog/update-category-data-dialog.component';
import { DonationRecepitsComponent } from './dashboard/pickup-details/donation-recepits/donation-recepits.component';
import { PickupeditableComponent } from './dashboard/pickups/pickupeditable/pickupeditable.component';
import { EditMenuComponent } from './dashboard/pickups/edit-menu/edit-menu.component';
import { SaveEditMenumodalComponent } from './dashboard/pickups/save-edit-menumodal/save-edit-menumodal.component';
import { DeleteMenuComponent } from './dashboard/pickups/delete-menu/delete-menu.component';
import { DatePostFormatPipe } from './shared/pipes/dateformat.service';
import { BlockCopyPasteDirective } from './shared/pipes/block-copy-paste.directive';
import { ProgressSpinnerModule, ProgressSpinnerComponent } from '../app/core/progress-spinner/progress-spinner.module';
import { ModalSpinnerComponent } from './dashboard/customers/modal-spinner/modal-spinner.component';
import { SpinnerService } from './core/spinner.service';
import { CharIndexPipe } from './shared/pipes/charIndex.service';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TimeSelectDialogComponent } from './time-select-dialog/time-select-dialog.component';
import { SAASsignUpComponent } from './SAAS/regModule/saassign-up/saassign-up.component';
import { SAASHeaderComponent } from './dashboard/templates/saas-header/saas-header.component';
import { SubscriptionComponent } from './SAAS/regModule/subscription/subscription.component';
import { BillingComponent } from './SAAS/regModule/billing/billing.component';
import { CheckoutComponent } from './SAAS/regModule/checkout/checkout.component';
import { ReceiptSuccessComponent } from './SAAS/regModule/receipt-success/receipt-success.component';
import { EditSubscriptionComponent } from './SAAS/regModule/edit-subscription/edit-subscription.component';
import { Time24to12Pipe } from './shared/pipes/time24to12.pipe';
import { ChangeeditSusbscriptionComponent } from './SAAS/regModule/changeedit-susbscription/changeedit-susbscription.component';
import { UpdateCheckoutComponent } from './SAAS/regModule/update-checkout/update-checkout.component';
import { SuccesPopupComponent } from './SAAS/regModule/succes-popup/succes-popup.component';
import { DeletePopupComponent } from './SAAS/regModule/billing/delete-popup/delete-popup.component';
import { SaasconfirmComponent } from './saasconfirm/saasconfirm.component';
import { EditMYearlyComponent } from './dashboard/customers/edit-myearly/edit-myearly.component';
import { EditbillingComponent } from './dashboard/customers/editbilling/editbilling.component';
import { EditcheckoutComponent } from './dashboard/customers/editcheckout/editcheckout.component';
import { PaysuccessfulComponent } from './dashboard/customers/paysuccessful/paysuccessful.component';
import { ActivebreadcrumnforbillingComponent } from './dashboard/templates/activebreadcrumnforbilling/activebreadcrumnforbilling.component';
import { AddfacilityfgComponent } from './dashboard/customers/addfacilityfg/addfacilityfg.component';
import { RegistrationComponent } from './SAAS/regModule/onetimebilling/registration/registration.component';
import { OnetimebillingcardpaymentComponent } from './SAAS/regModule/onetimebilling/onetimebillingcardpayment/onetimebillingcardpayment.component';
import { OnetimebillingsuccessComponent } from './SAAS/regModule/onetimebilling/onetimebillingsuccess/onetimebillingsuccess.component';
import { EditfacilityComponent } from './SAAS/regModule/saassign-up/editfacility/editfacility.component';
import { OnetimepickuprequestComponent } from './dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickuprequest.component';
import { OnetimepickupbillrequestComponent } from './dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupbillrequest/onetimepickupbillrequest.component';
import { OnetimepickupbillcardpayComponent } from './dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupbillcardpay/onetimepickupbillcardpay.component';
import { OnetimepickupbillsuccessComponent } from './dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupbillsuccess/onetimepickupbillsuccess.component';
import { OnetimepickupregistrationComponent } from './dashboard/Request_pickup/pickup-request-submission/onetimepickuprequest/onetimepickupregistration/onetimepickupregistration.component';
import { HelpScreenComponent } from './dashboard/templates/help-screen/help-screen.component';
import { BackgroundotpComponent } from './SAAS/regModule/onetimebilling/backgroundotp/backgroundotp.component';
import { BackgroundOTPPasswordComponent } from './SAAS/regModule/onetimebilling/background-otppassword/background-otppassword.component';
import { SaasconfirmmsgComponent } from './dashboard/customers/saasconfirmmsg/saasconfirmmsg.component';
import { PricingModuleComponent } from './pricingModule/pricing-module/pricing-module.component';
import { SaasPricingModuleComponent } from './pricingModule/Saas/saas-pricing-module/saas-pricing-module.component';
import { OtpPricingModuleComponent } from './pricingModule/Otp/otp-pricing-module/otp-pricing-module.component';
import { OtpPricingCreatePopupComponent } from './pricingModule/Otp/otp-pricing-create-popup/otp-pricing-create-popup.component';
import { OtpPricingEditPopupComponent } from './pricingModule/Otp/otp-pricing-edit-popup/otp-pricing-edit-popup.component';
import { OtpPricingDeletePopupComponent } from './pricingModule/Otp/otp-pricing-delete-popup/otp-pricing-delete-popup.component';
import { PromotionCodePricingComponent } from './pricingModule/promotionCode/promotion-code-pricing/promotion-code-pricing.component';
import { PromotionCodePricingCreateComponent } from './pricingModule/promotionCode/promotion-code-pricing-create/promotion-code-pricing-create.component';
import { PromotionCodePricingEditComponent } from './pricingModule/promotionCode/promotion-code-pricing-edit/promotion-code-pricing-edit.component';
import { PromotionCodePricingDeleteComponent } from './pricingModule/promotionCode/promotion-code-pricing-delete/promotion-code-pricing-delete.component';
import { ErrorpageComponent } from './dashboard/templates/help-screen/errorpage/errorpage.component';
import { FeatureTogglesGuard } from './shared/services/feature-toggles.guard';
import { SAASFeatureGuard } from './shared/services/saasfeature.guard';
import { EditToggleFeatureGuard } from './shared/services/edit-toggle-feature.guard';
import { PrcingControlFeatureGuard } from './shared/services/prcing-control-feature.guard';
import { OrderbyLastMessagePipePipe } from './shared/pipes/orderby-last-message-pipe.pipe';
import { DateDispalyFormatPipeService } from './shared/pipes/date-dispaly-format-pipe.service';
import { TimezoneService } from './shared/pipes/timezone.service';
import { TimezonedatepostService } from './shared/pipes/timezonedatepost.service';
import { TimeZoneabbrService } from './shared/pipes/time-zoneabbr.service';
import { EstpostformatService } from './estpostformat.service';
import { PickupsRequestComponent } from './dashboard/pickups/pickups-request/pickups-request.component';
import { FeaturetoggleComponent } from './dashboard/templates/featuretoggle/featuretoggle.component';
import { ConfirmPickupsRequestDialogComponent } from './dashboard/pickups/confirm-pickups-request-dialog/confirm-pickups-request-dialog.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { DateTimePickerCombinedComponent } from './dashboard/templates/date-time-picker-combined/date-time-picker-combined.component';
import { ShowGifDialogComponent } from './dashboard/templates/help-screen/show-gif-dialog/show-gif-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BillingSectionComponent } from './dashboard/billing-section/billing-section.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RoleGuardFeatureGuard } from './shared/services/role-guard.service';
import { DistanceMatrixPageComponent } from './dashboard/distance-matrix-page/distance-matrix-page.component';
import { PickupContractComponent } from './dashboard/pickup-contract/pickup-contract.component';
import { AddContractDialogComponent } from './dashboard/add-contract-dialog/add-contract-dialog.component';
import { EditContractDialogComponent } from './dashboard/edit-contract-dialog/edit-contract-dialog.component';
import { EditShipmentDialogComponent } from './dashboard/edit-shipment-dialog/edit-shipment-dialog.component';
import { AlphabetOnlyDirective } from './shared/pipes/alphabet-only-directive';
import { NumbersOnlyDirective } from './shared/pipes/numbers-only-directive';
import { NumbersWithDecimalOnlyDirective } from './shared/pipes/numbers-with-decimal-directive';
import { BillingInvoicesComponent } from './dashboard/billing-invoices/billing-invoices.component';
import { EditInvoiceDialogComponent } from './dashboard/edit-invoice-dialog/edit-invoice-dialog.component';
import { dateOnlyWithTimezone } from './shared/pipes/dateOnlyWithTimezone.service';
import { AlertDialogComponent } from './dialog/alert-dialog/alert-dialog.component';
import { LightboxModule } from 'ngx-lightbox';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
  declarations: [
    TruncatePipe,
    specialPipe,
    MaskDirective,
    TaxDirective,
    dateFormatPipe,
    EstpostformatService,
    DatePostFormatPipe,
    TimeZoneabbrService,
    TimezonedatepostService,
    DateDispalyFormatPipeService,
    TimezoneService,
    timeFormatPipe,
    PhoneFilterPipe,
    CharIndexPipe,
    AppComponent,
    LoginComponent,
    UserComponent,
    [MyOrderByPipe],
    ForgotDialogComponent,
    RecoveryDialogComponent,
    ResetDialogComponent,
    ForgotSuccessDialogComponent,
    DashboardComponent,
    LogoHeaderComponent,
    BlockCopyPasteDirective,
    RequestPickupComponent,
    HomeComponent,
    PickupsComponent,
    CustomersComponent,
    ImpactComponent,
    ProfileSetingsComponent,
    PassworddialogComponent,
    EmailchangedialogComponent,
    NumberchangedialogComponent,
    TransferownershipdialogComponent,
    TrackpickupComponent,
    DeleteaccountdialogComponent,
    RemoveaccountdialogComponent,
    RequestPickupMenuComponent,
    NoPickActiveDialogComponent,
    EditphotodialogComponent,
    ActivebreadcrumbComponent,
    TitledialogComponent,
    InviteuserdialogComponent,
    EdituserComponent,
    EditUserDetailsComponent,
    AddeditfilterComponent,
    PickupsFilterComponent,
    CustomerFilterComponent,
    EditDashboardComponent,
    EditDashboardDialogComponent,
    DeletedialogComponent,
    CustomerFacilityDetailsComponent,
    CustomerFacilitatorViewComponent,
    EditBusinessDialogComponent,
    MenuViewComponent,
    FacilityAccountDialogComponent,
    ImportMenuDialogComponent,
    CreateMenuDialogComponent,
    CustomerCreateMenuDialogComponent,
    UpoloadMenuDialogComponent,
    UnsuccessfulMenuDialogComponent,
    SuccessfulMenuDialogComponent,
    AccountcompletionMenuDialogComponent,
    AccountCreationDialogComponent,
    CustomerViewDetailsComponent,
    AddCategoryDialogComponent,
    CreatePickuplistComponent,
    CustomerProfileSettingsComponent,
    ConfirmPickupListComponent,
    SavePickupmodalComponent,
    PickupRequestSubmissionComponent,
    usersAdminViewComponent,
    SavedPickupTemplateComponent,
    EditPickupTemplateComponent,
    SavePickupListmodalComponent,
    PickupDetailsComponent,
    SavedPickupTemplateComponent,
    AlertDialogComponent,
    DatePickerComponent,
    DateCalendarComponent,
    TimePickerComponent,
    PickupCancellationModalComponent,
    TrackPickupListComponent,
    PickupInRouteComponent,
    TrackRoutePickedUpComponent,
    PickupOrderCompleteComponent,
    EditCustomerBusinessDialogComponent,
    DeleteUserDialogComponent,
    AddeditDateComponent,
    AddMainCategoryDialogComponent,
    DeleteProductDialogComponent,
    BackgroundPageComponent,
    PasswordComponent,
    PasswordSuccessDialogComponent,
    InviteUserSuccessDialogComponent,
    DeleteCategoryDialogComponent,
    DeleteUserComponent,
    DeleteFacilitatorAccountComponent,
    TaxnumberService,
    ResetPasswordSuccessComponent,
    GrabreportComponent,
    GenericProductsComponent,
    UpdateCategoryDialogComponent,
    DeleteGrabMenuComponent,
    UpdateCategoryDataDialogComponent,
    DonationRecepitsComponent,
    PickupeditableComponent,
    EditMenuComponent,
    SaveEditMenumodalComponent,
    DeleteMenuComponent,
    ModalSpinnerComponent,
    TimeSelectDialogComponent,
    SAASsignUpComponent,
    SAASHeaderComponent,
    SubscriptionComponent,
    BillingComponent,
    CheckoutComponent,
    ReceiptSuccessComponent,
    EditSubscriptionComponent,
    Time24to12Pipe,
    ChangeeditSusbscriptionComponent,
    UpdateCheckoutComponent,
    SuccesPopupComponent,
    DeletePopupComponent,
    SaasconfirmComponent,
    EditMYearlyComponent,
    EditbillingComponent,
    EditcheckoutComponent,
    PaysuccessfulComponent,
    ActivebreadcrumnforbillingComponent,
    AddfacilityfgComponent,
    RegistrationComponent,
    OnetimebillingcardpaymentComponent,
    OnetimebillingsuccessComponent,
    EditfacilityComponent,
    OnetimepickuprequestComponent,
    OnetimepickupbillrequestComponent,
    OnetimepickupbillcardpayComponent,
    OnetimepickupbillsuccessComponent,
    OnetimepickupregistrationComponent,
    HelpScreenComponent,
    BackgroundotpComponent,
    BackgroundOTPPasswordComponent,
    SaasconfirmmsgComponent,
    PricingModuleComponent,
    SaasPricingModuleComponent,
    OtpPricingModuleComponent,
    OtpPricingCreatePopupComponent,
    OtpPricingEditPopupComponent,
    OtpPricingDeletePopupComponent,
    PromotionCodePricingComponent,
    PromotionCodePricingCreateComponent,
    PromotionCodePricingEditComponent,
    PromotionCodePricingDeleteComponent,
    ErrorpageComponent,
    OrderbyLastMessagePipePipe,
    PickupsRequestComponent,
    FeaturetoggleComponent,
    ConfirmPickupsRequestDialogComponent,
    DateTimePickerCombinedComponent,
    ShowGifDialogComponent,
    BillingSectionComponent,
    DistanceMatrixPageComponent,
    PickupContractComponent,
    AddContractDialogComponent,
    EditContractDialogComponent,
    EditShipmentDialogComponent,
    AlphabetOnlyDirective,
    NumbersOnlyDirective,
    NumbersWithDecimalOnlyDirective,
    BillingInvoicesComponent,
    EditInvoiceDialogComponent,
    dateOnlyWithTimezone
  ],

  imports: [
    MatProgressSpinnerModule,
    PDFExportModule,
    MatTooltipModule,
    HttpClientModule,
    BrowserModule,
    [FlexLayoutModule],
    DragDropModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    CustomMaterialModule,
    NgxMatSelectSearchModule,
    FormsModule,
    MatDialogModule,
    AppRoutingModule,
    ProgressSpinnerModule,
    MDBBootstrapModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxPaginationModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    LightboxModule,
    GooglePlaceModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [
    ForgotDialogComponent,
    AlertDialogComponent,
    RecoveryDialogComponent,
    ResetDialogComponent,
    ForgotSuccessDialogComponent,
    PassworddialogComponent,
    EmailchangedialogComponent,
    DeleteUserDialogComponent,
    NumberchangedialogComponent,
    TransferownershipdialogComponent,
    EditDashboardDialogComponent,
    DeleteaccountdialogComponent,
    RemoveaccountdialogComponent,
    NoPickActiveDialogComponent,
    EditphotodialogComponent,
    TitledialogComponent,
    InviteuserdialogComponent,
    EdituserComponent,
    AddeditfilterComponent,
    PickupsFilterComponent,
    CustomerFilterComponent,
    EdituserComponent,
    ModalSpinnerComponent,
    EditUserDetailsComponent,
    SavePickupmodalComponent,
    SavePickupListmodalComponent,
    DeletedialogComponent,
    ProgressSpinnerComponent,
    usersAdminViewComponent,
    EditCustomerBusinessDialogComponent,
    EditBusinessDialogComponent,
    TimeSelectDialogComponent,
    FacilityAccountDialogComponent,
    ImportMenuDialogComponent,
    CreateMenuDialogComponent,
    CustomerCreateMenuDialogComponent,
    UpoloadMenuDialogComponent,
    UnsuccessfulMenuDialogComponent,
    SuccessfulMenuDialogComponent,
    AccountcompletionMenuDialogComponent,
    AccountCreationDialogComponent,
    AddCategoryDialogComponent,
    PickupCancellationModalComponent,
    AddeditDateComponent,
    AddMainCategoryDialogComponent,
    DeleteProductDialogComponent,
    BackgroundPageComponent,
    PasswordComponent,
    PasswordSuccessDialogComponent,
    InviteUserSuccessDialogComponent,
    DeleteCategoryDialogComponent,
    SuccesPopupComponent,
    DeleteUserComponent,
    DeleteFacilitatorAccountComponent,
    ResetPasswordSuccessComponent,
    UpdateCategoryDialogComponent,
    UpdateCategoryDataDialogComponent,
    DeleteGrabMenuComponent,
    PickupeditableComponent,
    PickupsRequestComponent,
    ConfirmPickupsRequestDialogComponent,
    EditMenuComponent,
    SaveEditMenumodalComponent,
    DeleteMenuComponent,
    DeletePopupComponent,
    SaasconfirmComponent,
    SaasconfirmmsgComponent,
    EditMYearlyComponent,
    EditbillingComponent,
    EditcheckoutComponent,
    PaysuccessfulComponent,
    AddfacilityfgComponent,
    RegistrationComponent,
    OnetimebillingcardpaymentComponent,
    OnetimebillingsuccessComponent,
    EditfacilityComponent,
    OnetimepickuprequestComponent,
    OnetimepickupbillrequestComponent,
    OnetimepickupbillcardpayComponent,
    OnetimepickupbillsuccessComponent,
    OnetimepickupregistrationComponent,
    HelpScreenComponent,
    BackgroundotpComponent,
    BackgroundOTPPasswordComponent,
    OtpPricingCreatePopupComponent,
    OtpPricingEditPopupComponent,
    OtpPricingDeletePopupComponent,
    PromotionCodePricingCreateComponent,
    PromotionCodePricingEditComponent,
    PromotionCodePricingDeleteComponent,
    ShowGifDialogComponent,
    AddContractDialogComponent,
    EditContractDialogComponent,
    EditShipmentDialogComponent, 
    EditInvoiceDialogComponent
  ],
  providers: [
    AppState,
    MenuService,
    GlobalService,
    AdmintoolsService,
    modalDataService,
    ClientFilterService,
    SpinnerService,
    LoaderService,
    MyOrderByPipe,
    DatePostFormatPipe,
    TimeZoneabbrService,
    specialPipe,
    TimezonedatepostService,
    DateDispalyFormatPipeService,
    TimezoneService,
    dateFormatPipe,
    EstpostformatService,
    timeFormatPipe,
    FeatureTogglesGuard,
    SAASFeatureGuard,
    EditToggleFeatureGuard,
    PrcingControlFeatureGuard,
    OrderbyLastMessagePipePipe,
    RoleGuardFeatureGuard,
    PhoneFilterPipe,
    dateOnlyWithTimezone
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
