import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { ɵNullViewportScroller } from '@angular/common';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from 'src/app/shared/services/profilesetting.service';
import { AppState } from 'src/app/shared/app-state';
import { AccountService } from 'src/app/shared/account.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { EstpostformatService } from 'src/app/estpostformat.service';
import { SpinnerService } from 'src/app/core/spinner.service';

@Component({
  selector: 'app-editphotodialog',
  templateUrl: './editphotodialog.component.html',
  styleUrls: ['./editphotodialog.component.scss'],
})
export class EditphotodialogComponent implements OnInit {
  fileToupload: File = null;
  returnphoto: any;
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  editCustomer: boolean;
  editprofile: boolean;
  File: string;
  base64textString: string;
  DriverServices: any;
  buttonDisabled: boolean;
  driverSelected: any;
  profileBase: string;
  profileImageUrl: any;
  ClientCategoryId: any;
  user: any;
  customer: any;
  ImageUrlGrabData: string;
  ImageUrlData: string;
  profileSubmit: boolean;
  result: any;
  relaodinfo: any;
  showMaxSizeError: boolean;
  imageSizeError: any;

  constructor(
    private CustomerService: CustomerService,
    private spinnerService: SpinnerService,
    private formBuilder: FormBuilder,
    public profileService: ProfileService,
    private state: AppState,
    public dialogRef: MatDialogRef<EditphotodialogComponent>,
    private accountService: AccountService,
    private _rdata: modalDataService,
    public estdatepostFormat: EstpostformatService,
    private customerServices: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private sanitizer: DomSanitizer
  ) { }
  onNoClick(): void {
    this.dialogRef.close(this.profileImageUrl);
  }
  ngOnInit() {
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
    });
    this.profileSubmit = true;
    this.user = this.state.user;
    // console.log(this.data[1]);
    if (this.data[1] === undefined) {
      this.editCustomer = false;
      this.editprofile = true;
    } else {
      this.editCustomer = true;
      this.editprofile = false;
    }
    this.profileImageUrl = this.data[0].user_photo;
    if (this.data[0].user_photo === undefined) {
      this.profileImageUrl = 'assets/images/logo.png';
    } else {
      this.profileImageUrl = this.data[0].user_photo;
    }
  }
  handleFileSelect(evt) {
    let eve = this;
    var files = evt.target.files;
    var file = files[0];
    var imageSize = file.size / 1024;
    if (imageSize > 2000) {
      this.showMaxSizeError = true;
      this.imageSizeError = 'Image size must be upto 2MB';
      setTimeout(() => {
        this.showMaxSizeError = false;
        this.imageSizeError = '';
      }, 3000);
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = reader.result.toString();
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          // if (height > 100 || width > 100) {
          //   alert("Height and Width must not exceed 100px.");
          //   return false;
          // } else {
          if (files && file) {
            var reader = new FileReader();
            reader.onload = eve._handleReaderLoadedd.bind(eve);
            reader.readAsBinaryString(file);
          }
          return true;
          // }
        };
      };
    }
  }

  //calucalte the size of file
  // ReadAsBase64(file): Promise<any> {
  //   const reader = new FileReader();
  //   const fileValue = new Promise((resolve, reject) => {
  //     reader.addEventListener('load', () => {
  //       const result: any = reader.result;
  //       if (!result) reject('Cannot read variable');
  //       console.log(result.length);
  //       console.log(2 ** 21);
  //       if (result.length > 2 ** 21) reject('Image size must be upto 2MB'); // Note: 2*2**20 = 2**21
  //       resolve(reader.result);
  //     });

  //     reader.addEventListener('error', (event) => {
  //       reject(event);
  //     });

  //     reader.readAsDataURL(file);
  //   });

  //   return fileValue;
  // }

  _handleReaderLoadedd(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.profileBase = btoa(binaryString);
    this.ImageUrl = true;
    this.ImageUrlnull = false;
    this.profileImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.profileBase);
    this.profileSubmit = false;
  }

  editCustomerUpload() {
    this.profileSubmit = true;
    if (this.data[1].Client.DriverServices.length > 0) {
      this.DriverServices = this.data[1].Client.DriverServices[0].ID;
    }
    if (this.data[1].Client.ClientCategory.length > 0) {
      this.ClientCategoryId = this.data[1].Client.ClientCategory[0].ClientCategoryId;
    }
    if (this.data[1].Client.MenuType.length > 0) {
      if (this.data[1].Client.MenuType[0].Title === 'Grab') {
        this.ImageUrlGrabData = this.profileBase;
        this.ImageUrlData = this.data[1].Client.Logo;
      } else {
        this.ImageUrlData = this.profileBase;
        this.ImageUrlGrabData = this.data[1].Client.ImageUrl;
      }
    } else {
      this.ImageUrlData = this.profileBase;
      this.ImageUrlGrabData = this.data[1].Client.ImageUrl;
    }
    let request = [];
    request = this.data[1].Client.NonProfitWorkingSlot;
    for (let i = 0; i < request.length; i++) {
      let datatime = request[i];
      // var m = moment.tz(datatime.StartTime, this.data[1].Client.TimeZone);
      // m.format();
      datatime.StartTime = this.estdatepostFormat.transform(datatime.StartTime);
      datatime.EndTime = this.estdatepostFormat.transform(datatime.EndTime);
    }
    let payload = {
      ClientId: this.data[1].Client.ClientId,
      ClientCategories: [
        {
          ClientCategoryId: this.ClientCategoryId,
        },
      ],
      ContactId: this.data[1].Client.ContactId,
      DriverNotes: this.data[1].Client.DriverNotes,
      HoldHarmlessAgreement: this.data[1].Client.HoldHarmlessAgreement,
      InviteUser: this.data[1].Client.InviteUser,
      NotificationTypes: this.data[1].Client.NotificationTypes,
      PickupType: this.data[1].Client.PickupType,
      SeparatedCompost: this.data[1].Client.SeparatedCompost,
      StripeSubscriptionId: this.data[1].Client.StripeSubscriptionId,
      TimeZone: this.data[1].Client.TimeZone,
      CityId: this.data[1].Client.CityId,
      StateId: this.data[1].Client.StateId,
      Name: this.data[1].Client.Name,
      Phone: this.data[1].Client.Phone,
      Address: this.data[1].Client.Address,
      Address2: this.data[1].Client.Address2,
      City: this.data[1].Client.City,
      State: this.data[1].Client.State,
      ZIP: this.data[1].Client.ZIP,
      Country: this.data[1].Client.Country,
      Email: this.data[1].Client.Email,
      Web: this.data[1].Client.Web,
      EINNumber: this.data[1].Client.EINNumber,
      HasFridgeStorage: this.data[1].Client.HasFridgeStorage,
      NumberOfPeopleServed: this.data[1].Client.NumberOfPeopleServed,
      WasteExpenditure: this.data[1].Client.WasteExpenditure,
      ClientTypeId: this.data[1].Client.ClientTypeId,
      ApprovalStatusId: this.data[1].Client.ApprovalStatus[0].ID,
      WayPointID: 0,
      WP_Client_Id: 0,
      MenuEdit: this.data[1].Client.MenuEdit,
      Logo: this.ImageUrlData,
      ImageUrl: this.ImageUrlGrabData,
      FacilitatorId: this.data[1].Client.FacilitatorId,
      StripeCustomerId: this.data[1].Client.StripeCustomerId,
      StripePlanId: this.data[1].Client.StripePlanId,
      FacilityGroupId: this.data[1].Client.FacilityGroupId,
      MenuTypeId: this.data[1].Client.MenuTypeId,
      CreatedOn: this.data[1].Client.CreatedOn,
      CreatedBy: this.data[1].Client.CreatedBy,
      NonProfitWorkingSlot: request,
      // ClientCategory: this.data[1].Client.ClientCategory,
      DriverServices: [
        {
          ID: this.DriverServices,
        },
      ],
      User_Client: [],
    };
    this.buttonDisabled = true;
    var spinnerRef = this.spinnerService.start();
    this.customerServices.updateCustomerDetails(payload).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        spinnerRef = this.spinnerService.start();
        this.CustomerService.getCustomerDetail(this.data[1].Client.ClientId).subscribe((res) => {
          this.spinnerService.stop(spinnerRef);
          this.buttonDisabled = true;
          this.customer = res.Result[0];
          if (this.customer.MenuType.length > 0) {
            if (this.customer.MenuType[0].Title === 'Grab') {
              this.profileImageUrl = this.customer.ImageUrl;
            } else {
              this.profileImageUrl = this.customer.Logo;
            }
          } else {
            this.profileImageUrl = this.customer.Logo;
          }
          this.result = [
            {
              user_photo: this.profileImageUrl,
            },
            {
              upload_photo: this.ImageUrlData,
            },
          ];
          this.relaodinfo.reloadphotoData = true;
          this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
          this.dialogRef.close(this.result);
        });
      },
      (error) => {
        this.profileSubmit = false;
        // alert(JSON.stringify(error));
        this.buttonDisabled = false;
        this.spinnerService.stop(spinnerRef);
      }
    );
    // this.dialogRef.close(this.profileImageUrl);
  }

  // profilr upload image
  uploadProfile() {
    this.profileSubmit = true;
    if (this.profileImageUrl) {
      this.profileImageUrl = this.profileImageUrl;
      const body = {
        Id: this.state.user.User.Id,
        FirstName: this.state.user.User.FirstName,
        LastName: this.state.user.User.LastName,
        Title: this.state.user.User.Title,
        PasswordHash: this.state.user.User.PasswordHash,
        ApprovalStatusId: 2,
        ImageUrl: this.profileBase,
        Email: this.state.user.User.Email,
        PhoneNumber: this.state.user.User.PhoneNumber,
        UserName: this.state.user.User.UserName,
        UserId: this.state.user.User.UserId,
        Roles: this.state.user.User.Roles,
        Clients: this.state.user.User.Clients,
        WP_Pwd_Hash: this.state.user.User.WP_Pwd_Hash,
        WP_User_Id: this.state.user.User.WP_User_Id,
        EmailConfirmed: this.state.user.User.EmailConfirmed,
        SecurityStamp: this.state.user.User.SecurityStamp,
        PhoneNumberConfirmed: this.state.user.User.EmailConfirmed,
        TwoFactorEnabled: this.state.user.User.TwoFactorEnabled,
        LockoutEndDateUtc: this.state.user.User.LockoutEndDateUtc,
        LockoutEnabled: this.state.user.User.LockoutEnabled,
        AccessFailedCount: this.state.user.User.AccessFailedCount,
        ResetCode: this.state.user.User.ResetCode,
        CityId: this.state.user.User.CityId,
        StateId: this.state.user.User.StateId,
        User_Client: [],
        IsSAASDriver: this.state.user.User.IsSAASDriver,
        CreatedBy: this.state.user.User.CreatedBy,
        CreatedOn: this.state.user.User.CreatedOn,
        ModifiedOn: this.state.user.User.ModifiedOn,
        ModifiedBy: this.state.user.User.ModifiedBy,
      };
      this.state.user.User.ImageUrl = this.profileImageUrl;
      // localStorage.setItem('user', JSON.stringify(this.state));
      this.buttonDisabled = true;
      var spinnerRef = this.spinnerService.start();
      this.profileService.profileSettingedit(body, this.state.user.User.Id).subscribe(
        (res) => {
          this.accountService.getUserDetails(this.user.User.UserId).subscribe(
            (data) => {
              this.profileImageUrl = data.Result[0].ImageUrl;
              this.state.user.User.ImageUrl = this.profileImageUrl;
              localStorage.setItem('user', JSON.stringify(this.state));
              this.spinnerService.stop(spinnerRef);
              this.dialogRef.close(this.profileImageUrl);
            },
            (error) => {
              this.profileSubmit = false;
              // this.state.isSpinnerVisible = false;
              this.spinnerService.stop(spinnerRef);
              // alert(JSON.stringify(error));
            }
          );
        },
        (error) => {
          // alert(JSON.stringify(error));
          this.buttonDisabled = false;
          this.spinnerService.stop(spinnerRef);
        }
      );
    }
  }
}
