import { Component, OnInit, ViewChild } from '@angular/core';
import { EditBusinessDialogComponent } from '../edit-business-dialog/edit-business-dialog.component';
import { MatDialog, MatDialogConfig, MatPaginator, MatDialogRef } from '@angular/material';
import { DeletedialogComponent } from '../deletedialog/deletedialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { InviteuserdialogComponent } from '../user/inviteuserdialog/inviteuserdialog.component';
import { EditCustomerBusinessDialogComponent } from '../customers/edit-customer-business-dialog/edit-customer-business-dialog.component';
import { DeleteUserDialogComponent } from '../delete-user-dialog-component/delete-user-dialog-component';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AccountService } from 'src/app/shared/account.service';
import { Client } from 'src/app/models/user.model';
import { CustomerCreateMenuDialogComponent } from '../create-menu-dialog/customer-create-menu-dialog/customer-create-menu-dialog.component';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { DeleteFacilitatorAccountComponent } from '../dashboard/delete-facilitator-account/delete-facilitator-account.component';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AppState } from 'src/app/shared/app-state';
import { SaasconfirmComponent } from 'src/app/saasconfirm/saasconfirm.component';
import { SAASService } from 'src/app/shared/services/saas.service';
import { environment } from 'src/environments/environment';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';

export interface PeriodicElement {
  First_Name: string;
  UserId: number;
  // Last_Name: string;
  email: string;
  Role: string;
  Status: string;
  Activity: string;
}

@Component({
  selector: 'app-customer-facility-details',
  templateUrl: './customer-facility-details.component.html',
  styleUrls: ['./customer-facility-details.component.scss'],
})
export class CustomerFacilityDetailsComponent implements OnInit {
  displayedColumns: string[] = ['UserId', 'First_Name', 'email', 'Role', 'Status', 'Activity'];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: MatTableDataSource<Client>;

  // route: any;
  accountService: any;
  state: any;
  userData: any;
  deleteData = [];
  dataSources: any;
  sort: any;
  // paginator: any;
  title: any;
  type: any;
  name: any;
  username: any;
  userfirstname: any;
  titles: any;
  ClientId: any;
  customer: any;
  clienttype: any;
  profileImageUrl: any;
  contacts: any;
  menuType: any;
  modalclose: string;
  showButton: any;
  VisibleForAdminFacilityOnly: boolean;
  deliveryPartner: boolean;
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  nodata: any;
  waypoint: boolean;
  editoption = true;
  userRole: any;
  editClientID: any;
  clientData: any;
  noPlaning: boolean;
  yearlyPlan: boolean;
  faclityCount: any;
  pickuptypdedata: any;
  saasfg: boolean;
  citydataID: any;
  frrunderfg = true;
  disabledOTPnewmenu = true;
  pickupstatesdata: any;
  relaodinfo: any;
  adminViewonly: boolean;
  Visibledriverservice = true;
  hideCreateMenu = false;
  showDriverNotes = false;
  clientDetails;
  showSpinner: boolean = true;
  changeMenuTypePermission: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private CustomerService: CustomerService,
    private appstate: AppState,
    private pricingModule: PricingModuleServiceService,
    private spinnerService: SpinnerService,
    private sassService: SAASService,
    private _rdata: modalDataService,
    private AdminService: AdmintoolsService // public dialogRef: MatDialogRef<CustomerFacilityDetailsComponent>,
  ) { }

  ngOnInit() {
    this.adminViewonly = getFeature('VisibleForAdminOnlyGrab');
    this.Visibledriverservice = getFeature('driverservices');
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
      if (this.relaodinfo.reloadData === true) {
        this.customerDetailsdata();
      } else if (this.relaodinfo.reloadphotoData === true) {
        this.customerDetailsdata();
      } else if (this.relaodinfo.reloadphotoData === false && this.relaodinfo.reloadData === false) {
        this.customerDetailsdata();
      }
    });
    // this.state.isSpinnerVisible = true;
    this.userRole = this.appstate.user.User.Roles[0].Name;
    // console.log(this.userRole);
    this.showButton = getFeature('accountdelete');
    this.VisibleForAdminFacilityOnly = getFeature('editdetails');
    this.changeMenuTypePermission = getFeature('changeMenuType');
  }

  customerDetailsdata() {
    this.ClientId = this.route.snapshot.params['id'];
    // this.v2_getclientcategories();
    this.v2_getAlluser(this.ClientId);
    // this.v2_getAllCustomers();
    var spinnerRef;
    if (this.showSpinner) {
      spinnerRef = this.spinnerService.start();
    }
    this.CustomerService.getCustomerDetail(this.route.snapshot.params['id']).subscribe(
      (data) => {
        if (this.showSpinner) {
          this.spinnerService.stop(spinnerRef);
          this.showSpinner = false;
        }

        this.menuType = data.Result[0].MenuType;
        //console.log(this.menuType);
        this.customer = data['Result'];
        // this.clientDetails = data['Result'];
        this.clientDetails = this.customer.map((x) => Object.assign({}, x));

        // console.log(this.customer);

        if (this.customer[0].PickupType.length === 1 && this.customer[0].PickupType[0].Title === 'Organic Recycling') {
          this.hideCreateMenu = true;
        } else {
          this.hideCreateMenu = false;
        }

        if (this.customer[0].DriverServices.length > 0) {
          if (
            this.customer[0].DriverServices[0].Title === 'SAAS' &&
            this.customer[0].ClientCategory[0].Title === 'SAAS Facility' &&
            !this.customer[0].FacilityGroupId
          ) {
            this.saasfg = true;
          } else {
            this.saasfg = false;
          }
        }
        if (this.relaodinfo.reloadData === true) {
          this.relaodinfo.reloadData = false;
          this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
        }
        if (this.relaodinfo.reloadphotoData === true) {
          this.relaodinfo.reloadphotoData = false;
          this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
        }
        this.sassService.pickupcitydetails(this.customer[0].StateId).subscribe((data) => {
          this.citydataID = data['Result'];
          for (var i = 0; i < this.citydataID.length; i++) {
            if (this.citydataID[i].ID === this.customer[0].CityId) {
              // this.customer[0].City = this.citydataID[i].ID;
              this.customer[0].City = this.citydataID[i].Title;
            }
          }
        });
        this.sassService.pickupstatedetails().subscribe((data) => {
          this.pickupstatesdata = data['Result'];
          for (var i = 0; i < this.pickupstatesdata.length; i++) {
            if (this.pickupstatesdata[i].ID === this.customer[0].StateId) {
              this.customer[0].State = this.pickupstatesdata[i].Title;
            }
          }
        });
        
        if (this.customer[0].PickupType && this.customer[0].PickupType[0]) {
          this.pickuptypdedata = this.customer[0].PickupType[0].Title;
        }

        if (this.userRole === 'Facilitator') {
          if (this.customer[0].ClientCategory[0].Title === 'Facility') {
            if (this.customer[0].FacilitatorId && this.customer[0].FacilityGroupId) {
              this.frrunderfg = false;
            } else if (this.customer[0].FacilitatorId) {
              this.frrunderfg = true;
              this.VisibleForAdminFacilityOnly = true;
            } else {
              this.frrunderfg = false;
            }
          }
        }

        if (this.customer[0].PickupType 
          && this.customer[0].PickupType[0] 
          && this.customer[0].PickupType[0].Title == 'One Time Pickup') {
          this.disabledOTPnewmenu = false;
        }

        const client: Client[] = [];
        this.userData = data.Result[0];
        if (this.userData.MenuType.length > 0) {
          if (this.userData.MenuType[0].Title === 'Grab') {
            this.waypoint = true;
            if (this.userData.ImageUrl == null) {
              // this.profileImageUrl = '../../../../assets/images/GLogo.jpg';
              this.ImageUrlnull = true;
              this.ImageUrl = false;
            } else {
              this.profileImageUrl = this.userData.ImageUrl;
              this.ImageUrl = true;
              this.ImageUrlnull = false;
            }
          } else {
            this.waypoint = false;
            if (this.userData.Logo == null && this.userData.ImageUrl == null) {
              // this.profileImageUrl = '../../../../assets/images/GLogo.jpg';
              this.ImageUrlnull = true;
              this.ImageUrl = false;
            } else if (this.userData.Logo && this.userData.ImageUrl == null) {
              this.profileImageUrl = this.userData.Logo;
              // this.profileImageUrl = this.data[0].customer[0].Logo;
              this.ImageUrl = true;
              this.ImageUrlnull = false;
            } else if (this.userData.ImageUrl && this.userData.Logo == null) {
              this.profileImageUrl = this.userData.ImageUrl;
              // this.profileImageUrl = this.data[0].customer[0].Logo;
              this.ImageUrl = true;
              this.ImageUrlnull = false;
            }
          }
        } else {
          if (this.userData.Logo == null) {
            // this.profileImageUrl = '../../../../assets/images/GLogo.jpg';
            this.ImageUrlnull = true;
            this.ImageUrl = false;
          } else {
            this.profileImageUrl = this.userData.Logo;
            // this.profileImageUrl = this.data[0].customer[0].Logo;
            this.ImageUrl = true;
            this.ImageUrlnull = false;
          }
        }
        if (data.Result[0].ClientCategory[0] == undefined) {
          this.clienttype = '';
        } else {
          this.clienttype = data.Result[0].ClientCategory[0].Title;
        }
        if (this.clienttype === 'Facility') {
          this.deliveryPartner = true;
          // this.editoption = true;
        }
        if (this.userRole === 'Facility') {
          if (this.customer[0].ClientCategory[0].Title == 'SAAS Facility' || this.customer[0].ClientCategory[0].Title == 'Facility') {
            this.editoption = true;
          } else {
            this.editoption = false;
          }
        }

        for (let i = 0; i <= this.customer.length - 1; i++) {
          const pickUnit: Client = this.customer[i];
          this.dataSource = this.customer[i].Contact;

          client.push(pickUnit);
        }
        this.dataSource = new MatTableDataSource(client);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        if (this.showSpinner) {
          this.spinnerService.stop(spinnerRef);
          this.showSpinner = false;
        }
        // this.spinnerService.stop(spinnerRef);
      }
    );
    // this.CustomerService.getFacilityMenu(
    //   this.route.snapshot.params["id"]
    // ).subscribe(data => {
    //   // this.customer = data.Result[0].Name;
    //   if (data.Result[0].MenuType.length > 0) {
    //     this.menuType = data.Result[0].MenuType;
    //   } else {
    //     this.menuType = [];
    //   }
    // });
  }
  deleteCustomerAccount() {
    if (this.userRole === 'Facility Group' || this.userRole === 'Facility' || this.userRole === 'Admin') {
      if (this.userData.ClientCategory.length > 0) {
        if (this.userData.ClientCategory[0].Title === 'SAAS Facility') {
          this.saasChecking();
        } else {
          this.deleteAccount();
        }
      } else if (this.appstate.user.User.Clients[0].ClientType) {
        if (this.appstate.user.User.Clients[0].ClientType === 'SAAS Facility') {
          this.saasChecking();
        } else {
          this.deleteAccount();
        }
      } else {
        this.deleteAccount();
      }
    } else {
      this.deleteAccount();
    }
  }
  saasChecking() {
    if (this.appstate.user.User.Roles[0].Name === 'Facility') {
      this.editClientID = this.appstate.user.User.Clients[0].ClientId;
    } else if (this.userRole === 'Admin') {
      if (this.userData.FacilityGroupId) {
        this.editClientID = this.userData.FacilityGroupId;
      } else {
        this.editClientID = this.userData.ClientId;
      }
    } else {
      this.editClientID = this.appstate.user['FacilityGroupId'];
    }
    this.sassService.getSubDetails(this.editClientID).subscribe((data) => {
      this.clientData = data.Result[0];
      if (this.clientData.StripePlanId) {
        // this.saasfg = true;
        this.noPlaning = false;
        // if (this.clientData.StripePlanId === 'price_1H2W2rICi4RDu2OllIjS8nqt') {
        this.pricingModule.getsubscriptionSAAS(this.clientData.StripePlanId).subscribe((data) => {
          let stripeData;
          stripeData = data['Result'];
          for (const status of stripeData) {
            if (this.clientData.StripePlanId === status.StripePlanId) {
              if (status.SubscriptionType === 'month') {
                // this.monthlyPrice = status.Price;
                this.yearlyPlan = false;
              } else {
                // this.yearlyPrice = status.Price;
                this.yearlyPlan = true;
              }
            }
          }
        });
        // if (this.clientData.StripePlanId === environment.yearlyKey) {
        //   this.yearlyPlan = true;
        // } else {
        //   this.yearlyPlan = false;
        // }
        // } else {
        //   this.noPlaning = true;
        // }
        this.CustomerService.getAllFilteredCustomers(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          this.editClientID,
          null,
          null,
          null,
          null
        ).subscribe((data) => {
          if (data.Result.length > 0) {
            data.Result = data.Result.filter((value) => value.ApprovalStatus === 'Active');
            this.faclityCount = data.Result.length;
          } else {
            this.faclityCount = 1;
          }
          // this.multipleFGdata = data.Result;
          // this.dialogRef.close();
          const dialogRef = this.dialog.open(SaasconfirmComponent, {
            width: '500px',
            // height: '440px',
            data: [{ faclityCount: this.faclityCount }, { yearlyPlan: this.yearlyPlan }, { active: 'Inactive' }],
            panelClass: 'custom-editSub-container',
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.deleteAccount();
            }
          });
          // }
        });
      } else {
        this.noPlaning = true;
        this.deleteAccount();
      }
    });
  }
  deleteAccount() {
    this.deleteData.push(this.userData);
    const dialogRef = this.dialog.open(DeletedialogComponent, {
      width: '700px',
      // height: '300px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ ClientData: this.deleteData }, { sass: this.clientData }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.state.isSpinnerVisible = false;
      // this.router.navigate(['Customers/']);
    });
  }
  opentransferownershiplDialog() {
    const dialogRef = this.dialog.open(InviteuserdialogComponent, {
      width: '640px',
      height: '450px',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  facility_menuView() {
    this.router.navigate(['FacilityMenuView']);
  }
  backtoCustomers() {
    if (localStorage.getItem('FacilitatorFGroupclientid') === null) {
      this.router.navigate(['Customers']);
    } else {
      this.router.navigate(['CustomerFacilitatorview/' + JSON.parse(localStorage.getItem('FacilitatorFGroupclientid')) + '']);
      localStorage.removeItem('FacilitatorFGroupclientid');
    }
  }
  editbusinessdetails() {
    const dialogRef = this.dialog.open(EditCustomerBusinessDialogComponent, {
      width: '850px',
      // height: '840px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: [
        {
          customer: this.customer,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerDetailsdata();
      } else {
        // console.log(this.customer);
        // console.log(this.clientDetails);
        this.customer = this.clientDetails.map((x) => Object.assign({}, x));
      }
    });
  }
  removeUserDialog() {
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      width: '650px',
      height: '300px',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  getCustomerDetails() {
    this.CustomerService.getCustomerDetails().subscribe((data) => {
      this.dataSource = data.Result;
    });
  }

  // v2_getAllCustomers() {
  //   this.CustomerService.v2_getAllCustomers().subscribe((data) => {
  //     this.dataSource = data.Result;
  //     console.log(data.Result);
  //   });
  // }

  v2_getAlluser(id) {
    // var spinnerRef = this.spinnerService.start();
    this.CustomerService.v2_getAllUser(id).subscribe((data) => {
      // this.spinnerService.stop(spinnerRef);
      this.dataSources = data.Result;
      this.nodata = data.Result.length;
      if (this.nodata === 0) {
        // alert('no data');
      }
    });
  }

  goToCreateMenuDialog() {
    const dialogRef = this.dialog.open(CustomerCreateMenuDialogComponent, {
      width: '700px',
      // height: "470px",
      panelClass: 'custom-Filterdialog-container',
      data: {
        clientId: this.ClientId,
        customerData: this.customer,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  showDriverNotesDiv() {
    this.showDriverNotes = !this.showDriverNotes;
  }
  redirectUserDetails(id) {
    this.router.navigate(['CustomerViewDetails/' + id.UserId + '']);
  }
}
