import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderbyLastMessagePipe',
})
export class OrderbyLastMessagePipePipe implements PipeTransform {
  transform(array: any, args?: any): any[] {
    if (array !== undefined && array !== null && args !== '') {
      const direction = args === 'desc' ? 1 : -1;
      array.sort((a: any, b: any) => {
        if (a['Title'] < b['Title']) {
          return 1 * direction;
        } else if (a['Title'] > b['Title']) {
          return -1 * direction;
        } else {
          return 0;
        }
      });
    }
    return array;
  }
}
