import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PricingPromotionCodeService } from '../../../shared/services/pricing-promotion-code.service';

@Component({
  selector: 'app-promotion-code-pricing-delete',
  templateUrl: './promotion-code-pricing-delete.component.html',
  styleUrls: ['./promotion-code-pricing-delete.component.scss'],
})
export class PromotionCodePricingDeleteComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PromotionCodePricingDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private promotionCode: PricingPromotionCodeService
  ) { }

  couponId = this.data['Id'];

  ngOnInit() {
  }

  deleteCoupon() {
    this.promotionCode.deleteCoupon(this.couponId).subscribe((response) => {
      this.dialogRef.close('res');
    });
  }

  close() {
    this.dialogRef.close();
  }
}
