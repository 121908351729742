import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AppState } from 'src/app/shared/app-state';
import { SpinnerService } from 'src/app/core/spinner.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SuccesPopupComponent } from '../succes-popup/succes-popup.component';
import { environment } from 'src/environments/environment';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';

@Component({
  selector: 'app-changeedit-susbscription',
  templateUrl: './changeedit-susbscription.component.html',
  styleUrls: ['./changeedit-susbscription.component.scss']
})
export class ChangeeditSusbscriptionComponent implements OnInit {
  activeState: boolean;
  activeyearlyState: boolean;
  clientId: any;
  reqsub: boolean;
  user: any;
  UserId: any;
  ClientId: any;
  planning: any;
  yearlyPlan: boolean;
  noPlaning: boolean;
  surplusnorganicmonthly: any;
  surplusnorganicannual: any;
  StripePlanIdMonthly: any;
  StripePlanIdYearly: any;
  constructor(private state: AppState, private spinnerService: SpinnerService, private sassService: SAASService, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog, private pricingModule: PricingModuleServiceService,
    public dialogRef: MatDialogRef<ChangeeditSusbscriptionComponent>) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    if (this.data['noPlaning'] === true) {
      this.noPlaning = true;
    } else {
      if (this.data['subPlan'] === true) {
        this.yearlyPlan = true;
      } else {
        this.yearlyPlan = false;
      }
    }
    this.state.user = JSON.parse(localStorage.getItem("user")).user;
    this.user = this.state.user;
    if (this.user.User.Roles[0].Name === 'Facility Group') {
      if (this.user.User.Clients[0].DriverService === 'SAAS') {
        this.ClientId = this.user.FacilityGroupId;
        this.UserId = this.user.User.UserId;
      }
    }
    if (this.user.User.Roles[0].Name === 'Admin') {
      this.ClientId = this.data['cliendid'];
    }
    if (this.user.User.Roles[0].Name === 'Facility') {
      if (this.user.User.Clients[0].DriverService === 'SAAS') {
        this.ClientId = this.user.User.Clients[0].ClientId;
        this.UserId = this.user.User.UserId;
      }
    }
    this.sassService.getSubDetails(this.ClientId).subscribe(
      data => {
        let pickupTypefinal;
        if (data.Result[0].PickupType.length > 0) {
          if (data.Result[0].PickupType.length === 2) {
            pickupTypefinal = 'combined';
          } else if (data.Result[0].PickupType[0].Title === 'Organic Recycling') {
            pickupTypefinal = 'Organic';
          } else {
            pickupTypefinal = 'Food';
          }
        } else {
          pickupTypefinal = 'combined';
        }
        this.getPricingRated(pickupTypefinal);
      });
  }
  getPricingRated(pickuptype) {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      let pricedata;
      pricedata = data['Result'];
      for (const status of pricedata) {
        if (status.SubscriptionType === 'month') {
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.surplusnorganicmonthly = status.Price;
              this.StripePlanIdMonthly = status.StripePlanId;
              // console.log(this.monthlyPrice);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.surplusnorganicmonthly = status.Price;
              this.StripePlanIdMonthly = status.StripePlanId;
              // console.log(this.monthlyPrice);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.surplusnorganicmonthly = status.Price;
            this.StripePlanIdMonthly = status.StripePlanId;
            // console.log(this.monthlyPrice);
          }
          // console.log(this.yearlyPrice)
        } else {
          // console.log(pickuptype)
          // console.log(status.PickupType)
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            // console.log(status.PickupType)
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.surplusnorganicannual = status.Price;
              this.StripePlanIdYearly = status.StripePlanId;
              // console.log(this.yearlyPrice);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.surplusnorganicannual = status.Price;
              this.StripePlanIdYearly = status.StripePlanId;
              // console.log(this.yearlyPrice);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.surplusnorganicannual = status.Price;
            this.StripePlanIdYearly = status.StripePlanId;
            // console.log(this.yearlyPrice);
          }
          // console.log(this.monthlyPrice);
          // console.log(this.yearlyPrice);
        }
        // if (status.SubscriptionType === 'month') {
        //   this.surplusnorganicmonthly = status.Price;
        //   this.StripePlanIdMonthly = status.StripePlanId;
        // } else {
        //   this.surplusnorganicannual = status.Price;
        //   this.StripePlanIdYearly = status.StripePlanId;
        // }
        // if (this.driverSelected === status.ID) {
        //this.surplusnorganicmonthly = this.data.Result[0].Price;
      }
      //this.surplusnorganicmonthly = this.data.Result[0].Price;
    });
  }
  montlyFunction() {
    this.activeState = true;
    this.activeyearlyState = false;
  }
  yearlyFunction() {
    this.activeState = false;
    this.activeyearlyState = true;
  }
  goToselectmontlyPayment() {
    if (this.activeState || this.activeyearlyState) {
      // if (this.activeState === true) {
      //   this.planning = 'price_HON4TdbdJWPeVz';
      // } else {
      //   this.planning = 'price_HON5NBmCH4W13i';
      // }

      // fot testing purpose
      if (this.activeState === true) {
        this.planning = this.StripePlanIdMonthly;
      } else {
        this.planning = this.StripePlanIdYearly;
      }
      // fot testing purpose
      // if (this.activeState === true) {
      //   this.planning = 'price_1H2W1OICi4RDu2OlWM9hgNwq';
      // } else {
      //   this.planning = 'price_1H2W2rICi4RDu2OllIjS8nqt';
      // }
      const payload = {
        PlanId: this.planning,
        Update: true,
        ClientId: this.ClientId,
        UserId: this.UserId
      };
      const spinnerRef = this.spinnerService.start();
      this.sassService.striping(payload).subscribe(
        data => {
          let finalData;
          this.spinnerService.stop(spinnerRef);
          if (data.message === 'Updated Successfully') {
            //  alert('Updated Successfully');
            finalData = 'Updated Successfully';
            this.dialogRef.close('res');
            const dialogRef = this.dialog.open(SuccesPopupComponent,
              {
                width: '338px',
                // height: '480px',
                data: { ClientData: finalData },
                panelClass: 'custom-editSub-container',
              });
            dialogRef.afterClosed().subscribe(result => {
            }
            );
          } else {
            alert(data.message);
          }
        },
        error => {
          this.spinnerService.stop(spinnerRef);
          // this.buttonDisabled = false;
        }
      );

    } else {
      this.reqsub = true;
    }
  }
}
