import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-edit-dashboard-dialog',
  templateUrl: './edit-dashboard-dialog.component.html',
  styleUrls: ['./edit-dashboard-dialog.component.scss']
})
export class EditDashboardDialogComponent implements OnInit {

  radioOptions = 'option 1';

  constructor(public dialogRef: MatDialogRef<EditDashboardDialogComponent>,
    @Inject( MAT_DIALOG_DATA )
    public data: MatDialog ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
