import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { SAASService } from 'src/app/shared/services/saas.service';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
@Component({
  selector: 'app-editfacility',
  templateUrl: './editfacility.component.html',
  styleUrls: ['./editfacility.component.scss'],
})
export class EditfacilityComponent implements OnInit {
  pickupTypeID = [];
  pickupTypeIDFG = [];
  PickupTypeS = [
    {
      ID: 1,
      Title: 'Food',
    },
    {
      ID: 2,
      Title: 'Organic Recycling',
    },
  ];
  allDeliveryStatus = [
    {
      ID: 1,
      Title: 'SAAS Facility',
    },
    {
      ID: 2,
      Title: 'SAAS Facility Group',
    },
  ];
  public numberForm: FormGroup;
  CompanyTitle: any;
  EmailFG: any;
  taxForm: any;
  FirstName: any;
  LastName: any;
  selectedType: any;
  Email: any;
  i = 0;
  PhoneNumber: any;
  PhoneNumberFG: any;
  TaxID: any;
  TaxIDFG: any;
  Address1: any;
  index: any;
  Address2FG: any;
  Address1FG: any;
  Address2: any;
  State: any;
  StateFG: any;
  City: any;
  ZipCode: any;
  menuEdit = true;
  menuEditFG = true;
  sendpickupTypes: any[];
  requireFirstName: boolean;
  requireLastName: boolean;
  requireCompanyTitle: boolean;
  requireselectedType: boolean;
  requireEmail: boolean;
  checkselectedType = false;
  requirePhoneNumber: boolean;
  public numberFormZip: FormGroup;
  multipleFGadd = [];
  facilityFGadd = [];
  requireTaxID: boolean;
  requireAddress1: boolean;
  requireState: boolean;
  requireCity: boolean;
  requireZipCode: boolean;
  invalidEmail: boolean;
  Country: any;
  requireCountry: boolean;
  dataerror: string;
  NotVisibileFG: boolean = true;
  VisibleForFG: boolean;
  facility: boolean = true;
  facilitygroup: boolean;
  CompanyTitleFG: any;
  CityFG: any;
  ZipCodeFG: any;
  CountryFG = 'USA';
  fcailityValidation: boolean;
  multipleFGaddFinal: any[];
  requireField: boolean;
  menueditble = true;
  menueditbleFG = true;
  dataerrortrue: boolean;
  pickupstatesdata: any;
  citydataID: any;
  onNoClick(): void {
    this.dialogRef.close();
  }
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditfacilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private sassService: SAASService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit() {
    this.pickupstates();
    this.CompanyTitleFG = this.data[0].value.CompanyTitle;
    this.EmailFG = this.data[0].value.Email;
    this.TaxIDFG = this.data[0].value.TaxID;
    this.PhoneNumberFG = this.data[0].value.PhoneNumber;
    this.Address1FG = this.data[0].value.Address1;
    this.Address2FG = this.data[0].value.Address2;
    this.CityFG = this.data[0].value.CityId;
    this.StateFG = this.data[0].value.StateId;
    this.sassService.pickupcitydetails(this.StateFG).subscribe((data) => {
      this.citydataID = data['Result'];
      for (var i = 0; i < this.citydataID.length; i++) {
        if (this.citydataID[i].ID === this.CityFG) {
          this.CityFG = this.citydataID[i].ID;
        }
      }
    });
    this.ZipCodeFG = this.data[0].value.ZipCode;
    this.CountryFG = this.data[0].value.Country;
    this.menuEditFG = this.data[0].value.MenuEdit;
    let count = 0;
    this.pickupTypeIDFG = [];
    for (var j = 0; j < this.PickupTypeS.length; j++) {
      for (var i = 0; i < this.data[0].value.PickupType.length; i++) {
        if (
          this.PickupTypeS[j].Title === this.data[0].value.PickupType[i].Title
        ) {
          this.pickupTypeIDFG.push(this.data[0].value.PickupType[i].Title);
          this.PickupTypeS[j]['checkedId'] = true;
        }
        if (this.data[0].value.PickupType[i].Title === 'Food') {
          count++;
        } else {
        }
      }
      if (count !== 0) {
        this.menueditbleFG = true;
      } else {
        this.menueditbleFG = false;
        this.menuEditFG = true;
      }
    }
    this.numberForm = this.formBuilder.group({
      number: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
    this.numberFormZip = this.formBuilder.group({
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
        ]),
      ],
    });
    this.taxForm = this.formBuilder.group({
      tax: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
  }
  keyPressZipCode(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  onCheckboxChagenFG(event, value) {
    if (event.checked) {
      this.pickupTypeIDFG.push(value);
    }
    if (!event.checked) {
      // this.selectPickupType = value;
      let index = this.pickupTypeIDFG.indexOf(value);
      if (index > -1) {
        this.pickupTypeIDFG.splice(index, 1);
      }
    }
    let count = 0;
    for (var j = 0; j < this.PickupTypeS.length; j++) {
      for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
        if (this.pickupTypeIDFG[j] === 'Food') {
          count++;
        } else {
        }
      }
    }
    if (count !== 0) {
      this.menueditbleFG = true;
    } else {
      this.menueditbleFG = false;
      this.menuEditFG = true;
    }
  }
  checkValue() {}
  keyPressFirstName(event: any) {
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateEmail: true,
        Email: event,
        SAASClient: '',
      };
      this.sassService.reigster(body1).subscribe((data) => {
        if (
          data.message ===
          'Field requires a unique email id and this email id has already been used'
        ) {
          this.dataerrortrue = true;
          this.dataerror = data.message;
        } else {
          this.dataerrortrue = false;
        }
      });
    } else {
      this.dataerrortrue = false;
    }
  }
  // email-validation
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.Email) === false) {
      return false;
    }
    return true;
  }
  validateEmailFG() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.EmailFG) === false) {
      return false;
    }
    return true;
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.pickupstatesdata = data['Result'];
    });
  }
  selectedstate() {
    this.sassService.pickupcitydetails(this.StateFG).subscribe((data) => {
      this.citydataID = data['Result'];
      this.City = '';
    });
  }
  selectedTypeValue() {
    if (this.checkselectedType === false) {
      this.selectedType = 'SAAS Facility';
      this.NotVisibileFG = true;
      this.VisibleForFG = false;
    } else {
      this.selectedType = 'SAAS Facility Group';
      this.NotVisibileFG = false;
      this.VisibleForFG = true;
    }
  }
  goTofacilityReg() {
    const sendpickupTypes = [];
    for (var i = 0; i < this.pickupTypeIDFG.length; i++) {
      sendpickupTypes.push({ Title: this.pickupTypeIDFG[i] });
    }
    if (this.PhoneNumberFG) {
      this.numberForm.controls.number.setValue(
        numberSetMask(this.PhoneNumberFG)
      );
    }
    this.sendpickupTypes = sendpickupTypes;
    if (
      this.CompanyTitleFG &&
      this.CompanyTitleFG !== undefined &&
      this.CompanyTitleFG !== null
    ) {
      // if (this.FirstName && this.FirstName !== undefined && this.FirstName !== null) {
      //   if (this.LastName && this.LastName !== undefined && this.LastName !== null) {
      // if (this.selectedType && this.selectedType !== undefined && this.selectedType !== null) {
      if (this.EmailFG && this.EmailFG !== undefined && this.EmailFG !== null) {
        if (this.validateEmailFG() === true) {
          if (
            this.PhoneNumberFG &&
            this.PhoneNumberFG !== undefined &&
            this.PhoneNumberFG !== null
          ) {
            if (
              this.TaxIDFG &&
              this.TaxIDFG !== undefined &&
              this.TaxIDFG !== null
            ) {
              if (
                this.Address1FG &&
                this.Address1FG !== undefined &&
                this.Address1FG !== null
              ) {
                if (
                  this.StateFG &&
                  this.StateFG !== undefined &&
                  this.StateFG !== null
                ) {
                  if (
                    this.CityFG &&
                    this.CityFG !== undefined &&
                    this.CityFG !== null &&
                    this.CityFG !== 'Select City'
                  ) {
                    // if (this.ZipCodeFG && this.ZipCodeFG !== undefined && this.ZipCodeFG !== null) {
                    if (this.numberFormZip.valid) {
                      if (
                        this.CountryFG &&
                        this.CountryFG !== undefined &&
                        this.CountryFG !== null
                      ) {
                        if (this.pickupTypeIDFG[0]) {
                          const body = {
                            Id: this.data[0].value.Id,
                            CompanyTitle: this.CompanyTitleFG,
                            // FirstName: this.FirstName,
                            // LastName: this.LastName,
                            Account: 'SAAS Facility',
                            Email: this.EmailFG,
                            PhoneNumber: this.PhoneNumberFG,
                            TaxID: this.TaxIDFG,
                            Country: this.CountryFG,
                            Address1: this.Address1FG,
                            Address2: this.Address2FG,
                            StateId: this.StateFG,
                            CityId: this.CityFG,
                            ZipCode: this.ZipCodeFG,
                            PickupType: this.sendpickupTypes,
                            MenuEdit: this.menuEditFG,
                          };
                          this.dialogRef.close(body);
                        } else {
                          this.requireField = true;
                          setTimeout(() => {
                            this.requireField = false;
                          }, 3000);
                        }
                      } else {
                        this.requireCountry = true;
                        setTimeout(() => {
                          this.requireCountry = false;
                        }, 3000);
                      }
                    } else {
                      this.requireZipCode = true;
                      setTimeout(() => {
                        this.requireZipCode = false;
                      }, 3000);
                    }
                  } else {
                    this.requireCity = true;
                    setTimeout(() => {
                      this.requireCity = false;
                    }, 3000);
                  }
                } else {
                  this.requireState = true;
                  setTimeout(() => {
                    this.requireState = false;
                  }, 3000);
                }
              } else {
                this.requireAddress1 = true;
                setTimeout(() => {
                  this.requireAddress1 = false;
                }, 3000);
              }
            } else {
              this.requireTaxID = true;
              setTimeout(() => {
                this.requireTaxID = false;
              }, 3000);
            }
          } else {
            this.requirePhoneNumber = true;
            setTimeout(() => {
              this.requirePhoneNumber = false;
            }, 3000);
          }
        } else {
          this.invalidEmail = true;
          setTimeout(() => {
            this.invalidEmail = false;
          }, 3000);
        }
      } else {
        this.requireEmail = true;
        setTimeout(() => {
          this.requireEmail = false;
        }, 3000);
      }
    } else {
      //     this.requireselectedType = true;
      //     setTimeout(() => {
      //       this.requireselectedType = false;
      //     }, 3000);
      //   }
      // } else {
      //       this.requireFirstName = true;
      //       setTimeout(() => {
      //         this.requireFirstName = false;
      //       }, 3000);
      //     }
      //   } else {
      //     this.requireLastName = true;
      //     setTimeout(() => {
      //       this.requireLastName = false;
      //     }, 3000);
      //   }
      // } else {
      this.requireCompanyTitle = true;
      setTimeout(() => {
        this.requireCompanyTitle = false;
      }, 3000);
    }
    // console.log(Body)
  }
}
