import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { FacilityAccountDialogComponent } from '../facility-account-dialog/facility-account-dialog.component';
import { MatDialog, MatDialogConfig, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { CreateMenuDialogComponent } from '../create-menu-dialog/create-menu-dialog.component';
import { UpoloadMenuDialogComponent } from '../upoload-menu-dialog/upoload-menu-dialog.component';
import { UnsuccessfulMenuDialogComponent } from '../unsuccessful-menu-dialog/unsuccessful-menu-dialog.component';
import { SuccessfulMenuDialogComponent } from '../successful-menu-dialog/successful-menu-dialog.component';
import { AccountcompletionMenuDialogComponent } from '../accountcompletion-menu-dialog/accountcompletion-menu-dialog.component';
import { AccountCreationDialogComponent } from '../account-creation-dialog/account-creation-dialog.component';
import { AddCategoryDialogComponent } from './add-category-dialog/add-category-dialog.component';
import { DeletedialogComponent } from '../deletedialog/deletedialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { CustomerService } from '../../shared/services/customer.service';
import { from } from 'rxjs';
import { AddMainCategoryDialogComponent } from './add-main-category-dialog/add-main-category-dialog.component';
import { DeleteProductDialogComponent } from './delete-product-dialog/delete-product-dialog.component';
import { AppState } from 'src/app/shared/app-state';
import { DeleteCategoryDialogComponent } from './delete-category-dialog/delete-category-dialog.component';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DeleteGrabMenuComponent } from './delete-grab-menu/delete-grab-menu.component';
import { UpdateCategoryDataDialogComponent } from './update-category-data-dialog/update-category-data-dialog.component';
import { UpdateCategoryDialogComponent } from './update-category-dialog/update-category-dialog.component';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { SpinnerService } from 'src/app/core/spinner.service';
import { SelectionModel } from '@angular/cdk/collections';
export interface PeriodicElement {
  ItemName: string;
  ProductId: string;
  Category: string;
  UnitValue: string;
  Weight: string;
  AddedBy: string;
  DateAdded: string;
  Delete: any;
}
const ELEMENT_DATA: PeriodicElement[] = [];
export interface PeriodicElements {
  CatergoryName: string;
  ProductID: number;
  Items: string;
  AddedByy: string;
  DateAddedd: string;
  Activity: string;
}
const ELEMENT_DATAS: PeriodicElements[] = [];

@Component({
  selector: 'app-menu-view',
  templateUrl: './menu-view.component.html',
  styleUrls: ['./menu-view.component.scss'],
})
export class MenuViewComponent implements OnInit, AfterViewInit, AfterViewChecked {
  ProductCount: any;
  clientdata: any;
  MenuItems: any;
  clientstatus: any;
  checkBoxTrue = false;
  selectedPageSize = [10, 25, 50, 100];
  Menuscategory: any;
  frrunderfg = true;
  userRole: any;
  [x: string]: any;
  Menus: any;
  RecordCount: any;
  ProductArray = [];
  pageSize: number = 10;
  createdbyuser: string;
  createdby: string;
  MenuCount: any;
  deleteData = [];
  VisibleAdminEditOnly: boolean;
  VisibleAdminDeleteOnly: boolean;
  displayedColumns: string[] = ['ProductId', 'ItemName', 'Category', 'UnitValue', 'Weight', 'AddedBy', 'DateAdded', 'Edit'];
  displayedColumnss: string[] = ['ProductID', 'CatergoryName', 'AddedByy', 'DateAddedd', 'Edit'];
  // dataSource = new MatTableDataSource(Object.assign(ELEMENT_DATA));
  data = Object.assign(ELEMENT_DATA);
  dataSource = new MatTableDataSource<Element>(this.data);
  selection = new SelectionModel<Element>(true, []);
  dataSources = new MatTableDataSource(ELEMENT_DATAS);
  category: any;
  sortColumn: any = null;
  isLoading = true;
  clients: any;
  MenuType: any;
  clientId: any;
  VisibleForAdminBulkMenu: boolean;
  pageNumber: any = 1;
  pageNumberCategory: any = 1;
  pageSizeCategory: any = 10;
  AddNewItemPermission: boolean;
  EditItemPermission: boolean;
  DeleteItemPermission: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator2: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private state: AppState,
    private router: Router,
    private route: ActivatedRoute,
    private CustomerService: CustomerService,
    private AdminService: AdmintoolsService,
    private spinnerService: SpinnerService
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSources.paginator = this.paginator2;
    this.dataSources.sort = this.sort;
  }

  ngOnInit() {
    this.userRole = this.state.user.User.Roles[0].Name;
    this.selection.isSelected = this.isChecked.bind(this);
    this.VisibleAdminEditOnly = getFeature('AdminEditMenu');
    this.VisibleAdminDeleteOnly = getFeature('AdminDeleteMenu');
    this.VisibleForAdminBulkMenu = getFeature('bulkMenuItemDeleted');
    this.AddNewItemPermission = getFeature('addMenuItem');
    this.EditItemPermission = getFeature('editMenuItem');
    this.DeleteItemPermission = getFeature('deleteMenuItem');

    this.menuGetData();
    this.categoryGetData();
    this.productById();
    this.state.isSpinnerVisible = true;

    this.pageSize = 10;
    this.dataSource.paginator = this.paginator;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const displayedRows = this.dataSource.connect().value.length;
    let isAllSelected = numSelected === displayedRows;

    if (isAllSelected) {
      isAllSelected = this.isAllDisplayedSelected();
    }
    return isAllSelected;
  }

  isAllDisplayedSelected() {
    let isAllDisplayedSelected = true;

    if (this.selection.selected.length === 0) {
      return this.isAllSelected();
    }

    this.dataSource.connect().value.some((element) => {
      if (!this.selection.isSelected(element)) {
        isAllDisplayedSelected = false;
        return isAllDisplayedSelected;
      }
    });
    return isAllDisplayedSelected;
  }
  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el) => el['ProductId'] === row.ProductId);
    if (found) {
      return true;
    }
    return false;
  }
  checked(row: any) {
    this.selection.select(row);
    var found = this.selection.selected.find((el) => el['ProductId'] === row.ProductId);
    if (found) {
      return true;
    }
    return false;
  }
  unchecked(row: any) {
    var found = this.selection.selected.find((el) => el['ProductId'] === row.ProductId);
    this.selection.deselect(found);
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
    if (found) {
      return true;
    } else {
      return false;
    }
  }
  ngAfterViewChecked() {
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
      this.cdRef.detectChanges();
    }
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // this.selection.clear()  :
    this.isViewableSelected() ? this.deselectRows() : this.selectRows();
  }
  isViewableSelected() {
    return this.isAllSelected() || this.isAllDisplayedSelected();
  }

  deselectRows() {
    const itemsToBeUnselected = this.dataSource.connect().value;
    itemsToBeUnselected.forEach((element) => {
      var found = this.selection.selected.find((el) => el['ProductId'] === element['ProductId']);
      this.selection.deselect(found);
    });
    if (this.selection.selected.length > 0) {
      this.checkBoxTrue = true;
    } else {
      this.checkBoxTrue = false;
    }
  }

  selectRows() {
    const currentlyDisplayedRows = this.dataSource.connect().value;

    for (let index = 0; index < currentlyDisplayedRows.length; index++) {
      this.selection.select(currentlyDisplayedRows[index]);
    }
  }
  removeSelectedRows(row) {
    console.log(row.ProductId);
    const dialogRef = this.dialog.open(DeleteProductDialogComponent, {
      width: '700px',
      // height: "520px",
      panelClass: 'custom-Filterdialog-container',
      data: {
        productId: row.ProductId,
        name: row.Name,
        clientId: this.Menus.ClientId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.menuGetData();
        this.state.isSpinnerVisible = false;
        this.checkBoxTrue = false;
        this.selection = new SelectionModel<Element>(true, []);
      }
    });
  }
  onChangePage(e) {
    e ? this.pageNumberCategory = e : null;
    this.isLoading = true;
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getAllCategories(this.pageNumberCategory, this.pageSizeCategory, this.sortColumn, this.route.snapshot.params['id']).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dataSources = data['Result'];
      this.Menuscategory = data.Result;
      this.RecordCount = data.RecordCount;
    });
  }

  //pagination menu page
  onChangeMenuPage(e) {
    e ? this.pageNumber = e : null;
    this.isLoading = true;
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getAllFacilityMenus(this.pageNumber, this.pageSize, this.route.snapshot.params['id'], this.sortColumn).subscribe((data) => {
      this.isLoading = false;
      this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result;
      this.dataSource.data = data['Result'];
      this.MenuCount = data.RecordCount;
    });
    // this.selection = new SelectionModel<Element>(true, []);
  }
  categoryGetData() {
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getAllCategories(this.pageNumberCategory, this.pageSizeCategory, this.sortColumn, this.route.snapshot.params['id']).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.isLoading = false;
        this.dataSources = data['Result'];
        this.Menuscategory = data.Result;
        this.RecordCount = data.RecordCount;
      },
      (error) => (this.isLoading = false)
    );
  }
  menuGetData() {
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getAllFacilityMenus(this.pageNumber, this.pageSize, this.route.snapshot.params['id'], this.sortColumn).subscribe((data) => {
      // this.spinnerService.stop(spinnerRef);
      // this.Menus = data.Result;
      // this.MenuCount = data.RecordCount;
      // this.dataSource.data = this.Menus;
      // this.dataSource = new MatTableDataSource(this.dataSource.data);
      // this.dataSource.sort = this.sort;
      // this.paginator.pageIndex = this.pageNumber - 1;
      this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result;
      this.dataSource.data = this.Menus;
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      this.MenuCount = data.RecordCount;
      // for (var i = 0; i < data.Result.length; i++) {
      //   var product_ID = data.Result[i].ProductId;
      //   this.ProductArray.push(product_ID)
      // }
      if (data.Result.lenght > 0) {
        this.createdbyuser = data.Result[0].CreatedByUser[0];
        this.createdby = data.Result[0].CreatedByUser[0].FirstName;
      }
    });
  }
  productById() {
    // var spinnerRef = this.spinnerService.start();
    this.CustomerService.getFacilityMenu(this.route.snapshot.params['id']).subscribe((data) => {
      // this.spinnerService.stop(spinnerRef);
      this.clients = data.Result[0];
      if (this.userRole === 'Facilitator') {
        if (this.clients.ClientCategory[0].Title === 'Facility') {
          if (this.clients.FacilityGroupId) {
            this.frrunderfg = false;
          }
        }
      }
      this.clientstatus = data.Result[0].ApprovalStatus[0].Title;
      this.MenuType = this.clients.MenuType.Title;
    });
  }
  deleteCategorieItem(CategorieItem) {
    const dialogRef = this.dialog.open(DeleteCategoryDialogComponent, {
      width: '700px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ SectionId: CategorieItem }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categoryGetData();
        this.menuGetData();
        this.state.isSpinnerVisible = false;
      }
    });
  }
  additems() {
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      width: '1000px',
      // height: "450px",
      panelClass: 'custom-Filterdialog-container',
      data: this.route.snapshot.params['id'],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.menuGetData();
      }
      // this.menuGetData();
    });
  }
  addcategory() {
    const dialogRef = this.dialog.open(AddMainCategoryDialogComponent, {
      width: '670px',
      // data: this.Menus
      panelClass: 'custom-Filterdialog-container',
      data: this.route.snapshot.params['id'],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categoryGetData();
      }
      // this.categoryGetData();
    });
  }
  updateCategory(CategoryData) {
    const dialogRef = this.dialog.open(UpdateCategoryDataDialogComponent, {
      width: '670px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ CategoryData }, { clientId: this.route.snapshot.params['id'] }],
      // clientId: this.route.snapshot.params["id"]
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categoryGetData();
      }
    });
  }
  updateitems(productdata) {
    const dialogRef = this.dialog.open(UpdateCategoryDialogComponent, {
      width: '1000px',
      // height: '450px',
      panelClass: 'custom-Filterdialog-container',
      data: [{ productdata }, { clientId: this.route.snapshot.params.id }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.menuGetData();
      }
    });
  }
  mapColumnName(columnName) {
    if (columnName == 'CatergoryName') {
      return 'Name';
    }
    if (columnName == 'ProductID') {
      return 'SequenceId';
    }
    if (columnName == 'AddedByy') {
      return 'CreatedBy';
    }
    if (columnName == 'DateAddedd') {
      return 'CreatedOn';
    }
  }
  sortTable(event) {
    var spinnerRef = this.spinnerService.start();
    this.sortColumn = this.mapColumnName(event.active);
    if (event.direction == 'desc') {
      this.sortColumn = '-' + this.sortColumn;
    }
    this.pageNumberCategory = 1;
    this.CustomerService.getAllCategoriesSorting(this.pageNumberCategory, this.pageSizeCategory, this.sortColumn, this.route.snapshot.params['id']).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.dataSources = data['Result'];
      this.Menuscategory = data.Result;
      this.RecordCount = data.RecordCount;
    });
  }
  //Product Sorting
  mapColumnNames(columnName) {
    if (columnName == 'ItemName') {
      return 'Name';
    }
    if (columnName == 'ProductId') {
      return 'SequenceId';
    }
    if (columnName == 'Category') {
      return 'Category';
    }
    if (columnName == 'UnitValue') {
      return 'Price';
    }
    if (columnName == 'Weight') {
      return 'Weight';
    }
    if (columnName == 'AddedBy') {
      return 'CreatedBy';
    }
    if (columnName == 'DateAdded') {
      return 'CreatedOn';
    }
  }
  sortTableForProduct(event) {
    this.pageNumber = 1;
    var spinnerRef = this.spinnerService.start();
    this.sortColumn = this.mapColumnNames(event.active);
    if (event.direction == 'desc') {
      this.sortColumn = '-' + this.sortColumn;
    }
    this.CustomerService.getAllFacilityMenusSorted(this.pageNumber, this.pageSize, this.route.snapshot.params['id'], this.sortColumn).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result[0];
      this.MenuCount = data.RecordCount;
      this.dataSource.data = data.Result;
    });
  }
  backtoCustomer() {
    this.router.navigate(['FacilityDetails/' + this.route.snapshot.params['id'] + '']);
    // this.router.navigate(["Customers"]);
  }
  updatemenu() {
    this.clients.Name = this.clients.Name.trim();
    this.clients.WayPointID = this.clients.WayPointID;
    let grabdata = {
      Name: this.clients.Name,
      WayPointID: this.clients.WayPointID,
    };
    if (this.clients.length === 0) {
      alert("Zero Item's Updated");
    } else {
      var spinnerRef = this.spinnerService.start();
      this.CustomerService.updatemenu(grabdata).subscribe((data) => {
        this.spinnerService.stop(spinnerRef);
        if (data['message'] === 'Sequence contains more than one element') {
          alert('Client already exists as grab');
        } else if (data['message'] === 'succeeded') {
          this.menuGetData();
          this.spinnerService.stop(spinnerRef);
        } else {
          alert(data['message']);
        }
      });
    }
  }
  deletemenu() {
    const dialogRef = this.dialog.open(DeleteGrabMenuComponent, {
      width: '670px',
      panelClass: 'custom-Filterdialog-container',
      data: {
        Count: this.MenuCount,
        sortColumn: this.sortColumn,
        clientId: this.route.snapshot.params['id'],
        clientname: this.clients.Name,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        var spinnerRef = this.spinnerService.start();
        this.CustomerService.getAllFacilityMenus(1, this.pageSize, this.route.snapshot.params['id'], this.sortColumn).subscribe((data) => {
          this.spinnerService.stop(spinnerRef);
          this.Menus = data.Result;
          this.MenuCount = data.RecordCount;
          this.dataSource.data = this.Menus;
          this.dataSource = new MatTableDataSource(this.dataSource.data);
          if (this.MenuCount === 0) {
            alert("Successfully Menu Item's Deleted.!!");
          }
        });
        this.state.isSpinnerVisible = false;
      }
    });
  }
  checkPermission() {
    if (this.userRole != 'Read-Only Admin') {
      if (this.clients) return true
    } else return false
  }
}
