import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AppState } from 'src/app/shared/app-state';
import { PickUps } from '../../../../app/models/pickups.model';
import { SavePickupmodalComponent } from '../../Request_pickup/save-pickupmodal/save-pickupmodal.component';
import { DeleteMenuComponent } from '../delete-menu/delete-menu.component';
import { SaveEditMenumodalComponent } from '../save-edit-menumodal/save-edit-menumodal.component';
import { SpinnerService } from 'src/app/core/spinner.service';
import { DatePostFormatPipe } from 'src/app/shared/pipes/dateformat.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { orCustomMaterialList } from 'src/app/shared/services/or-custom-material-list.model';

export interface PeriodicElement {
  name: string;
  Cost: number;
  Weight: number;
  Quantity: number;
}

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss']
})
export class EditMenuComponent implements OnInit, OnDestroy {
  // activeState = 'Starch';

  displayedColumns: string[] = ['name', 'Cost', 'Weight', 'Quantity'];
  displayedColumnsCompost: string[] = ['Title', 'Weight', 'add'];
  ELEMENT_DATA: PeriodicElement[] = [];
  dataSource: any;
  transaction: any = [];
  pickupRequestdata: any = [];
  sendRequest: any = [];
  pickup: any;
  deleteRequest: any = [];
  totalItems = 0;
  public createForm: FormGroup;
  imgSrc = '../../assets/images/editicon.png';
  wasClicked = false;
  requestData: { PickupRequest_Product: any; ClientId: number };
  dataedit: boolean;
  requestDataDisabled: boolean;
  success: boolean;
  SeparatedCombined: any;
  deleteSource: any;
  confirmbutton: boolean;
  showEditble: boolean;
  reqestType: any;
  CombinedRequest: boolean;
  Separated: boolean;
  foodRequest: boolean;
  separeatePickupID: any;
  combinedPickupID: any;
  separatedCompost: any;
  dataSourceCombined: any;
  dataSourceCombinedData: any;
  totalItemValue: number;
  totalWeight: number;
  totalQuantity: number;
  totalItemValueWithUnit: string;
  totalWeightwithUnit: string;
  ShippingID: any;
  nonprofitUpdate: boolean;
  selectedRecipient: any;
  error: never;
  spinnerRef: any;
  relaodinfo: any;

  constructor(
    public dialogRef: MatDialogRef<EditMenuComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private spinnerService: SpinnerService,
    public postDateService: DatePostFormatPipe,
    private formBuilder: FormBuilder,
    private _rdata: modalDataService,
    private pickupService: PickUpService,
    private state: AppState
  ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this._rdata.basicreloaddataInfo2Observe.subscribe(res => {
      this.relaodinfo = JSON.parse(res);
    });
    this.createForm = this.formBuilder.group({
      quantity: ['', Validators.compose([Validators.required])],
      Weight: ['', Validators.compose([Validators.required])],
      Cost: ['', Validators.compose([Validators.required])]
    });
    this.dataedit = true;
    // this.createPickuplist = true;
    this.reqestType = JSON.parse(localStorage.getItem('EditMenuType'));
    if (this.reqestType === 'CombinedEditMenu') {
      this.CombinedRequest = true;
    } else if (this.reqestType === 'SeparatedEditMenu') {
      this.Separated = true;
    } else if (this.reqestType === 'foodTypeEditMenu') {
      this.foodRequest = true;
    }
    this.pickupRequest();
    // this.separetaedRequest();
  }
  pickupRequest() {
    const listproduct = [];
    if (this.foodRequest === true) {
      this.data[0].pickupdata.PickupRequest[0].PickupRequest_Product.map(
        value => {
          if (value.wasClicked === false) {
            value.imgSrc = '../../assets/images/editicon.png';
            value.buttonDisabled = false;
          } else {
            value.imgSrc = '../../assets/images/editicon.png';
          }
          listproduct.push(value);
          this.dataSource = listproduct;
          this.deleteSource = this.dataSource;
          this.totalItems = this.deleteSource.length;
        }
      );
    }
    if (this.CombinedRequest === true) {
      this.data[0].pickupdata.PickupRequest[0].PickupRequest_OrganicRecycling.map(
        value => {
          value.SeparatedCompost[0] = { Description: 'Combined' };
          value.Weight = value.Weight * 0.0625;
          if (value.wasClicked === false) {
            value.imgSrc = '../../assets/images/editicon.png';
            value.buttonDisabled = false;
          } else {
            value.imgSrc = '../../assets/images/editicon.png';
          }
          listproduct.push(value);
          this.dataSourceCombinedData = listproduct;
          this.deleteSource = this.dataSourceCombinedData;
          this.totalItems = this.deleteSource.length;
        }
      );
    }
    if (this.Separated === true) {
      var spinnerRef = this.spinnerService.start();
      this.data[0].pickupdata.PickupRequest[0].PickupRequest_OrganicRecycling.map(
        value => {
          value.Weight = value.Weight * 0.0625;
          if (value.wasClicked === false) {
            value.imgSrc = '../../assets/images/editicon.png';
            value.buttonDisabled = false;
          } else {
            value.imgSrc = '../../assets/images/editicon.png';
          }
          listproduct.push(value);
          // this.dataSourceCombinedData = listproduct;
          // this.deleteSource = this.dataSourceCombinedData;
          // this.totalItems = this.deleteSource.length;
        }
      );
      this.pickupService.SeparatedCompost().subscribe(data => {
        this.spinnerService.stop(spinnerRef);
        this.separatedCompost = orCustomMaterialList(data.Result);
        var filteredArray = this.separatedCompost.filter(client => {
          return (
            listproduct.filter(editble => {
              return editble.SeparatedCompost[0].ID == client.ID;
            }).length == 0
          );
        });
        filteredArray.map(value => {
          value.Weight = '';
          value.SeparatedCompost = [value];
          if (listproduct.length > 0) {
            value.PickupImageURL = listproduct[0].PickupImageURL;
          }
          value.RequestId = this.data[0].pickupdata.RequestId;
          value.SeparatedCompostId = value.ID;
          value.ID = null;
          if (value.wasClicked === false) {
            value.imgSrc = '../../assets/images/editicon.png';
            value.buttonDisabled = false;
          } else {
            value.imgSrc = '../../assets/images/editicon.png';
          }
          listproduct.push(value);
        });
        this.dataSourceCombinedData = listproduct;
        this.deleteSource = this.dataSourceCombinedData;
        this.totalItems = this.deleteSource.length;
      });
    }
  }
  addItemsCompost(transaction, value) {
    if (transaction.editble === true) {
      if (transaction.Weight > 0) {
        if (transaction.Weight) {
          if (transaction.wasClicked === false) {
            transaction.wasClicked = true;
            transaction.buttonDisabled = false;
            transaction.showValidators = false;
            transaction.imgSrc = '../../assets/images/editicon.png';
            for (let i = 0; i < this.sendRequest.length; i++) {
              if (transaction.ID === this.sendRequest[i].ID) {
                this.sendRequest.splice(i, 1);
              }
            }
          } else {
            transaction.wasClicked = false;
            transaction.buttonDisabled = true;
            transaction.imgSrc = '../../assets/images/circle12.png';
            const body = {
              Name: transaction.SeparatedCompost[0].Description,
              SeparatedCompostId: transaction.SeparatedCompostId,
              RequestId: transaction.RequestId,
              PickupImageURL: transaction.PickupImageURL,
              ID: transaction.ID,
              // OrganicRecyclingPickupTypeId: transaction.OrganicRecyclingPickupTypeId,
              // Cost: transaction.Cost,
              Weight: transaction.Weight
            };
            this.sendRequest.push(body);
          }
          this.dataedit = false;
          this.success = true;
        } else {
          transaction.showValidators = true;
          setTimeout(() => {
            transaction.showValidators = false;
          }, 3000);
        }
      } else {
        transaction.showValidators = true;
        setTimeout(() => {
          transaction.showValidators = false;
        }, 3000);
      }
    } else {
      transaction.showEditble = true;
      setTimeout(() => {
        transaction.showEditble = false;
      }, 3000);
    }
  }

  addItems(transaction, value) {
    if (transaction.editble === true) {
      if (value > 0 && transaction.Weight > 0 && transaction.Cost > 0) {
        if (value && transaction.Cost && transaction.Weight) {
          if (transaction.wasClicked === false) {
            transaction.wasClicked = true;
            transaction.buttonDisabled = false;
            transaction.showValidators = false;
            transaction.imgSrc = '../../assets/images/editicon.png';
            for (let i = 0; i < this.sendRequest.length; i++) {
              if (transaction.ProductId === this.sendRequest[i].ProductId) {
                this.sendRequest.splice(i, 1);
              }
            }
          } else {
            transaction.wasClicked = false;
            transaction.buttonDisabled = true;
            transaction.imgSrc = '../../assets/images/circle12.png';
            const body = {
              Name: transaction.Product[0].Name,
              Quantity: +value,
              RequestId: transaction.RequestId,
              ProductId: transaction.ProductId,
              SectionId: transaction.SectionId,
              Description: transaction.Section[0].Description,
              Cost: transaction.Cost,
              Weight: transaction.Weight
            };
            this.sendRequest.push(body);
          }
          this.dataedit = false;
          this.success = true;
        } else {
          transaction.showValidators = true;
          setTimeout(() => {
            transaction.showValidators = false;
          }, 3000);
        }
      } else {
        transaction.showValidators = true;
        setTimeout(() => {
          transaction.showValidators = false;
        }, 3000);
      }
    } else {
      transaction.showEditble = true;
      setTimeout(() => {
        transaction.showEditble = false;
      }, 3000);
    }
  }
  requestConfirmCompostList() {
    let request = [];
    request = this.sendRequest;
    let deleterequest = [];
    deleterequest = this.deleteRequest;
    if (request.length > 0 || deleterequest.length > 0) {
      if (deleterequest.length > 0) {
        for (let i = 0; i < deleterequest.length; i++) {
          let deleteData = deleterequest[i];
          this.dialogRef.close('res');
          this.pickupService
            .deleteCompostMenuItem(deleteData.ID)
            .subscribe(data => { });
        }
      }
      if (request.length > 0) {
        this.pickupRequest();
        // this.dataSourceCombinedData.map(value => {
        //   value.imgSrc = '../../assets/images/editicon.png';
        //   value.showValidators = false;
        //   value.buttonDisabled = false;
        //   value.wasClicked = true;
        // });
        for (let i = 0; i < request.length; i++) {
          let data = request[i];
          if (data.ID) {
            let payload = {
              RequestId: data.RequestId,
              ID: data.ID,
              // OrganicRecyclingPickupTypeId: data.OrganicRecyclingPickupTypeId,
              Weight: data.Weight * 16,
              SeparatedCompostId: data.SeparatedCompostId,
              PickupImageURL: data.PickupImageURL
              // Quantity: data.Quantity,
            };
            this.dialogRef.close('res');
            this.pickupService
              .editMenuCompostRequest(payload)
              .subscribe(data => { });
          }
        }
        for (let i = 0; i < request.length; i++) {
          let data = request[i];
          if (data.ID === null) {
            let payload = {
              RequestId: data.RequestId,
              // OrganicRecyclingPickupTypeId: data.OrganicRecyclingPickupTypeId,
              Weight: data.Weight * 16,
              SeparatedCompostId: data.SeparatedCompostId,
              PickupImageURL: data.PickupImageURL
              // Quantity: data.Quantity,
            };
            this.dialogRef.close('res');
            this.pickupService
              .addSeparatedItemsRequest(payload)
              .subscribe(data => { });
          }
        }
      }
      this.relaodinfo.reloadmenuData = true;
      this._rdata.updatereloaddataclientBasicInfo2(
        JSON.stringify(this.relaodinfo)
      );
      const dialogRef = this.dialog.open(SaveEditMenumodalComponent, {
        width: '400px',
        height: '300px',
        data: [{ id: this.data[0].pickupdata.DeliveryId }]
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else {
      this.confirmbutton = true;
      setTimeout(() => {
        this.confirmbutton = false;
      }, 3000);
    }
  }

  requestConfirmList() {
    let request = [];
    request = this.sendRequest;
    let deleterequest = [];
    deleterequest = this.deleteRequest;
    var itemsProcessed = 0;
    var deleteProcessed = 0;
    if (request.length > 0 || deleterequest.length > 0) {
      if (request.length > 0) {
        this.spinnerRef = this.spinnerService.start();
        this.pickupRequest();
        this.dataSource.map(value => {
          // value.imgSrc = '../../assets/images/editicon.png';
          value.showValidators = false;
          value.buttonDisabled = false;
          value.wasClicked = true;
        });
        for (let i = 0; i < request.length; i++) {
          let data = request[i];
          let payload = {
            RequestId: data.RequestId,
            ProductId: data.ProductId,
            SectionId: data.SectionId,
            Weight: data.Weight,
            Cost: data.Cost,
            Quantity: data.Quantity
          };
          // this.dialogRef.close('res');
          this.pickupService.editMenuItemsRequest(payload).subscribe(
            data => {
              itemsProcessed++;
              if (itemsProcessed === request.length) {
                if (deleterequest.length > 0) {
                  for (let i = 0; i < deleterequest.length; i++) {
                    let deleteData = deleterequest[i];
                    // this.dialogRef.close('res');
                    this.pickupService
                      .deleteMenuItem(
                        deleteData.RequestId,
                        deleteData.ProductId
                      )
                      .subscribe(data => {
                        deleteProcessed++;
                        if (deleteProcessed === deleterequest.length) {
                          if (
                            this.data[0].pickupdata.ShippingID &&
                            this.data[0].pickupdata.Status[0].Title !==
                            'Cancelled' &&
                            this.data[0].pickupdata.Status[0].Title ===
                            'Claimed'
                          ) {
                            this.UpdatePickUpRoadiesStatus();
                          } else {
                            this.savePickup();
                          }
                        }
                      });
                  }
                } else {
                  if (
                    this.data[0].pickupdata.ShippingID &&
                    this.data[0].pickupdata.Status[0].Title !== 'Cancelled' &&
                    this.data[0].pickupdata.Status[0].Title === 'Claimed'
                  ) {
                    this.UpdatePickUpRoadiesStatus();
                  } else {
                    this.spinnerService.stop(this.spinnerRef);

                    this.savePickup();
                  }
                }
              }
            },
            error => {
              this.spinnerService.stop(this.spinnerRef);
              // alert(JSON.stringify(error));
            }
          );
        }
      } else {
        this.spinnerRef = this.spinnerService.start();
        if (deleterequest.length > 0) {
          for (let i = 0; i < deleterequest.length; i++) {
            let deleteData = deleterequest[i];

            this.pickupService
              .deleteMenuItem(deleteData.RequestId, deleteData.ProductId)
              .subscribe(data => {
                deleteProcessed++;
                if (deleteProcessed === deleterequest.length) {
                  if (
                    this.data[0].pickupdata.ShippingID &&
                    this.data[0].pickupdata.Status[0].Title !== 'Cancelled' &&
                    this.data[0].pickupdata.Status[0].Title === 'Claimed'
                  ) {
                    this.UpdatePickUpRoadiesStatus();
                  } else {
                    this.spinnerService.stop(this.spinnerRef);
                    this.savePickup();
                  }
                }
              });
          }
        }
      }
    } else {
      this.confirmbutton = true;
      setTimeout(() => {
        this.confirmbutton = false;
      }, 3000);
    }
  }

  onSearchChange(event, transaction) {
    transaction.editble = true;
    this.dataedit = true;
    this.success = false;
  }

  savePickup() {
    this.dialogRef.close('res');
    this.relaodinfo.reloadmenuData = true;
    this._rdata.updatereloaddataclientBasicInfo2(
      JSON.stringify(this.relaodinfo)
    );
    const dialogRef = this.dialog.open(SaveEditMenumodalComponent, {
      width: '400px',
      height: '300px',
      data: [{ id: this.data[0].pickupdata.DeliveryId }]
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  UpdatePickUpRoadiesStatus() {
    this.pickupService
      .getPickUpDetail(this.data[0].pickupdata.DeliveryId)
      .subscribe(data => {
        this.pickup = data.Result;
        const pickups: PickUps[] = [];
        this.totalItemValue = 0;
        this.totalWeight = 0;
        this.totalQuantity = 0;
        for (
          let i = 0;
          i <=
          this.pickup[0].PickupRequest[0]['PickupRequest_Product'].length - 1;
          i++
        ) {
          const pickUnit: PickUps = this.pickup[0].PickupRequest[0][
            'PickupRequest_Product'
          ][i];

          let pickupDetails = this.pickup[0].PickupRequest[0][
            'PickupRequest_Product'
          ][i];
          //total value calculation
          const itemValue = pickupDetails.Quantity * pickupDetails.Cost;
          this.totalItemValue = this.totalItemValue + itemValue;
          this.totalItemValueWithUnit = '$ ' + this.totalItemValue.toFixed(2);
          //weight-calculation
          const itemWeightValue = pickupDetails.Quantity * pickupDetails.Weight;
          this.totalWeight = this.totalWeight + itemWeightValue;
          // this.totalWeightwithUnit = this.totalWeight + " Oz";
          this.totalWeightwithUnit = this.totalWeight + '';

          //weight-calculation
          this.totalQuantity = this.totalQuantity + pickupDetails.Quantity;

          pickups.push(pickUnit);
        }
        if (this.data[0].pickupdata.ShippingID) {
          this.ShippingID = this.data[0].pickupdata.ShippingID;
        } else {
          this.ShippingID = 0;
        }
        if (this.ShippingID) {
          this.nonprofitUpdate = true;
        }
        let payloaddata = {
          // RecipientName: this.pickup[0].PickupRequest[0].Recipient,
          update: this.nonprofitUpdate,
          RecipientId: this.data[0].pickupdata.PickupRequest[0]
            .NonProfitReceiverId,
          Quantity: this.totalQuantity,
          Value: this.totalItemValue,
          Weight: this.totalWeightwithUnit,
          Description:
            'Pickup Request From Client ' +
            this.data[0].pickupdata.PickupRequest[0].Clients[0].Name,
          ClientId: this.data[0].pickupdata.PickupRequest[0].ClientId,
          Request_Id: this.data[0].pickupdata.RequestId,
          // DeliveryBetweenEnd: PickupBeforedata.toISOString(),
          DeliveryBetweenEnd: this.postDateService.transform(
            this.data[0].pickupdata.PickupBefore,
            this.data[0].pickupdata.PickupRequest[0].PickupRequest[0].Clients[0]
              .TimeZone
          ),
          DeliveryBetweenStart: this.postDateService.transform(
            this.data[0].pickupdata.PickupAfter,
            this.data[0].pickupdata.PickupRequest[0].PickupRequest[0].Clients[0]
              .TimeZone
          ),
          PickupAfter: this.postDateService.transform(
            this.data[0].pickupdata.PickupAfter,
            this.data[0].pickupdata.PickupRequest[0].PickupRequest[0].Clients[0]
              .TimeZone
          ),
          ShipmentId: this.ShippingID,
          Status: null
        };
        this.pickupService.updateRoadiesDelivery(payloaddata).subscribe(
          data => {
            this.spinnerService.stop(this.spinnerRef);
            if ('error' in data) {
              this.error = data['message'];
              if (this.error === 'unprocessable request parameters') {
                alert('Unprocessable request parameters');
              } else {
                alert(this.error);
              }
            }
            this.savePickup();
          },
          error => {
            this.state.isSpinnerVisible = false;
            // alert(JSON.stringify(error));
          }
        );
      });
  }
  deletemenuCompost(product) {
    const dialogRef = this.dialog.open(DeleteMenuComponent, {
      width: '700px',
      // height: "520px",
      data: [{ productData: product, Type: 'Compost' }]
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.deleteSource.indexOf(product);
        for (let i = 0; i < this.deleteRequest.length; i++) {
          if (product.ID === this.deleteRequest[i].ID) {
            this.deleteRequest.splice(i, 1);
          }
        }
        const body = {
          RequestId: product.RequestId,
          ID: product.ID
        };
        this.deleteRequest.push(body);
        this.deleteSource.splice(index, 1);
        this.dataSourceCombinedData = new MatTableDataSource<Element>(
          this.deleteSource
        );
        this.totalItems = this.deleteSource.length;
      }
    });
  }
  ngOnDestroy() {
    this.data[0].pickupdata.PickupRequest[0].PickupRequest_OrganicRecycling.map(
      value => {
        value.editble = false;
        value.Weight = value.Weight * 16;
      }
    );
  }
  deletemenu(product) {
    const dialogRef = this.dialog.open(DeleteMenuComponent, {
      width: '700px',
      // height: "520px",
      data: [
        {
          productData: product,
          id: this.data[0].pickupdata.DeliveryId,
          Type: 'food'
        }
      ]
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.deleteSource.indexOf(product);
        for (let i = 0; i < this.deleteRequest.length; i++) {
          if (product.ProductId === this.deleteRequest[i].ProductId) {
            this.deleteRequest.splice(i, 1);
          }
        }
        const body = {
          RequestId: product.RequestId,
          ProductId: product.ProductId
        };
        this.deleteRequest.push(body);
        this.deleteSource.splice(index, 1);
        this.dataSource = new MatTableDataSource<Element>(this.deleteSource);
        this.totalItems = this.deleteSource.length;
      }
    });
  }
}
