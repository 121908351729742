import { Component, OnInit, Inject } from '@angular/core';
import { InviteuserdialogComponent } from '../inviteuserdialog/inviteuserdialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment-timezone';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateMenuDialogComponent } from '../../create-menu-dialog/create-menu-dialog.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { CustomerService } from '../../../shared/services/customer.service';
import { EditBusinessDialogComponent } from '../../edit-business-dialog/edit-business-dialog.component';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AccountcompletionMenuDialogComponent } from '../../accountcompletion-menu-dialog/accountcompletion-menu-dialog.component';
import { AccountService } from 'src/app/shared/account.service';
import { AppState } from 'src/app/shared/app-state';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { AddfacilityfgComponent } from '../../customers/addfacilityfg/addfacilityfg.component';
import { EditMYearlyComponent } from '../../customers/edit-myearly/edit-myearly.component';
import { EditfacilityComponent } from 'src/app/SAAS/regModule/saassign-up/editfacility/editfacility.component';
import { TimezoneService } from 'src/app/shared/pipes/timezone.service';
import { DatePostFormatPipe } from 'src/app/shared/pipes/dateformat.service';
import { TimezonedatepostService } from 'src/app/shared/pipes/timezonedatepost.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

declare var require;

export class AllUsers {
  constructor(public Name: string, public Id: string) { }
}

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss'],
})
export class EdituserComponent implements OnInit {
  public numberForm: FormGroup;
  modalclose: string;
  info: any;
  existingadmin: boolean;
  multipleFGadd = [];
  existingadminno: boolean;
  requireFieldLast: boolean;
  requireFieldEmail: boolean;
  Phone: any;
  adminEmail: any;
  adminLastName: any;
  adminFirstName: any;
  radioOptions = 'option 2';
  requireField = false;
  multipleFGaddFinal: any[];
  invalidEmail = false;
  allDeliveryStatus: any;
  selectedAccountType: any;
  selectedType: any;
  deliveryStatusList: any;
  selectedStausId: any;
  noMenuSelectedId = null;
  menusId: any;
  clientId: any;
  requirednumber: boolean;
  optionsSelect: Array<any>;
  facility_type: any;
  buttonDisabled: boolean;
  error: any;
  infoClients: any;
  NofPeoples: any;
  nonProfitAuto = [];
  finaldateset = [];
  facilitatorloginID: any;
  sendpickupTypes: any;
  NotificationpickupTypes: any;
  MRFmaterialdata = [];
  ClientId: any;
  ClientIdlogin: any;
  VisibleForFG: boolean;
  user: any;
  driverService: any;
  saasFG: boolean;
  // zipLookup: any;
  fcailityValidation: boolean;
  dataerrortrue: boolean;
  driverSelected: any;
  relaodinfo: any;
  ORPickupTypeId: any;

  existingUser = new FormControl();

  alluser: AllUsers[] = [];
  filteredOptions: Observable<any[]>;

  constructor(
    private CustomerService: CustomerService,
    private accountService: AccountService,
    private sassService: SAASService,
    private AdminService: AdmintoolsService,
    public _rdata: modalDataService,
    private ClientFilterdata: ClientFilterService,
    public dialog: MatDialog,
    public timezone: TimezoneService,
    public timezonedatepostService: TimezonedatepostService,
    private formBuilder: FormBuilder,
    private state: AppState,
    public postDateService: DatePostFormatPipe,
    public dialogRef: MatDialogRef<EdituserComponent>,
    public BackRef: MatDialogRef<EditBusinessDialogComponent>,
    public capitailizepipe: specialPipe,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.CustomerService.CompostPickupTypeList().subscribe((data) => {
      data.Result.forEach((element) => {
        if (element.Title === 'Organic Recycling') {
          this.ORPickupTypeId = element.ID;
        }
      });
    });

    this.AdminService.SelectUser().subscribe(
    (data) => {
      this.alluser = data.Result;

      this.filteredOptions = this.existingUser.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.Name)),
        map(name => (name ? this._filter(name) : this.alluser.slice())),
      );
    });

    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
    });
    
    this.user = this.state.user;
    if (this.user.User.Roles[0].Name === 'Facility Group') {
      this.VisibleForFG = true;
    } else {
      this.VisibleForFG = false;
    }
    this.numberForm = this.formBuilder.group({
      number: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
    });
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.infoClients = JSON.parse(res);
    });
    this._rdata.basicInfo2Observe.subscribe((res) => {
      this.info = JSON.parse(res);
      var sendpickupTypes = [];
      for (var i = 0; i < this.info.pickupTypes.length; i++) {
        sendpickupTypes.push({ ID: this.info.pickupTypes[i] });
      }
      this.sendpickupTypes = sendpickupTypes;
      var NotificationpickupTypes = [];
      for (var i = 0; i < this.info.NotificationTypes.length; i++) {
        NotificationpickupTypes.push({ ID: this.info.NotificationTypes[i] });
      }
      this.NotificationpickupTypes = NotificationpickupTypes;
      this.adminFirstName = this.info.adminFirstName;
      this.adminLastName = this.info.adminLastName;
      this.adminEmail = this.info.adminEmail;
      this.Phone = this.info.Phone;
      this.facility_type = this.info.facility_type;
    });
    // console.log(this.info.zip);
    // var zipTimezones = require('zipcode-to-timezone');
    // this.zipLookup = zipTimezones.lookup(this.info.zip);
    // console.log(this.zipLookup);
    let dateset = [];
    let datesetTimeZone = [];
    // var b = moment.tz('2020-12-14 02:00', this.zipLookup);
    // b.utc().format(); // 2013-11-18T16:55Z
    // console.log(b.format());
    // console.log(b.utc().format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00'));
    this.nonProfitAuto = this.info.NonProfitWorkingSlots;
    dateset = this.info.NonProfitWorkingSlots;
    for (let i = 0; i < dateset.length; i++) {
      let datatime = dateset[i];
      var m = moment.tz(moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.StartTime, this.info.timezone);
      m.format();
      datatime.StartTime = m.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
      var n = moment.tz(moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime, this.info.timezone);
      n.format();
      datatime.EndTime = n.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
      // this.timezonedatepostService.transform(
      //   moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime,
      //   this.info.timezone
      // );
      // based on time zone to utc format  start time
      // moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime;
      //      m = moment.tz(moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime, 'America/Chicago');
      // m.format(); // 2013-11-18T11:55:00-05:00
      // // m.startOf('day').format(); // 2013-11-18T00:00:00-05:00
      // // m.tz('America/New_York').format(); // 2013-11-18T06:00:00+01:00
      // // m.startOf('day').format(); // 2013-11-18T00:00:00+01:00
      // console.log(m);
      // console.log(
      //   m.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00')
      // );
      // moment
      //   .tz(
      //     moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime,
      //     this.info.timezone
      //   )
      //   .utc()
      //   .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss.00');
      // moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime + ':00';
      // moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime;
    }
    this.finaldateset = dateset;

    // let checkdat;
    // checkdat = this.finaldateset;
    // for (let i = 0; i < checkdat.length; i++) {
    //   let datatimef = dateset[i];
    //   datatimef.StartTime = this.postDateService.transform(datatimef.StartTime);
    //   datatimef.EndTime = this.postDateService.transform(datatimef.EndTime);
    // }
    // console.log(checkdat);
    if (JSON.parse(localStorage.getItem('multiplefacilities'))) {
      this.multipleFGadd = JSON.parse(localStorage.getItem('multiplefacilities'));
      for (var j = 0; j < this.multipleFGadd.length; j++) {
        // let pickuptype = [];
        const pickuptype = [];
        for (var i = 0; i < this.info.PickupTypeSfg.length; i++) {
          pickuptype.push({ Title: this.info.PickupTypeSfg[i] });
        }
        this.multipleFGadd[j].PickupType = pickuptype;
      }
    }
    this.info.create_Menu = false;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    if (this.info.facility_type === 'Facility Group') {
      // this.CustomerService.getPickupTypeList().subscribe(data => {
      //   this.driverService = data.Result;
      //   this.driverService = this.driverService.filter(
      //     value =>
      //       value.Title !== 'SAAS' &&
      //       value.Title !== 'Roadie' &&
      //       value.Title !== 'Postmates'
      //   );
      //   this.driverSelected = this.driverService[0].ID;
      // });
    } else if (this.info.facility_type === 'MRF') {
      var MRFmaterialdata = [];
      for (var i = 0; i < this.info.SeparatedCompostID.length; i++) {
        MRFmaterialdata.push({ ID: this.info.SeparatedCompostID[i] });
      }
      this.MRFmaterialdata = MRFmaterialdata;
    }
    //     for (const status of this.driverService) {
    //       if (this.info.driverService === status.ID) {
    //         if (status.Title === 'SAAS') {
    if (this.info.nosaasFG === false) {
      this.saasFG = true;
    }
    //     }
    //   }
    // }
    //   }
    // );
    // get roles
    this.AdminService.getAccountType().subscribe(
      (data) => {
        this.allDeliveryStatus = data.Result;
        let statusArray = [];
        for (const status of this.allDeliveryStatus) {
          statusArray.push(status.Name);
          const roleId = localStorage.getItem('roleId');
          if (status.Id == roleId) {
            this.selectedType = status.Name;
            this.selectedStausId = status.Id;
          }
        }
        statusArray = statusArray.filter((value) => value !== 'Driver' && value !== 'Admin');
        this.deliveryStatusList = statusArray;
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  displayFn(user: AllUsers): string {
    return user && user.Name ? user.Name : '';
  }

  private _filter(name: string) {
    return this.alluser.filter(state =>
      state.Name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  getTitle(userId: string) {
    if (userId) {
      return this.alluser.find(user => user.Id === userId).Name;
    } else {
      return '';
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  keyPressPhone(event: any) {
    this.info.Phone = event;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
  }
  keyPressFirstName(event: any) {
    this.info.adminFirstName = event;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
  }
  keyPressLastName(event: any) {
    this.info.adminLastName = event;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
  }
  keyPressEmail(event: any) {
    this.info.adminEmail = event;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateEmail: true,
        Email: event,
        SAASClient: '',
      };
      this.sassService.reigster(body1).subscribe((data) => {
        if (data.message === 'Field requires a unique email id and this email id has already been used') {
          this.dataerrortrue = true;
          this.error = 'Email already exist, please use another email.';
        } else {
          this.dataerrortrue = false;
        }
      });
    } else {
      this.dataerrortrue = false;
    }
  }
  keyPressEmailwitoutsaas(event: any) {
    this.info.adminEmail = event;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
  }
  UpdateAccountType(value, event) {
    this.AdminService.getAccountType().subscribe(
      (data) => {
        this.allDeliveryStatus = data.Result;
        for (const status of this.allDeliveryStatus) {
          if (value === status.Name) {
            this.selectedStausId = status.Id;
          }
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }

  onSelectionChange(event) {
    if (event.value === 'option 1') {
      this.existingadmin = true;
      this.existingadminno = false;
    } else {
      this.existingadminno = true;
      this.existingadmin = false;
    }
  }
  goTofacilitygroup() {
    const body = {
      CompanyTitle: this.info.accountName,
      FirstName: this.info.adminFirstName,
      LastName: this.info.adminLastName,
      Account: this.info.facility_type,
      Email: this.info.adminEmail,
      PhoneNumber: this.info.Phone.replace(/[_-]/g, ''),
      TaxID: this.info.taxId,
      Country: this.info.country,
      Address1: this.info.businessAddress,
      Address2: this.info.address,
      // State: this.info.state,
      //  City: this.info.city,
      StateId: this.info.state,
      CityId: this.info.city,
      ZipCode: this.info.zip,
    };
    if (this.multipleFGadd.length > 0) {
      localStorage.setItem('multiplefacilities', JSON.stringify(this.multipleFGadd));
      let len = this.multipleFGadd.length;
      for (let i = 0; i < len; i++) {
        delete this.multipleFGadd[i].Id;
      }
      // this.multipleFGaddFinal = [body, ...this.multipleFGadd];
      // const body1 = {
      //   SAASClient: this.multipleFGaddFinal
      // }
      // var spinnerRef = this.spinnerService.start();
      // this.sassService.reigster(body1).subscribe(
      //   data => {
      //     // alert(data.message)
      //     if (data.message === 'User already exist') {
      //       this.error = 'Email already exist, please use another email.';
      //       this.dataerrortrue = true;

      //       // alert('This email already exists');
      //     } else if (data.message) {
      //       this.dataerrortrue = false;
      //       alert('Some error occurred.');
      //     } else {
      //       this.dataerrortrue = false;
      //       // localStorage.setItem('UserId', JSON.stringify(data.UserId));
      //       // localStorage.setItem('ClientId', JSON.stringify(data.ClientId));
      //       localStorage.setItem('NOofFacility', JSON.stringify(data.FacilityCount));
      //       this._rdata.resetBasicInfo2(JSON.stringify(this.info));
      //       //  this.info.accountTypetrue = true;
      //       this.info.clientId = data.ClientId;
      //       this.info.userId = data.UserId;
      //       // this.info.saascreatemenu = this.saascreatemenu;
      //       this._rdata.updateBasicInfo2(JSON.stringify(this.info));
      //     }
      //     // this.spinnerService.stop(spinnerRef);
      //     // this.performLoginTask(data)
      //   },
      //   error => {
      //     // this.spinnerService.stop(spinnerRef);
      //     // this.buttonDisabled = false;
      //   }
      // );
    }
  }
  sasscreation() {
    localStorage.setItem('multiplefacilities', JSON.stringify(this.multipleFGadd));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(EditMYearlyComponent, {
      width: '1220px',
      height: '785px',
      panelClass: 'custom-editdialog-container',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((selection) => {});
  }
  createmenus() {
    this.info.Phone = this.Phone;
    this.info.adminEmail = this.adminEmail;
    this.info.adminLastName = this.adminLastName;
    this.info.adminFirstName = this.adminFirstName;
    this.info.roleId = this.selectedStausId;
    this.info.existingUser = this.existingUser.value;

    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    
    if (this.facility_type === 'Facility' || this.facility_type === 'SAAS Facility') {
      if (this.existingUser.value) {
        if (this.info.pickupTypes.length === 1 && this.info.pickupTypes[0] == this.ORPickupTypeId) {
          this.createORFacility();
        } else {
          this.info.create_Menu = true;
          this._rdata.updateBasicInfo2(JSON.stringify(this.info));
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;

          this.dialogRef.close(this.modalclose);
          const dialogRef = this.dialog.open(CreateMenuDialogComponent, {
            width: '1220px',
            height: '785px',
            panelClass: 'create-menu-dialog',
            disableClose: true,
            data: {},
          });
          dialogRef.afterClosed().subscribe((result) => {});
        }
      } else {
        const payload = {
          Email: this.info.adminEmail,
        };
        this.buttonDisabled = true;
        this.accountService.openforgotDialog(payload).subscribe(
          (response) => {
            if (response['message'] == null) {
              this.error = 'Email already exist,  use another email.';
              this.dataerrortrue = true;
              this.buttonDisabled = false;
            } else if (response['message'] == 'Email does not exist') {
              if (this.info.pickupTypes.length === 1 && this.info.pickupTypes[0] == this.ORPickupTypeId) {
                this.createORFacility();
              } else {
                this.info.create_Menu = true;
                this._rdata.updateBasicInfo2(JSON.stringify(this.info));
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;

                this.dialogRef.close(this.modalclose);
                const dialogRef = this.dialog.open(CreateMenuDialogComponent, {
                  width: '1220px',
                  height: '785px',
                  panelClass: 'create-menu-dialog',
                  disableClose: true,
                  data: {},
                });
                dialogRef.afterClosed().subscribe((result) => {});
              }
            }
          },
          (error) => {
            this.buttonDisabled = false;
          }
        );
      }

    } else {
      let NPAgreement;
      if (this.info.facility_type === 'Non Profit') {
        this.NofPeoples = this.info.NofPeoples;
        NPAgreement = this.info.AgreementFlag;
      } else {
        NPAgreement = null;
        this.NofPeoples = 0;
      }
      let driverservicefinal;
      if (this.info.facility_type === 'Facility Group') {
        driverservicefinal = this.info.driverService;
      } else {
        driverservicefinal = null;
      }
      let nplogo;
      if (this.info.profileImageUrl) {
        let img = this.info.profileImageUrl;
        let splitImg = img.split(',');
        // console.log(splitImg[1]);
        nplogo = splitImg[1];
      } else {
        nplogo = null;
      }

      this.menusId = this.noMenuSelectedId;
      const payload = {
        Name: this.info.accountName,
        EINNumber: this.info.taxId,
        Web: '',
        Address: this.info.businessAddress,
        SeparatedCompost: this.MRFmaterialdata,
        Address2: this.info.address,
        StateId: this.info.state,
        CityId: this.info.city,
        HoldHarmlessAgreement: NPAgreement,
        ZIP: this.info.zip,
        Country: this.info.country,
        Phone: this.info.PhoneNumber.replace(/[_-]/g, ''),
        Email: this.info.email,
        HasFridgeStorage: false,
        NumberOfPeopleServed: this.NofPeoples,
        WasteExpenditure: false,
        ClientTypeId: 1,
        ApprovalStatusId: 2,
        WayPointID: 0,
        WP_Client_Id: 0,
        MenuEdit: true,
        Logo: nplogo,
        MenuTypeId: this.menusId,
        TimeZone: this.info.timezone,
        ClientCategories: [
          {
            ClientCategoryId: this.info.account_type,
          },
        ],
        DriverServices: [
          {
            ID: driverservicefinal,
          },
        ],
        DriverNotes: this.info.driverNotes,
        User_Client: [],
        FacilitatorId: this.info.FacilityGroupFRId,
        FacilityGroupId: this.info.FacilityGroupId,
      };
      this.buttonDisabled = true;

      const createAccount = () => {
        this.CustomerService.createAccount(payload).subscribe(
          (data) => {
            this.infoClients.facilitator = '';
            this.infoClients.facilitatorGroup = '';
            this.infoClients.facility = '';
            this.infoClients.nonprofits = '';
            if (this.info.facility_type !== 'Non Profit' && this.info.facility_type !== 'MRF') {
              let localUser = JSON.parse(localStorage.getItem('user'));
              localUser.user.User.Clients.push(data['Result'][0]);
              localStorage.setItem('user', JSON.stringify(localUser));
              this.state.user = JSON.parse(localStorage.getItem('user')).user;
            } else if (this.info.facility_type === 'Non Profit' || this.info.facility_type === 'MRF') {
              let request = [];
              // this.nonProfitAuto = this.info.NonProfitWorkingSlots;
              request = this.finaldateset;
              for (let i = 0; i < request.length; i++) {
                let dataFinal = request[i];
                // if (dataFinal.WorkingDay) {
                let payloadNP = {
                  ClientId: data['Result'][0].ClientId,
                  WorkingDay: dataFinal.WorkingDay,
                  StartTime: dataFinal.StartTime,
                  EndTime: dataFinal.EndTime,
                };
                this.accountService.NonProfitWorkingSeletion(payloadNP).subscribe((data) => {});
                // }
              }
            }
            this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.infoClients));
            this.clientId = data['Result'][0].ClientId;
            this.relaodinfo.reloadclientdata = true;
            this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));

            //invite user
            // this.accountcompletion(this.clientId);
            let payload1 = {
              FirstName: this.capitailizepipe.transform(this.info.adminFirstName),
              LastName: this.capitailizepipe.transform(this.info.adminLastName),
              Email: this.info.adminEmail,
              OnClientCreation: true,
              PhoneNumber: this.info.Phone.replace(/[_-]/g, ''),
              Clients: [
                {
                  ClientId: this.clientId,
                },
              ],
              RoleId: this.info.roleId,
            };

            if (this.existingUser.value) {
              const payload1 = {
                ClientId: this.clientId,
                Id: this.existingUser.value
              };

              this.AdminService.updateUser(payload1).subscribe((response) => {
                if ('error' in response) {
                  this.buttonDisabled = false;
                  this.error = response['message'];
                } else {
                  this.dialogRef.close();
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              });
            } else {
              this.AdminService.createUser(payload1).subscribe((response) => {
                if ('error' in response) {
                  this.buttonDisabled = false;
                  this.error = response['message'];
                } else {
                  this.dialogRef.close();
                  this.accountcompletion(this.clientId, this.info.facility_type);
                }
              });
            }

            this._rdata.resetBasicInfo2(JSON.stringify(this.info));
          },
          (error) => {
            this.buttonDisabled = false;
          }
        );
      }


      if (this.existingUser) {
        createAccount();
      } else {
        const payloaddata = {
          Email: this.info.adminEmail,
        };
        this.accountService.openforgotDialog(payloaddata).subscribe(
          (response) => {
            if (response['message'] == null) {
              this.error = 'Email already exist, please use another email.';
              this.dataerrortrue = true;
              this.buttonDisabled = false;
            } else if (response['message'] == 'Email does not exist') {
              this.dataerrortrue = false;
              createAccount();
            }
          },
          (error) => {
            this.buttonDisabled = false;
          }
        );
      }
      
      this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    }
  }

  selectExistingUser() {
    this.buttonDisabled = false;
  }

  createORFacility() {
    let payload = {
      Name: this.info.accountName,
      EINNumber: this.info.taxId,
      Web: '',
      Address: this.info.businessAddress,
      PickupType: this.sendpickupTypes,
      Address2: this.info.address,
      // City: this.info.city,
      // State: this.info.state,
      CityId: this.info.city,
      StateId: this.info.state,
      ZIP: this.info.zip,
      Country: this.info.country,
      Phone: this.info.PhoneNumber.replace(/[_-]/g, ''),
      Email: this.info.email,
      HasFridgeStorage: false,
      NumberOfPeopleServed: 1,
      WasteExpenditure: false,
      ClientTypeId: 1,
      ApprovalStatusId: 2,
      WP_Client_Id: 0,
      MenuEdit: false,
      TimeZone: this.info.timezone,
      InviteUser: this.info.facilityAccess,
      MenuTypeId: null,
      ClientCategories: [
        {
          ClientCategoryId: this.info.account_type,
        },
      ],
      DriverServices: [
        {
          ID: this.info.driverService,
        },
      ],
      DriverNotes: this.info.driverNotes,
      User_Client: [],
      NotificationTypes: this.NotificationpickupTypes,
      FacilitatorId: this.info.FacilitatorId,
      FacilityGroupId: this.info.FacilityGroupId,
      AllowSchedulePickup: this.info.scheduler,
    };
    // console.log(payload);
    this.CustomerService.createAccount(payload).subscribe((data) => {
      this.clientId = data['Result'][0].ClientId;
      if ('error' in data) {
      } else {
        let localUser = JSON.parse(localStorage.getItem('user'));
        localUser.user.User.Clients.push(data['Result'][0]);
        localStorage.setItem('user', JSON.stringify(localUser));
        this.state.user = JSON.parse(localStorage.getItem('user')).user;
        this.infoClients.facilitator = '';
        this.infoClients.facilitatorGroup = '';
        this.infoClients.facility = '';
        this.infoClients.nonprofits = '';
        this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.infoClients));
        this.relaodinfo.reloadclientdata = true;
        this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));

        if (!this.info.existingUser) {
          const payload1 = {
            FirstName: this.info.adminFirstName,
            LastName: this.info.adminLastName,
            Email: this.info.adminEmail,
            OnClientCreation: true,
            PhoneNumber: this.info.Phone.replace(/[_-]/g, ''),
            Clients: [
              {
                ClientId: this.clientId,
              },
            ],
            RoleId: this.info.roleId,
          };

          this.AdminService.createUser(payload1).subscribe((response) => {
            if ('error' in response) {
              this.buttonDisabled = false;
              this.error = response['message'];
            } else {
              this.accountcompletion(this.clientId, this.info.facility_type);
            }
          });
        } else {
          const payload1 = {
            ClientId: this.clientId,
            Id: this.info.existingUser
          };

          this.AdminService.updateUser(payload1).subscribe((response) => {
            if ('error' in response) {
              this.buttonDisabled = false;
              this.error = response['message'];
            } else {
              this.accountcompletion(this.clientId, this.info.facility_type);
            }
          });
        }

      }
    });
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
  }

  accountcompletion(id, facility) {
    // console.log(id, facility);
    this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(AccountcompletionMenuDialogComponent, {
      width: '550px',
      height: '550px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: [
        {
          clientId: id,
        },
        {
          type: facility,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  // email-validation
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.adminEmail) === false) {
      return false;
    }
    return true;
  }
  addfacility() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // this.dialogRef.close(this.modalclose)
    const dialogRef = this.dialog.open(AddfacilityfgComponent, {
      width: '1220px',
      height: '785px',
      panelClass: 'custom-pickupeditmenudialog-container',
      // panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: [
        {
          lengthData: this.multipleFGadd,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.multipleFGadd.push(result);
      }
    });
  }
  closeaddfacility(id) {
    for (let i = 0; i < this.multipleFGadd.length; ++i) {
      if (this.multipleFGadd[i].Id === id) {
        this.multipleFGadd.splice(i, 1);
      }
    }
  }
  updatefacility(value) {
    const dialogRef = this.dialog.open(EditfacilityComponent, {
      width: '1220px',
      height: '785px',
      data: [{ value }],
      panelClass: 'custom-datedialog-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.multipleFGadd.map((val) => {
          if (val.Id === result.Id) {
            val.CompanyTitle = result.CompanyTitle;
            val.Account = result.Account;
            val.Email = result.Email;
            val.PhoneNumber = result.PhoneNumber;
            val.TaxID = result.TaxID;
            val.Address1 = result.Address1;
            val.Address2 = result.Address2;
            val.Country = result.Country;
            val.StateId = result.StateId;
            val.CityId = result.CityId;
            val.ZipCode = result.ZipCode;
            val.PickupType = result.PickupType;
            val.MenuEdit = result.MenuEdit;
          }
        });
      }
    });
  }
  addUserDetails() {
    
    this.adminFirstName = this.adminFirstName.trim();
    this.adminLastName = this.adminLastName.trim();
    this.adminEmail = this.adminEmail.trim();
    this.Phone = this.Phone.trim();
    this.numberForm.controls.number.setValue(numberSetMask(this.Phone));
    // if (
    //   this.adminFirstName &&
    //   this.adminLastName &&
    //   this.adminEmail
    // ) {

    if (this.existingUser.value) {
      if (this.facility_type === 'SAAS Facility Group') {
        if (this.multipleFGadd.length > 0) {
          this.sasscreation();
        } else {
          this.fcailityValidation = true;
          setTimeout(() => {
            this.fcailityValidation = false;
          }, 3000);
        }
      }
      else {
        this.createmenus();
      }
      return;
    }

    if (this.adminFirstName) {
      if (this.adminLastName) {
        if (this.adminEmail) {
          if (this.numberForm.valid) {
            if (this.validateEmail() == true) {
              if (this.facility_type === 'SAAS Facility Group') {
                if (this.multipleFGadd.length > 0) {
                  this.sasscreation();
                } else {
                  this.fcailityValidation = true;
                  setTimeout(() => {
                    this.fcailityValidation = false;
                  }, 3000);
                }
              }
              // else if (this.facility_type === 'SAAS Facility') {
              //   this.sasscreation();
              //  }
              else {
                this.createmenus();
              }
            } else {
              this.invalidEmail = true;
              // this.requireField = false;
              setTimeout(() => {
                this.invalidEmail = false;
              }, 3000);
            }
          } else {
            this.requirednumber = true;
            setTimeout(() => {
              this.requirednumber = false;
            }, 3000);
          }
        } else {
          this.requireFieldEmail = true;
          setTimeout(() => {
            this.requireFieldEmail = false;
          }, 3000);
        }
      } else {
        this.requireFieldLast = true;
        setTimeout(() => {
          this.requireFieldLast = false;
        }, 3000);
      }
    } else {
      this.requireField = true;
      setTimeout(() => {
        this.requireField = false;
      }, 3000);
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.numberForm.controls[controlName].hasError(errorName);
  };

  backToEditBusiness() {
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(EditBusinessDialogComponent, {
      width: '1220px',
      height: '785px',
      panelClass: 'business-detail-container',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
