import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  activeState: boolean;
  activeyearlyState: boolean;
  clientId: any;
  monthlyPrice: any;
  yearlyPrice: any;
  constructor(
    private pricingModule: PricingModuleServiceService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit() {
    // console.log(JSON.parse(localStorage.getItem('multiplefacilities')));
    let pickupType;
    let pickupTypefinal;
    if (JSON.parse(localStorage.getItem('saasFG')) !== null) {
      pickupType = JSON.parse(localStorage.getItem('saasFG'));

      if (
        JSON.parse(localStorage.getItem('multiplefacilities'))[0].PickupType
          .length === 2
      ) {
        pickupTypefinal = 'combined';
      } else if (
        JSON.parse(localStorage.getItem('multiplefacilities'))[0].PickupType[0]
          .Title === 'Organic Recycling'
      ) {
        pickupTypefinal = 'Organic';
      } else {
        pickupTypefinal = 'Food';
      }
    } else {
      pickupType = JSON.parse(localStorage.getItem('multiplefacilities'));
      if (pickupType[0].PickupType.length === 2) {
        pickupTypefinal = 'combined';
      } else if (pickupType[0].PickupType[0].Title === 'Organic Recycling') {
        pickupTypefinal = 'Organic';
      } else {
        pickupTypefinal = 'Food';
      }
    }
    if (
      pickupTypefinal === undefined ||
      pickupTypefinal === null ||
      !pickupTypefinal
    ) {
      pickupTypefinal = 'combined';
    }
    if (JSON.parse(localStorage.getItem('accountType')) === 'montly') {
      this.activeState = true;
      this.activeyearlyState = false;
    } else {
      this.activeState = false;
      this.activeyearlyState = true;
    }
    this.getPricingRated(pickupTypefinal);
    // const stringToSplit = this.route.snapshot.params.id;
    // const x = stringToSplit.split('+');
    // this.clientId = x[1];
    // localStorage.setItem('UserId', JSON.stringify(x[0]));
    // localStorage.setItem('ClientId', JSON.stringify(x[1]));
    this.clientId = JSON.parse(localStorage.getItem('ClientId'));
  }
  getPricingRated(pickuptype) {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      let priceData;
      priceData = data['Result'];
      for (const status of priceData) {
        // console.log(status)
        // console.log(status.SubscriptionType === 'month')
        if (status.SubscriptionType === 'month') {
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.monthlyPrice = status.Price;
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.monthlyPrice = status.Price;
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.monthlyPrice = status.Price;
          }
          // console.log(this.yearlyPrice)
        } else {
          // console.log(pickuptype)
          // console.log(status.PickupType)
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            // console.log(status.PickupType)
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.yearlyPrice = status.Price;
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.yearlyPrice = status.Price;
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.yearlyPrice = status.Price;
          }
          // console.log(this.monthlyPrice)
        }
        // if (this.driverSelected === status.ID) {
        //this.surplusnorganicmonthly = this.data.Result[0].Price;
      }
    });
  }
  goToselectmontlyPayment() {
    localStorage.setItem('accountType', JSON.stringify('montly'));
    this.router.navigate(['billing/']);
  }
  goToselectPayment() {
    localStorage.setItem('accountType', JSON.stringify('yearly'));
    this.router.navigate(['billing/']);
  }
  montlyFunction() {
    this.activeState = true;
    this.activeyearlyState = false;
  }
  yearlyFunction() {
    this.activeState = false;
    this.activeyearlyState = true;
  }
  goToRegistrationPage() {
    this.router.navigate(['registration']);
  }
}
