import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { AccountService } from "src/app/shared/account.service";
import { AppState } from "src/app/shared/app-state";
import { BackgroundOTPPasswordComponent } from '../background-otppassword/background-otppassword.component';
import { SAASService } from 'src/app/shared/services/saas.service';
@Component({
  selector: 'app-backgroundotp',
  templateUrl: './backgroundotp.component.html',
  styleUrls: ['./backgroundotp.component.scss']
})
export class BackgroundotpComponent implements OnInit {
  public ownerForm: FormGroup;
  invalidemail = false;
  selectedEmoji: string;
  hide = true;
  loginError = "";
  loginForm: any;
  modalclose: string;
  showValidators: boolean;
  Email: string;
  dataerrortrue: boolean;
  disablebutton = true;
  dataerror: string;
  constructor(
    private sassService: SAASService,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }
  email: string;
  password: string;


  ngOnInit() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = true;
    // const dialog = this.dialog.open(BackgroundOTPPasswordComponent, {
    //   width: "540px",
    //   height: "340px",
    //   disableClose: true,
    //   panelClass: 'logindialog-container',

    // });
    // dialog.afterClosed().subscribe(selection => {
    //   if (selection) {
    //     this.selectedEmoji = selection;
    //   } else {
    //   }
    // });

  }
  keyPressFirstName(event: any) {
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateClient: true,
        OneTimeClient: { Email: event }
      };
      this.sassService.otpreigster(body1).subscribe(
        data => {
          if (data.message !== 'Client already exist') {
            this.dataerrortrue = true;
            this.disablebutton = true;
            this.dataerror = "Client doesn't exist";
          } else {
            this.dataerrortrue = false;
            this.disablebutton = false;
          }
        });
    } else {
      this.dataerrortrue = false;
    }
  }
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.Email) === false) {
      return false;
    }
    return true;
  }

  loginnewform() {
    localStorage.removeItem('onetimebilling');
    localStorage.removeItem('OneTimeItem');
    localStorage.setItem("otpemail", JSON.stringify(this.Email));
    localStorage.setItem("otpuser", JSON.stringify('existuser'));
    this.router.navigate(["PickupRegistration"]);
  }
  loginform() {
    localStorage.removeItem('onetimebilling');
    localStorage.removeItem('OneTimeItem');
    localStorage.setItem("otpuser", JSON.stringify('newuser'));
    this.router.navigate(["PickupRegistration"]);
  }
}
