import { Component, OnInit } from '@angular/core';
import { EditbillingComponent } from '../editbilling/editbilling.component';
import {
  MatPaginator,
  MatSort,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { EdituserComponent } from '../../user/edituser/edituser.component';
import { CreateMenuDialogComponent } from '../../create-menu-dialog/create-menu-dialog.component';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
@Component({
  selector: 'app-edit-myearly',
  templateUrl: './edit-myearly.component.html',
  styleUrls: ['./edit-myearly.component.scss'],
})
export class EditMYearlyComponent implements OnInit {
  modalclose: string;
  info: any;
  activeState: boolean;
  activeyearlyState: boolean;
  reqsub: boolean;
  updtateclient: boolean;
  updateinfo: any;
  monthlyPrice: any;
  yearlyPrice: any;
  constructor(
    private pricingModule: PricingModuleServiceService,
    public dialog: MatDialog,
    public _rdata: modalDataService,
    public dialogRef: MatDialogRef<EditMYearlyComponent>
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    let pickupTypefinal;
    this._rdata.basicupdateInfo2Observe.subscribe((res) => {
      this.updateinfo = JSON.parse(res);
      if (this.updateinfo.SAASupdate.PickupType) {
        if (this.updateinfo.SAASupdate.PickupType.length > 0) {
          if (this.updateinfo.SAASupdate.PickupType.length === 2) {
            pickupTypefinal = 'combined';
          } else if (this.updateinfo.SAASupdate.PickupType[0].ID === 2) {
            pickupTypefinal = 'Organic';
          } else {
            pickupTypefinal = 'Food';
          }
        } else {
          pickupTypefinal = 'combined';
        }
      }
      // console.log(pickupTypefinal);
      if (this.updateinfo.updateclient === true) {
        this.updtateclient = true;
      } else {
        this.updtateclient = false;
      }
    });
    let slectPlan;
    slectPlan = JSON.parse(localStorage.getItem('accountType'));
    if (slectPlan === 'yearly') {
      this.activeState = false;
      this.activeyearlyState = true;
    } else {
      this.activeState = true;
      this.activeyearlyState = false;
    }
    this._rdata.basicInfo2Observe.subscribe((res) => {
      this.info = JSON.parse(res);
      if (
        this.info.pickupTypes !== '' &&
        this.info.pickupTypes !== null &&
        this.info.pickupTypes !== undefined
      ) {
        if (this.info.pickupTypes.length > 0) {
          if (this.info.pickupTypes.length === 2) {
            pickupTypefinal = 'combined';
          } else if (this.info.pickupTypes[0] === 2) {
            pickupTypefinal = 'Organic';
          } else {
            pickupTypefinal = 'Food';
          }
        }
      }
      if (
        this.info.PickupTypeSfg !== '' &&
        this.info.PickupTypeSfg !== null &&
        this.info.PickupTypeSfg !== undefined
      ) {
        if (this.info.PickupTypeSfg.length > 0) {
          if (this.info.PickupTypeSfg.length === 2) {
            pickupTypefinal = 'combined';
          } else if (this.info.PickupTypeSfg[0] === 'Organic Recycling') {
            pickupTypefinal = 'Organic';
          } else {
            pickupTypefinal = 'Food';
          }
        }
      }
    });
    this.getPricingRated(pickupTypefinal);
    // console.log(pickupTypefinal);
  }
  getPricingRated(pickuptype) {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      let pricedata;
      pricedata = data['Result'];
      for (const status of pricedata) {
        if (status.SubscriptionType === 'month') {
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.monthlyPrice = status.Price;
              // console.log(this.monthlyPrice);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.monthlyPrice = status.Price;
              // console.log(this.monthlyPrice);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.monthlyPrice = status.Price;
            // console.log(this.monthlyPrice);
          }
          // console.log(this.yearlyPrice)
        } else {
          // console.log(pickuptype)
          // console.log(status.PickupType)
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            // console.log(status.PickupType)
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.yearlyPrice = status.Price;
              // console.log(this.yearlyPrice);
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.yearlyPrice = status.Price;
              // console.log(this.yearlyPrice);
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.yearlyPrice = status.Price;
            // console.log(this.yearlyPrice);
          }
          // console.log(this.monthlyPrice);
          // console.log(this.yearlyPrice);
        }
      }
    });
  }
  montlyFunction() {
    this.activeState = true;
    this.activeyearlyState = false;
  }
  yearlyFunction() {
    this.activeState = false;
    this.activeyearlyState = true;
  }
  edituserdetails() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    if (this.info.facility_type === 'SAAS Facility') {
      const dialogRef = this.dialog.open(CreateMenuDialogComponent, {
        width: '700px',
        // height: "680px",
        panelClass: 'custom-Filterdialog-container',
        disableClose: true,
        data: {},
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      const dialogRef = this.dialog.open(EdituserComponent, {
        width: '700px',
        // height: "680px",
        panelClass: 'custom-Filterdialog-container',
        disableClose: true,
        data: {},
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
  editbilling() {
    if (this.activeyearlyState === true || this.activeyearlyState === false) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef.close(this.modalclose);
      if (this.activeyearlyState === true) {
        localStorage.setItem('accountType', JSON.stringify('yearly'));
      } else {
        localStorage.setItem('accountType', JSON.stringify('montly'));
      }
      const dialogRef = this.dialog.open(EditbillingComponent, {
        width: '900px',
        // height: "950px",
        panelClass: 'custom-editdialog-container',
        disableClose: true,
        data: {},
      });
      dialogRef.afterClosed().subscribe((selection) => {});
    } else {
      this.reqsub = true;
    }
  }
}
