import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd
} from '@angular/router';
import { AdmintoolsService } from './admintools.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerService } from './customer.service';

@Injectable()
export class PrcingControlFeatureGuard implements CanActivate {
  app_env: string = environment.app_env;
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private AdminService: AdmintoolsService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.AdminService.FeatureToggles().pipe(
      map(res => {
        for (const element of res.Result) {
          if (
            element.Title === 'Admin Pricing Control' &&
            element.EnableFeature === false
          ) {
            this.router.navigate(['/error']);
          }
        }
        // if (res.Result[2].Title === 'Admin Pricing Control') {
        //   if (res.Result[2].EnableFeature === false) {
        //     this.router.navigate(['/error']);
        //   }
        // }
        return !!res;
      })
    );
  }
  // canActivate1() {
  //   // const isLogIn = localStorage.getItem('isLoggedin');

  //   this.AdminService.FeatureToggles().subscribe(result => {
  //     console.log(result.Result[0]);
  //     console.log(result.Result[0].Title);
  //     if (result.Result[0].Title === 'OTP') {
  //       console.log(result.Result[0].EnableFeature);
  //       if (location.href.includes('OneTimePickup')) {
  //         console.log('yes');
  //         return true;
  //         // window.location.href = redirectURL + 'login';
  //       }
  //     }
  //   });

  //   // let localUser = JSON.parse(localStorage.getItem('user'));

  //   this.router.navigate(['error']);
  //   return false;
  // }
}
