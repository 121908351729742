import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'timezoneabbr'
})
export class TimeZoneabbrService implements PipeTransform {
  transform(TimeZone) {
    let zone;
    if (TimeZone) {
      zone = TimeZone;
    } else {
      zone = 'America/New_York';
    }
    // if (moment(date).isValid()) {
    // console.log(moment.tz.zone('America/Chicago').abbr(360));
    // var timeZone = moment.tz.guess();
    // // var time = new Date();
    // var timeZoneOffset = date.getTimezoneOffset();
    // // console.log(timeZoneOffset);
    // // console.log(new Date().getTime());
    // console.log(moment.tz.zone(timeZone).abbr(date.getTime()));
    // console.log(timeZone);
    const updateDate = moment.tz.zone(zone).abbr(360);
    // console.log(moment.tz.zone(timeZone).abbr(timeZoneOffset));
    return updateDate;
    // }
    // return null;
  }
}
