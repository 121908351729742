import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';

@Component({
  selector: 'app-distance-matrix-page',
  templateUrl: './distance-matrix-page.component.html',
  styleUrls: ['./distance-matrix-page.component.scss']
})
export class DistanceMatrixPageComponent implements OnInit {
  selectedClient: string = null;
  clientsData: [];
  dataSource = [];
  nonProfits = [];
  dropdownList = [];
  radioOptions: string = "";
  mobileDisplayedColumns: string[] = ['Name', 'Address', 'Distance', 'Time'];
  noResult: boolean = false;
  filters = {}
  constructor(private ClientFilterdata: ClientFilterService,
    private pickupService: PickUpService,
    private spinnerService: SpinnerService,
    private router: Router,
  ) {
    this.ClientFilterdata.basicFilterDistanceMatrixObserve.subscribe((res) => {
      let filter = JSON.parse(res);
      this.filters = filter
      if (filter.globalSearchBy) this.radioOptions = filter.globalSearchBy;
      if (filter.globalClientName) {
        if (this.radioOptions === 'client') {
          this.dropdownList = this.clientsData;
          this.radioOptions = "client";
        } else {
          this.dropdownList = this.nonProfits;
          this.radioOptions = "nonprofit";
        }
        this.selectedClient = filter.globalClientName;
      }

    });
  }

  ngOnInit() {
    if (this.filters['globalClientName'] !== "" && this.filters['globalSearchBy'] !== "") this.searchData();

    var spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicInfoObserveclient.subscribe((res) => {
      let listOfClients = JSON.parse(res);
      if (listOfClients.allClients.length > 0) {
        listOfClients.allClients = listOfClients.allClients.filter((client) => client.ApprovalStatus == 'Active')

        this.clientsData = listOfClients.allClients.sort(function (a, b) {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        }, function (sorted) {
          this.dropdownList = sorted;
        });
      }
    });

    this.pickupService.getNpList().subscribe(
      (data) => {
        for (var i = 0; i < data.Result.length; i++) {
          if (data.Result[i]['ApprovalStatus'] == 'Active') {
            this.nonProfits.push(data.Result[i])
          }
        }
        this.spinnerService.stop(spinnerRef);
      },
      (error) => {
        this.spinnerService.stop(spinnerRef);
      }
    );
  }
  clientsdataChange() { }
  searchBy(e) {
    this.noResult = false;
    this.dataSource = [];
    this.selectedClient = null;
    if (e.value === 'client') {
      this.dropdownList = this.clientsData;
      this.radioOptions = "client";
    } else {
      this.dropdownList = this.nonProfits;
      this.radioOptions = "nonprofit";
    }
  }
  searchData() {
    if (!this.selectedClient) {
      return
    }
    var spinnerRef = this.spinnerService.start();
    this.noResult = false;
    this.dataSource = [];
    let isNonProfit = false;
    if (this.radioOptions === 'client') isNonProfit = false
    else isNonProfit = true

    this.pickupService.getRecipientFilterListByDistanceMatrix(this.selectedClient, isNonProfit).subscribe(
      (data) => {
        this.dataSource = data.Result
        if (data.Result.length === 0) this.noResult = true;
        let filters = {
          globalClientName: this.selectedClient,
          globalNonprofitName: this.selectedClient,
          globalSearchBy: this.radioOptions
        }
        this.ClientFilterdata.basicFilterDistanceMatrixinfo(JSON.stringify(filters));
        this.spinnerService.stop(spinnerRef);
      },
      (error) => {
        this.spinnerService.stop(spinnerRef);
      }
    );
  }
  goToPickupPage() {
    this.router.navigate(['Customers']);
  }
  redirectUserDetails(id) {
    if (id.ClientType == undefined)
      this.router.navigate(['FacilityDetails/' + id.ClientId + '']);
    else if (id.ClientType == 'Facility' || id.ClientType == 'SAAS Facility')
      this.router.navigate(['FacilityDetails/' + id.ClientId + '']);
    else if (id.ClientType == 'Facilitator')
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
    else if (id.ClientType == 'Facility Group' || id.ClientType == 'SAAS Facility Group')
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
    else if (id.ClientType == 'Non Profit')
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
    else if (id.ClientType === 'MRF')
      this.router.navigate(['CustomerFacilitatorview/' + id.ClientId + '']);
  }
}
