export function getFeature(feature) {
  let localUser = JSON.parse(localStorage.getItem('user'));
  if (localUser) {
    if (JSON.parse(localStorage.getItem('user')).user.User.Roles.length > 0) {
      var userRole = JSON.parse(localStorage.getItem('user')).user.User
        .Roles[0];
    }
  }
  if (userRole.Name === 'Admin' || userRole.Name === 'Billing Admin') {
    return true;
  } else if (userRole.Name === 'Facility') {
    if (feature === 'downloadReceipts') {
      return true;
    }
    if (feature === 'accountdelete') {
      return false;
    }
    if (feature === 'driverservices') {
      return false;
    }
    if (feature === 'foodpickup') {
      return false;
    }
    if (feature === 'schedulepickupbtn') {
      return false;
    }
    if (feature === 'VisibleForAdminOnlyGrab') {
      return false;
    }
    if (feature === 'requestStatus') {
      return false;
    }
    if (feature == 'edituserdetails') {
      return false;
    }
    if (feature === 'editdetails') {
      return true;
    }
    if (feature === 'AdminEditMenu') {
      return false;
    }
    if (feature === 'genericproductview') {
      return false;
    }
    if (feature === 'AdminDeleteMenu') {
      return false;
    }
    if (feature === 'OnlyFacility') {
      return false;
    }
    if (feature === 'DonationReceipts') {
      return false;
    }
    if (feature === 'ViewDonationReceipts') {
      return true;
    }
    if (feature === 'PickupUpdate') {
      return false;
    }
    if (feature === 'PickupMenuUpdate') {
      return false;
    }
    if (feature === 'inviteNewUser') {
      return false;
    }
    if (feature === 'bulkMenuItemDeleted') {
      return false;
    }
    return true;
  } else if (userRole.Name == 'Driver') {
    if (feature === 'driverservices') {
      return false;
    }
    if (feature === 'downloadReceipts') {
      return false;
    }
    //dashboard
    if (feature == 'dashboard') {
      return false;
    }
    if (feature === 'schedulepickupbtn') {
      return false;
    }
    if (feature === 'bulkMenuItemDeleted') {
      return false;
    }
    if (feature === 'genericproductview') {
      return false;
    }
    if (feature === 'VisibleForAdminOnlyGrab') {
      return false;
    }
    if (feature === 'foodpickup') {
      return true;
    }
    //pickups
    if (feature == 'pickups') {
      return true;
    }
    if (feature == 'requestPickupView') {
      return false;
    }
    if (feature == 'taxValue') {
      return true;
    }
    if (feature == 'requestStatus') {
      return true;
    }
    if (feature === 'DonationReceipts') {
      return false;
    }
    if (feature === 'PickupUpdate') {
      return false;
    }
    if (feature === 'PickupMenuUpdate') {
      return false;
    }

    //customer
    if (feature == 'addNewAccount') {
      return false;
    }
    if (feature == 'customer') {
      return false;
    }
    if (feature == 'customerAddFilter') {
      return false;
    }
    if (feature == 'customerExport') {
      return false;
    }
    if (feature === 'accountdelete') {
      return false;
    }
    if (feature === 'editdetails') {
      return false;
    }

    //user
    if (feature == 'users') {
      return false;
    }
    if (feature == 'inviteNewUser') {
      return false;
    }
    if (feature == 'usersAddFilters') {
      return false;
    }
    if (feature == 'usersExport') {
      return false;
    }
    if (feature == 'edituserdetails') {
      return false;
    }
    //impact
    if (feature == 'impact') {
      return false;
    }
    return false;
  } else if (
    userRole.Name === 'Non Profit' ||
    userRole.Name === 'Facility Group' ||
    userRole.Name === 'Facilitator'
  ) {
    if (feature === 'driverservices') {
      return false;
    }
    if (userRole.Name === 'Facility Group') {
      if (feature === 'downloadReceipts') {
        return true;
      }
      if (feature == 'taxValue') {
        return true;
      }
      if (feature === 'schedulepickupbtn') {
        return false;
      }
      if (feature === 'VisibleForAdminOnlyGrab') {
        return false;
      }
      if (feature === 'foodpickup') {
        return false;
      }
      if (feature === 'deliveryoption') {
        return false;
      }
      if (feature === 'genericproductview') {
        return false;
      }
      if (feature === 'bulkMenuItemDeleted') {
        return false;
      }
      if (feature === 'nonProfitData') {
        return true;
      }
      if (feature == 'dashboard') {
        return true;
      }
      if (feature === 'PickupFilter') {
        return true;
      }
      if (feature === 'RecipientList') {
        return true;
      }
      if (feature === 'DonationReceipts') {
        return false;
      }
      if (feature === 'ViewDonationReceipts') {
        return true;
      }
      if (feature === 'editdetails') {
        return true;
      }
      if (feature === 'edituserdetails') {
        return true;
      }
      if (feature === 'inviteNewUser') {
        return true;
      }
      if (feature === 'HideStatusOption') {
        return false;
      }
      if (feature === 'PickupUpdate') {
        return false;
      }
      if (feature === 'PickupMenuUpdate') {
        return false;
      }
      if (feature === 'accountdelete') {
        return true;
      }
      if (feature === 'FRViewOnly') {
        return false;
      }
      if (feature === 'addNewAccount') {
        return true;
      } else if (
        userRole.Name === 'Non Profit' ||
        userRole.Name === 'Facilitator'
      ) {
        if (feature == 'taxValue') {
          return false;
        }
        if (feature === 'schedulepickupbtn') {
          return false;
        }
        if (feature === 'VisibleForAdminOnlyGrab') {
          return false;
        }
        if (feature === 'foodpickup') {
          return false;
        }
        if (feature === 'bulkMenuItemDeleted') {
          return false;
        }
        if (feature === 'genericproductview') {
          return false;
        }
      } else if (userRole.Name === 'Admin') {
        if (feature === 'genericproductview') {
          return true;
        }
        if (feature === 'VisibleForAdminOnlyGrab') {
          return true;
        }
        if (feature === 'foodpickup') {
          return true;
        }
        if (feature === 'schedulepickupbtn') {
          return true;
        }
      } else if (userRole.Name === 'Non Profit') {
        if (feature === 'nonProfitData') {
          return false;
        }
        if (feature === 'VisibleForAdminOnlyGrab') {
          return false;
        }
        if (feature === 'schedulepickupbtn') {
          return false;
        }
        if (feature === 'estimatedTax') {
          return false;
        }
        if (feature === 'PickupUpdate') {
          return false;
        }
        if (feature === 'foodpickup') {
          return false;
        }
        if (feature === 'genericproductview') {
          return false;
        }
        if (feature === 'bulkMenuItemDeleted') {
          return false;
        }
        if (feature === 'PickupMenuUpdate') {
          return false;
        }
        // if (feature === 'RecipientList'){
        //     return false;
        // }
        // if (feature === 'PickupFilter'){
        //     return false;
        // }
        if (feature === 'DonationReceipts') {
          return false;
        }
        if (feature === 'FRViewOnly') {
          return false;
        }
      }
      if (feature === 'AdminEditMenu') {
        return false;
      }
      if (feature === 'AdminDeleteMenu') {
        return false;
      }
    } else if (userRole.Name === 'Non Profit') {
      if (feature === 'downloadReceipts') {
        return false;
      }
      if (feature === 'OnlyFacility') {
        return true;
      }
      if (feature === 'ViewDonationReceipts') {
        return true;
      }
      if (feature === 'DonationReceipts') {
        return true;
      }
      if (feature === 'inviteNewUser') {
        return false;
      }
      if (feature === 'VisibleForAdminOnlyGrab') {
        return false;
      }
      if (feature === 'schedulepickupbtn') {
        return false;
      }
      if (feature === 'genericproductview') {
        return false;
      }
      if (feature === 'PickupFilter') {
        return false;
      }
      if (feature === 'bulkMenuItemDeleted') {
        return false;
      }
      if (feature === 'nonProfitData') {
        return false;
      }
      if (feature === 'RecipientList') {
        return true;
      }
      if (feature === 'foodpickup') {
        return false;
      }
      if (feature === 'DonationReceipts') {
        return false;
      }
      if (feature === 'PickupUpdate') {
        return false;
      }
      if (feature === 'PickupMenuUpdate') {
        return false;
      }
    } else if (userRole.Name === 'Facilitator') {
      if (feature === 'downloadReceipts') {
        return true;
      }
      if (feature === 'PickupFilter') {
        return true;
      }
      if (feature === 'FRViewOnly') {
        return true;
      }
      if (feature === 'VisibleForAdminOnlyGrab') {
        return false;
      }
      if (feature === 'bulkMenuItemDeleted') {
        return false;
      }
      if (feature === 'schedulepickupbtn') {
        return false;
      }
      if (feature === 'foodpickup') {
        return false;
      }
      if (feature === 'genericproductview') {
        return false;
      }
      if (feature === 'nonProfitData') {
        return true;
      }
      if (feature === 'RecipientList') {
        return true;
      }
      if (feature === 'estimatedTax') {
        return false;
      }
      if (feature === 'AdminEditMenu') {
        return false;
      }
      if (feature === 'AdminDeleteMenu') {
        return false;
      }
      if (feature === 'DonationReceipts') {
        return false;
      }
      if (feature === 'ViewDonationReceipts') {
        return true;
      }
      if (feature === 'PickupUpdate') {
        return false;
      }
      if (feature === 'PickupMenuUpdate') {
        return false;
      }
      if (feature == 'inviteNewUser') {
        return true;
      }
      if (feature === 'accountdelete') {
        return true;
      }
    }
    //dashboard
    if (feature == 'dashboard') {
      return true;
    }

    //pickups
    if (feature == 'pickups') {
      return true;
    }
    if (feature == 'requestPickupView') {
      return false;
    }

    if (feature == 'requestStatus') {
      return false;
    }

    //customer
    if (feature == 'addNewAccount') {
      return false;
    }
    if (feature == 'customer') {
      return true;
    }
    if (feature == 'customerAddFilter') {
      return true;
    }
    if (feature == 'customerExport') {
      return true;
    }
    if (feature === 'accountdelete') {
      return false;
    }
    if (feature === 'editdetails') {
      return false;
    }

    //user
    if (feature == 'users') {
      return true;
    }
    if (feature == 'inviteNewUser') {
      return false;
    }
    if (feature == 'usersAddFilters') {
      return true;
    }
    if (feature == 'usersExport') {
      return true;
    }
    if (feature == 'edituserdetails') {
      return false;
    }

    //impact
    if (feature == 'impact') {
      return false;
    }
    return false;
  } else if (
    userRole.Name === 'SAAS Facility Group' ||
    userRole.Name === 'SAAS Facility'
  ) {
    if (feature === 'driverservices') {
      return false;
    }
  } else if (userRole.Name === 'Read-Only Admin') {
    if (feature === 'downloadReceipts') {
      return true;
    }
    if (feature === 'driverservices') {
      return true;
    }
    if (feature === 'foodpickup') {
      return false;
    }
    if (feature === 'schedulepickupbtn') {
      return false;
    }
    if (feature === 'VisibleForAdminOnlyGrab') {
      return true;
    }
    if (feature === 'requestStatus') {
      return false;
    }
    if (feature == 'edituserdetails') {
      return false;
    }
    // if (feature === 'editdetails') {
    //   return true;
    // }
    if (feature === 'AdminEditMenu') {
      return false;
    }
    if (feature === 'genericproductview') {
      return false;
    }
    if (feature === 'AdminDeleteMenu') {
      return false;
    }
    if (feature === 'OnlyFacility') {
      return false;
    }
    if (feature === 'DonationReceipts') {
      return false;
    }
    if (feature === 'ViewDonationReceipts') {
      return true;
    }
    if (feature === 'PickupUpdate') {
      return false;
    }
    if (feature === 'PickupMenuUpdate') {
      return false;
    }
    if (feature === 'inviteNewUser') {
      return false;
    }
    if (feature === 'bulkMenuItemDeleted') {
      return false;
    }
    //dashboard
    if (feature == 'dashboard') {
      return true;
    }

    //pickups
    if (feature == 'pickups') {
      return true;
    }
    if (feature == 'requestPickupView') {
      return false;
    }

    if (feature == 'requestStatus') {
      return true;
    }
    if (feature == 'taxValue') {
      return true;
    }

    //customer
    if (feature == 'addNewAccount') {
      return false;
    }
    if (feature == 'customer') {
      return true;
    }
    if (feature == 'customerAddFilter') {
      return true;
    }
    if (feature == 'customerExport') {
      return true;
    }
    if (feature === 'accountdelete') {
      return false;
    }
    if (feature === 'editdetails') {
      return false;
    }
    if (feature === 'changeMenuType') {
      return false
    }
    if (feature === 'addMenuItem') {
      return false
    }
    if (feature === 'editMenuItem') {
      return false
    }
    if (feature === 'editMenuItem') {
      return false
    }

    //user
    if (feature == 'users') {
      return true;
    }
    if (feature == 'inviteNewUser') {
      return false;
    }
    if (feature == 'usersAddFilters') {
      return true;
    }
    if (feature == 'usersExport') {
      return true;
    }
    if (feature == 'edituserdetails') {
      return false;
    }

    //impact
    if (feature == 'impact') {
      return false;
    }
    if (feature === 'nonProfitData') {
      return true;
    }
    return false;

  } else {
    return false;
  }
}
