import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timeFormatPipe'
})
export class timeFormatPipe implements PipeTransform {
  // transform(date: string) {
  //    const datePipe = new DatePipe('en-US');
  //    date = datePipe.transform(date, 'h:mm a');
  //    return date;
  // }
  transform(date: string, zone) {
    if (moment(date).isValid()) {
      let zonedate;
      if (zone) {
        zonedate = zone;
      } else {
        zonedate = 'America/New_York';
      }
      //   const  offset = -4.0;
      //   const  clientDate = new Date(date);
      //   const   utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
      // //   EST format
      //   const  serverDate = new Date(utc + (3600000 * offset));
      //   const myDate = new Date(serverDate);
      //   myDate.setHours(myDate.getHours() + 4) + ':' +  myDate.setMinutes(myDate.getMinutes() + 30);
      //   date = moment(myDate).format('LT');
      //   return date;
      const abbr = moment.tz.zone(zonedate).abbr(360);

      if (abbr == 'MST') {
        const updateDate = moment
          .utc(date)
          .clone()
          .tz(zonedate)
          // .add(60, 'minutes')
          .format('LT')
          .toString();
        return updateDate;
      } else {
        const updateDate = moment
          .utc(date)
          .clone()
          .tz(zonedate)
          .format('LT')
          .toString();
        return updateDate;
      }

    }
    return null;
  }
}
