import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { SpinnerService } from 'src/app/core/spinner.service';
import { dateOnlyWithTimezone } from 'src/app/shared/pipes/dateOnlyWithTimezone.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AddContractDialogComponent } from '../add-contract-dialog/add-contract-dialog.component';
import { AddeditDateComponent } from '../customers/addedit-date/addedit-date.component';
import { EditContractDialogComponent } from '../edit-contract-dialog/edit-contract-dialog.component';

@Component({
  selector: 'app-pickup-contract',
  templateUrl: './pickup-contract.component.html',
  styleUrls: ['./pickup-contract.component.scss']
})
export class PickupContractComponent implements OnInit {

  dataSource: any;
  globalcontractID: any;
  globalClient: any;
  filtermodal: boolean;
  globalPickup: any;
  globalNoOfContractedPickup: any;
  globalPerPickupPeriod: any;
  globalContractPickupPrice: any;
  globalExtraPickupPrice: any;
  globalContractStatus: any;
  pickupList: [];
  clientList: [];
  RecordCount: number;
  pageNumber: number;
  pageSize: number;
  selectedPageSize = [10, 25, 50, 100];
  filterInfo: any;
  globalClientName: any;
  contractPeriods = [];
  approvalStatuses = [];
  sortColumn: any = null;
  showReset: boolean = false;
  globalInvoiceStatus: any;
  statusList = [];
  displayedColumns: string[] = [
    'contractID',
    'clientName',
    'PickupType',
    'noContractedPickup',
    'pickupPeriod',
    'contractedPickupPrice',
    'extraPickupPrice',
    'contractStatus',
    'invoiceGenerated',
    'invoiceDue',
    'currentInvoiceStatus',
    'detail',
  ];
  mobileDisplayedColumns: string[] = ['contractID',
    'clientName',
    'PickupType',
    'noContractedPickup',
    'pickupPeriod',
    'contractedPickupPrice',
    'extraPickupPrice',
    'contractStatus',
    'invoiceGenerated',
    'invoiceDue',
    'currentInvoiceStatus',]
  invoiceDue_ToDate: any;
  invoiceDue_FromDate: any;
  invoiceGenerated_ToDate: any;
  invoiceGeneratedFromDate: any;
  constructor(private pickupService: PickUpService,
    private ClientFilterdata: ClientFilterService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    public dateTimeFormat: dateOnlyWithTimezone,
  ) { }
  ngOnInit() {
    var spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicInfoObserveclient.subscribe((res) => {
      const listOfClients = JSON.parse(res);
      if (listOfClients.allClients.length > 0) {
        this.clientList = listOfClients.allClients.sort(function (a, b) {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
    });

    this.pickupService.getPickupFilterList().subscribe((d) => {
      const list = d.Result;
      const index = list.findIndex((p) => p['Title'] === 'One Time Pickup');
      if (index > 1) {
        list.splice(index, 1);
      }
      this.pickupList = list;
    });
    this.pickupService.getinvoiceStatusList().subscribe((d) => {
      this.statusList = d.Result;
    });
    this.pickupService.getContractPeriod().subscribe((d) => {
      const list1 = d.Result;
      this.contractPeriods = list1;
    });
    this.pickupService.getApprovalStatus().subscribe((d) => {
      const list2 = d.Result;
      const index = list2.findIndex((p) => p['Title'] === 'Trash');
      if (index > 1) {
        list2.splice(index, 1);
      }
      this.approvalStatuses = list2;
    });

    this.globalcontractID = '';
    this.globalClient = '';
    this.globalPickup = '';
    this.globalNoOfContractedPickup = '';
    this.globalPerPickupPeriod = '';
    this.globalContractPickupPrice = '';
    this.globalExtraPickupPrice = '';
    this.globalContractStatus = '';
    this.pageSize = 10;
    this.pageNumber = 1;
    this.sortColumn = "-ID";
    this.globalInvoiceStatus = '';
    this.invoiceDue_FromDate = '';
    this.invoiceDue_ToDate = '';
    this.invoiceGenerated_ToDate = '';
    this.invoiceGeneratedFromDate = '';
    this.getPickupContract(spinnerRef);
  }

  setFilteredValue(category, secondCat, value) {
    var spinnerRef = this.spinnerService.start();
    this.pageNumber = 1;
    this[category] = value;

    if (category === 'globalClient' && value !== '') {
      const clients = this.clientList.find((client) => client['ClientId'] === value);
      this.globalClientName = clients['Name'];
    }

    if (['globalcontractID', 'globalClient', 'globalPickup', 'globalNoOfContractedPickup',
      'globalPerPickupPeriod', 'globalContractPickupPrice', 'globalExtraPickupPrice',
      'globalInvoiceStatus'].includes(category) && value === '') {
      this[category] = value;
      if (category == 'globalClient') {
        this.globalClientName = ''
      }
    }

    if (category === 'globalReset' && secondCat === 'reset') {

      this.globalcontractID = '';
      this.globalClient = '';
      this.globalClientName = '';
      this.globalPickup = '';
      this.globalNoOfContractedPickup = '';
      this.globalPerPickupPeriod = '';
      this.globalContractPickupPrice = '';
      this.globalExtraPickupPrice = '';
      this.globalContractStatus = '';
      this.pageSize = 10;
      this.pageNumber = 1;
      this.globalInvoiceStatus = '';
      this.invoiceDue_FromDate = '';
      this.invoiceDue_ToDate = '';
      this.invoiceGenerated_ToDate = '';
      this.invoiceGeneratedFromDate = '';
      this.getPickupContract(spinnerRef);
      this.checkFilters();
      return null;
    }
    this.getPickupContract(spinnerRef);
    this.checkFilters();
    return null;
  }
  getPickupContract(spinnerRef) {

    this.pickupService.getPickupContracts(this.pageNumber, this.pageSize, this.globalClientName, this.globalPickup, this.globalcontractID,
      this.globalNoOfContractedPickup, this.globalPerPickupPeriod.ID, this.globalContractPickupPrice, this.globalExtraPickupPrice,
      this.globalContractStatus, this.sortColumn, this.globalInvoiceStatus, this.invoiceDue_FromDate, this.invoiceDue_ToDate,
      this.invoiceGeneratedFromDate, this.invoiceGenerated_ToDate).subscribe((data) => {

        data.Result.map((val: any) => {
          val.Invoice_Due_Date = this.dateTimeFormat.transform(val.Invoice_Due_Date, val.TimeZone);
          val.Last_Invoice_Generated = this.dateTimeFormat.transform(val.Last_Invoice_Generated, val.TimeZone);
        });

        this.dataSource = data.Result
        this.RecordCount = data.RecordCount;
        if (this.invoiceDue_FromDate) this.invoiceDue_FromDate = moment(this.invoiceDue_FromDate).format('MM/DD/YY');
        if (this.invoiceDue_ToDate) this.invoiceDue_ToDate = moment(this.invoiceDue_ToDate).format('MM/DD/YY');
        if (this.invoiceGeneratedFromDate) this.invoiceGeneratedFromDate = moment(this.invoiceGeneratedFromDate).format('MM/DD/YY');
        if (this.invoiceGenerated_ToDate) this.invoiceGenerated_ToDate = moment(this.invoiceGenerated_ToDate).format('MM/DD/YY');
        this.spinnerService.stop(spinnerRef);
      },
        (error) => {
          console.log(error);
          this.spinnerService.stop(spinnerRef);
        });
  }
  // pagination
  onChange(e) {
    var spinnerRef = this.spinnerService.start();
    e ? (this.pageNumber = e) : null;
    if (this.invoiceGeneratedFromDate !== "" && this.invoiceGenerated_ToDate != "") {
      const selectedDate = new Date(this.invoiceGeneratedFromDate);
      const ToDate = new Date(this.invoiceGenerated_ToDate);
      ToDate.setHours(23);
      ToDate.setMinutes(59);
      ToDate.setSeconds(59);
      selectedDate.setHours(0);
      selectedDate.setMinutes(0);
      selectedDate.setSeconds(0);
      const selectedNDate = new Date(selectedDate);
      const selectedEndNDate = new Date(ToDate);
      const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
      const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];
      this.invoiceGeneratedFromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
      this.invoiceGenerated_ToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
    }
    if (this.invoiceDue_FromDate !== "" && this.invoiceDue_ToDate != "") {
      const selectedDate = new Date(this.invoiceDue_FromDate);
      const ToDate = new Date(this.invoiceDue_ToDate);
      ToDate.setHours(23);
      ToDate.setMinutes(59);
      ToDate.setSeconds(59);
      selectedDate.setHours(0);
      selectedDate.setMinutes(0);
      selectedDate.setSeconds(0);
      const selectedNDate = new Date(selectedDate);
      const selectedEndNDate = new Date(ToDate);
      const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
      const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];
      this.invoiceDue_FromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
      this.invoiceDue_ToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
    }
    this.getPickupContract(spinnerRef);
  }
  sortTable(event) {
    var spinnerRef = this.spinnerService.start();
    this.sortColumn = this.mapColumnName(event.active);
    if (this.sortColumn != null) {
      if (event.direction === 'desc') this.sortColumn = '-' + this.sortColumn;
      if (event.direction === 'asc') this.sortColumn = this.sortColumn;
      this.getPickupContract(spinnerRef);
    }
  }
  mapColumnName(columnName) {
    if (columnName === 'contractID') return 'ID';
    if (columnName === 'PickupType') return 'PickupType';
    if (columnName === 'pickupPeriod') return 'ContractPeriod';
    if (columnName === 'contractStatus') return 'ApprovalStatus';
    if (columnName === 'currentInvoiceStatus') return 'Invoice_Status';

    if (columnName === 'clientName') return 'ClientName';

    if (columnName === 'noContractedPickup') return 'No_Of_Contracted_Pickups';

    if (columnName === 'contractedPickupPrice') return 'Contract_Price_Per_Pickup';

    if (columnName === "extraPickupPrice") return 'Extra_Price_Per_Pickup';

    if (columnName === 'invoiceGenerated') return 'Last_Invoice_Generated';

    if (columnName === 'invoiceDue') return 'Invoice_Due_Date';

  }

  checkFilters() {
    if (
      this.globalcontractID || this.globalClient || this.globalPickup || this.globalNoOfContractedPickup ||
      this.globalPerPickupPeriod || this.globalContractPickupPrice || this.globalExtraPickupPrice ||
      this.globalContractStatus || this.invoiceDue_FromDate || this.invoiceDue_ToDate || this.invoiceGeneratedFromDate
      || this.invoiceGenerated_ToDate
    ) this.showReset = true;
    else this.showReset = false;

  }


  addNewContract() {
    const dialogRef = this.dialog.open(AddContractDialogComponent, {
      width: '1400px',
      height: '87vh',
      panelClass: 'pickup-dialog-container',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        var spinnerRef = this.spinnerService.start();
        this.getPickupContract(spinnerRef);
      }
    });
  }
  viewContract(element) {
    const dialogRef = this.dialog.open(EditContractDialogComponent, {
      width: '1400px',
      height: '87vh',
      data: element,
      panelClass: 'pickup-dialog-container',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        var spinnerRef = this.spinnerService.start();
        this.getPickupContract(spinnerRef);
      }
    });
  }
  viewInvoice(record) {
    if (record.InvoiceURL !== "" && record.InvoiceURL !== null) window.open(record.InvoiceURL, '_blank');
  }
  addFilterInvoice() {
    const dialogRef = this.dialog.open(AddeditDateComponent, {
      width: '800px',
      // height: '700px',
      panelClass: 'custom-datedialog-container',
      data: [
        {
          FromDate: this.invoiceDue_FromDate,
          ToDate: this.invoiceDue_ToDate,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.From != 'Invalid Date' && result.To != 'Invalid Date') {
          var spinnerRef = this.spinnerService.start();
          this.invoiceDue_FromDate = moment(result.From).format('MM/DD/YY');
          this.invoiceDue_ToDate = moment(result.To).format('MM/DD/YY');

          if (result !== undefined) {
            const selectedDate = new Date(result.From);
            const ToDate = new Date(result.To);

            ToDate.setHours(23);
            ToDate.setMinutes(59);
            ToDate.setSeconds(59);
            selectedDate.setHours(0);
            selectedDate.setMinutes(0);
            selectedDate.setSeconds(0);

            const selectedNDate = new Date(selectedDate);
            const selectedEndNDate = new Date(ToDate);
            const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
            const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];
            this.invoiceDue_FromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
            this.invoiceDue_ToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
            this.checkFilters();
            this.getPickupContract(spinnerRef);
          }
        } else {
          alert('Please select the Start date and End date');
        }
      }
    });
  }
  addFilterInvoiceGenerated() {
    const dialogRef = this.dialog.open(AddeditDateComponent, {
      width: '800px',
      // height: '700px',
      panelClass: 'custom-datedialog-container',
      data: [
        {
          FromDate: this.invoiceGenerated_ToDate,
          ToDate: this.invoiceGenerated_ToDate,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.From != 'Invalid Date' && result.To != 'Invalid Date') {
          var spinnerRef = this.spinnerService.start();
          this.invoiceGeneratedFromDate = moment(result.From).format('MM/DD/YY');
          this.invoiceGenerated_ToDate = moment(result.To).format('MM/DD/YY');

          if (result !== undefined) {
            const selectedDate = new Date(result.From);
            const ToDate = new Date(result.To);

            ToDate.setHours(23);
            ToDate.setMinutes(59);
            ToDate.setSeconds(59);
            selectedDate.setHours(0);
            selectedDate.setMinutes(0);
            selectedDate.setSeconds(0);

            const selectedNDate = new Date(selectedDate);
            const selectedEndNDate = new Date(ToDate);
            const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
            const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];
            this.invoiceGeneratedFromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
            this.invoiceGenerated_ToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
            this.checkFilters();
            this.getPickupContract(spinnerRef);
          }
        }
        else {
          alert('Please select the Start date and End date');
        }
      }
    });
  }
}
