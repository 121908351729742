import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { SuccessfulMenuDialogComponent } from '../successful-menu-dialog/successful-menu-dialog.component';

@Component({
  selector: 'app-upoload-menu-dialog',
  templateUrl: './upoload-menu-dialog.component.html',
  styleUrls: ['./upoload-menu-dialog.component.scss']
})
export class UpoloadMenuDialogComponent implements OnInit {
  modalclose: string;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<UpoloadMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }
  successfulmenu() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(SuccessfulMenuDialogComponent, {
      width: '750px', 
      // height: '550px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
