import { Injectable } from '@angular/core';

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateDispalyFormatPipe'
})
export class DateDispalyFormatPipeService {
  transform(date, zone) {
    if (moment(date).isValid()) {
      let zonedata;
      if (zone) {
        zonedata = zone
      } else {
        zonedata = 'America/New_York';
      }
      // console.log(
      //   moment(date)
      //     .utc()
      //     .format('YYYY-MM-DD' + 'T' + 'HH:mm:ss')
      // );
      const abbr = moment.tz.zone(zonedata).abbr(360);

      if (abbr == 'MST') {
        const updateDate = moment.utc(date).clone().tz(zonedata)
        // .add(60,'minutes')
        .format().split('T')[0] +
        'T' +moment.utc(date).clone().tz(zonedata)
        // .add(60,'minutes')
        .format().split('T')[1].split('-')[0].split('+')[0];
      return updateDate;
      }else{
        const updateDate = moment.utc(date).clone().tz(zonedata).format().split('T')[0] +
        'T' +moment.utc(date).clone().tz(zonedata).format().split('T')[1].split('-')[0].split('+')[0];
      return updateDate;
      }
      
    }
    return null;
  }
}
