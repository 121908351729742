import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { SavePickupListmodalComponent } from '../save-pickup-listmodal/save-pickup-listmodal.component';
import { MatDialog } from '@angular/material';
export interface PeriodicElement {
  name: string;
  Cost: number;
  weight: number;
  Quantity: string;
}
const ELEMENT_DATAA = {
  data : {
      Appetizers : [
    { name: 'Nachos', weight: 1, Cost: 12.00, Quantity: '' },
    { name: 'Antipasto', weight: 4, Cost: 19, Quantity: ''},
    { name: 'Lithium', weight: 6, Cost: 100, Quantity: 'Li'},
    { name: 'Bacon', weight: 9, Cost: 79, Quantity: ''},
    { name: 'Achari Pimiento Cheese', weight: 11, Cost: 1.0079, Quantity: ''},
    { name: 'Carbon', weight: 12, Cost: 79, Quantity: ''},
    {name: 'Bacon', weight: 14, Cost: 69, Quantity: ''},
    { name: 'Nachos', weight: 15, Cost: 99, Quantity: ''},
    { name: 'Fluorine', weight: 18, Cost: 49,  Quantity: ''},
    { name: 'Neon', weight: 20, Cost: 29, Quantity: ''},
      ],
      Entrees : [
    { name: 'Hydrogen', weight: 1, Cost: 179, Quantity: ''},
    { name: 'Bacon', weight: 4, Cost: 19, Quantity: ''},
    { name: 'Lithium', weight: 6, Cost: 79, Quantity: ''},
    { name: 'Beryllium', weight: 9.22, Cost: 9, Quantity: ''},
    { name: 'Boron', weight: 101, Cost: 59, Quantity: ''},
    { name: 'Nachos', weight: 12, Cost: 29, Quantity: ''},
    {name: 'Nitrogen', weight: 14, Cost: 8, Quantity: ''},
    { name: 'Oxygen', weight: 15, Cost: 9, Quantity: ''},
    { name: 'Achari Pimiento Cheese', weight: 14, Cost: 1.0079,  Quantity: ''},
    { name: 'Neon', weight: 20, Cost: 19, Quantity: ''},
      ]
  }
};
const ELEMENT_DATA: PeriodicElement[] = [
  { name: 'Nachos', weight: 0.5, Cost: 12.00, Quantity: '' },
    { name: 'Antipasto', weight: 4, Cost: 19, Quantity: ''},
    { name: 'Lithium', weight: 6, Cost: 100, Quantity: 'Li'},
    { name: 'Bacon', weight: 9, Cost: 79, Quantity: ''},
    { name: 'Achari Pimiento', weight: 11, Cost: 18, Quantity: ''},
    { name: 'Carbon', weight: 2, Cost: 79, Quantity: ''},
    {name: 'Bacon', weight: 14, Cost: 69, Quantity: ''},
    { name: 'Nachos', weight: 15, Cost: 99, Quantity: ''},
    { name: 'Fluorine', weight: 18, Cost: 49,  Quantity: ''},
    { name: 'Neon', weight: 20, Cost: 29, Quantity: ''},
];

@Component({
  selector: 'app-edit-pickup-template',
  templateUrl: './edit-pickup-template.component.html',
  styleUrls: ['./edit-pickup-template.component.scss']
})
export class EditPickupTemplateComponent implements OnInit {
   fooditems: any;
   activeState = 'Appetizers';
   @ViewChild('widgetsContent', { static: true }) public widgetsContent: ElementRef<any>;
  //   @ViewChild('widgetsContent', { read : ElementRef })
  //  public widgetsContent: ElementRef<any>;
    displayedColumns: string[] = [ 'name',  'Cost', 'weight', 'Quantity'];
    dataSource = new MatTableDataSource(ELEMENT_DATA);
    allfooditems: any;
    public scrollRight(): void {
      this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
    }
    public scrollLeft(): void {
      this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    }
    constructor(private router: Router, public dialog: MatDialog) { }
   ngOnInit() {
      this.fooditems = ELEMENT_DATAA.data;
    }
    ViewConfirmList() {
      const dialogRef = this.dialog.open(SavePickupListmodalComponent, {
        width: '600px', height: '320px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    pickupListItems(state) {
      this.dataSource = this.fooditems.Entrees;
      this.activeState = state;
    }
  }
