import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material";
import { Router } from '@angular/router';

@Component({
  selector: "app-accountcompletion-menu-dialog",
  templateUrl: "./accountcompletion-menu-dialog.component.html",
  styleUrls: ["./accountcompletion-menu-dialog.component.scss"]
})
export class AccountcompletionMenuDialogComponent implements OnInit {
  modalclose: string;
  clientId: any;
  successData: any;
  typeData: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<AccountcompletionMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.successData = this.data[0];
    this.typeData = this.data[1].type;
    this.clientId = this.successData.clientId;
  }
  done() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    if (this.typeData !== 'Facility' && this.typeData !== 'SAAS Facility') {
      this.router.navigate(["CustomerFacilitatorview/" + this.clientId + ""]);
    } else {
      this.router.navigate(["FacilityDetails/" + this.clientId + ""]);
    }
    // this.router.navigate(["FacilityDetails/" + this.clientId + ""]);
    // const dialogRef = this.dialog.open(AccountCreationDialogComponent, {
    //   width: '600px', height: '400px',
    //   data: {}
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
}
