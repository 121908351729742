import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { Router } from '@angular/router';
import { AppState } from '../app-state';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public state: AppState) {}

    canActivate() {
        if (location.href.includes('featuretoggle')) {
            if (environment.app_env === 'prod') {
                this.router.navigate(['/error']);
            }
        }
        let localUser = JSON.parse(localStorage.getItem('user'));
        if(localUser){
            this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
            this.state.user = JSON.parse(localStorage.getItem('user')).user;
            (<any>window).godrEmail = this.state.user['User']['Email'];
            (<any>window).godrRole = this.state.user.User.Roles[0].Name;
        }
        else{
            this.state.isLoggedIn = false;
            this.state.user = null;
            (<any>window).godrEmail = null;
            (<any>window).godrRole = null;
        }
        if (this.state.isLoggedIn === true) {
            return true;
        }
        this.router.navigate(['login']);
        return false;
    }
}
