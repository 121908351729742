import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AppState } from 'src/app/shared/app-state';
import { Router, ActivatedRoute } from '@angular/router';

import { ShowGifDialogComponent } from './show-gif-dialog/show-gif-dialog.component';

@Component({
  selector: 'app-help-screen',
  templateUrl: './help-screen.component.html',
  styleUrls: ['./help-screen.component.scss'],
})
export class HelpScreenComponent implements OnInit {
  facilitylogin: boolean;
  foodDiv1: boolean = false;
  foodDiv2: boolean = false;
  foodDiv3: boolean = false;
  foodDiv4: boolean = false;
  foodDiv5: boolean = false;

  firstPickupDiv1: boolean = false;
  firstPickupDiv2: boolean = false;
  firstPickupDiv3: boolean = false;
  firstPickupDiv4: boolean = false;

  pickupDiv1: boolean = false;
  pickupDiv2: boolean = false;
  pickupDiv3: boolean = false;
  pickupDiv4: boolean = false;
  pickupDiv5: boolean = false;

  donationReceiptDiv1: boolean = false;
  donationReceiptDiv2: boolean = false;

  profileandsetting: boolean = false;

  impactReport: boolean = false;
  dashboard: boolean = false;

  foodStorageArray = [
    'Always refrigerate perishable food within 2 hours (or 1 hour if the temperature is above 90°F).',
    'Check the temperature of your refrigerator and freezer with an appliance thermometer. The refrigerator should be at 40°F or below, and the freezer should be at 0°F or below.',
    'Perishable food such as meat and poultry should be wrapped securely to maintain quality and to prevent meat juices from mixing with other food.',
  ];

  leftoverArray = [
    'Discard any food left out at room temperature for more than 2 hours (or 1 hour if the temperature is above 90°F).',
    'Place food in Goodr packaging and immediately put in the refrigerator or freezer for rapid cooling.',
  ];

  walkThroughSurplusArr = [
    '(See our Food Safety Guidelines for more details)',
    'Store the food at a safe temperature until a Goodr driver arrives for pickup.',
    'Click Request Pickup at the time you would like to request a pickup. Select the facility for pickup location, select surplus food pickup, and click next. Add items for pickup to your pickup list, review your pickup items, then click Submit Request.',
    'Once a Goodr driver has been assigned, you will be notified by text and will receive updates when the driver is on their way. You can also track the pickup in the app by clicking on Track Pickup to get the latest status update.',
  ];

  constructor(public dialog: MatDialog) {}
  // onNoClick(): void {
  //   this.dialogRef.close();
  // }

  ngOnInit() {
    // if (this.data[0].role === 'Facility') {
    //   this.facilitylogin = true;
    // }
  }

  showDiv(value) {
    switch (value) {
      case 'foodDiv1':
        this.foodDiv1 = !this.foodDiv1;
        break;
      case 'foodDiv2':
        this.foodDiv2 = !this.foodDiv2;
        break;
      case 'foodDiv3':
        this.foodDiv3 = !this.foodDiv3;
        break;
      case 'foodDiv4':
        this.foodDiv4 = !this.foodDiv4;
        break;
      case 'foodDiv5':
        this.foodDiv5 = !this.foodDiv5;
        break;
      case 'firstPickupDiv1':
        this.firstPickupDiv1 = !this.firstPickupDiv1;
        break;
      case 'firstPickupDiv2':
        this.firstPickupDiv2 = !this.firstPickupDiv2;
        break;
      case 'firstPickupDiv3':
        this.firstPickupDiv3 = !this.firstPickupDiv3;
        break;
      case 'firstPickupDiv4':
        this.firstPickupDiv4 = !this.firstPickupDiv4;
        break;
      case 'pickupDiv1':
        this.pickupDiv1 = !this.pickupDiv1;
        break;
      case 'pickupDiv2':
        this.pickupDiv2 = !this.pickupDiv2;
        break;
      case 'pickupDiv3':
        this.pickupDiv3 = !this.pickupDiv3;
        break;
      case 'pickupDiv4':
        this.pickupDiv4 = !this.pickupDiv4;
        break;
      case 'pickupDiv5':
        this.pickupDiv5 = !this.pickupDiv5;
        break;
      case 'donationReceiptDiv1':
        this.donationReceiptDiv1 = !this.donationReceiptDiv1;
        break;
      case 'donationReceiptDiv2':
        this.donationReceiptDiv2 = !this.donationReceiptDiv2;
        break;
      case 'profileandsetting':
        this.profileandsetting = !this.profileandsetting;
        break;
      case 'impactReport':
        this.impactReport = !this.impactReport;
        break;
      case 'dashboard':
        this.dashboard = !this.dashboard;
        break;
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
    this.foodDiv2 = true;
  }

  openDialog(src) {
    let arr = src.src.split('/');
    let imgSource = arr[arr.length - 1];

    const dialogRef = this.dialog.open(ShowGifDialogComponent, {
      width: '1000px',
      maxWidth: '90vw',
      // height: '440px',
      data: [{ imgSrc: '../../../../../assets/GIF/' + imgSource }],
      panelClass: 'show-gif-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
