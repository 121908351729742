import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pickup-order-complete',
  templateUrl: './pickup-order-complete.component.html',
  styleUrls: ['./pickup-order-complete.component.scss']
})
export class PickupOrderCompleteComponent implements OnInit {

  constructor(private router: Router) { }
  deletePickup() {
  }
  addFoodItem() {
    this.router.navigate(['Products']);
  }
  track_Order() {
    this.router.navigate(['trackpickup']);
     }
     request_Pickup_List() {
      this.router.navigate(['Products']);
    }
  ngOnInit() {
  }

}
