export function orCustomMaterialList(list) {
    let allDeliveryStatus = [];
    list.forEach(element => {
        switch (element['Title']) {
            case 'Organics':
                allDeliveryStatus[0] = element;
                break;
            case 'Municipal Solid Waste (MSW)':
                allDeliveryStatus[4] = element;
                break;
            case 'Animal Bedding':
                allDeliveryStatus[6] = element;
                break;
            case 'Single Stream':
                allDeliveryStatus[5] = element;
                break;
            case 'Glass':
                allDeliveryStatus[10] = element;
                break;
            case 'Cardboard':
                allDeliveryStatus[9] = element;
                break;
            case 'Pallets':
                allDeliveryStatus[14] = element;
                break;
            case 'Aluminum':
                allDeliveryStatus[7] = element;
                break;
            case 'Paper':
                allDeliveryStatus[12] = element;
                break;
            case 'Plastic':
                allDeliveryStatus[13] = element;
                break;
            case 'Post-Consumer Organics':
                allDeliveryStatus[2] = element;
                break;
            case 'Pre-Consumer Organics':
                allDeliveryStatus[3] = element;
                break;
            case 'Special Waste (Animal)':
                allDeliveryStatus[7] = element;
                break;
            case 'Building Materials':
                allDeliveryStatus[8] = element;
                break;
            case 'Metal':
                allDeliveryStatus[11] = element;
                break;
            case 'Books':
                allDeliveryStatus[15] = element;
                break;
        }
    });

    let fRow = [];
    for (let i in allDeliveryStatus) {
        if (allDeliveryStatus[i]) {
            fRow.push(allDeliveryStatus[i]);
        }
    }

    return fRow;
}