import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { AppState } from 'src/app/shared/app-state';
@Component({
  selector: 'app-onetimebillingsuccess',
  templateUrl: './onetimebillingsuccess.component.html',
  styleUrls: ['./onetimebillingsuccess.component.scss']
})
export class OnetimebillingsuccessComponent implements OnInit {
  portalAccess = 'false';
  yearlyPay: any;
  confDate: string;
  user: any;
  editClientID: any;
  clientData: any;
  yearlyPlan: boolean;
  constructor(private router: Router, private state: AppState, private sassService: SAASService,) { }

  ngOnInit() {
    JSON.parse(localStorage.getItem('updateheckout'));
    this.portalAccess = localStorage.getItem('updateheckout');
    if (this.portalAccess === 'true') {
      this.user = this.state.user;
      if (this.user.User.Roles[0].Name === 'Facility Group') {
        if (this.user.User.Clients[0].DriverService === 'SAAS') {
          this.editClientID = this.user.FacilityGroupId;
        }
      }
      if (this.user.User.Roles[0].Name === 'Facility') {
        if (this.user.User.Clients[0].DriverService=== 'SAAS') {
          this.editClientID = this.user.User.Clients[0].ClientId;
        }
      }
      this.sassService.getSubDetails(this.editClientID).subscribe(
        data => {
          this.clientData = data.Result[0];
          if (this.clientData.StripePlanId === 'plan_I7hCXZP2V4MCcX') {
            this.yearlyPlan = true;
            this.confDate = moment(new Date()).add(1, 'y').format('MM/DD/YY');
          } else {
            this.yearlyPlan = false;
            this.confDate = moment(new Date()).add(1, 'M').format('MM/DD/YY');
          }
        }
      );
    } else {
      this.yearlyPay = JSON.parse(localStorage.getItem('accountType'));
      if (this.yearlyPay === 'montly') {
        this.confDate = moment(new Date()).add(1, 'M').format('MM/DD/YY');
      } else {
        this.confDate = moment(new Date()).add(1, 'y').format('MM/DD/YY');
      }
    }
  }
  ngOnDestroy() {
    localStorage.removeItem('accountType');
  }
  goToCheckoutPage() {
    this.router.navigate(['checkout']);
  }
  }

