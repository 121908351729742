import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/shared/services/profilesetting.service';
import { AppState } from 'src/app/shared/app-state';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';

@Component({
  selector: 'app-numberchangedialog',
  templateUrl: './numberchangedialog.component.html',
  styleUrls: ['./numberchangedialog.component.scss']
})
export class NumberchangedialogComponent implements OnInit {
  public numberForm: FormGroup;
  returnNumber: number;
  numberReq: boolean;
  number: any;
  buttonDisabled: boolean;
  constructor(private state: AppState, private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<NumberchangedialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MatDialog, public profileService: ProfileService) { }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.returnNumber = this.data[0].user_number;
    this.numberForm = this.formBuilder.group({
      number: ['', Validators.compose([
        Validators.required, Validators.minLength(12), Validators.maxLength(13)
      ])],
    });
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }
  }
  saveUpdates() {
    if (this.number === undefined) {
      this.number = this.returnNumber;
    } else {
      this.number = this.number;
    }
    this.numberForm.controls.number.setValue(numberSetMask(this.number));
    if (this.numberForm.valid) {
      if (this.number) {
        this.returnNumber = this.number;
        const body = {
          Id: this.state.user.User.Id,
          FirstName: this.state.user.User.FirstName,
          LastName: this.state.user.User.LastName,
          Title: this.state.user.User.Title,
          PasswordHash: this.state.user.User.PasswordHash,
          ApprovalStatusId: 2,
          ImageUrl: this.state.user.User.ImageUrl,
          Email: this.state.user.User.Email,
          PhoneNumber: this.number,
          UserName: this.state.user.User.UserName,
          UserId: this.state.user.User.UserId,
          Roles: this.state.user.User.Roles,
          Clients: this.state.user.User.Clients,
          WP_Pwd_Hash: this.state.user.User.WP_Pwd_Hash,
          WP_User_Id: this.state.user.User.WP_User_Id,
          EmailConfirmed: this.state.user.User.EmailConfirmed,
          SecurityStamp: this.state.user.User.SecurityStamp,
          PhoneNumberConfirmed: this.state.user.User.EmailConfirmed,
          TwoFactorEnabled: this.state.user.User.TwoFactorEnabled,
          LockoutEndDateUtc: this.state.user.User.LockoutEndDateUtc,
          LockoutEnabled: this.state.user.User.LockoutEnabled,
          AccessFailedCount: this.state.user.User.AccessFailedCount,
          ResetCode: this.state.user.User.ResetCode,
          User_Client : [],
          CityId: this.state.user.User.CityId,
          StateId: this.state.user.User.StateId,
          IsSAASDriver: this.state.user.User.IsSAASDriver,
          CreatedBy: this.state.user.User.CreatedBy,
          CreatedOn: this.state.user.User.CreatedOn,
          ModifiedOn: this.state.user.User.ModifiedOn,
          ModifiedBy: this.state.user.User.ModifiedBy
        };
        this.buttonDisabled = true;
        this.state.user.User.PhoneNumber = this.number;
        localStorage.setItem('user', JSON.stringify(this.state));
        this.profileService.profileSettingedit(body, this.state.user.User.Id).subscribe(
          res => {
          },
          error => {
            // alert(JSON.stringify(error));
            this.buttonDisabled = false;
          });
        this.dialogRef.close(this.returnNumber);
      } else {
        this.numberReq = true;
        setTimeout(() => {
          this.numberReq = false;
        }, 3000);
      }
    } else {
      this.numberReq = true;
      setTimeout(() => {
        this.numberReq = false;
      }, 1000);
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.numberForm.controls[controlName].hasError(errorName);
  }
}

