import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { MatDialog } from '@angular/material';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { getFeature } from 'src/app/shared/services/featureGuard.model';

@Component({
  selector: 'app-request-pickup',
  templateUrl: './request-pickup.component.html',
  styleUrls: ['./request-pickup.component.scss']
})
export class RequestPickupComponent implements OnInit {
  nomenu = false;
  menu = true;
  clientsData: any;
  info: any;
  foodrequest = false;
  createForm: any;
  combined: boolean;
  VisibleForFacility: boolean;
  singlefacility = false;
  constructor(
    private spinnerService: SpinnerService,
    private ClientFilterdata: ClientFilterService,
    private router: Router,
    private state: AppState,
    public dialog: MatDialog
  ) {}
  public form = {
    data: null
  };

  clientsdataChange(value) {
    if (value.data.MenuType == null) {
      this.nomenu = true;
      this.menu = false;
    } else {
      this.foodrequest = false;
      this.combined = false;
      this.menu = true;
      this.nomenu = false;
      if (value.data.PickupType.length > 0) {
        value.data.PickupType.map(pickuptype => {
          if (pickuptype === 'Food')  this.foodrequest = true;
          if (pickuptype === 'Organic Recycling') this.combined = true;

        });
      } else {
        this.foodrequest = false;
        this.combined = false;
      }
    }
  }
  onSubmit() {
    localStorage.setItem('Combined', JSON.stringify('foodType'));
    this.router.navigate(['Products/' + this.form.data.ClientId + '']);
  }
  ngOnInit() {
    this.state.user = JSON.parse(localStorage.getItem('user')).user;
    this.VisibleForFacility = getFeature('foodpickup');
    var spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicInfoObserve.subscribe(res => {
      this.info = JSON.parse(res);
      if (this.info.facility.length > 0) {
        this.clientsData = this.info.facility.filter(
          value =>
            value.ApprovalStatus === 'Active' &&
            value.PickupType[0] !== 'One Time Pickup'
        );
        this.spinnerService.stop(spinnerRef);
        if (this.state.user.User.Roles[0].Name === 'Facility') {
          if (this.clientsData.length === 1) {
            this.form.data = this.clientsData[0];
            this.singlefacility = true;
            this.clientsdataChange(this.form)
          }
        }
      }
    });
  }
  CreatePickupCompost() {
    localStorage.setItem('Combined', JSON.stringify('CombinedRequest'));
    this.router.navigate(['Products/' + this.form.data.ClientId + '']);
  }
  CreatePickupSeperate() {
    localStorage.setItem('Combined', JSON.stringify('Separated'));
    this.router.navigate(['Products/' + this.form.data.ClientId + '']);
  }
  CreatePickupNomenu() {
    this.router.navigate(['FacilityDetails/' + this.form.data.ClientId + '']);
  }
}
