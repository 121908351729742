import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { PickupsFilterComponent } from '../pickups/pickupsFilter/pickupsFilter.component';
import { ExcelServicesService } from 'src/app/shared/services/excel-services.service';
import { AppState } from 'src/app/shared/app-state';
import { Observable } from 'rxjs';
import { PickUps } from 'src/app/models/pickups.model';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { AddeditDateComponent } from '../customers/addedit-date/addedit-date.component';
import * as moment from 'moment-timezone';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { PickupeditableComponent } from './pickupeditable/pickupeditable.component';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { GlobalService } from 'src/app/shared/app.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { TimezoneService } from 'src/app/shared/pipes/timezone.service';
import { TimeZoneabbrService } from 'src/app/shared/pipes/time-zoneabbr.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { PickupsRequestComponent } from './pickups-request/pickups-request.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
@Component({
  selector: 'app-pickups',
  templateUrl: './pickups.component.html',
  styleUrls: ['./pickups.component.scss', '../dashboard/dashboard.component.scss'],
})
export class PickupsComponent implements OnInit, AfterViewInit {
  selectedEmoji: any;
  dateFormat: string;
  RecordCount: any;
  deliveryData: any;
  ToDate: string;
  FromDate: string;
  finalFromDate: any;
  finalToDate: any;
  pageSize: number;
  selectedClient: number;
  filterData: any;
  isRequestPickupsVisible: boolean;
  istaxValueVisible: boolean;
  selectedPageSize = [10, 25, 50, 100];
  sortColumn: any = null;
  pickuptotalData: any;
  totalitemCost: any;
  listdata: PickUps[];
  addfilter: boolean;
  buttonExport: boolean = false;
  filterCount = [];
  edieDate: any;
  editDate: any;
  selecteToddate: any;
  selectFromDate: any;
  pageNumber: number;
  info: any;
  globalStatus: any;
  globalClient: any;
  globalDelivery: any;
  globalRecipient: any;
  facilityGroupId: any;
  facilitatorId: any;
  globalPickupType: any;
  checkUnits: number = 0;
  weight: string = 'lbs';
  Checkbox: boolean;
  AccountName = [];
  infoToggle: any;
  productName: any;
  productCost: any;
  productQuantity: any;
  productWeight: any;
  productTotalWeight: number;
  productTaxValue: any;
  VisibleForAdminPickupUpdate: boolean;
  spinnerRefilter: any;
  selectedclientName: any;
  selectedRecipientName: any;
  selectedPickupName: any;
  selectedStatus: any;
  selectedPickup: any;
  filtermodal: boolean;
  token: any;
  exportData: string;
  globalPickup: any;
  sassinfo: any;
  // user: any;
  sassinfodata: boolean = true;
  relaodinfo: any;
  globalDriverService: any;
  downloadData: string;
  selectclient: boolean;
  VisibleFordownloadReceipts: boolean;
  Visibledriverservice = true;
  filterStatus: string;
  filterFlag: string;
  Recipient: any;
  RecipientList: any;
  RecipientData: any;
  recip: any[] = [];
  driverServices: [];
  pickupList: [];
  clientList = [];
  globalClientName: any;
  globalFlag: any = '';
  // selDriverservice: any;
  showReset: boolean = false;
  pendingFilterPickup: boolean = false;

  private _globalClient2: any;
  public get globalClient2(): any {
    return this._globalClient2;
  }
  public set globalClient2(value: any) {
    this.globalClient = value;
    this._globalClient2 = value;
  }

  constructor(
    public dateTimeFormat: dateFormatPipe,
    public timeZoneabbr: TimeZoneabbrService,
    private spinnerService: SpinnerService,
    private ClientFilterdata: ClientFilterService,
    private pickupService: PickUpService,
    private state: AppState,
    private excelService: ExcelServicesService,
    private globalService: GlobalService,
    public dialog: MatDialog,
    private router: Router,
    private _rdata: modalDataService,
    private customerService: CustomerService,
    public timezone: TimezoneService
  ) {
    this.state.user = JSON.parse(localStorage.getItem('user')).user;
    if (this.state.user.User.Roles[0].Name === 'Facility') {
      if (this.state.user.User.Clients[0].ClientType === 'SAAS Facility') {
        this.ClientFilterdata.basicInfosassObserve.subscribe((res) => {
          this.sassinfo = JSON.parse(res);
          this.sassinfodata = this.sassinfo.subscription;
        });
      }
    }
    this._rdata.basicInfo1Observe.subscribe((res) => {
      this.info = JSON.parse(res);
      if (this.info.status === 'OnRoute') {
        this.selectedStatus = 'Food Acquired';
      } else if (this.info.status === 'PickedUp') {
        this.selectedStatus = 'Loading Truck';
      } else {
        this.selectedStatus = this.info.status;
      }
      this.globalStatus = this.info.status;
      this.globalClient = this.info.client;
      this.globalClientName = this.info.selectedclientName;
      this.globalDelivery = this.info.Delivery;
      this.globalRecipient = this.info.Recipient;
      this.globalPickup = this.info.Pickup;
      this.globalDriverService = this.info.DriverService;
      this.facilitatorId = this.info.facilitatorId;
      this.facilityGroupId = this.info.facilityGroupId;
      this.selectedRecipientName = this.info.selectedRecipientName;
      this.selectedPickupName = this.info.selectedPickupName;
      this.selectedclientName = this.info.selectedclientName;
      this.pageNumber = this.info.pageNumber;
      this.pageSize = this.info.pageSize;
      this.finalFromDate = this.info.finalFromDate;
      this.finalToDate = this.info.finalToDate;
      this.FromDate = moment().subtract(365, 'days').format('MMM DD YYYY');
      this.ToDate = moment().format('MMM DD YYYY');
      if (this.finalFromDate === '')
        this.finalFromDate =
          moment().subtract(365, 'days').toISOString().split('T')[0] +
          'T' +
          moment().subtract(365, 'days').toISOString().split('T')[1].split('-')[0] +
          '.00';
      if (this.finalToDate === '')
        this.finalToDate = moment().toISOString().split('T')[0] + 'T' + moment().toISOString().split('T')[1].split('-')[0] + '.00';
      this.sortColumn = this.info.sortColumn;

      this.filterCount = [];
      for (const item in this.info) {
        if (this.info[item]) {
          if (this.info[item] !== null) {
            if (
              this.info[item] !== undefined &&
              item !== 'selectedRecipientName' &&
              item !== 'sortColumn' &&
              item !== 'selectedclientName' &&
              item !== 'finalToDate' &&
              item !== 'finalFromDate' &&
              item !== 'pageSize' &&
              item !== 'pickupsPage' &&
              item !== 'pageNumber'
            ) {
              this.filterCount.push(this.info[item]);
            }
          }
        }
      }
    });

    if (!this.sortColumn) {
      this.sortColumn = '-CreatedOn';
    }
    this.info.finalFromDate = this.finalFromDate;
    this.info.finalToDate = this.finalToDate;
    this.info.sortColumn = this.sortColumn;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
  }
  excelExport: any = [];

  public pickups_counts = {
    pickups_todays_count: 0,
    active_pickups_count: 0,
    completed_pickups_count: 0,
  };
  mobileDisplayedColumns: string[] = ['status', 'Name', 'driver', 'Pickup', 'taxValue', 'weight', 'CreatedOn', 'PickupDate', 'DeliveryDate'];
  displayedColumns: string[] = [
    'status',
    'Title',
    'requestId',
    'Name',
    'driver',
    'Recipient',
    'Pickup',
    'taxValue',
    'weight',
    'CreatedOn',
    'PickupDate',
    'DeliveryDate',
    // 'Edit',
    'flag',
    'detail',
  ];
  statusOptions: string[] = ['Pending', 'Unclaimed', 'Claimed', 'Loading Truck', 'Food Acquired', 'Delivered', 'Cancelled', 'Scheduled'];
  dataSource = new MatTableDataSource<PickUps>(ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.ClientFilterdata.basicInfoObserveclient.subscribe((res) => {
      const listOfClients = JSON.parse(res);
      if (listOfClients.allClients.length > 0) {
        this.clientList = listOfClients.allClients.sort(function (a, b) {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
    });
    this.pickupService.getPickupFilterList().subscribe((d) => {
      const list = d.Result;
      const index = list.findIndex((p) => p['Title'] === 'One Time Pickup');
      if (index > 1) {
        list.splice(index, 1);
      }
      this.pickupList = list;
    });
    this.pickupService.getRecipientList().subscribe((recip) => {
      const data = recip;
      this.Recipient = data.sort(function (a, b) {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
          return -1;
        }
        if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const recipients = [];
      for (let i = 0; i < this.Recipient.length; i++) {
        this.RecipientData = this.Recipient[i];
        const recipients_data = {
          RecipientId: this.RecipientData.ClientId,
          Name: this.RecipientData.Name,
        };
        recipients.push(recipients_data);
      }
      this.RecipientList = recipients;
      
    });
    this.customerService.getPickupTypeList().subscribe((d) => {
      this.driverServices = d.Result;
    });
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
      if (this.relaodinfo.reloadmenuData === true) {
        this.getPickups();
      }
    });
    this.Visibledriverservice = getFeature('driverservices');
    this.VisibleForAdminPickupUpdate = getFeature('PickupUpdate');
    this.VisibleFordownloadReceipts = getFeature('downloadReceipts');
    this._rdata.basicInfotoggleObserve.subscribe((res) => {
      this.infoToggle = JSON.parse(res);
    });
    if (this.infoToggle.checkToggle === true) {
      this.Checkbox = true;
      this.checkUnits = 1;
    } else {
      this.Checkbox = false;
      this.checkUnits = 0;
    }
    this.isRequestPickupsVisible = getFeature('requestPickupView');
    this.istaxValueVisible = getFeature('taxValue');

    if (getFeature('pickups') === true) {
      this.getPickUpCounts();
      this.getPickups();
    }
    this.checkFilters();
  }
  downloadrec() {
    this.token = this.state.user.User.Id;
    let filterString = '?';
    if (this.state.user.User.Roles[0].Name === 'Admin' || this.state.user.User.Roles[0].Name === 'Billing Admin' || this.state.user.User.Roles[0].Name === 'Read-Only Admin') {
      
      if (this.globalClient2) {
        filterString += 'ClientId=' + this.globalClient2 + '&';
      }
      else if (this.globalClient) {
        filterString += 'ClientId=' + this.globalClient + '&';
      }
      else if (this.globalRecipient) {
        filterString += 'ClientId=' + this.globalRecipient + '&';
      }
      else if (this.facilitatorId) {
        filterString += 'ClientId=' + this.facilitatorId + '&';
      }
      else if (this.facilityGroupId) {
        filterString += 'ClientId=' + this.facilityGroupId + '&';
      }
      if (this.finalFromDate && this.finalToDate) {
        filterString += 'From=' + this.finalFromDate.split('Z')[0] + '&To=' + this.finalToDate.split('Z')[0];
      }
      filterString += '&UID=' + this.token;
      if (this.globalClient || this.globalRecipient || this.facilityGroupId || this.facilitatorId) {
        window.open(this.globalService.DownloadReceipt + filterString, '_blank');
      } else {
        this.selectclient = true;
        setTimeout(() => {
          this.selectclient = false;
        }, 6000);
      }
    } else {
      filterString += 'ClientId=' + 0 + '&';
      if (this.finalFromDate && this.finalToDate) {
        filterString += 'From=' + this.finalFromDate.split('Z')[0] + '&To=' + this.finalToDate.split('Z')[0];
      }
      filterString += '&UID=' + this.token;
      window.open(this.globalService.DownloadReceipt + filterString, '_blank');
    }
  }
  exportAsXLSX(): void {
    this.token = this.state.user.User.Id;
    let filterString = '?parameterModel.uID=' + this.token;
    if (this.globalDelivery) {
      filterString += '&parameterModel.DeliveryId=' + this.globalDelivery;
    }
    if (this.globalStatus) {
      filterString += '&parameterModel.Status=' + this.globalStatus;
    }
    if (this.globalClient) {
      filterString += '&parameterModel.ClientId=' + this.globalClient;
    }
    if (this.globalPickupType) {
      filterString += '&parameterModel.DriverServices=' + this.globalPickupType;
    }
    if (this.facilityGroupId) {
      filterString += '&parameterModel.FacilityGroupId=' + this.facilityGroupId;
    }
    if (this.facilitatorId) {
      filterString += '&parameterModel.FacilitatorId=' + this.facilitatorId;
    }
    if (this.globalRecipient) {
      filterString += '&parameterModel.RecipantId=' + this.globalRecipient;
    }
    if (this.globalPickup) {
      filterString += '&parameterModel.PickupType=' + this.globalPickup;
    }
    if (this.globalDriverService) {
      filterString += '&parameterModel.DriverService=' + this.globalDriverService;
    }
    if (this.info.active) {
      filterString += '&parameterModel.ActivePickup=' + this.info.active;
    }
    if (this.sortColumn) {
      filterString += '&parameterModel.SortColumn=' + this.sortColumn;
    }
    if (this.finalFromDate && this.finalToDate) {
      filterString += '&parameterModel.From=' + this.finalFromDate.split('Z')[0] + '&parameterModel.To=' + this.finalToDate.split('Z')[0];
    }
    if (this.finalFromDate && this.finalToDate) {
      filterString += '&parameterModel.OzWeight=' + !this.Checkbox;
    }

    this.exportData = this.globalService.DeliveryExports + filterString;
  }

  closePickups(closeData) {
    if (closeData === 'delivery') {
      this.globalDelivery = null;
    } else if (closeData === 'Status') {
      this.globalStatus = null;
      this.selectedStatus = null;
      this.info.active = null;
    } else if (closeData === 'Client') {
      this.globalClient = null;
      this.selectedclientName = null;
    } else if (closeData === 'Recipient') {
      this.globalRecipient = null;
    } else if (closeData === 'Pickup') {
      this.globalPickup = null;
    } else if (closeData === 'DriverService') {
      this.globalDriverService = null;
    }
    this.filterCount = [];
    const data = {
      Delivery: this.globalDelivery,
      Status: this.globalStatus,
      EmaClientil: this.globalClient,
      Recipient: this.globalRecipient,
      Pickup: this.globalPickup,
      DriverService: this.globalDriverService,
    };
    for (const item in data) {
      if (data[item]) {
        if (data[item] !== null) {
          if (data[item] !== undefined) {
            this.filterCount.push(data[item]);
          }
        }
      }
    }
    this.info.selectedclientName = this.selectedclientName;
    this.info.status = this.globalStatus;
    this.info.client = this.globalClient;
    this.info.Delivery = this.globalDelivery;
    this.info.Recipient = this.globalRecipient;
    this.info.Pickup = this.globalPickup;
    this.info.DriverService = this.globalDriverService;
    var spinnerRef = this.spinnerService.start();
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this.pickupService
      .getFilteredDeliveries(
        this.pageNumber,
        this.pageSize,
        this.globalDelivery,
        this.globalStatus,
        this.globalClient,
        this.globalPickupType,
        this.facilityGroupId,
        this.facilitatorId,
        this.globalRecipient,
        this.globalPickup,
        this.globalDriverService,
        this.info.active,
        this.sortColumn,
        this.finalFromDate,
        this.finalToDate,
        this.pendingFilterPickup,
        this.globalFlag
      )
      .subscribe(
        (data) => {
          this.spinnerService.stop(spinnerRef);
          this.filterData = data;
          this.deliveryData = data;
          this.RecordCount = data.RecordCount;
          const pickups: PickUps[] = [];
          for (let i = 0; i <= data.Result.length - 1; i++) {
            const pickUnit: PickUps = data.Result[i];

            pickups.push(pickUnit);
          }
          this.listdata = pickups;
          this.dataSource = new MatTableDataSource(pickups);
          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          alert(JSON.stringify(error));
        }
      );
  }
  getPickUpCounts() {
    /* this is for API optimization start call*/
    this.pickupService.getFilteredDeliverieCount(1, 1, this.finalFromDate.split('Z')[0], this.finalToDate.split('Z')[0]).subscribe(
      (data) => {
        this.pickups_counts.pickups_todays_count = data.TotalPendingPickupsCount;
        this.pickups_counts.active_pickups_count = data.TotalActivePickupsCount;
        this.pickups_counts.completed_pickups_count = data.TotalCompletedPickupsCount;
      },
      (error) => {
        this.state.isSpinnerVisible = false;
      }
    );
  }
  pickupcountlist(event) {
    this.pendingFilterPickup = false;

    if (event == 'Pending Pickups') {
      this.info.status = null;
      this.selectedStatus = 'Pending Pickups';
      this.globalStatus = 'Pending Pickups';
      this.pendingFilterPickup = true;
    }
    if (event == '') this.filterStatus = '';
    this.pageNumber = 1;
    if (event === 'Active') {
      this.info.active = true;
      this.info.status = null;
      this.selectedStatus = 'Active';
      this.globalStatus = 'Active';
    } else {
      this.globalStatus = event;
      this.info.active = null;
      this.info.status = this.globalStatus;
    }
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this.checkFilters();
    this.getPickups();
  }
  resetPickups() {
    this.addfilter = false;
    this.pageSize = 10;
    let spinnerRef = this.spinnerService.start();
    this.sortColumn = '-CreatedOn';
    this.pickupService
      .getFilteredDeliveries(
        1,
        this.pageSize,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.sortColumn,
        this.finalFromDate,
        this.finalToDate,
        this.pendingFilterPickup,
        this.globalFlag
      )
      .subscribe(
        (data) => {
          this.spinnerService.stop(spinnerRef);
          this.filterCount = [];
          this.globalStatus = null;
          this.globalClient = null;
          this.selectedclientName = null;
          this.globalDelivery = null;
          this.globalRecipient = null;
          this.globalPickup = null;
          this.globalDriverService = null;
          this.facilityGroupId = null;
          this.facilitatorId = null;
          this.finalFromDate = null;
          this.finalToDate = null;
          this.selectedRecipientName = null;
          this.selectedPickupName = null;
          this.pageNumber = 1;
          this.pageSize = 10;
          this.info.active = null;
          this.info.selectedclientName = this.selectedclientName;
          this.info.selectedRecipientName = this.selectedRecipientName;
          this.info.selectedPickupName = this.selectedPickupName;
          this.info.status = this.globalStatus;
          this.info.client = this.globalClient;
          this.info.Delivery = this.globalDelivery;
          this.info.Recipient = this.globalRecipient;
          this.info.Pickup = this.globalPickup;
          this.info.DriverService = this.globalDriverService;
          this.info.facilityGroupId = this.facilityGroupId;
          this.info.facilitatorId = this.facilitatorId;
          this.info.pageNumber = this.pageNumber;
          this.info.pageSize = this.pageSize;
          this.finalFromDate = moment().subtract(365, 'days').format('MMM DD YYYY');
          this.finalToDate = moment().format('MMM DD YYYY');
          this.finalToDate.setHours(23);
          this.finalToDate.setMinutes(59);
          this.finalToDate.setSeconds(59);
          this.finalFromDate.setHours(0);
          this.finalFromDate.setMinutes(0);
          this.finalFromDate.setSeconds(0);
          const selectedNDateResult = moment(this.finalFromDate).add(0, 'hours').format().toString().split('+')[0];
          const selectedEndDateResult = moment(this.finalToDate).add(0, 'hours').format().toString().split('+')[0];
          this.finalFromDate = selectedNDateResult;
          this.finalFromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
          this.finalToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
          this.ToDate = moment().format('MMM DD YYYY');
          this.FromDate = moment(this.finalFromDate).format('MMM DD YYYY');
          this.info.finalFromDate = this.finalFromDate;
          this.info.finalToDate = this.finalToDate;
          this.info.sortColumn = this.sortColumn;
          this._rdata.updateBasicInfo1(JSON.stringify(this.info));
          this.filterData = data;
          this.deliveryData = data;
          this.RecordCount = data.RecordCount;
          const pickups: PickUps[] = [];
          for (let i = 0; i <= data.Result.length - 1; i++) {
            const pickUnit: PickUps = data.Result[i];

            pickups.push(pickUnit);
          }
          pickups.map((val) => {
            if (val['ScheduleDate']) {
              val['ScheduleDate'] = this.dateTimeFormat.transform(val['ScheduleDate'], val.TimeZone);
            }
            val.OrderSubmitted = this.dateTimeFormat.transform(val.OrderSubmitted, val.TimeZone);
            val.PickupDate = this.dateTimeFormat.transform(val.PickupDate, val.TimeZone);
            val.DeliveryDate = this.dateTimeFormat.transform(val.DeliveryDate, val.TimeZone);
            val.abbrivation = this.timeZoneabbr.transform(val.TimeZone);
          });
          this.dataSource = new MatTableDataSource(pickups);
          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
          this.spinnerService.stop(spinnerRef);
        }
      );
  }
  getPickups() {
    if (!this.FromDate || !this.ToDate) {
      this.ToDate = moment().format('MMM DD YYYY');
      this.FromDate = moment().subtract(365, 'days').format('MMM DD YYYY');

      this.finalFromDate =
        moment().subtract(365, 'days').toISOString().split('T')[0] +
        'T' +
        moment().subtract(365, 'days').toISOString().split('T')[1].split('-')[0] +
        '.00';
      this.finalToDate = moment().toISOString().split('T')[0] + 'T' + moment().toISOString().split('T')[1].split('-')[0] + '.00';
    } else {
      this.finalFromDate = this.info.finalFromDate;
      this.finalToDate = this.info.finalToDate;

      this.FromDate = moment(this.finalFromDate.split('.')[0]).format('MMM DD YYYY');
      this.ToDate = moment(this.finalToDate.split('.')[0]).format('MMM DD YYYY');
    }

    if (!this.pageNumber || !this.pageSize) {
      this.pageNumber = 1;
      this.pageSize = 10;
    }
    if (this.info.active === true) {
      this.info.active = true;
      this.selectedStatus = 'Active';
    } else {
      this.info.active = null;
    }
    if (this.relaodinfo.reloadmenuData === false) {
      var spinnerRef = this.spinnerService.start();
    }
    let newStatus = '';
    if (this.globalStatus === 'Loading Truck') {
      newStatus = 'PickedUp';
    } else if (this.globalStatus === 'Food Acquired') {
      newStatus = 'OnRoute';
    } else if (this.globalStatus === 'Active') {
      newStatus = '';
    } else {
      newStatus = this.globalStatus;
    }

    this.pickupService
      .getFilteredDeliveries(
        this.pageNumber,
        this.pageSize,
        this.globalDelivery,
        newStatus,
        this.globalClient,
        this.globalPickupType,
        this.facilityGroupId,
        this.facilitatorId,
        this.globalRecipient,
        this.globalPickup,
        this.globalDriverService,
        this.info.active,
        this.sortColumn,
        this.finalFromDate.split('Z')[0],
        this.finalToDate.split('Z')[0],
        this.pendingFilterPickup,
        this.globalFlag
      )
      .subscribe(
        (data) => {
          if (this.relaodinfo.reloadmenuData === false) {
            this.spinnerService.stop(spinnerRef);
          }
          if (this.relaodinfo.reloadmenuData === true) {
            this.relaodinfo.reloadmenuData = false;
            this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
          }
          this.filterData = data;
          this.deliveryData = data;
          this.RecordCount = data.RecordCount;
          const pickups: PickUps[] = [];

          for (let i = 0; i <= data.Result.length - 1; i++) {
            const pickUnit: PickUps = data.Result[i];
            pickups.push(pickUnit);
          }
          pickups.map((val) => {
            if (val['ScheduleDate']) {
              val['ScheduleDate'] = this.dateTimeFormat.transform(val['ScheduleDate'], val.TimeZone);
            }
            val.OrderSubmitted = this.dateTimeFormat.transform(val.OrderSubmitted, val.TimeZone);
            val.PickupDate = this.dateTimeFormat.transform(val.PickupDate, val.TimeZone);
            val.DeliveryDate = this.dateTimeFormat.transform(val.DeliveryDate, val.TimeZone);
            val.abbrivation = this.timeZoneabbr.transform(val.TimeZone);
          });
          if (this.Checkbox === true) {
            this.checkUnits = 1;
            this.infoToggle.checkToggle = true;
            this._rdata.updateBasicInfotoggle(JSON.stringify(this.infoToggle));
          } else {
            this.infoToggle.checkToggle = false;
            this._rdata.updateBasicInfotoggle(JSON.stringify(this.infoToggle));
            this.checkUnits = 0;
          }
          this.dataSource = new MatTableDataSource(pickups);
          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          this.spinnerService.stop(spinnerRef);
        }
      );
  }
  filterClose() {
    this.filtermodal = false;
  }
  filterOpen() {
    this.filtermodal = true;
  }
  goToDetailsPage() {
    this.router.navigate(['pickupDetails/']);
  }
  goToRequestPickupPage() {
    this.router.navigate(['RequestPickup-menu']);
  }

  mapColumnName(columnName) {
    if (columnName === 'Title') {
      return 'RequestStatus';
    }
    if (columnName === 'requestId') {
      return 'DeliveryId';
    }
    if (columnName === 'Name') {
      return 'ClientName';
    }
    if (columnName === 'driver') {
      return 'DriverServices';
    }
    if (columnName === 'Recipient') {
      return 'Recipient';
    }
    if (columnName === 'Pickup') {
      return 'PickupType';
    }
    if (columnName === 'taxValue') {
      return 'TotalTax';
    }
    if (columnName === 'weight') {
      return 'TotalWeight';
    }
    if (columnName === 'CreatedOn') {
      return 'CreatedOn';
    }
    if (columnName === 'PickupDate') {
      return 'PickupDate';
    }
    if (columnName === 'DeliveryDate') {
      return 'DeliveryDate';
    }
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  sortTable(event) {
    var spinnerRef = this.spinnerService.start();
    this.sortColumn = this.mapColumnName(event.active);
    if (this.sortColumn != null) {
      if (event.direction === 'desc') {
        this.sortColumn = '-' + this.sortColumn;
      }
      this.info.sortColumn = this.sortColumn;
      this._rdata.updateBasicInfo1(JSON.stringify(this.info));

      let newStatus = '';
      if (this.globalStatus === 'Loading Truck') {
        newStatus = 'PickedUp';
      } else if (this.globalStatus === 'Food Acquired') {
        newStatus = 'OnRoute';
      } else if (this.globalStatus === 'Active') {
        newStatus = '';
      } else {
        newStatus = this.globalStatus;
      }
      if ((this.globalStatus === null, this.info.active === true)) {
        this.globalStatus = 'Active';
      }
      this.pickupService
        .getFilteredDeliveries(
          this.pageNumber,
          this.pageSize,
          this.globalDelivery,
          newStatus,
          this.globalClient,
          this.globalPickupType,
          this.facilityGroupId,
          this.facilitatorId,
          this.globalRecipient,
          this.globalPickup,
          this.globalDriverService,
          this.info.active,
          this.sortColumn,
          this.finalFromDate,
          this.finalToDate,
          this.pendingFilterPickup,
          this.globalFlag
        )
        .subscribe(
          (data) => {
            this.spinnerService.stop(spinnerRef);
            this.deliveryData = data;
            this.RecordCount = data.RecordCount;
            const pickups: PickUps[] = [];
            for (let i = 0; i <= data['Result'].length - 1; i++) {
              const pickUnit: PickUps = data['Result'][i];

              pickups.push(pickUnit);
            }
            pickups.map((val) => {
              if (val['ScheduleDate']) {
                val['ScheduleDate'] = this.dateTimeFormat.transform(val['ScheduleDate'], val.TimeZone);
              }
              val.OrderSubmitted = this.dateTimeFormat.transform(val.OrderSubmitted, val.TimeZone);
              val.PickupDate = this.dateTimeFormat.transform(val.PickupDate, val.TimeZone);
              val.DeliveryDate = this.dateTimeFormat.transform(val.DeliveryDate, val.TimeZone);
              val.abbrivation = this.timeZoneabbr.transform(val.TimeZone);
            });
            this.dataSource = new MatTableDataSource(pickups);
          },
          (error) => {
            this.state.isSpinnerVisible = false;
            // alert(JSON.stringify(error));
            this.spinnerService.stop(spinnerRef);
          }
        );
    }
  }
  checkValue() {
    if (this.Checkbox === true) {
      this.checkUnits = 1;
      this.infoToggle.checkToggle = true;
      this._rdata.updateBasicInfotoggle(JSON.stringify(this.infoToggle));
    } else {
      this.infoToggle.checkToggle = false;
      this._rdata.updateBasicInfotoggle(JSON.stringify(this.infoToggle));
      this.checkUnits = 0;
    }
  }
  addEditdate() {
    const dialogRef = this.dialog.open(AddeditDateComponent, {
      width: '800px',
      // height: '700px',
      panelClass: 'custom-datedialog-container',
      data: [
        {
          FromDate: this.FromDate,
          ToDate: this.ToDate,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.editDate = result;
      if (this.editDate) {
        if (this.editDate.From !== null && this.editDate.To !== null) {
          var spinnerRef = this.spinnerService.start();
          this.FromDate = moment(this.editDate.From).format('MMM DD YYYY');
          this.ToDate = moment(this.editDate.To).format('MMM DD YYYY');

          if (this.editDate !== undefined) {
            const selectedDate = new Date(this.editDate.From);
            const ToDate = new Date(this.editDate.To);

            ToDate.setHours(23);
            ToDate.setMinutes(59);
            ToDate.setSeconds(59);
            selectedDate.setHours(0);
            selectedDate.setMinutes(0);
            selectedDate.setSeconds(0);

            const selectedNDate = new Date(selectedDate);
            const selectedEndNDate = new Date(ToDate);
            const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
            const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];

            this.finalFromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
            this.finalToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
            this.info.finalFromDate = this.finalFromDate;
            this.info.finalToDate = this.finalToDate;
            this.info.pageNumber = 1;
            this.getPickUpCounts();

            this.pickupService
              .getFilteredDeliveries(
                1,
                this.pageSize,
                this.globalDelivery,
                this.globalStatus,
                this.globalClient,
                this.globalPickupType,
                this.facilityGroupId,
                this.facilitatorId,
                this.globalRecipient,
                this.globalPickup,
                this.globalDriverService,
                this.info.active,
                this.sortColumn,
                this.finalFromDate,
                this.finalToDate,
                this.pendingFilterPickup,
                this.globalFlag
              )
              .subscribe(
                (data) => {
                  this.spinnerService.stop(spinnerRef);
                  this.deliveryData = data;
                  this.RecordCount = data.RecordCount;
                  const pickups: PickUps[] = [];
                  for (let i = 0; i <= data['Result'].length - 1; i++) {
                    const pickUnit: PickUps = data['Result'][i];

                    pickups.push(pickUnit);
                  }
                  pickups.map((val) => {
                    if (val['ScheduleDate']) {
                      val['ScheduleDate'] = this.dateTimeFormat.transform(val['ScheduleDate'], val.TimeZone);
                    }
                    val.OrderSubmitted = this.dateTimeFormat.transform(val.OrderSubmitted, val.TimeZone);
                    val.PickupDate = this.dateTimeFormat.transform(val.PickupDate, val.TimeZone);
                    val.DeliveryDate = this.dateTimeFormat.transform(val.DeliveryDate, val.TimeZone);
                    val.abbrivation = this.timeZoneabbr.transform(val.TimeZone);
                  });
                  this.dataSource = new MatTableDataSource(pickups);
                  this.dataSource.sort = this.sort;
                  this.listdata = pickups;
                  this.addfilter = true;
                },
                (error) => {
                  this.state.isSpinnerVisible = false;
                  // alert(JSON.stringify(error));
                  this.spinnerService.stop(spinnerRef);
                }
              );
          } else {
            alert('Please select the Date');
          }
        }
      }
    });
  }
  pickupupdate(pickupdata) {
    const dialogRef = this.dialog.open(PickupeditableComponent, {
      width: '850px',
      height: '785px',
      panelClass: 'custom-Filterdialog-container',
      // disableClose: true,
      data: [{ pickupdata }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getPickups();
      }
    });
  }
  pickupRequest() {
    const dialogRef = this.dialog.open(PickupsRequestComponent, {
      width: '1400px',
      height: '87vh',
      panelClass: 'pickup-dialog-container',
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getPickups();
    });
  }
  handlePickupsPage = (element) => this.router.navigate(['pickupDetails/' + element.DeliveryId + '']);
  handleTrackingPage = (element) => this.router.navigate(['TrackRoutePickedUp/' + element.DeliveryId]);
  pickupsFilter() {
    if (this.facilitatorId) {
      this.globalClient = this.facilitatorId;
    }
    if (this.facilityGroupId) {
      this.globalClient = this.facilityGroupId;
    }
    const dialogRef = this.dialog.open(PickupsFilterComponent, {
      disableClose: true,
      width: '900px',
      // height: '600px',
      panelClass: 'custom-Filterdialog-container',
      data: {
        DeliveryId: this.globalDelivery,
        selectedstatus: this.globalStatus,
        selectedclient: this.globalClient,
        selectedRecipient: this.globalRecipient,
        selectedPickup: this.globalPickup,
        selDriverservice: this.globalDriverService,
      },
    });
    // this.getClientList();
    dialogRef.afterClosed().subscribe((result) => {
      this.filterData = result;
      if (this.filterData !== undefined) {
        this.spinnerRefilter = this.spinnerService.start();
        this.selectedStatus = this.filterData.Status;
        if (this.filterData.Status === 'Food Acquired') {
          this.filterData.Status = 'OnRoute';
        } else if (this.filterData.Status === 'Loading Truck') {
          this.filterData.Status = 'PickedUp';
        }
        this.selectedPickup = this.filterData.Pickup;
        this.filterCount = [];
        for (const item in this.filterData) {
          if (this.filterData[item]) {
            if (this.filterData[item] !== null) {
              if (this.filterData[item] !== undefined && item !== 'RecipientName' && item !== 'selectedRecipientName') {
                this.filterCount.push(this.filterData[item]);
              }
            }
          }
        }

        if (this.filterData.Status) {
          this.info.active = null;
        }
        this.selectedRecipientName = this.filterData.RecipientName;
        this.selectedPickupName = this.filterData.PickupName;
        this.globalStatus = this.filterData.Status;
        this.globalClient = this.filterData.Client;
        this.globalDelivery = this.filterData.Delivery;
        this.globalRecipient = this.filterData.Recipient;
        this.globalPickup = this.filterData.Pickup;
        this.globalDriverService = this.filterData.DriverService;
        this.info.status = this.globalStatus;
        this.info.Delivery = this.globalDelivery;
        this.info.Recipient = this.globalRecipient;
        this.info.Pickup = this.globalPickup;
        this.info.DriverService = this.globalDriverService;
        this.info.selectedRecipientName = this.selectedRecipientName;
        this.info.selectedPickupName = this.selectedPickupName;
        if (!this.pageNumber || !this.pageSize) {
          this.pageNumber = 1;
          this.pageSize = 10;
        }
        if (this.globalClient) {

          this.customerService.getClientData(this.globalClient).subscribe((data) => {
            this.selectedclientName = data.Result[0].Name;
            this.info.selectedclientName = this.selectedclientName;
            if (data.Result[0].ClientType === 'Facility Group') {
              this.facilityGroupId = data.Result[0].ClientId;
              this.globalClient = null;
              this.facilitatorId = null;
            } else if (data.Result[0].ClientType === 'Facilitator') {
              this.facilitatorId = data.Result[0].ClientId;
              this.globalClient = null;
              this.facilityGroupId = null;
            } else {
              this.facilityGroupId = null;
              this.facilitatorId = null;
            }
            this.info.client = this.globalClient;
            this.info.facilityGroupId = this.facilityGroupId;
            this.info.facilitatorId = this.facilitatorId;
            this._rdata.updateBasicInfo1(JSON.stringify(this.info));
            this.filteredDeliveries();
          });
        } else {
          this._rdata.updateBasicInfo1(JSON.stringify(this.info));
          this.filteredDeliveries();
        }
      }
    });
  }
  setFilteredValue(category, secondCat, value) {

    this.pageNumber = 1;
    this.info.pageNumber = 1;
    this[category] = value;
    this.info[secondCat] = value;
    if (category === 'globalRecipient' && secondCat == 'Recipient') {
      if (value === '') {
        this.selectedRecipientName = value;
        this.info.Recipient = value;
        this.info.selectedRecipientName = this.selectedRecipientName;
      } else {
        const recipient = this.RecipientList.find((recip) => recip.RecipientId === value);
        
        this.selectedRecipientName = recipient.Name;
        this.info.Recipient = value;
        this.info.selectedRecipientName = recipient.Name;
      }
    }
    if (category === 'globalReset' && secondCat === 'reset') {
      this.info.active = null;
      if (this.info.status === 'OnRoute') {
        this.selectedStatus = 'Food Acquired';
      } else if (this.info.status === 'PickedUp') {
        this.selectedStatus = 'Loading Truck';
      } else {
        this.selectedStatus = '';
      }
      this.info.active = null;
      this.globalStatus = '';
      this.globalClient = '';
      this.globalDelivery = '';
      this.globalRecipient = '';
      this.globalPickup = '';
      this.globalDriverService = '';
      this.facilitatorId = '';
      this.facilityGroupId = '';
      this.selectedRecipientName = '';
      this.selectedPickupName = '';
      this.selectedclientName = '';
      this.pageNumber = 1;
      this.pageSize = this.info.pageSize;
      this.sortColumn = '';
      this.globalClientName = '';
      this.filterCount = [];

      this.info.status = '';
      this.info.client = '';
      this.info.selectedclientName = '';
      this.info.Delivery = '';
      this.info.Recipient = '';
      this.info.DriverService = '';
      this.info.facilitatorId = '';
      this.info.facilityGroupId = '';
      this.info.selectedRecipientName = '';
      this.info.selectedPickupName = '';
      this.info.selectedclientName = '';
      this.info.Pickup = '';

      this.globalFlag = '';
      this.info.IsContaminated = false;

      this.checkFilters();
      this.pickupcountlist('');
      this._rdata.updateBasicInfo1(JSON.stringify(this.info));
      this.getPickups();
      return null;
    }

    if (category === 'globalClient' && value !== '') {
      const client = this.clientList.find((client) => client.ClientId === value);
      
      this.globalClientName = client.Name;
      var spinnerRef = this.spinnerService.start();
      return this.customerService.getClientData(this.globalClient).subscribe((data) => {
        this.selectedclientName = data.Result[0].Name;
        this.info.selectedclientName = this.selectedclientName;
        this.spinnerService.stop(spinnerRef);
        if (data.Result[0].ClientType === 'Facility Group') {
          this.facilityGroupId = data.Result[0].ClientId;

          this.globalClient = this.globalClientName;
          this.facilitatorId = null;
        } else if (data.Result[0].ClientType === 'Facilitator') {
          this.facilitatorId = data.Result[0].ClientId;
          this.globalClient = this.globalClientName;
          this.facilityGroupId = null;
        } else {
          this.facilityGroupId = null;
          this.facilitatorId = null;
        }
        this.info.client = this.globalClient;
        this.info.facilityGroupId = this.facilityGroupId;
        this.info.facilitatorId = this.facilitatorId;
        this.info.selectedclientName = this.selectedclientName;
        this.checkFilters();

        this._rdata.updateBasicInfo1(JSON.stringify(this.info));
        this.getPickups();
      });
    }
    if (category === 'globalClient' && value === '') {
      this.globalClient = '';
      this.facilitatorId = null;
      this.facilityGroupId = null;
      this.selectedclientName = '';
      this.info.selectedclientName = null;
      this.info.client = this.globalClient;
      this.info.facilityGroupId = this.facilityGroupId;
      this.info.facilitatorId = this.facilitatorId;
      this.info.selectedclientName = this.selectedclientName;
      this.checkFilters();

      this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    }
    if (category === 'globalDelivery' && value !== '') {
      this.pageNumber = 1;
      this.globalDelivery = value;
      this.info.Delivery = value;
    }
    if (category === 'globalDelivery' && value === '') {
      this.pageNumber = 1;
      this.globalDelivery = '';
      this.info.Delivery = '';
    }
    if (category === 'globalStatus' && value !== '') {
    }
    if (category === 'globalFlag' && value !== '') {
      this.pageNumber = 1;
      this.globalFlag = value;
      this.info.IsContaminated = value;
      this.checkFilters();
    }
    if (category === 'globalFlag' && value === '') {
      this.pageNumber = 1;
      this.globalFlag = '';
      this.info.IsContaminated = false;
    }
    
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this.checkFilters();

    this.getPickups();
    return null;
  }


  filteredDeliveries() {
    if (!this.pageSize) {
      this.pageSize = 10;
    }
    this.pageNumber = 1;
    this.info.pageNumber = 1;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this.pickupService
      .getFilteredDeliveries(
        this.pageNumber,
        this.pageSize,
        this.globalDelivery,
        this.globalStatus,
        this.globalClient,
        this.globalPickupType,
        this.facilityGroupId,
        this.facilitatorId,
        this.globalRecipient,
        this.globalPickup,
        this.globalDriverService,
        this.info.active,
        this.sortColumn,
        this.finalFromDate,
        this.finalToDate,
        this.pendingFilterPickup,
        this.globalFlag
      )
      .subscribe(
        (data) => {
          this.spinnerService.stop(this.spinnerRefilter);
          this.deliveryData = data;
          this.RecordCount = data.RecordCount;
          const pickups: PickUps[] = [];
          for (let i = 0; i <= data['Result'].length - 1; i++) {
            const pickUnit: PickUps = data['Result'][i];

            pickups.push(pickUnit);
          }
          pickups.map((val) => {
            if (val['ScheduleDate']) {
              val['ScheduleDate'] = this.dateTimeFormat.transform(val['ScheduleDate'], val.TimeZone);
            }
            val.OrderSubmitted = this.dateTimeFormat.transform(val.OrderSubmitted, val.TimeZone);
            val.PickupDate = this.dateTimeFormat.transform(val.PickupDate, val.TimeZone);
            val.DeliveryDate = this.dateTimeFormat.transform(val.DeliveryDate, val.TimeZone);
            val.abbrivation = this.timeZoneabbr.transform(val.TimeZone);
          });

          this.dataSource = new MatTableDataSource(pickups);
          this.dataSource.sort = this.sort;
          this.listdata = pickups;
          this.addfilter = true;
        },
        (error) => {
          this.state.isSpinnerVisible = false;
        }
      );
  }
  // pagination
  onChange(e) {
    e ? (this.pageNumber = e) : null;
    this.selectedPageSize = [10, 25, 50, 100];
    var spinnerRef = this.spinnerService.start();
    this.info.pageNumber = this.pageNumber;
    this.info.pageSize = this.pageSize;
    this.FromDate = this.FromDate;
    this.ToDate = this.ToDate;
    this.info.checkToggle = this.info.checkToggle;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));

    this.pickupService
      .getFilteredDeliveries(
        this.pageNumber,
        this.pageSize,
        this.globalDelivery,
        this.globalStatus,
        this.globalClient,
        this.globalPickupType,
        this.facilityGroupId,
        this.facilitatorId,
        this.globalRecipient,
        this.globalPickup,
        this.globalDriverService,
        this.info.active,
        this.sortColumn,

        this.finalFromDate,
        this.finalToDate,
        this.pendingFilterPickup,
        this.globalFlag

        // this.finalFromDate.split("Z")[0],
        // this.finalToDate.split("Z")[0],
        // this.pendingFilterPickup

      )
      .subscribe(
        (data) => {
          this.spinnerService.stop(spinnerRef);
          this.deliveryData = data;
          const pickups: PickUps[] = [];
          for (let i = 0; i <= data['Result'].length - 1; i++) {
            const pickUnit: PickUps = data['Result'][i];

            pickups.push(pickUnit);
          }
          pickups.map((val) => {
            if (val['ScheduleDate']) {
              val['ScheduleDate'] = this.dateTimeFormat.transform(val['ScheduleDate'], val.TimeZone);
            }
            val.OrderSubmitted = this.dateTimeFormat.transform(val.OrderSubmitted, val.TimeZone);
            val.PickupDate = this.dateTimeFormat.transform(val.PickupDate, val.TimeZone);
            val.DeliveryDate = this.dateTimeFormat.transform(val.DeliveryDate, val.TimeZone);
            val.abbrivation = this.timeZoneabbr.transform(val.TimeZone);
          });
          this.dataSource = new MatTableDataSource(pickups);
          this.RecordCount = this.deliveryData.RecordCount;
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          this.spinnerService.stop(spinnerRef);
        }
      );
  }

  checkFilters() {
    if (
      this.globalStatus ||
      this.globalDelivery ||
      this.globalClient ||
      this.globalDriverService ||
      this.selectedRecipientName ||
      this.globalPickup,
      this.globalFlag
    ) {
      this.showReset = true;
    } else {
      this.showReset = false;
    }
  }
  viewEditPickup(pickup) {
    let orderTime = moment.tz(moment(), pickup.TimeZone).format('MM/DD/YY hh:mm A');

    if (this.VisibleForAdminPickupUpdate && pickup.Status === 'Delivered')
      return true;
      
    if (this.VisibleForAdminPickupUpdate && pickup.Status === 'Scheduled') {
      let newTime = moment(pickup.OrderSubmitted).add(15, 'minutes').format('MM/DD/YY hh:mm A');
      if (moment(newTime).isAfter(orderTime))
        return true;
    }
  }
}
const ELEMENT_DATA: PickUps[] = [];
