import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-removeaccountdialog',
  templateUrl: './removeaccountdialog.component.html',
  styleUrls: ['./removeaccountdialog.component.scss']
})
export class RemoveaccountdialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemoveaccountdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
