import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-otp-pricing-delete-popup',
  templateUrl: './otp-pricing-delete-popup.component.html',
  styleUrls: ['./otp-pricing-delete-popup.component.scss'],
})
export class OtpPricingDeletePopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<OtpPricingDeletePopupComponent>,@Inject(MAT_DIALOG_DATA) public data: MatDialog) {}

  ngOnInit() {
  }

  cancelSub() {
    this.dialogRef.close('res');
  }
  close() {
    this.dialogRef.close();
  }
}
