import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment-timezone';
import { AppState } from 'src/app/shared/app-state';
import { Observable } from 'rxjs';
import { PickUps } from 'src/app/models/pickups.model';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { formatDate } from '@angular/common';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DatePostFormatPipe } from 'src/app/shared/pipes/dateformat.service';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { SuccesPopupComponent } from 'src/app/SAAS/regModule/succes-popup/succes-popup.component';
import { DateDispalyFormatPipeService } from 'src/app/shared/pipes/date-dispaly-format-pipe.service';
import { TimeZoneabbrService } from 'src/app/shared/pipes/time-zoneabbr.service';
import { timeFormatPipe } from 'src/app/shared/pipes/time.service';
import { TimezonedatepostService } from 'src/app/shared/pipes/timezonedatepost.service';
import { EstpostformatService } from 'src/app/estpostformat.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickupeditableComponent } from '../pickups/pickupeditable/pickupeditable.component';
import { EditShipmentDialogComponent } from '../edit-shipment-dialog/edit-shipment-dialog.component';
import { environment } from 'src/environments/environment';
import { Lightbox } from 'ngx-lightbox';

declare var $;

@Component({
  selector: 'app-pickup-details',
  templateUrl: './pickup-details.component.html',
  styleUrls: ['./pickup-details.component.scss'],
})
export class PickupDetailsComponent implements OnInit, OnDestroy {
  
  pickup: any;
  allDeliveryStatus: any = [];
  selectedEmoji: any;
  totalItemValue: number;
  totalWeight: number;
  totalWeightwithUnit: any;
  totalItemValueWithUnit: any;
  totalQuantity: number;
  pickupStatus: any;
  requestStatus: any;
  recipient: any;
  client: any;
  pdfURLLink: any;
  orderSubmitted: any;
  orderDelivered: any;
  dropOffLocations: any;
  dropOffLocationData: any;
  dropDownNames: any;
  val: any;
  selectedDropOfLocationObject: any;
  pickupDate: any;
  pickupTime: any;
  pickupStartDate: any;
  pickupStartTime: any;
  pickupEndDate: any;
  pickupEndTime: any;
  selectedStartTime: any;
  changedDate: any;
  selectedEndTime: any;
  changedEndDate: any;
  drivers: any;
  selectedDriver: any;
  user: any;
  roadies: boolean;
  requireField: boolean;
  valid = true;
  pickupSubmitted: any;
  istaxValueVisible: boolean;
  requestStatusinVisible: boolean;
  info: any;
  requireTimeField: boolean;
  ShippingID = 0;
  diffTimeless: boolean;
  selectedDate: any;
  selectedEndDate: any;
  roadiesPickup: boolean;
  roadiesPickupCancel: boolean;
  infoDate: any;
  ShippingStatusID: string;
  menus: any;
  cancelledStatus = false;
  VisibleForAdmin: boolean;
  saasclient = false;
  PickupAftererror = null;
  PickupBeforerror = null;
  selectedSGDriver: string;
  selectedPickupAfter: any;
  selectedPickupEnd: any;
  disbuttonStatus: boolean;
  error: any;
  strMsg: boolean;
  DriverMsg: boolean;
  VisibleForAFFG: boolean;
  showStartTime: string;
  showEndTime: string;
  showStartDate: any;
  showEndDate: any;
  nonprofitUpdate: boolean;
  nonprofitSelect: boolean;
  selectedRecipientNone = true;
  OrganicPickupType: boolean;
  foodPickupType: boolean;
  sepaterdCompost: boolean;
  combinedCompost: boolean;
  FoodSeparateTYpe: any;
  CombinedTotalWeight: any;
  OrganicWeight: any;
  PaperWeight: any;
  CompostWeight: any;
  totalCompostWeight: any;
  mainPickupType: any;
  totalSeperateValue: any;
  selectedDriverNone: boolean;
  editblePickup: boolean;
  pickuptypeID: any;
  futureValid: boolean;
  futureStartValid: boolean;
  modifiedDate: any;
  RecipientName: any;
  postmate: boolean;
  PostmateId: number;
  postmaterrors: any;
  selectedstatus: any;
  editmenusvalid: boolean;
  OTPPOstmate: boolean;
  selectedStartDateSplit: string;
  selectedEndDateSplit: any;
  // DeliveryendTime: any;
  // pickupshowStartTime: any;
  driverService: any;
  driverServicetitle: any;
  selectDriverservice: boolean;
  roadiesPos: boolean;
  timeZoneabbr: any;
  modifiedtime: string;
  featureinfo: any;
  RoadiePostmatesFeature: boolean;
  npalertdata: any;
  npshowalertdata: any;
  npalertdatatrue: boolean;
  npalertWithStatus: boolean = false;
  newDate: any;
  orderScheduled: any;
  deliveredTime: string;
  distanceMatrixNpCount: any;
  merck_client_id: any = environment.merck_id
  app_env: string = environment.app_env;
  ORRecieptUrl: any = '';
  isSHowEmptyBox: boolean = true;

  constructor(
    private router: Router,
    private pickupService: PickUpService,
    private spinnerService: SpinnerService,
    private adminService: AdmintoolsService,
    public dateFormat: dateFormatPipe,
    public estdatepostFormat: EstpostformatService,
    private state: AppState,
    public timeZoneabbrivation: TimeZoneabbrService,
    public postDateService: DatePostFormatPipe,
    public dateDispalyFormatPipe: DateDispalyFormatPipeService,
    public timeDispalyFormatPipe: timeFormatPipe,
    public dialog: MatDialog,
    private ClientFilterdata: ClientFilterService,
    private route: ActivatedRoute,
    private _rdata: modalDataService,
    public timezonedatepostService: TimezonedatepostService,
    private customerService: CustomerService,
    private lightbox: Lightbox
  ) { }

  pickups: PickUps;
  displayedColumns: string[] = ['itemName', 'itemCategory', 'itemCost', 'Weight', 'itemQuantity', 'totalWeight', 'totalCost'];
  displayedColumnsCompost: string[] = ['Title', 'Weight', 'Quantity', 'ContaminatedWeight', 'ContaminatedQuantity'];
  dataSource: MatTableDataSource<PickUps>;
  dataSourceCompost: MatTableDataSource<PickUps>;

  ngOnInit() {
    // this.RegenerateDonationRe();
    this.distanceMatrixNpCount = 0;
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'Roadie/Postmates' && element.EnableFeature === true) {
            this.RoadiePostmatesFeature = true;
          } else {
            this.RoadiePostmatesFeature = false;
          }
        }
      }
      // }
    });
    this.VisibleForAdmin = getFeature('DonationReceipts');
    this.VisibleForAFFG = getFeature('ViewDonationReceipts');
    this._rdata.basicInfo1Observe.subscribe((res) => {
      this.info = JSON.parse(res);
    });
    this.info.pickupsPage = true;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this._rdata.basicInfoDateObserve.subscribe((res) => {
      this.infoDate = JSON.parse(res);
    });
    this.infoDate.requestStatusinVis = true;
    this._rdata.updateBasicInfoDate(JSON.stringify(this.infoDate));
    this.istaxValueVisible = getFeature('taxValue');
    this.requestStatusinVisible = getFeature('requestStatus');
    if (this.state.user.User.Roles[0].Name === 'Admin' || this.state.user.User.Roles[0].Name === 'Driver' || this.state.user.User.Roles[0].Name === 'Billing Admin') {
      this.valid = false;
    }
    this.pickupDetails();
    this.customerService.getMenuTypes().subscribe((res) => {
      this.menus = res.Result;
    });
    // this.nonprofitDetails();
  }
  getSaSSDrivers() {
    this.adminService.getUsersSASSByDriver().subscribe(
      (data) => {
        this.drivers = data;
      },
      (error) => { }
    );
  }
  deliveryStatus() {
    this.pickupService.getAllDeliveryStatus().subscribe(
      (deliveryStatus) => {
        //this.allDeliveryStatus = deliveryStatus['Result'];
        deliveryStatus['Result'].forEach((element) => {
          if (element['Title'] === 'Pending') {
            this.allDeliveryStatus[0] = element;
          } else if (element['Title'] === 'UnClaimed') {
            this.allDeliveryStatus[1] = element;
          } else if (element['Title'] === 'Claimed') {
            this.allDeliveryStatus[2] = element;
          } else if (element['Title'] === 'PickedUp') {
            this.allDeliveryStatus[3] = element;
          } else if (element['Title'] === 'OnRoute') {
            this.allDeliveryStatus[4] = element;
          } else if (element['Title'] === 'Delivered') {
            this.allDeliveryStatus[5] = element;
          } else if (element['Title'] === 'Cancelled') {
            this.allDeliveryStatus[6] = element;
          } else if (element['Title'] === 'Scheduled') {
            this.allDeliveryStatus[7] = element;
          }
        });
        if (this.roadiesPos === true) {
          this.allDeliveryStatus = this.allDeliveryStatus.filter(
            (value) => value.Title !== 'PickedUp' && value.Title !== 'OnRoute' && value.Title !== 'Claimed' && value.Title !== 'Delivered'
          );
        }
        for (status in this.allDeliveryStatus) {
          if (this.allDeliveryStatus[status].Title === 'OnRoute') {
            if (this.OrganicPickupType === true) {
              this.allDeliveryStatus[status].Title = 'Acquired';
            } else {
              this.allDeliveryStatus[status].Title = 'Food Acquired';
            }
          } else if (this.allDeliveryStatus[status].Title === 'PickedUp') {
            this.allDeliveryStatus[status].Title = 'Loading Truck';
          }
          this.pickupStatus = this.allDeliveryStatus[status];
        }
        if (
          this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Goodr' ||
          this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'SAAS'
        ) {
          this.allDeliveryStatus = this.allDeliveryStatus.filter((value) => value.Title !== 'Pending');
        }
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  RegenerateDonationRe() {
    const payload = {
      DeliveryId: this.route.snapshot.params.id,
      Regenerate: true,
    };
    var spinnerRef = this.spinnerService.start();
    this.pickupService.RegenerateDonationReceipt(payload).subscribe((data) => {
      this.spinnerService.stop(spinnerRef);
      this.pdfURLLink = data.PDFUrl;
      this.pickup[0].DonationReceiptURL = data.PDFUrl;
      window.open(this.pdfURLLink, '_blank');
    });
  }

  OpenDonationRe(url) {
    window.open(url, '_blank');
  }

  nonprofitDetails(pickupState) {
    if (this.OrganicPickupType === true) {
      this.pickupService.getMrfClientList(encodeURIComponent(pickupState)).subscribe(
        (data) => {
          this.dropOffLocationData = data;
          if (this.dropOffLocationData.length > 0) {
            this.dropOffLocationData = this.dropOffLocationData.filter((value) => value.ApprovalStatus[0].Title === 'Active');
  
            for (let idx in this.dropOffLocationData) {
              // alert(this.dropOffLocationData[idx].ClientId + " " + this.pickup[0].PickupRequest[0].NonProfitReceiverId)
              if (this.dropOffLocationData[idx].ClientId == parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId)) {
                this.selectedDropOfLocationObject = this.dropOffLocationData[idx];
              }
            }
            if (this.selectedDropOfLocationObject == null) {
              this.customerService.getCustomerDetail(this.pickup[0].PickupRequest[0].NonProfitReceiverId).subscribe((data) => {
                let newRecord = data.Result[0];
                newRecord.DestinationName = data.Result[0].Name;
                this.dropOffLocationData.push(newRecord)
                this.selectedDropOfLocationObject = newRecord;
              })
            }
            let detail = this.dropOffLocationData;
            for (var status of detail) {
            }
          } else {
            this.selectedRecipientNone = true;
          }
        },
        (error) => {
          this.state.isSpinnerVisible = false;
        }
      );
    } else {
      this.pickupService.getRecipientFilterListByDistanceMatrix(this.pickup[0].PickupRequest[0].ClientId, false).subscribe(
        (data) => {
          this.distanceMatrixNpCount = data.Result.length
          if (data.Result.length !== 0) {
            this.dropOffLocationData = data.Result;
            if (this.dropOffLocationData.length > 0) {
              this.dropOffLocationData = this.dropOffLocationData.filter((value) => value.ApprovalStatus === 'Active');
              for (let idx in this.dropOffLocationData) {
                if (this.dropOffLocationData[idx].ClientId == parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId)) {
                  this.selectedDropOfLocationObject = this.dropOffLocationData[idx];
                }
              }
              if (this.selectedDropOfLocationObject == null) {
                this.customerService.getCustomerDetail(this.pickup[0].PickupRequest[0].NonProfitReceiverId).subscribe((data) => {
                  let newRecord = data.Result[0];
                  newRecord.DestinationName = data.Result[0].Name;
                  this.dropOffLocationData.push(newRecord)
                  this.selectedDropOfLocationObject = newRecord;
                })
              }
            }
          } else {
            this.distanceMatrixNpCount = 0;
            this.pickupService.getRecipientFilterList(encodeURIComponent(pickupState)).subscribe(
              (data) => {
                this.dropOffLocationData = data;
                if (this.dropOffLocationData.length > 0) {
                  this.dropOffLocationData = this.dropOffLocationData.filter((value) => value.ApprovalStatus[0].Title === 'Active');
                  for (let idx in this.dropOffLocationData) {
                    if (this.dropOffLocationData[idx].ClientId == parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId)) {
                      this.selectedDropOfLocationObject = this.dropOffLocationData[idx];
                    }
                  }
                } else {
                  this.selectedRecipientNone = true;
                }
              },
              (error) => {
                this.state.isSpinnerVisible = false;
              }
            );
          }
        },
        (error) => {
          this.state.isSpinnerVisible = false;
        }
      );
    }
  }
  npalertgetdata() {
    if (
      this.state.user.User.Roles[0].Name === 'Admin' &&
      (!this.pickup[0].PickupRequest[0].NonProfitReceiverId ||
        this.pickup[0].PickupRequest[0].NonProfitReceiverId === null ||
        this.pickup[0].PickupRequest[0].NonProfitReceiverId === undefined)
    ) {
      this.pickupService.getNPfaileddata(this.pickup[0].RequestId).subscribe((data) => {
        this.npalertdata = data.Result;
        if (this.npalertdata.length > 0) {
          if (this.npalertdata[0].Message) {
            this.npalertdatatrue = true;
          }
          this.npalertdata = this.npalertdata.filter((value) => value.Type === 'Auto NonProfit' && value.Type !== 'Schedule Roadie Request');
          if (this.npalertdata[0].Type === 'Auto NonProfit') {
            this.npshowalertdata = this.npalertdata[0].Message;
            if (this.npshowalertdata === 'There are no nonprofits in the same state as the client.') {
              this.npshowalertdata = 'Add a nonprofit in the same state as the pickup location, and submit the pickup again.';
            } else if (this.npshowalertdata === 'There are no nonprofits available during the requested day and time.') {
              this.npshowalertdata = 'Add a nonprofit that is open during the requested day and time, and submit the pickup again.';
            } else if (this.npshowalertdata === 'There are no nonprofits that serve the number of people required for this pickup capacity.') {
              this.npshowalertdata =
                'Add a nonprofit that can serve the number of people required for this pickup capacity, and submit the pickup again.';
            } else if (this.npshowalertdata === 'There are no nonprofits located within 25 miles of the client address.') {
              this.npshowalertdata = 'Add a nonprofit within 25 miles of the pickup location, and submit the pickup again.';
            } else {
              this.npshowalertdata = this.npalertdata[0].Message;
            }
          } else {
   
          }
        } else {
          this.npalertdatatrue = false;
        }
      });
    } else {
      this.npalertdatatrue = false;
    }
  }
  pickupDetails() {
    var spinnerRef = this.spinnerService.start();
    this.pickupService.getPickUpDetail(this.route.snapshot.params.id).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.user = this.state.user;
        this.pickup = data.Result;

        if (this.state.user.User.Roles[0].Name === 'Facility' && this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'SAAS') {
          this.getSaSSDrivers();
        }

        let detailImages = this.pickup[0].PickupRequest[0].PickupImages;
        if (detailImages && detailImages.length) {
          for (let i in detailImages) {
            if (detailImages[i].Type == 'receipt') {
              this.ORRecieptUrl = detailImages[i].ImageURL;
            }
          }
        }

        this.deliveryStatus();
        this.driverServicedata();
        this.npalertgetdata();
        this.timeZoneabbr = this.timeZoneabbrivation.transform(this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
        this.modifiedtime = this.timeDispalyFormatPipe.transform(this.pickup[0].ModifiedOn, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
        this.deliveredTime = this.timeDispalyFormatPipe.transform(this.pickup[0].DeliveryDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
        if (this.pickup[0].PickupRequest[0].PickupType.length > 0) {
          if (this.pickup[0].Status[0].Title === 'Cancelled' && this.pickup[0].PickupRequest[0].NonProfitReceiverId && this.pickup[0].ModifiedOn)
            this.npalertWithStatus = true;
          else this.npalertWithStatus = false;

          let date = new Date();
          let year = date.getFullYear();
          let month = date.getMonth();
          let day = date.getDate();
          let hr = date.getHours();
          let min = date.getMinutes();
          let newDate = moment.tz(new Date(year, month, day, hr, min + 5), this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          newDate.format();
          this.newDate = moment(newDate).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
          this.mainPickupType = this.pickup[0].PickupRequest[0].PickupType[0].Description;
          if (this.pickup[0].PickupRequest[0].PickupType[0].Title === 'Organic Recycling') {
            this.OrganicPickupType = true;
            if (this.pickup[0].PickupRequest[0].OrganicRecyclingPickupType.length > 0) {
              if (this.pickup[0].PickupRequest[0].OrganicRecyclingPickupType[0].Title === 'Separated') {
                this.sepaterdCompost = true;
                this.FoodSeparateTYpe = this.pickup[0].PickupRequest[0].PickupType[0].Title;
                if (this.pickup[0].PickupRequest[0].PickupRequest_OrganicRecycling.length > 0) {
                  this.dataSourceCompost = this.pickup[0].PickupRequest[0].PickupRequest_OrganicRecycling;

                  let totalcw = 0;
                  for (let i in this.dataSourceCompost) {
                    totalcw = totalcw+ this.dataSourceCompost[i].ContaminatedWeight;
                  }
                  this.pickup[0].TotalWeight = this.pickup[0].TotalWeight+(totalcw*16);

                  this.totalSeperateValue = this.pickup[0].PickupRequest[0].PickupRequest_OrganicRecycling.map((r) => r.Weight * r.Quantity).reduce(
                    (sum, current) => Number(sum) + Number(current)
                  );
                }
              } else {
                this.FoodSeparateTYpe = this.pickup[0].PickupRequest[0].PickupType[0].Title;
                this.combinedCompost = true;
                if (this.pickup[0].PickupRequest[0].PickupRequest_OrganicRecycling.length > 0) {
                  this.CombinedTotalWeight = this.pickup[0].PickupRequest[0].PickupRequest_OrganicRecycling[0].Weight;
                }
              }
            }
          } else {
            this.foodPickupType = true;
          }
        } else {
          this.foodPickupType = true;
        }
        this.nonprofitDetails(this.pickup[0].PickupRequest[0].Clients[0].State);
        if (this.pickup[0].Driver.length > 0) {
          this.selectedDriver = this.pickup[0].Driver[0].Id;
          if (
            this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
            this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
            this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined &&
            this.pickup[0].Driver[0].Id != null
          ) {
            this.valid = false;
          }
        }

        if (this.pickup[0].ModifiedOn) {
          this.modifiedDate = moment(this.pickup[0].ModifiedOn.split('T')[0]).format('MM-DD-YYYY');
          this.pickup[0].modifiedDate = this.pickup[0].ModifiedOn.split('T')[0];
          this.pickup[0].modifiedTime = this.pickup[0].ModifiedOn.split('T')[1];
        } else {
          this.pickup[0].modifiedDate = null;
          this.pickup[0].modifiedTime = null;
        }
        //set-date
        if (this.pickup[0].PickupAfter !== '' && this.pickup[0].PickupAfter !== null) {
        
          let finalselectedstaredDateSplit;
          finalselectedstaredDateSplit = this.dateDispalyFormatPipe.transform(
            this.pickup[0].PickupAfter,
            this.pickup[0].PickupRequest[0].Clients[0].TimeZone
          );
          this.selectedStartDateSplit = this.dateDispalyFormatPipe.transform(
            this.pickup[0].PickupAfter,
            this.pickup[0].PickupRequest[0].Clients[0].TimeZone
          );
          this.pickupStartTime = finalselectedstaredDateSplit.split('T')[1];
          this.showStartTime = moment(finalselectedstaredDateSplit.split('T')[1], ['HH.mm']).format('hh:mm a');
          this.pickupStartDate = new Date(finalselectedstaredDateSplit);
          this.showStartDate = new Date(finalselectedstaredDateSplit);

        } else {
          this.pickupStartDate = new Date();
  
          const startDate = new Date(
            this.timezonedatepostService.transform(this.pickupStartDate.toISOString(), this.pickup[0].PickupRequest[0].Clients[0].TimeZone)
          );
          this.pickupStartDate =
            moment(startDate)
              // .add(1, 'hours')
              .add(5, 'minutes')
              .format()
              .toString()
              .split('T')[0] +
            'T' +
            moment(startDate)
              // .add(1, 'hours')
              .add(5, 'minutes')
              .format()
              .toString()
              .split('T')[1]
              .split('+')[0]
              .split('-')[0];
          this.pickupStartTime = this.pickupStartDate.split('T')[1].split('+')[0].split('-')[0];
          this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format('hh:mm a');
          this.showStartDate = new Date(this.pickupStartDate);
        }
        if (this.pickup[0].PickupBefore !== '' && this.pickup[0].PickupBefore !== null) {
          let finalselectedendDateSplit;
          finalselectedendDateSplit = this.dateDispalyFormatPipe.transform(
            this.pickup[0].PickupBefore,
            this.pickup[0].PickupRequest[0].Clients[0].TimeZone
          );
          this.selectedEndDateSplit = this.dateDispalyFormatPipe.transform(
            this.pickup[0].PickupBefore,
            this.pickup[0].PickupRequest[0].Clients[0].TimeZone
          );
          this.pickupEndTime = finalselectedendDateSplit.split('T')[1];
          this.showEndTime = moment(finalselectedendDateSplit.split('T')[1], ['HH.mm']).format('hh:mm a');
          this.pickupEndDate = new Date(finalselectedendDateSplit);
          this.showEndDate = new Date(finalselectedendDateSplit);

        } else {
          this.pickupEndDate = new Date(this.pickupStartDate);
          this.pickupEndDate =
            moment(this.pickupEndDate).add(2, 'hours').format().toString().split('T')[0] +
            'T' +
            moment(this.pickupEndDate).add(2, 'hours').format().toString().split('T')[1].split('+')[0].split('-')[0];
          this.pickupEndTime = new Date(this.pickupEndDate).getHours() + ':' + new Date(this.pickupEndDate).getMinutes() + ':' + '00';
          this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
          this.showEndDate = new Date(this.pickupEndDate);
          if (this.pickup[0].PickupRequest[0].Clients[0].DriverServices[0].Title === 'Roadie/Postmates') {
            this.updateDateTimedefault(this.pickupStartDate, this.pickupEndDate);
          }
          if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Roadie') {
            this.updateDateTimedefault(this.pickupStartDate, this.pickupEndDate);
          }
        }
        // validation for status
        if (this.pickup[0].PickupRequest[0].DriverServices.length > 0) {
          if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Goodr') {
            this.selectedSGDriver = 'DriverState=' + this.pickup[0].PickupRequest[0].Clients[0].State;
          } else if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'SAAS') {
            this.selectedSGDriver = 'sAASDriver=true&pagingparametermodel.nonPaginatedUsers=true';
          } else if (
            this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Goodr' ||
            this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'SAAS'
          ) {
            this.saasclient = true;
            if (this.state.user.User.Roles[0].Name === 'Driver') {
              if (
                this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
                this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
                this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined
              ) {
                this.valid = false;
              }
            } else if (
              this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
              this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
              this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined &&
              this.pickup[0].Driver.length > 0
            ) {
              this.valid = false;
            }
          } else {

          }

          // validation for status
          if (this.pickup[0].PickupRequest[0].DriverServices.length > 0) {
            if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Goodr') {
              this.selectedSGDriver = 'DriverState=' + this.pickup[0].PickupRequest[0].Clients[0].State;
            } else if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'SAAS') {
              this.selectedSGDriver = 'sAASDriver=true&pagingparametermodel.nonPaginatedUsers=true';
            } else if (
              this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Goodr' ||
              this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'SAAS'
            ) {
              this.saasclient = true;
              if (this.state.user.User.Roles[0].Name === 'Driver') {
                if (
                  this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
                  this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
                  this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined
                ) {
                  this.valid = false;
                }
              } else if (
                this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
                this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
                this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined &&
                this.pickup[0].Driver.length > 0
              ) {
                this.valid = false;
              }
            }
            // RoadiePostmatesFeature   true
            else if (
              this.pickup[0].PickupRequest[0].Clients[0].DriverServices[0].Title === 'Roadie/Postmates' &&
              this.pickup[0].PickupRequest[0].DriverServices.length === 0
            ) {
              this.selectedstatus = this.pickup[0].Status[0].Title;
              if (this.pickup[0].Status[0].Title === 'Pending') {
                this.cancelledStatus = true;
              } else {
                this.cancelledStatus = false;
              }
              this.roadiesPos = true;
              this.roadies = true;
            } else if (
              this.pickup[0].PickupRequest[0].Clients[0].DriverServices[0].Title === 'Roadie/Postmates' &&
              this.pickup[0].PickupRequest[0].DriverServices.length > 0
            ) {
              this.roadiesPos = true;
              if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Roadie') {
                if (this.postmate === true) {
                  if (this.selectedstatus === 'OnRoute') {
                    if (this.OrganicPickupType === true) {
                      this.selectedstatus = 'Acquired';
                    } else {
                      this.selectedstatus = 'Food Acquired';
                    }
                  } else if (this.selectedstatus === 'PickedUp') {
                    this.selectedstatus = 'Loading Truck';
                  }
                }
                this.roadies = true;
                this.roadiesPos = true;
                if (this.pickup[0].Status[0].Title === 'Pending') {
                  this.roadiesPickup = false;
                  this.cancelledStatus = true;
                } else if (this.pickup[0].Status[0].Title === 'UnClaimed') {
                  this.roadiesPickup = false;
                  this.cancelledStatus = false;
                } else if (this.pickup[0].Status[0].Title === 'Cancelled') {
                  this.roadiesPickup = true;
                  this.cancelledStatus = false;
                  this.roadiesPickupCancel = true;
                } else {
                  this.roadiesPickup = true;
                }
              } else {
                this.postmate = true;
                this.roadies = false;
                if (this.postmate === true) {
                  if (this.selectedstatus === 'OnRoute') {
                    if (this.OrganicPickupType === true) {
                      this.selectedstatus = 'Acquired';
                    } else {
                      this.selectedstatus = 'Food Acquired';
                    }
                  } else if (this.selectedstatus === 'PickedUp') {
                    this.selectedstatus = 'Loading Truck';
                  }
                }
                if (this.pickup[0].Status[0].Title === 'Pending') {
                  this.cancelledStatus = true;
                } else if (this.pickup[0].Status[0].Title === 'UnClaimed') {
                  this.editblePickup = true;
                  this.cancelledStatus = false;
                } else if (this.pickup[0].Status[0].Title === 'Cancelled') {
                  this.editblePickup = false;
                  this.cancelledStatus = false;
                } else {
                  this.editblePickup = true;
                  this.cancelledStatus = false;
                }
              }
              this.selectedstatus = this.pickup[0].Status[0].Title;
            }

            // individually postmamte or roadies toogle scenrio onn
            else if (
              this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Roadie' ||
              this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Postmates'
            ) {
              this.roadiesPos = true;
              if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Roadie') {
                if (this.postmate === true) {
                  if (this.selectedstatus === 'OnRoute') {
                    if (this.OrganicPickupType === true) {
                      this.selectedstatus = 'Acquired';
                    } else {
                      this.selectedstatus = 'Food Acquired';
                    }
                  } else if (this.selectedstatus === 'PickedUp') {
                    this.selectedstatus = 'Loading Truck';
                  }
                }
                this.roadies = true;
                if (this.pickup[0].Status[0].Title === 'Pending') {
                  this.roadiesPickup = false;
                  this.cancelledStatus = true;
                } else if (this.pickup[0].Status[0].Title === 'UnClaimed') {
                  this.roadiesPickup = false;
                  this.cancelledStatus = false;
                } else if (this.pickup[0].Status[0].Title === 'Cancelled') {
                  this.roadiesPickup = true;
                  this.cancelledStatus = false;
                  this.roadiesPickupCancel = true;
                } else {
                  this.roadiesPickup = true;
                }
              } else {
                this.postmate = true;
                this.roadies = false;
                if (this.postmate === true) {
                  if (this.selectedstatus === 'OnRoute') {
                    if (this.OrganicPickupType === true) {
                      this.selectedstatus = 'Acquired';
                    } else {
                      this.selectedstatus = 'Food Acquired';
                    }
                  } else if (this.selectedstatus === 'PickedUp') {
                    this.selectedstatus = 'Loading Truck';
                  }
                }
                if (this.pickup[0].Status[0].Title === 'Pending') {
                  this.cancelledStatus = true;
                } else if (this.pickup[0].Status[0].Title === 'UnClaimed') {
                  this.editblePickup = true;
                  this.cancelledStatus = false;
                } else if (this.pickup[0].Status[0].Title === 'Cancelled') {
                  this.editblePickup = false;
                  this.cancelledStatus = false;
                } else {
                  this.editblePickup = true;
                  this.cancelledStatus = false;
                }
              }
              this.selectedstatus = this.pickup[0].Status[0].Title;

            } else {
              if (this.pickup[0].PickupRequest[0].PickupType[0].Title === 'One Time Pickup') {
                this.OTPPOstmate = true;
              }
            }
          }
          if (this.state.user.User.Roles[0].Name !== 'Facility' || this.pickup[0].PickupRequest[0].DriverServices[0].Title !== 'SAAS') {
            this.adminService.getUsersByDriver(this.selectedSGDriver).subscribe((data) => {
              this.drivers = data.Result.filter((value) => value.ApprovalStatus[0].Title === 'Active');
              if (this.drivers.length > 0) {
              } else {
                this.selectedDriverNone = true;
              }
            });
          }
          this.requestStatus = this.pickup[0].Status[0].Title;
          this.recipient = this.pickup[0].PickupRequest[0].Recipient;
          this.recipientAddMTN();

          this.client = this.pickup[0].PickupRequest[0].Clients[0].Name;
          if (this.pickup[0].PickupDate) {
            this.pickupSubmitted = this.dateFormat.transform(this.pickup[0].PickupDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          }
          if (this.pickup[0].DeliveryDate) {
            this.orderDelivered = this.dateFormat.transform(this.pickup[0].DeliveryDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          }
          if (this.pickup[0].CreatedOn) {
            this.orderSubmitted = this.dateFormat.transform(this.pickup[0].CreatedOn, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          }
          if (this.pickup[0].PickupRequest[0].ScheduleDate) {
            this.orderScheduled = this.dateFormat.transform(
              this.pickup[0].PickupRequest[0].ScheduleDate,
              this.pickup[0].PickupRequest[0].Clients[0].TimeZone
            );
          }
        }

        this.requestStatus = this.pickup[0].Status[0].Title;
        this.recipient = this.pickup[0].PickupRequest[0].Recipient;
        this.recipientAddMTN();

        this.client = this.pickup[0].PickupRequest[0].Clients[0].Name;
        if (this.pickup[0].PickupDate) {
          this.pickupSubmitted = this.dateFormat.transform(this.pickup[0].PickupDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
        }
        if (this.pickup[0].DeliveryDate) {
          this.orderDelivered = this.dateFormat.transform(this.pickup[0].DeliveryDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
        }
        if (this.pickup[0].CreatedOn) {
          this.orderSubmitted = this.dateFormat.transform(this.pickup[0].CreatedOn, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
        }
        if (this.pickup[0].PickupRequest[0].ScheduleDate) {
          this.orderScheduled = this.dateFormat.transform(
            this.pickup[0].PickupRequest[0].ScheduleDate,
            this.pickup[0].PickupRequest[0].Clients[0].TimeZone
          );
        }
        const pickups: PickUps[] = [];
        this.totalItemValue = 0;
        this.totalWeight = 0;
        this.totalQuantity = 0;

        for (let i = 0; i <= this.pickup[0].PickupRequest[0]['PickupRequest_Product'].length - 1; i++) {
          const pickUnit: PickUps = this.pickup[0].PickupRequest[0]['PickupRequest_Product'][i];

          let pickupDetails = this.pickup[0].PickupRequest[0]['PickupRequest_Product'][i];
          //total value calculation
          const itemValue = pickupDetails.Quantity * pickupDetails.Cost;
          this.totalItemValue = this.totalItemValue + itemValue;
          this.totalItemValueWithUnit = this.totalItemValue / 2;
          //weight-calculation
          const itemWeightValue = pickupDetails.Quantity * pickupDetails.Weight;
          this.totalWeight = this.totalWeight + itemWeightValue;
          this.totalWeightwithUnit = this.totalWeight;

          //weight-calculation
          this.totalQuantity = this.totalQuantity + pickupDetails.Quantity;

          pickups.push(pickUnit);
        }
        this.dataSource = new MatTableDataSource(pickups);

        setTimeout(()=>{
          if ($('.visible-box-custom').length > 2) {
            this.isSHowEmptyBox = false;
          }
        }, 100);
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        this.spinnerService.stop(spinnerRef);
      }
    );
  }
  driverServicedata() {
    this.customerService.getPickupTypeList().subscribe((data) => {
      this.driverService = data.Result;
      if (this.pickup[0].PickupRequest[0].DriverServices.length > 0) {
        this.driverServicetitle = this.pickup[0].PickupRequest[0].DriverServices[0].Title;
      }
      // if (this.SAASFeature === false) {
      this.driverService = this.driverService.filter((value) => value.Title === 'Roadie' || value.Title === 'Postmates');
      //
    });
  }

  recipientAddMTN(){
    if(this.pickup[0] && this.pickup[0].PickupRequest[0] &&  parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId) == 393){
      this.recipient = 'MTN Organizations INC';
    }
  }

  UpdateDropLocationnon() {
    if (
      this.pickup[0].ShippingID &&
      this.pickup[0].ShippingID !== null &&
      this.pickup[0].ShippingID !== undefined &&
      this.pickup[0].Status[0].Title !== 'Cancelled' &&
      this.pickup[0].Status[0].Title === 'UnClaimed' &&
      (this.pickup[0].PickupRequest[0].Clients[0].DriverServices[0].Title === 'Roadie/Postmates' ||
        this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Roadie')
    ) {
      this.npalertdatatrue = false;
      this.UpdatePickUpnpRoadiesStatus();
      this.nonprofitSelect = true;
    }
    else {
      this.npalertdatatrue = false;
      this.UpdateDropLocation();
    }
  }
  UpdateDropLocation() {
    if (this.pickup[0].PickupRequest[0].NonProfitReceiverId === 'None') {
      this.pickup[0].PickupRequest[0].NonProfitReceiverId = null;
      this.selectedDropOfLocationObject = null;
      this.recipient = null;
    } else {
      for (let idx in this.dropOffLocationData) {
        if (this.dropOffLocationData[idx].ClientId == parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId)) {
          this.selectedDropOfLocationObject = this.dropOffLocationData[idx];
          if (this.selectedDropOfLocationObject.Name) this.RecipientName = this.selectedDropOfLocationObject.Name;
          if (this.selectedDropOfLocationObject.DestinationName) this.RecipientName = this.selectedDropOfLocationObject.DestinationName;
        }
      }
    }
    if (this.pickup[0].PickupRequest[0].PickupType.length > 0) {
      this.pickuptypeID = this.pickup[0].PickupRequest[0].PickupType[0].ID;
    } else {
      this.pickuptypeID = null;
    }
    let driverId;
    if (this.pickup[0].PickupRequest[0].DriverServices.length > 0) {
      driverId = this.pickup[0].PickupRequest[0].DriverServices[0].ID;
    } else {
      driverId = null;
    }
    let CreatedOn;
    if (this.pickup[0].PickupRequest[0].CreatedOn) {
      CreatedOn = this.estdatepostFormat.transform(this.pickup[0].PickupRequest[0].CreatedOn);
    }
    let payload = {
      ClientId: this.pickup[0].PickupRequest[0].ClientId,
      CreatedBy: this.pickup[0].PickupRequest[0].CreatedBy,
      CreatedOn: CreatedOn,
      Date: this.pickup[0].PickupRequest[0].Date,
      IsProcessed: this.pickup[0].PickupRequest[0].IsProcessed,
      ModifiedBy: this.pickup[0].PickupRequest[0].ModifiedBy,
      ModifiedOn: this.pickup[0].PickupRequest[0].ModifiedOn,
      NonProfitReceiverId: parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId),
      PickupRequest_Product: this.pickup[0].PickupRequest[0].PickupRequest_Product,
      Recipient: this.RecipientName,
      RequestId: this.pickup[0].PickupRequest[0].RequestId,
      DonationReceiptURL: this.pickup[0].DonationReceiptURL,
      DonationReceiver: this.pickup[0].DonationReceiver,
      SchedueleDate: this.pickup[0].PickupRequest[0].SchedueleDate,
      WP_Request_Id: this.pickup[0].PickupRequest[0].WP_Request_Id,
      DriverServiceId: driverId,
      PickupTypeId: this.pickuptypeID,
      OrganicRecyclingPickupTypeId: this.pickup[0].PickupRequest[0].OrganicRecyclingPickupTypeId,
      ScheduleDate: this.pickup[0].PickupRequest[0].ScheduleDate,
      SchedulePickup: true,
    };
    this.pickupService.updatePickupRequest(payload).subscribe((pickupRequest) => {
      
      if (this.selectedDropOfLocationObject) {
        if (this.selectedDropOfLocationObject.Name) {
          this.recipient = this.selectedDropOfLocationObject.Name;
        }
        if (this.selectedDropOfLocationObject.DestinationName) {
          this.recipient = this.selectedDropOfLocationObject.DestinationName;
        }

        this.recipientAddMTN();
      }
      this.recipientAddMTN();
      
      if (this.state.user.User.Roles[0].Name === 'Driver') {
        if (
          this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
          this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
          this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined
        ) {
          this.valid = false;
        }
      } else if (this.roadies) {
        if (this.pickup[0].PickupAfter !== null) {
          if (this.pickup[0].PickupBefore !== null) {
            this.valid = false;
          }
        }
      } else {
        if (this.selectedDriver) {
          this.valid = false;
        }
      }
    });
  }
  goToPickupPage() {
    this.router.navigate(['Pickups']);
  }
  goToTrackPickupPage() {
    this.router.navigate(['TrackRoutePickedUp']);
  }
  gotoNormalStatus() {
    if (this.state.user.User.Roles[0].Name === 'Admin') {
      for (const status of this.allDeliveryStatus) {
        if (status.ID == this.pickup[0].Status[0].ID) {
          if (status.Title === 'Cancelled') {
            this.UpdatePickUpStatus();
          } else {
            this.goTovalid();
          }
        }
      }
    } else {
      this.goTovalid();
    }
  }
  goTovalid() {
    if (this.state.user.User.Roles[0].Name === 'Driver') {
      if (
        this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
        this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
        this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined
      ) {
        this.UpdatePickUpStatus();
      } else {
        alert('Select a Dropoff Location.');
      }
    } else if (this.pickup[0].PickupRequest[0].NonProfitReceiverId === null) {
      alert('Select a Dropoff Location.');
    } else if (this.selectedDriver === undefined) {
      this.DriverMsg = true;
      setTimeout(() => {
        this.DriverMsg = false;
      }, 4000);
    } else {
      this.UpdatePickUpStatus();
    }
  }
  goTopostmatevalid() {
    if (this.pickup[0].TotalWeight) {
      if (
        this.pickup[0].PickupRequest[0].NonProfitReceiverId === null ||
        this.pickup[0].PickupRequest[0].NonProfitReceiverId === undefined ||
        !this.pickup[0].PickupRequest[0].NonProfitReceiverId
      ) {
        let strMsg = 'Select a Dropoff Location.';
        alert(strMsg);

        // alert(strMsg);
        setTimeout(() => {
          this.pickup[0].Status[0].Title = 'Pending';
        });
        for (const status of this.allDeliveryStatus) {
          if (status.Title == 'Pending') {
            this.pickup[0].Status[0].ID = Object.assign(status.ID);
          }
        }
      } else {
        if (
          this.pickup[0].Status[0].Title === 'Claimed' ||
          this.pickup[0].Status[0].Title === 'PickedUp' ||
          this.pickup[0].Status[0].Title === 'Delivered' ||
          this.pickup[0].Status[0].Title === 'OnRoute' ||
          this.pickup[0].Status[0].Title === 'Delivered'
        ) {
        } else {
          this.UpdatePickUppostmateStatus();
          // this.disbuttonStatus = true;
          this.valid = false;
        }
      }
    } else {
      for (const status of this.allDeliveryStatus) {
        if (status.Title == 'Pending') {
          this.pickup[0].Status[0].ID = Object.assign(status.ID);
        }
      }
      this.editmenusvalid = true;
      setTimeout(() => {
        this.editmenusvalid = false;
      }, 3000);
    }
  }
  goTodriver() {
    if (this.driverServicetitle === 'Roadie') {
      this.roadiesPos = true;
    } else {
      this.roadiesPos = false;
    }
  }
  goToroadiesvalid() {
    if (this.pickup[0].PickupRequest[0].NonProfitReceiverId === null) {
      let strMsg = 'Select a Dropoff Location.';
      alert(strMsg);
    } else {
      if (this.pickup[0].PickupRequest[0].Clients[0].DriverServices[0].Title === 'Roadie/Postmates') {
        this.npalertdatatrue = false;
        if (this.driverServicetitle !== undefined && this.driverServicetitle !== null && this.driverServicetitle) {
          if (this.pickup[0].PickupRequest[0].DriverServices.length > 0) {
            if (this.driverServicetitle === 'Postmates') {
              this.postmatenonOTP();
            } else {
              this.UpdatePickUpRoadiesStatus();
            }
          } else {
            let DriverServicedata;
            for (const status of this.driverService) {
              if (this.driverServicetitle === status.Title) {
                DriverServicedata = status.ID;
              }
            }
            if (this.pickup[0].PickupRequest[0].PickupType.length > 0) {
              this.pickuptypeID = this.pickup[0].PickupRequest[0].PickupType[0].ID;
            } else {
              this.pickuptypeID = null;
            }
            let CreatedOn;
            if (this.pickup[0].PickupRequest[0].CreatedOn) {
              CreatedOn = this.estdatepostFormat.transform(this.pickup[0].PickupRequest[0].CreatedOn);
            }
            let payload = {
              ClientId: this.pickup[0].PickupRequest[0].ClientId,
              CreatedBy: this.pickup[0].PickupRequest[0].CreatedBy,
              CreatedOn: CreatedOn,
              Date: this.pickup[0].PickupRequest[0].Date,
              IsProcessed: this.pickup[0].PickupRequest[0].IsProcessed,
              ModifiedBy: this.pickup[0].PickupRequest[0].ModifiedBy,
              ModifiedOn: this.pickup[0].PickupRequest[0].ModifiedOn,
              NonProfitReceiverId: parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId),
              PickupRequest_Product: this.pickup[0].PickupRequest[0].PickupRequest_Product,
              Recipient: this.RecipientName,
              RequestId: this.pickup[0].PickupRequest[0].RequestId,
              DonationReceiptURL: this.pickup[0].DonationReceiptURL,
              DonationReceiver: this.pickup[0].DonationReceiver,
              SchedueleDate: this.pickup[0].PickupRequest[0].SchedueleDate,
              WP_Request_Id: this.pickup[0].PickupRequest[0].WP_Request_Id,
              DriverServiceId: DriverServicedata,
              PickupTypeId: this.pickuptypeID,
              OrganicRecyclingPickupTypeId: this.pickup[0].PickupRequest[0].OrganicRecyclingPickupTypeId,
            };
            
            this.pickupService.updatePickupRequest(payload).subscribe((pickupRequest) => {
              if (this.driverServicetitle === 'Postmates') {
                this.postmatenonOTP();
              } else {
                this.UpdatePickUpRoadiesStatus();
              }
            });
          }
        } else {
          this.selectDriverservice = true;
          setTimeout(() => {
            this.selectDriverservice = false;
          }, 3000);
        }
      } else {
        this.npalertdatatrue = false;
        if (this.pickup[0].PickupRequest[0].DriverServices.length > 0) {
          if (this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Postmates') {
            this.postmatenonOTP();
          } else {
            this.UpdatePickUpRoadiesStatus();
          }
        }
      }
      // if(this.driverServicetitle === 'Postamtes'){
      // this.goTopostmatevalid();
      // } else {
      //   this.UpdatePickUpRoadiesStatus();
      // }
      // this.disbuttonStatus = true;
      // this.valid = false;
    }
  }
  UpdatePickUpStatus() {
    this.state.isSpinnerVisible = true;
    var spinnerRef = this.spinnerService.start();
    this.npalertdatatrue = false;
    for (let deliveryIdx in this.allDeliveryStatus) {
      if (this.allDeliveryStatus[deliveryIdx].ID == this.pickup[0].Status[0].ID) {
        this.pickup[0].Status[0] = Object.assign({}, this.allDeliveryStatus[deliveryIdx]);
        this.requestStatus = this.pickup[0].Status[0].Title;

        // this.UpdatePickUp();

        let payload = Object.assign({}, this.pickup[0]);
        if (this.state.user.User.Roles[0].Name === 'Driver') {
          this.selectedDriver = this.state.user.User.Id;
        }
        if (payload.DispatchNotification) {
          if (payload.DispatchNotification.length > 0) {
            payload.DispatchNotificationId = payload.DispatchNotification[0].DispatchNotificationId;
          }
        }
        if (this.pickup[0].DeliveryDate) {
          this.pickup[0].DeliveryDate = this.estdatepostFormat.transform(this.pickup[0].DeliveryDate);
        }
        let PickupDate;
        if (this.pickup[0].PickupDate) {
          PickupDate = this.estdatepostFormat.transform(this.pickup[0].PickupDate);
        }
        let CreatedOn;
        if (this.pickup[0].CreatedOn) {
          CreatedOn = this.estdatepostFormat.transform(this.pickup[0].CreatedOn);
        }
        payload.CreatedOn = CreatedOn;
        payload.DeliveryDate = this.pickup[0].DeliveryDate;
        payload.PickupDate = PickupDate;
        payload.StatusId = payload.Status[0].ID;
        payload.Status = [];
        payload.PickupAfter = null;
        payload.PickupBefore = null;
        payload.Driver = [];
        payload.DriverId = this.selectedDriver;
        payload.DonationReceiptURL = this.pickup[0].DonationReceiptURL;
        payload.RecipientId = this.pickup[0].PickupRequest[0].NonProfitReceiverId,
          (payload.DonationReceiver = this.pickup[0].DonationReceiver),
          this.pickupService.updateDelivery(payload).subscribe(
            (data) => {
              this.pickup = data.Result;
              if (this.pickup[0].PickupDate) {
                this.pickupSubmitted = this.dateFormat.transform(this.pickup[0].PickupDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
              }
              if (this.pickup[0].DeliveryDate) {
                this.orderDelivered = this.dateFormat.transform(this.pickup[0].DeliveryDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
              }
              // if (this.pickup[0].CreatedOn) {
              //   this.orderSubmitted = this.dateFormat.transform(
              //     this.pickup[0].CreatedOn,
              //     this.pickup[0].PickupRequest[0].Clients[0].TimeZone
              //   );
              // }
              this.modifiedtime = this.timeDispalyFormatPipe.transform(
                this.pickup[0].ModifiedOn,
                this.pickup[0].PickupRequest[0].Clients[0].TimeZone
              );
              this.deliveredTime = this.timeDispalyFormatPipe.transform(this.pickup[0].DeliveryDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
              this.spinnerService.stop(spinnerRef);
              let finalData;
              if (this.allDeliveryStatus[deliveryIdx].Title === 'Cancelled') finalData = 'Your pickup request has been canceled';
              else finalData = 'Requests status is updated successfully!';

              const dialogRef = this.dialog.open(SuccesPopupComponent, {
                width: '700px',
                maxWidth: '40vw',
                data: { ClientData: finalData },
                panelClass: 'custom-savedialog-container',
              });
              dialogRef.afterClosed().subscribe((result) => { });
            },
            (error) => {
              this.state.isSpinnerVisible = false;
              this.spinnerService.stop(spinnerRef);
            }
          );
      }
    }
  }
  // postmate cancel
  goToRoadiesfinalCancel() {
    this.npalertdatatrue = false;
    for (const status of this.allDeliveryStatus) {
      if (status.Title == 'Cancelled') {
        this.pickup[0].Status[0].ID = status.ID;
        this.goToroadiesCancelled();
      }
    }
  }
  // Schedule cancel
  goToSchedulefinalCancel() {
    for (let deliveryIdx in this.allDeliveryStatus) {
      if (this.allDeliveryStatus[deliveryIdx].Title === 'Cancelled') {
        this.pickup[0].Status[0] = Object.assign({}, this.allDeliveryStatus[deliveryIdx]);
        this.UpdatePickUpStatus();
      }
    }
  }
  goToroadiesCancelled() {
    var spinnerRef2 = this.spinnerService.start();
    if (this.pickup[0].ShippingID) {
      this.ShippingID = this.pickup[0].ShippingID;
    } else {
      this.ShippingID = 0;
    }
    for (const status of this.allDeliveryStatus) {
      if (this.pickup[0].Status[0].ID == status.ID) {
        //account-type-id
        let deliveryTitle;
        deliveryTitle = status.Title;
        if (deliveryTitle === 'Cancelled') {
          this.cancelledStatus = false;
          if (this.ShippingID === 0) {
            this.ShippingStatusID = this.pickup[0].Status[0].ID;
          } else {
            this.ShippingStatusID = 'Cancelled';
          }
          // let payloaddata = Object.assign({}, this.pickup[0]);
          let payloaddata = {
            // RecipientName: this.pickup[0].PickupRequest[0].Recipient,
            RecipientId: this.pickup[0].PickupRequest[0].NonProfitReceiverId,
            Quantity: this.totalQuantity,
            Value: this.totalItemValue,
            Weight: this.totalWeightwithUnit,
            Description: 'Pickup Request From Client ' + this.pickup[0].PickupRequest[0].Clients[0].Name,
            ClientId: this.pickup[0].PickupRequest[0].ClientId,
            Request_Id: this.pickup[0].RequestId,
            DeliveryBetweenEnd: this.pickup[0].PickupBefore,
            DeliveryBetweenStart: this.pickup[0].PickupAfter,
            ShipmentId: this.ShippingID,
            Status: this.ShippingStatusID,
            DonationReceiptURL: this.pickup[0].DonationReceiptURL,
            DonationReceiver: this.pickup[0].DonationReceiver,
          };
          if (this.ShippingID) {
            this.pickupService.updateRoadiesDelivery(payloaddata).subscribe(
              (data) => {
                if (data.message === 'The remote server returned an error: (400) Bad Request.') {
                  alert('Status cannot be changed');
                }
                this.pickup = data.Result;
                // alert(data.message);
                this.spinnerService.stop(spinnerRef2);
                this.pickupDetails();
              },
              (error) => {
                // this.state.isSpinnerVisible = false;
                this.spinnerService.stop(spinnerRef2);
                // alert(JSON.stringify(error));
              }
            );
          } else {
            // this.deliveryCancelledStatus();
            if (this.pickup[0].DeliveryDate) {
              this.pickup[0].DeliveryDate = this.estdatepostFormat.transform(this.pickup[0].DeliveryDate);
            }
            let PickupDate;
            if (this.pickup[0].PickupDate) {
              PickupDate = this.estdatepostFormat.transform(this.pickup[0].PickupDate);
            }
            let CreatedOn;
            if (this.pickup[0].CreatedOn) {
              CreatedOn = this.estdatepostFormat.transform(this.pickup[0].CreatedOn);
            }
            let PickupBefore;
            if (this.pickup[0].PickupBefore) {
              PickupBefore = this.estdatepostFormat.transform(this.pickup[0].PickupBefore);
            }
            let PickupAfter;
            if (this.pickup[0].PickupAfter) {
              PickupAfter = this.estdatepostFormat.transform(this.pickup[0].PickupAfter);
            }
            let payload = Object.assign({}, this.pickup[0]);
            payload.StatusId = payload.Status[0].ID;
            payload.CreatedOn = CreatedOn;
            payload.PickupAfter = PickupAfter;
            payload.PickupBefore = PickupBefore;
            payload.PickupDate = PickupDate;
            payload.DeliveryDate = this.pickup[0].DeliveryDate;
            payload.ShippingID = 0;
            this.pickupService.updateDelivery(payload).subscribe(
              (data) => {
                this.pickup = data.Result;
                this.spinnerService.stop(spinnerRef2);
                // alert("Your Request successfully Cancelled!");
                let finalData;
                finalData = 'Your pickup request has been canceled';
                const dialogRef = this.dialog.open(SuccesPopupComponent, {
                  width: '700px',
                  maxWidth: '40vw',
                  data: { ClientData: finalData },
                  panelClass: 'custom-savedialog-container',
                });
                dialogRef.afterClosed().subscribe((result) => { });
                this.requestStatus = this.pickup[0].Status[0].Title;
                this.selectedstatus = this.pickup[0].Status[0].Title;
                if (this.requestStatus === 'Cancelled') {
                  this.info.StatusId = this.requestStatus;
                }
                this.info.StatusId = this.requestStatus;
                if (this.requestStatus === 'Cancelled') {
                  this.roadiesPickup = true;
                }
                this._rdata.updateBasicInfo1(JSON.stringify(this.info));
              },
              (error) => {
                this.state.isSpinnerVisible = false;
                // alert(JSON.stringify(error));
                this.spinnerService.stop(spinnerRef2);
              }
            );
          }
        } else if (deliveryTitle !== 'UnClaimed') {
          alert('Action Not Allowed');
        }
      }
    }
  }
  // postmate cancel
  goTopostmatefinalCancel() {
    for (const status of this.allDeliveryStatus) {
      if (status.Title == 'Cancelled') {
        this.pickup[0].Status[0].ID = status.ID;
      }
    }
    this.cancelledStatus = false;
    let payloaddata = {
      Request_Id: this.pickup[0].RequestId,
      PostmateId: this.pickup[0].PostmateId,
      Cancel: true,
    };
    if (this.pickup[0].PostmateId === 0 || this.pickup[0].PostmateId === null || this.pickup[0].PostmateId === '0') {
      // this.deliveryCancelledStatus();
      if (this.pickup[0].DeliveryDate) {
        this.pickup[0].DeliveryDate = this.estdatepostFormat.transform(this.pickup[0].DeliveryDate);
      }
      let PickupDate;
      if (this.pickup[0].PickupDate) {
        PickupDate = this.estdatepostFormat.transform(this.pickup[0].PickupDate);
      }
      let CreatedOn;
      if (this.pickup[0].CreatedOn) {
        CreatedOn = this.estdatepostFormat.transform(this.pickup[0].CreatedOn);
      }
      let PickupBefore;
      if (this.pickup[0].PickupBefore) {
        PickupBefore = this.estdatepostFormat.transform(this.pickup[0].PickupBefore);
      }
      let PickupAfter;
      if (this.pickup[0].PickupAfter) {
        PickupAfter = this.estdatepostFormat.transform(this.pickup[0].PickupAfter);
      }
      let payload = Object.assign({}, this.pickup[0]);
      payload.StatusId = payload.Status[0].ID;
      payload.CreatedOn = CreatedOn;
      payload.PickupAfter = PickupAfter;
      payload.PickupBefore = PickupBefore;
      payload.PickupDate = PickupDate;
      payload.DeliveryDate = this.pickup[0].DeliveryDate;
      // payload.PostmateId = 0;
      this.pickupService.updateDelivery(payload).subscribe(
        (data) => {
          this.pickup = data.Result;
          // setTimeout(() => {
          //   this.pickup[0].Status[0].Title = 'UnClaimed';
          // });
          this.selectedstatus = this.pickup[0].Status[0].Title;
          // alert("Your Request successfully Cancelled!");
          let finalData;
          finalData = 'Your pickup request has been canceled';
          const dialogRef = this.dialog.open(SuccesPopupComponent, {
            width: '700px',
            maxWidth: '40vw',
            data: { ClientData: finalData },
            panelClass: 'custom-savedialog-container',
          });
          dialogRef.afterClosed().subscribe((result) => { });
          // this.requestStatus = this.pickup[0].Status[0].Title;
          // if (this.requestStatus === 'Cancelled') {
          //   this.roadiesPickupCancel = true;
          // }
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
        }
      );
    } else {
      this.pickupService.PostmatesPickupDelivery(payloaddata).subscribe(
        (data) => {
          if (data.message === 'The remote server returned an error: (400) Bad Request.') {
            alert('Status cannot be changed');
          } else if (data.message) {
            alert(data.message);
          } else {
            // alert("Your Request successfully Cancelled!");
            let finalData;
            finalData = 'Your pickup request has been canceled';
            const dialogRef = this.dialog.open(SuccesPopupComponent, {
              width: '700px',
              maxWidth: '40vw',
              data: { ClientData: finalData },
              panelClass: 'custom-editSub-container',
            });
            dialogRef.afterClosed().subscribe((result) => { });
            this.pickup[0] = data;
            // setTimeout(() => {
            //   this.pickup[0].Status[0].Title = 'UnClaimed';
            // });
            this.selectedstatus = this.pickup[0].Status[0].Title;
            this.requestStatus = this.pickup[0].Status[0].Title;
            if (this.requestStatus === 'Cancelled') {
              this.roadiesPickupCancel = true;
            }
          }
        },
        (error) => {
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
        }
      );
    }
  }
  goTopostmateCancelled() {
    this.npalertdatatrue = false;
    let cancelData;
    let claimedData;
    for (const status of this.allDeliveryStatus) {
      if (status.ID == this.pickup[0].Status[0].ID) {
        let deliveryTitle;
        deliveryTitle = status.Title;
        if (status.Title === 'Cancelled') {
          cancelData = true;
        } else if (status.Title === 'UnClaimed') {
          claimedData = true;
        }
      }
    }
    if (cancelData === true) {
      this.goTopostmatefinalCancel();
    } else if (claimedData === true) {
      if (this.pickup[0].TotalWeight) {
        // this.goTopostmatevalid();
      } else {
        for (const status of this.allDeliveryStatus) {
          if (status.Title == 'Pending') {
            this.pickup[0].Status[0].ID = Object.assign(status.ID);
          }
        }
        this.editmenusvalid = true;
        setTimeout(() => {
          this.editmenusvalid = false;
        }, 3000);
      }
    } else {
      alert('Action Not Allowed');
    }
  }
  // postmate status
  UpdatePickUppostmateStatus() {
    this.npalertdatatrue = false;
    if (this.OTPPOstmate === true) {
      this.postmateOTP();
    } else {
      this.postmatenonOTP();
    }
  }
  postmateOTP() {
    // this.disbuttonStatus = true;
    // this.valid = false;
    if (this.pickup[0].PickupAfter) {
      this.selectedPickupAfter = this.postDateService.transform(this.selectedStartDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      // this.pickup[0].PickupAfter = this.postDateService.transform(this.pickup[0].PickupAfter);
    } else {
      // const offset = -5.0;
      // this.pickupStartDate = new Date();
      // const utcstart = this.pickupStartDate.getTime() + (this.pickupStartDate.getTimezoneOffset() * 60000);
      // const startDate = new Date(utcstart + (3600000 * offset));
      this.selectedPickupAfter =
        moment(new Date(this.pickupStartDate)).format().toString().split('T')[0] +
        'T' +
        moment(new Date(this.pickupStartDate)).format().toString().split('T')[1].split('+')[0];
      // this.pickup[0].PickupAfter = moment(startDate).add(1, 'hours').format().toString().split('T')[0] + 'T' +
      //   moment(startDate).add(1, 'hours').format().toString().split('T')[1].split('+')[0];
    }
    if (this.pickup[0].PickupBefore) {
      this.selectedPickupEnd = this.postDateService.transform(this.selectedEndDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      // this.pickup[0].PickupBefore = this.postDateService.transform(this.pickup[0].PickupBefore);
    } else {
      this.selectedPickupEnd =
        moment(this.pickupEndDate)
          // .add(1, "minutes")
          .format()
          .toString()
          .split('T')[0] +
        'T' +
        moment(this.pickupEndDate)
          // .add(1, "minutes")
          .format()
          .toString()
          .split('T')[1]
          .split('+')[0];
    }
    let dt1 = new Date(this.selectedPickupAfter);
    let dt2 = new Date(this.selectedPickupEnd);
    // let dt3 = new Date(this.dateFormat.transform(new Date().toISOString()));
    function diff_hours(dt2, dt1) {
      var diff = Math.abs(dt1.getTime() - dt2.getTime());
      return diff / 60000;
    }
    // function diff_hoursStart(dt3, dt1) {
    //   var diff = Math.abs(dt1.getTime() - dt3.getTime());
    //   return diff / 60000;
    // }
    function endAfterStart(dt1, dt2) {
      var startDate = new Date(dt1);
      var endDate = new Date(dt2);
      return endDate.getTime() >= startDate.getTime();
    }

    //account-type-id
    let deliveryTitle;
    // deliveryTitle = status.Title;
    // deliveryTitle = "Claimed";
    // if (deliveryTitle === "Claimed") {
    if (endAfterStart(dt1, dt2) === true) {
      // if (diff_hoursStart(dt3, dt1) >= 60) {
      if (diff_hours(dt1, dt2) >= 120) {
        if (this.selectedPickupAfter !== undefined) {
          if (this.selectedPickupEnd !== undefined) {
            this.postmatenonOTP();
          }
        }
      } else {
        this.disbuttonStatus = false;
        this.diffTimeless = true;
        setTimeout(() => {
          this.diffTimeless = false;
        }, 4000);
        // this.pickupDetails();
      }
      // } else {
      //   this.disbuttonStatus = false;
      //   this.futureStartValid = true;
      //   setTimeout(() => {
      //     this.futureStartValid = false;
      //   }, 4000);
      // }
    } else {
      this.disbuttonStatus = false;
      this.futureValid = true;
      setTimeout(() => {
        this.futureValid = false;
      }, 4000);
    }
    // }
  }

  postmatenonOTP() {
    this.disbuttonStatus = true;
    this.valid = false;
    let payloaddata = {
      Request_Id: this.pickup[0].RequestId,
    };
    this.pickupService.PostmatesPickupDelivery(payloaddata).subscribe((data) => {
      if ('error' in data) {
        this.error = data['message'];
        // if (this.pickup[0].Status[0].Title !== 'UnClaimed') {
        for (let deliveryIdx in this.allDeliveryStatus) {
          if (this.allDeliveryStatus[deliveryIdx].Title === 'Pending') {
            this.pickup[0].Status[0] = Object.assign({}, this.allDeliveryStatus[deliveryIdx]);
          }
        }
        // }
        setTimeout(() => {
          this.pickup[0].Status[0].Title = 'Pending';
        });
        if (data['Postmate Errors']['pickup_address']) {
          this.postmaterrors = 'Pickup location address not valid';
          setTimeout(() => {
            this.postmaterrors = false;
          }, 6000);
        } else if (data['Postmate Errors']['dropoff_address']) {
          this.postmaterrors = 'Recipient location address not valid';
          setTimeout(() => {
            this.postmaterrors = false;
          }, 6000);
        } else if (data['Postmate Errors']['dropoff_phone_number'] && data['Postmate Errors']['pickup_phone_number']) {
          this.postmaterrors = 'Pickup and Recipient Phone numbers are not valid';
          setTimeout(() => {
            this.postmaterrors = false;
          }, 6000);
        } else if (data['Postmate Errors']['dropoff_phone_number']) {
          this.postmaterrors = 'Recipient location phone number is not valid';
          setTimeout(() => {
            this.postmaterrors = false;
          }, 6000);
        } else if (data['Postmate Errors']['pickup_phone_number']) {
          this.postmaterrors = 'Pickup location phone number is not valid';
          setTimeout(() => {
            this.postmaterrors = false;
          }, 6000);
        } else {
          alert(data['message']);
        }
        this.disbuttonStatus = false;
        // alert(JSON.stringify(data['Postmate Errors']));
      } else {
        // setTimeout(() => {
        //   this.pickup[0].Status[0].Title = 'UnClaimed';
        // });
        this.cancelledStatus = false;
        let finalData;
        if (this.nonprofitSelect) {
          // alert('Updated Successfully');
          finalData = 'Updated Successfully';
        } else {
          // alert('Successfully Claimed');
          finalData = 'Successfully Claimed';
        }
        const dialogRef = this.dialog.open(SuccesPopupComponent, {
          width: '700px',
          maxWidth: '40vw',
          data: { ClientData: finalData },
          panelClass: 'custom-savedialog-container',
        });
        dialogRef.afterClosed().subscribe((result) => { });
        this.pickupDetails();
        // this.pickup = data;
        // this.selectedstatus = this.pickup[0].Status[0].Title;
        this.editblePickup = true;
        // this.requestStatus = this.pickup[0].Status[0].Title;
        // this.info.StatusId = this.requestStatus;
        this.roadiesPickup = false;
        // this._rdata.updateBasicInfo1(JSON.stringify(this.info));
      }
    });
  }
  // roadies status
  UpdatePickUpnpRoadiesStatus() {
    this.disbuttonStatus = true;
    this.valid = false;
    if (this.pickup[0].PickupAfter) {
      this.selectedPickupAfter = this.postDateService.transform(this.selectedStartDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      // this.pickup[0].PickupAfter = this.postDateService.transform(this.pickup[0].PickupAfter);
    }
    if (this.pickup[0].PickupBefore) {
      this.selectedPickupEnd = this.postDateService.transform(this.selectedEndDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      // this.pickup[0].PickupBefore = this.postDateService.transform(this.pickup[0].PickupBefore);
    }
    if (this.pickup[0].ShippingID) {
      this.ShippingID = this.pickup[0].ShippingID;
    } else {
      this.ShippingID = 0;
    }
    for (const status of this.allDeliveryStatus) {
      if (this.pickup[0].Status[0].ID === status.ID) {
        //account-type-id
        let deliveryTitle;
        deliveryTitle = status.Title;
        deliveryTitle = 'Claimed';
        if (deliveryTitle === 'Claimed') {
          if (this.ShippingID) {
            this.nonprofitUpdate = true;
          }
          let payloaddata = {
            // RecipientName: this.pickup[0].PickupRequest[0].Recipient,
            update: this.nonprofitUpdate,
            RecipientId: this.pickup[0].PickupRequest[0].NonProfitReceiverId,
            Quantity: this.totalQuantity,
            Value: this.totalItemValue,
            Weight: this.totalWeightwithUnit,
            Description: 'Pickup Request From Client ' + this.pickup[0].PickupRequest[0].Clients[0].Name,
            ClientId: this.pickup[0].PickupRequest[0].ClientId,
            Request_Id: this.pickup[0].RequestId,
            // DeliveryBetweenEnd: PickupBeforedata.toISOString(),
            DeliveryBetweenEnd: this.selectedPickupEnd.split('T')[0] + 'T' + this.selectedPickupEnd.split('T')[1].split('-')[0].split('+')[0] + '.00',
            DeliveryBetweenStart:
              this.selectedPickupAfter.split('T')[0] + 'T' + this.selectedPickupAfter.split('T')[1].split('-')[0].split('+')[0] + '.00',
            PickupAfter: this.selectedPickupAfter.split('T')[0] + 'T' + this.selectedPickupAfter.split('T')[1].split('-')[0].split('+')[0] + '.00',
            ShipmentId: this.ShippingID,
            Status: null,
          };
          this.pickupService.updateRoadiesDelivery(payloaddata).subscribe(
            (data) => {
              if ('error' in data) {
                this.error = data['message'];
                if (this.error === 'unprocessable request parameters') {
                  alert('Unprocessable request parameters');
                } else if (this.error === 'Delivery window start time must be in the future.') {
                  this.futureStartValid = true;
                  setTimeout(() => {
                    this.futureStartValid = false;
                  }, 4000);
                } else {
                  alert(this.error);
                }
                this.disbuttonStatus = false;
                this.recipient = '';
                if (this.pickup[0].Status[0].Title !== 'Claimed') {
                  for (let deliveryIdx in this.allDeliveryStatus) {
                    if (this.allDeliveryStatus[deliveryIdx].Title === 'UnClaimed') {
                      this.pickup[0].Status[0] = Object.assign({}, this.allDeliveryStatus[deliveryIdx]);
                    }
                  }
                }
              } else {
                this.cancelledStatus = false;
                let finalData;
                if (this.nonprofitSelect) {
                  // alert('Updated Successfully');
                  finalData = 'Updated Successfully';
                } else {
                  // alert('Successfully Claimed');
                  finalData = 'Successfully Claimed';
                }
                const dialogRef = this.dialog.open(SuccesPopupComponent, {
                  width: '700px',
                  maxWidth: '40vw',
                  data: { ClientData: finalData },
                  panelClass: 'custom-savedialog-container',
                });
                dialogRef.afterClosed().subscribe((result) => { });
                this.pickupDetails();
                let pickup;
                pickup = data.Result;
                this.cancelledStatus = false;
                this.selectedstatus = this.pickup[0].Status[0].Title;
                this.requestStatus = pickup[0].Status[0].Title;
                this.info.StatusId = this.requestStatus;
                this.roadiesPickup = false;
                this._rdata.updateBasicInfo1(JSON.stringify(this.info));
              }
            },
            (error) => {
              this.disbuttonStatus = false;
              this.state.isSpinnerVisible = false;
              // alert(JSON.stringify(error));
            }
          );
        }
      }
    }
  }
  // roadies status
  UpdatePickUpRoadiesStatus() {
    this.disbuttonStatus = true;
    this.valid = false;
    // if (this.pickup[0].PickupAfter) {
    this.selectedPickupAfter = this.postDateService.transform(this.selectedStartDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
    // this.pickup[0].PickupAfter = this.postDateService.transform(this.pickup[0].PickupAfter);
    // } else {
    // const offset = -5.0;
    // this.pickupStartDate = new Date();
    // const utcstart = this.pickupStartDate.getTime() + (this.pickupStartDate.getTimezoneOffset() * 60000);
    // const startDate = new Date(utcstart + (3600000 * offset));
    // this.selectedPickupAfter =
    //   moment(new Date(this.pickupStartDate))
    //     .format()
    //     .toString()
    //     .split('T')[0] +
    //   'T' +
    //   moment(new Date(this.pickupStartDate))
    //     .format()
    //     .toString()
    //     .split('T')[1]
    //     .split('+')[0];
    // this.pickup[0].PickupAfter = moment(startDate).add(1, 'hours').format().toString().split('T')[0] + 'T' +
    //   moment(startDate).add(1, 'hours').format().toString().split('T')[1].split('+')[0];
    // }
    // if (this.pickup[0].PickupBefore) {
    this.selectedPickupEnd = this.postDateService.transform(this.selectedEndDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
    // this.pickup[0].PickupBefore = this.postDateService.transform(this.pickup[0].PickupBefore);
    // } else {
    //   this.selectedPickupEnd =
    //     moment(this.pickupEndDate)
    //       // .add(1, "minutes")
    //       .format()
    //       .toString()
    //       .split('T')[0] +
    //     'T' +
    //     moment(this.pickupEndDate)
    //       // .add(1, "minutes")
    //       .format()
    //       .toString()
    //       .split('T')[1]
    //       .split('+')[0];
    // }
    let dt1 = new Date(this.selectedPickupAfter);
    let dt2 = new Date(this.selectedPickupEnd);
    // let dt3 = new Date(this.dateFormat.transform(new Date().toISOString()));
    function diff_hours(dt2, dt1) {
      var diff = Math.abs(dt1.getTime() - dt2.getTime());
      return diff / 60000;
    }
    // function diff_hoursStart(dt3, dt1) {
    //   var diff = Math.abs(dt1.getTime() - dt3.getTime());
    //   return diff / 60000;
    // }
    function endAfterStart(dt1, dt2) {
      var startDate = new Date(dt1);
      var endDate = new Date(dt2);
      return endDate.getTime() >= startDate.getTime();
    }
    if (this.pickup[0].ShippingID) {
      this.ShippingID = this.pickup[0].ShippingID;
    } else {
      this.ShippingID = 0;
    }
    for (const status of this.allDeliveryStatus) {
      if (this.pickup[0].Status[0].ID === status.ID) {
        //account-type-id
        let deliveryTitle;
        let deliveryroaTitle;
        deliveryTitle = status.Title;
        // deliveryTitle = 'Claimed';
        deliveryroaTitle = 'UnClaimed';
        if (
          deliveryroaTitle === 'UnClaimed' &&
          (this.pickup[0].PickupRequest[0].Clients[0].DriverServices[0].Title === 'Roadie/Postmates' ||
            this.pickup[0].PickupRequest[0].DriverServices[0].Title === 'Roadie')
        ) {
          if (endAfterStart(dt1, dt2) === true) {
            // if (diff_hoursStart(dt3, dt1) >= 60) {
            if (diff_hours(dt1, dt2) >= 120) {
              if (this.selectedPickupAfter !== undefined) {
                if (this.selectedPickupEnd !== undefined) {
                  if (this.ShippingID) {
                    this.nonprofitUpdate = true;
                  }
                  let payloaddata = {
                    // RecipientName: this.pickup[0].PickupRequest[0].Recipient,
                    update: this.nonprofitUpdate,
                    RecipientId: this.pickup[0].PickupRequest[0].NonProfitReceiverId,
                    Quantity: this.totalQuantity,
                    Value: this.totalItemValue,
                    Weight: this.totalWeightwithUnit,
                    Description: 'Pickup Request From Client ' + this.pickup[0].PickupRequest[0].Clients[0].Name,
                    ClientId: this.pickup[0].PickupRequest[0].ClientId,
                    Request_Id: this.pickup[0].RequestId,
                    // DeliveryBetweenEnd: PickupBeforedata.toISOString(),
                    DeliveryBetweenEnd:
                      this.selectedPickupEnd.split('T')[0] + 'T' + this.selectedPickupEnd.split('T')[1].split('-')[0].split('+')[0] + '.00',
                    DeliveryBetweenStart:
                      this.selectedPickupAfter.split('T')[0] + 'T' + this.selectedPickupAfter.split('T')[1].split('-')[0].split('+')[0] + '.00',
                    PickupAfter:
                      this.selectedPickupAfter.split('T')[0] + 'T' + this.selectedPickupAfter.split('T')[1].split('-')[0].split('+')[0] + '.00',
                    ShipmentId: this.ShippingID,
                    Status: null,
                  };
                  this.pickupService.updateRoadiesDelivery(payloaddata).subscribe(
                    (data) => {
                      if ('error' in data) {
                        this.error = data['message'];
                        if (this.error === 'unprocessable request parameters') {
                          alert('Unprocessable request parameters');
                        } else if (this.error === 'Delivery window start time must be in the future.') {
                          this.futureStartValid = true;
                          setTimeout(() => {
                            this.futureStartValid = false;
                          }, 4000);
                        } else {
                          alert(this.error);
                        }
                        this.disbuttonStatus = false;
                        // alert('Rodies does not operate in the given location. \nPlease check the time, pickup location, dropOff location and total weight');
                        // alert(this.error)
                        // this.PickupAftererror = this.selectedPickupAfter.split('T')[0] + 'T' +
                        //   this.selectedPickupAfter.split('T')[1].split('-')[0].split('+')[0] + '.00';
                        // this.PickupBeforerror = this.selectedPickupEnd.split('T')[0] + 'T' +
                        //   this.selectedPickupEnd.split('T')[1].split('-')[0].split('+')[0] + '.00';
                        // this.pickupStartTime = this.PickupAftererror.split('T')[1];
                        // this.showStartTime = moment(this.pickupStartTime, ["HH.mm"]).format("hh:mm a");
                        // this.pickupStartDate = new Date(this.PickupAftererror);
                        // this.pickupEndTime = this.PickupBeforerror.split("T")[1];
                        // this.showEndTime = moment(this.pickupEndTime, ["HH.mm"]).format("hh:mm a");
                        // this.pickupEndDate = new Date(this.PickupBeforerror);

                        this.recipient = '';
                        if (this.pickup[0].Status[0].Title !== 'Claimed') {
                          for (let deliveryIdx in this.allDeliveryStatus) {
                            if (this.allDeliveryStatus[deliveryIdx].Title === 'Pending') {
                              this.pickup[0].Status[0] = Object.assign({}, this.allDeliveryStatus[deliveryIdx]);
                            }
                          }
                        }
                      } else {
                        this.cancelledStatus = false;
                        let finalData;
                        if (this.nonprofitSelect) {
                          // alert('Updated Successfully');
                          finalData = 'Updated Successfully';
                        } else {
                          // alert('Successfully Claimed');
                          finalData = 'Successfully Claimed';
                        }
                        const dialogRef = this.dialog.open(SuccesPopupComponent, {
                          width: '700px',
                          maxWidth: '40vw',
                          data: { ClientData: finalData },
                          panelClass: 'custom-savedialog-container',
                        });
                        dialogRef.afterClosed().subscribe((result) => { });
                        this.pickupDetails();
                        let pickup;
                        // this.pickup = data.Result;
                        // this.requestStatus = this.pickup[0].Status[0].Title;
                        this.info.StatusId = 'UnClaimed';
                        this.roadiesPickup = false;
                        this._rdata.updateBasicInfo1(JSON.stringify(this.info));
                      }
                      // location.reload();
                      // this.pickupDetails();
                    },
                    (error) => {
                      this.state.isSpinnerVisible = false;
                      // alert(JSON.stringify(error));
                    }
                  );
                }
              }
            } else {
              this.disbuttonStatus = false;
              this.diffTimeless = true;
              setTimeout(() => {
                this.diffTimeless = false;
              }, 4000);
              // this.pickupDetails();
            }
            // } else {
            //   this.disbuttonStatus = false;
            //   this.futureStartValid = true;
            //   setTimeout(() => {
            //     this.futureStartValid = false;
            //   }, 4000);
            // }
          } else {
            this.disbuttonStatus = false;
            this.futureValid = true;
            setTimeout(() => {
              this.futureValid = false;
            }, 4000);
          }
        }
      }
    }
  }

  UpdatePickUp() {
    var spinnerRef = this.spinnerService.start();

    let payload = Object.assign({}, this.pickup[0]);
    if (payload.DispatchNotification) {
      if (payload.DispatchNotification.length > 0) {
        payload.DispatchNotificationId = payload.DispatchNotification[0].DispatchNotificationId;
      }
    }
    let delivery;
    if (this.pickup[0].DeliveryDate) {
      delivery = this.estdatepostFormat.transform(this.pickup[0].DeliveryDate);
    }
    let pickupDate;
    if (this.pickup[0].PickupDate) {
      pickupDate = this.estdatepostFormat.transform(this.pickup[0].PickupDate);
    }
    let CreatedOn;
    if (this.pickup[0].CreatedOn) {
      CreatedOn = this.estdatepostFormat.transform(this.pickup[0].CreatedOn);
    }
    (payload.CreatedOn = CreatedOn), (payload.DeliveryDate = delivery), (payload.PickupDate = pickupDate), (payload.StatusId = payload.Status[0].ID);
    payload.Driver = [];
    payload.DriverId = this.selectedDriver;
    payload.DonationReceiptURL = this.pickup[0].DonationReceiptURL;
    payload.DonationReceiver = this.pickup[0].DonationReceiver;

    this.pickupService.updateDelivery(payload).subscribe(
      (data) => {
        this.pickup = data.Result;
        if (
          this.selectedDriver &&
          this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
          this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
          this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined
        ) {
          this.valid = false;
        }
        this.spinnerService.stop(spinnerRef);
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        this.spinnerService.stop(spinnerRef);
      }
    );
  }

  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }
  updateDateTimedefault(defaultStartDate, defaultEndDate) {
    let payload = Object.assign({}, this.pickup[0]);
    // this.postDateService.transform(this.pickupStartDate.toISOString())
    payload.PickupAfter = this.postDateService.transform(defaultStartDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
    payload.PickupBefore = this.postDateService.transform(defaultEndDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
    if (this.pickup[0].DeliveryDate) {
      this.pickup[0].DeliveryDate = this.estdatepostFormat.transform(this.pickup[0].DeliveryDate);
    }
    let PickupDate;
    if (this.pickup[0].PickupDate) {
      PickupDate = this.estdatepostFormat.transform(this.pickup[0].PickupDate);
    }
    let createon;
    // if (this.pickup[0].CreatedOn) {
    createon = this.estdatepostFormat.transform(this.pickup[0].CreatedOn);
    // } else {
    //   createon = this.pickup[0].CreatedOn;
    // }
    if (this.pickup[0].PickupBefore) {
      this.pickup[0].PickupBefore = this.postDateService.transform(this.selectedEndDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      payload.PickupBefore = this.pickup[0].PickupBefore;
    }
    (payload.DeliveryDate = this.pickup[0].DeliveryDate),
      (payload.PickupDate = PickupDate),
      (payload.CreatedOn = createon),
      (payload.DonationReceiptURL = this.pickup[0].DonationReceiptURL);
    delete payload.DispatchNotification;
    delete payload.PickupRequest;
    delete payload.Status;
    this.pickupService.updateDelivery(payload).subscribe(
      (data) => {
        this.pickup = data.Result;
        this.selectedEndDateSplit = this.dateDispalyFormatPipe.transform(
          this.pickup[0].PickupBefore,
          this.pickup[0].PickupRequest[0].Clients[0].TimeZone
        );

        this.selectedStartDateSplit = this.dateDispalyFormatPipe.transform(
          this.pickup[0].PickupAfter,
          this.pickup[0].PickupRequest[0].Clients[0].TimeZone
        );
        if (this.pickup[0].PickupAfter) {
          let eststartDate;
          eststartDate = this.dateDispalyFormatPipe.transform(this.pickup[0].PickupAfter, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          this.pickupStartTime = eststartDate.split('T')[1];
          this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format('hh:mm a');
          this.pickupStartDate = new Date(eststartDate);
        }
        if (this.pickup[0].PickupBefore) {
          let estendDate;
          estendDate = this.dateDispalyFormatPipe.transform(this.pickup[0].PickupBefore, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          this.pickupEndTime = estendDate.split('T')[1];
          this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
          this.pickupEndDate = new Date(estendDate);
        }
        this.recipient = this.pickup[0].PickupRequest[0].Recipient;
        this.recipientAddMTN();
        this.nonprofitDetails(this.pickup[0].PickupRequest[0].Clients[0].State);
        this.recipientAddMTN();
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    // }
  }
  //Date change event(start date-pickup)
  updateDateTime(selectedDate, pickupStartTime) {
    var spinnerRef = this.spinnerService.start();
    if (selectedDate) {
      selectedDate = selectedDate;
    } else {
      // selectedDate = new Date(this.pickup[0].PickupAfter)
      selectedDate = new Date(this.pickupStartDate);
    }
    let payload = Object.assign({}, this.pickup[0]);
    if (selectedDate === undefined) {
      this.requireTimeField = true;
      setTimeout(() => {
        this.requireTimeField = false;
      }, 4000);
    } else if (pickupStartTime === undefined) {
      this.requireTimeField = true;
      setTimeout(() => {
        this.requireTimeField = false;
      }, 4000);
    } else {
      selectedDate.setMinutes(0);
      selectedDate.setHours(0);
      let selectedfirstValue;
      selectedfirstValue = pickupStartTime.split(':')[0];

      let selectedSecondValue;
      selectedSecondValue = pickupStartTime.split(':')[1];
      selectedDate.setHours(selectedDate.getHours() + selectedfirstValue) +
        ':' +
        selectedDate.setMinutes(selectedDate.getMinutes() + selectedSecondValue);
      this.selectedDate = selectedDate;
      this.selectedStartTime = moment(selectedDate).format().toString();
      // let selectedStartDateSplit;
      this.selectedStartDateSplit = this.selectedStartTime.split('T')[0] + 'T' + this.selectedStartTime.split('T')[1].split('-')[0].split('+')[0];
      payload.PickupAfter = this.postDateService.transform(this.selectedStartDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      if (this.pickup[0].DeliveryDate) {
        this.pickup[0].DeliveryDate = this.estdatepostFormat.transform(this.pickup[0].DeliveryDate);
      }
      let PickupDate;
      if (this.pickup[0].PickupDate) {
        PickupDate = this.estdatepostFormat.transform(this.pickup[0].PickupDate);
      }
      let CreatedOn;
      if (this.pickup[0].CreatedOn) {
        CreatedOn = this.estdatepostFormat.transform(this.pickup[0].CreatedOn);
      }
      // if (this.PickupBeforerror) {
      //   this.pickup[0].PickupBefore = this.PickupBeforerror;
      //   payload.PickupBefore = this.pickup[0].PickupBefore;
      //   this.PickupBeforerror = null;
      // } else {
      // if (this.pickup[0].PickupBefore) {
      //   this.pickup[0].PickupBefore = this.postDateService.transform(
      //     this.pickup[0].PickupBefore
      //   );
      payload.PickupBefore = this.postDateService.transform(this.selectedEndDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      // }
      // else {
      //   let fixedDate;
      //   fixedDate = moment(new Date(selectedDate)).add(2, 'hours').format().toString().split('T')[0] + 'T' +
      //     moment(new Date(selectedDate)).add(2, 'hours').format().toString().split('T')[1].split('+')[0];
      //   this.pickupEndDate = new Date(fixedDate);
      //   this.showEndDate = new Date(moment(this.pickupEndDate).format('YYYY/MM/DD'));
      //   this.pickupEndTime = fixedDate.split('T')[1].split('+')[0].split('-')[0];
      //   this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
      // }
      // }
      (payload.DeliveryDate = this.pickup[0].DeliveryDate),
        (payload.PickupDate = PickupDate),
        (payload.CreatedOn = CreatedOn),
        (payload.DonationReceiptURL = this.pickup[0].DonationReceiptURL);
      delete payload.DispatchNotification;
      delete payload.PickupRequest;
      delete payload.Status;
      this.pickupService.updateDelivery(payload).subscribe(
        (data) => {
          this.pickup = data.Result;
          this.npalertgetdata();
          this.recipient = this.pickup[0].PickupRequest[0].Recipient;
          this.recipientAddMTN();

          if (!this.pickup[0].PickupRequest[0].NonProfitReceiverId) {
            // this.dropOffLocationData = [];
          } else {
            if (this.dropOffLocationData.length > 0) {
              this.dropOffLocationData = this.dropOffLocationData.filter((value) => value.ApprovalStatus[0].Title === 'Active');
              for (let idx in this.dropOffLocationData) {
                // alert(this.dropOffLocationData[idx].ClientId + " " + this.pickup[0].PickupRequest[0].NonProfitReceiverId)
                if (this.dropOffLocationData[idx].ClientId == parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId)) {
                  this.selectedDropOfLocationObject = this.dropOffLocationData[idx];
                }
              }
              let detail = this.dropOffLocationData;
              for (var status of detail) {
              }

            } else {
              this.selectedRecipientNone = true;
            }
            // this.nonprofitDetails(this.pickup[0].PickupRequest[0].Clients[0].State);
          }
          if (this.pickup[0].PickupAfter) {
            let eststartDate;
            eststartDate = this.dateDispalyFormatPipe.transform(this.pickup[0].PickupAfter, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
            this.pickupStartTime = eststartDate.split('T')[1];
            this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format('hh:mm a');
            this.pickupStartDate = new Date(eststartDate);
          }
          this.modifiedtime = this.timeDispalyFormatPipe.transform(this.pickup[0].ModifiedOn, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          this.deliveredTime = this.timeDispalyFormatPipe.transform(this.pickup[0].DeliveryDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          if (this.pickup[0].PickupBefore) {
            let estendDate;
            estendDate = this.dateDispalyFormatPipe.transform(this.pickup[0].PickupBefore, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
            this.pickupEndTime = estendDate.split('T')[1];
            this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
            this.pickupEndDate = new Date(estendDate);
          }
          if (
            this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
            this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
            this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined
          ) {
            if (this.pickup[0].PickupAfter !== null) {
              if (this.pickup[0].PickupBefore !== null) {
                this.valid = false;
              }
            }
          }
          this.spinnerService.stop(spinnerRef);
        },
        (error) => {
          this.spinnerService.stop(spinnerRef);
          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
        }
      );
    }
  }
  async onChangeDate(date) {
    this.selectedDate = new Date(date);
    this.pickupStartTime = this.ConvertTime(new Date(date));
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format('hh:mm a');
    this.selectedEndDate = new Date(date);
    this.selectedEndDate.setHours(this.selectedEndDate.getHours() + 2);

    this.pickupEndTime = this.ConvertTime(this.selectedEndDate);

    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    await this.updateDateTime(this.selectedDate, this.pickupStartTime);

    await this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
    setTimeout(() => {
      window.location.reload();
    }, 6000);
  }
  onChangeTime(time) {
    this.pickupStartTime = this.ConvertTime(time);
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format('hh:mm a');
    this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }

  //Date change event(end date-delivery)
  updateEndDateTime(selectedEndDate, pickupEndTime) {
    var spinnerRef = this.spinnerService.start();

    if (selectedEndDate) {
      selectedEndDate = selectedEndDate;
    } else {
      // selectedEndDate = new Date(this.pickup[0].PickupBefore);
      selectedEndDate = new Date(this.pickupEndDate);
    }
    let payload = Object.assign({}, this.pickup[0]);
    if (selectedEndDate === undefined) {
      this.requireTimeField = true;
      setTimeout(() => {
        this.requireTimeField = false;
      }, 4000);
    } else if (pickupEndTime === undefined) {
      this.requireTimeField = true;
      setTimeout(() => {
        this.requireTimeField = false;
      }, 4000);
    } else {
      selectedEndDate.setMinutes(0);
      selectedEndDate.setHours(0);
      let selectedfirstValue;
      selectedfirstValue = pickupEndTime.split(':')[0];

      let selectedSecondValue;
      selectedSecondValue = pickupEndTime.split(':')[1];
      selectedEndDate.setHours(selectedEndDate.getHours() + selectedfirstValue) +
        ':' +
        selectedEndDate.setMinutes(selectedEndDate.getMinutes() + selectedSecondValue);
      this.selectedEndDate = selectedEndDate;

      // let selectedEndDateSplit;
      this.selectedEndTime = moment(selectedEndDate).format().toString();
      this.selectedEndDateSplit = this.selectedEndTime.split('T')[0] + 'T' + this.selectedEndTime.split('T')[1].split('-')[0].split('+')[0];
      payload.PickupBefore = this.postDateService.transform(this.selectedEndDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      if (this.pickup[0].DeliveryDate) {
        this.pickup[0].DeliveryDate = this.estdatepostFormat.transform(this.pickup[0].DeliveryDate);
      }
      let PickupDate;
      if (this.pickup[0].PickupDate) {
        PickupDate = this.estdatepostFormat.transform(this.pickup[0].PickupDate);
      }
      let CreatedOn;
      if (this.pickup[0].CreatedOn) {
        CreatedOn = this.estdatepostFormat.transform(this.pickup[0].CreatedOn);
      }
      // if (this.PickupAftererror) {
      //   this.pickup[0].PickupAfter = this.PickupAftererror;
      //   payload.PickupAfter = this.pickup[0].PickupAfter;
      //   this.PickupAftererror = null;
      // } else {
      // if (this.pickup[0].PickupAfter) {
      //   this.pickup[0].PickupAfter = this.postDateService.transform(
      //     this.pickup[0].PickupAfter
      //   );
      payload.PickupAfter = this.postDateService.transform(this.selectedStartDateSplit, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
      // }
      // }
      (payload.DeliveryDate = this.pickup[0].DeliveryDate),
        (payload.PickupDate = PickupDate),
        (payload.CreatedOn = CreatedOn),
        (payload.DonationReceiptURL = this.pickup[0].DonationReceiptURL);
      delete payload.DispatchNotification;
      delete payload.PickupRequest;
      delete payload.Status;
      this.pickupService.updateDelivery(payload).subscribe(
        (data) => {
          this.pickup = data.Result;
          this.npalertgetdata();
          this.recipient = this.pickup[0].PickupRequest[0].Recipient;
          this.recipientAddMTN();

          if (!this.pickup[0].PickupRequest[0].NonProfitReceiverId) {
            // this.dropOffLocationData = [];
          } else {
            if (this.dropOffLocationData.length > 0) {
              this.dropOffLocationData = this.dropOffLocationData.filter((value) => value.ApprovalStatus[0].Title === 'Active');
              for (let idx in this.dropOffLocationData) {
                // alert(this.dropOffLocationData[idx].ClientId + " " + this.pickup[0].PickupRequest[0].NonProfitReceiverId)
                if (this.dropOffLocationData[idx].ClientId == parseInt(this.pickup[0].PickupRequest[0].NonProfitReceiverId)) {
                  this.selectedDropOfLocationObject = this.dropOffLocationData[idx];
                }
              }
              // this.dropOffLocationData = this.dropOffLocationData.filter(value => value.ApprovalStatus[0].Title === "Active");
              let detail = this.dropOffLocationData;
              for (var status of detail) {
              }

            } else {
              this.selectedRecipientNone = true;
            }
            // this.nonprofitDetails(this.pickup[0].PickupRequest[0].Clients[0].State);
          }
          if (this.pickup[0].PickupAfter) {
            let eststartDate;
            eststartDate = this.dateDispalyFormatPipe.transform(this.pickup[0].PickupAfter, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
            this.pickupStartTime = eststartDate.split('T')[1];
            this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format('hh:mm a');
            this.pickupStartDate = new Date(eststartDate);
          }
          this.modifiedtime = this.timeDispalyFormatPipe.transform(this.pickup[0].ModifiedOn, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          this.deliveredTime = this.timeDispalyFormatPipe.transform(this.pickup[0].DeliveryDate, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
          if (this.pickup[0].PickupBefore) {
            let estendDate;
            estendDate = this.dateDispalyFormatPipe.transform(this.pickup[0].PickupBefore, this.pickup[0].PickupRequest[0].Clients[0].TimeZone);
            this.pickupEndTime = estendDate.split('T')[1];
            this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
            this.pickupEndDate = new Date(estendDate);
          }
          if (
            this.pickup[0].PickupRequest[0].NonProfitReceiverId != null &&
            this.pickup[0].PickupRequest[0].NonProfitReceiverId !== 0 &&
            this.pickup[0].PickupRequest[0].NonProfitReceiverId !== undefined
          ) {
            if (this.pickup[0].PickupAfter !== null) {
              if (this.pickup[0].PickupBefore !== null) {
                this.valid = false;
              }
            }
          }
          this.spinnerService.stop(spinnerRef);
        },
        (error) => {
          this.spinnerService.stop(spinnerRef);

          this.state.isSpinnerVisible = false;
          // alert(JSON.stringify(error));
        }
      );
    }
  }

  onChangeEndDate(date) {
    this.selectedEndDate = new Date(date);
    this.pickupEndTime = this.ConvertTime(new Date(date));
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  onChangeEndTime(time) {
    this.pickupEndTime = this.ConvertTime(time);
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  ngOnDestroy() {
    this.info.pickupsPage = false;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
  }
  updateGoodrReq() {
    for (const status of this.allDeliveryStatus) {
      if (status.ID == this.pickup[0].Status[0].ID) {
        if (status.Title === 'Cancelled') {
          this.UpdatePickUpStatus();
        } else {
          this.goTovalid();
        }
      }
    }
  }
  pickupupdate(pickupdata) {
    const dialogRef = this.dialog.open(PickupeditableComponent, {
      width: '850px',
      height: '785px',
      panelClass: 'custom-Filterdialog-container',
      // disableClose: true,
      data: [{ pickupdata }, { showEditPickupItems: true }],
    });
    dialogRef.afterClosed().subscribe((result) => {
      // if (result) {
      this.pickupDetails();
      // }
    });
  }

  viewEditPickup(pickup) {
    if (this.state.user.User.Roles[0].Name === 'Read-Only Admin') return false;
    let currentTime = moment.tz(moment(), pickup.PickupRequest[0].Clients[0].TimeZone).format('MM/DD/YY hh:mm A');
    let newOrderTime = moment
      .utc(pickup.CreatedOn)
      .clone()
      .tz(pickup.PickupRequest[0].Clients[0].TimeZone)
      .add(15, 'minutes')
      .format('MM/DD/YY hh:mm A')
      .toString();

    if (pickup.Status[0].Title === 'Scheduled') if (moment(newOrderTime).isAfter(currentTime)) return true;
  }
  checkPermission() {
    if (this.user.User.Roles[0].Name === 'Admin' || this.user.User.Roles[0].Name === 'Billing Admin') return false;
    else return true
  }
  EditShipmentId(pickup) {
    if (pickup.ShippingID) return true;
  }
  navigateToRoadies(pickup) {
    if (this.app_env === 'prod') {
      var roadiesURL = 'https://my.roadie.com/send/' + pickup['ShippingID'];
    } else if (this.app_env !== 'prod') {
      var roadiesURL = 'https://my-sandbox.roadie.com/send/' + pickup['ShippingID'];;
    }
    window.open(roadiesURL, '_blank').focus();

  }
  shipmentUpdate(pickupdata) {
    const dialogRef = this.dialog.open(EditShipmentDialogComponent, {
      width: '650px',
      // height: '785px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: pickupdata,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pickupDetails();
      }
    });
  }

  openImage(index, images) {
    let photos = [];
    for (let i in images) {
      if (images[i].Type == 'image') {
        photos.push({src: images[i].ImageURL});
      }
    }
    this.lightbox.open(photos, index);
  }
}

export interface PeriodicElement {
  itemCategory: string;
  itemName: string;
  itemQuantity: string;
  itemCost: string;
  taxDeduction: string;
  totalWeight: string;
  totalCost: string;
}
