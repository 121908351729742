import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { SuccessfulMenuDialogComponent } from '../successful-menu-dialog/successful-menu-dialog.component';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { UnsuccessfulMenuDialogComponent } from '../unsuccessful-menu-dialog/unsuccessful-menu-dialog.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AppState } from 'src/app/shared/app-state';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';

@Component({
  selector: 'app-import-menu-dialog',
  templateUrl: './import-menu-dialog.component.html',
  styleUrls: ['./import-menu-dialog.component.scss'],
})
export class ImportMenuDialogComponent implements OnInit {
  radioOptions = 'option2';
  existingadmin: boolean;
  existingadminno = true;
  modalclose: string;
  isPagination = true;
  categories: any;
  categoryId: any;
  categoryName: any;
  errorcount = 0;
  successCount = 0;
  failuerCount = 0;
  clientId: any;
  successData: any;
  importFile: any;
  buttonDisabled: boolean;
  requestDataDisabled: boolean;
  waitMsg: any;
  csvRecordsData = [];
  relaodinfo: any;
  selectmenus: boolean;
  repeaterror: boolean;
  errorMessageduplicate: boolean;
  newcsvrecordlength: number;
  newData: any;
  stopper = 1;
  dataLength;
  newCSVArray;
  info: any;
  NotificationpickupTypes;
  sendpickupTypes;
  generic_custom;
  spinnerRef: any;
  userIddata;
  infoClients;
  saascreationfinal;
  error;
  clientData;

  constructor(
    public dialog: MatDialog,
    private CustomerService: CustomerService,
    private _rdata: modalDataService,
    private spinnerService: SpinnerService,
    private state: AppState,
    private AdminService: AdmintoolsService,
    private ClientFilterdata: ClientFilterService,
    private sassService: SAASService,
    public dialogRef: MatDialogRef<ImportMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSelectionChange(event) {
    if (event.value === 'option1') {
      this.existingadmin = true;
      this.existingadminno = false;
    } else {
      this.existingadminno = true;
      this.existingadmin = false;
    }
  }
  ngOnInit() {
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
    });
    this.buttonDisabled = false;
    this.successData = this.data;
    this.clientId = this.successData.clientId;
    this.data['importstatus'] = false;

    // console.log(this.data);

    this._rdata.basicInfo2Observe.subscribe((res) => {
      this.info = JSON.parse(res);
      var sendpickupTypes = [];
      for (var i = 0; i < this.info.pickupTypes.length; i++) {
        sendpickupTypes.push({ ID: this.info.pickupTypes[i] });
      }
      this.sendpickupTypes = sendpickupTypes;

      var NotificationpickupTypes = [];
      for (var i = 0; i < this.info.NotificationTypes.length; i++) {
        NotificationpickupTypes.push({ ID: this.info.NotificationTypes[i] });
      }
      this.NotificationpickupTypes = NotificationpickupTypes;
      if (this.info.facility_type === 'Facility' || this.info.facility_type === 'SAAS Facility') {
        this.generic_custom = true;
      }
    });

    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.infoClients = JSON.parse(res);
    });
  }
  openTemplate() {}
  deleteFile(inputFile) {
    inputFile.value = '';
    // document.getElementById(inputFile).innerHTML = document.getElementById(
    //   inputFile
    // ).innerHTML;
  }

  //READ-CSV-FILE

  title = 'app';
  public csvRecords: any[] = [];

  // @ViewChild('fileImportInput') fileImportInput: any;
  // @ViewChild('fileImportInput', { static: true }) fileImportInput: any;

  fileChangeListener($event: any): void {
    this.importFile = $event;
    document.getElementById('deleteImage').style.display = 'block';
  }

  showCount(totalCount) {
    // alert(this.successCount);
    this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    this.spinnerService.stop(this.spinnerRef);
    if (this.successCount > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef.close(this.modalclose);
      const dialogRef = this.dialog.open(SuccessfulMenuDialogComponent, {
        width: '750px',
        // height: '550px',
        panelClass: 'custom-Filterdialog-container',
        disableClose: true,
        data: {
          successCount: this.successCount,
          failuerCount: this.failuerCount,
          totalCount: totalCount,
          clientId: this.clientId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef.close(this.modalclose);
      const dialogRef = this.dialog.open(SuccessfulMenuDialogComponent, {
        width: '750px',
        panelClass: 'custom-Filterdialog-container',
        disableClose: true,
        data: {
          successCount: this.successCount,
          failuerCount: this.failuerCount,
          totalCount: totalCount,
          clientId: this.clientId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let dataArr = [];
    this.csvRecordsData = [];
    this.failuerCount = 0;

    csvRecordsArray = csvRecordsArray.filter((element, index) => {
      if (element) {
        var csvDataElements: any;
        csvDataElements = element.split(',');

        if (csvDataElements[0] && csvDataElements[1] && csvDataElements[2] && csvDataElements[3]) {
          if (index == 0) {
            return element;
          } else {
            if (!isNaN(csvDataElements[2]) && !isNaN(csvDataElements[3])) {
              if (Number(csvDataElements[2]) >= 0 && Number(csvDataElements[2]) >= 0) {
                return element;
              } else {
                this.failuerCount = this.failuerCount + 1;
              }
            } else {
              this.failuerCount = this.failuerCount + 1;
            }
          }
        } else {
          this.failuerCount = this.failuerCount + 1;
        }
      }
    });
    this.newCSVArray = csvRecordsArray;

    if (csvRecordsArray.length > 0) {
      for (let i = 1; i < csvRecordsArray.length - 1; i++) {
        const data = csvRecordsArray[i].split(',');
        // this.newData = data;
        data.map((category) => {});
        if (!data[0] || !data[1]) {
          // console.log(data);
          alert('category name and product name can not be blank');
          this.csvRecordsData.push(i);
          break;
        }
      }
      let checkdata = [];
      let checkdatarep = [];
      let m = 0;
      if (this.csvRecordsData.length === 0) {
        if (csvRecordsArray[1] === '') {
          alert('CSV file contains no records..');
          this.requestDataDisabled = false;
        } else {
          csvRecordsArray.forEach((element) => {
            // checkdata.push(element.split(',')[0])
            // var n = checkdata.includes("Mango");
            if (!checkdata.includes(element.split(',')[0])) {
              checkdata.push(element.split(',')[0]);
            } else {
              // console.log('error');
              checkdatarep.push(element.split(',')[0]);
            }
          });
          // if(checkdata.length === checkdatarep.length){

          // } else {
          //   this.repeaterror = true;
          // }
          let repeaterrorda;
          checkdata.length == checkdatarep.length ? ' ' : (this.repeaterror = true);
          checkdatarep.length == 0 ? ' ' : (repeaterrorda = true);
          // if (repeaterrorda === true) {
          //   this.errorMessageduplicate = true;
          //   setTimeout(() => {
          //     this.errorMessageduplicate = false;
          //   }, 4000);
          // } else {
          let menutype;
          menutype = 'Custom';
          // if(menutype !== 'Custom'){
          this.CustomerService.getMenuTypes().subscribe((data) => {
            const menus = data.Result;
            let menuId;
            for (const menu of menus) {
              if (menu.Title === menutype) {
                menuId = menu.ID;
              }
            }

            // console.log(this.clientId);
            this.CustomerService.getCustomerDetail(this.clientId).subscribe((dataget) => {
              let payloadassign = Object.assign({}, dataget.Result[0]);
              payloadassign.MenuTypeId = menuId;
              this.CustomerService.updateCustomerDetails(payloadassign).subscribe((data) => {
                // console.log(data);
                // (<HTMLInputElement>document.getElementById('importMenuFile')).disabled = true;
                // document.getElementById('deleteImage').style.display = 'none';
                // document.getElementById('waitMsg').style.display = 'block';
                this.requestDataDisabled = true;
                // this.CustomerService.getCategoriesofcsv(
                //   this.isPagination
                // ).subscribe(data => {
                //   this.categories = data['Result'];
                if (this.newCSVArray.length > 1) {
                  this.loopCSVfile();
                } else {
                  this.showCount(this.newcsvrecordlength - 2);
                }

                this.relaodinfo.reloadData = true;
                this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
                // });
              });
            });
          });
          // }
        }
        return dataArr;
      }
    }
  }

  async loopCSVfile() {
    if (this.newcsvrecordlength > 2) {
      if (this.newCSVArray[this.stopper]) {
        this.newData = this.newCSVArray[this.stopper].split(',');
        if (this.stopper < this.dataLength - 1) {
          let payload = {
            Name: this.newData[0],
            Price: this.newData[2],
            Weight: this.newData[3],
            Description: this.newData[0],
            DirectCost: 1,
            IsGoodrProduct: false,
            ApprovalStatusId: 2,
            WP_Product_Id: null,
            CreatedBy: null,
            CreatedOn: null,
            ModifiedBy: null,
            ModifiedOn: null,
            ProductSections: [
              {
                Name: this.newData[1].replace(/^\s+/g, ''),
              },
            ],
            Clients: [
              {
                ClientId: this.clientId,
              },
            ],
          };
          this.CustomerService.addNewFoodItem(payload).subscribe((data) => {
            if (data['message'] == 'Successfully Added') {
              this.successCount = this.successCount + 1;
            }

            if (data['message'] == 'Duplicate Entry') {
              this.failuerCount = this.failuerCount + 1;
            }

            if (this.successCount + this.failuerCount === this.newcsvrecordlength - 2) {
              this.showCount(this.newcsvrecordlength - 2);
            } else {
              this.stopper = this.stopper + 1;
              setTimeout(() => {
                this.loopCSVfile();
              }, 1000);
            }
          });
        }
      } else {
        this.failuerCount = this.failuerCount + 1;
        if (this.successCount + this.failuerCount === this.newcsvrecordlength - 2) {
          this.showCount(this.newcsvrecordlength - 2);
        } else {
          this.stopper = this.stopper + 1;
          setTimeout(() => {
            this.loopCSVfile();
          }, 1000);
        }
      }
    } else {
      this.showCount(this.newcsvrecordlength - 2);
    }
  }
  // CHECK IF FILE IS A VALID CSV FILE
  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  // GET CSV FILE HEADER COLUMNS
  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    // this.fileImportInput.nativeElement.value = "";
    this.csvRecords = [];
  }

  startConversion() {
    if (this.importFile !== undefined && this.importFile !== null && this.importFile.srcElement.files[0]) {
      if ((this.data['clientId'] = 'new')) {
        // console.log('Custom');
        this.spinnerRef = this.spinnerService.start();
        let payload = {
          Name: this.info.accountName,
          EINNumber: this.info.taxId,
          Web: '',
          Address: this.info.businessAddress,
          PickupType: this.sendpickupTypes,
          Address2: this.info.address,
          // City: this.info.city,
          // State: this.info.state,
          CityId: this.info.city,
          StateId: this.info.state,
          ZIP: this.info.zip,
          Country: this.info.country,
          Phone: this.info.PhoneNumber.replace(/[_-]/g, ''),
          Email: this.info.email,
          HasFridgeStorage: false,
          NumberOfPeopleServed: 1,
          WasteExpenditure: false,
          ClientTypeId: 1,
          ApprovalStatusId: 2,
          WayPointID: null,
          WP_Client_Id: 0,
          MenuEdit: this.info.isMenuEdit,
          TimeZone: this.info.timezone,
          InviteUser: this.info.facilityAccess,
          MenuTypeId: this.data['menuId'],
          ClientCategories: [
            {
              ClientCategoryId: this.info.account_type,
            },
          ],
          DriverServices: [
            {
              ID: this.info.driverService,
            },
          ],
          DriverNotes: this.info.driverNotes,
          User_Client: [],
          NotificationTypes: this.NotificationpickupTypes,
          SAASDriverId: this.info.selectedDriver,
          FacilitatorId: this.info.FacilitatorId,
          FacilityGroupId: this.info.FacilityGroupId,
          AllowSchedulePickup: this.info.scheduler,
        };
        this.buttonDisabled = true;
        this.CustomerService.createAccount(payload).subscribe((data) => {
          // this.spinnerService.stop(this.spinnerRef);
          let localUser = JSON.parse(localStorage.getItem('user'));
          localUser.user.User.Clients.push(data['Result'][0]);
          localStorage.setItem('user', JSON.stringify(localUser));
          this.state.user = JSON.parse(localStorage.getItem('user')).user;
          this.infoClients.facilitator = '';
          this.infoClients.facilitatorGroup = '';
          this.infoClients.facility = '';
          this.infoClients.nonprofits = '';
          this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.infoClients));

          if (this.clientData) {
            if (this.state.user.User.Roles[0].Name === 'Facility Group') {
              if (this.clientData.StripePlanId) {
                if (this.info.facility_type === 'SAAS Facility') {
                  this.saascreationfinal = true;
                  const payloadStripe = {
                    QuantityChange: true,
                    Update: true,
                    ClientId: this.info.FacilityGroupId,
                    UserId: this.state.user.User.UserId,
                  };
                  this.state.user = JSON.parse(localStorage.getItem('user')).user;
                  this.sassService.striping(payloadStripe).subscribe((data) => {});
                  // }
                }
                // if (this.driverService) {
                //   for (const status of this.driverService) {
                //     if (this.info.driverService === status.ID) {
                //       if (status.Title === 'SAAS') {
                //         this.saascreationfinal = true;
                //         const payloadStripe = {
                //           QuantityChange: true,
                //           Update: true,
                //           ClientId: this.info.FacilityGroupId,
                //           UserId: this.state.user.User.UserId
                //         };
                //         this.state.user = JSON.parse(
                //           localStorage.getItem('user')
                //         ).user;
                //         this.sassService
                //           .striping(payloadStripe)
                //           .subscribe(data => {});
                //       }
                //     }
                //   }
                // }
              }
            }
          }
          this.relaodinfo.reloadclientdata = true;
          this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
          this.clientId = data['Result'][0].ClientId;
          const payload1 = {
            FirstName: this.info.adminFirstName,
            LastName: this.info.adminLastName,
            Email: this.info.adminEmail,
            OnClientCreation: true,
            PhoneNumber: this.info.Phone.replace(/[_-]/g, ''),
            Clients: [
              {
                ClientId: this.clientId,
              },
            ],
            RoleId: this.info.roleId,
          };

          this.AdminService.createUser(payload1).subscribe((response) => {
            if ('error' in response) {
              this.spinnerService.stop(this.spinnerRef);
              this.buttonDisabled = false;
              this.error = response['message'];
            } else {
              // this.dialogRef.close();
              this.userIddata = response['UserId'];
              if (this.saascreationfinal === true && this.info.FacilityGroupId) {
                const payloadStripe = {
                  QuantityChange: true,
                  Update: true,
                  ClientId: this.info.FacilityGroupId,
                  // UserId: this.userIddata
                };
                this.sassService.striping(payloadStripe).subscribe((data) => {});
              }

              // if (type === 'Custom') {
              //   if (this.saascreationfinal !== true) {
              //     this.gotoImportMenuDialog(this.clientId);
              //   } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
              //     this.gotoImportMenuDialog(this.clientId);
              //   }
              // } else if (type === 'Generic') {
              //   this.buttonDisabled = true;
              //   if (this.userRole === 'Facility Group') {
              //     this.accountcompletion(this.clientId, this.info.facility_type);
              //   } else if (this.saascreationfinal !== true) {
              //     this.accountcompletion(this.clientId, this.info.facility_type);
              //   } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
              //     this.accountcompletion(this.clientId, this.info.facility_type);
              //   }
              // } else {
              //   this.buttonDisabled = true;

              //   if (this.userRole === 'Facility Group') {
              //     this.accountcompletion(this.clientId, this.info.facility_type);
              //   } else if (this.saascreationfinal === true && this.info.FacilityGroupId) {
              //     this.accountcompletion(this.clientId, this.info.facility_type);
              //   } else {
              //     this.accountcompletion(this.clientId, this.info.facility_type);
              //   }
              // }
              // this.state.isSpinnerVisible = false;

              let text = [];
              let files = this.importFile.srcElement.files;
              // console.log('Menu Creation');

              if (this.isCSVFile(files[0])) {
                let input = this.importFile.target;
                let reader = new FileReader();
                reader.readAsText(input.files[0]);
                reader.onload = () => {
                  let csvData = reader.result;
                  let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
                  this.newCSVArray = csvRecordsArray;

                  this.dataLength = csvRecordsArray.length;
                  this.newcsvrecordlength = csvRecordsArray.length;
                  let headersRow = this.getHeaderArray(csvRecordsArray);
                  this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
                };
                reader.onerror = function () {
                  alert('Unable to read ' + input.files[0]);
                };
              } else {
                alert('Please import valid .csv file.');
                this.fileReset();
              }
            }
          });
          // this._rdata.resetBasicInfo2(JSON.stringify(this.info));
        });
        this._rdata.updateBasicInfo2(JSON.stringify(this.info));
      } else {
        let text = [];
        let files = this.importFile.srcElement.files;
        if (this.isCSVFile(files[0])) {
          let input = this.importFile.target;
          let reader = new FileReader();
          reader.readAsText(input.files[0]);
          reader.onload = () => {
            let csvData = reader.result;
            let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
            this.newCSVArray = csvRecordsArray;

            this.dataLength = csvRecordsArray.length;
            this.newcsvrecordlength = csvRecordsArray.length;
            let headersRow = this.getHeaderArray(csvRecordsArray);
            this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
          };
          reader.onerror = function () {
            alert('Unable to read ' + input.files[0]);
          };
        } else {
          alert('Please import valid .csv file.');
          this.fileReset();
        }
        //   }
        // );
        //     }
        //   );
        // });
        // this.requestDataDisabled = true;
      }
    } else {
      this.selectmenus = true;
      setTimeout(() => {
        this.selectmenus = false;
      }, 3000);
    }
  }
  // };
}

export class CSVRecord {
  public category: any;
  public product: any;
  public price: any;
  public weight: any;

  constructor() {}
}
