import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-profile-setting-view',
  templateUrl: './profile-setting-view.component.html',
  styleUrls: ['./profile-setting-view.component.scss']
})
export class CustomerProfileSettingsComponent implements OnInit {
  constructor() { }
  dataSource = new MatTableDataSource<PeriodicElements>(ELEMENT_DATA);
  displayedColumns: string[] = ['AccountName', 'AccountType', 'Role', 'Date','Delete', 'Time'];
  ngOnInit() {
  }
}

export interface PeriodicElements {
  AccountName: string;
  AccountType: string;
  Role: string;
  Date: any;
  Time: any;
  Delete:any;

}
const ELEMENT_DATA: PeriodicElements[] = [
  { AccountName: 'Michaels Pizza', AccountType: 'Facility Group', Role: 'Admin', Date: '',Delete:'', Time: '' },
  { AccountName: 'Michaels Pizza', AccountType: 'Facility Group', Role: 'Admin', Date: '', Delete:'', Time: '' }
]
