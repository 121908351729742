export const environment = {
  production: false,
  app_env: 'qa',
  environmentUrl: 'https://gdr-qa-api-3-0.azurewebsites.net/api/',
  stripePublicKey: 'pk_test_o5BzzrjWgMPciolhe5OBI74G00ac5yC0k5',
  monthlyKey: 'plan_I7hyr5Nu3WMLbQ',
  yearlyKey: 'plan_I7hCXZP2V4MCcX',
  onetimeKey: 'price_1H9TliICi4RDu2OlptqmWzIa',
  gaTrackingId: 'UA-199638860-1',
  merck_id: 372

};
