import { Component, OnInit, Inject, SystemJsNgModuleLoaderConfig } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AppState } from 'src/app/shared/app-state';
import { InviteUserSuccessDialogComponent } from '../../usersAdminView/invite-user-success-dialog/invite-user-success-dialog.component';
import { FormControl } from '@angular/forms';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'app-inviteuserdialog',
  templateUrl: './inviteuserdialog.component.html',
  styleUrls: ['./inviteuserdialog.component.scss'],
})
export class InviteuserdialogComponent implements OnInit {
  public numberForm: FormGroup;
  radioOptions = 'option 2';
  selectedAccountType: any;
  allDeliveryStatus: any;
  allClientsData: any;
  userEmail: string;
  userLastName: any;
  userFirstName: any;
  requirednumberFirst: boolean;
  invalidEmail: boolean;
  requireField: boolean;
  selectedType: any;
  invalidemail: boolean;
  userPhoneno: any;
  requirednumber: boolean;
  selectClient: any[];
  clientsControl = new FormControl();
  disabledclients: boolean;
  clientrequireField: boolean;
  facilityGroupClients: any;
  facilitatorClients: any;
  FacilityClients: any;
  nonprofitsClients: any;
  facility: boolean;
  non_Profit: boolean;
  driver_service: boolean;
  facilitator: boolean;
  facility_Group: boolean;
  driverService: any;
  driverSelected: any;
  selectnonprofitClient: any;
  clientrequiredField: boolean;
  disabledFacilitator: boolean;
  facilitatorRole: any;
  facilitatorRoleClient: boolean;
  facilitatorRoleClientData: any;
  selectFacilitatorClient: boolean;
  info: any;
  optiondriverStatus: boolean;
  selectedDriver: any;
  serverError: boolean;
  IsSAASDriver: boolean;
  selectedSGDriver: any;
  buttonDisabled: boolean;
  spinnerRef: any;
  getClients: any;
  requireLast: boolean;
  requiredEmail: boolean;
  requiredNumber: boolean;
  requireInvalid: boolean;
  pickupstatesdata: any;
  citydataID: any;
  admincity: any;
  adminstate: any;
  requireFieldCity: boolean;
  requireFieldState: boolean;
  driver_serviceGDR: boolean;
  featureinfo: any;
  SAASFeature: boolean;
  constructor(
    private ClientFilterdata: ClientFilterService,
    private AdminService: AdmintoolsService,
    private spinnerService: SpinnerService,
    private customerServices: CustomerService,
    public dialog: MatDialog,
    private sassService: SAASService,
    private state: AppState,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InviteuserdialogComponent>,
    public capitailizepipe: specialPipe,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            this.SAASFeature = false;
          }
        }
        // if (this.featureinfo.featureData[1].Title === 'SAAS') {
        //   if (this.featureinfo.featureData[1].EnableFeature === false) {
        //     this.SAASFeature = false;
        //   }
        // }
      }
      // }
    });
    this.ClientFilterdata.basicCityInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      this.pickupstatesdata = this.info.statedata;
    });
    this.getuserdata();
    this.driverSelected = 'Goodr';

    this.numberForm = this.formBuilder.group({
      number: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
    });
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      this.getClients = this.info.getallClients;
      if (this.info.facility.length > 0) {
        this.FacilityClients = this.info.facility.filter((value) => value.ApprovalStatus === 'Active');
        // this.spinnerService.stop(this.spinnerRef);
      }
      if (this.state.user.User.Roles[0].Name === 'Facility Group') {
        if (this.info.facilitatorGroup.length > 0) {
          this.facilityGroupClients = this.info.facilitatorGroup.filter((value) => value.ApprovalStatus[0].Title === 'Active');
        }
      } else {
        this.customerServices.getnoSAASFGList().subscribe((data) => {
          this.facilityGroupClients = data.Result.filter((value) => value.ApprovalStatus === 'Active');
        });
      }
      if (this.info.facilitator.length > 0) {
        if (this.state.user.User.Roles[0].Name === 'Facilitator') {
          this.facilitatorClients = this.info.facilitator.filter((value) => value.ApprovalStatus[0].Title === 'Active');
        } else {
          this.facilitatorClients = this.info.facilitator.filter((value) => value.ApprovalStatus === 'Active');
        }
      }
      if (this.info.nonprofits.length > 0) {
        this.nonprofitsClients = this.info.nonprofits.filter((value) => value.ApprovalStatus === 'Active');
      }
      if (this.getClients === true) {
        if (this.spinnerRef) {
          this.spinnerService.stop(this.spinnerRef);
        }
      }
    });
    if (this.state.user.User.Roles[0].Name === 'Facilitator') {
      this.facilitatorRoleClient = true;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facility = false;
      this.disabledclients = true;
      this.disabledFacilitator = true;
    }
    // if (this.state.user.User.Roles[0].Name === 'Facility') {
    //   this.facilitatorRoleClient = false;
    //   this.facility_Group = false;
    //   this.non_Profit = false;
    //   this.facility = true;
    //   this.disabledclients = true;
    //   this.disabledFacilitator = true;
    // }
    this.customerServices.getPickupTypeList().subscribe(
      (data) => {
        this.driverService = data.Result;
        this.driverService = data.Result.filter(
          (value) => value.Title !== 'Roadie' && value.Title !== 'Postmates' && value.Title !== 'Roadie/Postmates'
        );
        // if (this.SAASFeature === false) {
        //   this.driverService = data.Result.filter(
        //     value =>
        //       value.Title !== 'SAAS' &&
        //       value.Title !== 'Roadie' &&
        //       value.Title !== 'Postmates'
        //   );
        // }
        for (const status of this.driverService) {
          if (this.info.driverService === status.ID) {
            if (status.Title === 'SAAS') {
              this.optiondriverStatus = true;
              this.selectedDriver = this.info.selectedDriver;
            } else {
              this.optiondriverStatus = false;
            }
          }
        }
      },
      (error) => {
        this.state.isSpinnerVisible = false;
      }
    );
    this.AdminService.getAccountType().subscribe(
      (data) => {
        this.allDeliveryStatus = data['Result'];
        if (this.state.user.User.Roles[0].Name === 'Facility') {
          this.allDeliveryStatus = this.allDeliveryStatus.filter(
            (value) =>
              value.Name !== 'Facilitator' &&
              value.Name !== 'Driver' &&
              value.Name !== 'Non Profit' &&
              value.Name !== 'Admin' &&
              value.Name !== 'Read-Only Admin' &&
              value.Name !== 'Billing Admin' &&
              value.Name !== 'Facility Group'
          );
        }
        if (
          this.state.user.User.Roles[0].Name == 'Facility Group' &&
          this.state.user.User.Clients.length > 0 &&
          (this.state.user.User.Clients[0].ClientType === 'SAAS Facility Group' ||
            this.state.user.User.Clients[0].ClientType === 'SAAS Facility')
        ) {
          this.allDeliveryStatus = this.allDeliveryStatus.filter(
            (value) =>
              value.Name !== 'Facilitator' &&
              value.Name !== 'Driver' &&
              value.Name !== 'Non Profit' &&
              value.Name !== 'Admin' &&
              value.Name !== 'Facility Group'
          );
        } else if (this.state.user.User.Roles[0].Name == 'Facility Group') {
          this.allDeliveryStatus = this.allDeliveryStatus.filter(
            (value) => value.Name !== 'Facilitator' && value.Name !== 'Driver' && value.Name !== 'Non Profit' && value.Name !== 'Admin'
          );
        }
        if (this.state.user.User.Roles[0].Name == 'Facilitator') {
          this.allDeliveryStatus = this.allDeliveryStatus.filter(
            (value) => value.Name !== 'Facility Group' && value.Name !== 'Driver' && value.Name !== 'Non Profit' && value.Name !== 'Admin'
          );
        }
        const facilitator = this.allDeliveryStatus.filter((client) => {
          return client.Name === 'Facilitator';
        });
        this.facilitatorRole = facilitator;
        let detail = this.allDeliveryStatus;

        if (this.state.user.User.Roles[0].Name === 'Admin') {
          let deliveryTypeArray = [...this.allDeliveryStatus];
          // console.log(rolesTypeArray);
          this.allDeliveryStatus = [];

          deliveryTypeArray.forEach((element) => {
            if (element.Name === 'Facility') {
              this.allDeliveryStatus[0] = element;
            } else if (element.Name === 'Facility Group') {
              this.allDeliveryStatus[1] = element;
            } else if (element.Name === 'Facilitator') {
              this.allDeliveryStatus[2] = element;
            } else if (element.Name === 'Non Profit') {
              this.allDeliveryStatus[3] = element;
            } else if (element.Name === 'Admin') {
              this.allDeliveryStatus[4] = element;
            } else if (element.Name === 'Driver') {
              this.allDeliveryStatus[5] = element;
            } else if (element.Name === 'Read-Only Admin') {
              this.allDeliveryStatus[6] = element;
            } else {
            }
          });
        }
        if (this.state.user.User.Roles[0].Name === 'Billing Admin') {
          let deliveryTypeArray = [...this.allDeliveryStatus];
          // console.log(rolesTypeArray);
          this.allDeliveryStatus = [];

          deliveryTypeArray.forEach((element) => {
            if (element.Name === 'Facility') {
              this.allDeliveryStatus[0] = element;
            } else if (element.Name === 'Facility Group') {
              this.allDeliveryStatus[1] = element;
            } else if (element.Name === 'Facilitator') {
              this.allDeliveryStatus[2] = element;
            } else if (element.Name === 'Non Profit') {
              this.allDeliveryStatus[3] = element;
            } else if (element.Name === 'Admin') {
              this.allDeliveryStatus[4] = element;
            } else if (element.Name === 'Driver') {
              this.allDeliveryStatus[5] = element;
            } else if (element.Name === 'Read-Only Admin') {
              this.allDeliveryStatus[6] = element;
            }  else if (element.Name === 'Billing Admin') {
              this.allDeliveryStatus[7] = element;
            }
          });
        }
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );

    // get clients
    this.allClientsData = this.state.user.User['Clients'];
    this.selectClient = [];
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  getuserdata() {
    this.AdminService.getUsers(1, 1).subscribe((data) => {
      this.facilitatorRoleClientData = data.Result[0].Clients;
      this.selectedType = [];
      for (var i = 0; i < this.facilitatorRoleClientData.length; i++) {
        this.selectedType.push(this.facilitatorRoleClientData[i].ClientId);
      }
    });
  }
  UpdateAccountType(value, event) {
    this.selectedAccountType = value.Name;
    this.selectClient[0] = undefined;
    if (this.selectedAccountType === 'Admin' || this.selectedAccountType === 'Billing Admin') {
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
    }
    if (this.selectedAccountType === 'Driver') {
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = true;
      this.driver_serviceGDR = true;
    }
    if (this.selectedAccountType === 'Facility Group') {
      if (!this.getClients === true) {
        this.spinnerRef = this.spinnerService.start();
      }
      this.facility_Group = true;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.disabledclients = false;
      // if ( (this.info.facilitatorGroup.length === 0)) {
      //   this.spinnerRef = this.spinnerService.start();
      // }
    }
    if (this.selectedAccountType === 'Non Profit') {
      if (!this.getClients === true) {
        this.spinnerRef = this.spinnerService.start();
      }
      this.non_Profit = true;
      this.facility_Group = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.disabledclients = false;
      // if ( (this.info.nonprofits.length === 0)) {
      //   this.spinnerRef = this.spinnerService.start();
      // }
    }
    if (this.selectedAccountType === 'Facilitator') {
      if (!this.getClients === true) {
        this.spinnerRef = this.spinnerService.start();
      }
      this.facilitator = true;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.disabledclients = false;
      // if ((this.info.facilitator.length === 0)) {
      //   this.spinnerRef = this.spinnerService.start();
      // }
    }
    if (this.selectedAccountType === 'Facility') {
      if (!this.getClients === true) {
        this.spinnerRef = this.spinnerService.start();
      }
      this.facility = true;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.disabledclients = false;
      // if ((this.info.facilitator.length === 0)) {
      //   this.spinnerRef = this.spinnerService.start();
      // }
    }
    if (this.selectedType !== undefined) {
      // this.editbusinessdetails();
    } else {
      // this.invalidemail = true;
    }
  }
  onSelectionChange() {
    this.driver_serviceGDR = true;
  }
  SelectClient(value, event) { }

  selectAccountType() { }

  selectedstate() {
    this.sassService.pickupcitydetails(this.adminstate).subscribe((data) => {
      this.citydataID = data['Result'];
      this.admincity = 'Select City';
      // for (var i = 0; i < this.citydataID.length; i++) {
      //   if (this.citydataID[i].ID === this.admincity) {
      //     this.admincity = this.citydataID[i].ID;
      //     // this.city = this.citydataID[i].Title;
      //   }
      // }
    });
  }
  //email-validation
  validateEmail() {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.userEmail) == false) {
      return false;
    }
    return true;
  }
  isvalid() {
    this.userFirstName = this.userFirstName.trim();
    this.userLastName = this.userLastName.trim();
    this.userEmail = this.userEmail.trim();
    this.userPhoneno = this.userPhoneno.trim();
    // if (this.userFirstName !== undefined) {
    if (this.userFirstName !== undefined && this.userFirstName && this.userFirstName !== null) {
      // if (this.userLastName !== undefined) {
      if (this.userLastName !== undefined && this.userLastName && this.userLastName !== null) {
        if (this.userEmail !== undefined) {
          if (this.userPhoneno !== undefined) {
            this.numberForm.controls.number.setValue(numberSetMask(this.userPhoneno));
            if (this.numberForm.valid) {
              if (this.validateEmail() === true) {
                if (this.state.user.User.Roles[0].Name === 'Facilitator') {
                  if (this.selectClient[0] === undefined) {
                    this.selectFacilitatorClient = true;
                    setTimeout(() => {
                      this.selectFacilitatorClient = false;
                    }, 3000);
                  } else {
                    this.createUser();
                  }
                } else if (this.selectedAccountType !== undefined) {
                  if (this.selectedAccountType) {
                    if (this.selectedAccountType === 'Driver') {
                      if (this.adminstate !== 'Select State' && this.adminstate !== undefined && this.adminstate !== null && this.adminstate) {
                        if (this.admincity !== 'Select City' && this.admincity !== undefined && this.admincity !== null && this.admincity) {
                          this.createUser();
                        } else {
                          this.requireFieldCity = true;
                          setTimeout(() => {
                            this.requireFieldCity = false;
                          }, 3000);
                        }
                      } else {
                        this.requireFieldState = true;
                        setTimeout(() => {
                          this.requireFieldState = false;
                        }, 3000);
                      }

                      // this.createUser();
                    }
                    if (this.selectedAccountType === 'Admin' || this.selectedAccountType === 'Billing Admin' || this.selectedAccountType === 'Read-Only Admin') {
                      this.createUser();
                    }

                    if (this.selectedAccountType === 'Facility' || 'Facilitator' || 'Facility Group') {
                      if (this.selectClient[0] === undefined) {
                        this.clientrequireField = true;
                        setTimeout(() => {
                          this.clientrequireField = false;
                        }, 3000);
                      } else {
                        this.createUser();
                      }
                    }
                    if (this.selectedAccountType === 'Non Profit') {
                      if (this.selectnonprofitClient === undefined) {
                        this.clientrequiredField = true;
                        setTimeout(() => {
                          this.clientrequiredField = false;
                        }, 3000);
                      } else {
                        this.createUser();
                      }
                    } else {
                      // this.createUser();
                    }
                  }
                } else {
                  this.invalidemail = true;
                  setTimeout(() => {
                    this.invalidemail = false;
                  }, 3000);
                }
              } else {
                this.invalidEmail = true;
                setTimeout(() => {
                  this.invalidEmail = false;
                }, 3000);
              }
            } else {
              this.requireInvalid = true;
              setTimeout(() => {
                this.requireInvalid = false;
              }, 3000);
            }
          } else {
            this.requiredNumber = true;
            setTimeout(() => {
              this.requiredNumber = false;
            }, 3000);
          }
        } else {
          this.requiredEmail = true;
          setTimeout(() => {
            this.requiredEmail = false;
          }, 3000);
        }
      } else {
        this.requireLast = true;
        setTimeout(() => {
          this.requireLast = false;
        }, 3000);
      }
    } else {
      this.requirednumberFirst = true;
      setTimeout(() => {
        this.requirednumberFirst = false;
      }, 3000);
    }
  }
  createUser() {
    const selectedClient = [];
    if (this.state.user.User.Roles[0].Name === 'Facilitator') {
      for (var i = 0; i < this.selectClient.length; i++) {
        selectedClient.push({
          ClientId: this.selectClient[i],
        });
      }
      this.selectedType.Id = this.facilitatorRole[0].Id;
    } else if (this.selectedAccountType === 'Facility') {
      for (var i = 0; i < this.selectClient.length; i++) {
        selectedClient.push({
          ClientId: this.selectClient[i],
        });
      }
    } else if (this.selectedAccountType === 'Facilitator') {
      for (var i = 0; i < this.selectClient.length; i++) {
        selectedClient.push({
          ClientId: this.selectClient[i],
        });
      }
    }
    if (this.selectedAccountType === 'Non Profit') {
      selectedClient.push({
        ClientId: this.selectnonprofitClient,
      });
    }
    if (this.selectedAccountType === 'Facility Group') {
      for (var i = 0; i < this.selectClient.length; i++) {
        selectedClient.push({
          ClientId: this.selectClient[i],
        });
      }
    }
    if (this.driverSelected === 'SAAS') {
      this.IsSAASDriver = true;
    } else {
      this.IsSAASDriver = false;
    }
    const payload = {
      FirstName: this.capitailizepipe.transform(this.userFirstName),
      LastName: this.capitailizepipe.transform(this.userLastName),
      Email: this.userEmail,
      PhoneNumber: this.userPhoneno.replace(/[_-]/g, ''),
      Clients: selectedClient,
      RoleId: this.selectedType.Id,
      OnClientCreation: false,
      IsSAASDriver: this.IsSAASDriver,
      StateId: this.adminstate,
      CityId: this.admincity,
    };
    this.buttonDisabled = true;

    this.AdminService.createUser(payload).subscribe((response) => {
      if ('error' in response) {
        this.buttonDisabled = false;
        this.serverError = true;
        setTimeout(() => {
          this.serverError = false;
        }, 3000);
      } else {
        this.dialogRef.close();
        this.state.isSpinnerVisible = false;
        const dialogRef = this.dialog.open(InviteUserSuccessDialogComponent, {
          width: '700px',
          // height: "680px",
          panelClass: 'custom-FilterInvitedialog-container',
          disableClose: true,
          data: {},
        });
        const res = 'res';
        this.dialogRef.close(res);
      }
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.numberForm.controls[controlName].hasError(errorName);
  };
}
