import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ChangeeditSusbscriptionComponent } from '../changeedit-susbscription/changeedit-susbscription.component';
import { MatDialog } from '@angular/material';
import { AppState } from 'src/app/shared/app-state';
import { SuccesPopupComponent } from '../succes-popup/succes-popup.component';
import { DeletePopupComponent } from '../billing/delete-popup/delete-popup.component';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { environment } from 'src/environments/environment';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';

@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.scss']
})
export class EditSubscriptionComponent implements OnInit {
  address: string;
  address1: string;
  city: string;
  stateCode: string;
  mobile: string;
  email: string;
  clientData: any;
  zip: any;
  country = 'USA';
  requireZipCode: boolean;
  requireCountry: boolean;
  requireCity: boolean;
  requireState: boolean;
  requireAddress1: boolean;
  requireTaxID: boolean;
  invalidEmail: boolean;
  requireEmail: boolean;
  dataerror: string;
  user: any;
  UserId: any;
  ClientId: any;
  dissuccess = true;
  yearlyPlan: boolean;
  noPlaning: boolean;
  multipleFGdata: any;
  faclityCount: any;
  changePlanning: boolean;
  sassinfo: any;
  sassinfodata: any;
  citydataID: any;
  pickupstatesdata: any;
  inactiveState: boolean;
  notactive: boolean;
  admintrue: boolean;
  monthlyPrice: any;
  yearlyPrice: any;
  couponValid: boolean;
  discountamount: any;
  totalcoupnValue: number;
  discouopnId: any;

  constructor(
    private pricingModule: PricingModuleServiceService,
    private router: Router,
    private sassService: SAASService,
    private promotionCode: PricingPromotionCodeService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private state: AppState,
    private customerService: CustomerService,
    private ClientFilterdata: ClientFilterService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.state.user = JSON.parse(localStorage.getItem('user')).user;
    this.user = this.state.user;
    this.UserId = this.user.User.UserId;
    if (this.user.User.Roles[0].Name === 'Admin') {
      this.admintrue = true;
    }
    // this.ClientId = this.user.User.Clients[0].ClientId;
    this.ClientId = this.route.snapshot.params.id;
    this.getById();
    this.ClientFilterdata.basicInfosassObserve.subscribe(res => {
      this.sassinfo = JSON.parse(res);
      this.sassinfodata = this.sassinfo.subscription;
    });
  }
  goToProfile() {
    if (this.admintrue === true) {
      if (this.clientData.ClientCategory[0].Title === 'SAAS Facility') {
        this.router.navigate(['FacilityDetails/' + this.ClientId + '']);
      } else {
        this.router.navigate(['CustomerFacilitatorview/' + this.ClientId + '']);
      }
    } else {
      this.router.navigate(['profile']);
    }
  }
  getById() {
    this.sassService.getSubDetails(this.ClientId).subscribe(data => {
      if (data.Result[0].StripeCouponId) {
        this.discouopnId = data.Result[0].StripeCouponId;
        this.couponValid = true;
      }
      if (data.Result[0].StripePlanId) {
        this.sassinfodata = true;
      } else {
        this.sassinfodata = false;
      }
      this.sassinfo.subscription = this.sassinfodata;
      this.ClientFilterdata.updateBasicSAASInfo(JSON.stringify(this.sassinfo));
      if (this.user.User.Roles[0].Name === 'Facility') {
        if (
          this.user.User.Clients[0].DriverService === 'SAAS' &&
          this.user.User.Clients[0].StripeCustomerId
        ) {
          this.multipleFGdata = data.Result;
        }
      }
      this.clientData = data.Result[0];
      if (this.clientData.ClientCategory[0].Title === 'SAAS Facility') {
        this.multipleFGdata = data.Result;
      }
      this.address = this.clientData.Address;
      this.address1 = this.clientData.Address2;
      this.pickupstates();
      this.sassService
        .pickupcitydetails(this.clientData.StateId)
        .subscribe(data => {
          this.citydataID = data['Result'];
          for (var i = 0; i < this.citydataID.length; i++) {
            if (this.citydataID[i].ID == this.clientData.CityId) {
              this.city = this.citydataID[i].ID;
            }
          }
        });
      this.mobile = this.clientData.Phone;
      this.email = this.clientData.Email;
      this.zip = this.clientData.ZIP;
      this.country = 'USA';
      this.clientData.Country = 'USA';
      this.pricingModule
        .getsubscriptionSAAS(this.clientData.StripePlanId)
        .subscribe(res => {
          if (this.clientData.StripePlanId) {
            this.noPlaning = false;
            let stripeData;
            stripeData = res['Result'];
            for (const status of stripeData) {
              if (this.clientData.StripePlanId === status.StripePlanId) {
                if (status.SubscriptionType === 'month') {
                  this.monthlyPrice = status.Price;
                  this.yearlyPlan = false;
                } else {
                  this.yearlyPrice = status.Price;
                  this.yearlyPlan = true;
                }
              }
            }
          } else {
            this.noPlaning = true;
          }
          // })
          //   }
          // );
          this.customerService
            .getAllFilteredCustomers(
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              this.route.snapshot.params.id,
              null,
              null,
              null,
              null
            )
            .subscribe(resp => {
              if (resp.Result.length > 0) {
                resp.Result = resp.Result.filter(
                  value => value.ApprovalStatus === 'Active'
                );
                this.faclityCount = resp.Result.length;
                if (this.user.User.Roles[0].Name === 'Admin') {
                  this.multipleFGdata = resp.Result;
                }
                if (this.user.User.Roles[0].Name === 'Facility Group') {
                  if (
                    this.user.User.Clients[0].DriverService === 'SAAS'
                  ) {
                    this.multipleFGdata = resp.Result;
                  }
                }
              } else {
                this.faclityCount = 1;
              }
              if (this.couponValid === true) {
                this.promotionCode
                  .getPromotionCodeBypromoId(this.discouopnId)
                  .subscribe(data => {
                    this.discountamount = data.Result[0].DiscountAmount;
                    if (this.yearlyPlan === true && this.faclityCount) {
                      this.totalcoupnValue =
                        this.faclityCount * this.yearlyPrice -
                        this.discountamount;
                    } else {
                      this.totalcoupnValue =
                        this.faclityCount * this.monthlyPrice -
                        this.discountamount;
                    }
                  });
              }
            });
        });
    });
  }
  selectedstate() {
    this.sassService.pickupcitydetails(this.stateCode).subscribe(data => {
      this.citydataID = data['Result'];
      this.city = '';
    });
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe(data => {
      this.pickupstatesdata = data['Result'];
      // this.stateCode = this.clientData.State;
      for (var i = 0; i < this.pickupstatesdata.length; i++) {
        if (this.pickupstatesdata[i].ID == this.clientData.StateId) {
          this.stateCode = this.pickupstatesdata[i].ID;
        }
      }
    });
  }
  // email-validation
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.email) === false) {
      return false;
    }
    return true;
  }
  goToSucesscheckout() {}
  UPdateBillingAddress() {
    if (this.email && this.email !== undefined && this.email !== null) {
      if (this.validateEmail() === true) {
        if (
          this.address &&
          this.address !== undefined &&
          this.address !== null
        ) {
          if (
            this.stateCode &&
            this.stateCode !== undefined &&
            this.stateCode !== null
          ) {
            if (this.city && this.city !== undefined && this.city !== null) {
              if (this.zip && this.zip !== undefined && this.zip !== null) {
                if (
                  this.country &&
                  this.country !== undefined &&
                  this.country !== null
                ) {
                  let putemail;
                  if (this.email === this.clientData.Email) {
                    putemail = null;
                  } else {
                    putemail = this.email;
                  }
                  let putcity;
                  if (this.city === this.clientData.City) {
                    putcity = null;
                  } else {
                    putcity = this.city;
                  }
                  let putcountry;
                  if (this.country === this.clientData.Country) {
                    putcountry = null;
                  } else {
                    putcountry = this.country;
                  }
                  let putAddress;
                  if (this.address === this.clientData.Address) {
                    putAddress = this.address;
                  } else {
                    putAddress = this.address;
                  }
                  let putAddress1;
                  if (this.address1 === this.clientData.Address2) {
                    putAddress1 = null;
                  } else {
                    putAddress1 = this.address1;
                  }
                  let putZip;
                  if (this.zip === this.clientData.EINNumber) {
                    putZip = null;
                  } else {
                    putZip = this.zip;
                  }
                  let putstate;
                  if (this.stateCode === this.clientData.State) {
                    putstate = null;
                  } else {
                    putstate = this.stateCode;
                  }
                  const payload = {
                    ClientId: this.ClientId,
                    UserId: this.UserId,
                    Update: true,
                    Email: putemail,
                    CityId: putcity,
                    Country: putcountry,
                    Address: putAddress,
                    Address2: putAddress1,
                    ZIP: putZip,
                    StateId: putstate
                  };
                  var spinnerRef = this.spinnerService.start();
                  this.sassService.striping(payload).subscribe(
                    data => {
                      // alert(data.message)
                      if (data.message === 'User already exist') {
                        this.dataerror = 'This email already exists';
                        alert('This email already exists');
                      } else if (data.message === 'Updated Successfully') {
                        this.dissuccess = true;
                        this.spinnerService.stop(spinnerRef);
                        this.dataerror = 'Updated Successfully';
                        const dialogRef = this.dialog.open(
                          SuccesPopupComponent,
                          {
                            width: '338px',
                            // height: '480px',
                            data: { ClientData: this.dataerror },
                            panelClass: 'custom-editSub-container'
                          }
                        );
                        dialogRef.afterClosed().subscribe(result => {});
                      } else {
                        alert(data.message);
                      }
                      // this.spinnerService.stop(spinnerRef);
                      // this.performLoginTask(data)
                    },
                    error => {
                      // this.state.isSpinnerVisible = false;
                      alert(JSON.stringify(error));
                      this.spinnerService.stop(spinnerRef);
                      // this.buttonDisabled = false;
                    }
                  );
                } else {
                  this.requireCountry = true;
                  setTimeout(() => {
                    this.requireCountry = false;
                  }, 3000);
                }
              } else {
                this.requireZipCode = true;
                setTimeout(() => {
                  this.requireZipCode = false;
                }, 3000);
              }
            } else {
              this.requireCity = true;
              setTimeout(() => {
                this.requireCity = false;
              }, 3000);
            }
          } else {
            this.requireState = true;
            setTimeout(() => {
              this.requireState = false;
            }, 3000);
          }
        } else {
          this.requireAddress1 = true;
          setTimeout(() => {
            this.requireAddress1 = false;
          }, 3000);
        }
      } else {
        this.invalidEmail = true;
        setTimeout(() => {
          this.invalidEmail = false;
        }, 3000);
      }
    } else {
      this.requireEmail = true;
      setTimeout(() => {
        this.requireEmail = false;
      }, 3000);
    }
  }
  deletesubplan() {
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      width: '408px',
      // height: '480px',
      data: [{ ClientId: this.ClientId }, { UserId: this.UserId }],
      panelClass: 'custom-editSub-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getById();
      }
    });
  }
  cancelSub() {
    const payload = {
      CancelSubscription: true,
      Update: true,
      ClientId: this.ClientId,
      UserId: this.UserId
    };
    const spinnerRef = this.spinnerService.start();
    this.sassService.striping(payload).subscribe(
      data => {
        let finalData;
        if (data.message === 'Updated Successfully') {
          this.getById();
          finalData = 'Successfully Cancelled';
          this.spinnerService.stop(spinnerRef);
          const dialogRef = this.dialog.open(SuccesPopupComponent, {
            width: '350px',
            // height: '480px',
            data: { ClientData: finalData },
            panelClass: 'custom-editSub-container'
          });
          dialogRef.afterClosed().subscribe(result => {});
        } else {
          alert(data.message);
        }
        this.spinnerService.stop(spinnerRef);
      },
      error => {
        this.spinnerService.stop(spinnerRef);
        // this.buttonDisabled = false;
      }
    );
  }
  onSearchChange(event, transaction) {
    this.dissuccess = false;
  }
  editpayment() {
    if (this.clientData.ApprovalStatus[0].Title === 'Active') {
      this.router.navigate(['checkoutupdate/' + this.ClientId + '']);
    } else {
      this.notactive = true;
    }
  }
  goToSucessReg() {
    if (this.clientData.ApprovalStatus[0].Title === 'Active') {
      if (this.clientData.UpcomingSubscriptionId) {
        this.changePlanning = true;
        setTimeout(() => {
          this.changePlanning = false;
        }, 10000);
      } else {
        const dialogRef = this.dialog.open(ChangeeditSusbscriptionComponent, {
          width: '700px',
          height: '530px',
          data: {
            subPlan: this.yearlyPlan,
            noPlaning: this.noPlaning,
            cliendid: this.ClientId
          },
          panelClass: 'custom-editSub-container'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.getById();
          }
        });
      }
    } else {
      this.inactiveState = true;
    }
  }
}
