import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { LoginComponent } from "../../../login/login.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-password-success-dialog",
  templateUrl: "./password-success-dialog.component.html",
  styleUrls: ["./password-success-dialog.component.scss"]
})
export class PasswordSuccessDialogComponent implements OnInit {
  selectedDialog: string;
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<PasswordSuccessDialogComponent>,
    public dialog: MatDialog
  ) {}
  openEmojiDialog() {
    this.dialogRef.close();
    this.router.navigate(["login"]);
  }
  confirmSelection() {
    this.dialogRef.close(this.selectedDialog);
  }
  ngOnInit() {}
}
