import { Injectable } from '@angular/core';
// import { HttpClient } from 'selenium-webdriver/http';
import { GlobalService } from '../app.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, Client } from 'src/app/models/user.model';
import { AppState } from '../app-state';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient, private globalService: GlobalService, public state: AppState) {}
  sortColumn: any;
  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
      this.state.user = JSON.parse(localStorage.getItem('user')).user;
      return this.state.user.User.Id;
    } else {
      this.state.isLoggedIn = false;
      this.state.user = null;
      return '';
    }
  }
  token = this.getUserToken();
  v2_getAllCustomers(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.Clients, httpOptions);
  }

  getAllCustomers(pageNumber: any, PageSize: any): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.Clients + '?pageNumber=' + pageNumber + '&PageSize=' + PageSize, httpOptions).pipe();
  }

  getCustomerDetail(ClientId: any): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
        LoadProduct: 'false',
      }),
    };
    return this.http.get<Client>(this.globalService.Clients + '/' + ClientId, httpOptions).pipe();
  }
  getCustomerDetails(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.getcustomerdetailsurl, httpOptions);
  }
  v2_getclientcategories(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.ClientCategories, httpOptions);
  }
  getFacilityMenu(ClientId: any): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
        LoadProduct: 'false',
      }),
    };
    return this.http.get<Client>(this.globalService.getcustomerurl + '/' + ClientId, httpOptions).pipe();
  }
  getClientData(ClientId: any): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.PortalClient + '?pagingparametermodel.clientId=' + ClientId, httpOptions).pipe();
  }

  getAllFacilityMenus(pageNumber: any, PageSize: any, ClientId: any, sortColumn: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?pageNumber=' + pageNumber + '&PageSize=' + PageSize;
    if (ClientId != null) {
      filterString += '&clientId=' + ClientId;
    }
    if (sortColumn != null) {
      filterString += '&SortColumn=' + sortColumn;
    }
    return this.http.get<Client>(this.globalService.productUrl + filterString, httpOptions).pipe();
  }
  getAllFacilityMenusSorted(pageNumber: any, PageSize: any, ClientId: any, sortColumn: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .get<Client>(
        this.globalService.productUrl +
          '?Clientid' +
          '=' +
          ClientId +
          '&pageNumber=' +
          pageNumber +
          '&PageSize=' +
          PageSize +
          '&SortColumn=' +
          sortColumn,
        httpOptions
      )
      .pipe();
  }
  // genericproducts
  getAllgenericproducts(pageNumber: any, PageSize: any, sortColumn: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '&pageNumber=' + pageNumber + '&PageSize=' + PageSize;
    if (sortColumn != null) {
      filterString += '&SortColumn=' + sortColumn;
    }
    return this.http.get<Client>(this.globalService.genericProducts + filterString, httpOptions).pipe();
  }
  getGenericProductSorted(pageNumber: any, PageSize: any, sortColumn: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .get<Client>(
        this.globalService.genericProducts + '&pageNumber=' + pageNumber + '&PageSize=' + PageSize + '&SortColumn=' + sortColumn,
        httpOptions
      )
      .pipe();
  }
  // Sorting For product
  getAllFacilityMenuProduct(pageNumber: any, PageSize: any, ClientId: any, sortColumn: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .get<Client>(
        this.globalService.getcustomerurl + '/' + ClientId + '?pageNumber=' + pageNumber + '&PageSize=' + PageSize + '&SortColumn=' + sortColumn,
        httpOptions
      )
      .pipe();
  }
  getAccountType(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.ClientCategories, httpOptions);
  }

  createAccount(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.Clients, payload, httpOptions).pipe();
  }
  v2_getAllUser(ClientId): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.clientdetailsurl + ClientId, httpOptions);
  }

  updateCustomerDetails(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put(this.globalService.getcustomerurl + '/' + payload.ClientId, payload, httpOptions).pipe();
  }

  getCategories(isPagination, ClientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(
      this.globalService.ProductSections + '?pagingparametermodel.pagination=' + isPagination + '&pagingparametermodel.clientId=' + ClientId,
      httpOptions
    );
  }
  getCategoriesofcsv(isPagination) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.ProductSections + '?pagingparametermodel.pagination=' + isPagination, httpOptions);
  }
  // getCategories(ClientId: any) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({ "Content-Type": "application/json", "UID": this.token })
  //   };
  //   return this.http.get<Client>(
  //     this.globalService.ProductSections +
  //     "?pagingparametermodel.clientId=" + ClientId,
  //     httpOptions
  //   );
  // }
  getAllCategories(pageNumber: any, PageSize: any, sortColumn: any, ClientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?pageNumber=' + pageNumber + '&PageSize=' + PageSize + '&clientId=' + ClientId;
    if (sortColumn != null) {
      filterString += '&SortColumn=' + sortColumn;
    }
    return this.http.get<Client>(this.globalService.ProductSections + filterString, httpOptions).pipe();
  }
  getAllCategoriesForGeneric(pageNumber: any, PageSize: any, sortColumn: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?pageNumber=' + pageNumber + '&PageSize=' + PageSize;
    if (sortColumn != null) {
      filterString += '&SortColumn=' + sortColumn;
    }
    return this.http.get<Client>(this.globalService.ProductSections + filterString, httpOptions).pipe();
  }
  getAllCategoriesdata(pageNumber: any, PageSize: any, sortColumn: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .get<Client>(
        this.globalService.ProductSections + '?pageNumber=' + pageNumber + '&PageSize=' + PageSize + '&SortColumn=' + sortColumn,
        httpOptions
      )
      .pipe();
  }
  getAllCategoriesSorting(pageNumber: any, PageSize: any, sortColumn: any, clientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .get<Client>(
        this.globalService.ProductSections +
          '?pageNumber=' +
          pageNumber +
          '&PageSize=' +
          PageSize +
          '&SortColumn=' +
          sortColumn +
          '&clientId=' +
          clientId,
        httpOptions
      )
      .pipe();
  }
  getMenuTypes(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.MenuTypes, httpOptions);
  }
  updatemenu(payload): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post<Client>(this.globalService.GrabUrl, payload, httpOptions);
  }
  deletemenu(id): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put<Client>(this.globalService.GrabUrl + '/' + id, httpOptions);
  }
  addNewFoodItem(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.productUrl, payload, httpOptions).pipe();
  }

  updateFoodItem(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put(this.globalService.productUrl, payload, httpOptions).pipe();
  }
  addNewCategory(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.post(this.globalService.categoriesUrl, payload, httpOptions).pipe();
  }
  updateCategory(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.put(this.globalService.categoriesUrl, payload, httpOptions).pipe();
  }
  deleteItem(productId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.delete(this.globalService.productUrl + '/' + productId, httpOptions).pipe();
  }

  deleteProductSections(productId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.delete(this.globalService.categoriesUrl + '/' + productId, httpOptions).pipe();
  }
  deleteClientsAccount(ClientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.delete(this.globalService.Clients + '/' + ClientId, httpOptions).pipe();
  }
  deleteFacilitatorAccount(ClientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.delete(this.globalService.Clients + '/' + ClientId, httpOptions).pipe();
  }
  deleteUser(Id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.delete(this.globalService.userDetailUrl + '/' + Id, httpOptions).pipe();
  }
  CompostPickupTypeList(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.CompostPickupTypes, httpOptions).pipe();
  }

  NotificationTypes(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.NotificationTypes, httpOptions).pipe();
  }
  getClientsProducttDetail(id): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.NonProfitWorkingSlots + '?ClientId=' + id, httpOptions).pipe();
  }
  getNPProducttDetail(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.NonProfitWorkingSlots, httpOptions).pipe();
  }
  InviteUserNotification(ClientId): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.clientdetailsurl + ClientId, httpOptions).pipe();
  }

  getPickupTypeList(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.PickupType, httpOptions).pipe();
  }
  getSAASFGList(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.NonSAASFG, httpOptions).pipe();
  }
  getnoSAASFGList(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.SAASFG, httpOptions).pipe();
  }
  // getAllFilteredCustomers(pageNumber: any, PageSize: any, ClientName: any, Administrator: any, Email: any,
  //   FacilityGroup: any, ClientType: any, PickupType:any): Observable<Client> { ?FacilitatorId=764
  getfacilityReceiver(clientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.Clients + '?FacilitatorId=' + clientId, httpOptions);
  }
  getfacilitygroup(clientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.Clients + '?FacilitatorId=' + clientId + '&CategoryTitle=Facility Group', httpOptions);
  }
  getAllClient(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.Clients, httpOptions);
  }
  getfacilityGroupReceiver(clientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.Clients + '?FacilityGroupId=' + clientId, httpOptions);
  }

  getNonProfitReceiver(clientId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.Clients + '/' + clientId, httpOptions);
  }
  getGrabMenu(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', UID: this.token }),
    };
    return this.http.post<Client>(this.globalService.GrabClientUrl, {}, httpOptions).pipe();
  }
  getAllFilteredCustomersCount(): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.ClientsCount, httpOptions).pipe();
  }
  getAllFilteredCustomers(
    pageNumber: any,
    PageSize: any,
    ClientName: any,
    Email: any,
    ClientType: any,
    PickupType: any,
    selectedMenu: any,
    clientId: any,
    facilityGroup: any,
    facilitator: any,
    PickupTypeid: any,
    selectedstatus: any,
    sortColumn: any
  ): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    let filterString = '?pagingparametermodel.pageNumber=' + pageNumber + '&pagingparametermodel.PageSize=' + PageSize;
    if (ClientName) {
      filterString += '&pagingparametermodel.clientName=' + ClientName;
    }
    if (Email) {
      // filterString += '&pagingparametermodel.email=' + Email;
      filterString += '&pagingparametermodel.citystate=' + Email;
    }
    if (ClientType) {
      filterString += '&pagingparametermodel.categoryTitle=' + ClientType;
    }
    if (PickupType) {
      filterString += '&pagingparametermodel.driverServices=' + PickupType;
    }
    if (selectedMenu) {
      filterString += '&pagingparametermodel.menutypes=' + selectedMenu;
    }
    if (clientId) {
      filterString += '&pagingparametermodel.clientId=' + clientId;
    }
    if (facilityGroup) {
      filterString += '&pagingparametermodel.facilityGroupId=' + facilityGroup;
    }
    if (facilitator) {
      filterString += '&pagingparametermodel.facilitatorId=' + facilitator;
    }
    if (PickupTypeid) {
      filterString += '&pagingparametermodel.PickupType=' + PickupTypeid;
    }
    if (selectedstatus) {
      filterString += '&pagingparametermodel.ApprovalStatus=' + selectedstatus;
    }
    if (sortColumn) {
      filterString += '&pagingparametermodel.SortColumn=' + sortColumn;
    }
    return this.http
      .get<Client>(
        this.globalService.PortalClient + filterString,
        // this.globalService.Clients + filterString,
        httpOptions
      )
      .pipe();
  }

  getAllUsersClients(): Observable<Client> {
    this.token = this.getUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http.get<Client>(this.globalService.getAllUsersClients, httpOptions).pipe();
  }
}
