import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from '../../shared/services/customer.service';
import { AppState } from 'src/app/shared/app-state';
import { Router } from '@angular/router';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SAASService } from 'src/app/shared/services/saas.service';

@Component({
  selector: 'app-deletedialog',
  templateUrl: './deletedialog.component.html',
  styleUrls: ['./deletedialog.component.scss']
})
export class DeletedialogComponent implements OnInit {
  info: any;
  accountArray = [];
  ClientId: any;
  userid: any;
  constructor(private CustomerService: CustomerService, private ClientFilterdata: ClientFilterService,
    public dialogRef: MatDialogRef<DeletedialogComponent>, private sassService: SAASService,
    private state: AppState, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.ClientFilterdata.basicInfoObserve.subscribe(res => {
      this.info = JSON.parse(res);
    });
  }
  // deleteClientAccount() {
  //   this.CustomerService.deleteClientsAccount(this.data[0].ClientData.ClientId).subscribe(
  //     data => {
  //       let localUser = JSON.parse(localStorage.getItem("user"));
  //       for(var i=0; i<localUser.user.User.Clients.length; i++){
  //         if(localUser.user.User.Clients[i].ClientId == this.data[0].ClientData.ClientId){
  //           localUser.user.User.Clients.splice(i, 1);
  //           break;
  //         }
  //       }
  //       this.info.facilitator = '';
  //       this.info.facilitatorGroup = '';
  //       this.info.facility = '';
  //       this.info.nonprofits = '';
  //       this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
  //       localStorage.setItem("user", JSON.stringify(localUser));
  //       this.state.user = JSON.parse(localStorage.getItem("user")).user;
  //       this.router.navigate(['Customers/']);
  //       this.dialogRef.close();
  //     });
  // }
  deleteClientAccount() {
    this.data[0].ClientData.forEach(item => {
      this.accountArray.push(item.ClientId);
    });
    for (var i = 0; i < this.accountArray.length; i++) {
      this.CustomerService.deleteClientsAccount(this.accountArray[i]).subscribe(
        data => {
          let localUser = JSON.parse(localStorage.getItem("user"));
          for (var i = 0; i < localUser.user.User.Clients.length; i++) {
            if (localUser.user.User.Clients[i].ClientId == this.accountArray[i]) {
              localUser.user.User.Clients.splice(i, 1);
              break;
            }
          }
          this.info.facilitator = '';
          this.info.facilitatorGroup = '';
          this.info.facility = '';
          this.info.nonprofits = '';
          this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
          localStorage.setItem("user", JSON.stringify(localUser));
          this.state.user = JSON.parse(localStorage.getItem("user")).user;
          this.router.navigate(['Customers/']);
          this.dialogRef.close('res');
          if (this.state.user.User.Roles[0].Name === 'Facility Group') {
            if (this.state.user.User.Clients[0].DriverService === 'SAAS') {
              this.ClientId = this.state.user['FacilityGroupId'];
            }
          }
          if (this.state.user.User.Roles[0].Name === 'Facility') {
            if (this.state.user.User.Clients[0].DriverService === 'SAAS') {
              this.ClientId = this.state.user.User.Clients[0].ClientId;
            }
          }
          if (this.data[1]) {
            if (this.data[1].sass) {
              if (this.data[1].sass.StripePlanId) {
                if (this.state.user.User.Roles[0].Name === 'Admin') {
                  if (this.data[1].sass.FacilityGroupId) {
                    this.ClientId = this.data[1].sass.FacilityGroupId;
                  } else {
                    this.ClientId = this.data[1].sass.ClientId;
                  }
                }
                if (this.state.user.User.Roles[0].Name === 'Facility' || this.state.user.User.Roles[0].Name === 'Facility Group') {
                  this.userid = this.state.user.User.UserId;
                } else {
                  this.userid = '';
                }
                const payloadStripe = {
                  CancelSubscription: true,
                  Update: true,
                  ClientId: this.ClientId,
                  UserId: this.userid
                };
                this.sassService.striping(payloadStripe).subscribe(
                  data => {
                    // }
                  });
              }
            }
          }
        });
    }
  }
}





