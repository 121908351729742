import { Component, OnInit } from '@angular/core';
import { FacilityAccountDialogComponent } from '../../facility-account-dialog/facility-account-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-request-pickup-menu',
  templateUrl: './request-pickup-menu.component.html',
  styleUrls: ['./request-pickup-menu.component.scss']
})
export class RequestPickupMenuComponent implements OnInit {

  constructor(public dialog: MatDialog) { }
  public form = {
    childGender: null
  };
 onSubmit() {
  }
  ngOnInit() {
  }
  Create_Pickup_List() {
    const dialogRef = this.dialog.open(FacilityAccountDialogComponent, {
      width: '700px', height: '520px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
