import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from '@angular/material';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ForgotSuccessDialogComponent } from '../../forgot-success-dialog/forgot-success-dialog.component';
import { PasswordStrengthValidator } from '../../PasswordStrengthValidators';
import { AccountService } from 'src/app/shared/account.service';
import { AppState } from 'src/app/shared/app-state';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { PasswordSuccessDialogComponent } from './password-success-dialog/password-success-dialog.component';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );
    return invalidCtrl || invalidParent;
  }
}
@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  modalclose: string;
  selectedDialog: string;
  setpassword = true;
  disabled = true;
  token: any;
  forgotId: any;
  userEmail: any;
  resetCode: any;
  resetpassword: any;
  responseToken: any;
  responseId: any;
  passwordError: string;
  dataValidators: boolean;
  confirmerror = false;
  constructor(
    private state: AppState,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PasswordComponent>,
    public dialog: MatDialog,
    private AdminService: AdmintoolsService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {
    this.passwordForm = this.formBuilder.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            PasswordStrengthValidator
          ])
        ],
        confirmPassword: new FormControl({ value: '', disabled: this.disabled })
      }
      // { validator: this.checkPasswords }
    );
  }
  ngOnInit() {
    this.resetCode = this.data['token'];
    this.AdminService.getclientDetails(this.data['userId']).subscribe(data => {
      this.userEmail = data.Result[0].Email;
      if (this.userEmail) {
        let payload = {
          Email: this.userEmail,
          ResetCode: this.resetCode
        };
        this.AdminService.forgotPasswordResetCode(payload).subscribe(
          response => {
            this.responseToken = response['Token'];
            this.responseId = response['Id'];
            if (response['message'] === 'In-valid Reset code') {
              this.passwordError = 'Sorry, the link you followed has expired.';
              this.dataValidators = true;
            }
          },
          error => {
            // alert(JSON.stringify(error));
          }
        );
      }
    });
    // this.forgotId = this.data[0].recoveryId;
    // this.token = this.data[1].recoveryToken;
  }
  keyPresspassword() {
    if (this.passwordForm.valid === true) {
      // this.disabled = false;
      this.passwordForm.controls['confirmPassword'].enable();
    } else {
      this.passwordForm.controls['confirmPassword'].disable();
      // this.disabled = true;
    }
    if (this.passwordForm.valid === true && this.setpassword === true) {
      if (
        this.passwordForm.controls['confirmPassword'].value ===
        this.passwordForm.controls['password'].value
      ) {
        this.setpassword = false;
      }
    } else {
      this.setpassword = true;
    }
    if (this.passwordForm.controls['confirmPassword'].value) {
      if (
        this.passwordForm.controls['confirmPassword'].value ===
        this.passwordForm.controls['password'].value
      ) {
        this.confirmerror = false;
      } else {
        this.confirmerror = true;
      }
    }
  }
  keyPressconpassword() {
    this.confirmerror = true;
    if (this.passwordForm.valid === true && this.setpassword === true) {
      if (
        this.passwordForm.controls['confirmPassword'].value ===
        this.passwordForm.controls['password'].value
      ) {
        this.setpassword = false;
        this.confirmerror = false;
      }
    } else {
      this.setpassword = true;
      this.confirmerror = true;
    }
  }
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  openforgotDialog(resetpassword) {
    let payload = {
      Token: this.responseToken,
      Id: this.responseId,
      Password: resetpassword,
      IsInvited: true
    };
    this.AdminService.forgotPasswordPost(payload).subscribe(response => {
      if (
        response['message'] != 'Your password has been updated successfully'
      ) {
        document.getElementById('errormessage').innerHTML = response['message'];
      } else {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        this.dialogRef.close(this.modalclose);
        const dialog = this.dialog.open(PasswordSuccessDialogComponent, {
          width: '600px',
          // height: "361px",
          disableClose: true,
          data: 'This text is passed into the dialog!'
        });
        dialog.afterClosed().subscribe(selection => {
          if (selection) {
            this.selectedDialog = selection;
          } else {
          }
        });
      }
    });
  }
}
