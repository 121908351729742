import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { EdituserComponent } from '../user/edituser/edituser.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import * as moment from 'moment-timezone';
import { FacilityAccountDialogComponent } from '../facility-account-dialog/facility-account-dialog.component';
import { CustomerService } from '../../shared/services/customer.service';
import { AppState } from 'src/app/shared/app-state';
import { AccountService } from 'src/app/shared/account.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountcompletionMenuDialogComponent } from '../accountcompletion-menu-dialog/accountcompletion-menu-dialog.component';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { AccountCreationDialogComponent } from '../account-creation-dialog/account-creation-dialog.component';
import { SpinnerService } from 'src/app/core/spinner.service';


@Component({
  selector: 'app-edit-business-dialog',
  templateUrl: './edit-business-dialog.component.html',
  styleUrls: ['./edit-business-dialog.component.scss'],
})
export class EditBusinessDialogComponent implements OnInit {
  modalclose: string;
  info: any;
  city: any;
  entityName: any;
  public numberForm: FormGroup;
  public numberFormZip: FormGroup;
  AgreementFlag: string;
  taxId: any;
  businessAddress: any;
  address: any;
  finaldateset = [];
  zip: any;
  sendpickupTypes = [];
  NotificationpickupTypes = [];
  state: any;
  State: any;
  requireField = false;
  accountId: any;
  noMenuSelectedId = null;
  public titleForm: FormGroup;
  facility_type: any;
  account_type: any;
  email: any;
  taxForm: FormGroup;
  invalidEmail: boolean;
  requireFieldAddress: boolean;
  requireFieldEmail: boolean;
  requireFieldCity: boolean;
  requireFieldState: boolean;
  requireFieldZip: boolean;
  userRole: any;
  buttonDisabled = false;
  nonProfitAuto = [];
  NofPeoples: any;
  citydataID: any;
  pickupstatesdata: any;
  City: any;
  menusId: any;
  PhoneNumber: any;
  infoClients: any;
  clientId: any;
  facilitatorloginID: any;
  ClientId: any;
  requireFNumber: boolean;
  country = 'USA';
  requireFieldcountry: boolean;
  uploadForm: FormGroup;
  imageURL: string;
  requirephoto: boolean;
  relaodinfo: any;
  errorimg: boolean;
  cityName: any;
  timezoneabbr: string;
  zipcodes: any;
  timezone: any;
  taxValueLengthError: boolean;
  showMaxSizeError: boolean;
  imageSizeError: any;

  options: any = {
    componentRestrictions: { country: ["us"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  }

  constructor(
    private CustomerService: CustomerService,
    private spinnerService: SpinnerService,
    public _rdata: modalDataService,
    private appstate: AppState,
    private sassService: SAASService,
    public dialog: MatDialog,
    private ClientFilterdata: ClientFilterService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    public changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EditBusinessDialogComponent>,
    public BackRef: MatDialogRef<FacilityAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this._rdata.basicreloaddataInfo2Observe.subscribe((res) => {
      this.relaodinfo = JSON.parse(res);
    });
    // this.info.country= 'USA';
    this.pickupstates();
    this.uploadForm = this.formBuilder.group({
      avatar: [null],
      name: [''],
    });
    this.numberForm = this.formBuilder.group({
      number: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
    });
    this.numberFormZip = this.formBuilder.group({
      zip: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])],
    });
    this.taxForm = this.formBuilder.group({
      tax: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    });
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.infoClients = JSON.parse(res);
    });
    this._rdata.basicInfo2Observe.subscribe((res) => {
      this.info = JSON.parse(res);
      this.city = this.info.city;
      this.entityName = this.info.entityName;
      this.imageURL = this.info.profileImageUrl;
      this.account_type = this.info.account_type;
      this.taxId = this.info.taxId;
      this.country = this.info.country;
      this.timezone = this.info.timezone;
      this.businessAddress = this.info.businessAddress;
      this.address = this.info.address;
      this.email = this.info.email;
      this.PhoneNumber = this.info.PhoneNumber;
      this.zip = this.info.zip.replace(/\s/g, '');
      this.state = this.info.state;
      this.facility_type = this.info.facility_type;
    });
    if (this.info.AgreementFlag === 'false') {
      this.AgreementFlag = 'false';
    } else {
      this.AgreementFlag = 'true';
    }
    if (this.info) {
      if (this.info.state) {
        this.selectedstate(()=>{});
      }
    }
    if (this.facility_type === 'SAAS Facility Group' || this.facility_type === 'SAAS Facility') {
      this.PhoneNumber = '1234567859';
      this.email = 'goodr@gmail.com';
    } else {
      if (this.info.PhoneNumber === '1234567859') {
        this.info.PhoneNumber = '';
        this.PhoneNumber = '';
      } else {
        this.PhoneNumber = this.info.PhoneNumber;
      }
      if (this.info.email === 'goodr@gmail.com') {
        this.info.email = '';
        this.email = '';
      } else {
        this.email = this.info.email;
      }
    }
    this.titleForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
    });
  }

  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.pickupstatesdata = data['Result'];
      for (var i = 0; i < this.pickupstatesdata.length; i++) {
        if (this.pickupstatesdata[i].ID === this.state) {
          this.state = this.pickupstatesdata[i].ID;
        }
      }
    });
  }
  showPreview(event) {
    let eve = this;
    var files = event.target.files;
    var file = files[0];
    var imageSize = file.size / 1024;
    this.showMaxSizeError = false;

    if (imageSize > 2000) {
      this.showMaxSizeError = true;
      this.imageSizeError = 'Image size must be upto 2MB';
      this.imageURL = '';
      this.uploadForm = this.formBuilder.group({
        avatar: [null],
        name: [''],
      });
      // this.showMaxSizeError = false;
      // this.imageSizeError = '';
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = reader.result.toString();
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          // if (height > 100 || width > 100) {
          //   alert("Height and Width must not exceed 100px.");
          //   return false;
          // } else {
            if (files && file) {

              if (files && file) {
                eve.showMaxSizeError = false;
                eve.imageSizeError = '';
                const newFile = (event.target as HTMLInputElement).files[0];
                eve.uploadForm.patchValue({
                  avatar: newFile,
                });
                eve.uploadForm.get('avatar').updateValueAndValidity();
                // File Preview
                const reader = new FileReader();
                reader.onload = () => {
                  eve.imageURL = reader.result as string;
                };
                reader.readAsDataURL(newFile);
              }
            }
            return true;
          // }
        };
      };

    }
  }

  selectedcity() {
    for (const status of this.citydataID) {
      if (status.ID === this.city) {
        this.timezone = status.time_zone;
        this.timezoneabbr = moment.tz.zone(status.time_zone).abbr(360);
        this.zipcodes = status.zip_codes.split('/');
      }
    }
  }
  selectedzip() {
    // console.log(this.zip.replace(/\s/g, ''));
  }
  selectedstate(cb) {
    this.sassService.pickupcitydetails(this.state).subscribe((data) => {
      this.citydataID = data['Result'];
      for (var i = 0; i < this.citydataID.length; i++) {
        if (this.citydataID[i].ID === this.city) {
          this.city = this.citydataID[i].ID;
          this.zipcodes = this.citydataID[i].zip_codes.split('/');
          this.timezoneabbr = moment.tz.zone(this.timezone).abbr(360);
          for (var j = 0; j < this.zipcodes.length; j++) {
            if (this.zipcodes[j].replace(/\s/g, '') == this.zip) {
              this.zip = this.zipcodes[j];
            }
          }
          // this.cityName = this.citydataID[i].Title;
          // console.log(this.cityName);
          // this.city = this.citydataID[i].Title;
        }
      }

      cb();
    });
  }
  keyPressZipCode(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  editMRFuserdetails() {
    var MRFmaterialdata = [];
    for (var i = 0; i < this.info.SeparatedCompostID.length; i++) {
      MRFmaterialdata.push({ ID: this.info.SeparatedCompostID[i] });
    }
    let img = this.imageURL;
    let splitImg = img.split(',');
    this.nonProfitAuto = this.info.NonProfitWorkingSlots;
    let dateset = [];
    dateset = this.info.NonProfitWorkingSlots;
    for (let i = 0; i < dateset.length; i++) {
      let datatime = dateset[i];
      var m = moment.tz(moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.StartTime, this.timezone);
      m.format();
      datatime.StartTime = m.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
      var n = moment.tz(moment(new Date()).format('YYYY-MM-DD') + 'T' + datatime.EndTime, this.timezone);
      n.format();
      datatime.EndTime = n.tz('America/New_York').format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
    }
    this.finaldateset = dateset;
    this.menusId = this.noMenuSelectedId;
    const payload = {
      Name: this.info.accountName,
      EINNumber: this.taxId.replace(/[_-]/g, ''),
      Web: '',
      Address: this.businessAddress,
      SeparatedCompost: MRFmaterialdata,
      Address2: this.address,
      StateId: this.state,
      CityId: this.city,
      TimeZone: this.timezone,
      // City: this.info.city,
      // State: this.info.state,
      ZIP: this.zip.replace(/\s/g, ''),
      Country: this.country,
      Phone: this.PhoneNumber.replace(/[_-]/g, ''),
      Email: this.email,
      HasFridgeStorage: false,
      NumberOfPeopleServed: this.NofPeoples,
      WasteExpenditure: false,
      ClientTypeId: 1,
      ApprovalStatusId: 2,
      WayPointID: 0,
      WP_Client_Id: 0,
      MenuEdit: true,
      Logo: splitImg[1],
      MenuTypeId: this.menusId,
      ClientCategories: [
        {
          ClientCategoryId: this.account_type,
        },
      ],
      DriverServices: [],
      DriverNotes: this.info.driverNotes,
      User_Client: [],
      FacilitatorId: null,
      FacilityGroupId: null,
    };
    this.buttonDisabled = true;
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.createAccount(payload).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        if (
          data['message'] ===
          'The input is not a valid Base-64 string as it contains a non-base 64 character, more than two padding characters, or an illegal character among the padding characters. '
        ) {
          this.buttonDisabled = false;
          this.errorimg = true;
          setTimeout(() => {
            this.errorimg = false;
          }, 3000);
        } else if (data['message']) {
          alert(data['message']);
        } else {
          if (this.info.facility_type !== 'Non Profit' && this.info.facility_type !== 'MRF') {
            let localUser = JSON.parse(localStorage.getItem('user'));
            localUser.user.User.Clients.push(data['Result'][0]);
            localStorage.setItem('user', JSON.stringify(localUser));
            this.state.user = JSON.parse(localStorage.getItem('user')).user;
          } else if (this.info.facility_type === 'Non Profit' || this.info.facility_type === 'MRF') {
            let request = [];
            // this.nonProfitAuto = this.info.NonProfitWorkingSlots;
            request = this.finaldateset;
            for (let i = 0; i < request.length; i++) {
              let dataFinal = request[i];
              // if (dataFinal.WorkingDay) {
              let payloadNP = {
                ClientId: data['Result'][0].ClientId,
                WorkingDay: dataFinal.WorkingDay,
                StartTime: dataFinal.StartTime,
                EndTime: dataFinal.EndTime,
              };
              this.accountService.NonProfitWorkingSeletion(payloadNP).subscribe((data) => { });
              // }
            }
          }
          this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.infoClients));
          this.clientId = data['Result'][0].ClientId;
          this.relaodinfo.reloadclientdata = true;
          this._rdata.updatereloaddataclientBasicInfo2(JSON.stringify(this.relaodinfo));
          this.accountcompletion(this.clientId, this.info.facility_type);
        }
      },
      (error) => {
        this.buttonDisabled = false;
      }
    );
  }
  accountcompletion(id, facility) {
    this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(AccountcompletionMenuDialogComponent, {
      width: '700px',
      // height: '570px',
      panelClass: 'custom-Filterdialog-container',
      disableClose: true,
      data: [
        {
          clientId: id,
        },
        {
          type: facility,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  edituserdetails() {
    // console.log(this.city);
    // for (var i = 0; i < this.citydataID.length; i++) {
    //   if (this.citydataID[i].ID === this.city) {
    //     this.city = this.citydataID[i].ID;
    //     this.cityName = this.citydataID[i].Title;
    //     console.log(this.cityName);
    //     // this.city = this.citydataID[i].Title;
    //   }
    // }
    this.info.city = this.city;
    this.info.AgreementFlag = this.AgreementFlag;
    // this.info.cityName = this.cityName;
    this.info.entityName = this.entityName;
    this.info.account_type = this.account_type;
    this.info.taxId = this.taxId.replace(/[_-]/g, '');
    this.info.businessAddress = this.businessAddress;
    this.info.address = this.address;
    this.info.email = this.email;
    this.info.zip = this.zip.replace(/\s/g, '');
    this.info.PhoneNumber = this.PhoneNumber;
    this.info.country = this.country;
    this.info.state = this.state;
    this.info.user_Details = true;
    this.info.profileImageUrl = this.imageURL;
    this.info.timezone = this.timezone;
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(EdituserComponent, {
      width: '1220px',
      height: '785px',
      panelClass: 'user-details-dialog',
      disableClose: true,
      // height: "530px",this.facility_type
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  // email-validation
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.email) === false) {
      return false;
    }
    return true;
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  addBusinessDetails() {
    this.email=this.email.trim();
    this.taxId=this.taxId.trim();
    this.businessAddress=this.businessAddress.trim();
    this.address=this.address.trim();
    this.PhoneNumber=this.PhoneNumber.trim();
    this.numberForm.controls.number.setValue(numberSetMask(this.PhoneNumber));
    // if (
    //   this.taxId !== '' &&
    //   this.businessAddress !== '' &&
    //   this.city !== '' &&
    //   this.state !== '' &&
    //   this.zip !== '' &&
    //   this.email !== ''
    // ) {
    if (this.info.facility_type === 'MRF') {
      this.businessDetails();
    } else {
      if (this.taxId !== '' && this.taxId.replace(/[_-]/g, '') !== 0 && this.taxId.replace(/[_-]/g, '') !== '0' && this.taxId.length == 10) {
        this.businessDetails();
      } else if (this.taxId.length == '') {
        this.requireField = true;
        setTimeout(() => {
          this.requireField = false;
        }, 3000);
      } else {
        this.taxValueLengthError = true;
        setTimeout(() => {
          this.taxValueLengthError = false;
        }, 3000);
      }
    }
  }

  businessDetails() {
    if (this.email !== '') {
      if (this.businessAddress !== '') {
        if (this.state !== '') {
          if (this.city !== '' && this.city !== 'Select City') {
            // if (this.zip !== '') {
            // if (this.numberFormZip.valid && this.zip !== '00000') {
            if (this.zip !== '00000' && this.zip !== 'Select Zip' && this.zip) {
              if (this.country !== '') {
                if (this.numberForm.valid) {
                  if (this.validateEmail() == true) {
                    if (this.info.facility_type === 'Non Profit') {
                      this.edituserdetails();
                      //Removed Required field for NP

                      // if (this.imageURL) {
                      //   this.edituserdetails();
                      // } else {
                      //   this.requirephoto = true;
                      //   setTimeout(() => {
                      //     this.requirephoto = false;
                      //   }, 3000);
                      // }
                    } else if (this.info.facility_type === 'MRF') {
                      if (this.imageURL) {
                        this.editMRFuserdetails();
                      } else {
                        this.requirephoto = true;
                        setTimeout(() => {
                          this.requirephoto = false;
                        }, 3000);
                      }
                    } else {
                      this.edituserdetails();
                    }
                  } else {
                    this.invalidEmail = true;
                    setTimeout(() => {
                      this.invalidEmail = false;
                    }, 3000);
                  }
                } else {
                  this.requireFNumber = true;
                  setTimeout(() => {
                    this.requireFNumber = false;
                  }, 3000);
                }
              } else {
                this.requireFieldcountry = true;
                setTimeout(() => {
                  this.requireFieldcountry = false;
                }, 3000);
              }
            } else {
              this.requireFieldZip = true;
              setTimeout(() => {
                this.requireFieldZip = false;
              }, 3000);
            }
          } else {
            this.requireFieldCity = true;
            setTimeout(() => {
              this.requireFieldCity = false;
            }, 3000);
          }
        } else {
          this.requireFieldState = true;
          setTimeout(() => {
            this.requireFieldState = false;
          }, 3000);
        }
      } else {
        this.requireFieldAddress = true;
        setTimeout(() => {
          this.requireFieldAddress = false;
        }, 3000);
      }
    } else {
      this.requireFieldEmail = true;
      setTimeout(() => {
        this.requireFieldEmail = false;
      }, 3000);
    }
  }

  backToAccountCreation() {
    this.dialogRef.close(this.modalclose);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(AccountCreationDialogComponent, {
      width: '1220px',
      height: '785px',
      // disableClose: true,
      panelClass: 'account-creation-dialog',
      data: {},
    });
    dialogRef.afterClosed().subscribe((selection) => { });
  }

  AddressChange(address: any) {
    console.log("address",address);
    
      let finalResult = {};
      let dd = address.address_components;
      finalResult['address1'] = '';
      this.businessAddress = '';
      this.zip = '';
      this.city = '';
      let cityid = '';
      this.state = '';

      for (let component in dd) {
          const componentType = dd[component].types[0];

         switch (componentType) {
            case "street_number": {
              this.address = `${dd[component].long_name} ${finalResult['address1']}`;
              break;
            }

            case "route": {
              this.businessAddress += dd[component].short_name;
              break;
            }

            case "postal_code": {
              console.log("ZIP :: ", dd[component].long_name);
              // this.zip = dd[component].long_name;
              setTimeout(() => {
                for (let i in this.zipcodes) {
                  if (parseInt(this.zipcodes[i]) == dd[component].long_name) {
                    this.zip = this.zipcodes[i];
                  }
                }
              }, 3000);
              break;
            }

            case "locality" || "sublocality":
              console.log("CITY :: ", dd[component].long_name);
              cityid = dd[component].long_name;
              if (cityid == "New York") {
                cityid = 'New York City';
              }
              break;

              case "administrative_area_level_3":
                console.log("CITY :: ", dd[component].long_name);
                cityid = dd[component].long_name;
                if (cityid == "New York") {
                  cityid = 'New York City';
                }
                break;

                case "sublocality_level_1":
                console.log("CITY :: ", dd[component].long_name);
                cityid = dd[component].long_name;
                if (cityid == "New York") {
                  cityid = 'New York City';
                }
                break;


            case "administrative_area_level_1": {
              console.log("STATE :: ", dd[component].long_name);
               for (let i in this.pickupstatesdata) {
                  if (this.pickupstatesdata[i].Title == dd[component].long_name) {
                     this.state = this.pickupstatesdata[i].ID;
                  }
               }
              break;
            }

         }
      }

      this.selectedstate(()=>{
        for (let i in this.citydataID) {
          if (this.citydataID[i].Title == cityid) {
             this.city = this.citydataID[i].ID;
             this.selectedcity();
          }
       }
      });
  }
}
