import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { SavePickupmodalComponent } from '../../../save-pickupmodal/save-pickupmodal.component';
import { AppState } from 'src/app/shared/app-state';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';

declare var Stripe: any;
export interface PeriodicElement {
  name: string;
  Cost: number;
  Weight: number;
  Quantity: number;
}
@Component({
  selector: 'app-onetimepickupbillrequest',
  templateUrl: './onetimepickupbillrequest.component.html',
  styleUrls: ['./onetimepickupbillrequest.component.scss'],
})
export class OnetimepickupbillrequestComponent implements OnInit, OnDestroy {
  public numberForm: FormGroup;
  taxForm: any;
  showedData = [];
  EmailFG: any;
  imgSrc = '../../assets/images/editicon.png';
  pickupTypeID = [];
  pickupTypeIDFG = [];
  sendRequest: any = [];
  sendRequestElement: any;
  // addEdit: boolean;
  allDeliveryStatus = [
    {
      ID: 1,
      Title: 'Facility',
    },
    {
      ID: 2,
      Title: 'Facility Group',
    },
  ];
  success: boolean;
  dataedit: boolean;
  CompanyTitle: any;
  FirstName: any;
  LastName: any;
  selectedType: any;
  Email: any;
  i = 0;
  PhoneNumber: any;
  PhoneNumberFG: any;
  TaxID: any;
  TaxIDFG: any;
  Address1: any;
  index: any;
  Address2FG: any;
  Address1FG: any;
  Address2: any;
  State: any;
  StateFG: any;
  City: any;
  ZipCode: any;
  menuEdit = true;
  menuEditFG = true;
  sendpickupTypes: any[];
  requireFirstName: boolean;
  requireLastName: boolean;
  requireCompanyTitle: boolean;
  requireselectedType: boolean;
  requireEmail: boolean;
  checkselectedType = false;
  requirePhoneNumber: boolean;
  multipleFGadd = [];
  facilityFGadd = [];
  requireTaxID: boolean;
  requireAddress1: boolean;
  requireState: boolean;
  requireCity: boolean;
  requireZipCode: boolean;
  invalidEmail: boolean;
  Country: any;
  requireCountry: boolean;
  dataerror: string;
  NotVisibileFG: boolean = true;
  VisibleForFG: boolean;
  facility: boolean = true;
  facilitygroup: boolean;
  CompanyTitleFG: any;
  CityFG: any;
  ZipCodeFG: any;
  CountryFG: any;
  confirmPickuplist: boolean;
  confirmPickuplistCompost: boolean;
  createPickuplist = true;
  totalitemCost: any;
  showValidators: boolean;
  activeState: any;
  buttonDisabled: boolean;
  requestDataDisabled: boolean;
  fcailityValidation: boolean;
  multipleFGaddFinal: any[];
  requireField: boolean;
  menueditble = true;
  menueditbleFG = true;
  productsData = [];
  dataerrortrue: boolean;
  public createForm: FormGroup;
  displayedColumns: string[] = ['name', 'Cost', 'Weight', 'Quantity'];
  displayedColumnsCompost: string[] = ['Title', 'Weight', 'add'];
  ELEMENT_DATA: PeriodicElement[] = [];
  dataSource: any;
  transaction: any = [];
  editbletrue: boolean;
  totalItems: any;
  onetimebilling: any;
  foodRequest: boolean;
  cardNumber: string;
  cvc: string;
  stripe: any;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;

  confirmfinallist: boolean;
  productList: any;
  clienbyData: any;
  PickupWeight: number;
  varyplanId: any;
  totalpaid: any;
  requireitemsView: boolean;
  selectdate: boolean;
  roadies: boolean;
  selectedEndDate: any;
  pickupEndTime: any;
  showEndTime: any;
  selectedEndTime: string;
  pickupEndDate: any;
  selectedDate: any;
  pickupStartTime: string;
  showStartTime: string;
  pickupStartDate: string | number | Date;
  selectedStartTime: string;
  showStartDate: Date;
  showEndDate: Date;
  info: any;
  npId: any;
  couponCodeDiv: boolean = false;
  coupendata: any = '';
  coupenValue = [];
  errorcoupen: boolean;
  validcoupensdata: any;
  totalfinalpaid: number;
  DeliveryPartner: any;
  PickupAfter: any;
  PickupBefore: any;
  errorcouponexpired: boolean;
  couponExpired: any = '';
  validcoupensexeeddata: boolean;

  constructor(
    private customerServices: CustomerService,
    public dialog: MatDialog,
    public dateFormat: dateFormatPipe,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sassService: SAASService,
    private state: AppState,
    private pickupService: PickUpService,
    private _rdata: modalDataService,
    private spinnerService: SpinnerService,
    private PromotionCodeService: PricingPromotionCodeService
  ) {}

  ngOnInit() {
    this.couponCodeDiv = false;
    this._rdata.basicInfo1Observe.subscribe((res) => {
      this.info = JSON.parse(res);
    });
    this.info.pickupsPage = true;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
    this.pickupRequest();
    this.defaultdatetime();
    // this.pickupService.getClientsProducttDetail(this.route.snapshot.params.id)
    //   .subscribe(
    //     data => {
    //       // this.spinnerService.stop(spinnerRef);
    //       this.state.pickups = data;
    //       this.productList = data.Result;
    //       for (const status of this.productList[0].PickupType) {
    //         if (status.Title === 'One Time Pickup') {
    //           this.pickupTypeID = status.ID;
    // this.foodRequest = true;
    // } else {
    // this.combinedID = status.ID;
    // this.compostRequest = true;
    // }
    // }
    // });
    // document.getElementById('cardpayment').style.display = 'none';
    // this.onetimebilling = JSON.parse(localStorage.getItem('onetimebilling'));
    // let products = JSON.parse(localStorage.getItem('OneTimeItem'));
    // console.log(this.productsData)
    // console.log(this.onetimebilling)
    // if (products) {
    // this.productsData = products;
    // this.dataSource = new MatTableDataSource<Element>(this.productsData);
    // console.log(this.dataSource)
    // this.totalItems = this.productsData.length -1;
    // this.CompanyTitle = this.onetimebilling[0].CompanyTitle;
    // this.FirstName = this.onetimebilling[0].FirstName;
    // this.LastName = this.onetimebilling[0].LastName;
    // this.selectedType = this.onetimebilling[0].CompanyTitle;
    // this.Email = this.onetimebilling[0].Email;
    // this.PhoneNumber = this.onetimebilling[0].PhoneNumber;
    // this.TaxID = this.onetimebilling[0].TaxID;
    // this.Country = this.onetimebilling[0].Country;
    // this.Address1 = this.onetimebilling[0].Address1;
    // this.Address2 = this.onetimebilling[0].Address2;
    // this.State = this.onetimebilling[0].State;
    // this.City = this.onetimebilling[0].City;
    // this.ZipCode = this.onetimebilling[0].ZipCode;
    // this.menuEdit = this.onetimebilling[0].MenuEdit;
    // } else {
    const body = {
      Name: '',
      Quantity: '',
      Price: '',
      wasClicked: true,
      Weight: '',
      imgSrc: '../../assets/images/editicon.png',
      index: 1,
      newitem: true,
      deleteoption: false,
    };
    this.productsData.push(body);
    this.dataSource = this.productsData;
    // }
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();
    this.cardNumberElement = elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number-element');
    this.cardNumberElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardExpiryElement = elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry-element');
    this.cardExpiryElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.cardCvcElement = elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc-element');
    this.cardCvcElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    document.getElementById('cardpayment').style.display = 'none';
    this.createForm = this.formBuilder.group({
      quantity: ['', Validators.compose([Validators.required])],
      Weight: ['', Validators.compose([Validators.required])],
      Cost: ['', Validators.compose([Validators.required])],
    });
    this.numberForm = this.formBuilder.group({
      number: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
    this.taxForm = this.formBuilder.group({
      tax: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]),
      ],
    });
  }
  onSearchChange(event, transaction) {
    // transaction.editble = true;
    this.dataedit = true;
    this.success = false;
  }
  pickupRequest() {
    this.pickupService
      .getClientsProducttDetail(this.route.snapshot.params.id)
      .subscribe(
        (data) => {
          this.clienbyData = data.Result;
          let body = {
            DriverService: true,
            StateId: this.clienbyData[0].StateId,
            CityId: this.clienbyData[0].CityId,
            Email: this.clienbyData[0].Email,
          };
          // this.sassService.onetimebillingStripePlan(body).subscribe((data) => {
          //   console.log(data)
          //   if (data.Roadies === true) {
          //     // if (this.clienbyData[0].DriverServices[0].Title === 'Roadie') {
          //     this.selectdate = true;
          //   } else {
          //     this.selectdate = false;
          //   }
          //   // }
          // });
        },
        (error) => {}
      );
  }
  keyPressFirstName(event: any) {
    if (this.validateEmail() === true) {
      let body1 = {
        ValidateEmail: true,
        Email: event,
        SAASClient: '',
      };
      this.sassService.reigster(body1).subscribe((data) => {
        if (
          data.message ===
          'Field requires a unique email id and this email id has already been used'
        ) {
          this.dataerrortrue = true;
          this.dataerror = data.message;
        } else {
          this.dataerrortrue = false;
        }
      });
    } else {
      this.dataerrortrue = false;
    }
  }
  validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.Email) === false) {
      return false;
    }
    return true;
  }
  validateEmailFG() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(this.EmailFG) === false) {
      return false;
    }
    return true;
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  goToPayment() {
    this.router.navigate(['PickupBilling']);
  }
  addItems(transaction, i) {
    if (
      transaction.Quantity > 0 &&
      transaction.Weight > 0 &&
      transaction.Price > 0
    ) {
      if (
        transaction.Quantity &&
        transaction.Price &&
        transaction.Weight &&
        transaction.Name
      ) {
        this.addItemsList(transaction, i);
      } else {
        transaction.nameShowValidators = true;
        setTimeout(() => {
          transaction.nameShowValidators = false;
        }, 3000);
      }
    } else {
      transaction.showValidators = true;
      setTimeout(() => {
        transaction.showValidators = false;
      }, 3000);
    }
  }

  addItemsList(transaction, i) {
    if (transaction.wasClicked === false) {
      transaction.wasClicked = true;
      transaction.buttonDisabled = false;
      transaction.showValidators = false;
      if (transaction.quantityZero === true) {
        transaction.QuanbuttonDisabled = false;
      }
      if (transaction.weightZero === true) {
        transaction.WeigthbuttonDisabled = false;
      }
      // }
      transaction.imgSrc = '../../assets/images/editicon.png';
    } else {
      if (transaction.wasClicked === true) {
        transaction.wasClicked = false;
      }
      transaction.buttonDisabled = true;
      transaction.imgSrc = '../../assets/images/circle12.png';
      // if (transaction.Sections[0].Name === 'New Items') {
      transaction.newitem = false;
      transaction.deleteoption = true;
      // this.index = i + 1;
      const sendbody = {
        Name: transaction.Name,
        Quantity: transaction.Quantity,
        Sections: [{ SectionId: 0, Name: 'New Items' }],
        Price: transaction.Price,
        Weight: transaction.Weight,
        imgSrc: '../../assets/images/circle12.png',
        wasClicked: transaction.wasClicked,
        index: transaction.index,
        newitem: transaction.newitem,
        deleteoption: transaction.deleteoption,
      };
      const body = {
        Name: '',
        Quantity: '',
        Price: '',
        wasClicked: true,
        Weight: '',
        imgSrc: '../../assets/images/editicon.png',
        index: transaction.index + 1,
        newitem: true,
        deleteoption: false,
      };
      for (let i = 0; i < this.productsData.length; i++) {
        if (transaction.ProductId === 0) {
          if (transaction.index === this.productsData[i].index) {
            this.productsData.splice(i, 1);
            this.productsData.push(sendbody);
          }
        }
      }
      let newitemcount = 0;
      for (let i = 0; i < this.productsData.length; i++) {
        if (this.productsData[i].newitem === true) {
          newitemcount++;
        }
      }
      if (newitemcount === 0) {
        this.productsData.push(body);
      }
      this.totalItems = this.productsData.length - 1;
      this.totalitemCost = this.productsData
        .map((r) => r.Quantity * r.Price)
        .reduce((sum, current) => Number(sum) + Number(current));
      this.dataSource = new MatTableDataSource<Element>(this.productsData);
      // this.sendRequestElement = new MatTableDataSource<Element>(this.productsData);
    }
  }
  deletePickup(product) {
    // let index;
    // let index1;
    //  index = this.productsData.indexOf(product);
    // this.productsData.splice(index, 1);
    //  index1 = this.showedData.indexOf(product);
    // this.showedData.splice(index1, 1);
    for (let i = 0; i < this.productsData.length; i++) {
      if (product.index === this.productsData[i].index) {
        this.productsData.splice(i, 1);
      }
    }
    for (let i = 0; i < this.showedData.length; i++) {
      if (product.index === this.showedData[i].index) {
        this.showedData.splice(i, 1);
      }
    }
    this.totalItems = this.productsData.length - 1;
    this.totalitemCost = this.productsData
      .map((r) => r.Quantity * r.Price)
      .reduce((sum, current) => Number(sum) + Number(current));
    this.dataSource = new MatTableDataSource<Element>(this.productsData);
    this.sendRequestElement = new MatTableDataSource<Element>(this.showedData);
    // this.sendRequestElement = new MatTableDataSource<Element>(this.productsData);
  }
  addFoodItem() {
    document.getElementById('cardpayment').style.display = 'none';
    this.confirmPickuplist = false;
    this.confirmPickuplistCompost = false;
    this.createPickuplist = true;
    this.confirmfinallist = false;
  }
  pickuplistItems() {
    document.getElementById('cardpayment').style.display = 'none';
    this.confirmPickuplist = true;
    this.confirmPickuplistCompost = false;
    this.createPickuplist = false;
    this.confirmfinallist = false;
  }
  ViewPickupList() {
    this.showedData = [];
    for (let item of this.productsData) {
      if (item.Name && item.Price && item.Quantity && item.Weight) {
        this.showedData.push({
          Name: item.Name,
          Price: item.Price,
          Quantity: item.Quantity,
          Weight: item.Weight,
          buttonDisabled: item.buttonDisabled,
          clickinput: item.clickinput,
          deleteoption: item.deleteoption,
          imgSrc: item.imgSrc,
          index: item.index,
          nameenter: item.nameenter,
          newitem: item.newitem,
          priceenter: item.priceenter,
          quantyenter: item.quantyenter,
          wasClicked: item.wasClicked,
          weigthenter: item.weigthenter,
        });
      }
    }
    this.sendRequestElement = new MatTableDataSource<Element>(this.showedData);
    document.getElementById('cardpayment').style.display = 'none';
    this.confirmfinallist = false;
    this.foodRequest = true;
    if (this.selectdate === true) {
      this.roadies = true;
    } else {
      this.roadies = false;
    }
    if (this.productsData.length === 1) {
      this.showValidators = true;
      this.requestDataDisabled = true;
      this.totalItems = 0;
      this.totalitemCost = 0;
      setTimeout(() => {
        this.showValidators = false;
      }, 1500);
    } else {
      let clickitems = 0;
      this.productsData.map((value) => {
        if (
          value.nameenter === true &&
          value.priceenter === true &&
          value.weigthenter === true &&
          value.quantyenter === true &&
          value.imgSrc === '../../assets/images/editicon.png'
        ) {
          value.clickinput = true;
          clickitems++;
          setTimeout(() => {
            value.clickinput = false;
          }, 3000);
        } else {
          value.clickinput = false;
        }
      });
      if (clickitems === 0) {
        this.confirmPickuplist = true;
        // this.confirmPickuplistCompost = true;
        this.createPickuplist = false;
        this.requestDataDisabled = false;
        if (this.productsData.length > 1) {
          this.totalitemCost = this.productsData
            .map((r) => r.Quantity * r.Price)
            .reduce((sum, current) => Number(sum) + Number(current));
          this.totalItems = this.productsData.length;
        } else {
          this.totalItems = 0;
          this.totalitemCost = 0;
        }
      }
    }
  }
  onSearchChangename(event, transaction) {
    transaction.nameenter = true;
  }
  onSearchChangeweigth(event, transaction) {
    transaction.weigthenter = true;
  }
  onSearchChangeprice(event, transaction) {
    transaction.priceenter = true;
  }
  onSearchChangequanty(event, transaction) {
    transaction.quantyenter = true;
  }
  requestConfirmList() {
    if (this.productsData.length > 1) {
      let finalstartdate;
      let finalenddtae;
      // if (this.selectdate === true) {
      finalstartdate = this.pickupStartDate;
      finalenddtae = this.pickupEndDate;
      // } else {
      //   finalstartdate = null;
      //   finalenddtae = null;
      // }
      this.PickupWeight = this.productsData
        .map((r) => r.Quantity * r.Weight)
        .reduce((sum, current) => Number(sum) + Number(current));
      let body = {
        CityId: this.clienbyData[0].CityId,
        StateId: this.clienbyData[0].StateId,
        PickupWeight: this.PickupWeight,
        // Roadies: this.selectdate,
        PickupAfter: finalstartdate,
        PickupBefore: finalenddtae,
        PickupAddress: this.clienbyData[0].Address,
      };
      this.sassService.onetimebillingStripePlan(body).subscribe((data) => {
        if (data.PlanId) {
          this.confirmfinallist = true;
          this.confirmPickuplist = false;
          this.createPickuplist = false;
          document.getElementById('cardpayment').style.display = 'none';
          this.varyplanId = data.PlanId;
          this.totalpaid = data.Price;
          this.DeliveryPartner = data.DeliveryPartner;
          this.PickupAfter = data.PickupAfter.split('Z')[0];
          this.PickupBefore = data.PickupBefore.split('Z')[0];
          this.npId = data.NPClientId;
        } else {
          alert(data.message);
        }
      });
    } else {
      this.requireitemsView = true;
      setTimeout(() => {
        this.requireitemsView = false;
      }, 3000);
    }
  }
  onChangeEndDate(date) {
    this.selectedEndDate = date;
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  onChangeEndTime(time) {
    this.pickupEndTime = this.ConvertTime(time);
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.updateEndDateTime(this.selectedEndDate, this.pickupEndTime);
  }
  //Date change event(end date-delivery)
  updateEndDateTime(selectedEndDate, pickupEndTime) {
    if (selectedEndDate) {
      selectedEndDate = selectedEndDate;
    } else {
      selectedEndDate = new Date(this.pickupEndDate);
    }
    selectedEndDate.setMinutes(0);
    selectedEndDate.setHours(0);
    let selectedfirstValue;
    selectedfirstValue = pickupEndTime.split(':')[0];

    let selectedSecondValue;
    selectedSecondValue = pickupEndTime.split(':')[1];
    selectedEndDate.setHours(selectedEndDate.getHours() + selectedfirstValue) +
      ':' +
      selectedEndDate.setMinutes(
        selectedEndDate.getMinutes() + selectedSecondValue
      );
    this.selectedEndDate = selectedEndDate;

    let selectedEndDateSplit;
    this.selectedEndTime = moment(selectedEndDate).format().toString();
    selectedEndDateSplit =
      this.selectedEndTime.split('T')[0] +
      'T' +
      this.selectedEndTime.split('T')[1].split('-')[0].split('+')[0];
    this.pickupEndDate = selectedEndDateSplit;
  }
  //Time-conversion
  ConvertTime(t) {
    return moment(t, ['h:mm A']).format('HH:mm');
  }

  onChangeDate(date) {
    this.selectedDate = date;
    this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
  onChangeTime(time) {
    this.pickupStartTime = this.ConvertTime(time);
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
      'hh:mm a'
    );
    this.updateDateTime(this.selectedDate, this.pickupStartTime);
  }
  //Date change event(start date-pickup)
  updateDateTime(selectedDate, pickupStartTime) {
    if (selectedDate) {
      selectedDate = selectedDate;
    } else {
      selectedDate = new Date(this.pickupStartDate);
    }
    selectedDate.setMinutes(0);
    selectedDate.setHours(0);
    let selectedfirstValue;
    selectedfirstValue = pickupStartTime.split(':')[0];

    let selectedSecondValue;
    selectedSecondValue = pickupStartTime.split(':')[1];
    selectedDate.setHours(selectedDate.getHours() + selectedfirstValue) +
      ':' +
      selectedDate.setMinutes(selectedDate.getMinutes() + selectedSecondValue);
    this.selectedDate = selectedDate;
    this.selectedStartTime = moment(selectedDate).format().toString();
    let selectedStartDateSplit;
    selectedStartDateSplit =
      this.selectedStartTime.split('T')[0] +
      'T' +
      this.selectedStartTime.split('T')[1].split('-')[0].split('+')[0];
    this.pickupStartDate = selectedStartDateSplit;
  }
  defaultdatetime() {
    this.pickupStartDate = new Date();
    const startDate = new Date(
      this.dateFormat.transform(this.pickupStartDate.toISOString(),'America/New_York')
    );
    this.pickupStartDate =
      moment(startDate)
        .add(1, 'hours')
        .add(30, 'minutes')
        .format()
        .toString()
        .split('T')[0] +
      'T' +
      moment(startDate)
        .add(1, 'hours')
        .add(30, 'minutes')
        .format()
        .toString()
        .split('T')[1]
        .split('+')[0]
        .split('-')[0];
    this.pickupStartTime = this.pickupStartDate
      .split('T')[1]
      .split('+')[0]
      .split('-')[0];
    this.showStartTime = moment(this.pickupStartTime, ['HH.mm']).format(
      'hh:mm a'
    );
    this.showStartDate = new Date(
      moment(this.pickupStartDate).format('YYYY/MM/DD')
    );
    this.pickupEndDate = new Date(this.pickupStartDate);
    this.pickupEndDate =
      moment(this.pickupEndDate)
        .add(2, 'hours')
        .format()
        .toString()
        .split('T')[0] +
      'T' +
      moment(this.pickupEndDate)
        .add(2, 'hours')
        .format()
        .toString()
        .split('T')[1]
        .split('+')[0]
        .split('-')[0];
    this.pickupEndTime =
      new Date(this.pickupEndDate).getHours() +
      ':' +
      new Date(this.pickupEndDate).getMinutes() +
      ':' +
      '00';
    this.showEndTime = moment(this.pickupEndTime, ['HH.mm']).format('hh:mm a');
    this.showEndDate = new Date(
      moment(this.pickupEndDate).format('YYYY/MM/DD')
    );
  }
  finalcardpaymentmeth() {
    document.getElementById('cardpayment').style.display = 'block';
    this.confirmPickuplist = false;
    this.confirmPickuplistCompost = false;
    this.createPickuplist = false;
    this.confirmfinallist = false;
  }
  pickuplistorder() {
    document.getElementById('cardpayment').style.display = 'none';
    this.confirmPickuplist = false;
    this.confirmPickuplistCompost = false;
    this.createPickuplist = false;
    this.confirmfinallist = true;
  }
  finalcardpayment() {
    if (this.validcoupensdata) {
      this.coupendata = this.coupendata;
    } else {
      this.coupendata = null;
    }
    this.stripe.createToken(this.cardNumberElement).then((result) => {
      if (result.token) {
        if (result.token.id) {
          this.requestDataDisabled = true;
          const sendData = [];
          this.productsData.pop();
          for (let item of this.productsData) {
            sendData.push({
              ItemName: item.Name,
              Quantity: item.Quantity,
              ItemRetailValue: item.Price,
              ItemWeight: item.Weight,
            });
          }
          let finalstartdate;
          let finalenddtae;
          // if (this.selectdate === true) {
          finalstartdate = this.pickupStartDate;
          finalenddtae = this.pickupEndDate;
          // } else {
          //   finalstartdate = null;
          //   finalenddtae = null;
          // }
          const sendbodydata = {
            OneTimeClient: {
              PromoCodeName: this.coupendata,
              PlanId: this.varyplanId,
              Token: result.token.id,
            },
            OneTimeItem: sendData,
            // PickupTypeId: this.pickupTypeID,
            ClientId: +this.route.snapshot.params.id,
            Pickup: true,
            DeliveryPartner: this.DeliveryPartner,
            PickupAfter: this.PickupAfter,
            PickupBefore: this.PickupBefore,
            NPClientId: this.npId,
            // Roadies: this.selectdate,
            // PickupAfter: finalstartdate,
            // PickupBefore: finalenddtae,
          };
          // this.pickupService.addItemsRequest(JSON.stringify(sendbodydata)).subscribe(
          //   data => {
          this.sassService
            .onetimebillingAdmin(JSON.stringify(sendbodydata))
            .subscribe(
              (data) => {
                if (data.message === undefined) {
                  localStorage.removeItem('pickupRequestData');
                  const dialogRef = this.dialog.open(SavePickupmodalComponent, {
                    width: '700px',
                    panelClass: 'custom-savedialog-container',
                    data: {
                      DeliveryId: data.DeliveryId,
                    },
                  });
                  dialogRef.afterClosed().subscribe((result) => {});
                } else {
                  this.requestDataDisabled = false;
                  alert(data.message);
                }
              },
              (error) => {
                this.state.isSpinnerVisible = false;
                this.requestDataDisabled = false;
                // alert(JSON.stringify(error));
              }
            );
        }
      }
    });
  }

  validCoupen() {
    this.validcoupensdata = '';
    const payload = {
      PromoCodeName: this.coupendata,
      ValidateOTP: true,
      OTPPrice: this.totalpaid,
    };
    this.PromotionCodeService.validCoupen(payload).subscribe(
      (data) => {
        if (
          data.message ===
          'Coupon duration is month which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is year which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (data.message === 'Coupon was in error') {
          this.checkForError();
        } else if (
          data.message === 'Invalidate Coupon due to price paramters'
        ) {
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.validcoupensexeeddata = true;
          setTimeout(() => {
            this.validcoupensexeeddata = false;
          }, 3000);
        } else if (
          data.message.includes('Coupon validity is already expired')
        ) {
          this.couponExpired = data.message;
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.errorcouponexpired = true;
          setTimeout(() => {
            this.errorcouponexpired = false;
          }, 3000);
        } else if (
          data.message ===
          'Object reference not set to an instance of an object.'
        ) {
          this.errorcoupen = true;
          setTimeout(() => {
            this.errorcoupen = false;
          }, 3000);
        } else {
          if (data.message.includes('Coupon is Valid')) {
            this.validcoupensdata = 'Coupon Applied';
            this.coupenValue = [];
            var r = /\d+/g;
            // var s = "you can enter 333 maximum 500 choices";
            var m;
            while ((m = r.exec(data.message)) != null) {
              this.coupenValue.push(m[0]);
            }
            this.totalfinalpaid = this.totalpaid - this.coupenValue[0];
          } else {
            alert('Something wrong with Coupon');
          }
        }
      },
      (error) => {
        // alert(JSON.stringify(error));
      }
    );
  }
  ngOnDestroy() {
    this.info.pickupsPage = false;
    this._rdata.updateBasicInfo1(JSON.stringify(this.info));
  }

  showCoupon() {
    this.couponCodeDiv = true;
  }

  checkForError() {
    this.validcoupensdata = '';
    this.coupenValue = [];
    this.errorcoupen = true;
    setTimeout(() => {
      this.errorcoupen = false;
    }, 3000);
  }

  clearCoupon() {
    this.errorcoupen = false;
    this.coupendata = null;
    this.validcoupensdata = '';
  }
}
