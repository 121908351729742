import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { MatDialogConfig } from '@angular/material';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { PasswordStrengthValidator } from '../../../loginmodule/PasswordStrengthValidators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ProfileService } from 'src/app/shared/services/profilesetting.service';
import { AppState } from 'src/app/shared/app-state';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
@Component({
  selector: 'app-passworddialog',
  templateUrl: './passworddialog.component.html',
  styleUrls: ['./passworddialog.component.scss']
})
export class PassworddialogComponent implements OnInit {
  myForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  password: any;
  confirmPassword: any;
  oldpassword: any;
  Error: string;
  setpassword = true;
  confirmerror = false;
  disabled = true;
  buttonDisabled: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PassworddialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private state: AppState,
    public profileService: ProfileService
  ) {
    this.myForm = this.formBuilder.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            PasswordStrengthValidator
          ])
        ],
        confirmPassword: new FormControl({
          value: '',
          disabled: this.disabled
        }),
        oldpassword: []
        //   null,
        //   Validators.compose([Validators.required, Validators.minLength(8)])
        // ]
      }
      // { validator: this.checkPasswords }
    );
  }
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}
  keyPresspassword() {
    if (this.myForm.valid === true) {
      // this.disabled = false;
      this.myForm.controls['confirmPassword'].enable();
    } else {
      this.myForm.controls['confirmPassword'].disable();
      // this.disabled = true;
    }
    if (this.myForm.valid === true && this.setpassword === true) {
      if (
        this.myForm.controls['confirmPassword'].value ===
        this.myForm.controls['password'].value
      ) {
        this.setpassword = false;
      }
    } else {
      this.setpassword = true;
    }
    if (this.myForm.controls['confirmPassword'].value) {
      if (
        this.myForm.controls['confirmPassword'].value ===
        this.myForm.controls['password'].value
      ) {
        this.confirmerror = false;
      } else {
        this.confirmerror = true;
      }
    }
  }
  keyPressconpassword() {
    this.confirmerror = true;
    if (this.myForm.valid === true && this.setpassword === true) {
      if (
        this.myForm.controls['confirmPassword'].value ===
        this.myForm.controls['password'].value
      ) {
        this.setpassword = false;
        this.confirmerror = false;
      }
    } else {
      this.setpassword = true;
      this.confirmerror = true;
    }
  }
  savePasswordDialog() {
    const body = {
      userId: this.state.user.User.Id,
      currentPassword: this.oldpassword,
      newPassword: this.password
    };
    this.buttonDisabled = true;
    this.profileService
      .profilePasswordSetting(body, this.state.user.User.Id)
      .subscribe(
        res => {
          if (res.message === undefined) {
            alert('Password successfully changed!!');
            this.dialogRef.close();
          } else {
            this.Error = res.message;
            alert(this.Error);
            this.buttonDisabled = false;
          }
        },
        error => {
          // alert(JSON.stringify(error));
        }
      );
  }
}
