import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'phoneFilter' })
export class PhoneFilterPipe implements PipeTransform {
  transform(value: string): string {
    if (value !== undefined) {
      if (value !== null) {
        const newVal = value.replace(/[^\w\s]/gi, '').toLocaleLowerCase();
        return this.titleCase(newVal);
      }
    }
  }
  titleCase(value) {
    const str = value.toString();
    return `${str.slice(0, 3)}-${str.slice(3, 6)}-${str.slice(6)}`;
  }
}
