import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
@Component({
  selector: 'app-save-pickup-listmodal',
  templateUrl: './save-pickup-listmodal.component.html',
  styleUrls: ['./save-pickup-listmodal.component.scss']
})
export class SavePickupListmodalComponent implements OnInit {

  constructor(private router: Router, public dialogRef: MatDialogRef<SavePickupListmodalComponent>,
              @Inject( MAT_DIALOG_DATA ) public data: MatDialog ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }
  createNewPickupList() {
    this.router.navigate(['SavedPickupTemplates']);
    this.dialogRef.close();
  }
  pickup_submission() {
    this.router.navigate(['ConfirmPickupList']);
    this.dialogRef.close();
  }
}
