import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { AppState } from 'src/app/shared/app-state';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { numberSetMask } from 'src/app/shared/pipes/maskSet';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { SAASService } from 'src/app/shared/services/saas.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';

@Component({
  selector: 'edit-user-details',
  templateUrl: './editUserDetails.component.html',
  styleUrls: ['./editUserDetails.component.scss'],
})
export class EditUserDetailsComponent implements OnInit {
  public numberForm: FormGroup;
  user: any;
  modalclose: string;
  existingadmin: boolean;
  existingadminno: boolean;
  radioOptions = 'option 2';
  optionsSelect: Array<any>;
  clients: any;
  requireFieldEmail: boolean;
  requireFieldLast: boolean;
  allclients: any;
  selectedType: any[];
  selectedRole: any;
  buttonDisabled: boolean;
  disabledclients: boolean;
  nonprofitsClients: any;
  FacilityClients: any;
  facilityGroupClients: any;
  facilitatorClients: any;
  facility_Group: boolean;
  non_Profit: boolean;
  facilitator: boolean;
  facility: boolean;
  driver_service: boolean;
  selectnonprofitClient: any;
  notvalid: boolean;
  number: any;
  radio = [
    {
      description: 'Active ',
      Active: 'Publish',
    },
    {
      description: 'Inactive ',
      Active: 'Trash',
    },
  ];
  selectedEntry: any;
  statusData: any;
  status: any;
  statusSelected: any;
  info: any;
  FirstName: any;
  LastName: any;
  Email: any;
  requireField: boolean;
  HideForFG: boolean;
  invalidEmail: boolean;
  allDeliveryStatus: any;
  userRoles: any;
  requirednumber: boolean;
  driverSelected: any;
  driverService: any;
  optiondriverStatus: boolean;
  selectedDriver: any;
  IsSAASDriver: boolean;
  hidevisible: boolean = false;
  requireFieldAccount: boolean;
  pickupstatesdata: any;
  citydataID: any;
  admincity: any;
  adminstate: any;
  requireFieldCity: boolean;
  requireFieldState: boolean;
  driver_serviceGDR: boolean;
  featureinfo: any;
  SAASFeature: boolean;
  RoadiePostmatesFeature: boolean;
  adminUserRole: boolean;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private state: AppState,
    private customerServices: CustomerService,
    private ClientFilterdata: ClientFilterService,
    private AdminService: AdmintoolsService,
    private spinnerService: SpinnerService,
    private sassService: SAASService,
    public dialogRef: MatDialogRef<EditUserDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    public capitailizepipe: specialPipe
  ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'SAAS' && element.EnableFeature === false) {
            this.SAASFeature = false;
          }
          if (element.Title === 'Roadie/Postmates' && element.EnableFeature === true) {
            this.RoadiePostmatesFeature = true;
          } else {
            this.RoadiePostmatesFeature = false;
          }
        }
        // if (this.featureinfo.featureData[1].Title === 'SAAS') {
        //   if (this.featureinfo.featureData[1].EnableFeature === false) {
        //     this.SAASFeature = false;
        //   }
        // }
      }
      // }
    });
    this.ClientFilterdata.basicCityInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      this.pickupstatesdata = this.info.statedata;
    });
    this.HideForFG = getFeature('HideStatusOption');
    this.state.user = JSON.parse(localStorage.getItem('user')).user;
    this.user = this.state.user;

    if (this.user.User.Roles[0].Name === 'Admin' || this.user.User.Roles[0].Name === 'Billing Admin') {
      this.adminUserRole = true;
    } else {
      this.adminUserRole = false;
    }

    if (this.user.User.Roles[0].Name === 'Facility Group') {
      this.hidevisible = true;
    }
    this.numberForm = this.formBuilder.group({
      number: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
    });
    this.AdminService.getAccountType().subscribe((data) => {
      this.allDeliveryStatus = data.Result;

      if (this.state.user.User.Roles[0].Name === 'Facility') {
        this.allDeliveryStatus = this.allDeliveryStatus.filter(
          (value) =>
            value.Name !== 'Facilitator' &&
            value.Name !== 'Driver' &&
            value.Name !== 'Non Profit' &&
            value.Name !== 'Admin' &&
            value.Name !== 'Facility Group'
        );
      }

      if (this.state.user.User.Roles[0].Name == 'Facilitator') {
        this.allDeliveryStatus = this.allDeliveryStatus.filter(
          (value) =>
            value.Name !== 'Facility Group' &&
            value.Name !== 'Facility' &&
            value.Name !== 'Driver' &&
            value.Name !== 'Non Profit' &&
            value.Name !== 'Admin'
        );
      }

      if (this.state.user.User.Roles[0].Name == 'Facility Group') {
        this.allDeliveryStatus = this.allDeliveryStatus.filter(
          (value) => value.Name !== 'Facilitator' && value.Name !== 'Driver' && value.Name !== 'Non Profit' && value.Name !== 'Admin'
        );
      }

      if (this.state.user.User.Roles[0].Name == 'Admin' || this.state.user.User.Roles[0].Name == 'Read-Only Admin') {
        let rolesTypeArray = [...this.allDeliveryStatus];
        // console.log(rolesTypeArray);
        this.allDeliveryStatus = [];

        rolesTypeArray.forEach((element) => {
          if (element.Name === 'Facility') {
            this.allDeliveryStatus[0] = element;
          } else if (element.Name === 'Facility Group') {
            this.allDeliveryStatus[1] = element;
          } else if (element.Name === 'Facilitator') {
            this.allDeliveryStatus[2] = element;
          } else if (element.Name === 'Non Profit') {
            this.allDeliveryStatus[3] = element;
          } else if (element.Name === 'Admin') {
            this.allDeliveryStatus[4] = element;
          } else if (element.Name === 'Driver') {
            this.allDeliveryStatus[5] = element;
          } else if (element.Name === 'Read-Only Admin') {
            this.allDeliveryStatus[6] = element;
          } else {
          }
        });
      }
      if (this.state.user.User.Roles[0].Name == 'Billing Admin') {
        let rolesTypeArray = [...this.allDeliveryStatus];
        // console.log(rolesTypeArray);
        this.allDeliveryStatus = [];

        rolesTypeArray.forEach((element) => {
          if (element.Name === 'Facility') {
            this.allDeliveryStatus[0] = element;
          } else if (element.Name === 'Facility Group') {
            this.allDeliveryStatus[1] = element;
          } else if (element.Name === 'Facilitator') {
            this.allDeliveryStatus[2] = element;
          } else if (element.Name === 'Non Profit') {
            this.allDeliveryStatus[3] = element;
          } else if (element.Name === 'Admin') {
            this.allDeliveryStatus[4] = element;
          } else if (element.Name === 'Driver') {
            this.allDeliveryStatus[5] = element;
          } else if (element.Name === 'Read-Only Admin') {
            this.allDeliveryStatus[6] = element;
          } else if (element.Name === 'Billing Admin') {
            this.allDeliveryStatus[7] = element;
          }
          else {
          }
        });
      }
    });
    (error) => {
      // this.state.isSpinnerVisible = false;
      // alert(JSON.stringify(error));
    };
    this.customerServices.getPickupTypeList().subscribe(
      (data) => {
        this.driverService = data.Result;
        // if(this.RoadiePostmatesFeature === true){//////
        this.driverService = data.Result.filter(
          (value) => value.Title !== 'Roadie' && value.Title !== 'Postmates' && value.Title !== 'Roadie/Postmates'
        );
        // }
        // if (this.SAASFeature === false) {
        //   this.driverService = data.Result.filter(
        //     value =>
        //       value.Title !== 'SAAS' &&
        //       value.Title !== 'Roadie' &&
        //       value.Title !== 'Postmates'
        //   );
        // }
        // for (const status of this.driverService) {
        //   if (this.info.driverService === status.ID) {
        //     if (status.Title === 'SAAS') {
        //       this.optiondriverStatus = true;
        //       this.selectedDriver = this.info.selectedDriver;
        //     } else {
        //       this.optiondriverStatus = false;
        //     }

        //   }
        // }
        // if(!this.info.driverService){
        //   this.driverSelected = this.driverService[0].ID;
        // }
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
    var spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
      if (this.info.facility.length > 0) {
        this.FacilityClients = this.info.facility.filter((value) => value.ApprovalStatus === 'Active');
        // this.spinnerService.stop(this.spinnerRef);
      }
      if (this.info.facilitatorGroup.length > 0) {
        if (this.state.user.User.Roles[0].Name === 'Facility Group') {
          this.facilityGroupClients = this.info.facilitatorGroup.filter((value) => value.ApprovalStatus[0].Title === 'Active');
        } else {
          this.facilityGroupClients = this.info.facilitatorGroup.filter((value) => value.ApprovalStatus === 'Active');
        }
      }
      if (this.info.facilitator.length > 0) {
        this.facilitatorClients = this.info.facilitator.filter((value) => value.ApprovalStatus === 'Active');
      }
      if (this.info.nonprofits.length > 0) {
        this.nonprofitsClients = this.info.nonprofits.filter((value) => value.ApprovalStatus === 'Active');
      }
      if (this.info.getallClients === true) {
        this.spinnerService.stop(spinnerRef);
      }
    });
    this.getStatusData();
    this.user = this.data;
    for (var i = 0; i < this.pickupstatesdata.length; i++) {
      if (this.pickupstatesdata[i].ID === this.user.StateId) {
        this.adminstate = this.pickupstatesdata[i].ID;
        this.admincity = this.user.CityId;
        this.selectedstate();
        // this.city = this.citydataID[i].Title;
      }
    }
    if (this.user.IsSAASDriver === true) {
      this.driverSelected = 'SAAS';
    } else {
      this.driverSelected = 'Goodr';
    }
    this.userRoles = this.user.Roles[0].Id;
    this.statusSelected = this.user.ApprovalStatusId;
    if (this.user.Roles.length > 0) {
      this.selectedRole = this.user.Roles[0].Name;
    }
    if (this.selectedRole === 'Facility') {
      this.notvalid = false;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = true;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.selectedType = [];
      this.FacilityClients = this.FacilityClients;
      for (var i = 0; i < this.user.Clients.length; i++) {
        this.selectedType.push(this.user.Clients[i].ClientId);
      }
    } else if (this.selectedRole === 'Facilitator') {
      this.notvalid = false;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = true;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.selectedType = [];
      this.facilitatorClients = this.facilitatorClients;
      for (var i = 0; i < this.user.Clients.length; i++) {
        this.selectedType.push(this.user.Clients[i].ClientId);
      }
    } else if (this.selectedRole === 'Facility Group') {
      this.notvalid = false;
      this.facility_Group = true;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.selectedType = [];
      this.facilityGroupClients = this.facilityGroupClients;
      for (var i = 0; i < this.user.Clients.length; i++) {
        this.selectedType.push(this.user.Clients[i].ClientId);
      }
    } else if (this.selectedRole === 'Non Profit') {
      this.notvalid = false;
      this.facility_Group = false;
      this.non_Profit = true;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
      this.selectnonprofitClient = [];
      this.nonprofitsClients = this.nonprofitsClients;
      this.selectnonprofitClient = this.user.Clients[0].ClientId;
    } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Billing Admin' || this.selectedRole === 'Read-Only Admin') {
      this.notvalid = true;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
    } else if (this.selectedRole === 'Driver') {
      this.notvalid = true;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = true;
      this.driver_serviceGDR = true;
    }
  }
  onSelectionChange(event) {
    if (event.value === 'option 1') {
      this.existingadmin = true;
      this.existingadminno = false;
    } else {
      this.existingadminno = true;
      this.existingadmin = false;
    }
  }
  onSelectionChangedriver() {
    this.driver_serviceGDR = true;
  }
  selectedstate() {
    this.sassService.pickupcitydetails(this.adminstate).subscribe((data) => {
      this.citydataID = data['Result'];
      for (var i = 0; i < this.citydataID.length; i++) {
        if (this.citydataID[i].ID === this.admincity) {
          this.admincity = this.citydataID[i].ID;
        }
      }
    });
  }
  selectedstatedset() {
    this.sassService.pickupcitydetails(this.adminstate).subscribe((data) => {
      this.citydataID = data['Result'];
      this.admincity = 'Select City';
    });
  }
  onSelectionChangeStatus(event) {
    this.statusSelected = event.value;
  }
  getStatusData() {
    this.AdminService.getStatus().subscribe((data) => {
      this.statusData = data;
      this.status = data.Result;
      this.status = data.Result.filter((value) => value.Title !== 'Trash');
    });
  }

  editAccountType(value, event) {
    this.selectedType = [];
    this.allDeliveryStatus.forEach((element) => {
      if (element.Name === value) {
        this.userRoles = element.Id;
      }
    });
    this.selectedRole = value;
    if (this.selectedRole === 'Facility') {
      this.notvalid = false;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = true;
      this.driver_service = false;
      this.driver_serviceGDR = false;
    } else if (this.selectedRole === 'Facilitator') {
      this.notvalid = false;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = true;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
    } else if (this.selectedRole === 'Facility Group') {
      this.notvalid = false;
      this.facility_Group = true;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
    } else if (this.selectedRole === 'Non Profit') {
      this.notvalid = false;
      this.facility_Group = false;
      this.non_Profit = true;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
    } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Billing Admin' || this.selectedRole === 'Read-Only Admin') {
      this.notvalid = true;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = false;
      this.driver_serviceGDR = false;
    } else if (this.selectedRole === 'Driver') {
      this.notvalid = true;
      this.facility_Group = false;
      this.non_Profit = false;
      this.facilitator = false;
      this.facility = false;
      this.driver_service = true;
      this.driver_serviceGDR = true;
    }
  }
  UpdateAccountType(value, event) {
    this.user.Clients = [{ ClientId: value }];
  }

  UpdateAccountRole(value) {
    this.user.Roles = [value];
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  validatePhoneNumber() {
    const phoneno = /^\d{10}$/;
    if (this.user.PhoneNumber.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  }
  EditUserDetails() {
    this.user.FirstName=this.user.FirstName.trim();
    this.user.LastName=this.user.LastName.trim();
    this.user.Email=this.user.Email.trim();
    this.user.PhoneNumber=this.user.PhoneNumber.trim();
    this.numberForm.controls.number.setValue(numberSetMask(this.user.PhoneNumber));
    // if (
    //   this.user.FirstName &&
    //   this.user.LastName &&
    //   this.user.Email
    // ) {
    if (this.user.FirstName) {
      if (this.user.LastName) {
        if (this.user.Email) {
          if (this.numberForm.valid) {
            if (this.selectedRole !== undefined) {
              if (this.selectedRole) {
                if (this.selectedRole === 'Driver') {
                  if (this.adminstate !== 'Select State' && this.adminstate !== undefined && this.adminstate !== null && this.adminstate) {
                    if (this.admincity !== 'Select City' && this.admincity !== undefined && this.admincity !== null && this.admincity) {
                      this.updateUserDetails();
                    } else {
                      this.requireFieldCity = true;
                      setTimeout(() => {
                        this.requireFieldCity = false;
                      }, 3000);
                    }
                  } else {
                    this.requireFieldState = true;
                    setTimeout(() => {
                      this.requireFieldState = false;
                    }, 3000);
                  }
                } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Billing Admin' || this.selectedRole === 'Read-Only Admin') {
                  this.updateUserDetails();
                } else if (this.selectedRole === 'Non Profit') {
                  if (this.selectnonprofitClient === undefined) {
                    this.requireFieldAccount = true;
                    setTimeout(() => {
                      this.requireFieldAccount = false;
                    }, 3000);
                  } else {
                    this.updateUserDetails();
                  }
                } else if (this.selectedRole === 'Facility' || 'Facilitator' || 'Facility Group') {
                  if (this.selectedType[0] === undefined) {
                    this.requireFieldAccount = true;
                    setTimeout(() => {
                      this.requireFieldAccount = false;
                    }, 3000);
                  } else {
                    this.updateUserDetails();
                  }
                }
              }
            }
          } else {
            this.requirednumber = true;
            setTimeout(() => {
              this.requirednumber = false;
            }, 3000);
          }
        } else {
          this.requireFieldEmail = true;
          setTimeout(() => {
            this.requireFieldEmail = false;
          }, 3000);
        }
      } else {
        this.requireFieldLast = true;
        setTimeout(() => {
          this.requireFieldLast = false;
        }, 3000);
      }
    } else {
      this.requireField = true;
      setTimeout(() => {
        this.requireField = false;
      }, 3000);
    }
  }
  updateUserDetails() {
    var spinnerRef = this.spinnerService.start();
    if (this.driver_serviceGDR === true) {
      this.admincity = this.admincity;
      this.adminstate = this.adminstate;
    } else {
      this.admincity = null;
      this.adminstate = null;
    }

    if (this.user.PhoneNumber === null) {
      this.number = this.user.PhoneNumber;
    } else {
      this.number = this.user.PhoneNumber.replace(/[_-]/g, '');
    }
    let user_Client = [];
    if (this.selectedRole === 'Facility') {
      for (var i = 0; i < this.selectedType.length; i++) {
        user_Client.push({
          ClientId: this.selectedType[i],
        });
      }
    }
    if (this.selectedRole === 'Facilitator') {
      for (var i = 0; i < this.selectedType.length; i++) {
        user_Client.push({
          ClientId: this.selectedType[i],
        });
      }
    }
    if (this.selectedRole === 'Non Profit') {
      user_Client.push({
        ClientId: this.selectnonprofitClient,
      });
    }
    if (this.selectedRole === 'Facility Group') {
      for (var i = 0; i < this.selectedType.length; i++) {
        user_Client.push({
          ClientId: this.selectedType[i],
        });
      }
    }
    if (this.selectedRole === 'Driver') {
      user_Client = [];
    }
    if (this.selectedRole === 'Admin' || this.selectedRole === 'Billing Admin') {
      user_Client = [];
    }
    if (this.driverSelected === 'SAAS') {
      this.IsSAASDriver = true;
    } else {
      this.IsSAASDriver = false;
    }
    const payload = {
      Id: this.user.Id,
      FirstName: this.capitailizepipe.transform(this.user.FirstName),
      LastName: this.capitailizepipe.transform(this.user.LastName),
      Title: this.user.Title,
      // ApprovalStatusId: this.user.ApprovalStatus[0].ID,
      ImageUrl: this.user.ImageUrl,
      Email: this.user.Email,
      PhoneNumber: this.number,
      UserName: this.user.UserName,
      UserId: this.user.UserId,
      SecurityStamp: this.user.SecurityStamp,
      AspNetRoles: [
        {
          Id: this.userRoles,
        },
      ],
      CityId: this.admincity,
      StateId: this.adminstate,
      User_Client: user_Client,
      PasswordHash: this.user.PasswordHash,
      ApprovalStatusId: this.statusSelected,
      WP_Pwd_Hash: this.user.WP_Pwd_Hash,
      WP_User_Id: this.user.WP_User_Id,
      IsSAASDriver: this.IsSAASDriver,
      EmailConfirmed: this.user.EmailConfirmed,
      PhoneNumberConfirmed: this.user.PhoneNumberConfirmed,
      TwoFactorEnabled: this.user.TwoFactorEnabled,
      LockoutEndDateUtc: this.user.LockoutEndDateUtc,
      LockoutEnabled: this.user.LockoutEnabled,
      AccessFailedCount: this.user.AccessFailedCount,
      ResetCode: this.user.ResetCode,
      CreatedBy: this.user.CreatedBy,
      CreatedOn: this.user.CreatedOn,
      ModifiedOn: this.user.ModifiedOn,
      ModifiedBy: this.user.ModifiedBy,
    };
    this.buttonDisabled = true;
    this.AdminService.updateUserDetails(payload).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
        this.dialogRef.close();
        this.state.isSpinnerVisible = false;
      },
      (error) => {
        this.spinnerService.stop(spinnerRef);
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
        this.buttonDisabled = false;
      }
    );
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.numberForm.controls[controlName].hasError(errorName);
  };
}
