import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { PickUps } from 'src/app/models/pickups.model';
import { getFeature } from '../../shared/services/featureGuard.model';
import { CustomerService } from '../../shared/services/customer.service';
import * as moment from 'moment-timezone';
import { AddeditDateComponent } from '../customers/addedit-date/addedit-date.component';
import { MatDialog } from '@angular/material';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ExcelDashboardService } from 'src/app/shared/services/excel-dashboard.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { Chart } from 'chart.js';
import { PickupsRequestComponent } from '../pickups/pickups-request/pickups-request.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  user: any;
  public chartType = 'bar';
  excel = [];
  imgSrc = 'assets/images/logo.png';
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  public doughnutChartType: string = 'doughnut';
  public doughnutChartOptions: any = {
    aspectRatio: 2,
    responsive: true,
    percentageInnerCutout: 50,
    cutoutPercentage: 90,
    plugin_chart_name: 'doughnut',
    animation: {
      animateScale: false,
      animateRotate: false,
    },
    legend: {
      labels: {
        fontColor: 'black',
        fontSize: 13,
        wordBreak: 'break-all',
      },
      position: 'right',
    },
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,

      custom: function (tooltipModel: any) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem: { lines: any }) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '<thead>';

          titleLines.forEach(function (title: string) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';

          bodyLines.forEach(function (body: string, i: string | number) {
            const num = body[0].split('-')[0];
            const span = '<span class="chartjs-tooltip-key" style="background:#000"></span>';
            innerHtml +=
              "<tr><td style='background:#000;padding: 0 5px 0 5px;margin-left:5;color:#fff'>" +
              span +
              num.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              ' - ' +
              body[0].split('-')[1].split(':')[0] +
              '</td></tr>';
          });
          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector('table');
          if (tableRoot) {
            tableRoot.innerHTML = innerHtml;
          }
        }

        // `this` will be the overall tooltip
        const position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        // tooltipEl.style.padding = "2px " + "2px";
        tooltipEl.style.pointerEvents = 'none';
      },
    },
  };
  public chartHovered(e: any): void { }

  public doughnutChartColors: Array<any> = [
    {
      backgroundColor: ['#6549AD', '#565F73', '#AAAFB9', '#F89C45', '#F0471E', '#89D02E'],
      hoverBackgroundColor: ['#6549AD', '#565F73', '#AAAFB9', '#F89C45', '#F0471E', '#89D02E'],
      borderWidth: 0,
    },
  ];
  public doughnutChartColorsOR: Array<any> = [
    {
      backgroundColor: ['#6549AD', '#565F73', '#AAAFB9', '#F89C45', '#F0471E', '#89D02E', '#bc5090', '#7CDDDD', '#007ED6', '#EC6B56'],
      hoverBackgroundColor: ['#6549AD', '#565F73', '#AAAFB9', '#F89C45', '#F0471E', '#89D02E', '#bc5090', '#7CDDDD', '#007ED6', '#EC6B56'],
      borderWidth: 0,
    },
  ];
  public doughnutChart: any = {
    responsive: true,
  };
  public doughnutCharDatasets: Array<any>;
  public doughnutCharDatasetsOR: Array<any>;
  public doughnutChartLabelsOR: Array<any>;

  public doughnutChartLabels: Array<any>;
  public chartDatasets: Array<any>;
  totalItems = [];
  public chartLabels: Array<any>;
  public chartColors: Array<any> = [
    {
      backgroundColor: '#89D02E',
      borderWidth: 0,
    },
  ];
  public chartOptionsMob: any = {
    responsive: true,
    FontSize: 6,
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: 8,
          },
          scaleLabel: {
            display: true,
            fontSize: 10,
            fontFamily: 'ProximaNova',
            fontStyle: 'bold',
            labelString: 'Date',
            stacked: true,
            barPercentage: 0.2,
            valueFormatString: 'MM-DD-YY',
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return '$ ' + value;
            },
          },
          scaleLabel: {
            display: true,
            stacked: true,
            barThickness: 4,
            // labelString: 'Estimated Tax Saving'
            fontSize: 10,
            fontFamily: 'ProximaNova',
            fontStyle: 'bold',
            labelString: 'Est Tax Savings',
          },
          stacked: true,
        },
      ],
    },
  };
  public chartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            fontSize: 20,
            fontFamily: 'ProximaNova',
            fontStyle: 'bold',
            labelString: 'Date',
            stacked: true,
            barPercentage: 0.2,
            valueFormatString: 'MM-DD-YY',
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
          scaleLabel: {
            display: true,
            stacked: true,
            barThickness: 4,
            // labelString: 'Estimated Tax Saving'
            fontSize: 20,
            fontFamily: 'ProximaNova',
            fontStyle: 'bold',
            labelString: 'Estimated Tax Savings',
          },
          stacked: true,
        },
      ],
    },
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,

      custom: function (tooltipModel: any) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem: { lines: any }) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '<thead>';

          titleLines.forEach(function (title: string) {
            innerHtml +=
              "<tr style='background:#000;padding: 0 5px 0 5px;margin:0;color:#fff;text-align:left'><th class='m-0' style='padding: 0 5px 0 5px'>" +
              title +
              '</th></tr>';
          });
          innerHtml += '</thead><tbody>';

          bodyLines.forEach(function (body: string, i: string | number) {
            const num = body[0].split(':')[0];
            const span = '<span class="chartjs-tooltip-key"></span>';
            innerHtml +=
              "<tr><td style='background:#000;padding: 0 5px 0 5px;margin:0;color:#fff'>" +
              span +
              num.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              ' - $' +
              Number(body[0].split(':')[1])
                .toFixed(2)
                .toString()
                .trimLeft()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              '</td></tr>';
          });
          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector('table');
          if (tableRoot) {
            tableRoot.innerHTML = innerHtml;
          }
        }

        // `this` will be the overall tooltip
        const position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        // tooltipEl.style.padding = "2px " + "2px";
        tooltipEl.style.pointerEvents = 'none';
      },
    },
  };
  clientdata: any;
  editDate: any;
  selectFromDate: string;
  clientId: any = 'all';
  selectedpickupType: any = 'Surplus Food';
  selecteToddate: string;
  finalFromDate: any;
  finalToDate: any;
  addfilter: boolean;
  dashboardGetData: PickUps;
  dashboardGetDataOR: PickUps;
  FromDate: string;
  ToDate: string;
  month = [];
  TotalSavings = [];
  RetailValue = [];
  exportableDashboardData = [];
  exportableDashboardDataOR = [];
  clientusers: any;
  spinnerRef: any;
  clientName: any;
  social_impact_heading = {};
  social_impact_headingOR = {};
  social_impact = {};
  social_impactOR = {};
  profileImage: any;
  PickupList: any;
  facilitatorId: any;
  facilityGroupId: any;
  dashboardGetDataFinal = [];
  dashboardGetDataFinalOR = [];
  PickupListData: any;
  pickupid: any;
  pickuptypeClients: any;
  featureinfo: any;
  OTPFeature: boolean;
  toataltaxsavingdate: any;
  isRequestPickupsVisible: boolean;
  sassinfodata: boolean = true;
  sassinfo: any;
  showClientDropdown: boolean = true;
  TotalMealCount: any;
  TotalFoodDelivered: any;
  TotalCarbonDioxide: any;

  constructor(
    private pickupService: PickUpService,
    private excelDashboardService: ExcelDashboardService,
    private ClientFilterdata: ClientFilterService,
    private AdminService: AdmintoolsService,
    private router: Router,
    private state: AppState,
    private spinnerService: SpinnerService,
    private CustomerService: CustomerService,
    public dialog: MatDialog
  ) {
    this.state.user = JSON.parse(localStorage.getItem('user')).user;
    (<any>window).godrEmail = this.state.user['User']['Email'];
    (<any>window).godrRole = this.state.user.User.Roles[0].Name;
    if (this.state.user.User.Roles[0].Name === 'Facility') {
      if (this.state.user.User.Clients[0].ClientType === 'SAAS Facility') {
        this.ClientFilterdata.basicInfosassObserve.subscribe((res) => {
          this.sassinfo = JSON.parse(res);
          this.sassinfodata = this.sassinfo.subscription;
        });
      }
    }
  }

  exportAsXLSX(): void {
    const exportableDashboardData: any = [];
    const exportableDashboardDataOR: any = [];
    const exportableDashboardDataGraph: any = [];
    const exportableDashboardDataClientName: any = [];
    const exportableDashboardDataClientNameOR: any = [];

    this.exportableDashboardData = [];
    this.exportableDashboardDataOR = [];

    if (
      this.user.User.Roles[0].Name === 'Facility' ||
      this.user.User.Roles[0].Name === 'Admin' ||
      this.user.User.Roles[0].Name === 'Facility Group' ||
      this.user.User.Roles[0].Name === 'Facilitator' || this.user.User.Roles[0].Name === 'Billing Admin' || this.user.User.Roles[0].Name === 'Read-Only Admin'
    ) {
      const Clients = this.user.User.Clients;
      for (const status of Clients) {
        if (status.ClientId == this.clientId) {
          this.clientName = status.Name;
        }
      }
      if (this.clientName) {
        this.clientName = this.clientName;
      } else {
        this.clientName = 'All Clients data';
      }
      const customer = {
        key: 'Customer Name',
        data: this.clientName,
        data1: '',
      };

      const social_impact_space = {
        key: '',
      };
      const social_impact_rollup = {
        key: 'Overview',
      };

      const Datedata = {
        key: 'Date Range',
        data: this.FromDate + ' to ' + this.ToDate,
        data1: '',
      };

      if (this.dashboardGetData) {
        this.exportableDashboardData = [];
        for (let i = 0; i < this.dashboardGetData['TotalWastedItems'].length; i++) {
          const clients_data = {
            'Most Wasted Item Rank': i + 1,
            'Food Item': this.dashboardGetData['TotalWastedItems'][i].ProductName,
            Quantity: this.dashboardGetData['TotalWastedItems'][i].Weight / 16,
          };
          this.exportableDashboardData.push(clients_data);
        }
        if (this.dashboardGetData['TotalSavings']) {
          for (let i = 0; i < this.dashboardGetData['TotalSavings'].length; i++) {
            const TaxSaving = {
              Date: this.dashboardGetData['TotalSavings'][i].CreatedOn,
              'Est Tax Saving': +this.dashboardGetData['TotalSavings'][i].TotalSaving,
            };
            exportableDashboardDataGraph.push(TaxSaving);
          }
          this.dashboardGetData['TotalSavings'].forEach((y) => {
            y.CreatedOn = moment(y.CreatedOn).format('MM/DD/YY');
            this.month.push(y.CreatedOn);
            this.TotalSavings.push(y.TotalSaving);
          });
        }
        exportableDashboardData.MealSearved = this.dashboardGetData['TotalMealCount'];
        exportableDashboardData.FoodDelivered = this.dashboardGetData['TotalFoodDelivered'];
        exportableDashboardData.CO2 = this.dashboardGetData['TotalCarbonDioxide'];

        // For SF Tab
        this.social_impact_heading = {
          key: 'Meals Served',
          data: 'Pounds Diverted from Landfill',
          data1: 'Pounds of CO2e Prevented',
        };
        this.social_impact = {
          key: this.dashboardGetData['TotalMealCount'],
          data: this.dashboardGetData['TotalFoodDelivered'],
          data1: this.dashboardGetData['TotalCarbonDioxide'],
        };
        exportableDashboardDataClientName.push(customer);
        exportableDashboardDataClientName.push(Datedata);
        exportableDashboardDataClientName.push(social_impact_space);
        exportableDashboardDataClientName.push(social_impact_rollup);
        exportableDashboardDataClientName.push(this.social_impact_heading);
        exportableDashboardDataClientName.push(this.social_impact);
      }
      // for OR Tab
      if (this.dashboardGetDataOR) {
        this.social_impact_headingOR = {
          key: '',
          data: 'Pounds of CO2e Prevented',
        };
        this.social_impactOR = {
          key: '',
          data: this.TotalFoodDelivered,
        };
        exportableDashboardDataClientNameOR.push(customer);
        exportableDashboardDataClientNameOR.push(Datedata);
        exportableDashboardDataClientNameOR.push(social_impact_space);
        exportableDashboardDataClientNameOR.push(social_impact_rollup);
        exportableDashboardDataClientNameOR.push(this.social_impact_headingOR);
        exportableDashboardDataClientNameOR.push(this.social_impactOR);

        this.exportableDashboardDataOR = [];
        for (let i = 0; i < this.dashboardGetDataOR['TotalWastedItems'].length; i++) {
          const clients_data = {
            'Most Wasted Item Rank': i + 1,
            'Food Item': this.dashboardGetDataOR['TotalWastedItems'][i].ProductName,
            Quantity: this.dashboardGetDataOR['TotalWastedItems'][i].Weight,
          };
          this.exportableDashboardDataOR.push(clients_data);
        }
        if (this.dashboardGetDataOR['TotalSavings']) {
          for (let i = 0; i < this.dashboardGetDataOR['TotalSavings'].length; i++) {
            const TaxSaving = {
              Date: this.dashboardGetDataOR['TotalSavings'][i].CreatedOn,
              'Est Tax Saving': +this.dashboardGetDataOR['TotalSavings'][i].TotalSaving,
            };
            exportableDashboardDataGraph.push(TaxSaving);
          }
          this.dashboardGetDataOR['TotalSavings'].forEach((y) => {
            y.CreatedOn = moment(y.CreatedOn).format('MM/DD/YY');
            this.month.push(y.CreatedOn);
            this.TotalSavings.push(y.TotalSaving);
            // this.RetailValue.push(y.RetailValue);
          });
        }
      }

      if (exportableDashboardDataClientNameOR.length == 0) {
        exportableDashboardDataClientNameOR.push(customer);
        exportableDashboardDataClientNameOR.push(Datedata);
        exportableDashboardDataClientNameOR.push(social_impact_space);
        exportableDashboardDataClientNameOR.push(social_impact_rollup);
        exportableDashboardDataClientNameOR.push({
          key: '',
          data: 'Pounds of CO2e Prevented',
        });
        exportableDashboardDataClientNameOR.push({
          data: 0,
          key: ''
        });
      }
      // } else {
      //   this.exportableDashboardData = [];
      // }
      // } else {
    }
    // }
    this.excelDashboardService.exportAsExcelFile(
      [this.exportableDashboardData, this.exportableDashboardDataOR],
      [exportableDashboardDataClientName, exportableDashboardDataClientNameOR],
      exportableDashboardDataGraph,
      this.selectedpickupType,
      'Report'
    );
  }
  ngOnInit() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    this.pickuptypeClients = localUser.user.User.Clients.filter((client) => client.ClientType !== 'Non Profit' && client.ClientType !== 'MRF');
    this.pickuptypeClients.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : ((b.Name.toLowerCase() > a.Name.toLowerCase()) ? -1 : 0))
    if (this.pickuptypeClients.length === 1)
      this.showClientDropdown = false;
    else
      this.showClientDropdown = true;

    this.isRequestPickupsVisible = getFeature('requestPickupView');
    // this.getAllClients();

    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'OTP' && element.EnableFeature === false) {
            this.OTPFeature = false;
          }
        }
      }
      // }
    });
    const now = new Date();
    // Till now Default date range prevMonthFirstDate - prevMonthLastDate but according requirment changed  Prsent date to Last year date
    const presentDate = new Date();
    var pastYear = now.getFullYear() - 1;
    const prevYearDate = now.setFullYear(pastYear);
    this.ToDate = moment(presentDate).format('MMM DD YYYY');
    this.FromDate = moment(prevYearDate).format('MMM DD YYYY');
    this.user = this.state.user;
    if (this.user.User.ImageUrl == null) {
      this.ImageUrlnull = true;
      this.ImageUrl = false;
    } else {
      this.ImageUrl = true;
      if (this.user.User.ImageUrl) {
        this.profileImage = this.user.User.ImageUrl;
      }
    }
    this.pickupType();
  }

  editDashboard() {
    this.router.navigate(['editDashboard']);
  }
  pickupType() {
    this.pickupService.getPickupFilterList().subscribe((data) => {
      this.PickupListData = data.Result;
      if (this.OTPFeature === false) {
        this.PickupListData = this.PickupListData.filter((picuptype) => {
          return picuptype.Title !== 'One Time Pickup';
        });
      }
      this.PickupList = this.PickupListData;
      for (const status of this.PickupListData) {
        if (status.Title === 'Food') {
          status.Title = 'Surplus Food';
        }
      }
      this.clientlist();
    });
  }
  clientlist() {
    if (this.pickuptypeClients.length === 1) {
      this.clientId = this.pickuptypeClients[0].ClientId;
      this.clientName = this.pickuptypeClients[0].Name;
      this.loadDashboardData(this.pickuptypeClients[0].ClientId);
    } else this.loadDashboardData('');
  }
  generateReport() {
    const doughnutChartDatasets = [];
    const doughnutChartLabels = [];
    let combinedFoodwasted = 0;
    if (this.dashboardGetData !== null) {
      this.dashboardGetData.TotalWastedItems.forEach((wastedItem) => {
        doughnutChartDatasets.push(wastedItem.Weight / 16);
        combinedFoodwasted = combinedFoodwasted + wastedItem.Weight / 16;
        let customLabel = wastedItem.ProductName;
        if (customLabel.length > 16) customLabel = customLabel.substring(0, 16) + '...';

        return doughnutChartLabels.push(
          customLabel +
          ' - ' +
          (wastedItem.Weight / 16)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          ' lbs'
        );
      });
      let totalWastage = this.dashboardGetData['TotalFoodDelivered'] - combinedFoodwasted;
      doughnutChartDatasets.push(totalWastage);
      this.doughnutCharDatasets = [
        {
          data: doughnutChartDatasets,
          labels:
            doughnutChartLabels.length > 0
              ? doughnutChartLabels.push(
                'Others - ' +
                totalWastage
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                ' lbs'
              )
              : doughnutChartLabels,
        },
      ];
      this.doughnutChartLabels = doughnutChartLabels;

      this.chartDatasets = [
        {
          data: this.TotalSavings,
          label: 'Total Savings',
        },
        // {
        //   data: this.RetailValue,
        //   label: 'Retail Value',
        // },
      ];
      this.chartLabels = this.month;
      if (this.TotalSavings.length === 0) {
        this.dashboardGetData['TotalSavingsMaxValue'] = 0;
        this.dashboardGetData['TotalSavingsMinValue'] = 0;
      }
    }
  }
  addEditdate() {
    const dialogRef = this.dialog.open(AddeditDateComponent, {
      width: '800px',
      // height: '500px',
      panelClass: 'custom-datedialog-container',
      data: [
        {
          FromDate: this.FromDate,
          ToDate: this.ToDate,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.editDate = result;
      if (this.editDate != undefined) {
        this.FromDate = moment(this.editDate.From).format('MMM DD YYYY');
        this.ToDate = moment(this.editDate.To).format('MMM DD YYYY');
        // this.spinnerRef = this.spinnerService.start();
        if (this.clientId === 'all') {
          this.getDashboardData(null);
          this.PickupList = this.PickupListData;
        } else {
          this.getDashboardData(this.clientId);
        }
      }
    });
  }
  loadDashboardData(val) {
    // this.spinnerRef = this.spinnerService.start();
    this.TotalSavings = [];
    this.month = [];
    this.clientName = '';
    // this.clientlist();
    if (this.clientId === 'all') {
      this.selectedpickupType = 'Surplus Food';
      this.getDashboardData(null);
      this.PickupList = this.PickupListData;
    } else {
      this.getDashboardData(this.clientId);
    }
  }

  getDashboardData(clientId) {
    let facilityGroupId = null;
    let facilitatorId = null;
    if (this.clientId === 'all') {
      clientId = null;
      this.pullDeliveriesData(facilitatorId, facilityGroupId, clientId, this.selectedpickupType);
    } else {
      for (let i = 0; i < this.pickuptypeClients.length; i++) {
        if (this.pickuptypeClients[i].ClientId == clientId) {
          if (this.pickuptypeClients[i].PickupType !== null) {
            if (this.pickuptypeClients[i].PickupType.length > 0) {
              this.selectedpickupType = this.pickuptypeClients[i].PickupType[0];
            }
            //  else {
            //   this.selectedpickupType = null;
            // }
          }
          // else {
          //   this.selectedpickupType = null;
          // }
          this.clientName = this.pickuptypeClients[i].Name;
          this.facilityGroupId = null;
          this.facilitatorId = null;
          if (this.pickuptypeClients[i].ClientType === 'Facility Group') {
            this.facilityGroupId = clientId;
            clientId = null;
          } else if (this.pickuptypeClients[i].ClientType === 'Facilitator') {
            this.facilitatorId = clientId;
            clientId = null;
          }
          this.pullDeliveriesData(this.facilitatorId, this.facilityGroupId, clientId, this.selectedpickupType);
        }
      }
    }
  }

  loadPickupData() {
    this.spinnerRef = this.spinnerService.start();
    this.clientlist();
    this.clientId = 'all';
    if (this.clientId === 'all') {
      this.getDashboardData(null);
    }
  }
  pullDeliveriesData(facilitatorId, facilityGroupId, clientId, selectedpickupType) {
    this.spinnerRef = this.spinnerService.start();
    if (selectedpickupType == 'Food') {
      selectedpickupType = 'Surplus Food';
    }
    for (const status of this.PickupListData) {
      if (status.Title === selectedpickupType) {
        this.pickupid = status.ID;
        this.selectedpickupType = status.ID
      }
    }
    if (getFeature('dashboard') === true) {
      this.clientusers = this.user.User.Clients;
      this.month = [];
      this.TotalSavings = [];
      const selectedDate = new Date(this.FromDate);
      const ToDate = new Date(this.ToDate);

      ToDate.setHours(23);
      ToDate.setMinutes(59);
      ToDate.setSeconds(59);
      selectedDate.setHours(0);
      selectedDate.setMinutes(0);
      selectedDate.setSeconds(0);

      const selectedNDate = new Date(selectedDate);
      const selectedEndNDate = new Date(ToDate);
      const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
      const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];
      this.finalFromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
      this.finalToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';

      if (facilityGroupId !== null || facilitatorId !== null) {
        this.selectedpickupType = 1;
      }
      this.pickupService.dashboardData(clientId, facilityGroupId, facilitatorId, this.finalFromDate, this.finalToDate, this.pickupid).subscribe(
        (data) => {
          this.dashboardGetData = data['SFDashboard'];
          this.dashboardGetDataOR = data['ORDashboard'];

          if (data['SFDashboard'] !== null && data['ORDashboard'] !== null) {
            let MealCount1 = data['SFDashboard'].TotalMealCount && Number(data['SFDashboard'].TotalMealCount);
            // let MealCount2 = data['ORDashboard'].TotalMealCount && Number(data['ORDashboard'].TotalMealCount)
            this.TotalMealCount = MealCount1;


            let FoodDelivered1 = data['SFDashboard'].TotalFoodDelivered && Number(data['SFDashboard'].TotalFoodDelivered);
            let FoodDelivered2 = data['ORDashboard'].TotalFoodDelivered && Number(data['ORDashboard'].TotalFoodDelivered);
            let MSW = 0;
            data['ORDashboard'].TotalWastedItems.forEach((itm) => {
              if (itm.ProductName === 'Municipal Solid Waste (MSW)') {
                MSW = itm.Weight;
              }
            });
            this.TotalFoodDelivered = FoodDelivered1 + FoodDelivered2 - MSW;

            let CarbonDioxide1 = data['SFDashboard'].TotalCarbonDioxide && Number(data['SFDashboard'].TotalCarbonDioxide);
            let CarbonDioxide2 = data['ORDashboard'].TotalCarbonDioxide && Number(data['ORDashboard'].TotalCarbonDioxide)
            this.TotalCarbonDioxide = CarbonDioxide1 + CarbonDioxide2;
            if (this.dashboardGetData['TotalSavings']) {
              if (this.dashboardGetData['TotalSavings'].length > 0) {
                const taxvaluedate = this.dashboardGetData['TotalSavings'][this.dashboardGetData['TotalSavings'].length - 1];
                this.toataltaxsavingdate = taxvaluedate.CreatedOn;
              }
            }
            this.dashboardGetData['TotalSavings'].forEach((y) => {
              y.CreatedOn = moment(y.CreatedOn).format('MM/DD/YY');
              this.month.push(y.CreatedOn);
              this.TotalSavings.push(y.TotalSaving);
            });
            this.dashboardGetDataOR['TotalDefaulitItems'] = [
              {
                ProductName: 'Organic',
                imgSrc: '../../assets/images/organic.png',
                Weight: '0',
              },
              {
                ProductName: 'Paper',
                imgSrc: '../../assets/images/paper.png',
                Weight: '0',
              },
              {
                ProductName: 'Cardboard',
                imgSrc: '../../assets/images/cardboard.png',
                Weight: '0',
              },
              {
                ProductName: 'Plastic',
                imgSrc: '../../assets/images/plastic.png',
                Weight: '0',
              },
              {
                ProductName: 'Aluminum',
                imgSrc: '../../assets/images/aluminium.png',
                Weight: '0',
              },
              {
                ProductName: 'Pallets',
                imgSrc: '../../assets/images/pallets.png',
                Weight: '0',
              },
              {
                ProductName: 'Single Stream',
                imgSrc: '../../assets/images/combined.png',
                Weight: '0',
              },
              {
                ProductName: 'Glass',
                imgSrc: '',
                Weight: '0',
              },
              {
                ProductName: 'Organics',
                imgSrc: '',
                Weight: '0',
              },
            ];
            if (this.dashboardGetDataOR['TotalWastedItems'].length > 0) {
              this.dashboardGetDataOR['TotalWastedItems'].map((value) => {
                if (value.ProductName === 'Organic') {
                  value.imgSrc = '../../assets/images/organic.png';
                } else if (value.ProductName === 'Paper') {
                  value.imgSrc = '../../assets/images/paper.png';
                } else if (value.ProductName === 'Cardboard') {
                  value.imgSrc = '../../assets/images/cardboard.png';
                } else if (value.ProductName === 'Plastic') {
                  value.imgSrc = '../../assets/images/plastic.png';
                } else if (value.ProductName === 'Aluminum') {
                  value.imgSrc = '../../assets/images/aluminium.png';
                } else if (value.ProductName === 'Pallets') {
                  value.imgSrc = '../../assets/images/pallets.png';
                } else if (value.ProductName === 'Single Stream') {
                  value.imgSrc = '../../assets/images/combined.png';
                } else if (value.ProductName === 'Glass' || value.ProductName === 'Organics') {
                  value.imgSrc = '';
                }
              });
              this.dashboardGetDataFinalOR = [];
              for (let index = 0; index < this.dashboardGetDataOR['TotalDefaulitItems'].length; index++) {
                const month = this.dashboardGetDataOR['TotalDefaulitItems'][index];
                let filter = this.dashboardGetDataOR['TotalWastedItems'].filter((item) => {
                  return item.ProductName == month.ProductName;
                });
                if (filter.length > 0) {
                  this.dashboardGetDataFinalOR.push(filter[0]);

                  const doughnutChartDatasetsOR = [];
                  const doughnutChartLabelsOR = [];
                  let combinedFoodwasted = 0;
                  this.dashboardGetDataOR.TotalWastedItems.forEach((wastedItem) => {
                    doughnutChartDatasetsOR.push(wastedItem.Weight);
                    combinedFoodwasted = combinedFoodwasted + wastedItem.Weight;
                    let customLabel = wastedItem.ProductName;
                    if (customLabel !== null && customLabel.length > 16) customLabel = customLabel.substring(0, 16) + '...';

                    return doughnutChartLabelsOR.push(
                      customLabel +
                      ' - ' +
                      (wastedItem.Weight)
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                      ' lbs'
                    );
                  });
                  this.doughnutCharDatasetsOR = [
                    {
                      data: doughnutChartDatasetsOR,
                      labels: doughnutChartLabelsOR,
                    },
                  ];
                  this.doughnutChartLabelsOR = doughnutChartLabelsOR;
                }
              }
            } else {
              this.dashboardGetDataFinalOR = this.dashboardGetDataOR['TotalDefaulitItems'];
            }
            this.totalItems = this.dashboardGetDataFinalOR.filter((data) => {
              return data.ProductName !== 'Combined';
            });
            this.totalItems.sort(function (a, b) {
              return b.Weight - a.Weight;
            });
            this.generateReport();
          } else if (data['SFDashboard'] !== null) {
            this.TotalMealCount = data['SFDashboard'].TotalMealCount && data['SFDashboard'].TotalMealCount;
            this.TotalFoodDelivered = data['SFDashboard'].TotalFoodDelivered && data['SFDashboard'].TotalFoodDelivered;
            this.TotalCarbonDioxide = data['SFDashboard'].TotalCarbonDioxide && data['SFDashboard'].TotalCarbonDioxide;
            if (this.dashboardGetData['TotalSavings']) {
              if (this.dashboardGetData['TotalSavings'].length > 0) {
                const taxvaluedate = this.dashboardGetData['TotalSavings'][this.dashboardGetData['TotalSavings'].length - 1];
                this.toataltaxsavingdate = taxvaluedate.CreatedOn;
              }
            }
            this.dashboardGetData['TotalSavings'].forEach((y) => {
              y.CreatedOn = moment(y.CreatedOn).format('MM/DD/YY');
              this.month.push(y.CreatedOn);
              this.TotalSavings.push(y.TotalSaving);
            });
            this.generateReport();
          } else if (data['ORDashboard'] !== null) {
            this.TotalMealCount = data['ORDashboard'].TotalMealCount && data['ORDashboard'].TotalMealCount;
            let MSW = 0;
            data['ORDashboard'].TotalWastedItems.forEach((itm) => {
              if (itm.ProductName === 'Municipal Solid Waste (MSW)') {
                MSW = itm.Weight;
              }
            });
            this.TotalFoodDelivered = Number(data['ORDashboard'].TotalFoodDelivered && data['ORDashboard'].TotalFoodDelivered) - Number(MSW);
            this.TotalCarbonDioxide = data['ORDashboard'].TotalCarbonDioxide && data['ORDashboard'].TotalCarbonDioxide;
            this.dashboardGetDataOR['TotalDefaulitItems'] = [
              {
                ProductName: 'Organic',
                imgSrc: '../../assets/images/organic.png',
                Weight: '0',
              },
              {
                ProductName: 'Paper',
                imgSrc: '../../assets/images/paper.png',
                Weight: '0',
              },
              {
                ProductName: 'Cardboard',
                imgSrc: '../../assets/images/cardboard.png',
                Weight: '0',
              },
              {
                ProductName: 'Plastic',
                imgSrc: '../../assets/images/plastic.png',
                Weight: '0',
              },
              {
                ProductName: 'Aluminum',
                imgSrc: '../../assets/images/aluminium.png',
                Weight: '0',
              },
              {
                ProductName: 'Pallets',
                imgSrc: '../../assets/images/pallets.png',
                Weight: '0',
              },
              {
                ProductName: 'Single Stream',
                imgSrc: '../../assets/images/combined.png',
                Weight: '0',
              },
              {
                ProductName: 'Glass',
                imgSrc: '',
                Weight: '0',
              },
              {
                ProductName: 'Organics',
                imgSrc: '',
                Weight: '0',
              },
            ];
            if (this.dashboardGetDataOR['TotalWastedItems'].length > 0) {
              this.dashboardGetDataOR['TotalWastedItems'].map((value) => {
                if (value.ProductName === 'Organic') {
                  value.imgSrc = '../../assets/images/organic.png';
                } else if (value.ProductName === 'Paper') {
                  value.imgSrc = '../../assets/images/paper.png';
                } else if (value.ProductName === 'Cardboard') {
                  value.imgSrc = '../../assets/images/cardboard.png';
                } else if (value.ProductName === 'Plastic') {
                  value.imgSrc = '../../assets/images/plastic.png';
                } else if (value.ProductName === 'Aluminum') {
                  value.imgSrc = '../../assets/images/aluminium.png';
                } else if (value.ProductName === 'Pallets') {
                  value.imgSrc = '../../assets/images/pallets.png';
                } else if (value.ProductName === 'Single Stream') {
                  value.imgSrc = '../../assets/images/combined.png';
                } else if (value.ProductName === 'Glass' || value.ProductName === 'Organics') {
                  value.imgSrc = '';
                }
              });
              this.dashboardGetDataFinalOR = [];
              for (let index = 0; index < this.dashboardGetDataOR['TotalDefaulitItems'].length; index++) {
                const month = this.dashboardGetDataOR['TotalDefaulitItems'][index];
                let filter = this.dashboardGetDataOR['TotalWastedItems'].filter((item) => {
                  return item.ProductName == month.ProductName;
                });
                if (filter.length > 0) {
                  this.dashboardGetDataFinalOR.push(filter[0]);

                  const doughnutChartDatasetsOR = [];
                  const doughnutChartLabelsOR = [];
                  let combinedFoodwasted = 0;
                  this.dashboardGetDataOR.TotalWastedItems.forEach((wastedItem) => {
                    doughnutChartDatasetsOR.push(wastedItem.Weight);
                    combinedFoodwasted = combinedFoodwasted + wastedItem.Weight;
                    let customLabel = wastedItem.ProductName;
                    if (customLabel !== null && customLabel.length > 16) customLabel = customLabel.substring(0, 16) + '...';

                    return doughnutChartLabelsOR.push(
                      customLabel +
                      ' - ' +
                      (wastedItem.Weight)
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                      ' lbs'
                    );
                  });
                  this.doughnutCharDatasetsOR = [
                    {
                      data: doughnutChartDatasetsOR,
                      labels: doughnutChartLabelsOR,
                    },
                  ];
                  this.doughnutChartLabelsOR = doughnutChartLabelsOR;
                }
              }
            } else {
              this.dashboardGetDataFinalOR = this.dashboardGetDataOR['TotalDefaulitItems'];
            }
            this.totalItems = this.dashboardGetDataFinalOR.filter((data) => {
              return data.ProductName !== 'Combined';
            });
            this.totalItems.sort(function (a, b) {
              return b.Weight - a.Weight;
            });
            this.generateReport();
          } else if (data['SFDashboard'] == null && data['ORDashboard'] == null) {
            this.selectedpickupType = null;
            this.TotalMealCount = 0;
            this.TotalFoodDelivered = 0;
            this.TotalCarbonDioxide = 0;
          }
          this.spinnerService.stop(this.spinnerRef);
        },
        (error) => {
          this.spinnerService.stop(this.spinnerRef);
        }
      );
      // }
      this.spinnerService.stop(this.spinnerRef);
    }
    this.spinnerService.stop(this.spinnerRef);
  }
  pickupRequest() {
    const dialogRef = this.dialog.open(PickupsRequestComponent, {
      width: '1400px',
      height: '87vh',
      panelClass: 'pickup-dialog-container',
    });
    dialogRef.afterClosed().subscribe((res) => { });
  }
}
