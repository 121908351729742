import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ConditionalExpr } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { SpinnerService } from 'src/app/core/spinner.service';

@Component({
  selector: 'app-delete-grab-menu',
  templateUrl: './delete-grab-menu.component.html',
  styleUrls: ['./delete-grab-menu.component.scss']
})
export class DeleteGrabMenuComponent implements OnInit {
  buttonDisabled: boolean;
  sortColumn: any = null;
  Menus: any;
  RecordCount: any;
  ProductArray = [];
  pageSize: number;
  MenuCount: any;
  dataSource: any;
  constructor(
    public dialogRef: MatDialogRef<DeleteGrabMenuComponent>,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private state: AppState,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private CustomerService: CustomerService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {}
  deletemenu() {
    if (this.data['Count'] === 0) {
      this.dialogRef.close();
      alert("Zero Item's Deleted");
    } else {
      this.deletemenus();
    }
  }
  async deletemenus() {
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getAllFacilityMenus(
      1,
      1000,
      this.data['clientId'],
      this.data['sortColumn']
    ).subscribe(data => {
      this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result;
      this.MenuCount = data.RecordCount;
      this.dataSource = this.Menus;
      if (data.Result.length === 0) {
        this.dialogRef.close('res');
        // alert("Zero Item's Deleted");
      } else {
        for (var i = 0; i < data.Result.length; i++) {
          var product_ID = data.Result[i].ProductId;
          this.ProductArray.push(product_ID);
        }
        // alert("Are you sure you want to delete the Item ?");
        let j = 0;
        for (var i = 0; i < this.ProductArray.length; i++) {
          // console.log(i, this.ProductArray.length);
          this.CustomerService.deleteItem(this.ProductArray[i]).subscribe(
            data => {
              j++;
              if (j === this.ProductArray.length) {
                this.dialogRef.close('res');
              }
            }
          );
        }
        // this.dialogRef.close(this.MenuCount);
      }
    });
  }
}
