import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-success-dialog',
  templateUrl: './forgot-success-dialog.component.html',
  styleUrls: ['./forgot-success-dialog.component.scss']
})
export class ForgotSuccessDialogComponent implements OnInit {
  selectedDialog: string;
  constructor(private router: Router,
              public dialogRef: MatDialogRef<ForgotSuccessDialogComponent>,
              public dialog: MatDialog) { }
  openEmojiDialog() {
    this.dialogRef.close(this.selectedDialog);
  }
  confirmSelection() {
    this.dialogRef.close(this.selectedDialog);
  }
  ngOnInit() {
  }

}
