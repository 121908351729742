import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppState } from '../app-state';
import { GlobalService } from '../app.service';
import { Observable } from 'rxjs';
import { pricingmodulesaas } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class PricingModuleServiceService {
  constructor(
    private http: HttpClient,
    public state: AppState,
    private globalService: GlobalService
  ) {}

  getUserToken() {
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(
        localStorage.getItem('user')
      ).isLoggedIn;
      this.state.user = JSON.parse(localStorage.getItem('user')).user;
      return this.state.user.User.Id;
    } else {
      this.state.isLoggedIn = false;
      this.state.user = null;
      return '';
    }
  }

  token = this.getUserToken();

  editSAASPricingModule(requestDetails): Observable<pricingmodulesaas> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        UID: this.token,
      }),
    };
    return this.http
      .put<pricingmodulesaas>(
        this.globalService.SAASPlansput,
        requestDetails,
        httpOptions
      )
      .pipe();
  }

  getPrcingSAAS(): Observable<pricingmodulesaas> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get<pricingmodulesaas>(this.globalService.SAASPlans, httpOptions)
      .pipe();
  }

  getsubscriptionSAAS(StripePlanId): Observable<pricingmodulesaas> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get<pricingmodulesaas>(
        this.globalService.SAASPlanStripePlanId + StripePlanId,
        httpOptions
      )
      .pipe();
  }
}
