import { Component, OnInit, Inject } from '@angular/core';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { OtpPricingDeletePopupComponent } from '../../Otp/otp-pricing-delete-popup/otp-pricing-delete-popup.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
@Component({
  selector: 'app-saas-pricing-module',
  templateUrl: './saas-pricing-module.component.html',
  styleUrls: ['./saas-pricing-module.component.scss'],
})
export class SaasPricingModuleComponent implements OnInit {
  surplusfood: boolean;
  organic: boolean;
  surplusnorganic: boolean;
  combinedMonthly: number;
  combinedYearly: number;
  combinedMonthlyId: number;
  combinedYearlyId: number;
  combinedMonthlyStripeId: any = '';
  combinedYearlyStripeId: any = '';

  surplusmonthly: number;
  surplusannual: number;
  surplusmonthlyId: number;
  surplusannualId: number;
  surplusMonthlyStripeId: any = '';
  surplusAnnualStripeId: any = '';

  organicMonthly: number;
  organicAnnual: number;
  organicMonthlyId: number;
  organicAnnualId: number;
  organicMonthlySpripeId: any = '';
  organicAnnualSpripeId: any = '';

  changedValue = true;
  changedValueyearly = true;
  enterValue: boolean;
  getresultValue: any;
  approvalMonthly: any;
  StripePlanIdMonthly: any;
  approvalYearly: any;
  StripePlanIdYearly: any;
  changedValueyes = true;
  changedValueyearlyyes = true;
  enteryearlyValue: boolean;

  combinedMonthError: boolean;
  combinedYearError: boolean;
  surplusMonthError: boolean;
  surplusYearError: boolean;
  organicMonthError: boolean;
  organicYearError: boolean;

  sfmChangedValue: boolean = true;
  sfyChangedValue: boolean = true;
  ormChangedValue: boolean = true;
  oryChangedValue: boolean = true;

  constructor(
    private pricingModule: PricingModuleServiceService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.displaysurplusfoodnorganic();
    this.getPricingRates();
  }

  getPricingRates() {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      this.getresultValue = data['Result'];
      let pricedata;
      pricedata = data['Result'];
      for (const status of pricedata) {
        // console.log(status);

        if (
          status.CombinedPickup === true &&
          status.SubscriptionType === 'month' &&
          status.PickupTypeId === null
        ) {
          this.combinedMonthly = status.Price;
          this.combinedMonthlyStripeId = status.StripePlanId;
          this.combinedMonthlyId = status.ID;
        }

        if (
          status.CombinedPickup === true &&
          status.SubscriptionType === 'year' &&
          status.PickupTypeId === null
        ) {
          this.combinedYearly = status.Price;
          this.combinedYearlyStripeId = status.StripePlanId;
          this.combinedYearlyId = status.ID;
        }

        if (
          status.CombinedPickup === false &&
          status.SubscriptionType === 'month' &&
          status.PickupTypeId === 1
        ) {
          this.surplusmonthly = status.Price;
          this.surplusMonthlyStripeId = status.StripePlanId;
          this.surplusmonthlyId = status.ID;
        }

        if (
          status.CombinedPickup == false &&
          status.SubscriptionType === 'year' &&
          status.PickupTypeId === 1
        ) {
          this.surplusannual = status.Price;
          this.surplusAnnualStripeId = status.StripePlanId;
          this.surplusannualId = status.ID;
        }

        if (
          status.CombinedPickup === false &&
          status.SubscriptionType === 'month' &&
          status.PickupTypeId === 2
        ) {
          this.organicMonthly = status.Price;
          this.organicMonthlySpripeId = status.StripePlanId;
          this.organicMonthlyId = status.ID;
        }

        if (
          status.CombinedPickup === false &&
          status.SubscriptionType === 'year' &&
          status.PickupTypeId === 2
        ) {
          this.organicAnnual = status.Price;
          this.organicAnnualSpripeId = status.StripePlanId;
          this.organicAnnualId = status.ID;
        }
      }
      //this.surplusnorganicmonthly = this.data.Result[0].Price;
    });
  }
  onSearchChangeinput() {
    this.changedValueyes = false;
  }
  onSearchChangeyearlyinput() {
    this.changedValueyearlyyes = false;
  }
  onSearchChange() {
    this.changedValue = false;
  }
  onSearchChangeyearly(event, transaction) {
    this.changedValueyearly = false;
  }

  editSurplusMonthly() {
    this.sfmChangedValue = false;
  }
  editSurplusYearly() {
    this.sfyChangedValue = false;
  }
  editORMonthly() {
    this.ormChangedValue = false;
  }
  editORYearly() {
    this.oryChangedValue = false;
  }

  displaysurplusfoodnorganic() {
    this.surplusfood = false;
    this.organic = false;
    this.surplusnorganic = true;
  }
  confirmPopup(type, pickupType) {
    if (pickupType === 'combined') {
      if (type === 'monthly') {
        if (
          this.combinedMonthly === null ||
          this.combinedMonthly === undefined ||
          this.combinedMonthly === 0
        ) {
          this.combinedMonthError = true;
          setTimeout(() => {
            this.combinedMonthError = false;
          }, 3000);
        } else {
          this.confirmationPopup(
            this.combinedMonthly,
            type,
            pickupType,
            this.combinedMonthlyId,
            this.combinedMonthlyStripeId
          );
        }
      }
      if (type === 'yearly') {
        if (
          this.combinedYearly === null ||
          this.combinedYearly === undefined ||
          this.combinedYearly === 0
        ) {
          this.combinedYearError = true;
          setTimeout(() => {
            this.combinedYearError = false;
          }, 3000);
        } else {
          this.confirmationPopup(
            this.combinedYearly,
            type,
            pickupType,
            this.combinedYearlyId,
            this.combinedYearlyStripeId
          );
        }
      }
    } else if (pickupType === 'surplus') {
      if (type === 'monthly') {
        if (
          this.surplusmonthly === null ||
          this.surplusmonthly === undefined ||
          this.surplusmonthly === 0
        ) {
          this.surplusMonthError = true;
          setTimeout(() => {
            this.surplusMonthError = false;
          }, 3000);
        } else {
          this.confirmationPopup(
            this.surplusmonthly,
            type,
            pickupType,
            this.surplusmonthlyId,
            this.surplusMonthlyStripeId
          );
        }
      }

      if (type === 'yearly') {
        if (
          this.surplusannual === null ||
          this.surplusannual === undefined ||
          this.surplusannual === 0
        ) {
          this.surplusYearError = true;
          setTimeout(() => {
            this.surplusYearError = false;
          }, 3000);
        } else {
          this.confirmationPopup(
            this.surplusannual,
            type,
            pickupType,
            this.surplusannualId,
            this.surplusAnnualStripeId
          );
        }
      }
    } else if (pickupType === 'organic') {
      if (type === 'monthly') {
        if (
          this.organicMonthly === null ||
          this.organicMonthly === undefined ||
          this.organicMonthly === 0
        ) {
          this.organicMonthError = true;
          setTimeout(() => {
            this.organicMonthError = false;
          }, 3000);
        } else {
          this.confirmationPopup(
            this.organicMonthly,
            type,
            pickupType,
            this.organicMonthlyId,
            this.organicMonthlySpripeId
          );
        }
      }

      if (type === 'yearly') {
        if (
          this.organicAnnual === null ||
          this.organicAnnual === undefined ||
          this.organicAnnual === 0
        ) {
          this.organicYearError = true;
          setTimeout(() => {
            this.organicYearError = false;
          }, 3000);
        } else {
          this.confirmationPopup(
            this.organicAnnual,
            type,
            pickupType,
            this.organicAnnualId,
            this.organicAnnualSpripeId
          );
        }
      }
    } else {
      alert('Something went wrong');
    }
  }

  confirmationPopup(value, type, pickupType, id, stripeId) {
    const dialogRef = this.dialog.open(OtpPricingDeletePopupComponent, {
      width: '500px',
      // height: '440px',
      data: [
        {
          confirmMsg:
            'You have changed the ' + type + ' subscription cost to $' + value,
        },
      ],
      panelClass: 'custom-editSub-container',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.editSubsscriptionCost(value, type, pickupType, id, stripeId);
      }
    });
  }

  displaysurplusfood() {
    this.surplusfood = true;
    this.organic = false;
    this.surplusnorganic = false;
  }

  displayorganic() {
    this.surplusfood = false;
    this.organic = true;
    this.surplusnorganic = false;
  }

  editSubsscriptionCost(value, type, pickupType, id, stripeId) {
    let payload = {
      ID: id,
      SubscriptionType: type === 'monthly' ? 'month' : 'year',
      Price: value,
      StripePlanId: stripeId,
      ApprovalStatusId: 2,
      PickupTypeId:
        pickupType === 'surplus' ? 1 : pickupType === 'organic' ? 2 : null,
      CombinedPickup: pickupType === 'combined' ? true : false,
    };

    this.pricingModule.editSAASPricingModule(payload).subscribe((data) => {
      this.disableInput();
      this.getPricingRates();
    });
  }

  disableInput() {
    this.changedValue = true;
    this.changedValueyearly = true;
    this.sfmChangedValue = true;
    this.sfyChangedValue = true;
    this.ormChangedValue = true;
    this.oryChangedValue = true;
  }
}
