import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class MyOrderByPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] && b[field]) {
        a[field] = a[field];
        b[field] = b[field];
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    for (var i = 0; i < array.length; i++) {
      if (array[i][field] !== null && array[i][field]) {
        array[i][field] = array[i][field].charAt(0) + array[i][field].substr(1);
      }
    }
    return array;
  }
}
