import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ProfileService } from 'src/app/shared/services/profilesetting.service';

@Component({
  selector: 'app-emailchangedialog',
  templateUrl: './emailchangedialog.component.html',
  styleUrls: ['./emailchangedialog.component.scss']
})
export class EmailchangedialogComponent implements OnInit {
  public emailForm: FormGroup;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<EmailchangedialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MatDialog, public profileService: ProfileService) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.emailForm = this.formBuilder.group({

      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        Validators.required])],
    });
  }
  saveUpdates(email) {
    // this.profileService.profileEmailedit(email).subscribe(
    //        data => {
    //   },
    //   error => {
    //     alert(JSON.stringify(error));
    //   });
    // this.dialogRef.close();
    }
  public hasError = (controlName: string, errorName: string) => {
    return this.emailForm.controls[controlName].hasError(errorName);
  }

}
