import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material";
import { UnsuccessfulMenuDialogComponent } from "../unsuccessful-menu-dialog/unsuccessful-menu-dialog.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-successful-menu-dialog",
  templateUrl: "./successful-menu-dialog.component.html",
  styleUrls: ["./successful-menu-dialog.component.scss"]
})
export class SuccessfulMenuDialogComponent implements OnInit {
  modalclose: string;
  clientId: any;
  successData: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<SuccessfulMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.successData = this.data;
    this.clientId = this.successData.clientId;
  }
  editItem() {
    this.dialogRef.close();
    this.router.navigate(["FacilityMenuView/" + this.clientId + ""]);
  }
  completeItem() {
    this.dialogRef.close();
    this.router.navigate(["FacilityDetails/" + this.clientId + ""]);
  }
  unsuccessfulmenu() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // this.dialogRef.close(this.modalclose);
    // const dialogRef = this.dialog.open(UnsuccessfulMenuDialogComponent, {
    //   width: '700px',
    //   data: {}
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
}
