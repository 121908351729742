import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SAASService } from 'src/app/shared/services/saas.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
import { PricingPromotionCodeService } from 'src/app/shared/services/pricing-promotion-code.service';
export interface PeriodicElement {
  LastName: string;
  FirstName: string;
  Email: any;
  Price: number;
  Monthly: number;
  // Yearly: number;
  Total: number;
}
// const ELEMENT_DATA: PeriodicElement[] = [];
const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  fullName: string;
  address: string;
  address1: string;
  city: string;
  state: string;
  mobile: string;
  email: string;
  clientData: any;
  zip: any;
  country: any;
  multipleFGdata: any;
  accountType: any;
  accountTypemonthly: any;
  accountTypeMY: any;
  faclityCount: any;
  public numberFormZip: FormGroup;
  monthlyPrice: any;
  yearlyPrice: any;
  coupendata = null;
  planID: any;
  showCouponDiv: boolean = false;
  validcoupensdata: any;
  coupenValue: any[];
  totalpaid: any;
  totalfinalpaid: number;
  activeState: boolean;
  errorcoupen: any;
  totalfinal: number;
  coupendataEnter: any;
  couponExpired: any = '';
  errorcouponexpired: boolean;
  validcoupensexeeddata: boolean;
  constructor(
    private pricingModule: PricingModuleServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private PromotionCodeService: PricingPromotionCodeService,
    private sassService: SAASService,
    private route: ActivatedRoute
  ) {}
  displayedColumns: string[] = [
    'CompanyName',
    // "LastName",
    // "Email",
    // "Price",
    'Monthly',
    // "Total",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ngOnInit() {
    let pickupTypefinal;
    this.showCouponDiv = false;
    if (JSON.parse(localStorage.getItem('saasFG')) !== null) {
      this.clientData = JSON.parse(localStorage.getItem('saasFG'));
      let facdata;
      facdata = JSON.parse(localStorage.getItem('multiplefacilities'));
      if (facdata[0].PickupType.length === 2) {
        pickupTypefinal = 'combined';
      } else if (facdata[0].PickupType[0].Title === 'Organic Recycling') {
        pickupTypefinal = 'Organic';
      } else {
        pickupTypefinal = 'Food';
      }
    } else {
      let clientdata;
      clientdata = JSON.parse(localStorage.getItem('multiplefacilities'));
      this.clientData = clientdata[0];
      if (this.clientData.PickupType.length === 2) {
        pickupTypefinal = 'combined';
      } else if (this.clientData.PickupType[0].Title === 'Organic Recycling') {
        pickupTypefinal = 'Organic';
      } else {
        pickupTypefinal = 'Food';
      }
    }
    if (
      pickupTypefinal === undefined ||
      pickupTypefinal === undefined ||
      !pickupTypefinal
    ) {
      pickupTypefinal = 'combined';
    }
    this.numberFormZip = this.formBuilder.group({
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
        ]),
      ],
    });
    this.accountTypeMY = JSON.parse(localStorage.getItem('accountType'));
    this.getPricingRated(pickupTypefinal);
    this.multipleFGdata = JSON.parse(
      localStorage.getItem('multiplefacilities')
    );
    this.faclityCount = this.multipleFGdata.length;
    this.dataSource = new MatTableDataSource(this.multipleFGdata);
    // const ClientId = this.route.snapshot.params.id;
    // this.sassService.getSubDetails(ClientId).subscribe(
    //   data => {
    // this.clientData = data.Result[0];
    this.address = this.clientData.Address1;
    this.address1 = this.clientData.Address2;
    this.city = this.clientData.City;
    // this.state = this.clientData.State;
    this.mobile = this.clientData.Phone;
    this.email = this.clientData.Email;
    this.zip = this.clientData.ZipCode;
    this.country = this.clientData.Country;
    this.pickupstates();
    this.selectedstorestate();
    //   }
    // );
  }
  getPricingRated(pickuptype) {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      let priceData;
      priceData = data['Result'];
      for (const status of priceData) {
        if (status.SubscriptionType === 'month') {
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.monthlyPrice = status.Price;
              if (this.accountTypeMY == 'montly') {
                this.planID = status.StripePlanId;
              }
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.monthlyPrice = status.Price;
              if (this.accountTypeMY == 'montly') {
                this.planID = status.StripePlanId;
              }
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.monthlyPrice = status.Price;
            if (this.accountTypeMY == 'montly') {
              this.planID = status.StripePlanId;
            }
          }
        } else {
          // console.log(pickuptype)
          // console.log(status.PickupType)
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            // console.log(status.PickupType)
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.yearlyPrice = status.Price;
              if (this.accountTypeMY == 'yearly') {
                this.planID = status.StripePlanId;
              }
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.yearlyPrice = status.Price;
              if (this.accountTypeMY == 'yearly') {
                this.planID = status.StripePlanId;
              }
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.yearlyPrice = status.Price;
            if (this.accountTypeMY == 'yearly') {
              this.planID = status.StripePlanId;
            }
          }
        }
        // if (status.SubscriptionType === 'month') {
        //   this.planID = status.StripePlanId;
        //   if (this.accountTypeMY == 'montly') {
        //     this.planID = status.StripePlanId;
        //   }
        //   this.monthlyPrice = status.Price;
        // } else {
        //   this.yearlyPrice = status.Price;
        //   if (this.accountTypeMY == 'yearly') {
        //     this.planID = status.StripePlanId;
        //   }
        // }
        if (this.accountTypeMY === 'montly' && this.faclityCount) {
          this.totalfinalpaid = this.faclityCount * this.monthlyPrice;
          this.totalfinal = this.faclityCount * this.monthlyPrice;
        } else {
          this.totalfinalpaid = this.faclityCount * this.yearlyPrice;
          this.totalfinal = this.faclityCount * this.yearlyPrice;
        }
        // if (this.driverSelected === status.ID) {
        //this.surplusnorganicmonthly = this.data.Result[0].Price;
      }
      if (
        JSON.parse(localStorage.getItem('promocodedata')) !== null &&
        JSON.parse(localStorage.getItem('promocodedata')) !== undefined &&
        JSON.parse(localStorage.getItem('promocodedata'))
      ) {
        let promocodeactive;
        promocodeactive = JSON.parse(localStorage.getItem('promocodedata'));
        this.coupendataEnter = promocodeactive;
        this.coupendata = promocodeactive;
        this.showCouponDiv = true;
        // this.getPricingRated()
        this.validCoupen();
      }
    });
  }
  validCoupen() {
    if (
      this.coupendataEnter == '' ||
      this.coupendataEnter == undefined ||
      this.coupendataEnter == null
    ) {
      this.errorcoupen = true;
    } else {
      const payload = {
        PromoCodeName: this.coupendataEnter,
        PlanId: this.planID,
        ValidateOTP: false,
      };
      this.PromotionCodeService.validCoupen(payload).subscribe((data) => {
        if (
          data.message ===
          'Coupon duration is month which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is year which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is month'
        ) {
          this.checkForError();
        } else if (
          data.message ===
          'Coupon duration is once which is not match with the plan duration which is year'
        ) {
          this.checkForError();
        } else if (
          data.message === 'Invalidate Coupon due to price paramters'
        ) {
          this.validcoupensdata = '';
          this.coupenValue = [];
          this.validcoupensexeeddata = true;
          setTimeout(() => {
            this.validcoupensexeeddata = false;
          }, 3000);
        } else if (
          data.message.includes('Coupon validity is already expired')
        ) {
          this.couponExpired = data.message;
          this.validcoupensdata = '';
          this.coupendata = null;
          // localStorage.removeItem('promocodedata');
          localStorage.removeItem('finalvaluepay');
          this.coupenValue = [];
          this.totalfinalpaid = this.totalfinal;
          this.errorcouponexpired = true;
          setTimeout(() => {
            this.errorcouponexpired = false;
          }, 5000);
        } else if (
          data.message ===
          'Object reference not set to an instance of an object.'
        ) {
          this.checkForError();
        } else if (data.message === 'Coupon was in error') {
          this.checkForError();
        } else {
          if (data.message.includes('Coupon is Valid')) {
            this.coupendata = this.coupendataEnter;
            this.activeState = true;
            this.validcoupensdata = 'Coupon Applied';
            this.coupenValue = [];
            var r = /\d+/g;
            // var s = "you can enter 333 maximum 500 choices";
            var m;
            while ((m = r.exec(data.message)) != null) {
              this.coupenValue.push(m[0]);
            }
            this.totalfinalpaid = this.totalfinal - this.coupenValue[0];
          } else {
            alert('Something wrong with Coupon');
          }
        }
      });
    }
  }
  goToCheckout() {
    localStorage.setItem('promocodedata', JSON.stringify(this.coupendata));

    localStorage.setItem('finalvaluepay', JSON.stringify(this.totalfinalpaid));
    this.router.navigate(['checkout']);
  }
  goToSubscriptionPage() {
    this.router.navigate(['subscription']);
  }
  keyPressZipCode(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      for (var i = 0; i < data['Result'].length; i++) {
        if (data['Result'][i].ID === this.clientData.StateId) {
          this.state = data['Result'][i].Title;
        }
      }
    });
  }
  selectedstorestate() {
    this.sassService
      .pickupcitydetails(this.clientData.StateId)
      .subscribe((data) => {
        for (var i = 0; i < data['Result'].length; i++) {
          if (data['Result'][i].ID == this.clientData.CityId) {
            this.city = data['Result'][i].Title;
          }
        }
      });
  }
  showCoupon() {
    this.showCouponDiv = true;
  }
  checkForError() {
    this.validcoupensdata = '';
    this.coupendata = null;
    // localStorage.removeItem('promocodedata');
    localStorage.removeItem('finalvaluepay');
    this.coupenValue = [];
    this.totalfinalpaid = this.totalfinal;
    this.errorcoupen = true;
    setTimeout(() => {
      this.errorcoupen = false;
    }, 3000);
  }
  clearCoupon() {
    this.coupendataEnter = null;
    this.coupendata = null;
    this.errorcoupen = false;
    this.validcoupensdata = '';
    this.totalfinalpaid = this.totalfinal;
  }
}
