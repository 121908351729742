import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment-timezone';
import { Router, ActivatedRoute } from '@angular/router';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AppState } from 'src/app/shared/app-state';
import { PickUps } from '../../../../app/models/pickups.model';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SavePickupmodalComponent } from '../save-pickupmodal/save-pickupmodal.component';
import { SpinnerService } from 'src/app/core/spinner.service';
import { MyOrderByPipe } from 'src/app/shared/pipes/sort.pipe';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { DatePostFormatPipe } from 'src/app/shared/pipes/dateformat.service';
import { TimezonedatepostService } from 'src/app/shared/pipes/timezonedatepost.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { orCustomMaterialList } from 'src/app/shared/services/or-custom-material-list.model';

export interface PeriodicElement {
  name: string;
  Cost: number;
  Weight: number;
  Quantity: number;
}

@Component({
  selector: 'app-create-pickuplist',
  templateUrl: './create-pickuplist.component.html',
  styleUrls: ['./create-pickuplist.component.scss']
})
export class CreatePickuplistComponent implements OnInit {
  fooditems: any;
  // activeState = 'Starch';

  @ViewChild('widgetsContent', { static: true })
  public widgetsContent: ElementRef<any>;
  displayedColumns: string[] = ['name', 'Cost', 'Weight', 'Quantity'];
  displayedColumnsCompost: string[] = ['Title', 'Weight', 'add'];
  ELEMENT_DATA: PeriodicElement[] = [];
  dataSource: any;
  dataSourceCombined: any;
  dataSourceCombinedData: any;
  productList: any;
  allfooditems: any;
  newArr: any = [];
  PickupRequestItem: any;
  allsectiondetails: any;
  productCategory: any;
  transaction: any = [];
  productdata: any;
  sendRequest: any = [];
  sendRequestElement: any;
  listmenu: any;
  Clients: any;
  success: boolean;
  dataedit: boolean;
  editbletrue: boolean;
  totalItems = 0;
  public createForm: FormGroup;
  imgSrc = '../../assets/images/editicon.png';
  wasClicked = false;
  finalrequestData: { PickupRequest_Product: any; ClientId: number };
  confirmPickuplist: boolean;
  confirmPickuplistCompost: boolean;
  createPickuplist: boolean;
  totalitemCost: any;
  showValidators: boolean;
  activeState: any;
  buttonDisabled: boolean;
  requestDataDisabled: boolean;
  errordata: any;
  newItems: boolean;
  SectionId: number;
  Description: any;
  listproduct = [];
  CombinedData = [];
  listproductFilter = [];
  addEdit: boolean;
  index: any;
  NewName = '';
  newName: string;
  storeRequestData: any;
  storeRequestDataCompost: any;
  pickupTypeID: any;
  foodRequest: boolean;
  compostRequest: boolean;
  separatedCompost: any;
  Separated: boolean;
  finalSeparateData: {
    PickupRequest_OrganicRecycling: any[];
    ClientId: number;
  };
  SeparatedCombined: any;
  separeatePickupID: any;
  combinedID: any;
  combinedPickupID: any;
  reqestType: any;
  pickupStartDate: any;
  pickupEndDate: any;
  PickupAfter: any;
  featureinfo: any;
  RoadiePostmatesFeature: boolean;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth'
    });
  }
  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth'
    });
  }

  constructor(
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    public orderService: MyOrderByPipe,
    public timezonedatepostService: TimezonedatepostService,
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    public postDateService: DatePostFormatPipe,
    private pickupService: PickUpService, private ClientFilterdata: ClientFilterService,
    public dateFormat: dateFormatPipe,
    public datePostFormatPipe: DatePostFormatPipe,
    private state: AppState,
    public capitailizepipe: specialPipe,
  ) { }

  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe(res => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'Roadie/Postmates' && element.EnableFeature === true) {
            this.RoadiePostmatesFeature = true;
          } else {
            this.RoadiePostmatesFeature = false;
          }
        }
      }
    });
    this.reqestType = JSON.parse(localStorage.getItem('Combined'));
     if (this.reqestType === 'Separated') {
      this.Separated = true;
      this.combinedRequest();
      this.separetaedRequest();
    } else if (this.reqestType === 'foodType') {
      this.foodRequest = true;
    } 
    this.pickupRequest();
    const Combinedbody = {
      Description: 'Combined',
      ID: 1,
      Weight: '',
      imgSrc: '../../assets/images/editicon.png'
    };
    this.CombinedData.push(Combinedbody);
    this.dataSourceCombinedData = this.CombinedData;
    // localUser.user.User.Clients.push(data['Result'][0])
    this.storeRequestDataCompost = JSON.parse(
      localStorage.getItem('separateRequestData')
    );
    this.storeRequestData = JSON.parse(
      localStorage.getItem('pickupRequestData')
    );
    if (this.storeRequestData && this.reqestType === 'foodType') {
      if (this.storeRequestData.PickupRequest_Product.length > 0) {
        if (this.storeRequestData.ClientId == this.route.snapshot.params.id) {
          this.confirmPickuplist = true;
          // this.confirmPickuplistCompost = true;
          this.sendRequestElement = new MatTableDataSource<Element>(
            this.storeRequestData.PickupRequest_Product
          );
          this.sendRequest = this.storeRequestData.PickupRequest_Product;
          if (this.sendRequest.length > 0) {
            this.totalitemCost = this.storeRequestData.PickupRequest_Product.map(
              r => r.Quantity * r.Cost
            ).reduce((sum, current) => Number(sum) + Number(current));
            this.totalItems = this.sendRequest.length;
          } else {
            this.totalItems = 0;
            this.totalitemCost = 0;
          }
        }
      }
    } else {
      this.createPickuplist = true;
    }
    if (this.storeRequestDataCompost) {
      if (
        this.storeRequestDataCompost.PickupRequest_Product.length > 0 &&
        this.reqestType === 'Separated' &&
        this.storeRequestDataCompost.PickupRequest_Product[0].Name !==
        'Combined'
      ) {
        if (
          this.storeRequestDataCompost.ClientId == this.route.snapshot.params.id
        ) {
          // this.confirmPickuplist = true;
          this.confirmPickuplistCompost = true;
          this.sendRequestElement = new MatTableDataSource<Element>(
            this.storeRequestDataCompost.PickupRequest_Product
          );
          this.sendRequest = this.storeRequestDataCompost.PickupRequest_Product;
          if (this.sendRequest.length > 0) {
            this.totalitemCost = this.storeRequestDataCompost.PickupRequest_Product.map(
              r => r.Weight
            ).reduce((sum, current) => Number(sum) + Number(current));
            this.totalItems = this.sendRequest.length;
          } else {
            this.totalItems = 0;
            this.totalitemCost = 0;
          }
        }
      }
    } else {
      this.createPickuplist = true;
    }
    if (this.storeRequestDataCompost) {
      if (
        this.storeRequestDataCompost.PickupRequest_Product.length > 0 &&
        this.reqestType === 'CombinedRequest' &&
        this.storeRequestDataCompost.PickupRequest_Product[0].Name ===
        'Combined'
      ) {
        if (
          this.storeRequestDataCompost.ClientId == this.route.snapshot.params.id
        ) {
          // this.confirmPickuplist = true;
          this.confirmPickuplistCompost = true;
          this.sendRequestElement = new MatTableDataSource<Element>(
            this.storeRequestDataCompost.PickupRequest_Product
          );
          this.sendRequest = this.storeRequestDataCompost.PickupRequest_Product;
          if (this.sendRequest.length > 0) {
            this.totalitemCost = this.storeRequestDataCompost.PickupRequest_Product.map(
              r => r.Weight
            ).reduce((sum, current) => Number(sum) + Number(current));
            this.totalItems = this.sendRequest.length;
          } else {
            this.totalItems = 0;
            this.totalitemCost = 0;
          }
        }
      }
    } else {
      this.createPickuplist = true;
    }
    this.createForm = this.formBuilder.group({
      quantity: ['', Validators.compose([Validators.required])],
      Weight: ['', Validators.compose([Validators.required])],
      Price: ['', Validators.compose([Validators.required])],
      Name: ['', Validators.compose([Validators.required])]
    });
    this.dataedit = true;
  }
  combinedRequest() {
    this.pickupService.OrganicRecyclingPickupType().subscribe(data => {
      this.SeparatedCombined = data.Result;
      for (const status of this.SeparatedCombined) {
        if (status.Title === 'Separated') {
          // this.pickupTypeID.push(status.ID);
          this.separeatePickupID = status.ID;
        } else {
          this.combinedPickupID = status.ID;
        }
      }
    });
  }
  separetaedRequest() {
    this.pickupService.SeparatedCompost().subscribe(data => {
      this.separatedCompost = orCustomMaterialList(data.Result);
      this.separatedCompost.map(value => {
        if (value.wasClicked === false) {
          value.imgSrc = '../../assets/images/circle12.png';
        } else {
          value.imgSrc = '../../assets/images/editicon.png';
        }
      });
      this.dataSourceCombined = this.separatedCompost;
    });
  }
  pickupRequest() {
    this.listproductFilter = [];
    var spinnerRef = this.spinnerService.start();
    this.pickupService
      .getClientsProducttDetail(this.route.snapshot.params.id)
      .subscribe(
        data => {
          this.spinnerService.stop(spinnerRef);
          this.state.pickups = data;
          const listmenu = [];
          this.productList = data.Result;
          for (const status of this.productList[0].PickupType) {
            if (status.Title === 'Food') {
              this.pickupTypeID = status.ID;
              // this.foodRequest = true;
            } else {
              this.combinedID = status.ID;
              // this.compostRequest = true;
            }
          }

          if (this.productList[0].Products.length > 0) {
            // get categories
            this.productList[0].Products.forEach(element => {
              if (element.Sections.length > 0) {
                if (listmenu.indexOf(element.Sections[0].Name) === -1) {
                  listmenu.push(element.Sections[0].Name);
                  this.listmenu = listmenu;
                }
              }
            });
            if (this.confirmPickuplist === true) {
              let newitemsdata = JSON.parse(
                localStorage.getItem('newitemsRequestData')
              );
              if (
                newitemsdata &&
                JSON.parse(localStorage.getItem('newitemsRequestclientid')) ==
                this.storeRequestData.ClientId
              ) {
                newitemsdata.forEach(element => {
                  this.productList[0].Products.push(element);
                });
              } else {
                const body = {
                  Name: this.NewName,
                  Quantity: '',
                  RequestId: 0,
                  ProductId: 0,
                  Sections: [{ SectionId: 0, Name: 'New Items' }],
                  // Description: 0,
                  Cost: '',
                  Weight: '',
                  imgSrc: '../../assets/images/editicon.png',
                  index: 1
                };
                this.productList[0].Products.push(body);
              }
            } else {
              const body = {
                Name: this.NewName,
                Quantity: '',
                RequestId: 0,
                ProductId: 0,
                Sections: [{ SectionId: 0, Name: 'New Items' }],
                // Description: 0,
                Cost: '',
                Weight: '',
                imgSrc: '../../assets/images/editicon.png',
                index: 1
              };
              this.productList[0].Products.push(body);
            }
          } else {
            if (this.reqestType === 'foodType') {
              alert("Zero Menu Item's");
              this.router.navigate(['RequestPickup-menu']);
            }
          }
          if (this.listmenu) {
            const otherss = this.listmenu.filter(othersMenu => {
              return othersMenu.length > 0 && othersMenu === 'Other';
            });
            const index = this.listmenu.indexOf('Other');
            if (index > -1) {
              this.listmenu.splice(index, 1);
            }
            this.listmenu.sort();
            if (otherss[0] === 'Other') {
              this.listmenu.push('Other');
            }
            this.listmenu.push('New Items');
            this.activeState = this.listmenu[0];
          }
          // const listproduct = [];
          this.productList[0].Products.map(value => {
            if (value.Sections.length > 0) {
              if (value.Sections[0].Name === this.activeState) {
                if (this.productList[0].MenuEdit === false) {
                  if (
                    value.Price === 0 ||
                    value.Price === null ||
                    value.Price === undefined ||
                    !value.Price
                  ) {
                    value.QuanbuttonDisabled = false;
                    value.quantityZero = true;
                  } else {
                    value.QuanbuttonDisabled = true;
                  }
                  if (
                    value.Weight === 0 ||
                    value.Weight === null ||
                    value.Weight === undefined ||
                    !value.Weight
                  ) {
                    value.WeigthbuttonDisabled = false;
                    value.weightZero = true;
                  } else {
                    value.WeigthbuttonDisabled = true;
                  }
                }
                if (value.wasClicked === false) {
                  value.imgSrc = '../../assets/images/circle12.png';
                } else {
                  value.imgSrc = '../../assets/images/editicon.png';
                }
                this.listproductFilter.push(value);
                // this.dataSource = this.listproduct;
              }
            }
          });
          this.dataSource = this.orderService.transform(
            this.listproductFilter,
            'Name'
          );
        },
        error => {
          this.spinnerService.stop(spinnerRef);
        }
      );
  }

  pickupListItems(activeState) {
    this.activeState = activeState;
    this.listproductFilter = [];
    this.dataSource = [];
    this.productList[0].Products.map(value => {
      if (value.Sections.length > 0) {
        if (value.Sections[0].Name === activeState) {
          if (value.wasClicked === false) {
            value.imgSrc = '../../assets/images/circle12.png';
          } else {
            value.imgSrc = '../../assets/images/editicon.png';
          }
          if (this.productList[0].MenuEdit === false) {
            if (
              value.Price === 0 ||
              value.quantityZero === true ||
              value.Price === null ||
              value.Price === undefined ||
              !value.Price
            ) {
              value.quantityZero = true;
              value.QuanbuttonDisabled = false;
            } else {
              value.QuanbuttonDisabled = true;
              value.quantityZero = false;
            }
            if (
              value.Weight === 0 ||
              value.weightZero === true ||
              value.Weight === null ||
              value.Weight === undefined ||
              !value.Weight
            ) {
              value.weightZero = true;
              value.WeigthbuttonDisabled = false;
            } else {
              value.WeigthbuttonDisabled = true;
              value.weightZero = false;
            }
          }
          this.listproductFilter.push(value);
          if (activeState === 'New Items') {
            this.dataSource = this.listproductFilter.sort(function (a, b) {
              return a.index - b.index;
            });
          } else {
            this.listproductFilter = this.orderService.transform(
              this.listproductFilter,
              'Name'
            );
            this.dataSource = this.listproductFilter;
          }
        }
      }
    });
  }

  addItems(transaction, i, value) {
    if (transaction.Sections[0].Name === 'New Items') {
      if (value > 0 && transaction.Weight > 0 && transaction.Price > 0) {
        if (
          value &&
          transaction.Price &&
          transaction.Weight &&
          transaction.Name
        ) {
          this.addItemsList(transaction, i, value);
        } else {
          transaction.nameShowValidators = true;
          setTimeout(() => {
            transaction.nameShowValidators = false;
          }, 3000);
        }
      } else {
        transaction.showValidators = true;
        setTimeout(() => {
          transaction.showValidators = false;
        }, 3000);
      }
    } else {
      this.addItemsList(transaction, i, value);
    }
  }
  addWeigth(transaction, i) {
    // if (transaction.Sections[0].Name === 'New Items') {
    //   if ( transaction.Weight > 0 && transaction.Price > 0) {
    //     if (transaction.Price && transaction.Weight) {
    //       this.addItemsWeigth(transaction, i);
    //     } else {
    //       transaction.nameShowValidators = true;
    //       setTimeout(() => {
    //         transaction.nameShowValidators = false;
    //       }, 3000);
    //     }
    //   } else {
    //     transaction.showValidators = true;
    //     setTimeout(() => {
    //       transaction.showValidators = false;
    //     }, 3000);
    //   }

    // } else {
    this.addItemsWeigth(transaction, i);
    // }
  }
  addItemsWeigth(transaction, i) {
    if (transaction.Weight > 0) {
      if (transaction.Weight) {
        if (transaction.wasClicked === false) {
          transaction.wasClicked = true;
          transaction.buttonDisabled = false;
          transaction.showValidators = false;
          if (this.productList[0].MenuEdit === false) {
            if (transaction.quantityZero === true) {
              transaction.QuanbuttonDisabled = false;
            }
            if (transaction.weightZero === true) {
              transaction.WeigthbuttonDisabled = false;
            }
          }
          transaction.imgSrc = '../../assets/images/editicon.png';
        } else {
          transaction.wasClicked = false;
          transaction.buttonDisabled = true;
          transaction.imgSrc = '../../assets/images/circle12.png';
        }
        for (let i = 0; i < this.sendRequest.length; i++) {
          if (transaction.ID === this.sendRequest[i].ID) {
            this.sendRequest.splice(i, 1);
          }
        }
        this.dataedit = false;
        this.success = true;
        if (this.sendRequest.length === 0) {
          this.requestDataDisabled = true;
        } else {
          this.requestDataDisabled = false;
        }
        const body = {
          Name: transaction.Description,
          Weight: transaction.Weight,
          ID: transaction.ID
        };
        this.sendRequest.push(body);
        const bodydata = {
          PickupRequest_Product: this.sendRequest,
          ClientId: +this.route.snapshot.params.id
        };
        this.totalItems = this.sendRequest.length;
        this.sendRequestElement = new MatTableDataSource<Element>(
          this.sendRequest
        );
        localStorage.setItem('separateRequestData', JSON.stringify(bodydata));
      } else {
        transaction.showValidators = true;
        setTimeout(() => {
          transaction.showValidators = false;
        }, 3000);
      }
    } else {
      transaction.showValidators = true;
      setTimeout(() => {
        transaction.showValidators = false;
      }, 3000);
    }
  }

  addItemsList(transaction, i, value) {
      this.index = i + 1;
    // added the index for new item reference
    if (value > 0 && transaction.Weight > 0 && transaction.Price > 0) {
      if (value && transaction.Price && transaction.Weight) {
        let j = 0;
        for (const itemdata of this.listproductFilter) {
          if (
            itemdata.Price !== '' &&
            itemdata.Weight !== '' &&
            itemdata.Price !== ''
          ) {
            j++;
          }
        }
        if (transaction.Sections[0].Name === 'New Items') {
          if (j === this.listproductFilter.length) {
            const body = {
              Name: '',
              Quantity: '',
              RequestId: 0,
              ProductId: 0,
              Sections: [{ SectionId: 0, Name: 'New Items' }],
              Cost: '',
              wasClicked: true,
              Weight: '',
              imgSrc: '../../assets/images/editicon.png',
              index: this.index + 1
            };
            this.productList[0].Products.push(body);
            this.listproductFilter.push(body);
            this.dataSource = new MatTableDataSource<Element>(
              this.listproductFilter
            );
          }
        }
        if (transaction.wasClicked === false) {
          transaction.wasClicked = true;
          transaction.buttonDisabled = false;
          transaction.showValidators = false;
          if (this.productList[0].MenuEdit === false) {
            if (transaction.quantityZero === true) {
              transaction.QuanbuttonDisabled = false;
            }
            if (transaction.weightZero === true) {
              transaction.WeigthbuttonDisabled = false;
            }
          }
          transaction.imgSrc = '../../assets/images/editicon.png';
        } else {
          transaction.wasClicked = false;
          transaction.imgSrc = '../../assets/images/circle12.png';
          transaction.buttonDisabled = true;
          if (transaction.Sections[0].Name === 'New Items') {
            const sendbody = {
              Name: this.capitailizepipe.transform(transaction.Name),
              Quantity: +value,
              RequestId: 0,
              ProductId: 0,
              Sections: [{ SectionId: 0, Name: 'New Items' }],
              Price: transaction.Price,
              Weight: transaction.Weight,
              imgSrc: '../../assets/images/circle12.png',
              wasClicked: transaction.wasClicked,
              index: this.index
            };
            for (let i = 0; i < this.productList[0].Products.length; i++) {
              if (transaction.ProductId === 0) {
                if (
                  transaction.index === this.productList[0].Products[i].index
                ) {
                  this.productList[0].Products.splice(i, 1);
                  this.productList[0].Products.push(sendbody);
                }
              }
            }
            // for (let i = 0; i < this.listproductFilter.length; i++) {
            //   if (body.index === this.listproductFilter[i].index) {
            //     this.editbletrue = true;
            //   }
            // }
            // let valid_counts = 0;
            // for (let i = 0; i < this.listproductFilter.length; i++) {
            //   if (this.listproductFilter[i].wasClicked === true) {
            //     valid_counts = i++;
            //   }
            // }
            // if (this.editbletrue !== true || valid_counts === 0) {
            //   this.productList[0].Products.push(body);
            //   this.listproductFilter.push(body);
            // }
            const newitemslist = this.productList[0].Products.filter(list => {
              return list.Sections[0].Name === 'New Items';
            });
            this.dataSource = new MatTableDataSource<Element>(
              this.listproductFilter
            );
            // }
            localStorage.setItem(
              'newitemsRequestData',
              JSON.stringify(newitemslist)
            );
            localStorage.setItem(
              'newitemsRequestclientid',
              JSON.stringify(this.route.snapshot.params.id)
            );
          }
        }
        for (let i = 0; i < this.sendRequest.length; i++) {
          if (transaction.ProductId === 0) {
            if (transaction.index === this.sendRequest[i].index) {
              this.sendRequest.splice(i, 1);
            }
          } else {
            if (transaction.ProductId === this.sendRequest[i].ProductId) {
              this.sendRequest.splice(i, 1);
            }
          }
        }
        if (transaction.Sections[0].Name === 'New Items') {
          this.NewName = this.capitailizepipe.transform(transaction.Name);
          this.index = this.index;
        } else {
          this.NewName = this.capitailizepipe.transform(transaction.Name);
          this.index = undefined;
        }
        this.dataedit = false;
        this.success = true;
        transaction.Quantity = +value;
        if (this.sendRequest.length === 0) {
          this.requestDataDisabled = true;
        } else {
          this.requestDataDisabled = false;
        }
        if (transaction.SectionId === 0) {
          this.SectionId = 0;
        } else {
          this.SectionId = transaction.Sections[0].SectionId;
        }
        if (transaction.Description === 0) {
          this.Description = this.capitailizepipe.transform(transaction.Name);
        } else {
          this.Description = transaction.Sections[0].Description;
        }
        if (transaction.ProductId === 0) {
          this.newName = this.NewName;
        } else {
          this.newName = null;
        }
        const body = {
          Name: this.NewName,
          Quantity: transaction.Quantity,
          RequestId: 0,
          ProductId: transaction.ProductId,
          SectionId: this.SectionId,
          Cost: transaction.Price,
          Weight: transaction.Weight,
          index: this.index
        };
        this.sendRequest.push(body);
        const bodydata = {
          PickupRequest_Product: this.sendRequest,
          ClientId: +this.route.snapshot.params.id
        };
        this.totalItems = this.sendRequest.length;
        this.sendRequest = this.orderService.transform(
          this.sendRequest,
          'Name'
        );
        this.sendRequestElement = new MatTableDataSource<Element>(
          this.sendRequest
        );
        localStorage.setItem('pickupRequestData', JSON.stringify(bodydata));
      } else {
        transaction.showValidators = true;
        setTimeout(() => {
          transaction.showValidators = false;
        }, 3000);
      }
    } else {
      transaction.showValidators = true;
      setTimeout(() => {
        transaction.showValidators = false;
      }, 3000);
    }
  }
  ViewCombinedPickupList() {
    this.addEdit = false;
    if (this.sendRequest.length === 0) {
      this.showValidators = true;
      this.requestDataDisabled = true;
      this.totalItems = 0;
      this.totalitemCost = 0;
      setTimeout(() => {
        this.showValidators = false;
      }, 1500);
    } else {
      // this.confirmPickuplist = true;
      this.confirmPickuplistCompost = true;
      this.createPickuplist = false;
      this.requestDataDisabled = false;
      if (this.sendRequest.length > 0) {
        this.totalitemCost = this.sendRequest
          .map(r => r.Weight)
          .reduce((sum, current) => Number(sum) + Number(current));
        this.totalItems = this.sendRequest.length;
      } else {
        this.totalItems = 0;
        this.totalitemCost = 0;
      }
    }
  }
  createpickup() {
    this.router.navigate(['/RequestPickup-menu']);
  }
  ViewPickupList() {
    this.sendRequest = this.orderService.transform(this.sendRequest, 'Name');
    this.sendRequestElement = new MatTableDataSource<Element>(this.sendRequest);
    this.addEdit = false;
    if (this.sendRequest.length === 0) {
      this.showValidators = true;
      this.requestDataDisabled = true;
      this.totalItems = 0;
      this.totalitemCost = 0;
      setTimeout(() => {
        this.showValidators = false;
      }, 1500);
    } else {
      this.confirmPickuplist = true;
      // this.confirmPickuplistCompost = true;
      this.createPickuplist = false;
      this.requestDataDisabled = false;
      if (this.sendRequest.length > 0) {
        this.totalitemCost = this.sendRequest
          .map(r => r.Quantity * r.Cost)
          .reduce((sum, current) => Number(sum) + Number(current));
        this.totalItems = this.sendRequest.length;
      } else {
        this.totalItems = 0;
        this.totalitemCost = 0;
      }
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.createForm.controls[controlName].hasError(errorName);
  };
  ViewPickupListcancel() {
    if (this.foodRequest) {
      localStorage.removeItem('pickupRequestData');
      this.addFoodItem();
      for (var m = 0; m < this.productList[0].Products.length; m++) {
        for (var k = 0; k < this.sendRequest.length; k++) {
          if (
            this.productList[0].Products[m].ProductId ==
            this.sendRequest[k].ProductId
          ) {
            this.productList[0].Products[m].Quantity = null;
            this.productList[0].Products[m].Price = null;
            this.productList[0].Products[m].Weight = null;
            break;
          }
        }
      }
      var data1 = ['New Items'];
      var items = this.productList[0].Products;
      var i = items.length;
      while (i--) {
        if (data1.indexOf(items[i].Sections[0].Name) != -1) {
          items.splice(i, 1);
        }
      }
      const body = {
        Name: '',
        Quantity: '',
        RequestId: 0,
        ProductId: 0,
        Sections: [{ SectionId: 0, Name: 'New Items' }],
        Cost: '',
        Weight: '',
        imgSrc: '../../assets/images/editicon.png',
        index: 1
      };
      this.productList[0].Products.push(body);
      var itemslist = this.listproductFilter;
      var j = itemslist.length;
      while (j--) {
        if (data1.indexOf(itemslist[j].Sections[0].Name) != -1) {
          itemslist.splice(j, 1);
        }
      }
      if (this.activeState === 'New Items') {
        this.listproductFilter.push(body);
      }
      this.dataSource = new MatTableDataSource<Element>(this.listproductFilter);
      this.sendRequest = [];
      this.totalItems = 0;
    } else {
      localStorage.removeItem('separateRequestData');
    }
    if (this.Separated) {
      this.addCominedItem();
    }
    this.router.navigate(['RequestPickup-menu']);
  }
  requestSeparateList() {
    this.requestDataDisabled = true;
    localStorage.removeItem('separateRequestData');
    const sendData = [];
    for (let item of this.sendRequest) {
      sendData.push({
        SeparatedCompostId: item.ID,
        Weight: item.Weight * 16,
        PickupImageURL: null
      });
    }
    const sendbodydata = {
      PickupRequest_OrganicRecycling: sendData,
      PickupTypeId: this.combinedID,
      ClientId: +this.route.snapshot.params.id,
      OrganicRecyclingPickupTypeId: this.separeatePickupID
    };
    this.finalSeparateData = sendbodydata;
    this.pickupService
      .addItemsRequest(JSON.stringify(this.finalSeparateData))
      .subscribe(
        data => {
          if (data.message === undefined) {
            // if (this.productList[0].DriverServices[0].Title === 'Postmates') {
            //   if (
            //     data.PickupRequest[0].NonProfitReceiverId === null ||
            //     data.PickupRequest[0].NonProfitReceiverId === undefined ||
            //     !data.PickupRequest[0].NonProfitReceiverId
            //   ) {
            //     this.popupconfirmodal(data.DeliveryId);
            //   } else {
            //     this.UpdatePickUppostmateStatus(
            //       data.DeliveryId,
            //       data.RequestId
            //     );
            //   }
            // } else {
            this.popupconfirmodal(data.DeliveryId);
            // }
            // const dialogRef = this.dialog.open(SavePickupmodalComponent, {
            //   width: '700px',
            //   panelClass: 'custom-savedialog-container',
            //   data: {
            //     DeliveryId: data.DeliveryId
            //   }
            // });
            // dialogRef.afterClosed().subscribe(result => {
            // });
          } else {
            this.errordata = data.message;
            this.requestDataDisabled = false;
            alert(this.errordata);
          }
        },
        error => {
          this.state.isSpinnerVisible = false;
          this.requestDataDisabled = false;
          // alert(JSON.stringify(error));
        }
      );
  }
  requestConfirmList() {
    this.requestDataDisabled = true;
    localStorage.removeItem('pickupRequestData');
    localStorage.removeItem('newitemsRequestData');
    localStorage.removeItem('newitemsRequestclientid');
    const sendData = [];
    for (let item of this.sendRequest) {
      sendData.push({
        Product: [{ Name: item.ProductId ? null : item.Name }],
        Quantity: item.Quantity,
        RequestId: 0,
        ProductId: item.ProductId,
        SectionId: item.SectionId,
        Cost: item.Cost,
        Weight: item.Weight
      });
    }
    if (
      this.productList[0].DriverServices[0].Title === 'Roadie' ||
      this.productList[0].DriverServices[0].Title === 'Roadie/Postmates'
    ) {
      this.pickupStartDate = new Date();
      const startDate = new Date(
        this.timezonedatepostService.transform(
          this.pickupStartDate.toISOString(),
          this.productList[0].TimeZone
        )
      );
      this.pickupStartDate =
        moment(startDate)
          // .add(1, 'hours')
          .add(5, 'minutes')
          .format()
          .toString()
          .split('T')[0] +
        'T' +
        moment(startDate)
          // .add(1, 'hours')
          .add(5, 'minutes')
          .format()
          .toString()
          .split('T')[1]
          .split('+')[0]
          .split('-')[0];
      this.PickupAfter =
        moment(startDate)
          .add(5, 'minutes')
          .format()
          .toString()
          .split('T')[0] +
        'T' +
        moment(startDate)
          .add(5, 'minutes')
          .format()
          .toString()
          .split('T')[1]
          .split('+')[0]
          .split('-')[0];
      this.pickupEndDate = new Date(this.pickupStartDate);
      this.pickupEndDate =
        moment(this.pickupEndDate)
          .add(2, 'hours')
          .format()
          .toString()
          .split('T')[0] +
        'T' +
        moment(this.pickupEndDate)
          .add(2, 'hours')
          .format()
          .toString()
          .split('T')[1]
          .split('+')[0]
          .split('-')[0];
      this.pickupStartDate = this.postDateService.transform(
        this.pickupStartDate,
        this.productList[0].TimeZone
      );
      this.pickupEndDate = this.postDateService.transform(
        this.pickupEndDate,
        this.productList[0].TimeZone
      );
    } else {
      this.pickupStartDate = null;
      this.pickupEndDate = null;
    }
    // this.pickupafter remove because of Pickup time after  2 min
    // this.PickupAfter = this.timezonedatepostService.transform(
    //   this.PickupAfter,
    //   this.productList[0].TimeZone
    // );
    const sendbodydata = {
      PickupRequest_Product: sendData,
      PickupTypeId: this.pickupTypeID,
      ClientId: +this.route.snapshot.params.id,
      PickupAfter: this.pickupStartDate,
      DeliveryBetweenStart: this.pickupStartDate,
      DeliveryBetweenEnd: this.pickupEndDate
    };
    this.finalrequestData = sendbodydata;
    this.pickupService
      .addItemsRequest(JSON.stringify(this.finalrequestData))
      .subscribe(
        data => {
          if (data.message === undefined) {
            // if (data.PickupRequest[0].DriverServices.length > 0) {
            //   if (
            //     data.PickupRequest[0].DriverServices[0].Title === 'Postmates'
            //   ) {
            //     if (
            //       data.PickupRequest[0].NonProfitReceiverId === null ||
            //       data.PickupRequest[0].NonProfitReceiverId === undefined ||
            //       !data.PickupRequest[0].NonProfitReceiverId
            //     ) {
            //       this.popupconfirmodal(data.DeliveryId);
            //     } else {
            //       this.UpdatePickUppostmateStatus(
            //         data.DeliveryId,
            //         data.RequestId
            //       );
            //     }
            //   } else {
            //     this.popupconfirmodal(data.DeliveryId);
            //   }
            // } else {
            this.popupconfirmodal(data.DeliveryId);
            // }
          } else {
            this.errordata = data.message;
            this.requestDataDisabled = false;
            alert(this.errordata);
          }
        },
        error => {
          this.state.isSpinnerVisible = false;
          this.requestDataDisabled = false;
          // alert(JSON.stringify(error));
        }
      );
  }
  // postmate status
  UpdatePickUppostmateStatus(DeliveryId, RequestId) {
    let payloaddata = {
      Request_Id: RequestId
    };
    this.pickupService.PostmatesPickupDelivery(payloaddata).subscribe(data => {
      if ('error' in data) {
        if (data['Postmate Errors']['pickup_address']) {
          this.popupconfirmodal(DeliveryId);
        } else if (data['Postmate Errors']['dropoff_address']) {
          this.popupconfirmodal(DeliveryId);
        } else if (
          data['Postmate Errors']['dropoff_phone_number'] &&
          data['Postmate Errors']['pickup_phone_number']
        ) {
          this.popupconfirmodal(DeliveryId);
        } else if (data['Postmate Errors']['dropoff_phone_number']) {
          this.popupconfirmodal(DeliveryId);
        } else if (data['Postmate Errors']['pickup_phone_number']) {
          this.popupconfirmodal(DeliveryId);
        } else {
          this.popupconfirmodal(DeliveryId);
        }
        // alert(JSON.stringify(data['Postmate Errors']));
      } else {
        this.popupconfirmodal(DeliveryId);
      }
    });
  }
  popupconfirmodal(data) {
    const dialogRef = this.dialog.open(SavePickupmodalComponent, {
      width: '700px',
      panelClass: 'custom-savedialog-container',
      data: {
        DeliveryId: data
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }
  onSearchChange(event) {
    this.dataedit = true;
    this.success = false;
  }
  addCominedTotal() {
    this.addEdit = true;
    const listproduct = [];
    this.CombinedData.map(value => {
      if (value.wasClicked === false) {
        value.imgSrc = '../../assets/images/editicon.png';
        value.wasClicked = true;
        value.buttonDisabled = false;
      }
    });
    this.dataSourceCombinedData = new MatTableDataSource<Element>(
      this.CombinedData
    );
    this.confirmPickuplist = false;
    this.confirmPickuplistCompost = false;
    this.createPickuplist = true;
  }
  addCominedItem() {
    this.addEdit = true;
    const listproduct = [];
    this.separatedCompost.map(value => {
      if (value.wasClicked === false) {
        value.imgSrc = '../../assets/images/editicon.png';
        value.wasClicked = true;
        value.buttonDisabled = false;
      }
    });
    this.dataSourceCombined = new MatTableDataSource<Element>(
      this.separatedCompost
    );
    this.confirmPickuplist = false;
    this.confirmPickuplistCompost = false;
    this.createPickuplist = true;
  }

  addFoodItem() {
    // this.listproductFilter = this.orderService.transform(this.listproductFilter, 'Name');
    this.addEdit = true;
    const listproduct = [];
    this.productList[0].Products.map(value => {
      if (value.Sections.length > 0) {
        value.wasClicked = true;
        if (this.productList[0].MenuEdit === false) {
          if (value.quantityZero === true) {
            value.QuanbuttonDisabled = false;
          }
          if (value.weightZero === true) {
            value.WeigthbuttonDisabled = false;
          }
        }
        value.imgSrc = '../../assets/images/editicon.png';
        value.buttonDisabled = false;
        listproduct.push(value);
        this.productList[0].Products = listproduct;
      }
    });
    this.listproductFilter.map(value => {
      if (value.Sections.length > 0) {
        const listproductNew = [];
        this.listproductFilter.map(list => {
          if (value.Sections[0].Name === 'New Items') {
            this.dataSource = '';
            value.wasClicked = true;
            value.imgSrc = '../../assets/images/editicon.png';
            value.buttonDisabled = false;
            listproductNew.push(list);
            this.dataSource = new MatTableDataSource<Element>(listproductNew);
          }
        });
      }
    });
    this.confirmPickuplist = false;
    this.confirmPickuplistCompost = false;
    this.createPickuplist = true;
  }
  deletePickup(product) {
    const index = this.sendRequest.indexOf(product);
    // this.dataSource = this.orderService.transform(this.dataSource, 'Name');
    this.sendRequest.map(value => {
      if (value.SectionId === 0) {
        if (value.index === product.index) {
          value.imgSrc = '../../assets/images/editicon.png';
          value.Quantity = null;
          // value.Name = null;
          value.Weight = null;
          value.Price = null;
        }
      } else {
        if (value.ProductId === product.ProductId) {
          value.imgSrc = '../../assets/images/editicon.png';
          value.Quantity = null;
          value.Weight = null;
          value.Price = null;
        }
      }
    });
    this.sendRequest.splice(index, 1);
    const bodydata = {
      PickupRequest_Product: this.sendRequest,
      ClientId: +this.route.snapshot.params.id
    };
    localStorage.setItem('pickupRequestData', JSON.stringify(bodydata));
    this.totalItems = this.sendRequest.length;
    this.sendRequest = this.orderService.transform(this.sendRequest, 'Name');
    this.sendRequestElement = new MatTableDataSource<Element>(this.sendRequest);
    if (this.sendRequest.length === 0) {
      this.requestDataDisabled = true;
    } else {
      this.requestDataDisabled = false;
    }
    this.ViewPickupList();
  }
  deleteCompost(product) {
    const index = this.sendRequest.indexOf(product);
    this.sendRequest.splice(index, 1);
    const bodydata = {
      PickupRequest_Product: this.sendRequest,
      ClientId: +this.route.snapshot.params.id
    };
    localStorage.setItem('separateRequestData', JSON.stringify(bodydata));
    this.totalItems = this.sendRequest.length;
    this.sendRequest = this.orderService.transform(this.sendRequest, 'Name');
    this.sendRequestElement = new MatTableDataSource<Element>(this.sendRequest);
    if (this.sendRequest.length === 0) {
      this.requestDataDisabled = true;
    } else {
      this.requestDataDisabled = false;
    }
    this.ViewCombinedPickupList();
  }
}
