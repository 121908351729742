import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from '@angular/material';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { EditbillingComponent } from '../../customers/editbilling/editbilling.component';
import { EditMYearlyComponent } from '../../customers/edit-myearly/edit-myearly.component';
import { EditcheckoutComponent } from '../../customers/editcheckout/editcheckout.component';

@Component({
  selector: 'app-activebreadcrumnforbilling',
  templateUrl: './activebreadcrumnforbilling.component.html',
  styleUrls: ['./activebreadcrumnforbilling.component.scss']
})
export class ActivebreadcrumnforbillingComponent implements OnInit {

  modalclose: string;
  info: any;
  newAccountstatus: boolean;
  businessstatus: boolean;
  createMenustatus: boolean;
  userDetailsstatus: boolean;
  ImportStatus:  boolean=true;

  constructor( public dialog: MatDialog,
               public _rdata: modalDataService,
               public dialogRef: MatDialogRef<EditbillingComponent>,
               public BackRef: MatDialogRef<EditMYearlyComponent>,
               @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // this.data['importstatus'];
    // if(this.data['importstatus'] === false){
    //   this.ImportStatus = false;
    // }
    this._rdata.basicInfo2Observe.subscribe(res => {
    this.info = JSON.parse(res);
    if (this.info.accountTypetrue === true) {
       this.newAccountstatus = true;
     }
    if (this.info.business_Detail === true) {
      this.businessstatus = true;
    }
    if (this.info.user_Details === true) {
      this.userDetailsstatus = true;
    }
    });
  }
  goToCheckout() {
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(EditcheckoutComponent, {
      width: "700px",
      height: "460px",
      panelClass: 'custom-editdialog-container',
      data: {
       },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
  billingFlow() {
    this.dialogRef.close(this.modalclose);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(EditMYearlyComponent, {
      width: "700px",
      height: "640px",
      panelClass: 'custom-editdialog-container',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(selection => {
    });
  }

  editbilling() {
    this.dialogRef.close(this.modalclose);
    const dialogRef = this.dialog.open(EditbillingComponent, {
      width: "840px",
      // height: "890px",
      panelClass: 'custom-editdialog-container',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
 
}

