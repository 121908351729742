import { Component, isDevMode, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { AppState } from './shared/app-state';
import { LoaderService } from './shared/services/loader.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { AdmintoolsService } from './shared/services/admintools.service';
import { ClientFilterService } from './shared/services/clientFilter.service';
import { filter } from 'rxjs/operators';

declare var gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'PortalAzure';
  app_env: string = environment.app_env;
  environmentName: 'DEV';
  environmentUrl = 'Debug api';
  showLoader: boolean;
  featureinfo: any;

  constructor(
    private router: Router,
    public state: AppState,
    private ClientFilterdata: ClientFilterService,
    private AdminService: AdmintoolsService,
    private loaderService: LoaderService
  ) {
    this.environmentUrl = environment.environmentUrl;
    const storedState = localStorage.getItem('state');

    if (storedState) {
      const sessionState: AppState = JSON.parse(storedState);
      this.state.isLoggedIn = sessionState.isLoggedIn;
      if (this.state.isLoggedIn) {
        (<any>window).godrEmail = this.state.user['User']['Email'];
        (<any>window).godrRole = this.state.user.User.Roles[0].Name;
      }
      this.state.user = sessionState.user;
      this.state.title = sessionState.title;
    }

    //Google Analytics Code
    if (environment.gaTrackingId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;
      document.head.appendChild(gTagManagerScript);
      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
      `;
      document.head.appendChild(gaScript);
      const navEndEvent$ = router.events.pipe(filter((e) => e instanceof NavigationEnd));
      navEndEvent$.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.gaTrackingId, { page_path: event.urlAfterRedirects });
        }
      });
    }
  }

  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
    });
    // this.CheckService.initVersionCheck(environment.environmentUrl);
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    if (isDevMode()) {
    } else {
    }

    // feature toggle get call
    this.AdminService.FeatureToggles().subscribe((result) => {
      this.featureinfo.featureData = result.Result;
      this.ClientFilterdata.updateBasicFeatureInfo(JSON.stringify(this.featureinfo));
    });
    if (this.app_env === 'dev') {
      var iOSURL = 'goodrappdev://gdr-dev-dashboard-3-0.eastus.cloudapp.azure.com/pickupDetails/';
    } else if (this.app_env === 'qa') {
      var iOSURL = 'goodrappqa://gdr-qa-dashboard-3-0.eastus.cloudapp.azure.com/pickupDetails/';
    } else if (this.app_env === 'staging') {
      var iOSURL = 'goodrappstage://gdr-staging-dashboard-3-0.eastus.cloudapp.azure.com/pickupDetails/';
    } else if (this.app_env === 'uat') {
      var iOSURL = 'goodrappuat://gdr-uat-dashboard-3-0.eastus.cloudapp.azure.com/pickupDetails/';
    } else if (this.app_env === 'prod') {
      var iOSURL = 'goodrapp://gdr-live-dashboard-3-0.eastus.cloudapp.azure.com/pickupDetails/';
    }
    if (location.href.includes('pickupDetails')) {
      var deliveryId = location.href.split('/pickupDetails/')[1];
      var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      if (iOS === true) {
        // if ((navigator.userAgent.match('/iPhone/i')) || (navigator.userAgent.match(/iPod/i))) {
        // location.replace(iOSURL);
        window.location.href = iOSURL + deliveryId;
      }
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
