import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { SpinnerService } from 'src/app/core/spinner.service';
import { AlertDialogComponent } from 'src/app/dialog/alert-dialog/alert-dialog.component';
import { SuccesPopupComponent } from 'src/app/SAAS/regModule/succes-popup/succes-popup.component';
import { dateFormatPipe } from 'src/app/shared/pipes/date.service';
import { dateOnlyWithTimezone } from 'src/app/shared/pipes/dateOnlyWithTimezone.service';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { PickUpService } from 'src/app/shared/services/pickup.service';
import { AddContractDialogComponent } from '../add-contract-dialog/add-contract-dialog.component';
import { AddeditDateComponent } from '../customers/addedit-date/addedit-date.component';
import { EditContractDialogComponent } from '../edit-contract-dialog/edit-contract-dialog.component';
import { EditInvoiceDialogComponent } from '../edit-invoice-dialog/edit-invoice-dialog.component';


@Component({
  selector: 'app-billing-invoices',
  templateUrl: './billing-invoices.component.html',
  styleUrls: ['./billing-invoices.component.scss']
})
export class BillingInvoicesComponent implements OnInit {

  dataSource: any;
  globalcontractID: any;
  globalClient: any;
  filtermodal: boolean;
  globalPickup: any;
  globalNoOfContractedPickup: any;
  globalNoOfDeliveredPickup: any;
  globalPerPickupPeriod: any;
  globalContractPickupPrice: any;
  globalExtraPickupPrice: any;
  globalContractStatus: any;
  globalsubtotal: any;
  pickupList: [];
  clientList: [];
  RecordCount: number;
  pageNumber: number;
  pageSize: number;
  selectedPageSize = [10, 25, 50, 100];
  filterInfo: any;
  globalClientName: any;
  globalInvoiceTotal: any;
  contractPeriods = [];
  sortColumn: any = null;
  showReset: boolean = false;
  statusList = [];
  displayedColumns: string[] = [
    'select',
    'clientName',
    'contractID',
    'invoiceStart',
    'noDeliveredPickup',
    'noContractedPickup',
    'contractedPickupPrice',
    'noOfExtraPickups',
    'extraPickupPrice',
    'subtotal',
    'discount',
    'invoiceTotal',
    'netTerm',
    'invoiceGenerated',
    'invoiceDue',
    'currentInvoiceStatus',
    'detail',
  ];
  mobileDisplayedColumns: string[] = [
    // 'select',
    'contractID',
    'clientName',
    'PickupType',
    'noDeliveredPickup',
    'noContractedPickup',
    'pickupPeriod',
    'contractedPickupPrice',
    'extraPickupPrice',
    'contractStatus',
    'invoiceGenerated',
    'invoiceDue',
    'currentInvoiceStatus',]
  checkedRecords = [];
  globalDiscount: any;
  globalNetDays: any;
  masterChecked: boolean = false;
  invoiceGenerated_ToDate: any;
  invoiceGenerated_FromDate: any;
  constructor(private pickupService: PickUpService,
    private ClientFilterdata: ClientFilterService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    public dateTimeFormat: dateOnlyWithTimezone,
  ) { }
  ngOnInit() {
    var spinnerRef = this.spinnerService.start();
    this.ClientFilterdata.basicInfoObserveclient.subscribe((res) => {
      const listOfClients = JSON.parse(res);
      if (listOfClients.allClients.length > 0) {
        this.clientList = listOfClients.allClients.sort(function (a, b) {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          }
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
    });

    this.pickupService.getPickupFilterList().subscribe((d) => {
      const list = d.Result;
      const index = list.findIndex((p) => p['Title'] === 'One Time Pickup');
      if (index > 1) {
        list.splice(index, 1);
      }
      this.pickupList = list;
    });

    this.pickupService.getContractPeriod().subscribe((d) => {
      const list1 = d.Result;
      this.contractPeriods = list1;
    });
    this.pickupService.getinvoiceStatusList().subscribe((d) => {
      this.statusList = d.Result;
    });

    this.globalcontractID = '';
    this.globalClient = '';
    this.globalPickup = '';
    this.globalNoOfContractedPickup = '';
    this.globalNoOfDeliveredPickup = '';
    this.globalPerPickupPeriod = '';
    this.globalContractPickupPrice = '';
    this.globalExtraPickupPrice = '';
    this.globalContractStatus = '';
    this.pageSize = 10;
    this.pageNumber = 1;
    this.sortColumn = "-ID";
    this.globalInvoiceTotal = '';
    this.globalDiscount = '';
    this.globalNetDays = '';
    this.globalsubtotal = '';
    this.invoiceGenerated_ToDate = '';
    this.invoiceGenerated_FromDate = '';
    this.getInvoices(spinnerRef);
  }

  setFilteredValue(category, secondCat, value) {
    var spinnerRef = this.spinnerService.start();
    this.pageNumber = 1;
    this[category] = value;
    if (category === 'globalClient' && value !== '') {
      const clients = this.clientList.find((client) => client['ClientId'] === value);
      this.globalClientName = clients['Name'];
    }

    if (['globalcontractID', 'globalClient', 'globalPickup', 'globalNoOfContractedPickup', 'globalNoOfDeliveredPickup',
      'globalPerPickupPeriod', 'globalContractPickupPrice', 'globalExtraPickupPrice',
      'globalDiscount', 'globalInvoiceTotal', 'globalNetDays', 'globalContractStatus',
      'globalsubtotal'].includes(category) && value === '') {
      this[category] = value;
      if (category == 'globalClient') {
        this.globalClientName = ''
      }
    }

    if (category === 'globalReset' && secondCat === 'reset') {

      this.globalcontractID = '';
      this.globalClient = '';
      this.globalClientName = '';
      this.globalPickup = '';
      this.globalNoOfContractedPickup = '';
      this.globalNoOfDeliveredPickup = '';
      this.globalPerPickupPeriod = '';
      this.globalContractPickupPrice = '';
      this.globalExtraPickupPrice = '';
      this.globalContractStatus = '';
      this.pageSize = 10;
      this.pageNumber = 1;
      this.globalInvoiceTotal = '';
      this.globalDiscount = '';
      this.globalNetDays = '';
      this.globalsubtotal = '';
      this.invoiceGenerated_ToDate = '';
      this.invoiceGenerated_FromDate = '';
      this.getInvoices(spinnerRef);
      this.checkFilters();
      return null;
    }
    this.getInvoices(spinnerRef);
    this.checkFilters();
    return null;
  }
  getInvoices(spinnerRef) {
    this.pickupService.getContractInvoices(this.pageNumber, this.pageSize, this.globalClientName, this.globalPickup, this.globalcontractID,
      this.globalNoOfContractedPickup, this.globalNoOfDeliveredPickup,  this.globalPerPickupPeriod.ID, this.globalContractPickupPrice, this.globalExtraPickupPrice,
      this.globalContractStatus, this.globalInvoiceTotal, this.globalDiscount, this.globalNetDays, this.globalsubtotal,
      this.sortColumn, this.invoiceGenerated_FromDate, this.invoiceGenerated_ToDate).subscribe((data) => {

       data.Result.map((val: any) => {
          val.Invoice_Due_Date = this.dateTimeFormat.transform(val.Invoice_Due_Date, val.TimeZone);
          val.Date = this.dateTimeFormat.transform(val.Date, val.TimeZone);
          val.SentToQuickBooksDate = this.dateTimeFormat.transform(val.Sent_To_QuickBooks_Date, val.TimeZone);
          // val.Period_Start_Date = this.dateTimeFormat.transform(val.Period_Start_Date, val.TimeZone);
          // val.Period_End_Date = this.dateTimeFormat.transform(val.Period_End_Date, val.TimeZone);
          val.Period_Start_Date = moment(val.Period_Start_Date).format('MM/DD/YY');
          val.Period_End_Date = moment(val.Period_End_Date).format('MM/DD/YY');
          val.checked = false
        });

        this.dataSource = data.Result
        this.RecordCount = data.RecordCount
        this.checkedRecords = [];
        this.masterChecked = false;
        if (this.invoiceGenerated_FromDate) this.invoiceGenerated_FromDate = moment(this.invoiceGenerated_FromDate).format('MM/DD/YY');
        if (this.invoiceGenerated_ToDate) this.invoiceGenerated_ToDate = moment(this.invoiceGenerated_ToDate).format('MM/DD/YY');

        this.spinnerService.stop(spinnerRef);
      },
        (error) => {
          this.spinnerService.stop(spinnerRef);
        });
  }
  // pagination
  onChange(e) {
    var spinnerRef = this.spinnerService.start();
    e ? (this.pageNumber = e) : null;
    if (this.invoiceGenerated_FromDate !== "" && this.invoiceGenerated_ToDate != "") {
      const selectedDate = new Date(this.invoiceGenerated_FromDate);
      const ToDate = new Date(this.invoiceGenerated_ToDate);
      ToDate.setHours(23);
      ToDate.setMinutes(59);
      ToDate.setSeconds(59);
      selectedDate.setHours(0);
      selectedDate.setMinutes(0);
      selectedDate.setSeconds(0);
      const selectedNDate = new Date(selectedDate);
      const selectedEndNDate = new Date(ToDate);
      const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
      const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];
      this.invoiceGenerated_FromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
      this.invoiceGenerated_ToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
    }
    this.getInvoices(spinnerRef);
  }
  sortTable(event) {
    var spinnerRef = this.spinnerService.start();
    this.sortColumn = this.mapColumnName(event.active);
    if (this.sortColumn != null) {
      if (event.direction === 'desc') this.sortColumn = '-' + this.sortColumn;
      if (event.direction === 'asc') this.sortColumn = this.sortColumn;
      this.getInvoices(spinnerRef);
    }
  }
  mapColumnName(columnName) {
    if (columnName === 'clientName') return 'ClientName';
    if (columnName === 'contractID') return 'PickupContractId';
    if (columnName === 'invoiceStart') return 'Period_Start_Date';
    if (columnName === 'invoiceEnd') return 'Period_End_Date';
    if (columnName === 'noDeliveredPickup') return 'No_Of_Delivered_Pickups';
    if (columnName === 'noContractedPickup') return 'No_Of_Contracted_Pickups';
    if (columnName === 'contractedPickupPrice') return 'Contract_Price_Per_Pickup';
    if (columnName === "extraPickupPrice") return 'Extra_Price_Per_Pickup';

    if (columnName === 'discount') return 'DiscountInPercent';
    if (columnName === 'invoiceTotal') return 'Total_Pickup_Price';
    if (columnName === 'netTerm') return 'NetDays';
    if (columnName === 'invoiceGenerated') return 'Date';
    if (columnName === 'invoiceDue') return 'Sent_To_QuickBooks_Date';
    if (columnName === 'currentInvoiceStatus') return 'Invoice_Status';

  }

  checkFilters() {
    if (
      this.globalcontractID || this.globalClient || this.globalPickup || this.globalNoOfContractedPickup ||
      this.globalNoOfDeliveredPickup || this.globalPerPickupPeriod || this.globalContractPickupPrice || this.globalExtraPickupPrice ||
      this.globalContractStatus || this.globalDiscount || this.globalNetDays || this.globalInvoiceTotal ||
      this.globalsubtotal || this.invoiceGenerated_FromDate || this.invoiceGenerated_ToDate
    ) this.showReset = true;
    else this.showReset = false;

  }


  addNewContract() {
    const dialogRef = this.dialog.open(AddContractDialogComponent, {
      width: '1400px',
      height: '87vh',
      panelClass: 'pickup-dialog-container',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        var spinnerRef = this.spinnerService.start();
        this.getInvoices(spinnerRef);
      }
    });
  }
  viewContract(element) {
    const dialogRef = this.dialog.open(EditContractDialogComponent, {
      width: '1400px',
      height: '87vh',
      data: element,
      panelClass: 'pickup-dialog-container',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        var spinnerRef = this.spinnerService.start();
        this.getInvoices(spinnerRef);
      }
    });
  }

  selectRow(event, row) {
    row['checked'] = event.checked;
    if (event.checked) {
      this.checkedRecords.push(row);
    } else {
      const index = this.checkedRecords.indexOf(row);
      if (index > -1) {
        this.checkedRecords.splice(index, 1);
      }
    }
  }

  editInvoice(record) {
    const dialogRef = this.dialog.open(EditInvoiceDialogComponent, {
      width: '1400px',
      height: '87vh',
      data: record,
      panelClass: 'pickup-dialog-container',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        var spinnerRef = this.spinnerService.start();
        this.getInvoices(spinnerRef);
      }
    });
  }
  selectAllRow(e) {
    this.masterChecked = e.checked;
    this.dataSource.map((val: any) => {
      val.checked = e.checked;
    });
    let record = this.dataSource.filter((e) => e.checked == true);
    this.checkedRecords = record;
    console.log(this.checkedRecords);
  }
  selected() {

    let record = [];
    if (this.dataSource) {
      record = this.dataSource.filter((e) => e.checked == true);
    }
    if (record.length == 0) return true
    return false

  }
  // Approve invoice func
  approveInvoice() {
    if (this.checkedRecords){
    let record = this.checkedRecords.filter((e) => e.InvoiceStatus.Title == 'Draft')
    record = record.map((e) => e.ID)
    // if(record.length == 0) return;
    var spinnerRef = this.spinnerService.start();
    let payload = {
      "BillingId": record
    }
    this.pickupService.approveInvoices(payload).subscribe((d) => {
      this.dataSource.map((val: any) => val.checked = false);
      const dialogRef = this.dialog.open(SuccesPopupComponent, {
        width: '700px',
        maxWidth: '40vw',
        data: { ClientData: 'Your invoice(s) have been approved successfully.' },
        panelClass: 'custom-savedialog-container',
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getInvoices(spinnerRef);
      });

    },
      (er) => {
        console.log(er);
        alert(er.error.Message);
        this.spinnerService.stop(spinnerRef);

      });
    }
  }
  // fetchStatusInvoice func
  fetchStatusInvoice() {
    let record = this.checkedRecords.filter((e) => e.InvoiceStatus.Title == 'Open')
    record = record.map((e) => e.ID)
    //if(record.length == 0) return;
    var spinnerRef = this.spinnerService.start();
    let payload = {
      "BillingId": record
    }
    this.pickupService.fetchStatusInvoice(payload).subscribe((d) => {
      this.dataSource.map((val: any) => val.checked = false);
      const dialogRef = this.dialog.open(SuccesPopupComponent, {
        width: '700px',
        maxWidth: '40vw',
        data: { ClientData: 'Your invoice(s) status have been updated successfully.' },
        panelClass: 'custom-savedialog-container',
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getInvoices(spinnerRef);
      });
    },
      (er) => {
        console.log(er);
        alert(er.error.Message);
        this.spinnerService.stop(spinnerRef);
      });
  }
  // generateInvoice func
  generateInvoice() {

    let record = this.checkedRecords.filter((e) => e.InvoiceStatus.Title == 'Approved')
    record = record.map((e) => e.ID)

    var spinnerRef = this.spinnerService.start();

    if (record.length == 0) {
      const dialogRef = this.dialog.open(SuccesPopupComponent, {
        width: '700px',
        maxWidth: '40vw',
        data: {
          title: 'Error',
          ClientData: 'You have not selected any approved invoice, Please select approved invoice to send QuickBooks.'
        },
        panelClass: 'custom-savedialog-container',
      });
      dialogRef.afterClosed().subscribe((result) => {
      this.getInvoices(spinnerRef);
      });
      return;
    }

    let payload = record
    this.pickupService.generateInvoice(payload).subscribe((d: any) => {
      if (d.Error != true) {
        this.dataSource.map((val: any) => val.checked = false);
        const dialogRef = this.dialog.open(SuccesPopupComponent, {
          width: '700px',
          maxWidth: '40vw',
          data: { ClientData: 'Your invoice(s) have been to QuickBooks successfully.' },
          panelClass: 'custom-savedialog-container',
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.getInvoices(spinnerRef);
        });
      }
      else {
        const dialogRef = this.dialog.open(SuccesPopupComponent, {
          width: '700px',
          maxWidth: '40vw',
          data: {
            title: 'Error',
            ClientData: d.Message
          },
          panelClass: 'custom-savedialog-container',
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.getInvoices(spinnerRef);
        });
      }
    },
      (er) => {

        this.spinnerService.stop(spinnerRef);

        const dialogRef = this.dialog.open(SuccesPopupComponent, {
          width: '700px',
          maxWidth: '40vw',
          data: {
            title: 'Error',
            ClientData: er.error.Message
          },
          panelClass: 'custom-savedialog-container',
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.getInvoices(spinnerRef);
        });
      });

  }
  addFilterInvoiceGenerated() {
    const dialogRef = this.dialog.open(AddeditDateComponent, {
      width: '800px',
      // height: '700px',
      panelClass: 'custom-datedialog-container',
      data: [
        {
          FromDate: this.invoiceGenerated_ToDate,
          ToDate: this.invoiceGenerated_ToDate,
        },
      ],
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.From != 'Invalid Date' && result.To != 'Invalid Date') {
          var spinnerRef = this.spinnerService.start();
          this.invoiceGenerated_FromDate = moment(result.From).format('MM/DD/YY');
          this.invoiceGenerated_ToDate = moment(result.To).format('MM/DD/YY');

          if (result !== undefined) {
            const selectedDate = new Date(result.From);
            const ToDate = new Date(result.To);

            ToDate.setHours(23);
            ToDate.setMinutes(59);
            ToDate.setSeconds(59);
            selectedDate.setHours(0);
            selectedDate.setMinutes(0);
            selectedDate.setSeconds(0);

            const selectedNDate = new Date(selectedDate);
            const selectedEndNDate = new Date(ToDate);
            const selectedNDateResult = moment(selectedNDate).add(0, 'hours').format().toString().split('+')[0];
            const selectedEndDateResult = moment(selectedEndNDate).add(0, 'hours').format().toString().split('+')[0];
            this.invoiceGenerated_FromDate = selectedNDateResult.split('T')[0] + 'T' + selectedNDateResult.split('T')[1].split('-')[0] + '.00';
            this.invoiceGenerated_ToDate = selectedEndDateResult.split('T')[0] + 'T' + selectedEndDateResult.split('T')[1].split('-')[0] + '.00';
            this.checkFilters();
            this.getInvoices(spinnerRef);
          }
        }
        else {
          alert('Please select the Start date and End date');
        }
      }
    });
  }

}

