import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-pickupmodal',
  templateUrl: './save-pickupmodal.component.html',
  styleUrls: ['./save-pickupmodal.component.scss']
})
export class SavePickupmodalComponent implements OnInit {

  constructor(private router: Router, public dialogRef: MatDialogRef<SavePickupmodalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }
  pickupDetails() {
    this.router.navigate(['pickupDetails/' + this.data['DeliveryId'] + '']);
    this.dialogRef.close();
  }
  trackPickup() {
    this.router.navigate(['TrackRoutePickedUp/' + this.data['DeliveryId'] + '']);
    this.dialogRef.close();
  }
  OTPpickup() {
    this.router.navigate(['Pickups' + '']);
    this.dialogRef.close();
  }

}
