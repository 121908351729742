import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment-timezone';
import { modalDataService } from 'src/app/shared/services/modalData.service';

@Component({
  selector: 'app-addedit-date',
  templateUrl: './addedit-date.component.html',
  styleUrls: ['./addedit-date.component.scss'],
})
export class AddeditDateComponent implements OnInit {
  changedToDate: any;
  changedFromDate: any;
  fromTime = '00:00:00';
  toTime = '23:59:59';
  fromDate: any;
  toDate: any;
  infoDate: any;
  constructor(
    public dialogRef: MatDialogRef<AddeditDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog,
    private observedData: modalDataService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.observedData.basicInfoDateObserve.subscribe(res => {
      this.infoDate = JSON.parse(res);
    });
    this.fromDate = new Date(this.data[0].FromDate);
    this.toDate = new Date(this.data[0].ToDate);
    this.infoDate.requestStatusinVis = false;
    this.observedData.updateBasicInfoDate(JSON.stringify(this.infoDate));
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  onChangeFromDate(date) {
    this.fromDate = date;
  }
  onChangeToDate(date) {
    this.toDate = date;
  }
  popupClose() {
    const data = {
      From: this.fromDate,
      To: this.toDate
    };
    this.dialogRef.close(data);
  }
}
