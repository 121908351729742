import { Component, OnInit, HostBinding, Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/core/menu.service';
import { AppState } from 'src/app/shared/app-state';
import { getFeature } from 'src/app/shared/services/featureGuard.model';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { environment } from 'src/environments/environment';
import { Input, ElementRef, ViewChild } from '@angular/core';
import * as Hammer from 'hammerjs';
import { MatSidenav } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { SAASService } from 'src/app/shared/services/saas.service';
import { AccountService } from 'src/app/shared/account.service';
@Component({
  selector: 'app-logo-header',
  templateUrl: './logo-header.component.html',
  styleUrls: ['./logo-header.component.scss'],
})
@Directive({
  selector: '[orientatio]',
})
export class LogoHeaderComponent implements OnInit {
  user: any;
  name: string;
  finalname: any;
  imgSrc = 'assets/images/logo.png';
  ImageUrlnull: boolean;
  ImageUrl: boolean;
  isRequestPickupsVisible: boolean;
  SchedulePickupVisible: boolean;
  menu: Array<any> = [];
  breadcrumbList: Array<any> = [];
  userRole: any;
  facilitatorClients: any;
  facilityGroupClients: any;
  nonprofitsClients: any;
  info: any;
  app_env: string = environment.app_env;
  FacilityClients: any;
  navbarOpen: boolean;
  sideNavImg = true;
  sideCloseNavImg: boolean;
  navbarSideOpen: boolean;
  orentation: boolean;
  getallClients = false;
  appVer: any;
  Versioninfo: any;
  currentVersion: any;
  version: any;
  @HostBinding('class') mode;
  @ViewChild(MatSidenav, { static: true })
  public sidenav: MatSidenav;
  appContainer: any;
  backdrop: boolean;
  hammertime: any;
  activeDrop: boolean;
  editSubscription: boolean;
  editClientID: any;
  sassinfo: any;
  sassinfodata = true;
  adminView: boolean;
  infostate: any;
  featureinfo: any;
  OTPFeature = false;
  pricingControl = true;
  infoallclients: any;
  profileimage: any;
  showProfileSetting: boolean = false;
  firstNameLetter: any;
  lastNameLetter: any;

  constructor(
    elementRef: ElementRef,
    private router: Router,
    private accountService: AccountService,
    private sassService: SAASService,
    private AdminService: AdmintoolsService,
    private customerService: CustomerService,
    private ClientFilterdata: ClientFilterService,
    private state: AppState,
    private menuService: MenuService
  ) {
    this.hammertime = new Hammer(elementRef.nativeElement, {});
    if (window.innerHeight > window.innerWidth) {
      this.hammertime.on('panright', (event) => {
        this.sidenav.open();
      });
      this.hammertime.on('panleft', (event) => {
        this.sidenav.close();
      });
      this.hammertime.on('panup', (event) => false);
      this.hammertime.on('pandown', (event) => false);
    } else {
      this.hammertime.off('panright', (event) => false);
      this.hammertime.off('panleft', (event) => false);
      this.hammertime.off('panup', (event) => false);
      this.hammertime.off('pandown', (event) => false);
    }
  }
  ngOnInit() {
    this.ClientFilterdata.basicFeatureInfoObserve.subscribe((res) => {
      this.featureinfo = JSON.parse(res);
      // if (this.featureinfo.featureData) {
      if (this.featureinfo.featureData) {
        for (const element of this.featureinfo.featureData) {
          if (element.Title === 'OTP' && element.EnableFeature === true) {
            this.OTPFeature = true;
          }
          if (element.Title === 'Admin Pricing Control' && element.EnableFeature === false) {
            this.pricingControl = false;
          }
        }
        // if (this.featureinfo.featureData[0].Title === 'OTP') {
        //   if (this.featureinfo.featureData[0].EnableFeature === false) {
        //     this.OTPFeature = false;
        //   }
        // }
        // if (this.featureinfo.featureData[2].Title === 'Admin Pricing Control') {
        //   if (this.featureinfo.featureData[2].EnableFeature === false) {
        //     this.pricingControl = false;
        //   }
        // }
      }
      // }
    });
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.info = JSON.parse(res);
    });
    this.ClientFilterdata.basicInfoObserveclient.subscribe((res) => {
      this.infoallclients = JSON.parse(res);
    });
    this.ClientFilterdata.basicCityInfoObserve.subscribe((res) => {
      this.infostate = JSON.parse(res);
    });
    if (!this.info.facilitator || !this.info.facilitatorGroup || !this.info.facility || !this.info.nonprofits) {
      this.getClients();
    }
    if (!this.infostate.statedata) {
      this.pickupstates();
    }
    this.ClientFilterdata.basicVersionObserve.subscribe((res) => {
      this.Versioninfo = JSON.parse(res);
    });
    this.state.user = JSON.parse(localStorage.getItem('user')).user;
    this.user = this.state.user;

    if (this.user.User) {
      this.firstNameLetter = this.user.User.FirstName;
      this.lastNameLetter = this.user.User.LastName;
    }

    // console.log(this.firstNameLetter);
    this.profileimage = this.user.User.ImageUrl;
    if (this.profileimage) {
      // console.log(this.profileimage);
      if (this.profileimage.split('/Goodr3')[0] === 'https://gdr-live-api-3-0.eastus.cloudapp.azure.com') {
        this.accountService.getUserDetails(this.user.User.UserId).subscribe((data) => {
          this.state.user.User.ImageUrl = data.Result[0].ImageUrl;
          localStorage.setItem('user', JSON.stringify(this.state));
          this.profileimage = data.Result[0].ImageUrl;
        });
      }
    }
    if (this.user.User.Roles[0].Name === 'Admin') {
      this.adminView = true;
    }
    if (this.user.User.Roles[0].Name === 'Facility') {
      if (this.user.User.Clients.length > 0) {
        if (this.user.User.Clients[0].ClientType === 'SAAS Facility') {
          if (this.user.User.Clients[0].StripeCustomerId) {
            this.saasgettingDetails();
          } else {
            this.sassinfodata = true;
          }
          this.ClientFilterdata.basicInfosassObserve.subscribe((res) => {
            this.sassinfo = JSON.parse(res);
            this.sassinfodata = this.sassinfo.subscription;
            // this.sassinfo = this.sassinfo.subscriptionPlan ;
            if (!this.sassinfodata) {
              this.sassinfodata = true;
            }
          });
        }
      }
    }
    // console.log(this.Versioninfo.version)
    // if (!this.Versioninfo.version) {
    //   this.Versioninfo.version = environment.version;
    this.initVersionCheck();
    // }

    this.isRequestPickupsVisible = getFeature('requestPickupView');
    this.SchedulePickupVisible = getFeature('schedulepickupbtn');
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.state.isLoggedIn = JSON.parse(localStorage.getItem('user')).isLoggedIn;
      // this.state.user = JSON.parse(localStorage.getItem("user")).user;
      // this.user = this.state.user;
      if (this.user.User.Roles[0].Name === 'Facility Group') {
        if (this.user.User.Clients.length > 0) {
          if (this.user.User.Clients[0].ClientType === 'SAAS Facility Group') {
            this.editSubscription = true;
            this.editClientID = this.user.FacilityGroupId;
          } else {
            this.editSubscription = false;
          }
        } else {
          this.editSubscription = false;
        }
      }
      if (this.user.User.Roles[0].Name === 'Facility') {
        if (this.user.User.Clients.length > 0) {
          if (this.user.User.Clients[0].ClientType === 'SAAS Facility') {
            this.editSubscription = true;
            this.editClientID = this.user.User.Clients[0].ClientId;
          } else {
            this.editSubscription = false;
          }
        } else {
          this.editSubscription = false;
        }
      }
      // this.menu = this.menuService.getMenu();
      // this.listenRouting();
      if (this.user.User.ImageUrl == null) {
        this.ImageUrlnull = true;
        this.ImageUrl = false;
      } else {
        this.ImageUrl = true;
      }
      this.userRole = this.state.user.User.Roles[0].Name;
    } else {
      this.performLogOutTask();
    }

    // if (this.router.url === '/profile') {
    //   this.checkProfile();
    // }
  }
  initVersionCheck(frequency = 1000 * 60 * 30) {
    let version = JSON.parse(localStorage.getItem('version'));
    this.currentVersion = version;
    setInterval(() => {
      this.checkVersion();
    }, frequency);
  }
  pickupstates() {
    this.sassService.pickupstatedetails().subscribe((data) => {
      this.infostate.statedata = data['Result'];
      this.ClientFilterdata.updateBasicCityInfo(JSON.stringify(this.infostate));
    });
  }
  saasgettingDetails() {
    this.customerService.getCustomerDetail(this.editClientID).subscribe(
      (data) => {
        if (data.Result[0].StripePlanId) {
          this.sassinfodata = true;
        } else {
          this.sassinfodata = false;
        }
        this.sassinfo.subscription = this.sassinfodata;
        this.ClientFilterdata.updateBasicSAASInfo(JSON.stringify(this.sassinfo));
        // this.ClientFilterdata.updateBasicSAASInfo(JSON.stringify(this.sassinfo));
      },
      (error) => {
        // this.state.isSpinnerVisible = false;
        // alert(JSON.stringify(error));
      }
    );
  }
  @HostListener('window:resize') updateOrientatioState() {
    if (window.innerHeight > window.innerWidth) {
      this.hammertime.on('panright', (event) => {
        this.sidenav.open();
      });
      this.hammertime.on('panleft', (event) => {
        this.sidenav.close();
      });
      this.hammertime.on('panup', (event) => false);
      this.hammertime.on('pandown', (event) => false);
    } else {
      // this.backdrop = false;
      this.hammertime.off('panright', (event) => false);
      this.hammertime.off('panleft', (event) => false);
      this.hammertime.off('panup', (event) => false);
      this.hammertime.off('pandown', (event) => false);
    }
  }
  checkVersion() {
    this.AdminService.getVersionAPI().subscribe((res) => {
      this.version = res['Portal'].Production;
      localStorage.setItem('version', JSON.stringify(this.version));
      this.Versioninfo.version = this.version;
      this.ClientFilterdata.updateBasicVersion(JSON.stringify(this.Versioninfo));
      // const hashChanged = this.hasHashChanged(this.currentVersion, this.version);
      // If new version, do something
      if (this.currentVersion !== this.version) {
        location.reload(true);
        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
        // for an example: location.reload();
      }
      // store the new hash so we wouldn't trigger versionChange again
      // only necessary in case you did not force refresh
      this.currentVersion = this.version;
      //     },
      //     (err) => {
      //         console.error(err, 'Could not get version');
      //     }
      // );
    });
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  // private hasHashChanged(currentHash, newHash) {
  //   console.log(currentHash, newHash)
  //   if (!currentHash) {
  //     return false;
  //   }

  //   return currentHash !== newHash;
  // }
  Requestpickup() {
    this.router.navigate(['/RequestPickup-menu']);
  }
  trackpickup() {
    this.router.navigate(['/Pickups']);
  }
  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this.router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;
        routerList = routerUrl.slice(1).split('/');
        routerList.forEach((router, index) => {
          target = target.find((page) => page.path.slice(2) === router);
          this.breadcrumbList.push({});
          if (index + 1 !== routerList.length) {
            target = target.children;
          }
        });
        for (var i = 0; i < this.breadcrumbList.length; i++) {
          // this.allclientdetails=this.clients[j];
          this.finalname = this.breadcrumbList[i].name;
        }
      }
    });
  }
  // get roles
  getClients() {
    this.AdminService.getClientwithoutPagination().subscribe(
      (clients) => {
        const facilitator = clients.Result.filter((client) => {
          return client.ClientType && client.ClientType === 'Facilitator';
        });
        this.facilitatorClients = facilitator;
        const facilityGroupClient = clients.Result.filter((client) => {
          return (client.ClientType && client.ClientType === 'Facility Group') || (client.ClientType && client.ClientType === 'SAAS Facility Group');
        });
        this.facilityGroupClients = facilityGroupClient;
        // if (
        // this.state.user.IsInviteUser === true
        //  && this.userRole === 'Facility'
        // ) {
        //   const Facility = clients.Result.filter(client => {
        //     return (
        //       (client.ClientType && client.ClientType === 'Facility') ||
        //       (client.ClientType &&
        //         client.ClientType === 'SAAS Facility' &&
        //         client.InviteUser === true)
        //     );
        //   });
        //   this.FacilityClients = Facility;
        // } else {
        const Facility = clients.Result.filter((client) => {
          return (client.ClientType && client.ClientType === 'Facility') || (client.ClientType && client.ClientType === 'SAAS Facility');
        });
        this.FacilityClients = Facility;
        // }
        const nonprofitsClient = clients.Result.filter((client) => {
          return client.ClientType && client.ClientType === 'Non Profit';
        });
        this.nonprofitsClients = nonprofitsClient;

        if (this.userRole === 'Facility Group') {
          this.AdminService.FacilityGroupClients(this.state.user.User.Email).subscribe((data) => {
            this.facilityGroupClients = data.Result[0].Clients;
            this.info.facilitatorGroup = this.facilityGroupClients;
            this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
          });
        } else {
          if (this.facilityGroupClients) {
            this.info.facilitatorGroup = this.facilityGroupClients;
          }
        }

        if (this.userRole == 'Facilitator') {
          this.AdminService.FacilityGroupClients(this.state.user.User.Email).subscribe((data) => {
            this.facilitatorClients = data.Result[0].Clients;
            this.info.facilitator = this.facilitatorClients;
            this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
          });
        } else {
          if (this.facilitatorClients) {
            this.info.facilitator = this.facilitatorClients;
          }
        }
        if (this.nonprofitsClients) {
          this.info.nonprofits = this.nonprofitsClients;
        }
        if (this.FacilityClients) {
          this.info.facility = this.FacilityClients;
        }
        const allclientlist = clients.Result.filter((client) => {
          return client.ClientType && client.ClientType !== 'Non Profit' && client.ClientType !== 'MRF';
        });
        this.getallClients = true;
        this.info.getallClients = this.getallClients;
        this.infoallclients.allClients = allclientlist;
        this.ClientFilterdata.updateBasicInfoclient(JSON.stringify(this.infoallclients));
        this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
      },
      (error) => {
        this.state.isSpinnerVisible = false;
        this.getallClients = false;
        this.info.getallClients = this.getallClients;
        this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.info));
        this.ClientFilterdata.updateBasicInfoclient(JSON.stringify(this.infoallclients));
        // alert(JSON.stringify(error));
      }
    );
  }
  performLogOutTask() {
    this.state.isSpinnerVisible = false;
    this.state.isLoggedIn = false;
    this.state.user = null;
    localStorage.clear();
    location.href = '/login';
    (<any>window).godrEmail = null;
    (<any>window).godrRole = null;
    // this.router.navigate(["login"]);
  }
  toggleSideNavbar() {
    // if (this.navbarSideOpen !== true) {
    //   this.activeDrop = true;
    // } else {
    //   this.activeDrop = false;
    // }
    this.navbarSideOpen = !this.navbarSideOpen;
  }
  closeNavbar() {
    this.sidenav.close();
  }
  toggleNavbar() {
    // this.appContainer = this.appContainer;
    this.sidenav.open();
  }

  showHideProfileSetting() {
    this.showProfileSetting = !this.showProfileSetting;
  }

  checkProfile() {
    this.ngOnInit();
  }
  // checkProfile() {
  //   setInterval(() => {
  //     let user = JSON.parse(localStorage.getItem('user')).user;

  //     if (this.profileimage !== user.User.ImageUrl) {
  //       this.profileimage = user.User.ImageUrl;
  //     }
  //   }, 5000);
  // }
}
