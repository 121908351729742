import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
@Injectable()
export class ClientFilterService {
  basicinfo = {
    facilitatorGroup: '',
    nonprofits: '',
    facility: '',
    facilitator: '',
    selectedDriver: '',
    getallClients: ''
  };
  basicinfoclient = {
    allClients: ''
  }
  basicFeatureinfo = {
    featureData: ''
  };
  basicCityinfo = {
    statedata: ''
  };
  basicsaasinfo = {
    subscription: ''
  };
  basicversion = {
    version: ''
  };
  basicClientinfo = {
    globalClientName: '',
    globalPickupTypeid: '',
    filterPickupType: '',
    globalEmail: '',
    globalClientType: '',
    globalPickupType: '',
    globalselectedMenu: '',
    globalclientId: '',
    globalfacilityGroup: '',
    globalfacilitator: '',
    pageNumber: '',
    pageSize: '',
    sortColumn: '',
    globalStatus: '',
    filterDriver: '',
    filterFacilitator: '',
    filterFacilitygroup: '',
    globalPickupTypeName: '',
    globalFacilitatorGroupName: '',
    globalFacilitatorName: ''
  };
  basicFilterDistanceMatrix = {
    globalClientName: '',
    globalNonprofitName: '',
    globalSearchBy: ''
  }
  // public bookmarks: BehaviorSubject<any> = new BehaviorSubject([]); // The initial value
  private basicInfoData = new BehaviorSubject<string>(
    JSON.stringify(this.basicinfo)
  );
  private basicInfoclientData = new BehaviorSubject<string>(
    JSON.stringify(this.basicinfoclient)
  );
  private basicInfoFeatureData = new BehaviorSubject<string>(
    JSON.stringify(this.basicFeatureinfo)
  );
  private basicCityInfoData = new BehaviorSubject<string>(
    JSON.stringify(this.basicCityinfo)
  );
  private basicInfosaasData = new BehaviorSubject<string>(
    JSON.stringify(this.basicsaasinfo)
  );
  private basicVersionData = new BehaviorSubject<string>(
    JSON.stringify(this.basicversion)
  );
  private basicClientinfoData = new BehaviorSubject<string>(
    JSON.stringify(this.basicClientinfo)
  );
  private basicFilterDistanceMatrixData = new BehaviorSubject<string>(
    JSON.stringify(this.basicFilterDistanceMatrix)
  );
  basicInfoObserve = this.basicInfoData.asObservable();
  basicInfoObserveclient = this.basicInfoclientData.asObservable();
  basicFeatureInfoObserve = this.basicInfoFeatureData.asObservable();
  basicCityInfoObserve = this.basicCityInfoData.asObservable();
  basicInfosassObserve = this.basicInfosaasData.asObservable();
  basicVersionObserve = this.basicVersionData.asObservable();
  basicClientinfoObserve = this.basicClientinfoData.asObservable();
  basicFilterDistanceMatrixObserve = this.basicFilterDistanceMatrixData.asObservable();

  updateBasicVersion(data: string) {
    this.basicVersionData.next(data);
  }
  updateBasicInfo(data: string) {
    this.basicInfoData.next(data);
  }
  updateBasicInfoclient(data: string) {
    this.basicInfoclientData.next(data);
  }
  updateBasicFeatureInfo(data: string) {
    this.basicInfoFeatureData.next(data);
  }
  updateBasicCityInfo(data: string) {
    this.basicCityInfoData.next(data);
  }
  updateBasicSAASInfo(data: string) {
    this.basicInfosaasData.next(data);
  }
  resetBasicInfo(data: string) {
    this.basicInfoData.next(JSON.stringify(this.basicinfo));
  }
  updatebasicClientinfo(data: string) {
    this.basicClientinfoData.next(data);
  }
  resetbasicClientinfo(data: string) {
    this.basicClientinfoData.next(JSON.stringify(this.basicClientinfo));
  }
  basicFilterDistanceMatrixinfo(data: string) {
    this.basicFilterDistanceMatrixData.next(data);
  }
  resetbasicFilterDistanceMatrixinfo(data: string) {
    this.basicFilterDistanceMatrixData.next(JSON.stringify(this.basicFilterDistanceMatrix));
  }
}
