import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SAASService } from 'src/app/shared/services/saas.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { PricingModuleServiceService } from 'src/app/shared/services/pricing-module-service.service';
declare var Stripe: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  email: string;
  firstname: string;
  lastname: string;
  cardNumber: string;
  cvc: string;
  stripe: any;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;
  multipleFGadd: any[];
  multipleFGaddFinal: any[];
  message: string;
  yearlyPay: any;
  UserId: any;
  ClientId: any;
  clientId: any;
  faclityCount: any;
  storedata: any;
  fgdata: boolean;
  monthlyPrice: any;
  yearlyPrice: any;
  monthlyID: any;
  yearlyID: any;
  finalPrice: any;
  promocode: any;
  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private sassService: SAASService,
    public _rdata: modalDataService,
    private pricingModule: PricingModuleServiceService,
    private spinnerService: SpinnerService
  ) {}
  ngOnInit() {
    let pickupTypefinal;
    this.finalPrice = localStorage.getItem('finalvaluepay');
    if (
      localStorage.getItem('promocodedata') !== undefined &&
      localStorage.getItem('promocodedata') !== null &&
      localStorage.getItem('promocodedata') !== ''
    ) {
      this.promocode = JSON.parse(localStorage.getItem('promocodedata'));
    }
    this.yearlyPay = JSON.parse(localStorage.getItem('accountType'));
    this.UserId = JSON.parse(localStorage.getItem('UserId'));
    this.ClientId = JSON.parse(localStorage.getItem('ClientId'));
    if (JSON.parse(localStorage.getItem('saasFG'))) {
      this.multipleFGadd = JSON.parse(
        localStorage.getItem('multiplefacilities')
      );
      if (this.multipleFGadd[0].PickupType.length === 2) {
        pickupTypefinal = 'combined';
      } else if (
        this.multipleFGadd[0].PickupType[0].Title === 'Organic Recycling'
      ) {
        pickupTypefinal = 'Organic';
      } else {
        pickupTypefinal = 'Food';
      }
      this.storedata = JSON.parse(localStorage.getItem('saasFG'));
      this.faclityCount = this.multipleFGadd.length;
      this.fgdata = true;
    } else {
      this.storedata = JSON.parse(localStorage.getItem('multiplefacilities'));
      this.faclityCount = 1;
      this.fgdata = false;
      if (this.storedata[0].PickupType.length === 2) {
        pickupTypefinal = 'combined';
      } else if (
        this.storedata[0].PickupType[0].Title === 'Organic Recycling'
      ) {
        pickupTypefinal = 'Organic';
      } else {
        pickupTypefinal = 'Food';
      }
    }
    // if (
    //   pickupTypefinal === undefined ||
    //   pickupTypefinal === undefined ||
    //   !pickupTypefinal
    // ) {
    //   pickupTypefinal = 'combined';
    // }
    // if(JSON.parse(localStorage.getItem('multiplefacilities'))[0].Account === 'SAAS Facility'){
    //   this.faclityCount = 1;
    // }
    // this.sassService.getSubDetails(this.ClientId).subscribe(
    //   data => {
    //     if (data.Result[0].ClientCategory[0].Title === 'Facility Group') {
    //       this.faclityCount = JSON.parse(localStorage.getItem('NOofFacility'));
    //     } else {
    //       this.faclityCount = 1;
    //     }
    //   }
    // );
    this.getPricingRated(pickupTypefinal);
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();
    this.cardNumberElement = elements.create('cardNumber');
    this.cardNumberElement.mount('#card-number-element');
    this.cardNumberElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    this.cardExpiryElement = elements.create('cardExpiry');
    this.cardExpiryElement.mount('#card-expiry-element');
    this.cardExpiryElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    this.cardCvcElement = elements.create('cardCvc');
    this.cardCvcElement.mount('#card-cvc-element');
    this.cardCvcElement.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  getPricingRated(pickuptype) {
    this.pricingModule.getPrcingSAAS().subscribe((data) => {
      let priceData;
      priceData = data['Result'];
      for (const status of priceData) {
        if (status.SubscriptionType === 'month') {
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.monthlyPrice = status.Price;
              this.monthlyID = status.StripePlanId;
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.monthlyPrice = status.Price;
              this.monthlyID = status.StripePlanId;
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.monthlyPrice = status.Price;
            this.monthlyID = status.StripePlanId;
          }
          // console.log(this.yearlyPrice)
        } else {
          // console.log(pickuptype)
          // console.log(status.PickupType)
          if (status.PickupType.length > 0 && pickuptype !== 'combined') {
            // console.log(status.PickupType)
            if (
              status.PickupType[0].Title === 'Organic Recycling' &&
              pickuptype === 'Organic' &&
              pickuptype !== 'Food'
            ) {
              this.yearlyPrice = status.Price;
              this.yearlyID = status.StripePlanId;
            } else if (
              status.PickupType[0].Title === 'Food' &&
              pickuptype !== 'Organic' &&
              pickuptype === 'Food'
            ) {
              this.yearlyPrice = status.Price;
              this.yearlyID = status.StripePlanId;
            }
          } else if (
            status.PickupType.length === 0 &&
            pickuptype === 'combined' &&
            pickuptype !== 'Organic' &&
            pickuptype !== 'Food'
          ) {
            this.yearlyPrice = status.Price;
            this.yearlyID = status.StripePlanId;
          }
          // console.log(this.monthlyPrice)
        }
        // if (status.SubscriptionType === 'month') {
        //   this.monthlyPrice = status.Price;
        //   this.monthlyID = status.StripePlanId;
        // } else {
        //   this.yearlyPrice = status.Price;
        //   this.yearlyID = status.StripePlanId;
        // }
        // if (this.driverSelected === status.ID) {
        //this.surplusnorganicmonthly = this.data.Result[0].Price;
      }
    });
  }
  goToSucessPage() {
    this.router.navigate(['paymentSuccess']);
  }
  goToBillingPage() {
    this.router.navigate(['billing/']);
  }
  CancelStripe() {
    const payload = {
      ClientId: this.ClientId,
      UserId: this.UserId,
      Delete: true,
    };
    const spinnerRef = this.spinnerService.start();
    this.sassService.reigster(payload).subscribe(
      (data) => {
        this.spinnerService.stop(spinnerRef);
      },
      (error) => {
        alert(JSON.stringify(error));
        this.spinnerService.stop(spinnerRef);
        // this.buttonDisabled = false;
      }
    );
  }
  createUser() {
    this.stripe.createToken(this.cardNumberElement).then((result) => {
      if (result.token.id) {
        let planid;
        let body;
        let payload;
        if (this.yearlyPay === 'montly') {
          planid = this.monthlyID;
        } else {
          planid = this.yearlyID;
        }
        if (this.fgdata === true) {
          let len = this.multipleFGadd.length;
          for (let i = 0; i < len; i++) {
            delete this.multipleFGadd[i].Id;
          }
          this.multipleFGaddFinal = [this.storedata, ...this.multipleFGadd];
          const body1 = {
            PromoCodeName: this.promocode,
            Token: result.token.id,
            PlanId: planid,
            SAASClient: this.multipleFGaddFinal,
          };
          payload = body1;
        } else {
          body = {
            PromoCodeName: this.promocode,
            Token: result.token.id,
            PlanId: planid,
            SAASClient: [
              {
                Account: this.storedata[0].Account,
                Address1: this.storedata[0].Address1,
                Address2: this.storedata[0].Address2,
                CityId: this.storedata[0].CityId,
                CompanyTitle: this.storedata[0].CompanyTitle,
                Country: this.storedata[0].Country,
                Email: this.storedata[0].Email,
                FirstName: this.storedata[0].FirstName,
                LastName: this.storedata[0].LastName,
                MenuEdit: this.storedata[0].MenuEdit,
                PhoneNumber: this.storedata[0].PhoneNumber,
                PickupType: this.storedata[0].PickupType,
                StateId: this.storedata[0].StateId,
                TaxID: this.storedata[0].TaxID,
                ZipCode: this.storedata[0].ZipCode,
              },
            ],
          };
          payload = body;
        }
        const spinnerRef = this.spinnerService.start();
        this.sassService.reigster(payload).subscribe(
          (data) => {
            if (data.message === undefined) {
              localStorage.removeItem('multiplefacilities');
              localStorage.removeItem('saasFG');
              localStorage.removeItem('promocodedata');
              localStorage.removeItem('finalvaluepay');
              localStorage.setItem('updateheckout', JSON.stringify(false));
              this.router.navigate(['paymentSuccess']);
            } else {
              alert(data.message);
            }
            this.spinnerService.stop(spinnerRef);
          },
          (error) => {
            // alert(JSON.stringify(error));
            if (error) {
              alert("Sorry, payment failed! Click on 'Pay' to continue");
            }
            this.spinnerService.stop(spinnerRef);
            // this.buttonDisabled = false;
          }
        );
      }
    });
  }
}
