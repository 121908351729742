import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { UpoloadMenuDialogComponent } from '../../upoload-menu-dialog/upoload-menu-dialog.component';
import { AccountcompletionMenuDialogComponent } from '../../accountcompletion-menu-dialog/accountcompletion-menu-dialog.component';
import { ImportMenuDialogComponent } from '../../import-menu-dialog/import-menu-dialog.component';
import { modalDataService } from 'src/app/shared/services/modalData.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { AdmintoolsService } from 'src/app/shared/services/admintools.service';
import { Router } from '@angular/router';
import { AppState } from 'src/app/shared/app-state';
import { ClientFilterService } from 'src/app/shared/services/clientFilter.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { specialPipe } from 'src/app/shared/pipes/specialpipe';
import { SuccessfulMenuDialogComponent } from '../../successful-menu-dialog/successful-menu-dialog.component';
import { UnsuccessfulMenuDialogComponent } from '../../unsuccessful-menu-dialog/unsuccessful-menu-dialog.component';

@Component({
  selector: 'app-customer-create-menu-dialog',
  templateUrl: './customer-create-menu-dialog.component.html',
  styleUrls: ['./customer-create-menu-dialog.component.scss'],
})
export class CustomerCreateMenuDialogComponent implements OnInit {
  modalclose: string;
  info: any;
  menuType: any;
  customId: any;
  grabId: any;
  genericId: any;
  noMenuSelectedId = null;
  clientId: any;
  menusId: any;
  successData: any;
  clientData: any;
  customerData: any;
  ClientCategoryId: any;
  buttonDisabled: boolean;
  infoClients: any;
  spinnerRef: any;
  customerMenuType: any;
  convertType;
  importFile: any;
  importFilesField: any;
  Menus: any;
  MenuCount: any;
  dataSource: any;
  ProductArray = [];
  categoriesArray = [];
  selectmenus: boolean = false;
  Menuscategory: any;
  newCSVArray: string[];
  dataLength: number;
  newcsvrecordlength: number;
  csvRecords: any;
  csvRecordsData: any[];
  requestDataDisabled: boolean;
  repeaterror: boolean;
  newData: string[];
  stopper = 1;
  successCount: any;
  failuerCount: any;
  constructor(
    public _rdata: modalDataService,
    private spinnerService: SpinnerService,
    private AdminService: AdmintoolsService,
    private CustomerService: CustomerService,
    public dialog: MatDialog,
    private ClientFilterdata: ClientFilterService,
    private state: AppState,
    private router: Router,
    public dialogRef: MatDialogRef<CustomerCreateMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.ClientFilterdata.basicInfoObserve.subscribe((res) => {
      this.infoClients = JSON.parse(res);
    });
    this.buttonDisabled = false;
    this.successData = this.data;
    this.customerData = this.successData.customerData[0];
    this.customerMenuType = this.customerData.MenuType;
    if (this.customerMenuType.length > 0) {
      this.customerMenuType[0].Title === 'Custom' ? (this.convertType = 'generic') : (this.convertType = 'custom');
    }

    // console.log(this.customerData);
    // this._rdata.basicInfo2Observe.subscribe(res => {
    //   this.info = JSON.parse(res);
    // });
    //get-clients
    this.CustomerService.getCustomerDetail(this.successData.clientId).subscribe((data) => {
      this.clientData = data;
    });
  }

  fileChangeListener($event: any): void {
    this.importFile = $event;
    document.getElementById('deleteImage').style.display = 'block';
  }

  deleteFile(inputFile) {
    inputFile.value = '';

    document.getElementById('deleteImage').style.display = 'none';
  }
  createAccount(type) {
    // this.createAccount();
    this.buttonDisabled = true;
    this.CustomerService.getMenuTypes().subscribe(
      (data) => {
        let menus = data['Result'];
        for (const menu of menus) {
          if (menu.Title == 'Generic') {
            this.genericId = menu.ID;
          } else if (menu.Title == 'Grab') {
            this.grabId = menu.ID;
          } else {
            this.customId = menu.ID;
          }
          this.menuType = menu;
        }
        this.createAccountMenu(type);
      },
      (error) => {
        alert(JSON.stringify(error));
        this.buttonDisabled = false;
      }
    );
  }

  async deleteFacilityMenu(type) {
    this.CustomerService.getAllFacilityMenus(1, 500, this.customerData.ClientId, null).subscribe((data) => {
      // console.log(this.customerData.ClientId);
      // console.log(data);
      // this.spinnerService.stop(spinnerRef);
      this.Menus = data.Result;
      this.MenuCount = data.RecordCount;
      this.dataSource = this.Menus;
      this.ProductArray = [];
      if (data.Result.length === 0) {
        // alert("Zero Item's Deleted");
        if (type === 'custom') {
          this.convertToCustomMenuType(type);
        }
      } else {
        for (var i = 0; i < data.Result.length; i++) {
          // console.log(data.Result[i].ProductId);
          var product_ID = data.Result[i].ProductId;
          this.ProductArray.push(product_ID);
        }
        let z = 0;
        // alert("Are you sure you want to delete the Item ?");
        for (var i = 0; i < this.ProductArray.length; i++) {
          this.CustomerService.deleteItem(this.ProductArray[i]).subscribe((data) => {
            // console.log(data);
            z++;
            if (type === 'custom') {
              if (z === this.ProductArray.length) {
                this.convertToCustomMenuType(type);
              }
            }
          });
        }
      }
    });
  }

  async deleteFacilityCategories() {
    this.categoriesArray = [];
    var spinnerRef = this.spinnerService.start();
    this.CustomerService.getAllCategories(1, 500, null, this.customerData.ClientId).subscribe((data) => {
      // console.log(data);
      if (data.Result.length === 0) {
        // alert("Zero Item's Deleted");
      } else {
        for (var i = 0; i < data.Result.length; i++) {
          // console.log(data.Result[i].SectionId);
          var categorysection_ID = data.Result[i].SectionId;
          this.categoriesArray.push(categorysection_ID);
        }
        // alert("Are you sure you want to delete the Item ?");
        for (var i = 0; i < this.categoriesArray.length; i++) {
          this.CustomerService.deleteProductSections(this.categoriesArray[i]).subscribe((data) => {
            // console.log(data);
          });
        }
      }
      this.spinnerService.stop(spinnerRef);
      this.Menuscategory = data.Result;
    });
  }

  updateAccount(type) {
    this.CustomerService.getMenuTypes().subscribe(
      (data) => {
        let menus = data['Result'];
        for (const menu of menus) {
          if (menu.Title == 'Generic') {
            this.genericId = menu.ID;
            // console.log(this.genericId);
          } else if (menu.Title == 'Grab') {
            this.grabId = menu.ID;
          } else {
            this.customId = menu.ID;
          }
          this.menuType = menu;
        }
        if (type === 'custom') {
          if (this.importFile !== undefined && this.importFile !== null && this.importFile.srcElement.files[0]) {
            this.deleteFacilityMenu(type);
            this.deleteFacilityCategories();
            this.spinnerRef = this.spinnerService.start();
            this.buttonDisabled = true;
            // setTimeout(() => {
            //   this.convertToCustomMenuType(type);
            // }, 1000);
            // we are uncommented because of delete menus taking time so after delete we call the convertToCustomMenuType function
            // console.log(this.customerData.ClientId);
          } else {
            this.selectmenus = true;
            setTimeout(() => {
              this.selectmenus = false;
            }, 3000);
          }
        } else {
          this.deleteFacilityMenu(type);
          this.deleteFacilityCategories();
          setTimeout(() => {
            this.convertToGenericMenuType(type);
          }, 1000);
        }
      },
      (error) => {
        alert(JSON.stringify(error));
        this.buttonDisabled = false;
      }
    );
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  convertToCustomMenuType(type) {
    let files = this.importFile.srcElement.files;
    if (this.isCSVFile(files[0])) {
      let input = this.importFile.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        this.newCSVArray = csvRecordsArray;

        this.dataLength = csvRecordsArray.length;
        this.newcsvrecordlength = csvRecordsArray.length;
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };
      reader.onerror = function () {
        alert('Unable to read ' + input.files[0]);
      };
    } else {
      alert('Please import valid .csv file.');
      // this.fileReset();
    }
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let dataArr = [];
    this.csvRecordsData = [];

    this.failuerCount = 0;
    this.successCount = 0;

    csvRecordsArray = csvRecordsArray.filter((element, index) => {
      if (element) {
        var csvDataElements: any;
        csvDataElements = element.split(',');

        if (csvDataElements[0] && csvDataElements[1] && csvDataElements[2] && csvDataElements[3]) {
          if (index == 0) {
            return element;
          } else {
            if (!isNaN(csvDataElements[2]) && !isNaN(csvDataElements[3])) {
              if (Number(csvDataElements[2]) >= 0 && Number(csvDataElements[2]) >= 0) {
                return element;
              } else {
                this.failuerCount = this.failuerCount + 1;
              }
            } else {
              this.failuerCount = this.failuerCount + 1;
            }
          }
        } else {
          this.failuerCount = this.failuerCount + 1;
        }
      }
    });
    this.newCSVArray = csvRecordsArray;

    if (csvRecordsArray.length > 0) {
      for (let i = 1; i < csvRecordsArray.length - 1; i++) {
        const data = csvRecordsArray[i].split(',');
        // this.newData = data;
        data.map((category) => {});
        if (!data[0] || !data[1]) {
          // console.log(data);
          alert('category name and product name can not be blank');
          this.csvRecordsData.push(i);
          break;
        }
      }
      let checkdata = [];
      let checkdatarep = [];
      let m = 0;
      if (this.csvRecordsData.length === 0) {
        if (csvRecordsArray[1] === '') {
          alert('CSV file contains no records..');
          //this.requestDataDisabled = false;
        } else {
          csvRecordsArray.forEach((element) => {
            // checkdata.push(element.split(',')[0])
            // var n = checkdata.includes("Mango");
            if (!checkdata.includes(element.split(',')[0])) {
              checkdata.push(element.split(',')[0]);
            } else {
              // console.log('error');
              checkdatarep.push(element.split(',')[0]);
            }
          });
          // if(checkdata.length === checkdatarep.length){

          // } else {
          //   this.repeaterror = true;
          // }
          let repeaterrorda;
          checkdata.length == checkdatarep.length ? ' ' : (this.repeaterror = true);
          checkdatarep.length == 0 ? ' ' : (repeaterrorda = true);
          // if (repeaterrorda === true) {
          //   this.errorMessageduplicate = true;
          //   setTimeout(() => {
          //     this.errorMessageduplicate = false;
          //   }, 4000);
          // } else {
          let menutype;
          menutype = 'Custom';
          // if(menutype !== 'Custom'){
          this.CustomerService.getMenuTypes().subscribe((data) => {
            const menus = data.Result;
            let menuId;
            for (const menu of menus) {
              if (menu.Title === menutype) {
                menuId = menu.ID;
              }
            }
            this.CustomerService.getCustomerDetail(this.customerData.ClientId).subscribe((dataget) => {
              let payloadassign = Object.assign({}, dataget.Result[0]);
              payloadassign.MenuTypeId = menuId;
              this.CustomerService.updateCustomerDetails(payloadassign).subscribe((data) => {
                this.spinnerService.stop(this.spinnerRef);
                (<HTMLInputElement>document.getElementById('importMenuFile')).disabled = true;
                // document.getElementById('deleteImage').style.display = 'none';
                //document.getElementById('waitMsg').style.display = 'block';
                this.spinnerRef = this.spinnerService.start();

                this.requestDataDisabled = true;
                // this.CustomerService.getCategoriesofcsv(
                //   this.isPagination
                // ).subscribe(data => {
                //   this.categories = data['Result'];
                if (this.newCSVArray.length > 1) {
                  this.loopCSVfile();
                } else {
                  this.showCount(this.newcsvrecordlength - 2);
                }
                // this.relaodinfo.reloadData = true;
                // this._rdata.updatereloaddataclientBasicInfo2(
                //   JSON.stringify(this.relaodinfo)
                // );
                // });
              });
            });
          });
          // }
        }
        return dataArr;
      }
    }
  }

  async loopCSVfile() {
    if (this.newcsvrecordlength > 2) {
      if (this.newCSVArray[this.stopper]) {
        this.newData = this.newCSVArray[this.stopper].split(',');
        if (this.stopper < this.dataLength - 1) {
          let payload = {
            Name: this.newData[0],
            Price: this.newData[2],
            Weight: this.newData[3],
            Description: this.newData[0],
            DirectCost: 1,
            IsGoodrProduct: false,
            ApprovalStatusId: 2,
            WP_Product_Id: null,
            CreatedBy: null,
            CreatedOn: null,
            ModifiedBy: null,
            ModifiedOn: null,
            ProductSections: [
              {
                Name: this.newData[1].replace(/^\s+/g, ''),
              },
            ],
            Clients: [
              {
                ClientId: this.customerData.ClientId,
              },
            ],
          };
          this.CustomerService.addNewFoodItem(payload).subscribe((data) => {
            if (data['message'] == 'Successfully Added') {
              this.successCount = this.successCount + 1;
            }

            if (data['message'] == 'Duplicate Entry') {
              this.failuerCount = this.failuerCount + 1;
            }

            if (this.successCount + this.failuerCount === this.newcsvrecordlength - 2) {
              this.showCount(this.newcsvrecordlength - 2);
            } else {
              this.stopper = this.stopper + 1;
              setTimeout(() => {
                this.loopCSVfile();
              }, 500);
            }
          });
        }
        if (this.stopper === this.dataLength - 1) {
          this.dialogRef.close();
          this.router.navigate(['FacilityMenuView/' + this.customerData.ClientId]);
        }
      } else {
        this.failuerCount = this.failuerCount + 1;

        if (this.successCount + this.failuerCount === this.newcsvrecordlength - 2) {
          this.showCount(this.newcsvrecordlength - 2);
        } else {
          this.stopper = this.stopper + 1;
          setTimeout(() => {
            this.loopCSVfile();
          }, 500);
        }

        if (this.stopper === this.dataLength - 1) {
          this.dialogRef.close();
          this.router.navigate(['FacilityMenuView/' + this.customerData.ClientId]);
        }
      }
    } else {
      this.showCount(this.newcsvrecordlength - 2);
    }
  }
  // showCount(arg0: number) {
  //   throw new Error('Method not implemented.');
  // }

  showCount(totalCount) {
    // alert(this.successCount);
    this._rdata.resetBasicInfo2(JSON.stringify(this.info));
    this._rdata.updateBasicInfo2(JSON.stringify(this.info));
    this.spinnerService.stop(this.spinnerRef);
    if (this.successCount > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef.close(this.modalclose);
      const dialogRef = this.dialog.open(SuccessfulMenuDialogComponent, {
        width: '600px',
        // height: '550px',
        panelClass: 'custom-Filterdialog-container',
        disableClose: true,
        data: {
          successCount: this.successCount,
          failuerCount: this.failuerCount,
          totalCount: totalCount,
          clientId: this.customerData.ClientId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialogRef.close(this.modalclose);
      const dialogRef = this.dialog.open(SuccessfulMenuDialogComponent, {
        width: '600px',
        panelClass: 'custom-Filterdialog-container',
        disableClose: true,
        data: {
          successCount: this.successCount,
          failuerCount: this.failuerCount,
          totalCount: totalCount,
          clientId: this.customerData.ClientId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  convertToGenericMenuType(type) {
    this.spinnerRef = this.spinnerService.start();
    this.buttonDisabled = true;
    this.menusId = this.genericId;
    // console.log(this.menusId);
    if (this.customerData.ClientCategory.length > 0) {
      this.ClientCategoryId = this.customerData.ClientCategory[0].ClientCategoryId;
    }
    let payload = {
      ClientId: this.customerData.ClientId,
      ClientCategories: [
        {
          ClientCategoryId: this.ClientCategoryId,
        },
      ],
      Name: this.customerData.Name,
      City: this.customerData.City,
      State: this.customerData.State,
      CityId: this.customerData.CityId,
      StateId: this.customerData.StateId,
      FacilitatorId: this.customerData.FacilitatorId,
      FacilityGroupId: this.customerData.FacilityGroupId,
      Phone: this.customerData.Phone,
      Address: this.customerData.Address,
      ZIP: this.customerData.ZIP,
      InviteUser: this.customerData.InviteUser,
      CreatedBy: this.customerData.CreatedBy,
      CreatedOn: this.customerData.CreatedOn,
      ImageUrl: this.customerData.ImageUrl,
      Address2: this.customerData.Address2,
      Logo: this.customerData.Logo,
      Country: this.customerData.Country,
      Email: this.customerData.Email,
      Web: this.customerData.Web,
      EINNumber: this.customerData.EINNumber,
      HasFridgeStorage: this.customerData.HasFridgeStorage,
      NumberOfPeopleServed: this.customerData.NumberOfPeopleServed,
      WasteExpenditure: this.customerData.WasteExpenditure,
      StripeCustomerId: this.customerData.StripeCustomerId,
      StripePlanId: this.customerData.StripePlanId,
      ClientTypeId: this.customerData.ClientTypeId,
      StripeSubscriptionId: this.customerData.StripeSubscriptionId,
      ApprovalStatusId: this.customerData.ApprovalStatusId,
      MenuTypeId: this.menusId,
      WayPointID: this.customerData.WayPointID,
      WP_Client_Id: 0,
      MenuEdit: true,
      TimeZone: this.customerData.TimeZone,
      // "ClientCategories":this.user[0].ClientCategory[0].Title,
      // ClientCategories: this.user,
      DriverServices: [
        {
          ID: this.customerData.DriverServices[0].ID,
        },
      ],
      User_Client: [],
      SAASDriverId: this.customerData.selectedDriver,
      AllowSchedulePickup: this.customerData.AllowSchedulePickup,
    };
    this.CustomerService.updateCustomerDetails(payload).subscribe(
      (data) => {
        // console.log(data);
        this.infoClients.facilitator = '';
        this.infoClients.facilitatorGroup = '';
        this.infoClients.facility = '';
        this.infoClients.nonprofits = '';
        this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.infoClients));
        let localUser = JSON.parse(localStorage.getItem('user'));
        for (var i = 0; i < localUser.user.User.Clients.length; i++) {
          if (localUser.user.User.Clients[i].ClientId == data['Result'][0].ClientId) {
            localUser.user.User.Clients.splice(i, 1);
            break;
          }
        }
        localUser.user.User.Clients.push(data['Result'][0]);
        localStorage.setItem('user', JSON.stringify(localUser));
        this.state.user = JSON.parse(localStorage.getItem('user')).user;
        this.clientId = data['Result'][0].ClientId;
        this.spinnerService.stop(this.spinnerRef);
        this.dialogRef.close();
        this.router.navigate(['FacilityMenuView/' + this.clientId]);
      },
      (error) => {
        this.buttonDisabled = false;
      }
    );

    // this._rdata.updateBasicInfo2(JSON.stringify(this.info));
  }

  createAccountMenu(type) {
    if (type !== 'custom') {
      this.spinnerRef = this.spinnerService.start();
      this.buttonDisabled = true;
      if (type == 'custom') {
        this.menusId = this.customId;
      } else {
        this.menusId = this.genericId;
      }
      if (this.customerData.ClientCategory.length > 0) {
        this.ClientCategoryId = this.customerData.ClientCategory[0].ClientCategoryId;
      }
      let payload = {
        ClientId: this.customerData.ClientId,
        ClientCategories: [
          {
            ClientCategoryId: this.ClientCategoryId,
          },
        ],
        Name: this.customerData.Name,
        City: this.customerData.City,
        State: this.customerData.State,
        CityId: this.customerData.CityId,
        StateId: this.customerData.StateId,
        FacilitatorId: this.customerData.FacilitatorId,
        FacilityGroupId: this.customerData.FacilityGroupId,
        Phone: this.customerData.Phone,
        Address: this.customerData.Address,
        ZIP: this.customerData.ZIP,
        InviteUser: this.customerData.InviteUser,
        CreatedBy: this.customerData.CreatedBy,
        CreatedOn: this.customerData.CreatedOn,
        ImageUrl: this.customerData.ImageUrl,
        Address2: this.customerData.Address2,
        Logo: this.customerData.Logo,
        Country: this.customerData.Country,
        Email: this.customerData.Email,
        Web: this.customerData.Web,
        EINNumber: this.customerData.EINNumber,
        HasFridgeStorage: this.customerData.HasFridgeStorage,
        NumberOfPeopleServed: this.customerData.NumberOfPeopleServed,
        WasteExpenditure: this.customerData.WasteExpenditure,
        StripeCustomerId: this.customerData.StripeCustomerId,
        StripePlanId: this.customerData.StripePlanId,
        ClientTypeId: this.customerData.ClientTypeId,
        StripeSubscriptionId: this.customerData.StripeSubscriptionId,
        ApprovalStatusId: this.customerData.ApprovalStatusId,
        MenuTypeId: this.menusId,
        WayPointID: this.customerData.WayPointID,
        WP_Client_Id: 0,
        MenuEdit: true,
        TimeZone: this.customerData.TimeZone,
        // "ClientCategories":this.user[0].ClientCategory[0].Title,
        // ClientCategories: this.user,
        DriverServices: [
          {
            ID: this.customerData.DriverServices[0].ID,
          },
        ],
        User_Client: [],
        SAASDriverId: this.customerData.selectedDriver,
        AllowSchedulePickup: this.customerData.AllowSchedulePickup,
      };
      this.CustomerService.updateCustomerDetails(payload).subscribe(
        (data) => {
          this.infoClients.facilitator = '';
          this.infoClients.facilitatorGroup = '';
          this.infoClients.facility = '';
          this.infoClients.nonprofits = '';
          this.ClientFilterdata.updateBasicInfo(JSON.stringify(this.infoClients));
          let localUser = JSON.parse(localStorage.getItem('user'));
          for (var i = 0; i < localUser.user.User.Clients.length; i++) {
            if (localUser.user.User.Clients[i].ClientId == data['Result'][0].ClientId) {
              localUser.user.User.Clients.splice(i, 1);
              break;
            }
          }
          localUser.user.User.Clients.push(data['Result'][0]);
          localStorage.setItem('user', JSON.stringify(localUser));
          this.state.user = JSON.parse(localStorage.getItem('user')).user;
          if (type == 'custom') {
            this.clientId = data['Result'][0].ClientId;
            this.gotoImportMenuDialog(this.clientId);
          } else {
            this.clientId = data['Result'][0].ClientId;
            this.spinnerService.stop(this.spinnerRef);
            this.dialogRef.close();
            this.router.navigate(['FacilityMenuView/' + this.clientId]);
          }
          // this._rdata.resetBasicInfo2(JSON.stringify(this.info));
        },
        (error) => {
          // alert(JSON.stringify(error));
          this.buttonDisabled = false;
        }
      );
    } else {
      this.gotoImportMenuDialog(this.customerData.ClientId);
    }
    // this._rdata.updateBasicInfo2(JSON.stringify(this.info));
  }

  gotoImportMenuDialog(id) {
    const dialogRef = this.dialog.open(ImportMenuDialogComponent, {
      width: '700px',
      // height: "450px",
      data: {
        clientId: id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    this.dialogRef.close(this.modalclose);
  }

  skipMenu() {
    this.dialogRef.close();
  }
}
